import React from 'react';
import {action, makeObservable, observable} from "mobx";
import {LanguagesModel} from "../../../../models/languages/languages";
import {languagesService} from "../../../../services/languages/languages.service";
import {KeyRequest} from "../../../../models/languages/key.request";
import {FilterDataHandler} from "../../../../models/filter/filter.data.handler";
import {PaginatedRequest} from "../../../../models/core/paginated.request";

export class LanguagesListStore {

    @observable
    public scopes: string[] = [];

    @observable
    public locales: string[] = [];

    @observable
    public createModalShown: boolean = false;

    @observable
    public editModalShown: boolean = false;

    @observable
    public editModalItem?: LanguagesModel;

    public dataHandler: FilterDataHandler<LanguagesModel>;

    private request: PaginatedRequest = new PaginatedRequest();


    constructor() {
        makeObservable(this);
        this.updateKey = this.updateKey.bind(this);
        this.createKey = this.createKey.bind(this);
        this.openKeyCreateModal = this.openKeyCreateModal.bind(this);
        this.closeKeyCreateModal = this.closeKeyCreateModal.bind(this);
        this.closeKeyEditModal = this.closeKeyEditModal.bind(this);
        this.dataHandler = new FilterDataHandler(request => languagesService.getAllKeys(request));
        this.loadLocales();
        this.loadScopes();
    }

    public async removeKey(key: LanguagesModel) {
        await languagesService.deleteKey(key.id!!);
        this.dataHandler.removeItem(key);
    }

    @action
    public setScopes(scopes: string[]): void {
        this.scopes = scopes;
    }

    @action
    public setLocales(locales: string[]): void {
        this.locales = locales;
    }

    public async loadScopes() {
        this.setScopes(await languagesService.getScopes());
    }

    public async loadLocales() {
        this.setLocales(await languagesService.getLocales());
    }

    public isShownKeyEditModal(item: LanguagesModel): boolean {
        return this.editModalShown && this.editModalItem === item;
    }

    public isShownKeyCreateModal(): boolean {
        return this.createModalShown;
    }

    @action
    public openKeyEditModal(item: LanguagesModel): void {
        this.editModalShown = true;
        this.editModalItem = item;
    }

    @action
    public closeKeyEditModal(): void {
        this.editModalShown = false;
    }

    @action
    public openKeyCreateModal(): void {
        this.createModalShown = true;
    }

    @action
    public closeKeyCreateModal(): void {
        this.createModalShown = false;
    }

    public async updateKey(request: KeyRequest, item: LanguagesModel) {
        const updatedItem = await languagesService.updateKey(request, item.id);
        this.dataHandler.updateItem(item, updatedItem);
    }

    public async createKey(request: KeyRequest) {
        const createdItem = await languagesService.createKey(request);
        this.dataHandler.addItem(createdItem);
    }

    public async importLocales(value: {file: File, withUpdate: boolean}, onProgress: (percent: number) => void) {
        return await languagesService.importLocaleFile(value, onProgress);
    }

    public async updateItems() {
        this.dataHandler.setLoading(true);
        await this.dataHandler.reloadItems(this.request);
        this.dataHandler.setLoading(false);
    }


}

export const LanguagesStoreContext = React.createContext<LanguagesListStore | null>(null);
