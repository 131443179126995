import React, {useMemo, useState} from "react";
import {Button, Form, FormInstance, Input, Select, Space, Table, Upload} from "antd";
import {ShipContext} from "../../ship-modal";
import {ColumnsType} from "antd/es/table";
import {PreShipmentParcelInfo} from "../../../../../../models/parcels/pre-shipments";
import {preShipmentsService} from "../../../../../../services/shipments/pre-shipments.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Courier} from "../../../../../../models/parcels/shipment";
import {RenderUploadLabelField} from "./select-courier-label-field";

export const columnsFactory = (form: FormInstance): ColumnsType<PreShipmentParcelInfo> => {
    return [
        {
            title: 'Number',
            dataIndex: 'number',
            className: 'v-top',
            render: (number: string, info) => <Space direction={"horizontal"} size={[10, 10]} style={{marginTop: 5}}>
                <FontAwesomeIcon icon={info.type} color={"#676767"} />
                <span>{number || "(will be generated)"}</span>
            </Space>
        },
        {
            title: 'Tracking code',
            render: (_, parcel, index) => <Form.Item
                key={index}
                name={["parcels", index.toString(), 'trackCode']}
                rules={[{ required: true, message: 'Tracking code is required' }]}
            >
                <Input />
            </Form.Item>
        },
        {
            title: 'Label',
            width: 100,
            render: (_, parcel, index) => <RenderUploadLabelField parcel={parcel} index={index} />
        },
        {
            title: 'Label page',
            width: 100,
            render: (_, parcel, index) => <Form.Item
                key={index}
                name={["parcels", index.toString(), 'labelPage']}
                rules={[{ required: true, message: 'Tracking code is required' }]}
            >
                <Input />
            </Form.Item>
        }
    ]
}

interface SelectCourierStepManuallyProps {
    couriers: Courier[];
}

export const SelectCourierStepManually: React.FC<SelectCourierStepManuallyProps> = ({couriers}) => {

    const c = React.useContext(ShipContext);

    const [form] = Form.useForm();
    const columns = useMemo(() => columnsFactory(form), []);
    const parcels = useMemo(() => c.parcels.filter(p => !p.mergedTo), [c.parcels])

    const [loading, setLoading] = useState<boolean>(false);

    return <Space direction="vertical" size={[15, 15]}>
            <Space direction="vertical" size={[15, 15]}>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{courier: couriers[0].id, parcels: parcels.map(v => ({"labelPage": 1}))}}
                    onFinish={values => {
                        form.validateFields().then(async () => {
                            parcels.map((value, index) => {
                                const insertions = values.parcels[index.toString()];
                                const realIdx = c.parcels.findIndex(p => p.number === value.number);
                                if (-1 !== realIdx) {
                                    c.parcels[realIdx] = {
                                        ...value,
                                        ...insertions,
                                        labelPage: parseInt(insertions.labelPage)
                                    }
                                }
                            })

                            setLoading(true);
                            try {
                                c.onComplete(await preShipmentsService.customBatchShip(c.selectedDocuments, values.courier, c.parcels, c.address!))
                            } finally {
                                setLoading(false);
                            }
                        })
                    }}
                >
                    <Form.Item
                        name="courier"
                        label="Courier"
                    >
                        <Select disabled={loading}>
                            {couriers.map(courier => <Select.Option value={courier.id}>{courier.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Table
                        columns={columns}
                        dataSource={parcels}
                        rowKey={"id"}
                        pagination={false}
                    />
                </Form>
            </Space>
            <Space direction="horizontal" size={[15, 15]}>
                <Button type={"primary"} onClick={() => form.submit()} disabled={loading}>Create shipment</Button>
                <Button type={"default"} onClick={() => c.setStep(2)} disabled={loading}>Go back</Button>
            </Space>
    </Space>
}