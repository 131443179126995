import React, {useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Checkbox, Col, Form, FormInstance, Input, Modal, Row, Tooltip} from "antd";
import {UserContacts} from "../../../../models/users/user/extended.user";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {generatePassword} from "../../../../utils/helpers";


interface UserDataEditModalProps {
    visible: boolean,
    onClose: () => void,
    onSubmit: (values: UserContacts) => Promise<void>,
    userData?: UserContacts
}

export const UserModal: React.FC<UserDataEditModalProps> = ({visible, userData, onClose, onSubmit}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const setGeneratedPassword = (form: FormInstance) => {
        const generatedPassword = generatePassword();
        form.setFieldsValue({"password": generatedPassword});
    };

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={t(userData ? "USER.EDIT" : "USERS.CREATE")}
            okText={t(userData ? "SAVE" : "ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                size={"middle"}
                form={form}
                layout="vertical"
                name={"edit_user_form"}
                initialValues={{...userData, sendPassword: true}}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        onSubmit({
                            ...values,
                            password: values.password ? values.password.trim() : "",
                            sendPassword: values.sendPassword === undefined ? false : values.sendPassword
                        })
                            .then(
                                () => {
                                    form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    setLoading(false);
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                            .then(() =>
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={[10, 0]} align={"middle"}>
                    <Col span={12}>
                        <Form.Item
                            name={"firstName"}
                            label={t("FIRSTNAME")}
                            rules={[{
                                required: true,
                                message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                            }]}
                        >
                            <Input disabled={loading} type={"text"}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={"lastName"}
                            label={t("LASTNAME")}
                            rules={[{
                                required: true,
                                message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                            }]}
                        >
                            <Input disabled={loading} type={"text"}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"email"}
                            label={t("EMAIL")}
                            rules={[{
                                required: true,
                                message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                            }]}
                        >
                            <Input disabled={loading} type={"text"}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"password"}
                            label={t("PASSWORD.TITLE")}
                        >
                            <Input.Password
                                disabled={loading}
                                placeholder={t("PASSWORD.EDIT")}
                                allowClear
                                addonAfter={
                                    <Tooltip title={t("PASSWORD.GENERATE")}>
                                        <FontAwesomeIcon onClick={() => setGeneratedPassword(form)} style={{cursor: "pointer"}} icon={["fas", "dice"]}/>
                                    </Tooltip>
                                }
                                iconRender={visible => (
                                    visible ? (
                                            <Tooltip title={t("PASSWORD.HIDE")}>
                                                <FontAwesomeIcon style={{marginLeft: "3px"}} icon={["fas", "eye-slash"]}/>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title={t("PASSWORD.SHOW")}>
                                                <FontAwesomeIcon style={{marginLeft: "3px"}} icon={["fas", "eye"]}/>
                                            </Tooltip>
                                    )
                                )}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item noStyle
                                   shouldUpdate={(prevValues, nextValues) => prevValues.password !== nextValues.password}>
                            {({getFieldValue, setFieldsValue}) => getFieldValue("password") ? (
                                <Form.Item name={"sendPassword"} valuePropName={"checked"}>
                                    <Checkbox>{t("PASSWORD.SEND_PASSWORD")}</Checkbox>
                                </Form.Item>
                            ) : null}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
