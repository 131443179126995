import React, {useState} from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Button, Card, Tooltip, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CustomCard} from "../../custom-card/custom.card";
import {SellerModal} from "../modals/seller.modal";
import {SellerDetails} from "../../../../models/payer-receiver-seller/payer.reveiver.seller";


interface SellerCardProps {
    data: SellerDetails,
    editSeller: (values: SellerDetails) => Promise<void>
}

export const SellerCard: React.FC<SellerCardProps> = observer(({data, editSeller}) => {
    const {langStore} = useRequiredStore(RootStoreContext);
    const t = useTranslate();
    const [modalShown, setModalShown] = useState<boolean>(false);

    const cardDataRows = [
        {key: "name"},
        {key: "vatNumber", label: "VAT.TITLE"},
        {key: "customsCode", label: "EORI"},
        {key: "country"},
        {key: "city"},
        {key: "street"},
        {key: "zip"},
        {key: "regNumber", label: "REG_NUMBER"}
    ];

    return (
        <>
            <Card
                className="card"
                title={t("PAYER_SELLER_DETAILS.SELLER_DETAILS")}
                extra={
                    <Tooltip placement="topRight" title={t("EDIT")}>
                        <Button
                            onClick={() => setModalShown(true)}
                            className={"card-btn btn"}
                            type="default"
                            icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                        />
                    </Tooltip>
                }
            >
                <CustomCard data={{...data, country: data.country.name}} rowKeys={cardDataRows}/>
            </Card>
            <SellerModal data={data} visible={modalShown} onClose={() => setModalShown(false)} onSubmit={editSeller}/>
        </>
    );
});
