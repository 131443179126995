import React, {useEffect, useMemo, useState} from "react";
import {Modal, Table} from "antd";
import {useTranslate} from "../../../hooks/translate.hook";
import {OrderSplitPreview} from "../../../models/orders/order-split/order.split";
import {columnsFactory} from "./split.modal.columns";
import {orderSplitService} from "../../../services/orders/order-split/orders.split";
import {TableFooter} from "../../common/table-footer/table.footer";
import {generateDetails} from "./split.model.table-footer";
import {useNavigate} from "react-router";

interface OrdersSplitModalProps {
    orderId: number;
    onClose: () => void;
}

export const OrdersSplitModal: React.FC<OrdersSplitModalProps> = ({orderId, onClose}) => {

    const t = useTranslate();
    const [loading, setLoading] = useState(false);

    const [preview, setPreview] = useState<OrderSplitPreview | null>(null);

    const columns = useMemo(() => columnsFactory(t), [])

    const navigate = useNavigate();

    useEffect(() => {
        orderSplitService.preview(orderId).then(preview => setPreview(preview))
    }, [])

    return <Modal
        width={"900px"}
        destroyOnClose
        visible
        confirmLoading={loading}
        title={t("SPLIT")}
        okText={t("OK")}
        cancelText={t("CANCEL")}
        onOk={() => {
            orderSplitService.doSplit(orderId).then(() => {
                navigate("/orders/retail?filter=id__id%3D" + orderId + "%7C%3D&page=1&limit=20");
            })
        }}
        onCancel={() => {
            onClose();
        }}
    >
        {preview ? (
            <>
                {preview.segments.map((segment => {
                    return (
                        <>
                            <h2>{t('WAREHOUSES.WAREHOUSE_TITLE')} - {segment.warehouse.name}</h2>
                            <Table
                                dataSource={segment.items}
                                columns={columns}
                                pagination={false}
                                footer={() => <TableFooter data={generateDetails(segment)} />}
                            />
                        </>
                    )
                }))}
            </>
        ) : (
            <>Loading...</>
        )}
    </Modal>
}