import { Button, Row, Space, Typography } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { Link } from "react-router-dom";
import { ArticleLockedStock } from "../../../../models/articles/articles.locked-stock";
import { ArticlesLinks } from "../articles-links/articles.links";
import { ArticleLockedStockStore } from "./articles.locked-stock.store";
import { ArticlesLockedStockModal } from "./modal/article.locked-stock.modal";

export const ArticlesLockedStock: React.FC = observer(() => {
    const t = useTranslate();
    const [store] = useState(() => new ArticleLockedStockStore());

    const columns: ColumnsType<ArticleLockedStock> = [
        {
            title: t("WAREHOUSES.TITLE"),
            key: "warehouseName",
            dataIndex: "warehouseName",
        },
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            key: "articleName",
            dataIndex: "articleName",
            render: (article) => <Link to={"/articles/" + article}>{article}</Link>,
        },
        {
            title: t("STOCK"),
            key: "amount",
            dataIndex: "amount",
        },
        {
            title: t("LOCKED_STOCK"),
            key: "lockedAmount",
            dataIndex: "lockedAmount",
        },
        {
            title: t("RESULT_STOCK"),
            key: "lockedAmount",
            dataIndex: "lockedAmount",
            render: (_, row) => row.amount - row.lockedAmount,
        },
        {
            title: t("ACTION"),
            key: "action",
            dataIndex: "action",
            render: (_, row) => (
                <Button onClick={() => store.openModal(row)} type="primary">
                    {t("SHOW_DETAILS")}
                </Button>
            ),
        },
    ];
    return (
        <>
            <Space direction="vertical" size={[50, 50]}>
                <ArticlesLinks />
                <Space direction="vertical" size={[0, 0]}>
                    <Row style={{ marginBottom: 10 }} justify="space-between" wrap>
                        <Typography.Title level={3} style={{ margin: 0 }}>
                            {t("ARTICLES.LOCKED_STOCK")}
                        </Typography.Title>
                    </Row>
                    <Table<ArticleLockedStock>
                        rowKey={(row) => row.id + "_" + row.warehouseId}
                        loading={store.loading}
                        columns={columns}
                        dataSource={store.lockedStock}
                        pagination={false}
                    />
                </Space>
            </Space>

            {store.modalItem && <ArticlesLockedStockModal item={store.modalItem} onClose={store.closeModal} />}
        </>
    );
});
