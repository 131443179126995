import {ColumnsType} from "antd/es/table";
import {Link} from "react-router-dom";
import {Amount} from "../../../../../models/prices/price";
import {displayPrice} from "../../../../../models/prices/price.helper";
import Date from "../../../../date/date";
import {Button, Space, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Translate} from "../../../../../hooks/translate.hook";
import {Invoice} from "../../../../../models/documents/invoices/invoice";
import {splitNumber} from "../../../../../utils/helpers";

export const columnsFactory = (t: Translate): ColumnsType<Invoice> => {
    return [
        {
            title: t("INVOICES.NUMBER"),
            dataIndex: "docNumber",
            key: "docNumber",
            width: "120px",
            render: (number: string, item: Invoice) => <Link to={"/invoices/" + item.id}>{number}</Link>,
        },
        // {
        //     title: t("PACKING_LIST"),
        //     dataIndex: "order",
        //     key: "order_packing",
        //     render: ({packingList}: InvoiceOrderDetails) => packingList ? <span style={{whiteSpace: "nowrap"}}>{packingList.docNumber}</span> : null
        // },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "grandTotal",
            key: "grandTotal",
            render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
        },
        // {
        //     title: t("WAREHOUSES.WAREHOUSE_TITLE"),
        //     dataIndex: "order",
        //     key: "warehouse",
        //     render: ({warehouse}: InvoiceOrderDetails) => warehouse ? <>{warehouse.name}</> : null,
        // },
        {
            title: t("WEIGHT_BRUTTO"),
            dataIndex: "totalGrossWeight",
            key: "totalGrossWeight",
            render: (item: number) => splitNumber(item),
        },
        {
            title: t("PAYMENT_TERMS"),
            dataIndex: "paymentTerms",
            key: "paymentTerms",
            width: 105
        },
        {
            title: t("INVOICES.DISPATCH.IS_DISPATCHED"),
            dataIndex: "dispatched",
            key: "dispatched",
            render: (item: boolean) =>
                item ? t("INVOICES.DISPATCH.DISPATCHED") : t("INVOICES.DISPATCH.NOT_DISPATCHED"),
        },
        {
            title: t("INVOICES.DISPATCH.DISPATCH_DATE"),
            dataIndex: "date",
            key: "date",
            render: (date: string) => <Date dateString={date} />,
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date: string) => <Date dateString={date} showTime />,
        },
        {
            title: t("ACTION"),
            key: "action",
            className: "table-button-cell",
            width: 50,
            render: (_: any, item) => (
                <Space direction={"horizontal"}>
                    <Tooltip placement="topRight" title={t("DOWNLOAD.DO_DOWNLOAD")}>
                        <a href={"/documents/" + item.id} target={"_blank"}>
                            <Button
                                className="table-btn btn-remove btn"
                                type="default"
                                icon={
                                    <FontAwesomeIcon
                                        icon={["fas", "file-pdf"]}
                                    />
                                }
                            />
                        </a>
                    </Tooltip>
                </Space>
            ),
        },
    ];
}