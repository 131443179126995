import React from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import {Alert} from "antd";
import "./version-checker.scss"
import {Trans} from "react-i18next";
import {useTranslate} from "../../../hooks/translate.hook";

export const VersionChecker: React.FC = observer(() => {

    const {commonStore} = useRequiredStore(RootStoreContext);

    const t = useTranslate();

    if (commonStore.outdated) {
        return (
            <Alert
                className={"version-checker"}
                message={t('VERSION.UPDATED.TITLE')}
                description={
                    <Trans
                        t={t}
                        i18nKey={t('VERSION.UPDATED.CONTENT')}
                        components={[
                            <a href={""}>{t('VERSION.UPDATED.ACTION')}</a>
                        ]}
                    />
                }
                type="warning"
                showIcon
            />
        );
    }

    return null;
});
