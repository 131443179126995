import { Form, Modal, Select } from "antd";
import React, { useMemo } from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { Department } from "../../../../../models/departments/department";
import { useResetFormOnCloseModal } from "./use-reset-on-close";

interface BranchDataDepartmentChildrenModalProps {
    rootDepartmentId: number;
    departments: Department[];
    parent?: number;
    selectedChildren: { id: number; name: string }[];
    visible: boolean;
    onCancel: () => void;
}
export const BranchDataDepartmentChildrenModal: React.FC<BranchDataDepartmentChildrenModalProps> = ({
    selectedChildren = [],
    departments,
    rootDepartmentId,
    parent,
    visible,
    onCancel,
}) => {
    const t = useTranslate();
    const [form] = Form.useForm();

    useResetFormOnCloseModal({
        form,
        visible,
    });

    const onOk = () => {
        form.submit();
    };

    const departmentsToSelect = useMemo(
        () =>
            departments.filter(
                (item) =>
                    item.id !== rootDepartmentId &&
                    !selectedChildren.map((item) => item.id).includes(item.id) &&
                    item.id !== parent
            ),

        [departments, rootDepartmentId, selectedChildren, parent]
    );

    return (
        <Modal title="TITLE" visible={visible} onOk={onOk} onCancel={onCancel}>
            <Form form={form} layout="vertical" name="departmentChildren">
                <Form.Item
                    name="id"
                    label={t("NAME")}
                    rules={[
                        {
                            required: true,
                            message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                        },
                    ]}
                >
                    <Select
                        autoFocus={true}
                        showSearch
                        allowClear
                        optionFilterProp={"children"}
                        placeholder={t("NAME")}
                    >
                        {departmentsToSelect.map((department) => (
                            <Select.Option key={department.id} value={department.id}>
                                {department.name.replace(/—/g, "")}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};
