import {Translate} from "../../../../hooks/translate.hook";
import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {Link} from "react-router-dom";
import {Claim, ClaimStatus} from "../../../../models/claims/claims";
import {Amount} from "../../../../models/prices/price";
import {displayPrice} from "../../../../models/prices/price.helper";
import Date from "../../../date/date";
import {ClaimsStatus} from "../../../claims/status/claim.status";

export const columnsFactory = (t: Translate): ColumnsType<Claim> => {
    return [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("NUMBER"),
            dataIndex: "documentNumber",
            key: "documentNumber",
            render: (number: number, claim) => <Link to={"/claims/" + claim.id}>{number}</Link>,
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            render: (item: ClaimStatus) => <ClaimsStatus status={item} />
        },
        // {
        //     title: t("TOTAL_AMOUNT"),
        //     dataIndex: "grandTotal",
        //     key: "grandTotal",
        //     render: (item: Amount) => displayPrice(item),
        // },
        {
            title: t("DATE"),
            dataIndex: "documentDate",
            key: "documentDate",
            render: (item: string) => <Date dateString={item} showTime />
        }
    ];
}