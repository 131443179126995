import React, {useState} from "react";
import {Col, Form, Modal, Row} from "antd";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {
    UIInputWidgetPropertyResponsibleUser
} from "../../../../ui/input/widget/property-responsible-user/ui.input.widget.property-responsible-user";
import {PropertyInventoryTaskPart} from "../../../../../../models/property/property";
import {propertyService} from "../../../../../../services/property/property.service";

interface PartEditModalProps {
    part: PropertyInventoryTaskPart
    onClose: () => void;
}

export const PartEditModal: React.FC<PartEditModalProps> = ({ part, onClose }) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={part.type.name}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={onClose}
            maskClosable={false}
            width={700}
            className={"inventory-task-modal"}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{responsiblePersons: part.responsiblePersons.map(u => u.id)}}
                onFinish={() => form
                    .validateFields()
                    .then(async values => {
                        setLoading(true);
                        try {
                            await propertyService.editInventoryPart(part.id, values)
                            onClose();
                        }
                        catch (e: any) {
                            form.setFields(e.response?.data?.fields || [])
                        } finally {
                            setLoading(false);
                        }
                    })
                }
            >
                <Row gutter={[0, 0]}>
                    <Col span={14}>
                        <Form.Item
                            label={t('PROPERTY.PROPERTY.RESPONSIBLE_PERSON')}
                            name={['responsiblePersons']}
                            rules={[{ required: true, message: 'Missing users' }]}
                            style={{width: '100%'}}
                        >
                            <UIInputWidgetPropertyResponsibleUser multiple />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}