import React from "react";
import {WidgetProps} from "../widget";
import {Col, Row} from "antd";
import "./ui.input.card-list.scss"

export interface CardConfig {
    title: string;
    hint?: string;
    color: string;
    value: string;
}

interface CardListProps extends WidgetProps {
    cards: CardConfig[];
}

export const UIInputCardList: React.FC<CardListProps> = ({value, onChange, cards, ...props}) => {

    return (
        <Row gutter={[10, 10]}>
            {cards.map((card, key) => (
                <Col
                    key={key}
                    span={Math.floor(24 / cards.length)}
                >
                    <div
                        className={"ui-input-card-list-cart" + (value === card.value ? " selected" : "")}
                        style={{["--bg-color" as any]: card.color}}
                        onClick={() => onChange && onChange(card.value)}
                    >
                        {card.value.toUpperCase()}
                        <div className={"hint"}>
                            {card.hint}
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    )
}