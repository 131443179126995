import { AxiosInstance } from "axios";
import API from "../../../utils/api";
import { PaginatedRequest } from "../../../models/core/paginated.request";
import { PaginatedResult } from "../../../models/core/paginated.result";
import { ArticlesStock } from "../../../models/articles/articles.stock";

export class ArticlesStockService {
    private api: AxiosInstance = API;

    getAll(request: PaginatedRequest): Promise<PaginatedResult<ArticlesStock>> {
        return this.api
            .get<PaginatedResult<ArticlesStock>>("articles/stock", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }

    getAllInWarehouses(
        request: PaginatedRequest,
        warehouses: string[]
    ): Promise<PaginatedResult<ArticlesStock>> {
        request.addFilter("warehouse_types", warehouses);
        return this.getAll(request);
    }
}

export const articlesStockService = new ArticlesStockService();
