import React, {PropsWithChildren} from "react";
import {Row, Select, Typography} from "antd";
import logo from "./assets/fms_logo.svg"
import "./login/login.scss";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../utils/store";
import {RootStoreContext} from "../../stores/root/root.store";
import {useTranslate} from "../../hooks/translate.hook";


interface LoginWrapperProps {
    title: string
}
export const LoginWrapper: React.FC<PropsWithChildren<LoginWrapperProps>> = observer(({title, children}) => {
    const t = useTranslate();
    const langStore = useRequiredStore(RootStoreContext).langStore;

    return (
        <div className="login-wrapper">
            <Row justify="space-around" align="middle" style={{height: "100vh", maxWidth: 350, width: "100%"}} className="login-row">
                <div className="login-block">
                    <img src={logo}/>
                    <div className="login-form-items">
                        <Row justify="space-between" align="top" style={{width: "100%"}}>
                            <Typography.Title level={3}>{t(title)}</Typography.Title>
                            <Select
                                showSearch
                                optionFilterProp={"children"}
                                onChange={(value: string) => t.i18n.changeLanguage(value)}
                                style={{width: "70px"}}
                                value={t.i18n.language}
                            >
                                {langStore.locales.map((item, index) => (
                                    <Select.Option
                                        key={index}
                                        value={item}
                                    >
                                        {item.toUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Row>
                        {children}
                    </div>
                </div>
            </Row>
        </div>
    );
})