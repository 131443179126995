import React, {useEffect, useState} from "react";
import {Col, Form, Modal, Row, Select} from "antd";
import {Branch} from "../../../../../models/branches/branch";
import {branchesService} from "../../../../../services/branches/branches.service";
import {System} from "../../../../../models/systems/system/system";
import {systemsService} from "../../../../../services/systems/systems.service";
import {departmentsService} from "../../../../../services/departments/departments.service";
import {Department, DepartmentFlatten} from "../../../../../models/departments/department";
import {DepartmentRole} from "../../../../../models/departments/roles";
import {WikiArticleAccess, WikiArticleAccessLevel} from "../../../../../models/wiki/wiki";
import {flattenDepartments} from "../../../../../models/departments/helper";

interface WikiPagePermissionsEditModalProps {
    permission: WikiArticleAccess | null,
    visible: boolean,
    onSubmit: (value: WikiArticleAccess, editable: WikiArticleAccess | null) => void,
    onClose: () => void;
}

const createDefaultValues = (access: WikiArticleAccess) => {
    return {
        system: access.system.id,
        branch: access.branch?.id || "",
        department: access.department?.id || 0,
        departmentRole: access.departmentRole?.id || 0,
        level: access.level
    }
}

export const WikiPagePermissionsEditModal: React.FC<WikiPagePermissionsEditModalProps> = ({visible, onSubmit, onClose, permission}) => {

    const [form] = Form.useForm();

    const [branches, setBranches] = useState<Branch[]>([]);
    const [systems, setSystems] = useState<System[]>([]);
    const [departments, setDepartments] = useState<DepartmentFlatten[]>([]);
    const [departmentRoles, setDepartmentRoles] = useState<DepartmentRole[]>([]);

    useEffect(() => {
        branchesService.getAllBranches().then(branches => setBranches(branches));
        systemsService.getSystems().then(systems => setSystems(systems.filter(s => -1 !== ['fms_main', 'fms_ws'].indexOf(s.id))));
    }, []);

    useEffect(() => {
        let values: any = {};

        if (permission) {
            values = createDefaultValues(permission);
            form.setFieldsValue(values);
            loadDepartments(values.branch);
            if (values.department) {
                loadDepartmentRoles(values.department);
            }
        } else {
            form.resetFields();
            form.setFieldsValue({"department": 0, "departmentRole": 0, "branch": "", "level": WikiArticleAccessLevel.READABLE})
        }
    }, [permission])

    const loadDepartments = (branchId: string) => {
        setDepartmentRoles([]);
        if ("" === branchId) {
            setDepartments([]);
            return;
        }

        departmentsService
            .getBranchDepartments(branchId!)
            .then(departments => setDepartments(flattenDepartments(departments)));
    }

    const loadDepartmentRoles = (departmentId: number) => {
        if (0 === departmentId) {
            setDepartmentRoles([]);
            return;
        }

        departmentsService
            .getDepartmentRoles(departmentId)
            .then(departmentRoles => setDepartmentRoles(departmentRoles));
    }

    return <Modal
        width={500}
        visible={visible}
        title={"Edit permissions"}
        onCancel={() => onClose()}
        onOk={() => {
            form.validateFields().then(value => {

                const result: WikiArticleAccess = {
                    system: systems.find(e => e.id === value.system)!,
                    branch: branches.find(e => e.id === value.branch) || null,
                    department: departments.find(e => e.id === value.department)?.department || null,
                    departmentRole: departmentRoles.find(e => e.id === value.departmentRole) || null,
                    level: value.level || "readable"
                }

                onSubmit(result, permission);
            })
        }}
        destroyOnClose={true}
    >
        <Form
            size={"middle"}
            layout={"vertical"}
            form={form}
            autoComplete="off"
        >
            <Row gutter={[10, 10]}>
                <Col span={12}>
                    <Form.Item
                        label={"System"}
                        name={['system']}
                        rules={[{ required: true }]}
                    >
                        <Select>
                            {systems.map(branch => (
                                <Select.Option value={branch.id}>{branch.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={"Branch"}
                        name={['branch']}
                    >
                        <Select<string>
                            onChange={value => {
                                loadDepartments(value)
                            }}
                        >
                            <Select.Option value={""}>All branches</Select.Option>
                            {branches.map(branch => (
                                <Select.Option value={branch.id}>{branch.id.toUpperCase()}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                            prevValues.branch !== curValues.branch
                        }
                    >
                        {() => (
                            <Form.Item
                                label={"Department"}
                                name={['department']}
                            >
                                <Select<number> onChange={value => loadDepartmentRoles(value)}>
                                    <Select.Option value={0}>All departments</Select.Option>
                                    {departments.map(branch => (
                                        <Select.Option value={branch.id}>{branch.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                            prevValues.branch !== curValues.branch
                        }
                    >
                        {() => (
                            <Form.Item
                                label={"Role"}
                                name={['departmentRole']}
                            >
                                <Select<number>>
                                    <Select.Option value={0}>All roles</Select.Option>
                                    {departmentRoles.map(role => (
                                        <Select.Option value={role.id!}>{role.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={"Permission level"}
                        name={['level']}
                        rules={[{ required: true }]}
                    >
                        <Select>
                            {Object.values(WikiArticleAccessLevel).map(value => (
                                <Select.Option value={value}>{value}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal>
}