import React, { PropsWithChildren } from "react";
import { Tag } from "antd";
import {useTranslate} from "../../../hooks/translate.hook";
import "./status.scss";

interface StatusProps<Status extends string> {
    status: Status;
    colorsMap: Record<Status, string>;
    prefix?: string;
    large?: boolean;
}

export const StatusTag = <Status extends string>({
    prefix,
    status,
    colorsMap,
    large = false,
}: PropsWithChildren<StatusProps<Status>>) => {
    const t = useTranslate();

    return (
        <Tag
            className={large ? "order-status-large order-status" : "order-status"}
            color={colorsMap[status] || undefined}
        >
            {prefix ? t(prefix + "." + status.toUpperCase()) : status.beautify()}
        </Tag>
    );
};
