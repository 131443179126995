import React from "react";
import {OrderStatus, statusColor} from "../../../stores/common/common.store";
import {StatusTag} from "../../common/status/status";

interface OrderStatusProps {
    status: OrderStatus;
    large?: boolean;
}
export const OrdersStatus: React.FC<OrderStatusProps> = ({status, large = false}) => {
    return (
        <StatusTag<OrderStatus> status={status} colorsMap={statusColor} large={large} prefix="ORDERS.ORDER.STATUS"/>
    )
}
