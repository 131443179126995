import {OrderSplitSegment} from "../../../models/orders/order-split/order.split";
import {TableFooterData} from "../../../models/table-footer/table.footer.data";

export const generateDetails = (segment: OrderSplitSegment): TableFooterData => {
    return {
        qty: segment.qty,
        taxPercent: segment.taxPercent,
        totalWeight: 0,
        taxAmount: segment.taxAmount,
        totalAmount: segment.totalAmount,
        grandTotalAmount: segment.grandTotalAmount
    }
}