import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {WidgetProps} from "../../../../../common/ui/input/widget/widget";
import {propertyService} from "../../../../../../services/property/property.service";
import {PropertyLocation} from "../../../../../../models/property/property";

interface UIInputWidgetPropertyLocationProps extends WidgetProps {
    selectFirstOnLoad?: boolean;
}

export const UIInputWidgetPropertyLocation: React.FC<UIInputWidgetPropertyLocationProps> = ({value, onChange, multiple, selectFirstOnLoad = false}) => {

    const [locations, setLocations] = useState<PropertyLocation[]>([]);

    useEffect(() => {
        propertyService.getAllLocations().then(types => {
            setLocations(types);
            if (onChange && selectFirstOnLoad && undefined === value && types[0]) {
                onChange(types[0].id)
            }
        });
    }, []);

    return (
        <Select
            mode={multiple ? "multiple" : undefined}
            onChange={onChange as any}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
        >
            {locations.map(type =>
                <Select.Option key={String(type.id)} value={type.id}>({type.code}) {type.name}</Select.Option>)}
        </Select>
    )
}