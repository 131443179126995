import { AxiosInstance } from "axios";
import API from "../../../utils/api";
import {OrderComment, OrderData, OrderMeta} from "../../../models/orders/order-data/order.data";
import { OrdersCreateAddValues } from "../../../models/orders/orders-cart/orders-create/orders.create.add.values";
import { Error } from "../../../models/errors/error";
import { ShipmentWithTrackingUrl } from "../../../models/parcels/shipment";
import {MetaResult} from "../../../models/meta-result/meta.result";
import {OrdersUpdateRequest} from "../../../models/orders/order-update/order.update";

export class OrderDataService {
    private api: AxiosInstance = API;

    getOrder(id: string): Promise<MetaResult<OrderData, OrderMeta>> {
        return this.api.get<MetaResult<OrderData, OrderMeta>>("orders/" + id).then((x) => x.data);
    }

    updateOrder(id: number, values: { clientOrderId: string; paymentTerms: string }): Promise<OrderData> {
        return this.api.post<OrderData>("orders/" + id, values).then((x) => x.data);
    }

    updateDate(id: number, date: any): Promise<OrderData> {
        return this.api.post<OrderData>("orders/" + id + "/date", {date}).then((x) => x.data);
    }

    postComment(id: number, values: { message: string }): Promise<OrderComment> {
        return this.api.post<OrderComment>("orders/" + id + "/comments", values).then((x) => x.data);
    }

    deleteComment(id: number): Promise<void> {
        return this.api.delete("orders/comments/" + id);
    }

    syncOrder(id: number): Promise<OrderData> {
        return this.api.post<OrderData>("orders/" + id + "/sync").then((x) => x.data);
    }

    lockExchange(id: number): Promise<OrderData> {
        return this.api.post<OrderData>("orders/" + id + "/lock-exchange").then((x) => x.data);
    }

    unlockExchange(id: number): Promise<OrderData> {
        return this.api.delete<OrderData>("orders/" + id + "/lock-exchange").then((x) => x.data);
    }

    postProcedure(id: number): Promise<OrderData> {
        return this.api.post<OrderData>("orders/" + id + "/post-procedure").then((x) => x.data);
    }

    recalcWeight(orderId: number, values: { weight: string }): Promise<void> {
        return this.api.post("orders/" + orderId + "/recalc-weights", values);
    }

    getWarnings(id: number): Promise<Error[]> {
        return this.api.get<Error[]>("orders/" + id + "/warnings").then((x) => x.data);
    }

    getComments(id: number): Promise<OrderComment[]> {
        return this.api.get<OrderComment[]>("orders/" + id + "/comments").then((x) => x.data);
    }

    duplicateToMissingItems(id: string): Promise<void> {
        return this.api.post("orders/" + id + "/missing-items").then((x) => x.data);
    }

    clearMissingItems(id: string): Promise<void> {
        return this.api.delete("orders/" + id + "/missing-items").then((x) => x.data);
    }

    addOrderItem(id: number, request: OrdersCreateAddValues): Promise<void> {
        return this.api.post("orders/" + id + "/items", request);
    }

    removeOrderItem(id: number): Promise<void> {
        return this.api.delete("orders/items/" + id);
    }

    updateOrderItem(itemId: number | string, request: OrdersUpdateRequest): Promise<void> {
        return this.api.post("orders/items/" + itemId, request);
    }

    updateQty(itemId: number | string, qty: number): Promise<void> {
        return this.api.post("orders/items/" + itemId, {qty: qty});
    }

    updateOrderDiscount(orderId: number, discountPercent: { discountPercent: number }): Promise<void> {
        return this.api.post("orders/" + orderId + "/discount", discountPercent);
    }

    getOrderShipments(id: number): Promise<ShipmentWithTrackingUrl[]> {
        return this.api.get<ShipmentWithTrackingUrl[]>("orders/" + id + "/shipments").then((x) => x.data);
    }
}

export const orderDataService = new OrderDataService();
