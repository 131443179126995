import React, { useState } from "react";
import { Button, Card, Checkbox, Popconfirm, Row, Space, Tooltip, Typography } from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import { AgentDataAddressesModal } from "./agent.data.addresses.modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRequiredStore } from "../../../../utils/store";
import { AgentDataStoreContext } from "../agent.data.store";
import { CustomCard } from "../../../common/custom-card/custom.card";
import { observer } from "mobx-react";
import { CustomerAccountAddress } from "../../../../models/accounts/customer-account/address/customer.account.address";

interface AgentDataAddressCardProps {
    data: CustomerAccountAddress;
    removable: boolean;
    last: boolean;
    changeable?: boolean;
    allowAddOrRemove?: boolean;
}

export const AgentDataAddressCard: React.FC<AgentDataAddressCardProps> = observer(({ data, removable, last, changeable, allowAddOrRemove = changeable = true }) => {
    const t = useTranslate();

    const store = useRequiredStore(AgentDataStoreContext);
    const [isModalShown, setIsModalShown] = useState<boolean>(false);

    const cardRows = [
        { key: "companyName", label: "COMPANY_NAME" },
        { key: "firstName" },
        { key: "lastName" },
        { key: "vat", label: "VAT.TITLE" },
        { key: "customsCode", label: "EORI" },
        { key: "regCode", label: "REG_NUMBER" },
        { key: "country" },
        { key: "region" },
        { key: "city" },
        { key: "address" },
        { key: "index" },
        { key: "phone" },
        { key: "email" },
    ];

    return (
        <>
            <Card
                className={"card"}
                title={<Typography.Text>{data.name}</Typography.Text>}
                extra={changeable || allowAddOrRemove ? (
                    <Space key={0} direction="horizontal" size={[5, 5]} align="center" style={{ width: "auto" }}>
                        {allowAddOrRemove ? (
                            <Tooltip placement="topRight" title={t("ADDRESS_DEFAULT")}>
                                <Checkbox
                                    className="large"
                                    disabled={last}
                                    checked={data.default}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            store.setDefaultAddress(data);
                                        }
                                    }}
                                />
                            </Tooltip>
                        ) : null}
                        {changeable ? (
                            <Tooltip placement="topRight" title={t("EDIT")}>
                                <Button
                                    size={"small"}
                                    className={"card-btn btn"}
                                    onClick={() => setIsModalShown(true)}
                                    type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                                />
                            </Tooltip>
                        ) : null}
                        {allowAddOrRemove ? (
                            <Popconfirm
                                title={t("ARE_YOU_SURE")}
                                onConfirm={() => store.removeAddress(data)}
                                placement="top"
                                okText={t("YES")}
                                cancelText={t("NO")}
                                disabled={removable || data.default}
                            >
                                <Tooltip placement="topRight" title={t("REMOVE")}>
                                    <Button
                                        disabled={removable || data.default}
                                        className={"card-btn btn-remove btn"}
                                        type="default"
                                        size={"small"}
                                        icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                                    />
                                </Tooltip>
                            </Popconfirm>
                        ) : null}
                    </Space>
                ) :null}
            >
                <CustomCard data={{ ...data, country: data.country.name }} rowKeys={cardRows} />
            </Card>
            <AgentDataAddressesModal
                type={data.type}
                visible={isModalShown}
                item={data}
                onSubmit={store.updateAddress}
                onClose={() => setIsModalShown(false)}
            />
        </>
    );
});
