import { Button, Row, Space, Tag, Typography } from "antd";
import React, {useMemo, useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { Link } from "react-router-dom";
import { FieldTypeName } from "../../../../models/filter/filter.field.type.name";
import { FilterFormValueFieldValues } from "../../../../models/filter/filter.form.value";
import { FieldGroupConfig } from "../../../../models/filter/filter.group";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
import { articlesService } from "../../../../services/articles/articles.service";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import Date from "../../../date/date";
import { ArticlesLinks } from "../articles-links/articles.links";
import {ArticlesExportModal} from "../export/articles.export.modal";
import {FilterDataHandler} from "../../../../models/filter/filter.data.handler";
import {articlesListService} from "../../../../services/articles/articles/articles.list.service";
import {ExtendedArticleBranchData} from "../../../../models/articles/article";
import {convertTableColumnsToColumnConfigs} from "../../../../utils/table";
import {ArticlesListActions} from "./actions/articles.list.actions";

interface ArticlesListProps extends ComponentPropsFromRoute {}
export const ArticlesList: React.FC<ArticlesListProps> = ({ name }) => {
    const t = useTranslate();

    const dataHandler = useMemo(() => new FilterDataHandler((request) => articlesListService.getAll(request)), []);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("CODE"),
            dataIndex: "name",
            key: "name",
            render: (name: string) =>
                name.toUpperCase() !== "DELIVERY" ? (
                    <Link style={{ whiteSpace: "nowrap" }} to={"/articles/" + name}>
                        {name}
                    </Link>
                ) : (
                    name
                ),
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "description",
        },
        {
            title: t("ARTICLES.STATUS.IS_CATALOG_HIDDEN"),
            dataIndex: "manuallyHiddenInCatalog",
            key: "manuallyHiddenInCatalog",
            render: (item: boolean) =>
                !item ? (
                    <Tag color={"green"}>{t("ARTICLES.VISIBLE")}</Tag>
                ) : (
                    <Tag color={"red"}>{t("ARTICLES.INVISIBLE")}</Tag>
                ),
        },
        {
            title: t("ARTICLES.STATUS.IS_CLIENT_HIDDEN"),
            key: "manuallyHiddenForClient",
            dataIndex: "branch",
            render: (branchData: ExtendedArticleBranchData) =>
                !branchData.manuallyHiddenForClient ? (
                    <Tag color={"green"}>{t("ARTICLES.VISIBLE")}</Tag>
                ) : (
                    <Tag color={"red"}>{t("ARTICLES.INVISIBLE")}</Tag>
                ),
        },
        {
            title: t("ARTICLES.STATUS.IS_STATISTIC_HIDDEN"),
            key: "statisticHidden",
            dataIndex: "branch",
            render: (branchData: ExtendedArticleBranchData) =>
                !branchData.statisticHidden ? (
                    <Tag color={"green"}>{t("ARTICLES.VISIBLE")}</Tag>
                ) : (
                    <Tag color={"red"}>{t("ARTICLES.INVISIBLE")}</Tag>
                ),
        },
        {
            title: t("ARTICLES.STATUS.IS_TEMPORARY_HIDDEN"),
            dataIndex: "temporaryHidden",
            key: "temporaryHidden",
            render: (item: boolean) =>
                !item ? (
                    <Tag color={"green"}>{t("ARTICLES.VISIBLE")}</Tag>
                ) : (
                    <Tag color={"red"}>{t("ARTICLES.INVISIBLE")}</Tag>
                ),
        },
        {
            title: t("ARTICLES.STATUS.IS_PERMANENTLY_HIDDEN"),
            dataIndex: "permanentlyHidden",
            key: "permanentlyHidden",
            render: (item: boolean) =>
                !item ? (
                    <Tag color={"green"}>{t("ARTICLES.VISIBLE")}</Tag>
                ) : (
                    <Tag color={"red"}>{t("ARTICLES.INVISIBLE")}</Tag>
                ),
        },
        {
            title: t("ARTICLES.STATUS.IS_TECDOC_HIDDEN"),
            dataIndex: "tecDocHidden",
            key: "tecDocHidden",
            render: (item: boolean) =>
                !item ? (
                    <Tag color={"green"}>{t("ARTICLES.VISIBLE")}</Tag>
                ) : (
                    <Tag color={"red"}>{t("ARTICLES.INVISIBLE")}</Tag>
                ),
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (item: string) => <Date dateString={item} showTime={false} />,
        },
    ];

    const filterGroups: FieldGroupConfig[] = [
        {
            name: "id",
            visibleName: "ID",
            fields: [
                {
                    name: "id",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "code",
            visibleName: "CODE",
            fields: [
                {
                    name: "name",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "description",
            visibleName: "ARTICLES.DESCRIPTION",
            fields: [
                {
                    name: "description",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "vehicles",
            visibleName: "VEHICLES",
            fields: [
                {
                    name: "manufacturer",
                    type: FieldTypeName.SELECT,
                    choicesLoader: () => {
                        return articlesService.getManufacturers().then((manufacturers) =>
                            manufacturers.map((item) => ({
                                value: item.id + "",
                                name: item.name,
                            }))
                        );
                    },
                },
                {
                    name: "model",
                    type: FieldTypeName.SELECT,
                    requireFields: ["manufacturer"],
                    choicesLoader: (fieldValues: FilterFormValueFieldValues) => {
                        return articlesService
                            .getManufacturerModels(fieldValues["manufacturer"].values)
                            .then((models) =>
                                models.map((model) => ({
                                    name: model.fullName,
                                    value: model.id + "",
                                }))
                            );
                    },
                },
                {
                    name: "engine",
                    type: FieldTypeName.SELECT,
                    requireFields: ["manufacturer", "model"],
                    choicesLoader: (fieldValues: FilterFormValueFieldValues) => {
                        return articlesService.getSubmodels(fieldValues["model"].values).then((submodels) =>
                            submodels.map((submodel) => ({
                                name: submodel.engineAndPower,
                                value: submodel.id + "",
                            }))
                        );
                    },
                },
            ],
        },
        {
            name: "category",
            visibleName: "CATEGORIES",
            fields: [
                {
                    name: "group",
                    type: FieldTypeName.SELECT,
                    choicesLoader: () => {
                        return articlesService
                            .getGroups()
                            .then((groups) => groups.map((group) => ({ name: group.name, value: group.id + "" })));
                    },
                },
                {
                    name: "subgroup",
                    type: FieldTypeName.SELECT,
                    requireFields: ["group"],
                    choicesLoader: (fieldValues) => {
                        return articlesService
                            .getSubgroups(fieldValues["group"].values)
                            .then((subgroups) =>
                                subgroups.map((subgroup) => ({ name: subgroup.name, value: subgroup.id + "" }))
                            );
                    },
                },
            ],
        },
        {
            name: "oem",
            visibleName: "OEM",
            fields: [
                {
                    name: "oem",
                    type: FieldTypeName.STRING,
                    filterRegExp: /[^a-zA-Z0-9_-]/g,
                },
            ],
        },
        {
            name: "cross",
            visibleName: "ARTICLES.ANALOGUES",
            fields: [
                {
                    name: "cross",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "manuallyHiddenInCatalog",
            visibleName: "ARTICLES.STATUS.IS_CATALOG_HIDDEN",
            fields: [
                {
                    name: "manuallyHiddenInCatalog",
                    type: FieldTypeName.BOOL,
                },
            ],
        },
        {
            name: "manuallyHiddenForClient",
            visibleName: "ARTICLES.STATUS.IS_CLIENT_HIDDEN",
            fields: [
                {
                    name: "manuallyHiddenForClient",
                    type: FieldTypeName.BOOL,
                },
            ],
        },
        {
            name: "statisticHidden",
            visibleName: "ARTICLES.STATUS.IS_STATISTIC_HIDDEN",
            fields: [
                {
                    name: "statisticHidden",
                    type: FieldTypeName.BOOL,
                },
            ],
        },
        {
            name: "temporaryHidden",
            visibleName: "ARTICLES.STATUS.IS_TEMPORARY_HIDDEN",
            fields: [
                {
                    name: "temporaryHidden",
                    type: FieldTypeName.BOOL,
                },
            ],
        },
        {
            name: "permanentlyHidden",
            visibleName: "ARTICLES.STATUS.IS_PERMANENTLY_HIDDEN",
            fields: [
                {
                    name: "permanentlyHidden",
                    type: FieldTypeName.BOOL,
                },
            ],
        },
        {
            name: "tecDocHidden",
            visibleName: "ARTICLES.STATUS.IS_TECDOC_HIDDEN",
            fields: [
                {
                    name: "tecDocHidden",
                    type: FieldTypeName.BOOL,
                },
            ],
        },
        {
            name: "createdAt",
            visibleName: "CREATED_AT",
            fields: [
                {
                    name: "createdAt",
                    type: FieldTypeName.DATE_RANGE,
                },
            ],
        },
    ];

    return (
        <>
            <Space direction="vertical" size={[50, 50]}>
                <ArticlesLinks />
                <Space direction="vertical" size={[0, 0]}>
                    <Row style={{ marginBottom: 10 }} justify="space-between" wrap>
                        <Typography.Title level={3} style={{ margin: 0 }}>
                            {t("ARTICLES.LIST_TITLE")}
                        </Typography.Title>
                        <ArticlesListActions />
                    </Row>
                    <FilteredTable
                        reloadOnLngChange
                        columns={columns}
                        columnsConfigs={{ pageKey: name, columns: convertTableColumnsToColumnConfigs(columns) }}
                        filterGroups={filterGroups}
                        dataHandler={dataHandler}
                    />
                </Space>
            </Space>
        </>
    );
};
