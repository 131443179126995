import React from "react";
import {observer} from "mobx-react";
import {Space, Tag, Typography} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {PageHeader} from "@ant-design/pro-components";

export const MyPageHeader: React.FC = observer(() => {
   const {authStore: {user, group}} = useRequiredStore(RootStoreContext);

   const t = useTranslate();

   return (
       <PageHeader
           style={{padding: 0}}
           ghost={false}
           title={
               <Space direction={"vertical"} size={[0, 0]}>
                  <Typography.Title
                      level={1}
                      style={{margin: 0}}
                  >
                      {user?.firstName + " " + user?.lastName}
                  </Typography.Title>
                   <Typography.Text style={{fontSize: 16, fontWeight: "normal"}}>{user?.email || null}</Typography.Text>
               </Space>
           }
           extra={
              <Tag style={{padding: "5px 10px"}} color={"#1890FF"}>
                  <Space direction={"vertical"} size={[0, 0]}>
                      <span>{t("MY_PAGE.GROUP_DESCRIPTION")}:</span>
                      <span style={{fontSize: 24}}>{group?.name}</span>
                  </Space>
              </Tag>
           }
       >
       </PageHeader>
   )
});
