import {FilterValues} from "../filter/filter.props";

export class PaginatedRequest {
    public page: number = 1;
    public limit: number = 20;
    public filters: FilterValues;
    public extraFields: Record<string, any> = {};

    constructor(defaultFilters?: FilterValues) {
        this.filters = defaultFilters || {}
    }

    clearFilters() {
        this.filters = {};
    }

    addFilter(key: string, value: string|string[]) {
        this.filters[key] = value;
    }

    toParams() {
        let params: any = {
            page: this.page,
            limit: this.limit,
            ...this.extraFields
        };

        for (let prop in this.filters) {
            params[`filter[${prop}]`] = this.filters[prop];
        }

        return params;
    }

    toFilters() {
        let params: any = {};

        for (let prop in this.filters) {
            params[`filter[${prop}]`] = this.filters[prop];
        }
        return params;
    }
}
