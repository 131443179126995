import React, {useEffect, useState} from "react";
import {SlotTableCell, SlotTableColumn, SlotTableData} from "./slot-table.model";
import "./slot-table.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface SlotTableProps {
    getData: () => Promise<SlotTableData>
    getKeyByCoordinateKeys: (X: SlotTableColumn, Y: SlotTableColumn) => string;
    onEmptyCellClick?: (X: SlotTableColumn, Y: SlotTableColumn) => void;
    onCellClick?: (cell: SlotTableCell<any>, X: SlotTableColumn, Y: SlotTableColumn) => void;
    back: () => void;
    next: () => void;
}

export const SlotTable: React.FC<SlotTableProps> = ({getData, getKeyByCoordinateKeys, onEmptyCellClick, onCellClick, back, next}) => {

    const [{colsX, colsY, cells}, setData] = useState<SlotTableData>({colsX: [], colsY: [], cells: {}});

    if (!onEmptyCellClick) {
        onEmptyCellClick = () => null;
    }

    if (!onCellClick) {
        onCellClick = () => null;
    }

    useEffect(() => {
        getData().then(data => setData(data))
    }, [getData]);

    return (
        <table className={"slot-table"} width={"100%"}>
            <thead>
            <tr>
                <th onClick={back}>
                    <FontAwesomeIcon icon={["fas", "arrow-left"]} />
                </th>
                {colsX.map(column => (
                    <th key={column.name}>{column.visibleName}</th>
                ))}
                <th onClick={next}>
                    <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                </th>
            </tr>
            </thead>
            <tbody>
            {colsY.map(colY => (
                        <tr key={colY.name}>
                            <th>{colY.visibleName}</th>
                            {colsX.map(colX => {
                                const cell = cells[getKeyByCoordinateKeys(colX, colY)]
                                if (cell) {
                                    return (
                                        <td
                                            key={colX.name}
                                            onClick={() => onCellClick(cell, colX, colY)}
                                            style={{backgroundColor: cell.color}}
                                        >
                                            <div className={"cell-value"}>
                                                {cell.prefix ? (
                                                    <div className={"cell-value-prefix"} style={{backgroundColor: cell.prefixColor}}>
                                                        {cell.prefix}
                                                    </div>
                                                ) : null}
                                                <div className={"cell-value-basic"}>
                                                    {cell?.visibleValue || ""}
                                                </div>
                                            </div>
                                        </td>
                                    )
                                }
                                return (
                                    <td
                                        key={colX.name}
                                        className={"empty"}
                                        onClick={() => onEmptyCellClick(colX, colY)}
                                    ></td>
                                )
                            })}
                            <th>{colY.visibleName}</th>
                        </tr>
                    ))}
            </tbody>
        </table>
    )
}