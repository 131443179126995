import React, {RefObject, useRef} from "react";
import {observer} from "mobx-react";
import {Card, Checkbox, notification, Row, Typography} from "antd";
import {CustomCard} from "../../../../common/custom-card/custom.card";
import {OrdersCheckoutAddress} from "../../../../../models/orders/orders-cart/orders-checkout/orders.checkout.address";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {NotCompletedOrder} from "../../../../../models/orders/orders-cart/orders-checkout/orders.checkout.not_completed.order";



interface OrdersCheckoutAddressesCardProps {
    order: NotCompletedOrder
    address: OrdersCheckoutAddress,
    onClick: (order: NotCompletedOrder, address: OrdersCheckoutAddress, checked: boolean) => Promise<void>
}
export const OrdersCheckoutAddressesCard: React.FC<OrdersCheckoutAddressesCardProps> = observer(({address, order, onClick}) => {
    const t = useTranslate();
    const checkboxRef = useRef(null) as RefObject<any>

    const cardRows = [
        {key: "companyName", label: "BUSINESS_NAME"},
        {key: "firstName"},
        {key: "lastName"},
        {key: "vat", label: "VAT.TITLE"},
        {key: "customsCode", label: "EORI"},
        {key: "country"},
        {key: "region"},
        {key: "city"},
        {key: "address"},
        {key: "index"},
        {key: "phone"},
        {key: "email"},
    ];


    const openNotification = (type: "success" | "error", message: string): void => {
        notification[type]({
            message: t(type.toUpperCase()),
            description: message
        });
    }


    return (
        <Card
            hoverable
            onClick={async () => {
                await onClick(order, address, !address.checked);
                if (address.type === "shipping") {
                    openNotification("success", t("CART.DATA_UPDATED.SUCCESS"));
                }
            }}
            className={"card"}
            title={
                <Row align={"middle"} justify={"space-between"}>
                    <Typography.Text ellipsis>{address.name || address.city}</Typography.Text>
                    <Checkbox
                        className={"large"}
                        ref={checkboxRef}
                        checked={address.checked}
                    />
                </Row>
            }
        >
            <CustomCard data={{...address, country: address.country.name}} rowKeys={cardRows}/>
        </Card>
    )
})
