import React from "react";
import {action, computed, makeObservable, observable} from "mobx";
import {FilterDataHandler} from "../../../models/filter/filter.data.handler";
import {MissingItem} from "../../../models/missing-items/missing.item";
import {missingItemService} from "../../../services/missing-items/missing.items.service";
import {PaginatedRequest} from "../../../models/core/paginated.request";

export class MissingItemsPartStore {
    public dataHandler: FilterDataHandler<MissingItem>;

    public request = new PaginatedRequest();

    @observable
    public addAllModalShown: boolean = false;

    @observable
    public downloadAllModalShown: boolean = false;

    @observable
    public selectedMissingItems: number[] = [];

    constructor(private account: number, hidden: boolean) {
        makeObservable(this);
        this.addToCartItems = this.addToCartItems.bind(this);
        this.openAddAllToCartModal = this.openAddAllToCartModal.bind(this);
        this.closeAddAllToCart = this.closeAddAllToCart.bind(this);
        this.openDownloadAllModal = this.openDownloadAllModal.bind(this);
        this.closeDownloadAllModal = this.closeDownloadAllModal.bind(this);
        this.hideAllMissingItems = this.hideAllMissingItems.bind(this);
        this.createOrder = this.createOrder.bind(this);
        this.createReservation = this.createReservation.bind(this);
        this.createOrderWithoutReservation = this.createOrderWithoutReservation.bind(this);
        this.createOrderWithFilters = this.createOrderWithFilters.bind(this);
        this.createReservationWithFilters = this.createReservationWithFilters.bind(this);
        this.createOrderWithoutReservationWithFilters = this.createOrderWithoutReservationWithFilters.bind(this);
        this.dataHandler = new FilterDataHandler(request => {
            request.addFilter('hidden', hidden ? '1' : '0')
            return missingItemService.getMissingItems(request, this.account)
        });

    }

    @action
    private setSelectedMissingItems(value: number[]): void {
        this.selectedMissingItems = value;
    }

    @action
    public setAddAllModalShown(value: boolean): void {
        this.addAllModalShown = value;
    }

    @action
    public setDownloadAllModalShown(value: boolean): void {
        this.downloadAllModalShown = value;
    }


    public async hideItem(item: MissingItem): Promise<void> {
        this.dataHandler.setLoading(true);
        await missingItemService.hideItem(item.id, this.account);
        this.dataHandler.removeItem(item);
        this.dataHandler.setLoading(false);
    }

    public async showItem(item: MissingItem): Promise<void> {
        this.dataHandler.setLoading(true);
        await missingItemService.showItem(item.id, this.account);
        this.dataHandler.removeItem(item);
        this.dataHandler.setLoading(false);
    }

    public checkArticles(articles: number[]): void {
        this.setSelectedMissingItems(articles)
    }

    public async addToCartItems(): Promise<void> {
        this.dataHandler.setLoading(true);
        await missingItemService.takeToCart(this.selectedMissingItems, this.account);
        this.setSelectedMissingItems([])
        await this.dataHandler.reloadItems(this.request);
        this.dataHandler.setLoading(false);
    }

    public async createOrder(): Promise<void> {
        this.dataHandler.setLoading(true);
        try {
            await missingItemService.createOrder(this.selectedMissingItems, this.account);
            this.setSelectedMissingItems([])
            await this.dataHandler.reloadItems(this.request);
        }
        finally {
            this.dataHandler.setLoading(false);
        }
    }

    public async createOrderWithoutReservation(): Promise<void> {
        this.dataHandler.setLoading(true);
        try {
            await missingItemService.createOrderWithoutReservation(this.selectedMissingItems, this.account);
            this.setSelectedMissingItems([])
            await this.dataHandler.reloadItems(this.request);
        }
        finally {
            this.dataHandler.setLoading(false);
        }
    }

    public async createReservation(): Promise<void> {
        this.dataHandler.setLoading(true);
        try {
            await missingItemService.createReservation(this.selectedMissingItems, this.account);
            this.setSelectedMissingItems([])
            await this.dataHandler.reloadItems(this.request);
        }
        finally {
            this.dataHandler.setLoading(false);
        }
    }

    public async createOrderWithFilters(): Promise<void> {
        this.dataHandler.setLoading(true);
        try {

            await missingItemService.createOrderWithFilters(this.dataHandler.lastRequest?.toFilters(), this.account);
            this.setSelectedMissingItems([])
            await this.dataHandler.reloadItems(this.request);
        }
        finally {
            this.dataHandler.setLoading(false);
        }
    }

    public async createOrderWithoutReservationWithFilters(): Promise<void> {
        this.dataHandler.setLoading(true);
        try {
            await missingItemService.createOrderWithoutReservationWithFilters(this.dataHandler.lastRequest?.toFilters(), this.account);
            this.setSelectedMissingItems([])
            await this.dataHandler.reloadItems(this.request);
        }
        finally {
            this.dataHandler.setLoading(false);
        }
    }

    public async createReservationWithFilters(): Promise<void> {
        this.dataHandler.setLoading(true);
        try {
            await missingItemService.createReservationWithFilters(this.dataHandler.lastRequest?.toFilters(), this.account);
            this.setSelectedMissingItems([])
            await this.dataHandler.reloadItems(this.request);
        }
        finally {
            this.dataHandler.setLoading(false);
        }
    }

    public openAddAllToCartModal(): void {
        this.setAddAllModalShown(true);
    }

    public closeAddAllToCart(): void {
        this.setAddAllModalShown(false);
    }

    public async addAllToCart(values: {warehouses: number[]}): Promise<void> {
        this.dataHandler.setLoading(true);
        await missingItemService.takeAllToCart(values, this.account);
        this.setAddAllModalShown(false);
        await this.dataHandler.reloadItems(this.request);
        this.dataHandler.setLoading(false);
    }

    public openDownloadAllModal(): void {
        this.setDownloadAllModalShown(true);
    }

    public closeDownloadAllModal(): void {
        this.setDownloadAllModalShown(false);
    }

    public async hideAllMissingItems(): Promise<void> {
        this.dataHandler.setLoading(true);
        await missingItemService.hideAllItems(this.account);
        await this.dataHandler.reloadItems(this.request);
        this.dataHandler.setLoading(false);
    }

    @computed
    public get isDisabled(): boolean {
        return this.selectedMissingItems.length === 0 || this.dataHandler.items.length === 0;
    }


}

export const MissingItemsStoreContext = React.createContext<MissingItemsPartStore | null>(null);