import { observer } from "mobx-react-lite";
import React from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Descriptions, Space, Tag, Typography} from "antd";
import {OrderDataHeader} from "./order-data-header/order.data.header";
import {OrderDataComments} from "../order-data-comments/order-data-comments";
import {PayerSellerReceiverData} from "../../../common/payer-seller-receiver-data/payer.seller.receiver.data";
import {isAllowed} from "../../../../utils/helpers";
import {OrderDataEditItemsTable} from "../order-data-edit-items-table/order.data.edit.items.table";
import {OrderDataDetails} from "./order-data-details/order.data.details";
import {OrderDataHistory} from "../order-data-history/order.data.history";
import {OrderDataLogger} from "../order-data-logger/order.data.logger";
import {useRequiredStore} from "../../../../utils/store";
import {OrderDataStoreContext} from "../order.data.store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {displayPrice} from "../../../../models/prices/price.helper";
import {OrderDataDocuments} from "../order-data-documents/order-data-documents";

interface OrderDataRetailProps {
}

export const OrderDataRetail: React.FC<OrderDataRetailProps> = observer(() => {
    const t = useTranslate();

    const store = useRequiredStore(OrderDataStoreContext);
    const {
        authStore: { attributes }
    } = useRequiredStore(RootStoreContext);

    return (
        <Space direction="vertical" size={[50, 50]}>
            <OrderDataHeader />

            <OrderDataComments />
            <PayerSellerReceiverData
                payer={store.order!.payer}
                receiver={store.order!.receiver}
                seller={store.order!.seller}
                editPayerReceiver={store.editPayerReceiverData}
                editSeller={store.editSellerData}
            />

            <div>

                <Typography.Title level={3} style={store.order?.comments.length ? {marginBottom: 10} : undefined}>{t("ORDER.SHIPPING")}</Typography.Title>
                <Descriptions
                    bordered
                    size={"middle"}
                    column={1}
                >
                    <Descriptions.Item label="Available Warehouses">
                        {Object.keys(store.orderMeta?.wAv || {}).map(key => {
                            return <Tag color={store.orderMeta?.wAv[key].available ? "green": "red"}>{store.orderMeta?.wAv[key].name}</Tag>
                        })}
                    </Descriptions.Item>
                    <Descriptions.Item label="Shipping Costs">
                        {store.orderMeta?.couriers.map(courier => {
                            return <Tag color={"default"}>{courier.name} - {displayPrice(courier)}</Tag>
                        })}
                    </Descriptions.Item>
                </Descriptions>
            </div>

            <OrderDataDocuments order={store.order!} />

            {/*{isAllowed(attributes, "shipment_management") ? <OrderDataShipments /> : null}*/}
            {store.editingOrderItems ? <OrderDataEditItemsTable /> : <OrderDataDetails />}
            {isAllowed(attributes, "logger.list") ? <OrderDataHistory /> : null}
            {isAllowed(attributes, "smartlogger.list") ? <OrderDataLogger /> : null}
        </Space>
    );
});
