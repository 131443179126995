import {ColumnsType} from "antd/es/table";
import React from "react";
import {Translate} from "../../../hooks/translate.hook";
import {OrderSplitSegmentItem} from "../../../models/orders/order-split/order.split";
import {displayPrice} from "../../../models/prices/price.helper";

export const columnsFactory = (t: Translate): ColumnsType<OrderSplitSegmentItem> => {

    return [
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            key: 'articleName',
            render: (_: any, item) => item.article.name
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: 'description',
            key: 'description',
            render: (_: any, item) => item.article.description
        },
        {
            title: t("PRICE"),
            dataIndex: "price",
            key: "price",
            render: (_: any, item) => displayPrice(item.calculatedPrice.amount)
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "qty",
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "totalAmount",
            key: "totalAmount",
            render: (amount) => displayPrice(amount)
        }
    ]
}