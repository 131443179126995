import React, {useMemo} from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../../utils/store";
import {AgentDataStoreContext} from "../../agent.data.store";
import {Button, Space} from "antd";
import FilteredTable from "../../../../common/filtered-table/filtered.table";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/es/table";
import i18n from "i18next";
import {columnsFactory} from "./agent.data.claims.columns";
import {Claim} from "../../../../../models/claims/claims";
import {FilterDataHandler} from "../../../../../models/filter/filter.data.handler";
import {claimService} from "../../../../../services/claims/claims.service";
import {Link} from "react-router-dom";
import {FieldGroupConfig} from "../../../../../models/filter/filter.group";
import {filterGroupsFactory} from "./agent.data.claims.filter-groups";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {ColumnsConfig, convertTableColumnsToColumnConfigs} from "../../../../../utils/table";

export const AgentDataClaims: React.FC<ComponentPropsFromRoute> = observer(({name}) => {
    const {account} = useRequiredStore(AgentDataStoreContext);
    const t = useTranslate();

    const columns: ColumnsType<Claim> = useMemo(() => columnsFactory(t), [i18n.language]);
    const columnsConfigs: ColumnsConfig = useMemo(() => convertTableColumnsToColumnConfigs<Claim>(columns), [i18n.language])
    const fcgConfigs: FieldGroupConfig[] = useMemo(() => filterGroupsFactory(t), []);
    const dataHandler: FilterDataHandler<Claim> = useMemo(() => new FilterDataHandler(request => claimService.getAllByAccount(request, account!.id)), []);

    return (
        <Space direction="vertical" size={[0, 0]}>
            <FilteredTable
                columns={columns}
                columnsConfigs={{ columns: columnsConfigs, pageKey: name }}
                dataHandler={dataHandler}
                filterGroups={fcgConfigs}
                buttons={[
                    <Link to={"/clients/" + account?.agent.number + "/claims/new"}>
                        <Button type={"primary"}>
                            {t("CLAIMS.CREATE.TITLE")}
                        </Button>
                    </Link>
                ]}
            />
        </Space>
    );
});
