import React from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { useRequiredStore } from "../../../../../utils/store";
import { Link } from "react-router-dom";
import { displayPrice } from "../../../../../models/prices/price.helper";
import { ExpandedTable } from "../../../../common/order-details/expanded.table";
import { ProformaDataStoreContext } from "../proforma.data.store";
import { ProformaDetailsItem } from "../../../../../models/documents/proformas/proforma.data";
import { TableFooter } from "../../../../common/table-footer/table.footer";
import { generateDocumentDetails } from "../../../../../models/documents/invoices/invoice-data/invoice.data.helper";
import { Amount } from "../../../../../models/prices/price";
import { isAllowed } from "../../../../../utils/helpers";
import { RootStoreContext } from "../../../../../stores/root/root.store";
import { maxAmount } from "../../../../../utils/validators";
import {nonStrictComparator} from "../../../../ui/table/filter-dropdown/filter-dropdown.comparator";
import {FilterDropdown} from "../../../../ui/table/filter-dropdown/filter-dropdown";
import {ColumnType} from "antd/es/table/interface";

const selectSavedValues = (row: any) => ({ qty: row.qty });
const selectEditValues = (row: any) => ({ qty: row.qty });

export const ProformaDataDetails: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(ProformaDataStoreContext);
    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);

    interface Column extends ColumnType<ProformaDetailsItem> {
        editable?: boolean;
        hint?: string;
        validationRules?: any;
    }

    type Columns = Column[]

    const columns: Columns = [
        {
            title: t("ORDERS.ORDER.TITLE"),
            dataIndex: "order",
            key: "proforma_data_detail_order",
            width: "135px",
            render: (_: any, row: any) => <Link to={"/orders/" + row.order.id}>#{row.order.id}</Link>,
            sorter: (a, b) => a.order.id > b.order.id ? 1 : -1,
            onFilter: nonStrictComparator(v => v.order.id),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "articleName",
            key: "proforma_data_detail_articleName",
            width: "135px",
            render: (article: string) =>
                article.toUpperCase() !== "DELIVERY" ? (
                    <Link to={"/articles/" + article} style={{ whiteSpace: "nowrap" }}>
                        {article}
                    </Link>
                ) : (
                    t("DELIVERY.TITLE")
                ),
            sorter: (a, b) => a.articleName > b.articleName ? 1 : -1,
            onFilter: nonStrictComparator(v => v.articleName),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "proforma_data_detail_articleDescription",
            width: "300px",
        },
        {
            title: t("PRICE"),
            dataIndex: "price",
            key: "proforma_data_detail_price",
            width: "167px",
            render: (item: Amount) => (item ? <>{displayPrice(item)}</> : null),
            sorter: (a, b) => a.price.value > b.price.value ? 1 : -1,
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "proforma_data_detail_quantity",
            width: "125px",
            editable: true,
            hint: t("MAX_AMOUNT_SHORT") + " " + store.stockAmount + " " + t("PCS"),
            validationRules: [
                {
                    validator: maxAmount(store.stockAmount!, t),
                },

                {
                    required: true,
                    message: t("FROM.ERROR.ENTER_VALUE"),
                },
            ],
            // render: (item: string, row: any) => <span>{item}</span>,
            sorter: (a, b) => a.qty > b.qty ? 1 : -1,
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "totalPrice",
            key: "proforma_data_detail_totalPrice",
            width: "140px",
            render: (item: Amount) => (item ? <>{displayPrice(item)}</> : null),
            sorter: (a, b) => a.totalPrice.value > b.totalPrice.value ? 1 : -1,
        },
    ];

    if (store.proformaData?.status === "opened" && isAllowed(attributes, "document_proforma_create")) {
        columns.push({
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
        });
    }

    const columnsConfig = [
        {
            title: "ORDERS.ORDER.TITLE",
            key: "proforma_data_detail_order",
        },
        {
            title: "ARTICLES.TITLE_SINGLE",
            key: "proforma_data_detail_articleName",
        },
        {
            title: "DESCRIPTION",
            key: "proforma_data_detail_articleDescription",
        },
        {
            title: "PRICE",
            key: "proforma_data_detail_price",
        },
        {
            title: "QUANTITY",
            key: "proforma_data_detail_quantity",
        },
        {
            title: "TOTAL_AMOUNT",
            key: "proforma_data_detail_totalPrice",
        },
    ];

    return (
        <ExpandedTable<ProformaDetailsItem>
            title="DETAILS"
            pageKey="proforma_data_detail"
            columns={columns}
            columnsConfig={columnsConfig}
            tableData={store.proformaData!.items}
            tableLoading={store.loading}
            isEditable={isAllowed(attributes, "document_proforma_create") && store.proformaData?.status === "opened"}
            editableRowKey="qty"
            getEditValues={selectEditValues}
            getSaveValues={selectSavedValues}
            onSave={store.updateQty}
            onDelete={store.remove}
            onEdit={store.getArticleAmount}
            tableFooter={<TableFooter data={generateDocumentDetails(store.proformaData! as any)} />}
        />
    );
});
