import React from "react";
import {useTranslate} from "../../../hooks/translate.hook";
import { Link } from "react-router-dom";
import { Space, Typography } from "antd";
import "./quick-links.scss";

export interface QuickLink {
    path: string;
    text: string;
    description: string;
}

interface QuickLinksProps {
    links: QuickLink[];
}

export const QuickLinks: React.FC<QuickLinksProps> = ({ links }) => {
    const t = useTranslate();

    return (
        <Space direction="vertical" size={[0, 0]}>
            <Typography.Title level={3}>{t("QUICK_LINKS.TITLE")}</Typography.Title>
            <Space direction="vertical" size={[0, 0]}>
                {links.map((link) => (
                    <Typography.Paragraph key={link.path}>
                        <Link style={{ textDecoration: "underline" }} to={link.path}>
                            {t(link.text)}
                        </Link>{" "}
                        - {t(link.description)}
                    </Typography.Paragraph>
                ))}
            </Space>
        </Space>
    );
};
