import React from "react";
import {WidgetProps} from "../widget";
import {UIInputWidgetCountry} from "../../../../../ui/input/widget/country/ui.input.widget.country";

export const CountryWidget: React.FC<WidgetProps> = ({onChange, value, multiple}) => {

    return (
        <UIInputWidgetCountry
            multiple={multiple}
            onChange={v => onChange(multiple ? v : [v] as any)}
            value={multiple ? value : value[0] as any}
        />
    )
}