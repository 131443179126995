import React, {useState} from "react";
import {Button, Checkbox, Form, Space} from "antd";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../../utils/store";
import {OrdersCreateStoreContext} from "../../orders.create.store";
import {NumberInput} from "../../../../../common/number-input/number.input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Warehouse} from "../../../../../../models/warehouses/warehouse";
import "./orders.create.edit.form.scss";
import {observer} from "mobx-react";

export const OrdersCreateEditForm: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(OrdersCreateStoreContext);

    const [discountDisabled, setDiscountDisabled] = useState<boolean>(true);
    const [form] = Form.useForm();

    const isItemDisabled = (warehouse: Warehouse): boolean => store.editFormValues.warehouses ? (
        store.editFormValues.warehouses.length === 1 && store.editFormValues.warehouses[0] === warehouse.id
    ) : false;


    return (
        <Form
            size={"middle"}
            form={form}
            layout="horizontal"
            name={"cart_form"}
            initialValues={store.editFormValues}
            onFinish={() => {
                setDiscountDisabled(true);
                form.validateFields()
                    .then(values => {
                        store.updateCart(values);
                    })
            }}
        >
            <table className={"edit-form-table"}>
                <tbody>
                <tr>
                    <td className={"label"}>
                        {t("DISCOUNT_PERCENT")}:
                    </td>
                    <td>
                        <Space direction={"horizontal"} size={[10, 10]}>
                            <Form.Item
                                noStyle
                                style={{marginRight: 10}}
                                name={"discountPercent"}
                            >
                                <NumberInput style={{width: 50}} onKeyDown={event => {
                                    if (event.keyCode === 13) {
                                        event.preventDefault();
                                    }
                                }} disabled={discountDisabled} placeholder={"Discount"}/>
                            </Form.Item>
                            <Form.Item noStyle>
                                <Button
                                    type={discountDisabled ? "primary" : "default"}
                                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                                    onClick={() => {
                                        setDiscountDisabled(!discountDisabled);
                                        if (!discountDisabled) {
                                            form.submit();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Space>
                    </td>
                </tr>
                {store.type === 'regular' ? (
                    <tr>
                        <td className={"label"}>
                            {t("ORDERS.CREATE.CALCULATE_BY")}:
                        </td>
                        <td>
                            <Form.Item name={"warehouses"} noStyle>
                                <Checkbox.Group style={{display: "flex", flexWrap: "wrap"}}>
                                    {store.warehouses.map(warehouse => (
                                        <Checkbox
                                            style={{display: "flex"}}
                                            disabled={isItemDisabled(warehouse)}
                                            className="orders-edit-form-checkbox"
                                            key={warehouse.id}
                                            value={warehouse.id}
                                            onChange={() => form.submit()}
                                        >
                                            <span className="orders-edit-form-warehouse">{warehouse.name}</span>
                                        </Checkbox>
                                    ))}
                                </Checkbox.Group>
                            </Form.Item>
                        </td>
                    </tr>
                ) : null}
                <tr>
                    <td></td>
                    <td>
                        <Form.Item noStyle name={"ignoreRegionalProhibitions"} valuePropName={"checked"}>
                            <Checkbox
                                onChange={() => form.submit()}>{t("ORDER.CREATE.IGNORE_REGIONAL_PROHIBITIONS")}</Checkbox>
                        </Form.Item>
                    </td>
                </tr>
                {store.account.useBackorder ? (
                    <tr>
                        <td></td>
                        <td>
                            <Form.Item noStyle name={"useBackorder"} valuePropName={"checked"}>
                                <Checkbox
                                    onChange={() => form.submit()}>{t("ORDER.CREATE.USE_BACKORDER")}</Checkbox>
                            </Form.Item>
                        </td>
                    </tr>
                ) : null}
                </tbody>
            </table>
        </Form>
    );
})
