import React, { useState } from "react";
import { observer } from "mobx-react";
import { useRequiredStore } from "../../../../../utils/store";
import { AgentDataStoreContext } from "../../agent.data.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { ColumnsType } from "antd/es/table";
import { MergedInvoice } from "../../../../../models/documents/invoices/merged-invoice/merged.invoice";
import { Link } from "react-router-dom";
import { Amount } from "../../../../../models/prices/price";
import { displayPrice } from "../../../../../models/prices/price.helper";
import Date from "../../../../date/date";
import FilteredTable from "../../../../common/filtered-table/filtered.table";
import { ComponentPropsFromRoute } from "../../../../../routers/routers";
import { AgentDataMergedInvoicesStore } from "./agent.data.merged.invoices.store";
import {Alert, Button, Col, DatePicker, Dropdown, Form, Menu, Row, Space, Tooltip} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterKey } from "../../../../../models/filtered-table/filtered.table.props";
import {Export} from "../../../../common/export/export";
import {convertTableColumnsToColumnConfigs} from "../../../../../utils/table";
import {TableRowSelection} from "antd/lib/table/interface";
import {PDFModal} from "../pdf-modal/pdf.modal";
import dayjs from "dayjs";

interface AgentDataMergedInvoicesProps extends ComponentPropsFromRoute {}
export const AgentDataMergedInvoices: React.FC<AgentDataMergedInvoicesProps> =
    observer(({ name }) => {
        const agentDataStore = useRequiredStore(AgentDataStoreContext);
        const [store] = useState(
            () =>
                new AgentDataMergedInvoicesStore(
                    agentDataStore.account!.id
                )
        );
        const t = useTranslate();

        const [exportModalShown, setExportModalShown] = useState<boolean>(false);
        const [exportAsPDFModalShown, setExportAsPDFModalShown] = useState<boolean>(false);

        const [selectedDocs, setSelectedDocs] = useState<any[]>([]);


        const columns: ColumnsType<MergedInvoice> = [
            {
                title: t("INVOICES.NUMBER"),
                dataIndex: "docNumber",
                key: "docNumber",
                width: "180px",
                render: (number: string, item: MergedInvoice) => <Link to={"/merged-invoices/" + item.id}>{number}</Link>,
            },
            {
                title: t("QUANTITY"),
                dataIndex: "qty",
                key: "qty",
            },
            {
                title: t("TOTAL_AMOUNT"),
                dataIndex: "grandTotal",
                key: "grandTotal",
                render: (amount: Amount) => (
                    <span style={{ fontWeight: "bold" }}>
                        {displayPrice(amount)}
                    </span>
                ),
            },
            {
                title: t("TOTAL_PRICE"),
                dataIndex: "subtotal",
                key: "subtotal",
                render: (amount: Amount) => (
                    <span style={{ fontWeight: "bold" }}>
                        {displayPrice(amount)}
                    </span>
                ),
            },
            {
                title: "VAT",
                dataIndex: "subtotalVat",
                key: "subtotalVat",
                render: (amount: Amount) => (
                    <span style={{ fontWeight: "bold" }}>
                        {displayPrice(amount)}
                    </span>
                ),
            },
            {
                title: t("DATE"),
                dataIndex: "date",
                key: "date",
                render: (date: string) => <Date dateString={date} showTime />,
            },
            {
                title: t("CREATED_AT"),
                dataIndex: "createdAt",
                key: "createdAt",
                render: (date: string) => <Date dateString={date} showTime />,
            },
            {
                title: t("ACTION"),
                key: "action",
                className: "table-button-cell",
                width: 50,
                render: (_: any, item) => (
                    <Space direction={"horizontal"}>
                        <Tooltip placement="topRight" title={t("DOWNLOAD.DO_DOWNLOAD")}>
                            <a href={"/documents/" + item.id} target={"_blank"}>
                                <Button
                                    className="table-btn btn-remove btn"
                                    type="default"
                                    icon={
                                        <FontAwesomeIcon
                                            icon={["fas", "file-pdf"]}
                                        />
                                    }
                                />
                            </a>
                        </Tooltip>
                    </Space>
                ),
            },
        ];

        const filters: FilterKey[] = [
            {
                key: "id",
                label: "ID",
                type: "string",
            },
            {
                key: "number",
                label: t("INVOICES.NUMBER"),
                type: "string",
            },
            {
                type: "int",
                label: t("QUANTITY"),
                key: "qty",
            },
            {
                key: "grandTotal",
                label: t("TOTAL_AMOUNT"),
                type: "int",
            },
            {
                key: "subtotal",
                label: t("TOTAL_PRICE"),
                type: "int",
            },
            {
                key: "subtotalVat",
                label: "VAT",
                type: "int",
            },
            {
                key: "date",
                label: t("DATE"),
                type: "date",
            },
            {
                key: "createdAt",
                label: t("CREATED_AT"),
                type: "date",
            },
        ];

        const exportDefaultValues = {
            date: [dayjs().add(-1, "M"), dayjs()]
        };

        const rowSelection: TableRowSelection<MergedInvoice> = {
            preserveSelectedRowKeys: true,
            type: "checkbox",
            selectedRowKeys: selectedDocs,
            onChange: (selectedRowKeys: React.Key[], selectedRows: MergedInvoice[]) => {
                setSelectedDocs(selectedRowKeys)
            },
        };

        return (
            <Space direction="vertical" size={[10, 10]}>
                <Row justify={"end"}>
                    <Dropdown
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                <Menu.Item
                                    key={0}
                                    onClick={() => setExportModalShown(true)}
                                >
                                    {t('DOWNLOAD.DO_DOWNLOAD')}
                                </Menu.Item>
                                <Menu.Item
                                    key={1}
                                    onClick={() => setExportAsPDFModalShown(true)}
                                >
                                    {t('DOCUMENTS.EXPORT.AS_PDF')}
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button type={"primary"}>
                            {t("ACTION")}
                        </Button>
                    </Dropdown>
                </Row>

                <Alert
                    message={t("INVOICES.MERGED_INVOICE.LENGTH") + ": " + selectedDocs.length}
                    type="info"
                />

                {exportAsPDFModalShown ? (
                    <PDFModal
                        onClose={() => setExportAsPDFModalShown(false)}
                        accountId={agentDataStore.account!.id}
                        selectedDocs={selectedDocs}
                        type={"merged_invoice"}
                        />
                ) : null}
                <Export
                    title={"DOWNLOAD.DO_DOWNLOAD"}
                    withBasicExportFields
                    isShown={exportModalShown}
                    config={{
                        link: "/api/v3/documents/merged-invoices/account/" + agentDataStore.account?.id,
                    }}
                    additionalDefaultValues={exportDefaultValues}
                    onClose={() => setExportModalShown(false)}
                >
                    <Col span={24}>
                        <Form.Item
                            label={t("DATE")}
                            name="date"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "FROM.ERROR.PLEASE_SELECT_VALUE"
                                    ),
                                },
                            ]}
                        >
                            <DatePicker.RangePicker
                                showNow
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Export>
                <FilteredTable
                    columns={columns}
                    columnsConfigs={{ columns: convertTableColumnsToColumnConfigs(columns), pageKey: name }}
                    filterKeys={filters}
                    dataHandler={store.dataHandler}
                    rowSelection={rowSelection}
                />
            </Space>
        );
    });
