import React, {useMemo} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {SmartTable} from "../../../../common/smart-table/smart-table";
import {SmartTableDataHandler} from "../../../../common/smart-table/smart-table.data-handler";
import {propertyService} from "../../../../../services/property/property.service";
import {columnFactory} from "./changes-list.property.view.columns";
import {Property, PropertyDocumentItem} from "../../../../../models/property/property";
import {downloadBlobFile} from "../../../../../utils/blob.download";

interface ReviewListPropertyViewProps {
    property: Property;
}

export const ChangesListPropertyView: React.FC<ReviewListPropertyViewProps> = ({property}) => {

    const t = useTranslate();

    const handler = useMemo(() => new SmartTableDataHandler(r => propertyService.getDocumentsForProperty(property.id, r)), []);
    const columns = useMemo(() => columnFactory(t), [t])

    return (
        <SmartTable
            handler={handler}
            columns={columns}
            actions={[
                {
                    icon: "download",
                    title: t("DOWNLOAD"),
                    onClick: async (item: PropertyDocumentItem) => {
                        const {blob, filename} = await propertyService.downloadQtyChangePDF(item.document.id);
                        downloadBlobFile(blob, filename);
                    }
                }
            ]}
        />
    )
}