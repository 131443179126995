import {AxiosInstance} from "axios";
import API from "../../../utils/api";

export class SenderService {
    private api: AxiosInstance = API;

    send(id: number): Promise<void> {
        return this.api
            .post('documents/' + id + '/send-to-client');
    }
}

export const senderService = new SenderService();