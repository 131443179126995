import {action, makeObservable, observable} from "mobx";
import {errorsService} from "../../services/errors/errors.service";
import {RouteConfig} from "../../routers/routers";
import {ErrorsList} from "./list/errors.list";

export class ErrorsStore {
    @observable
    public loading: boolean = false;

    @observable
    public groups: string[] = [];

    constructor() {
        makeObservable(this);
        this.init();
    }

    @action
    private setLoading(value: boolean): void {
        this.loading = value;
    }

    @action
    private setGroups(value: string[]): void {
        this.groups = value;
    }

    private async init() {
        this.setLoading(true);
        this.setGroups(await errorsService.getGroups());
        this.updateRouters();
        this.setLoading(false);
    }

    private updateRouters() {
        const routes: RouteConfig[] = this.groups.map((group, index) => ({
            systemName: "ERRORS." + group.toUpperCase() + ".TITLE",
            path: index === 0 ? "/errors" : "/errors/" + group,
            component: ErrorsList,
            exact: true,
            // access?: string;
            name: "errors-" + group,
            configData: {group},
        }));
    }
}
