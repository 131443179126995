import {Translate} from "../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {Link} from "react-router-dom";
import {ContainerItem} from "../../../../../models/containers/containers";
import {Article} from "../../../../../models/articles/article";

export const columnsFactory = (t: Translate): ColumnsType<ContainerItem> => {
    return [
        {
            title: t("ARTICLES.NAME"),
            dataIndex: "article",
            key: "article",
            render: (article: Article) => <Link to={"/articles/" + article.name}>{article.name}</Link>
        },
        {
            title: t("QTY"),
            dataIndex: "qty",
            key: "qty"
        },
        {
            title: t("RESERVED_QTY"),
            dataIndex: "reservedQty",
            key: "reservedQty"
        },
        {
            title: t("BOXES_QTY"),
            dataIndex: "boxesQty",
            key: "boxesQty"
        }
    ];
}