import React, { useState } from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Button, Col, DatePicker, Form, Input, Row, Select, Spin} from "antd";
import { AxiosError } from "axios";
import { ErrorResponseData } from "../../../../../models/core/response";
import { useRequiredStore } from "../../../../../utils/store";
import { RootStoreContext } from "../../../../../stores/root/root.store";
import { BranchDataWarehouseStoreContext } from "./branch.data.warehouse.store";
import dayjs from "dayjs";

export const BranchDataWarehouseForm: React.FC = observer(() => {
    const {
        langStore: { countries },
    } = useRequiredStore(RootStoreContext);
    const store = useRequiredStore(BranchDataWarehouseStoreContext);
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    if (!countries.length) {
        return <Spin />;
    }
    const defaultValues = {
        ...store.warehouse,
        branch: store.warehouse?.branch.id || "fed",
        country: store.warehouse?.country?.id,
        lockedUntil: store.warehouse?.lockedUntil ? dayjs(store.warehouse?.lockedUntil) : null
    };

    const submit = store.warehouse ? store.editWarehouse : store.createWarehouse;

    return (
        <Form
            form={form}
            layout="vertical"
            name="edit_branch_form"
            initialValues={defaultValues}
            onFinish={() =>
                form.validateFields().then((values) => {
                    if (values.lockedUntil) {
                        values.lockedUntil = values.lockedUntil.format();
                    }
                    setLoading(true);
                    submit(values)
                        .then(
                            () => {
                                return;
                            },
                            (e: AxiosError<ErrorResponseData>) => {
                                setLoading(false);
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            }
                        )
                        .then(() => (e: any) => form.setFields(e.response?.data?.fields))
                        .finally(() => setLoading(false));
                })
            }
        >
            <Row gutter={[10, 0]} align={"middle"}>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item name="id" label={t("ID")}>
                        <Input disabled={!!store.warehouse} type="text" placeholder="ID" />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="name"
                        label={t("NAME")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input disabled={loading} type="text" placeholder={t("NAME")} />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="branch"
                        label={t("BRANCH.TITLE")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp={"children"}
                            placeholder={t("COUNTRY")}
                            disabled={loading}
                        >
                            {store.branches.map((branch) => (
                                <Select.Option key={branch.id as any} value={branch.id as any}>
                                    {branch.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="country"
                        label={t("COUNTRY")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp={"children"}
                            placeholder={t("COUNTRY")}
                            disabled={loading}
                        >
                            {countries.map((country) => (
                                <Select.Option value={country.id!} key={country.id!}>
                                    {country.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="ocName"
                        label={t("1C.NAME")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input type="text" placeholder={t("1C.NAME")} disabled={loading} />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="ocCode"
                        label={t("1C.CODE")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input type="text" placeholder={t("1C.CODE")} disabled={loading} />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="ocOrderCode"
                        label={t("1C.CODE_SALES")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input type="text" placeholder={t("1C.CODE_SALES")} disabled={loading} />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="type"
                        label={t("TYPE")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp={"children"}
                            placeholder={t("TYPE")}
                            disabled={loading}
                        >
                            {["retail", "customs"].map((item) => (
                                <Select.Option key={item} value={item}>
                                    {item.beautify()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="status"
                        label={t("STATUS")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp={"children"}
                            placeholder={t("STATUS")}
                            disabled={loading}
                        >
                            {["active", "inactive"].map((item) => (
                                <Select.Option key={item} value={item}>
                                    {item.beautify()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[10, 0]} align={"middle"}>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                            name="lockedUntil"
                            label={t("WAREHOUSE.LOCKED_UNTIL")}
                    >
                        <DatePicker allowClear showTime />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[10, 0]} align={"middle"}>
                <Col span={24}>
                    <Button disabled={loading} type="primary" htmlType="submit">
                        {t("SAVE")}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
});
