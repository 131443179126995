import React, {useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {AgentDataStoreContext} from "../agent.data.store";
import {Col, Form, Input, Modal, Row, Select} from "antd";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {CustomerAccountAgreement} from "../../../../models/accounts/customer-account/agreement/customer.account.agreement";

interface AgentDataAgreementsModalProps {
    agreement?: CustomerAccountAgreement,
    visible: boolean,
    onClose: () => void,
    onSubmit: (values: CustomerAccountAgreement, id: number) => Promise<any>
}

export const AgentDataAgreementsModal: React.FC<AgentDataAgreementsModalProps> = ({agreement, visible, onClose, onSubmit}) => {

    const t = useTranslate();
    const store = useRequiredStore(AgentDataStoreContext);

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    let defaultValues: any | undefined;

    if (agreement) {
        defaultValues = {...agreement}
    }


    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={agreement ? t("AGREEMENT.EDIT") : t("AGREEMENT.ADD")}
            okText={agreement ? t("EDIT") : t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"agreements_form"}
                initialValues={defaultValues}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        onSubmit(values, agreement ? agreement.id : store.account!.id)
                            .then(() => {
                                !agreement && form.resetFields();
                                return onClose();
                            }, (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                            .then(() =>
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    {!agreement ? (
                        <Col span={24}>
                            <Form.Item
                                name="category"
                                label={t("AGREEMENT.TYPE")}
                                rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                            >
                                <Select placeholder={t("AGREEMENT.TYPE")}>
                                    {store.agreementTypes.map(type => (
                                        <Select.Option key={type} value={type}>{t("AGREEMENT." + type.toUpperCase())}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    ) : null}
                    <Col span={24}>
                        <Form.Item
                            name="content"
                            label={t("AGREEMENT.TEXT")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input.TextArea placeholder={t("AGREEMENT.TEXT")}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
