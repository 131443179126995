import {StringMap} from "i18next";
import {notification} from "antd";
import {Translate} from "../../hooks/translate.hook";

export class Notifications
{
    private t?: Translate;

    init(t: Translate) {
        this.t = t;
    }

    public successfully(): void
    {
        this.message("success", "Completed successfully");
    }

    /**
     *
     * @param type
     * @param message
     * @param options
     */
    public message(type: "success" | "error", message: string, options: StringMap = {}) {
        this.t && notification[type]({message: this.t(message, options)})
    }
}

export const notifications = new Notifications();