import {Translate} from "../../../hooks/translate.hook";
import {SmartColumn} from "../../common/smart-table/smart-table.model";
import {DeclarationProvider} from "../upload/declaration.upload.model";
import {FieldType} from "../../common/smart-table/smart-table.field-config";
import {CountryWidget} from "../../common/smart-table/filter/dropdown/widget/custom/country.widget";

export const columnsFactory = (t: Translate): SmartColumn<DeclarationProvider>[] => {
    return [
        {
            visibleName: "ID",
            dataIndex: "id",
            name: "id",
            type: FieldType.INTEGER,
            filterable: true
        },
        {
            visibleName: t("PROVIDERS.CODE"),
            dataIndex: "code",
            name: "code",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: t("NAME"),
            dataIndex: "name",
            name: "name",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: t("AGREEMENTS.DISPATCH_PLACE"),
            dataIndex: "dispatchFrom",
            name: "dispatchFrom",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: t("DECLARATION.PROVIDER.TRANSPORT_TYPE"),
            dataIndex: "transportType",
            name: "transportType",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: t("ADDRESS"),
            dataIndex: "address",
            name: "address",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: t("CITY"),
            dataIndex: "city",
            name: "city",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: t("INDEX"),
            dataIndex: "zip",
            name: "zip",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: t("COUNTRY_CODE"),
            dataIndex: ["country", "id"],
            name: "country",
            type: FieldType.STRING,
            widget: CountryWidget,
            filterable: true
        }
    ]
}