import { Button, Space, Typography } from "antd";
import { observer } from "mobx-react";
import React, { useState } from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { Link } from "react-router-dom";
import { FieldTypeName } from "../../../../models/filter/filter.field.type.name";
import { FieldGroupConfig } from "../../../../models/filter/filter.group";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
import { RootStoreContext } from "../../../../stores/root/root.store";
import { useRequiredStore } from "../../../../utils/store";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import Date from "../../../date/date";
import { UserModal } from "../user-modal/user.modal";
import { UsersListStore } from "./users.list.store";
import {useNavigate} from "react-router";

interface IAccesses {
    group: {
        id: number;
        name: string;
    };
    system: {
        id: string;
        name: string;
    };
}

interface UsersListProps extends ComponentPropsFromRoute {}
export const UsersList: React.FC<UsersListProps> = observer(({ name }) => {
    const {
        commonStore: { systemGroups, systems },
    } = useRequiredStore(RootStoreContext);
    const navigate = useNavigate();

    const [store] = useState(() => new UsersListStore(navigate));
    const t = useTranslate();

    const [modalShown, setModalShown] = useState<boolean>(false);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (id: number) => <Link to={"/users/" + id}>{id}</Link>,
        },
        {
            title: t("FIRSTNAME"),
            dataIndex: "firstName",
            key: "firstName",
        },
        {
            title: t("LASTNAME"),
            dataIndex: "lastName",
            key: "lastName",
        },
        {
            title: t("EMAIL"),
            dataIndex: "email",
            key: "email",
        },
        {
            title: t("GROUP.TITLE"),
            dataIndex: "accesses",
            key: "accesses",
            render: (accesses: IAccesses[]) => {
                return accesses.length && accesses ? (
                    <>{accesses.map((item) => `${item.system.name} (${item.group.name})`).join(", ")}</>
                ) : (
                    "-"
                );
            },
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (dateString: string) => {
                return <Date dateString={dateString} showTime={false} />;
            },
        },
    ];

    const columnsConfigs = [
        {
            title: "ID",
            key: "id",
        },
        {
            title: "FIRSTNAME",
            key: "firstName",
        },
        {
            title: "LASTNAME",
            key: "lastName",
        },
        {
            title: "EMAIL",
            key: "email",
        },
        {
            title: "GROUP.TITLE",
            key: "accesses",
        },
        {
            title: "CREATED_AT",
            key: "createdAt",
        },
    ];

    const filterGroups: FieldGroupConfig[] = [
        {
            name: "id",
            visibleName: "ID",
            fields: [
                {
                    name: "id",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "firstName",
            visibleName: "FIRSTNAME",
            fields: [
                {
                    name: "firstName",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "lastName",
            visibleName: "LASTNAME",
            fields: [
                {
                    name: "lastName",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "email",
            visibleName: "EMAIL",
            fields: [
                {
                    name: "email",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "createdAt",
            visibleName: "CREATED_AT",
            fields: [
                {
                    name: "createdAt",
                    type: FieldTypeName.DATE_RANGE,
                },
            ],
        },
        {
            name: "systems",
            visibleName: "SYSTEM",
            fields: [
                {
                    name: "systems",
                    type: FieldTypeName.SELECT,
                    multiple: true,
                    choices: systems.map((system) => ({
                        name: system.name,
                        value: system.id,
                    })),
                },
            ],
        },
        {
            name: "groups",
            visibleName: "GROUP.TITLE",
            fields: [
                {
                    name: "groups",
                    type: FieldTypeName.GROUPED_SELECT,
                    multiple: true,
                    choices: systemGroups.map((item) => ({
                        groupName: item.name,
                        choices: item.groups.map((group) => ({ name: group.name, value: String(group.id) })),
                    })),
                },
            ],
        },
    ];

    return (
        <>
            <Space direction={"vertical"} size={[50, 50]}>
                <Space direction={"vertical"} size={[0, 0]}>
                    <Typography.Title level={3}>{t("USERS.SETTINGS.TITLE")}</Typography.Title>
                    <Space direction={"horizontal"} size={[10, 10]}>
                        <Link to="/users/groups">
                            <Button type={"primary"}>
                                {t("USERS.GROUPS.TITLE")}
                            </Button>
                        </Link>
                        <Link to="/users/accesses">
                            <Button type={"primary"}>
                                {t("USERS.ACCESSES.TITLE")}
                            </Button>
                        </Link>
                        <Link to="/users/groups-assign-rule">
                            <Button type={"primary"}>
                                {t("USERS.ASSIGN_RULES.TITLE")}
                            </Button>
                        </Link>
                        <Button type="primary" onClick={() => setModalShown(true)}>
                            {t("USERS.CREATE")}
                        </Button>
                    </Space>
                </Space>
                <Space direction={"vertical"} size={[0, 0]}>
                    <Typography.Title level={3}>{t("USERS.LIST.TITLE")}</Typography.Title>
                    <FilteredTable
                        columns={columns}
                        columnsConfigs={{ columns: columnsConfigs, pageKey: name }}
                        filterGroups={filterGroups}
                        dataHandler={store.dataHandler}
                    />
                </Space>
            </Space>
            <UserModal visible={modalShown} onClose={() => setModalShown(false)} onSubmit={store.createUser} />
        </>
    );
});
