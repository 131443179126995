import {InvoiceData} from "../../invoice-data/invoice.data";
import {Amount} from "../../../../prices/price";

export interface MergedInvoiceIncludedInvoicePackingList {
    id: number,
    docNumber: string,
    date: string
}

export interface MergedInvoiceIncludedInvoice {
    id: number
    docNumber: string
    dispatched: boolean
    grandTotal: Amount
    invoiceCreatedAt: string
    subtotal: Amount
    subtotalVat: Amount
    totalGrossWeight: string
    totalNetWeight: string,
    packingListDocument: MergedInvoiceIncludedInvoicePackingList | null
}

export interface MergedInvoiceIncludedCreditInvoice {
    id: number
    docNumber: string
    grandTotal: Amount
    subtotal: Amount
    subtotalVat: Amount
}

export enum MergedInvoiceStatus {
    OPENED = "opened",
    CLOSED = "closed"
}

export interface MergedInvoiceData extends InvoiceData {
    date: string;
    invoices: MergedInvoiceIncludedInvoice[]
    creditInvoices: MergedInvoiceIncludedCreditInvoice[]
    // status: MergedInvoiceStatus
    customTotalGrossWeight: string;
}
