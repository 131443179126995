import {SlotTableCell, SlotTableColumn} from "../../common/slot-table/slot-table.model";
import dayjs from "dayjs";
import {ParsedQuery} from "query-string";
import {Slot} from "../../../models/calendar/slot";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

export type RangeType = [start: dayjs.Dayjs|undefined|null, end: dayjs.Dayjs|undefined|null];

export enum SlotType {
    CLIENT = 'client',
    EVENT = 'event'
}

export enum SlotStatus {
     FREE = 'free',
     NOT_CONFIRMED = 'not_confirmed',
     CONFIRMED = 'confirmed'
}

const slotStatusColor: Record<SlotStatus, string> = {
    free: '#e9fde3',
    not_confirmed: '#fdf7e3',
    confirmed: '#fde3e3'
}

export const generateColX = ([start, end]: RangeType): SlotTableColumn[] => {
    const cols: SlotTableColumn[] = [];
    if (!start || !end) {
        return cols;
    }

    let day = start
    while(day.isBefore(end, 'day') || day.isSame(end, 'day')) {
        cols.push({visibleName: day.format('DD.MM'), name: day.format('YYYY-MM-DD')})
        day = day.add(1, 'day')
    }

    return cols
}

export const generateColY = (): SlotTableColumn[] => {
    const cols: SlotTableColumn[] = [];

    let day = dayjs().set('hour', 7).set('minute', 0);
    for (let i = 0; i < 25; i++) {
        cols.push({visibleName: day.format('HH:mm'), name: day.format('HH:mm')})
        day = day.add(30, 'minutes')
    }

    return cols
}

export const getKeyByCoordinateKeys = (colX: SlotTableColumn, colY: SlotTableColumn): string => {
    return colX.name + 'T' + colY.name
}

export const handleRangeDate = ([start, end]: RangeType): RangeType => {
    if (end && start && end.diff(start, 'day') > 10) {
        end = start.add(10, 'day')
    }

    return [start, end]
}

export const getValuesFromURL = (p: ParsedQuery): RangeType => {
    if (typeof p.start === "string" && typeof p.end === "string") {
        return handleRangeDate([
            dayjs(p.start),
            dayjs(p.end)
        ])
    }

    return [
        dayjs(),
        dayjs().add(10, 'day')
    ]
}

export const getValuesFromRange = ([start, end]: RangeType): any => {
    return {
        start: start?.format('YYYY-MM-DD') || null,
        end: end?.format('YYYY-MM-DD') || null
    }
}

export const generateCellsForSlots = (slots: Slot[]): Record<string, SlotTableCell<Slot>> => {
    const cells: Record<string, SlotTableCell<Slot>> = {}

    slots.forEach(slot => {
        const start = dayjs.tz(slot.from, dayjs.tz.guess());
        const end = dayjs.tz(slot.until, dayjs.tz.guess());
        cells[dayjs(start).format('YYYY-MM-DDTHH:mm')] = {
            value: slot,
            visibleValue: start.format('HH:mm') + ' - ' + end.format('HH:mm'),
            color: slotStatusColor[slot.status],
            prefix: slot.type === 'event' ? 'E' : 'C',
            prefixColor: '#bccfff'
        }
    })

    return cells
}

export const getNext = ([start, end]: RangeType): RangeType => {
    return [
        start?.add(1, 'week'),
        end?.add(1, 'week')
    ]
}

export const getBack = ([start, end]: RangeType): RangeType => {
    return [
        start?.add(-1, 'week'),
        end?.add(-1, 'week')
    ]
}