import React, {useState} from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {Button, Col, Form, Input, Modal, Row, Select, Tooltip, Typography} from "antd";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {renderSortButtons} from "../../../../models/users/user/user.data.helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BranchDataDepartmentsStoreContext} from "./branch.data.departments.store";
import {DepartmentType} from "../../../../models/departments/department";

export const BranchDataDepartmentsModal: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(BranchDataDepartmentsStoreContext);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    //const isSelectOptionDisabled = (id: string) => form.getFieldValue("positions").map((item: WarehouseWithSort) => item.warehouse).includes(id);

    const onSubmit = store.departmentEditItem ? store.updateDepartment : store.createDepartment;

    const defaultValues = store.departmentEditItem ? {
        ...store.departmentEditItem,
        parent: store.departmentEditItem.parent?.id,
        roles:  store.departmentEditItem.roles ? [...store.departmentEditItem.roles]
            .slice()
            .sort((a, b) => a.position! - b.position!)
             : [{id: null, position: 0, name: ""}]
    } : {roles: [{id: null, position: 0, name: ""}]};

    return (
        <Modal
            visible={store.departmentsModalShown}
            confirmLoading={loading}
            title={
                store.departmentEditItem ? t("BRANCH.DATA.DEPARTMENTS.EDIT") : t("BRANCH.DATA.DEPARTMENTS.ADD")
            }
            okText={store.departmentEditItem ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                store.closeDepartmentModal();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"address_form"}
                initialValues={defaultValues}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        onSubmit(values)
                            .then(
                                () => {
                                    !store.departmentEditItem && form.resetFields();
                                    return store.closeDepartmentModal();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(
                                            e.response?.data?.fields
                                        );
                                    }
                                }
                            )
                            .then(
                                () =>
                                    // onClose(),
                                    (e: any) =>
                                        form.setFields(e.response?.data?.fields)
                            )
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label={t("NAME")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                type="text"
                                placeholder={t("NAME")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="type"
                            label={t("TYPE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Select
                                disabled={loading}
                                placeholder={t("TYPE")}
                            >
                                {Object.values(DepartmentType).map(value => (
                                    <Select.Option value={value}>{value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="parent"
                            label={t("DEPARTMENT.PARENT")}
                        >
                            <Select
                                showSearch
                                allowClear
                                loading={loading}
                                optionFilterProp={"children"}
                                placeholder={t("DEPARTMENT.PARENT")}
                            >
                                {store.departments.map(department => (
                                    <Select.Option key={department.id} value={department.id}>
                                        {department.name.replace(/—/g, "")}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {store.departmentEditItem ? (
                        <Col span={24}>
                            <Typography.Paragraph style={{marginBottom: 8}}>{t("ROLES.TITLE")}</Typography.Paragraph>
                            <Form.List
                                name="roles"
                            >
                                {(fields, {add, remove}, {errors}) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Row justify={"space-between"} gutter={5} key={field.key}>
                                                {renderSortButtons(form, index, fields.length, loading, "roles", "roles")}
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "id"]}
                                                    
                                                    style={{display: "none"}}
                                                >
                                                    <Input/>
                                                </Form.Item>
                                                <Col span={18}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, "name"]}
                                                        
                                                        rules={[{
                                                            required: true,
                                                            message: t('FROM.ERROR.PLEASE_INPUT_VALUE')
                                                        }]}
                                                    >
                                                        <Input type="text" placeholder={t("DEPARTMENTS.ROLE.TITLE")} disabled={loading}/>

                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <Tooltip placement="topRight" title={t('REMOVE')}>
                                                        <Button
                                                            type="default"
                                                            danger
                                                            onClick={() => remove(field.name)}
                                                            icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                                                            style={{width: "100%"}}
                                                            className="btn-flex-center"
                                                            disabled={loading}
                                                        />
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                disabled={loading}
                                                onClick={() => {
                                                    add();
                                                }}
                                                block
                                                icon={<FontAwesomeIcon icon={["fas", "plus-circle"]}/>}
                                            >
                                                {t("ADD")}
                                            </Button>
                                            <Form.ErrorList errors={errors}/>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    ) : null}
                </Row>
            </Form>
        </Modal>
    );
})
