import React, {useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Modal, Tabs} from "antd";
import {ConnectByUserTab} from "./tabs/connect.by-user.tab";
import {ConnectByAgentTab} from "./tabs/connect.by-agent.tab";
interface UserDataConnectModalProps {
    visible: boolean,
    onClose: () => void,
}

export const UserDataConnectModal: React.FC<UserDataConnectModalProps> = ({visible, onClose}) => {
    const t = useTranslate();
    const [onSubmit, setOnSubmit] = useState<() => void>();

    return (
        <Modal
            visible={visible}
            title={t("USER.PROFILE.CONNECT_TITLE")}
            okText={t("USER.PROFILE.CONNECT")}
            cancelText={t("CANCEL")}
            onOk={onSubmit}
            onCancel={onClose}
            maskClosable={false}
        >
            <Tabs destroyInactiveTabPane={true}>
                <Tabs.TabPane tab={"By Agent"} key={1}>
                    <ConnectByAgentTab setOnSubmit={setOnSubmit} onClose={onClose} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={"By User"} key={2}>
                    <ConnectByUserTab setOnSubmit={setOnSubmit} onClose={onClose} />
                </Tabs.TabPane>
            </Tabs>

        </Modal>
    );
};
