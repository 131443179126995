import React, {useState} from "react";
import {observer} from "mobx-react";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../../models/core/response";
import {UserAssignRulesStore} from "../user.assign.rules.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Col, Form, Modal, Row, Select} from "antd";
import {Group, SystemGroup} from "../../../../../models/users/group/group";
import {AssignRule, AssignRuleRequest} from "../../../../../models/users/assign-rule/assign.rule";
import {useRequiredStore} from "../../../../../utils/store";
import {RootStoreContext} from "../../../../../stores/root/root.store";

interface AssignRulesModalProps {
    item?: AssignRule
    visible: boolean,
    onClose: () => void,
    onSuccess: (request: AssignRuleRequest, item?: AssignRule) => Promise<void>
}
export const AssignRulesModal: React.FC<AssignRulesModalProps> = observer(({visible, item, onClose, onSuccess}) => {
    const {commonStore: {systemGroups}} = useRequiredStore(RootStoreContext);
    const t = useTranslate();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={t(item ? "USERS.ASSIGN_RULES.EDIT" : "USERS.ASSIGN_RULES.CREATE")}
            okText={t(item ? "SAVE" : "ADD")}
            cancelText={t("CANCEL")}

            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                size={"middle"}
                form={form}
                layout="vertical"
                name={"edit_user_form"}
                initialValues={item ? {group: item.group.id, assignerGroup: item.assignerGroup.id} : undefined}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        onSuccess(values, item)
                            .then(
                                () => {
                                    !item && form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                            .then(() => {}, (e) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={[10, 0]} align={"middle"}>
                    <Col span={24}>
                        <Form.Item
                            name={"group"}
                            label={t("USERS.ASSIGNED_GROUP")}
                            rules={[{
                                required: true,
                                message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                            }]}
                        >
                            <Select disabled={loading} placeholder={t("GROUP.TITLE")}>
                                {systemGroups.map((item: SystemGroup, index) => (
                                    <Select.OptGroup key={item.id + item.name + index} label={item.name}>
                                        {item.groups.map(({id, name}: Group, index: number) => (
                                            <Select.Option key={id} value={id}>{name}</Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"assignerGroup"}
                            label={t("USERS.ASSIGNING_GROUP")}
                            rules={[{
                                required: true,
                                message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                            }]}
                        >
                            <Select disabled={loading} placeholder={t("GROUP.TITLE")}>
                                {systemGroups.map((item: SystemGroup) => (
                                    <Select.OptGroup key={item.id} label={item.name}>
                                        {item.groups.map(({id, name}: Group, index: number) => (
                                            <Select.Option key={id} value={id}>{name}</Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
});
