"use strict";

export class MdRedactor {

    fileUploader;
    onChange;
    mdt; // mdTextarea
    history = [];

    constructor(parentNode, fileUploader, onChange) {
        if (parentNode === null || !parentNode) {
            console.error("no parent node ");
        }
        this.mdt = parentNode;
        this.fileUploader = fileUploader;
        this.onChange = onChange;

        this.activateKeyListener();
        this.activatePasteEvents();
        this.setTextAreaLineHeight();
    }

    setTextAreaLineHeight(){
        let style = window.getComputedStyle(this.mdt);
        this.mdt.lineHeight = parseInt(style.getPropertyValue('line-height').replace("px", ""));
        this.mdt.boxSizing = style.getPropertyValue('box-sizing');
        this.mdt.paddingTop = parseInt(style.getPropertyValue('padding-top').replace("px", ""));
        this.mdt.paddingBottom = parseInt(style.getPropertyValue('padding-bottom').replace("px", ""));
        this.mdt.borderTop = parseInt(style.getPropertyValue('border-top-width').replace("px", ""));
        this.mdt.borderBottom = parseInt(style.getPropertyValue('border-bottom-width').replace("px", ""));
        this.mdt.topPadding = (this.mdt.boxSizing === "border-box" ? this.mdt.borderTop+this.mdt.paddingTop : this.mdt.paddingTop);
    }

    setHeader = (parameter) => {
        let sharp = "";
        parameter = parseInt(parameter);
        while (parameter > 0) {
            sharp += "#";
            parameter--;
        }

        this.command(sharp + " ", "");
    }

    setBold = () => {
        this.command("**", "**");
    }

    setItalic = () => {
        this.command("_", "_");
    }

    setQuote = () => {
        this.command("\n> ", "");
    }

    setCode = () => {
        this.command("\n\n``` \n", " \n```\n\n");
    }

    setNumericList = () => {
        const selection = this.getSelection();

        let list = selection.selection.split("\n");
        for (let i = 0; i < list.length; i++) {
            list[i] = "1. " + list[i].trim()
        }

        this.insertText(selection, list.join(" \n"));
    }

    setSymbolicList = () => {
        const selection = this.getSelection();

        let list = selection.selection.split("\n");
        for (let i = 0; i < list.length; i++) {
            list[i] = "- " + list[i].trim()
        }

        this.insertText(selection, list.join(" \n"));
    }

    setLink = () => {
        const selection = this.getSelection();

        let text = selection.selection;

        if (text.length === 0) {
            text = "text";
        }

        this.insertText(selection, "[" + text + "](url)");
    }

    setTable = () => {
        const selection = this.getSelection();

        let text = "| header | header |\n"
            + "| ------ | ------ |\n"
            + "| cell | cell |\n"
            + "| cell | cell |";

        this.insertText(selection, "\n\n" + text + "\n\n");
    }

    convertToTable = () => {
        const selection = this.getSelection();

        let text = "";
        let lines = selection.selection.split(/[\r\n]+/);

        for(let i = 0; i < lines.length; i++){
        
            let cols = lines[i].split("\t")
            text = text+"| "+cols.join(" | ")+" |\n"
            
            if(i === 0) {
                let dashes = [];
                
                for(let ii = 0; ii < cols.length; ii++){
                    dashes.push("-");
                }
                
                text = text+"| "+dashes.join(" | ")+" |\n"
            }
        }

        this.insertText(selection, "\n" + text + "\n");
    }

    setInfoBlock = () => {
        this.command("\n!> ", "");
    }

    setQuestionBlock = () => {
        this.command("\n?> ", "");
    }

    setErrorBlock = () => {
        this.command("\nx> ", "");
    }

    setFile = (fileName, filePath) => {
        const selection = this.getSelection();

        let text = selection.selection;

        if (text.length === 0) {
            text = fileName;
        }

        this.insertText(selection, "![" + text + "](" + filePath + ")");
    }

    setTab() {
        this.command("    ", "");
    }


    historyAdd(record) {
        this.history.push(record);
    }

    historyAddRecord(selection, text) {

        let record = {
            s: selection.s,
            f: selection.s + text.length,
            content: selection.selection,
            selection: ""
        }

        this.historyAdd(record);
    }

    historyUndo() {
        if (this.history.length > 0) {
            let data = this.history.pop();
            this.insertText(data, data.content, true)
        }
    }

    getSelection() {
        const start = this.mdt.selectionStart;
        const finish = this.mdt.selectionEnd;
        const selection = this.mdt.value.substring(start, finish);

        return {s: start, f: finish, selection: selection}
    }

    setCaretPosition(pos) {
        this.mdt.focus()
        this.mdt.setSelectionRange(pos, pos)
    }

    insertText(selection, text, undo = false) {
        const scrollTop = this.mdt.scrollTop;
        const leftSide = this.mdt.value.substring(0, selection.s);
        const rightSide = this.mdt.value.substring(selection.f, this.mdt.value.length);
        if (!undo) {
            this.historyAddRecord(selection, text);
        }

        const val = leftSide + text + rightSide;
        this.mdt.value = this.clearNewLines(val);
        this.setCaretPosition(selection.s + text.length);
        this.returnCaretToViewPort(scrollTop);
        this.onChange(this.mdt.value);
    }

    delete(type) {
        let selection = this.getSelection();

        if (type === "deleteContentBackward" && selection.s === selection.f) { // backspace
            selection.s = selection.s - 1;
            selection.selection = this.mdt.value.substring(selection.s, selection.f);
        } else if (type === "deleteContentForward" && selection.s === selection.f) { // delete
            selection.f = selection.f + 1;
            selection.selection = this.mdt.value.substring(selection.s, selection.f);
        }

        this.insertText(selection, "");
    }

    command(leftSide, rightSide) {
        const selection = this.getSelection();
        this.insertText(selection, leftSide + selection.selection + rightSide)
    }

    correctHeight(){
        this.mdt.style.height = "5px";
        this.mdt.style.height = this.mdt.scrollHeight+"px";
    }

    returnCaretToViewPort(scrollTop){
        let val = this.mdt.value;
        let lines = val.split("\n")
        let linesCount = lines.length;
        let selection = this.getSelection();
        let caretLinePosition = 0;
        let symbolsCount = 0;
        for(let i = 0; i < linesCount; i++){
            if(symbolsCount <= selection.f && (symbolsCount+lines[i].length) >= selection.f){
                caretLinePosition = i+1;
                break;
            }
            symbolsCount = symbolsCount+lines[i].length+1;
        }

        let offsetHeight = this.mdt.offsetHeight;
        let caretOffset = (caretLinePosition*this.mdt.lineHeight)+this.mdt.topPadding;
        if(scrollTop < (caretOffset-this.mdt.lineHeight) && caretOffset < (offsetHeight+scrollTop)) {
            this.mdt.scrollTop = scrollTop;
        } else if(caretOffset > (offsetHeight+scrollTop)){
            this.mdt.scrollTop = (caretOffset+3)-(offsetHeight);
        } else if(scrollTop >= (caretOffset-this.mdt.lineHeight)){
            this.mdt.scrollTop = (caretOffset-(this.mdt.lineHeight+3));
        }
    }

    activateKeyListener() {
        this.mdt.addEventListener("keydown", (event) => {
            let code = event.code;
            let key = event.key;
            let ctrl = event.ctrlKey || event.metaKey;
            let alt = event.altKey;

            if (event.key === "Tab") {
                event.preventDefault();
                this.setTab();
            } else if (ctrl && key === "z") {
                event.preventDefault();
                this.historyUndo();
            } else if (ctrl && key === "b") {
                event.preventDefault();
                this.setBold();
            } else if (ctrl && key === "i") {
                event.preventDefault();
                this.setItalic();
            } else if (ctrl && key === "u") {
                console.log("underline");
            } else if (ctrl && key === "l") {
                event.preventDefault();
                this.setLink();
            } else if (ctrl && alt && code === "KeyT") {
                event.preventDefault();
                this.convertToTable();
            } else if (ctrl && -1 !== ["1", "2", "3", "4", "5", "6"].indexOf(key)) {
                event.preventDefault();
                this.setHeader(key);
            } 
        });

        this.mdt.addEventListener("beforeinput", (event) => {
            event.preventDefault();
            if (event.data !== null) {
                this.insertText(this.getSelection(), event.data);
            } else if (event.inputType === "insertLineBreak") {
                this.insertText(this.getSelection(), "\n", false, false);
            } else if (event.inputType === "deleteContentBackward") { // backspace
                this.delete("deleteContentBackward");
            } else if (event.inputType === "deleteContentForward") { // delete
                this.delete("deleteContentForward");
            } else{
                // event.preventDefault();
            }
        });
    }

    activatePasteEvents() {
        this.mdt.addEventListener('paste', (event) => {
            const data = event.clipboardData;
            const file = data && data.files && data.files[0];

            if (file !== undefined) {
                event.preventDefault();
                this.fileUploader(file).then(
                    ([fileName, filePath]) => {
                        this.setFile(fileName, filePath);
                    }
                )
            }
        });

        this.mdt.addEventListener('drop', (event) => {
            event.preventDefault();
            const file = event.dataTransfer.files
            if (file !== undefined && file.length > 0 && file[0] !== undefined) {
                event.preventDefault();
                this.fileUploader(file[0]).then(
                    ([fileName, filePath]) => {
                        this.setFile(fileName, filePath);
                    }
                )
            }
        });
    }

    clearNewLines(text) {
        return text.replace('/(\n\r|\r\n)/g', '\n');
    }

}