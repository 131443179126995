import React, {useState} from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {AgentDataStoreContext} from "../agent.data.store";
import {Button, Col, Row, Space, Tooltip, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AgentDataAgreementsCard} from "./agent.data.agreements.card";
import {AgentDataAgreementsModal} from "./agent.data.agreements.modal";

export const AgentDataAgreements: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(AgentDataStoreContext);
    const [isModalShown, setIsModalShown] = useState<boolean>(false);

    return (
        <Space size={[0, 0]} direction={"vertical"}>
            <Row>
                <Typography.Title level={3}>{t("AGREEMENT.TITLE")}</Typography.Title>
                <Tooltip placement="topLeft" title={t("ADD")}>
                    <Button
                        className="btn"
                        style={{margin: "3px 0 0 5px"}}
                        onClick={() => setIsModalShown(true)}
                        type="default"
                        icon={<FontAwesomeIcon icon={["fas", "plus"]}/>}
                    />
                </Tooltip>
            </Row>
            <Row gutter={[30, 30]}>
                <Col className={"card-col"} xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <AgentDataAgreementsCard onAdd={() => setIsModalShown(true)}/>
                </Col>
            </Row>
            <AgentDataAgreementsModal
                visible={isModalShown}
                onClose={() => setIsModalShown(false)}
                onSubmit={store.createAgreement}
            />
        </Space>
    );
});
