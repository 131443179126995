import {PaginatedResult} from "../../../models/core/paginated.result";
import {AssignRule, AssignRuleRequest} from "../../../models/users/assign-rule/assign.rule";
import {AxiosInstance} from "axios";
import API from "../../../utils/api";
import {PaginatedRequest} from "../../../models/core/paginated.request";

export class AssignRulesService {
    api: AxiosInstance = API;

    getRules(request: PaginatedRequest): Promise<PaginatedResult<AssignRule>> {
        return this.api.get<PaginatedResult<AssignRule>>(
            "access/groups/assign-rules",
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }

    updateRule(request: AssignRuleRequest, id: number): Promise<AssignRule> {
        return this.api.post<AssignRule>("access/groups/assign-rules/" + id, request).then(x => x.data);
    }
    createRule(request: AssignRuleRequest): Promise<AssignRule> {
        return this.api.post<AssignRule>("access/groups/assign-rules", request).then(x => x.data);
    }
    removeRule(id: number): Promise<void> {
        return this.api.delete("access/groups/assign-rules/" + id);
    }
}

export const assignRulesService = new AssignRulesService();
