import React, {useEffect, useState} from "react";
import {WidgetProps} from "../widget";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {priceListsService} from "../../../../../../services/price-lists/price-lists.service";
import {PriceList} from "../../../../../../models/price-lists/price.lists";
import {Select} from "antd";

export const UIInputWidgetPrice: React.FC<WidgetProps> = ({value, onChange, multiple}) => {

    const t = useTranslate();

    const [prices, setPrices] = useState<PriceList[]>([]);

    useEffect(() => {
        priceListsService.getPriceLists().then(prices => setPrices(prices))
    }, [])

    return (
        <Select
            mode={multiple ? "multiple" : undefined}
            onChange={onChange as any}
            value={value as any}
            style={{width: '250px'}}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
        >
            {prices.map(price =>
                <Select.Option key={price.id} value={price.id}>{price.name}</Select.Option>)}
        </Select>
    )
}