import { AxiosInstance } from "axios";
import API from "../../utils/api";

import { PaginatedResult } from "../../models/core/paginated.result";
import { WarehouseExpanded } from "../../models/warehouses/warehouses";
import { PaginatedRequest } from "../../models/core/paginated.request";
import { Warehouse } from "../../models/warehouses/warehouse";

export class WarehousesService {
    api: AxiosInstance = API;

    getAll(request: PaginatedRequest): Promise<PaginatedResult<WarehouseExpanded>> {
        return this.api
            .get<PaginatedResult<WarehouseExpanded>>("warehouses", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }

    getAllWarehouses(): Promise<Warehouse[]> {
        return this.api.get<Warehouse[]>("warehouses/all").then((x) => x.data);
    }

    getAllGroupedWarehouses(warehouseId: string): Promise<Warehouse[]> {
        return this.api.get<Warehouse[]>("warehouses/" + warehouseId + "/all").then((x) => x.data);
    }

    getAllGroupedWarehousesForAccount(accountId: number): Promise<Warehouse[]> {
        return this.api.get<Warehouse[]>("warehouses/account/" + accountId + "/all").then((x) => x.data);
    }

    getBranchWarehouse(branchId: string, id: string): Promise<WarehouseExpanded> {
        return this.api.get<WarehouseExpanded>("branches/" + branchId + "/warehouses/" + id).then((x) => x.data);
    }

    updateBranchWarehouse(branchId: string, id: string, values: any): Promise<WarehouseExpanded> {
        return this.api
            .put<WarehouseExpanded>("branches/" + branchId + "/warehouses/" + id, values)
            .then((x) => x.data);
    }

    deleteBranchWarehouse(branchId: string, id: string): Promise<void> {
        return this.api.post<void>("branches/" + branchId + "/warehouses/" + id).then((x) => x.data);
    }

    createBranchWarehouse(branchId: string, values: any): Promise<WarehouseExpanded> {
        return this.api.post<WarehouseExpanded>("branches/" + branchId + "/warehouses", values).then((x) => x.data);
    }
}

export const warehousesService = new WarehousesService();
