import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Select } from "antd";
import { find } from "lodash";
import React, { RefObject, useContext, useEffect, useRef, useState } from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { FieldStateGroup } from "../../../../../models/filter/filter.field.state";
import {
    createFieldStateGroup,
    createFormValues,
    FilterFormValue,
} from "../../../../../models/filter/filter.form.value";
import { FieldConfig, FieldConfigChoices, FieldGroupConfig } from "../../../../../models/filter/filter.group";
import { SelectedFilters } from "./selected-filters/selected.filters";
import "./filter.popup.scss";
import { FilterContext } from "../filter";

export interface FieldsProps {
    popupRef: RefObject<HTMLDivElement>;
    config: FieldConfig;
}

interface SelectedFilterProps {
    configs: FieldGroupConfig[];
    editingStateGroup: FieldStateGroup | undefined;
    onCompleted: (group: FieldStateGroup) => void;
    close: () => void;
    removeEditingTag: () => void;
}

const FilterPopup: React.FC<SelectedFilterProps> = ({
    configs,
    editingStateGroup,
    onCompleted,
    removeEditingTag,
    close,
}) => {
    const context = useContext(FilterContext);
    const [choices, setChoices] = useState<Record<string, FieldConfigChoices>>({});
    const [form] = Form.useForm();

    const t = useTranslate();
    const popupRef = useRef() as RefObject<HTMLDivElement>;

    useEffect(() => {
        if (editingStateGroup) {
            form.setFieldsValue(createFormValues(editingStateGroup));
        }
    }, [editingStateGroup]);

    useEffect(() => {
        const closePopupHandler = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!popupRef?.current?.contains(target)) {
                closePopup();
            }
        };

        document.addEventListener("mousedown", closePopupHandler);

        return () => {
            document.removeEventListener("mousedown", closePopupHandler);
        };
    }, []);

    const onClose = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        closePopup();
    };

    const closePopup = (): void => {
        form.resetFields();
        removeEditingTag();
        close();
    };

    const onSubmit = (values: FilterFormValue): void => {
        const fieldStateGroup = createFieldStateGroup(find(configs, { name: values.key })!, values.fields);
        onCompleted(fieldStateGroup);
        context.setChoices({ ...context.choices, ...choices });
    };

    return (
        <div className="filter-popup" ref={popupRef}>
            <Form layout={"inline"} form={form} onFinish={onSubmit} initialValues={{ queryType: "%" }}>
                <Form.Item className="filterKeySelect" name="key" noStyle>
                    <Select
                        defaultOpen={!editingStateGroup}
                        autoFocus={!editingStateGroup}
                        placeholder={t("FILTER.SELECT_FIELD")}
                        getPopupContainer={() => popupRef.current as HTMLElement}
                        dropdownMatchSelectWidth={180}
                    >
                        {configs.map((config) => (
                            <Select.Option key={config.name} value={config.name}>
                                {t(config.visibleName)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    style={{ width: "100%" }}
                    noStyle
                    shouldUpdate={(prevValue, curValue) => prevValue.key !== curValue.key}
                >
                    {({ getFieldValue }) => {
                        const config = find(configs, { name: getFieldValue("key") });
                        if (config) {
                            return (
                                <SelectedFilters
                                    form={form}
                                    popupRef={popupRef}
                                    config={config}
                                    choices={choices}
                                    setChoices={setChoices}
                                />
                            );
                        }
                        return null;
                    }}
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prevValue, curValue) => prevValue.key !== curValue.key}>
                    {({ getFieldValue }) =>
                        getFieldValue("key") ? (
                            <Form.Item className="filterSubmitButton">
                                <Button
                                    style={{ width: "100%", marginLeft: 3 }}
                                    htmlType="submit"
                                    type="primary"
                                    icon={<FontAwesomeIcon icon={["fas", "check"]} />}
                                />
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>
                <Form.Item className="filterCloseButton">
                    <Button
                        style={{ width: "100%" }}
                        danger
                        icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                        onClick={onClose}
                    />
                </Form.Item>
            </Form>
        </div>
    );
};

export default FilterPopup;
