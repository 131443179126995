import {SmartColumn} from "../../../../common/smart-table/smart-table.model";
import {PropertyInventoryTaskPropertyReview} from "../../../../../models/property/property";
import {Translate} from "../../../../../hooks/translate.hook";
import {FieldType} from "../../../../common/smart-table/smart-table.field-config";
import {
    InventoryTaskReviewStatus
} from "../../../inventory-tasks/common/inventory-task-review-status/inventory-task-review.status";

export const columnFactory = (t: Translate): SmartColumn<PropertyInventoryTaskPropertyReview>[] => {
    return [
        {
            name: 'year',
            visibleName: t('YEAR'),
            type: FieldType.INTEGER,
            render: (_, r) => r.task.year
        },
        {
            name: 'status',
            visibleName: t('STATUS'),
            type: FieldType.STRING,
            render: v => <InventoryTaskReviewStatus status={v} />
        },
        {
            name: 'qty',
            visibleName: t('QTY'),
            type: FieldType.INTEGER,
        },
        {
            name: 'comment',
            visibleName: t('COMMENT'),
            type: FieldType.STRING,
            render: c => c || '(none)'
        },
        {
            name: 'user',
            visibleName: t('USER.TITLE'),
            type: FieldType.STRING,
            render: u => u.firstName + ' ' + u.lastName
        },
        {
            name: 'createdAt',
            visibleName: t('DATE'),
            type: FieldType.DATETIME,
        },
    ]
}