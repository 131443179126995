import React, { useState } from "react";
import { observer } from "mobx-react";
import { AgentDataIntegration } from "../../../../../models/agents/agent-data/agent-data-integrations/agent.data.integration";
import { Button, Card, message, notification, Popconfirm, Space, Tooltip, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomCard } from "../../../../common/custom-card/custom.card";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { AgentDataIntegrationsModal } from "./agent.data.integrations.modal";
import { useRequiredStore } from "../../../../../utils/store";
import { AgentDataStoreContext } from "../../agent.data.store";

interface AgentDataIntegrationsCardProps {
    integration: AgentDataIntegration;
}

export const AgentDataIntegrationsCard: React.FC<AgentDataIntegrationsCardProps> = observer(({ integration }) => {
    const store = useRequiredStore(AgentDataStoreContext);
    const t = useTranslate();
    const [isModalShown, setIsModalShown] = useState<boolean>(false);

    const cardRows = [
        { key: "type" },
        {
            key: "lastUploadedAt",
            label: t("CLIENT.INTEGRATIONS.LAST_UPLOADED_AT"),
            isDate: true,
        },
        {
            key: "scheduledUploadAt",
            label: t("CLIENT.INTEGRATIONS.SCHEDULED_UPLOADED_AT"),
            isDate: true,
        },
    ];

    const onUploadBtnClick = async () => {
        message.info(t("UPLOAD.BEGINS"), 1.5);
        try {
            await store.uploadIntegrationData(integration);
            notification.success({
                message: t("SUCCESS"),
                description: t("CLIENT.INTEGRATIONS.UPLOAD.SUCCESS"),
            });
        } catch (e: any) {}
    };
    return (
        <>
            <Card
                className="card"
                title={
                    <Typography.Title title={integration.name} ellipsis level={5}>
                        {integration.name}
                    </Typography.Title>
                }
                extra={
                    <Space direction="horizontal" size={[5, 5]}>
                        <Tooltip placement="topRight" title={t("UPLOAD.TITLE")}>
                            <Button
                                className={"card-btn btn"}
                                onClick={onUploadBtnClick}
                                type="default"
                                size={"small"}
                                icon={<FontAwesomeIcon icon={["fas", "upload"]} />}
                            />
                        </Tooltip>
                        <Tooltip placement="topRight" title={t("EDIT")}>
                            <Button
                                className={"card-btn btn"}
                                onClick={() => setIsModalShown(true)}
                                type="default"
                                size={"small"}
                                icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                            />
                        </Tooltip>
                        <Popconfirm
                            title={t("ARE_YOU_SURE")}
                            onConfirm={() => store.removeIntegration(integration)}
                            placement="top"
                            okText={t("YES")}
                            cancelText={t("NO")}
                        >
                            <Tooltip placement="topRight" title={t("REMOVE")}>
                                <Button
                                    size={"small"}
                                    className={"card-btn btn-remove btn"}
                                    type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                                />
                            </Tooltip>
                        </Popconfirm>
                    </Space>
                }
            >
                <CustomCard data={integration} rowKeys={cardRows} />
            </Card>
            <AgentDataIntegrationsModal
                defaultFileLangueage={store.account?.language}
                item={integration}
                visible={isModalShown}
                warehouses={store.account!.warehouses}
                accountId={store.account!.id}
                onSubmit={store.updateIntegration}
                onClose={() => setIsModalShown(false)}
                testConnection={store.testIntegrationConnection}
            />
        </>
    );
});
