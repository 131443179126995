import dayjs from "dayjs";

export const createTodayRange = (): [dayjs.Dayjs, dayjs.Dayjs] => {
    return [
        dayjs(),
        dayjs()
    ];
}

export const createThisMonthRange = (): [dayjs.Dayjs, dayjs.Dayjs] => {
    return [
        dayjs().startOf('month'),
        dayjs().endOf('month')
    ];
}

export const createPreviousMonthRange = (): [dayjs.Dayjs, dayjs.Dayjs] => {
    return [
        dayjs().subtract(1, 'month').startOf('month'),
        dayjs().subtract(1, 'month').endOf('month')
    ];
}

export const createYesterday = (): dayjs.Dayjs => {
    return dayjs().subtract(1, 'day');
}

export const createWeekAgo = (): dayjs.Dayjs => {
    return dayjs().subtract(1, 'week');
}