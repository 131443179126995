import React, {PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Tabs, Input, Space} from "antd";
import {TextAreaRef} from "antd/es/input/TextArea";
import {MarkdownViewer} from "../markdown-viewer/markdown-viewer";
import {MdRedactor} from "../markdown-editor/markdown-editor";
import {MarkdownTextareaCommands} from "./markdown-textarea-commands";
import {Link} from "react-router-dom";
import {useTranslate} from "../../../../hooks/translate.hook";

const { TabPane } = Tabs;

type FileUploader = (file: File) => Promise<[string, string]>

interface MarkdownTextareaProps {
    value?: string
    onChange?: (value: any) => any
    fileUploader: FileUploader
    disabled?: boolean,
    height?: string;
}

export const MarkdownTextarea: React.FC<PropsWithChildren<MarkdownTextareaProps>> = ({value, onChange, fileUploader, disabled, height = "500px"}) => {

    const t = useTranslate();

    const ref = useRef<TextAreaRef>(null);

    const [editor, setEditor] = useState<MdRedactor|null>(null);

    const [allowRendering, setAllowRendering] = useState<boolean>(false);

    const commands = useMemo(() => ({right: <MarkdownTextareaCommands editor={editor!} />}), [editor]);

    const onTabChange = useCallback((activeTab: string) => {
        setAllowRendering(activeTab === 'view')
    }, []);

    useEffect(() => {
        if (ref.current?.resizableTextArea) {
            setEditor(new MdRedactor(ref.current.resizableTextArea.textArea, fileUploader, onChange));
        }
    }, [ref])

    return (
        <Space direction={"vertical"}>
            <Tabs onChange={onTabChange} defaultActiveKey="edit" tabBarExtraContent={commands}>
                <TabPane tab={"Write"} key={"edit"}>
                    {/*<Progress percent={30} />*/}
                    <Input.TextArea ref={ref} value={value} disabled={disabled} onChange={e => onChange!(e.target.value)} onResize={() => editor?.setTextAreaLineHeight()} style={{height: height}} />
                </TabPane>
                <TabPane tab={"Preview"} key={"view"}>
                    {allowRendering ?
                        <MarkdownViewer>{value}</MarkdownViewer> : null
                    }
                </TabPane>
            </Tabs>
            <Link target={"_blank"} to={"https://www.markdownguide.org/basic-syntax/"}>
                {t("COMMON.MARKDOWN_SYNTAX_LINK")}
            </Link>
        </Space>

    )
}