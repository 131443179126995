import React, {useEffect, useState} from "react";
import {WidgetProps} from "../../../../../../../common/cards-view/model/model";
import {Select, Tag} from "antd";
import {PlanerTag} from "../../../../../../../../models/departments/planer_tags";
import {plannerService} from "../../../../../../../../services/planner/planner.service";

export const TaskTagsWidget: React.FC<WidgetProps> = ({onChange, value, placeholder}) => {

    const [tags, setTags] = useState<PlanerTag[]>([]);

    useEffect(() => {
        plannerService.getDepartmentAvailableTags().then(tags => setTags(tags));
    }, [])

    return (
        <Select
            mode={"multiple"}
            onChange={onChange as any}
            value={(value || []).map(v => parseInt(v as string))}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
            placeholder={placeholder}
            maxTagCount={2}
            variant={"filled"}
        >
            {tags.map((tag, key) => (
                <Select.Option value={tag.id} key={key}>
                    <Tag color={tag.color}>{tag.name}</Tag>
                </Select.Option>
            ))}
        </Select>
    )
}