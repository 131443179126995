import React, {useState} from "react";
import {observer} from "mobx-react";
import {Button, Row, Space, Spin, Table, Tabs} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Link} from "react-router-dom";
import {useRequiredStore} from "../../../../../utils/store";
import {UserGroupsStoreContext} from "../user.groups.store";
import {UsersGroupsModal} from "./users.groups.modal";
import {RootStoreContext} from "../../../../../stores/root/root.store";

export const UsersGroupsList: React.FC = observer(() => {
    const t = useTranslate();
    const {commonStore: {systemGroups}} = useRequiredStore(RootStoreContext);
    const store = useRequiredStore(UserGroupsStoreContext);
    const [modalShown, setModalShown] = useState<boolean>(false);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "50px",
            render: (id: string) => <Link to={"/users/groups/" + id}>{id}</Link>
        },
        {
            title: t("NAME"),
            dataIndex: "name",
            key: "name",
        }
    ];

    if (!systemGroups.length) {
        return (
            <Space direction={"horizontal"} align={"center"}
                   style={{height: "calc(100vh - 90px)", justifyContent: "center"}}>
                <Spin/>
            </Space>
        )
    }


    return (
        <Space direction={"vertical"} size={[15, 15]}>
            <Row justify={"end"}>
                <Button onClick={() => setModalShown(true)} type="primary">{t("USERS.GROUPS.ADD")}</Button>
            </Row>
            <Tabs>
                {systemGroups.map(({id, name, groups}) => (
                    <Tabs.TabPane tab={name} key={id}>
                        <Table
                            scroll={{x: true}}
                            rowKey={"id"}
                            columns={columns}
                            dataSource={[...groups]}
                        />
                    </Tabs.TabPane>
                ))}
            </Tabs>
            <UsersGroupsModal visible={modalShown} onClose={() => setModalShown(false)} onSubmit={store.createGroup}/>
        </Space>
    );
});
