import React, { useState } from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import { articlesStockService } from "../../../../services/articles/articles/articles.stock.service";
import { ArticlesStock } from "../../../../models/articles/articles.stock";
import { Column } from "../../../../models/core/columns";
import { Link } from "react-router-dom";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
import {
    Button,
    Checkbox,
    Col,
    Dropdown,
    Form,
    Menu,
    Row,
    Select, Space,
    Typography,
} from "antd";
import { useRequiredStore } from "../../../../utils/store";
import { RootStoreContext } from "../../../../stores/root/root.store";
import { Export } from "../../../common/export/export";
import { ColumnsType } from "antd/es/table";
import { PaginatedRequest } from "../../../../models/core/paginated.request";
import { FilterKey } from "../../../../models/filtered-table/filtered.table.props";
import {ArticlesLinks} from "../articles-links/articles.links";

interface ArticlesStockListProps extends ComponentPropsFromRoute {}

export const ArticlesStockList: React.FC<ArticlesStockListProps> = observer(
    ({ requiredConfigs, name }) => {
        const {
            commonStore: { warehouses }
        } = useRequiredStore(RootStoreContext);
        const t = useTranslate();

        const [exportModalShown, setExportModalShown] =
            useState<boolean>(false);
        const [request, setRequest] = useState<PaginatedRequest | null>(null);

        const columnsForExportModal: { value: string; label: string }[] = [
            {
                label: "ID",
                value: "id",
            },
            {
                label: t("CODE"),
                value: "name",
            },
            {
                label: t("DESCRIPTION"),
                value: "description",
            },
        ];

        const columns: ColumnsType<ArticlesStock> = [
            {
                title: "ID",
                dataIndex: "id",
                key: "id",
            },
            {
                title: t("CODE"),
                dataIndex: "name",
                key: "name",
                render: (value: string) => value.toUpperCase() !== "DELIVERY" ? (
                    <Link
                        style={{ whiteSpace: "nowrap" }}
                        to={"/articles/" + value}
                    >
                        {value}
                    </Link>
                ) : value,
            },
            {
                title: t("DESCRIPTION"),
                dataIndex: "description",
                key: "description",
            },
        ];

        const columnsConfigs: { key: string; title: string }[] = [
            {
                title: "ID",
                key: "id",
            },
            {
                title: "CODE",
                key: "name",
            },
            {
                title: "DESCRIPTION",
                key: "description",
            },
        ];

        if (warehouses) {
            warehouses.forEach(({ id, name, type }) => {
                if (requiredConfigs!.warehouseTypes.includes(type)) {
                    const column: Column = {
                        title: name,
                        dataIndex: id,
                        key: id,
                        render: (_, row: ArticlesStock) => {
                            return (
                                <>
                                    {
                                        row.stocks.find(
                                            (item) => item.warehouse.id === id
                                        )?.amount
                                    }
                                </>
                            );
                        },
                    };
                    const columnsConfig = {
                        title: name,
                        key: id,
                    };

                    columnsConfigs.push(columnsConfig);
                    columns.push(column);
                }
            });
        }

        const filters: FilterKey[] = [
            {
                key: "id",
                label: "ID",
                type: "string",
            },
            {
                key: "name",
                label: t("CODE"),
                type: "string",
            },
            {
                key: "description",
                label: t("DESCRIPTION"),
                type: "string",
            },
            {
                key: "amount",
                label: t("QUANTITY"),
                type: "int",
            },
            {
                key: "warehouses",
                label: t("WAREHOUSES.NAME"),
                type: "select",
                options: warehouses.map(({ id, name }) => ({
                    value: id,
                    label: name,
                })),
            },
        ];

        const defaultValues = {
            columns: columnsForExportModal.map((item) => item.value),
            warehouses: warehouses
                .filter(({ type }) =>
                    requiredConfigs!.warehouseTypes.includes(type)
                )
                .map((warehouse) => warehouse.id),
            calculateStock: false,
            onlySelectedPage: false,
            useFilters: false,
        };

        const getRequest = (request: PaginatedRequest): void => {
            setRequest(request);
        };

        return (
            <Space direction="vertical" size={[50, 50]}>
                <ArticlesLinks/>
                <Space direction="vertical" size={[0, 0]}>
                    <Row justify="space-between" gutter={[0, 0]}>
                        <Typography.Title level={3}>
                            {t(
                                requiredConfigs!.warehouseTypes.includes(
                                    "consignation"
                                )
                                    ? "ARTICLES.CONS_WAREHOUSES.TITLE"
                                    : "ARTICLES.WAREHOUSES.TITLE"
                            )}
                        </Typography.Title>
                        <Dropdown
                            trigger={["click"]}
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key={1}
                                        onClick={() => setExportModalShown(true)}
                                    >
                                        {t("DOWNLOAD.DO_DOWNLOAD")}
                                    </Menu.Item>
                                    <Menu.Item key={2}>
                                        <a href={"/api/v3/m1/stock/export?token=" }>
                                            {t("ARTICLES.EXPORT_FOR_M1")}
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key={2}>
                                        <a href={"/api/v3/m1/zip" }>
                                            {t("ARTICLES.EXPORT_FOR_M1_ALL_IN_ZIP")}
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key={2}>
                                        <a href={"/api/v3/m1/excl-in-magento-zip" }>
                                            {t("ARTICLES.EXPORT_FOR_M1_EXL_MAGENTO_IN_ZIP")}
                                        </a>
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button
                                className={"table-page-button"}
                                type={"primary"}
                            >
                                {t("ACTION")}
                            </Button>
                        </Dropdown>
                    </Row>
                    <FilteredTable
                        reloadOnLngChange
                        getRequest={getRequest}
                        filterKeys={filters}
                        columns={columns}
                        columnsConfigs={{ columns: columnsConfigs, pageKey: name }}
                        loadDataCallback={(request) =>
                            articlesStockService.getAllInWarehouses(
                                request,
                                requiredConfigs!.warehouseTypes
                            )
                        }
                    />
                </Space>
                <Export
                    title={
                        requiredConfigs!.warehouseTypes.includes("consignation")
                            ? "ARTICLES.CONS_WAREHOUSES.DOWNLOAD.TITLE"
                            : "ARTICLES.WAREHOUSES.DOWNLOAD.TITLE"
                    }
                    withBasicExportFields
                    isShown={exportModalShown}
                    config={{
                        link: "/api/v3/articles/stock",
                        query: request?.toParams()!,
                    }}
                    additionalDefaultValues={defaultValues}
                    onClose={() => setExportModalShown(false)}
                >
                    <Col span={24}>
                        <Form.Item label={t("COLUMNS")} name={"columns"}>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp={"children"}
                                mode="multiple"
                                placeholder={t("COLUMNS")}
                            >
                                {columnsForExportModal.map(
                                    ({ value, label }) => (
                                        <Select.Option
                                            key={value}
                                            value={value}
                                        >
                                            {label}
                                        </Select.Option>
                                    )
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={t("WAREHOUSES.TITLE")}
                            name={"warehouses"}
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "FROM.ERROR.PLEASE_SELECT_VALUE"
                                    ),
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp={"children"}
                                mode="multiple"
                                placeholder={t("WAREHOUSES.TITLE")}
                            >
                                {warehouses
                                    .filter(({ type }) =>
                                        requiredConfigs!.warehouseTypes.includes(
                                            type
                                        )
                                    )
                                    .map(({ id, name, type }) => (
                                        <Select.Option key={id} value={id}>
                                            {name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"calculateStock"}
                            valuePropName={"checked"}
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "FROM.ERROR.PLEASE_SELECT_VALUE"
                                    ),
                                },
                            ]}
                        >
                            <Checkbox>
                                {t(
                                    "ARTICLES.WAREHOUSES.DOWNLOAD.CALCULATE_STOCK"
                                )}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"onlySelectedPage"}
                            valuePropName={"checked"}
                        >
                            <Checkbox>
                                {t(
                                    "ARTICLES.WAREHOUSES.DOWNLOAD.SELECTED_PAGE"
                                )}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"useFilters"}
                            valuePropName={"checked"}
                        >
                            <Checkbox>
                                {t("ARTICLES.WAREHOUSES.DOWNLOAD.USE_FILTERS")}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Export>
            </Space>
        );
    }
);
