import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {AuthModel} from "../../models/auth/auth.model";


export class AuthService {

    api: AxiosInstance = API;

    auth(username: string, password: string): Promise<AuthModel> {
        return this.api.post<AuthModel>('login_check', {username, password}).then(x => x.data);
    }

    logout(): Promise<void> {
        return this.api.get("logout");
    }

    switchAccount(id: number): Promise<void> {
        return this.api.post('authentication/switch-account/' + id);
    }
}

export const authService = new AuthService();
