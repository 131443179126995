import React, {useEffect, useState} from "react";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {Button, Col, Form, FormInstance, Input, Row} from "antd";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../../../models/core/response";


interface UserAccessesCategoryCreateFormProps {
    onClose: () => void,
    onSubmit: (values: {name: string, editingCategoryId?: string}) => Promise<any>,
    form: FormInstance
}

export const UserAccessesCategoryCreateForm: React.FC<UserAccessesCategoryCreateFormProps> = ({onClose, onSubmit, form}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);

    return (
        <Form
            form={form}
            layout="vertical"
            name={"category_create_form"}
            onFinish={() => form
                .validateFields()
                .then(values => {
                    setLoading(true);
                    onSubmit(values)
                        .then(
                            () => {
                                form.resetFields();
                                return onClose();
                            },
                            (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                        .then(() => {}, (e) => form.setFields(e.response?.data?.fields))
                        .finally(() => setLoading(false));
                })
            }
        >
            <Row gutter={10} >
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label={t("NAME")}
                        rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                    >
                        <Input type="text" placeholder={t("NAME")}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item style={{marginBottom: 0}}>
                        <Button
                            loading={loading}
                            style={{display: "block", marginLeft: "auto"}}
                            htmlType={"submit"}
                            type={"primary"}
                        >
                            {t("SAVE")}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}
