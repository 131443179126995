import API from "./api";
import {RootStore} from "../stores/root/root.store";
import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseData} from "../models/core/response";
import {notification} from "antd";
import {version} from "../version";
import {fakerService} from "../services/faker/faker.service";
import {ViolationListStore} from "../stores/violation-list/violation-list.store";
import {NavigateFunction} from "react-router/dist/lib/hooks";

export const initInterceptors = (store: RootStore, serverErrorMessageGetter: () => string, errorTitleGetter: () => string, accessErrorMessageGetter: () => string): void => {
    initMessagesInterceptor(store.violationListStore, errorTitleGetter);
    initConfirmMessagesInterceptor(store);
    initServerErrorInterceptor(serverErrorMessageGetter, errorTitleGetter);
    initAccessErrorInterceptor(accessErrorMessageGetter, errorTitleGetter);
    initChangedBranchHeaderInterceptor();
    initLocaleHeaderInterceptor(store);
    initNotFoundInterceptor(store.commonStore.navigate);
    initVersionInterceptor(store);
}

const initMessagesInterceptor = (store: ViolationListStore, errorTitleGetter: () => string): void => {
    API.interceptors.response.use((value: AxiosResponse): AxiosResponse => {
        return value;
    }, (error: AxiosError<ErrorResponseData>) => {
        if (error.response?.status === 400) {
            if (error.response.data.messages) {
                error.response.data.messages.forEach((item) => {
                    notification.error({
                        message: errorTitleGetter(),
                        description: item
                    });
                });
            }
            if (error.response.data.violations) {
                store.setViolations(error.response.data.violations);
            }
        }
        return Promise.reject(error);
    });
}

const initConfirmMessagesInterceptor = (rootStore: RootStore): void => {
    API.interceptors.response.use((value: AxiosResponse): AxiosResponse => {
        return value;
    }, (error: AxiosError<ErrorResponseData>) => {
        if (error.response?.status === 402 && error.response.data.messages) {
            const message = error.response.data?.messages[0] || "";

            return new Promise((resolve, reject) => {
                rootStore.confirmStore.open(
                    message,
                    () => {
                        const config: AxiosRequestConfig = {
                            ...error.config,
                            params: {
                                force: 1
                            }
                        };

                        return API
                            .request(config)
                            .then(result => resolve(result))
                            .catch(reason => reject(reason))
                    },
                    () => {
                        reject(error);
                    }
                );
            });
        }
        return Promise.reject(error);
    });
}

const initServerErrorInterceptor = (errorMessageGetter: () => string, errorTitleGetter: () => string): void => {
    API.interceptors.response.use((value: AxiosResponse): AxiosResponse => {
        return value;
    }, (error: AxiosError<ErrorResponseData>) => {
        if (error.response?.status === 500) {
            notification.error({
                message: errorTitleGetter(),
                description: errorMessageGetter()
            });
        }
        return Promise.reject(error)
    });
}

const initAccessErrorInterceptor = (errorMessageGetter: () => string, errorTitleGetter: () => string): void => {
    API.interceptors.response.use((value: AxiosResponse): AxiosResponse => {
        return value;
    }, (error: AxiosError<ErrorResponseData>) => {
        if (error.response?.status === 403) {
            notification.error({
                message: errorTitleGetter(),
                description: errorMessageGetter()
            });
        }
        return Promise.reject(error)
    });
}

const initChangedBranchHeaderInterceptor = () => {
    initCustomHeaderInterceptor("Set-Branch", () => fakerService.getChangedBranch());
}

const initLocaleHeaderInterceptor = (store: RootStore) => {
    initCustomHeaderInterceptor("Locale", () => store.langStore.i18n?.language || null);
}

const initNotFoundInterceptor = (navigate: NavigateFunction) => {
    API.interceptors.response.use((value: AxiosResponse): AxiosResponse => {
        return value;
    }, (error: AxiosError<ErrorResponseData>) => {

        if (!error.config.allowNotFound && error.response?.status === 404) {
            navigate("/not-found")
        }
        return Promise.reject(error);
    });
}

export const initVersionInterceptor = (rootStore: RootStore) => {
    API.interceptors.response.use((response) => {
        if (parseInt(response.headers.version) !== version) {
            rootStore.commonStore.setOutdated();
        }
        return response;
    })
}

export const initCustomHeaderInterceptor = (name: string, valueGetter: () => string | null) => {
    API.interceptors.request.use((config: AxiosRequestConfig) => {
        const value = valueGetter();
        if (value) {
            config.headers[name] = value;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
}

