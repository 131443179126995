import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {Button, Space, Typography} from "antd";
import {Claim} from "../../../models/claims/claims";
import {claimService} from "../../../services/claims/claims.service";
import {useParams} from "react-router-dom";
import {LoadingSpin} from "../../common/loading-spin/loading.spin";
import {ClaimsStatus} from "../status/claim.status";
import {ClaimComments} from "./comments/claim.comments";
import {ClaimParts} from "./parts/claim.parts";
import {ClaimDescription} from "./descrption/claim.description";
import {notifications} from "../../../services/notifications/notifications";
import {ClaimLogger} from "./logger/claim.logger";
import {useTranslate} from "../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";

export const ClaimView: React.FC<ComponentPropsFromRoute> = observer(({name}) => {

    const { id } = useParams<{ id: string }>();
    const t = useTranslate();

    const [claim, setClaim] = useState<Claim>();
    const [updateState, setUpdateState] = useState<boolean>(false);

    const update = async () => {
        setUpdateState(true);
        await claimService.getClaim(parseInt(id!)).then(claim => setClaim(claim));
        setUpdateState(false);
    }

    const finishProcessing = async () => {
        await claimService.complete(claim!.id);
        await update();
        notifications.successfully();
    }

    useEffect(() => {
        update();
    }, [])

    if (!claim) {
        return <LoadingSpin />
    }

    return (
        <Space direction="vertical" size={[20, 20]}>
            <PageHeader
                style={{ padding: 0 }}
                ghost={false}
                title={
                    <Typography.Title level={1} style={{ margin: 0 }}>
                        {t("CLAIMS.CLAIM.TITLE") + " #" + claim.documentNumber}
                    </Typography.Title>
                }
                subTitle={
                    <Typography.Text disabled>
                        {t("CLAIMS.CLAIM.TYPE." + claim.type.toUpperCase())}
                    </Typography.Text>
                }
                extra={<ClaimsStatus large status={claim.status} />}
            >
                <Button type={"primary"} onClick={finishProcessing}>{t('FINISH_PROCESSING')}</Button>
            </PageHeader>

            <ClaimDescription claim={claim} />
            <ClaimComments claim={claim} />
            {claim ? (
                <ClaimParts claim={claim} onUpdate={update} loading={updateState} />
            ) : (
                <LoadingSpin />
            )}
            <ClaimLogger claim={claim} />
        </Space>
    );
});
