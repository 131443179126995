import React, {useEffect, useRef, useState} from 'react';
import {ColumnsType} from "antd/es/table";
import {Error, ErrorRelationType} from "../../../models/errors/error";
import FilteredTable from "../../common/filtered-table/filtered.table";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {useTranslate} from "../../../hooks/translate.hook";
import {useParams, useLocation} from "react-router-dom";
import {FilterKey} from "../../../models/filtered-table/filtered.table.props";
import Date from "../../date/date";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import {ErrorsListStore} from "./errors.list.store";
import {Tag} from "antd";
import {ErrorsListLink} from "./errors.list.link";
import {ErrorsListActions} from "./errors.list.actions";

interface ErrorsListProps extends ComponentPropsFromRoute {};
export const ErrorsList: React.FC<ErrorsListProps> = ({name, requiredConfigs}) => {
    const t = useTranslate();
    const {pageStore} = useRequiredStore(RootStoreContext);
    const match = useLocation();
    const {groupId} = useParams<{ groupId: string }>();
    const rendered = useRef(false);
    const [store] = useState(() => new ErrorsListStore(groupId !== "all" ? groupId : undefined));

    useEffect(() => {
        pageStore.updateRouteName(match.pathname, "ERRORS." + groupId!.toUpperCase() + ".TITLE");
        if (rendered.current) {
            store.updateGroup(groupId!)
        } else {
            rendered.current = true;
        }
    }, [groupId]);

    const columns: ColumnsType<Error> = [
        {
            title: t("OBJECT"),
            dataIndex: "relationType",
            key: "relationType",
            render: (type: ErrorRelationType, row) => <ErrorsListLink error={row}/>
        },

        {
            title: t("LEVEL"),
            dataIndex: "level",
            key: "level",
            width: 50,
            render: (type: string) => <Tag color={type === "error" ? "red" : "warning"}>{type.toUpperCase()}</Tag>,
        },
        {
            title: t("TYPE"),
            dataIndex: "type",
            key: "type",
        },
        {
            title: t("MESSAGE"),
            dataIndex: "message",
            key: "message",
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: date => <Date dateString={date} showTime withSeconds/>
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            render: (_: any, error) => <ErrorsListActions error={error}/>
        }
    ];

    const columnsConfigs: { key: string; title: string }[] = [
        {
            title: "OBJECT",
            key: "relationType"
        },
        {
            title: "LEVEL",
            key: "level"
        },
        {
            title: "TYPE",
            key: "type"
        },
        {
            title: "MESSAGE",
            key: "message"
        },
        {
            title: "CREATED_AT",
            key: "createdAt"
        }
    ];

    const filters: FilterKey[] = [
        {
            label: t("OBJECT"),
            key: "relationId",
            type: "string",
        },
        {
            label: t("LEVEL"),
            key: "level",
            type: "select",
            options: [{value: "error", label: "Error"}, {value: "warning", label: "Warning"}]
        },
        {
            label: t("TYPE"),
            key: "type",
            type: "string",
        },
        {
            label: t("MESSAGE"),
            key: "message",
            type: "string",
        },
        {
            label: t("CREATED_AT"),
            key: "createdAt",
            type: "date",
        },
    ];


    return (
        <FilteredTable
            filterKeys={filters}
            dataHandler={store.dataHandler}
            columns={columns}
            columnsConfigs={{ columns: columnsConfigs, pageKey: name }}
        />
    );
};
