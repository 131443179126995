import { AxiosInstance } from "axios";
import { Shipper } from "../../models/shippers/shipper";
import API from "../../utils/api";

export class ShipeprsService {
    private api: AxiosInstance = API;

    getShippers(): Promise<Shipper[]> {
        return this.api
            .get<Shipper[]>("delivery/shippers/all")
            .then((x) => x.data);
    }
}

export const shippersService = new ShipeprsService();
