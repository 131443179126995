import React from "react";
import {Outlet} from "react-router-dom";

interface ExtendedSwitchProps {
    context?: unknown;
    [K: string]: any;
}

export const ExtendedSwitch: React.FC<ExtendedSwitchProps> = ({context}) => {
    return (
        <Outlet context={context} />
    );
};
