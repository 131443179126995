import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../../utils/store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Alert, Col, Row, Spin, Typography} from "antd";
import {OrdersCheckoutShippingsCard} from "./orders.checkout.shippings.card";
import {OrdersCheckoutStoreContext} from "../orders.checkout.store";
import {NotCompletedOrder} from "../../../../../models/orders/orders-cart/orders-checkout/orders.checkout.not_completed.order";

interface OrdersCheckoutShippingsProps {
    order: NotCompletedOrder
}

export const OrdersCheckoutShippings: React.FC<OrdersCheckoutShippingsProps> = observer(({order}) => {
    const t = useTranslate();
    const store = useRequiredStore(OrdersCheckoutStoreContext
    );

    useEffect(() => {
        if (!store.shippings[order.id]) {
            store.getShipping(order.id);
        }
    }, [order]);

    return (
        <>
            <Typography.Title level={3}>{t("CART.DELIVERY.OPTIONS.TITLE")}</Typography.Title>
            <Row gutter={[30, 30]}>
                {store.shippings[order.id] ? store.shippings[order.id].map(shipping => (
                    <Col className={"card-col"} key={shipping.id} xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                        <OrdersCheckoutShippingsCard order={order} shipping={shipping}/>
                    </Col>
                )) : <Spin/>}
                {store.agentShippingsNotFound[order.id] ? (
                    <Col span={24}>
                        <Alert type={"error"} message={t("CART.DELIVERY.OPTIONS.NOT_FOUND")}/>
                    </Col>
                ) : null}
            </Row>
        </>
    );
});
