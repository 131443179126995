import React, {PropsWithChildren} from "react";
import {Button, Dropdown, Space, Menu} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MdRedactor} from "../markdown-editor/markdown-editor";
import {IconName} from "@fortawesome/fontawesome-svg-core";

interface MarkdownTextareaCommandsProps {
    editor: MdRedactor
}

interface ListCommandButton {
    label: string,
    fn: () => void
}

interface CommandButton {
    icon: IconName;
    fn: () => void;
    fnList?: ListCommandButton[]
}

export const MarkdownTextareaCommands: React.FC<PropsWithChildren<MarkdownTextareaCommandsProps>> = ({editor}) => {

    const buttons: CommandButton[] = [
        {
            icon: 'h-square',
            fn: () => null,
            fnList: [
                {label: 'H1', fn: () => editor.setHeader(1)},
                {label: 'H2', fn: () => editor.setHeader(2)},
                {label: 'H3', fn: () => editor.setHeader(3)},
                {label: 'H4', fn: () => editor.setHeader(4)},
                {label: 'H5', fn: () => editor.setHeader(5)},
                {label: 'H6', fn: () => editor.setHeader(6)},
            ],
        },
        {icon: 'bold',        fn: () => editor?.setBold()},
        {icon: 'italic',      fn: () => editor?.setItalic()},
        {icon: 'quote-left',  fn: () => editor?.setQuote()},
        {icon: 'code',        fn: () => editor?.setCode()},
        {icon: 'list-ol',     fn: () => editor?.setNumericList()},
        {icon: 'list-ul',     fn: () => editor?.setSymbolicList()},
        {icon: 'link',        fn: () => editor?.setLink()},
        {icon: 'table',       fn: () => editor?.setTable()},
        {icon: 'info',        fn: () => editor?.setInfoBlock()},
        {icon: 'question',    fn: () => editor?.setQuestionBlock()},
        {icon: 'exclamation', fn: () => editor?.setErrorBlock()}
    ]

    return (
            <Space>
                {buttons.map((button, key) => button.fnList ? (
                    <Dropdown
                        key={key}
                        overlay={
                            <Menu>
                                {button.fnList.map((fn, key) => (
                                    <Menu.Item key={key} onClick={fn.fn}>
                                        {fn.label}
                                    </Menu.Item>
                                ))}
                            </Menu>
                        }>
                        <Button
                            size={"small"}
                            key={button.icon}
                            icon={<FontAwesomeIcon icon={["fas", button.icon]} transform={{size: 13}} />}
                        />
                    </Dropdown>
                ) : (
                    <Button
                            size={"small"}
                            key={key}
                            icon={<FontAwesomeIcon icon={["fas", button.icon]} transform={{size: 13}} />}
                            onClick={button.fn} />
                ))}
            </Space>
    );
}