import { TableShipment } from "./../../../models/parcels/shipment";
import { isAllowed } from "./../../../utils/helpers";
import { action, makeObservable, observable } from "mobx";
import React from "react";
import {OrderComment, OrderData, OrderMeta} from "../../../models/orders/order-data/order.data";
import { orderDataService } from "../../../services/orders/order-data/order.data.service";
import { payerReceiverSellerService } from "../../../services/payer-receiver-seller/payer.receiver.seller.service";
import {
    PayerOrReceiverDetails,
    PayerReceiverFormValues,
    SellerDetails,
} from "../../../models/payer-receiver-seller/payer.reveiver.seller";
import { PagesStore } from "../../../stores/pages/pages.store";
import { Error } from "../../../models/errors/error";
import { NotCompletedOrder } from "../../../models/orders/orders-cart/orders-checkout/orders.checkout.not_completed.order";
import { articlesService } from "../../../services/articles/articles.service";
import { OrdersCreateAddValues } from "../../../models/orders/orders-cart/orders-create/orders.create.add.values";
import { ordersCheckoutService } from "../../../services/orders/orders-cart/orders.checkout.service";
import {MetaResult} from "../../../models/meta-result/meta.result";
import {convertDataForTable} from "../../documents/common/shipments/document.shipments.helper";
import {OrdersUpdateRequest} from "../../../models/orders/order-update/order.update";
export type OrderModalType = "export" | "headerModalShown" | "editWeight" | "editDateModalShown";
export class OrderDataStore {
    @observable
    public order: OrderData | null = null;

    @observable
    public orderMeta: OrderMeta | null = null;

    @observable
    public addOrderItemModalShown: boolean = false;

    @observable
    public loading: boolean = false;

    @observable
    public editingOrderItems: boolean = false;

    @observable
    public resetting: boolean = false;

    @observable
    public locking: boolean = false;

    @observable
    public makingPostProcedure: boolean = false;

    @observable
    public headerModalShown: boolean = false;

    @observable
    public editWeightModalShown: boolean = false;

    @observable
    public editDateModalShown: boolean = false;

    @observable
    public masterOrderId: number | null = null;

    @observable
    public recalculatingWeight: boolean = false;

    @observable
    public warningsModalShown: boolean = false;

    @observable
    public warnings: Error[] = [];

    @observable
    public exportModalShown: boolean = false;

    @observable
    public splitModalShown: boolean = false;

    @observable
    public actionMenuVisible: boolean = false;

    @observable
    public shipments: TableShipment[] = [];

    public id: string = "";

    constructor(private pageStore: PagesStore, private url: string, private attributes: string[]) {
        makeObservable(this);
    }

    @action
    private setShipments(value: TableShipment[]): void {
        this.shipments = value;
    }

    @action
    private setAddOrderItemModalShown(value: boolean): void {
        this.addOrderItemModalShown = value;
    }

    @action
    public setActionMenuVisible(value: boolean): void {
        this.actionMenuVisible = value;
    }

    @action
    public setWarnings(value: Error[]): void {
        this.warnings = value;
    }

    @action
    public setWarningsShown(value: boolean): void {
        this.warningsModalShown = value;
    }

    @action
    public setExportModalShown(value: boolean): void {
        this.exportModalShown = value;
    }

    @action
    public setSplitModalShown(value: boolean) {
        this.splitModalShown = value;
    }

    @action
    public setRecalculatingWeight(value: boolean): void {
        this.recalculatingWeight = value;
    }

    @action
    public setMasterOrderId(id: number | null): void {
        this.masterOrderId = id;
    }

    @action
    public setEditingOrderItems(value: boolean): void {
        this.editingOrderItems = value;
    }

    @action
    public setHeaderModalShown = (value: boolean): void => {
        this.headerModalShown = value;
    };

    @action
    public setEditWeightModalShown = (value: boolean): void => {
        this.editWeightModalShown = value;
    };

    @action
    public setEditDateModalShown = (value: boolean): void => {
        this.editDateModalShown = value;
    };

    @action
    private setMakingPostProcedure(value: boolean): void {
        this.makingPostProcedure = value;
    }

    @action
    private setResetting(resetting: boolean): void {
        this.resetting = resetting;
    }

    @action
    private setLocking(locking: boolean): void {
        this.locking = locking;
    }

    @action
    private setOrder(order: OrderData): void {
        this.order = order;
        if (order.status === "sync") {
            setTimeout(() => this.loadInvoiceData(), 2000)
        }
    }

    @action
    private setOrderWithMeta({data, meta}: MetaResult<OrderData, OrderMeta>): void {
        this.order = data;
        this.orderMeta = meta;
    }

    @action
    private addComment(comment: OrderComment): void {
        this.order?.comments.push(comment);
    }

    @action
    private setLoading(loading: boolean): void {
        this.loading = loading;
    }

    @action
    private deleteComment(comment: OrderComment): void {
        const commentIndex = this.order!.comments.indexOf(comment);
        if (commentIndex !== -1) {
            this.order?.comments.splice(commentIndex, 1);
        }
    }

    @action
    private setPayerReceiverData(type: "payer" | "receiver", data: PayerOrReceiverDetails): void {
        this.order![type] = data;
    }

    @action
    private setSellerData(seller: SellerDetails): void {
        this.order!.seller = seller;
    }

    public setOrderId(id: string): void {
        this.id = id;
        this.loadData();
    }

    public showWarnings = async () => {
        this.setWarnings(await orderDataService.getWarnings(this.order!.id));
        this.setWarningsShown(true);
    };

    public closeWarningsModal = () => {
        this.setWarningsShown(false);
    };

    public async editOrder(values: { clientOrderId: string; paymentTerms: string }) {
        this.setOrder(await orderDataService.updateOrder(this.order!.id, values));
    }

    public async editDate(date: any) {
        await orderDataService.updateDate(this.order!.id, date)
        await this.loadData()
    }

    public editSellerData = async (values: SellerDetails) => {
        await this.setSellerData(await payerReceiverSellerService.updateSellerData(this.order!.id, values));
    };

    public editPayerReceiverData = async (type: "payer" | "receiver", values: PayerReceiverFormValues) => {
        const data = await payerReceiverSellerService.updatePayerReceiveData(this.order!.id, type, values);
        this.setPayerReceiverData(type, data);
    };

    public removeComment = async (comment: OrderComment) => {
        await orderDataService.deleteComment(comment.id);
        this.deleteComment(comment);
    };

    public async makeComment(values: { message: string }) {
        this.addComment(await orderDataService.postComment(this.order!.id, values));
    }

    public async sync(openNotification: (type: "success" | "error", message: string) => void) {
        this.setResetting(true);
        try {
            this.setOrder(await orderDataService.syncOrder(this.order!.id));
            this.setResetting(false);
            openNotification("success", "ORDERS.ORDER.RESET_SUCCESS");
        } catch (e: any) {
            this.setResetting(false);
            openNotification("error", "ORDERS.ORDER.RESET_ERROR");
        }
    }

    public async lockExchange(openNotification: (type: "success" | "error", message: string) => void) {
        // ORDERS.ORDER.BLOCK_SUCCESS
        this.setLocking(true);
        try {
            this.setOrder(await orderDataService.lockExchange(this.order!.id));
            openNotification("success", "ORDERS.ORDER.BLOCK_SUCCESS");
            this.setLocking(false);
        } catch (e: any) {
            openNotification("error", "ORDERS.ORDER.BLOCK_ERROR");
            this.setLocking(false);
        }
    }

    public async unlockExchange(openNotification: (type: "success" | "error", message: string) => void) {
        this.setLocking(true);
        try {
            this.setOrder(await orderDataService.unlockExchange(this.order!.id));
            this.setLocking(false);
            openNotification("success", "ORDERS.ORDER.UNLOCK_SUCCESS");
        } catch (e: any) {
            this.setLocking(false);
            openNotification("error", "ORDERS.ORDER.UNLOCK_ERROR");
        }
    }

    public async makePostProcedure(openNotification: (type: "success" | "error", message: string) => void) {
        this.setMakingPostProcedure(true);
        try {
            this.setOrder(await orderDataService.postProcedure(this.order!.id));
            this.setMakingPostProcedure(false);
            openNotification("success", "ORDERS.ORDER.POST_PROCEDURE_SUCCESS");
        } catch (e: any) {
            this.setMakingPostProcedure(false);
            openNotification("error", "ORDERS.ORDER.POST_PROCEDURE_ERROR");
        }
    }

    public handleModal(modalType: OrderModalType, open: boolean): void {
        const modalsMap: { [type: string]: (value: boolean) => void } = {
            editWeight: this.setEditWeightModalShown,
            headerModalShown: this.setHeaderModalShown,
            editDateModalShown: this.setEditDateModalShown
        };
        if (modalsMap[modalType]) {
            const handler = modalsMap[modalType];
            handler(open);
        }
    }

    public recalcWeight = async (
        values: { weight: string },
        openNotification: (type: "success" | "error", message: string) => void
    ) => {
        this.setRecalculatingWeight(true);
        try {
            await orderDataService.recalcWeight(this.masterOrderId!, values);
            await this.setOrderWithMeta(await orderDataService.getOrder(this.id));
            this.setRecalculatingWeight(false);
            openNotification("success", "RECALC_WEIGHT.SUCCESS");
        } catch (e: any) {
            this.setRecalculatingWeight(false);
        }
    };

    public async duplicateToMissingItems(openNotification: (type: "success" | "error", message: string) => void) {
        this.setActionMenuVisible(false);
        try {
            await orderDataService.duplicateToMissingItems(this.id);
            openNotification("success", "ORDER.DUPLICATE_TO_MISSING_ITEMS.SUCCESS");
        } catch (e: any) {}
    }

    public async clearToMissingItems(openNotification: (type: "success" | "error", message: string) => void) {
        this.setActionMenuVisible(false);
        try {
            await orderDataService.clearMissingItems(this.id);
            openNotification("success", "ORDER.CLEAR_MISSING_ITEMS.SUCCESS");
        } catch (e: any) {}
    }

    public handleActionMenu = (event: any) => {
        if (event.key === 5 || event.key === 6) {
            this.setActionMenuVisible(false);
        }
    };

    public handleActionMenuVisibleChange = (visible: boolean) => {
        this.setActionMenuVisible(visible);
    };

    public deleteOrderItem = async (orderId: number, itemId: number) => {
        await orderDataService.removeOrderItem(itemId);
        this.setOrderWithMeta(await orderDataService.getOrder(this.id));
    };

    public getStockArticleAmount = async (articleId: number, order: NotCompletedOrder) => {
        return articlesService.getStockAmount(articleId, order.warehouse.id);
    };

    public updateOrderItem = async (itemId: number | string, requestValues: OrdersUpdateRequest, orderId: number) => {
        await orderDataService.updateOrderItem(itemId, requestValues);
        this.setOrderWithMeta(await orderDataService.getOrder(this.id));
    };

    public updateDiscount = async (discountPercent: number) => {
        await orderDataService.updateOrderDiscount(this.order!.id, { discountPercent });
        this.setOrderWithMeta(await orderDataService.getOrder(this.id));
    };

    public openAddModal = (): void => {
        this.setAddOrderItemModalShown(true);
    };

    public closeAddModal = (): void => {
        this.setAddOrderItemModalShown(false);
    };

    public searchArticle = async (search: string): Promise<any> => {
        const articles = await articlesService.searchArticle(search);
        return articles.map(({ id, name, description, multiple }) => ({
            value: id,
            label: name + " - " + description,
            multiple: multiple
        }));
    };

    public addToCart = async (values: OrdersCreateAddValues, orderId?: number) => {
        await orderDataService.addOrderItem(orderId!, values);
        this.setOrderWithMeta(await orderDataService.getOrder(this.id));
    };

    public showEditItemsTable = (): void => {
        this.setEditingOrderItems(true);
    };

    public async hideEditItemsTable() {
        this.setOrderWithMeta(await orderDataService.getOrder(this.id));
        this.setEditingOrderItems(false);
    }

    public cancelOrder = async () => {
        await ordersCheckoutService.cancelOrder(this.order!.id);
        this.setOrderWithMeta(await orderDataService.getOrder(this.id));
    };

    private async loadData() {
        this.setLoading(true);
        this.setOrderWithMeta(await orderDataService.getOrder(this.id));
        this.setMasterOrderId(this.order!.mergedOrderId);
        if (isAllowed(this.attributes, "shipment_management")) {
            this.setShipments(convertDataForTable(await orderDataService.getOrderShipments(this.order!.id)));
        }
        this.pageStore.updateRouteName(this.url, this.order?.id);
        this.setLoading(false);
    }

    private async loadInvoiceData() {
        this.setOrderWithMeta(await orderDataService.getOrder(this.id));
    }
}

export const OrderDataStoreContext = React.createContext<OrderDataStore | null>(null);
