import React from "react";
import {observer} from "mobx-react";
import {Col, Row, Typography} from "antd";
import {useTranslate} from "../../../hooks/translate.hook";
import {PayerReceiverCard} from "./cards/payer.receiver.card";
import {SellerCard} from "./cards/seller.card";
import {
    PayerOrReceiverDetails,
    PayerReceiverFormValues,
    SellerDetails
} from "../../../models/payer-receiver-seller/payer.reveiver.seller";


interface PayerSellerReceiverDataProps {
    payer: PayerOrReceiverDetails,
    receiver: PayerOrReceiverDetails,
    seller: SellerDetails,
    editPayerReceiver: (type: "payer" | "receiver", values: PayerReceiverFormValues) => Promise<void>
    editSeller: (values: SellerDetails) => Promise<void>
    withAddressType?: boolean;
}

export const PayerSellerReceiverData: React.FC<PayerSellerReceiverDataProps> = observer(({
                                                                                             payer,
                                                                                             receiver,
                                                                                             seller,
                                                                                             editPayerReceiver,
                                                                                             editSeller,
                                                                                             withAddressType = false
                                                                                         }) => {
    const t = useTranslate();
    return (
        <>
            <Typography.Title level={3}>{t("PAYER_SELLER_DETAILS.TITLE")}</Typography.Title>
            <Row gutter={[30, 30]}>
                <Col className="card-col" xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <PayerReceiverCard
                        type={"payer"}
                        title={"PAYER_SELLER_DETAILS.PAYER_DETAILS"}
                        data={payer}
                        editPayerReceiver={editPayerReceiver}
                        withAddressType={withAddressType}
                    />
                </Col>
                <Col className="card-col" xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <PayerReceiverCard
                        type={"receiver"}
                        title={"PAYER_SELLER_DETAILS.RECEIVER_DETAILS"}
                        data={receiver}
                        editPayerReceiver={editPayerReceiver}
                        withAddressType={withAddressType}
                    />
                </Col>
                <Col className="card-col" xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <SellerCard
                        data={seller}
                        editSeller={editSeller}
                    />
                </Col>
            </Row>
        </>
    );
});
