import {AxiosInstance} from "axios";
import API from "../../utils/api";

const BRANCH_TOKEN = 'sb';

export class FakerService {
    private api: AxiosInstance = API;

    constructor() {
        this.initChangedBranchListener();
    }

    public switchUser(value: string): Promise<void> {
        return this.api.post("authentication/switch-user?username=" + value)
    }

    public resetUser(): Promise<void> {
        return this.api.delete("authentication/switch-user")
    }

    public switchBranch(value: string): Promise<void> {
        localStorage.setItem(BRANCH_TOKEN, value);
        sessionStorage.setItem(BRANCH_TOKEN, value);

        return Promise.resolve();
    }

    public resetBranch(): Promise<void> {
        localStorage.removeItem(BRANCH_TOKEN);
        sessionStorage.removeItem(BRANCH_TOKEN);

        return Promise.resolve();
    }

    public getChangedBranch(): string|null {
        let branch = sessionStorage.getItem(BRANCH_TOKEN);
        if (branch) {
            return branch;
        }

        branch = localStorage.getItem(BRANCH_TOKEN)
        if (branch) {
            sessionStorage.setItem(BRANCH_TOKEN, branch)
        }

        return branch;
    }

    public updateChangedBranchInLocalStorage(): void {
        const token = sessionStorage.getItem(BRANCH_TOKEN);
        if (token) {
            localStorage.setItem(BRANCH_TOKEN, token)
        } else {
            localStorage.removeItem(BRANCH_TOKEN)
        }
    }

    public initChangedBranchListener(): void {
        let branch = this.getChangedBranch();
        if (branch) {
            document.addEventListener('visibilitychange', (e) => {
                if (document.visibilityState === "visible") {
                    fakerService.updateChangedBranchInLocalStorage()
                }
            });
        }
    }
}

export const fakerService = new FakerService();