import React, {useState} from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {Col, Form, Input, Modal, Row, Select} from "antd";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {SellerDetails} from "../../../../models/payer-receiver-seller/payer.reveiver.seller";

interface SellerModalProps {
    data: SellerDetails,
    visible: boolean,
    onClose: () => void,
    onSubmit: (values: SellerDetails) => Promise<void>
}
export const SellerModal: React.FC<SellerModalProps> = observer(({data, visible, onClose, onSubmit}) => {
    const t = useTranslate();
    const {langStore} = useRequiredStore(RootStoreContext);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const defaultValues: SellerDetails = {...data, countryCode: data.country.id || data.countryCode}


    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={t("PAYER_SELLER_DETAILS.EDIT_SELLER")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={ "seller_edit_form"}
                initialValues={defaultValues}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        onSubmit(values)
                            .then(() => {
                                form.resetFields();
                                return onClose();
                            }, (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                            .then(() =>
                                // onClose(),
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label={t("NAME")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input type="text" disabled={loading} placeholder={t("NAME")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="customsCode"
                            label={"EORI"}
                        >
                            <Input type="text" disabled={loading} placeholder={"EORI"}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="regNumber"
                            label={t("REG_NUMBER")}
                        >
                            <Input type="text" disabled={loading} placeholder={t("REG_NUMBER")}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="vatNumber"
                            label={"VAT"}
                        >
                            <Input type="text" disabled={loading} placeholder={"VAT"}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="countryCode"
                            label={t("COUNTRY")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Select
                                showSearch
                                optionFilterProp={"children"}
                                disabled={loading}
                                placeholder={t("COUNTRY_CODE")}
                            >
                                {langStore.countries.map(({id, name}) => (
                                    <Select.Option
                                        key={id as string}
                                        value={id as string}
                                    >
                                        {name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="city"
                            label={t("CITY")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input type="text" disabled={loading} placeholder={t("CITY")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="street"
                            label={t("STREET")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input type="text" disabled={loading} placeholder={t("STREET")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="zip"
                            label={"ZIP"}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input type="text" disabled={loading} placeholder="ZIP"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="bankName"
                            label={t("BANK_NAME")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input type="text" disabled={loading} placeholder={t("BANK_NAME")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="bankAddress"
                            label={t("BANK_ADDRESS")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input type="text" disabled={loading} placeholder={t("BANK_ADDRESS")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="bankSwift"
                            label={t("BANK_SWIFT")}
                        >
                            <Input type="text" disabled={loading} placeholder={t("BANK_SWIFT")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="bankIban"
                            label={t("BANK_IBAN")}
                        >
                            <Input type="text" disabled={loading} placeholder={t("BANK_IBAN")}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
})
