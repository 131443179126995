import React, {useState} from "react";
import {Form, Input, Modal, DatePicker} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {PropertyInventoryTaskPart} from "../../../../../models/property/property";
import {
    UIInputWidgetPropertyResponsibleUser
} from "../../../ui/input/widget/property-responsible-user/ui.input.widget.property-responsible-user";
import dayjs from "dayjs";
import {propertyService} from "../../../../../services/property/property.service";

interface EditDataModalProps {
    part: PropertyInventoryTaskPart;
    onClose: (part: PropertyInventoryTaskPart, completed: boolean) => void;
}

export const EditDataModal: React.FC<EditDataModalProps> = ({ part, onClose }) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    let initialValues: any = {}
    if (part.document) {
        initialValues = {
            documentNumber: part.document.number,
            date: dayjs(part.document.date),
            accountant: part.document.accountant.id
        }
    }

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={part.type.name}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => onClose(part, false)}
            maskClosable={false}
            width={500}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={initialValues}
                onFinish={() => form
                    .validateFields()
                    .then(async values => {
                        setLoading(true);
                        try {
                            await propertyService.editInventoryPartWriteOffData(part.id, values)
                            onClose(part, true);
                        }
                        catch (e: any) {
                            form.setFields(e.response?.data?.fields || [])
                        } finally {
                            setLoading(false);
                        }
                    })
                }
            >
                <Form.Item
                    label={t('PROPERTY.INVENTORY_TASK_PROPERTY_DOCUMENT_NUMBER.TITLE')}
                    name={['documentNumber']}
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('DATE')}
                    name={['date']}
                    rules={[{ required: true }]}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    label={t('PROPERTY.INVENTORY_TASK_PROPERTY_ACCOUNTANT.TITLE')}
                    name={['accountant']}
                    rules={[{ required: true }]}
                >
                    <UIInputWidgetPropertyResponsibleUser />
                </Form.Item>
            </Form>
        </Modal>
    );
}