import React, { useState } from "react";
import { observer } from "mobx-react";
import { AxiosError } from "axios";
import { ErrorResponseData } from "../../../../models/core/response";
import { Button, Col, Form, Input, Modal, Row, Select, Tooltip, Divider } from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import { AgreementsRequestTranslation } from "../../../../models/agreements/agreement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRequiredStore } from "../../../../utils/store";
import { AgreementsListStoreContext } from "../agreements-list/agreements.list.store";

export const AgreementsModal: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(AgreementsListStoreContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const isSelectOptionDisabled = (language: string) =>
        form
            .getFieldValue("translations")
            .map((item: AgreementsRequestTranslation) => item.locale)
            .includes(language);
    const onSubmit = store.editModalItem && store.tableItemAgreement ? store.updateAgreement : store.createAgreement;
    const defaultValues = store.editModalItem ? { ...store.editModalItem } : undefined;

    return (
        <Modal
            visible={store.modalShown}
            confirmLoading={loading}
            title={t(store.editModalItem ? "MARKETING_AGREEMENT.EDIT" : "MARKETING_AGREEMENT.ADD")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            destroyOnClose
            onCancel={() => {
                store.closeEditModal();
                //form.resetFields();
            }}
        >
            <Form
                size={"middle"}
                form={form}
                layout="vertical"
                name={"agreements_form"}
                //preserve={false}
                initialValues={defaultValues}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        onSubmit(values)
                            .then(
                                () => {
                                    store.closeEditModal();
                                    //form.resetFields();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                }
                            )
                            .then(
                                () =>
                                    // onClose(),
                                    (e: any) =>
                                        form.setFields(e.response?.data?.fields)
                            )
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Form.List
                    name="translations"
                    rules={[
                        {
                            validator: async (rule, names) => {
                                try {
                                    if (!names || names.length === 0) {
                                        return Promise.reject(t("FROM.ERROR.PLEASE_ADD_VALUE"));
                                    }
                                } catch (error) {
                                    throw new Error(error as string);
                                }
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Row
                                    justify="space-between"
                                    gutter={[10, 0]}
                                    key={field.key}
                                    style={{ alignItems: index === 0 ? "center" : "flex-start" }}
                                >
                                    <Col span={22}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, nextValues) =>
                                                prevValues.length !== nextValues.length
                                            }
                                        >
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "locale"]}
                                                    
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                                        },
                                                    ]}
                                                    label={
                                                        index === 0 ? t("MARKETING_AGREEMENT.AGREEMENT.TITLE") : null
                                                    }
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        optionFilterProp={"children"}
                                                        disabled={loading}
                                                        placeholder={t("LOCALES")}
                                                    >
                                                        {store.locales.map((item) => (
                                                            <Select.Option
                                                                key={item}
                                                                value={item}
                                                                disabled={isSelectOptionDisabled(item)}
                                                            >
                                                                {item.toUpperCase()}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Tooltip placement="topRight" title={t("DELETE")}>
                                            <Button
                                                disabled={loading}
                                                style={{ marginTop: index === 0 ? 5 : 0 }}
                                                type="default"
                                                onClick={() => remove(field.name)}
                                                icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                                                className="btn-flex-center"
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, "name"]}
                                            
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                },
                                            ]}
                                        >
                                            <Input type="text" disabled={loading} placeholder={t("NAME")} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, "description"]}
                                            
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                },
                                            ]}
                                        >
                                            <Input.TextArea
                                                cols={5}
                                                rows={5}
                                                disabled={loading}
                                                placeholder={t("DESCRIPTION")}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {fields.length > 1 && index !== fields.length - 1 ? (
                                        <Col span={24}>
                                            <Divider style={{ margin: "0 0 20px 0" }} type="horizontal" />
                                        </Col>
                                    ) : null}
                                </Row>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    disabled={loading}
                                    onClick={() => {
                                        add({});
                                    }}
                                    block
                                    icon={<FontAwesomeIcon icon={["fas", "plus-circle"]} />}
                                >
                                    {t("ADD")}
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
});
