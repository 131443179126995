import React, {useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Link, useParams} from "react-router-dom";
import {PropertyInventoryTask} from "../../../../models/property/property";
import {propertyService} from "../../../../services/property/property.service";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {Button, Col, Descriptions, Row, Typography} from "antd";
import {InventoryTaskStatus} from "../common/inventory-task-status/inventory-task.status";
import {useNavigate} from "react-router";
import Date from "../../../date/date";
import {InventoryTasksViewParts} from "./parts/view.inventory-task.parts";
import {notifications} from "../../../../services/notifications/notifications";
import {PageHeader} from "@ant-design/pro-components";

export const InventoryTasksView: React.FC<ComponentPropsFromRoute> = () => {

    const t = useTranslate();
    const navigate = useNavigate();

    const { id } = useParams<{ id: string }>();

    const [task, setTask] = useState<PropertyInventoryTask | null>(null);

    useEffect(() => {
        reload();
    }, [id]);

    const reload = () => {
        setTask(null);
        return propertyService.getInventoryTask(parseInt(id!)).then(task => setTask(task));
    }

    const close = async () => {
        await propertyService.closeInventoryTask(parseInt(id!));
        await reload();
        notifications.successfully();
    }

    if (!task) {
        return <LoadingSpin />
    }

    return (
        <PageHeader
            style={{ padding: 0 }}
            ghost={false}
            title={
                <Typography.Title level={1} style={{ margin: 0 }}>
                    {t("PROPERTY.INVENTORY_TASK.TITLE")}
                </Typography.Title>
            }
            onBack={() => {
                navigate("/property/inventory-tasks");
            }}
            extra={<InventoryTaskStatus status={task.status} large={true} />}
        >
            <Row gutter={[25, 25]}>
                <Col span={24}>
                    <Row justify={"space-between"}>
                        <Col>
                            <Link to={"/property/review/inventory-task/" + id + "?backToStart=1"}>
                                <Button type={"primary"}>Scan & review</Button>
                            </Link>
                        </Col>
                        <Col>
                            <Button type={"primary"} onClick={close}>{t("CLOSE")}</Button>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <div className="data-descriptions-with-edit-wrapper">
                        <div className="data-content-wrapper">
                            <Descriptions
                                className="data-content-wrapper-descriptions"
                                size={"small"}
                                layout={"vertical"}
                                contentStyle={{ fontSize: "17px" }}
                                labelStyle={{ color: "#858585" }}
                                column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
                            >
                                <Descriptions.Item label={t("YEAR")}>
                                    {task.year}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("PROPERTY.PROPERTY.INVENTORY_TASK.AUTHOR")}>
                                    {task.startedBy.firstName} {task.startedBy.lastName}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("PROPERTY.PROPERTY.INVENTORY_TASK.AUTHOR")}>
                                    <Date dateString={task.createdAt} />
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <Typography.Title level={3}>{t("PROPERTY.INVENTORY_TASK.PARTS.TITLE")}</Typography.Title>
                    <Row gutter={[15, 15]}>
                        <Col>
                            <InventoryTasksViewParts task={task} reload={reload} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </PageHeader>
    )
}