import React, {useState} from "react";
import {
    Modal,
    Form,
    Row,
    Col,
    Button,
    DatePicker,
    Upload
} from "antd";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../../models/core/response";
import {monthlyStatementService} from "../../../../../services/accounting/monthly-statement.service";
import {UploadMonthlyStatement} from "../../../../../models/accounting/monthly-statements";
import {useNavigate} from "react-router";
import {useTranslate} from "../../../../../hooks/translate.hook";
import dayjs from "dayjs";

interface ReportsAccountingMonthlyStatementsUploadModalProps {
    visible: boolean;
    onClose: () => void;
    onSubmit: (report: any) => void;
}

interface FormValue {
    file: File;
    month: dayjs.Dayjs;
}

export const ReportsAccountingMonthlyStatementsUploadModal: React.FC<ReportsAccountingMonthlyStatementsUploadModalProps> = ({ visible, onClose, onSubmit }) => {
    const t = useTranslate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm<FormValue>();

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            style={{top: 20}}
            title={t("REPORTS.ACCOUNTING.MONTHLY-STATEMENTS.UPLOAD.TITLE")}
            okText={t("REPORTS.ACCOUNTING.MONTHLY-STATEMENTS.UPLOAD.TITLE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                size={"middle"}
                form={form}
                layout="vertical"
                name={"client_form"}
                initialValues={{}}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);

                        const dto: UploadMonthlyStatement = {
                            year:  parseInt(values.month.format('YYYY')),
                            month: parseInt(values.month.format('M')),
                            file:  values.file
                        }

                        monthlyStatementService.uploadStatement(dto)
                            .then(
                                (result) => {
                                    form.resetFields();
                                    onClose();
                                    navigate("/reports/monthly-statements/" + result.id);
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                }
                            )
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={[30, 0]} align={"top"}>
                    <Col xxl={24} xl={24} md={24} sm={24} xs={24}>
                        <Row gutter={[10, 0]} align="middle">
                            <Col span={24}>
                                <Form.Item
                                    name={["month"]}
                                    label={t("MONTH")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                        },
                                    ]}
                                >
                                    <DatePicker picker="month" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={["file"]}
                                    valuePropName={""}
                                    getValueFromEvent={e => e.file}
                                    label={t("FILE")}
                                    help={"CSV Fields: Invoice date, Number, Type (Arve, Credit, etc), Subtotal, Tax, Grand total"}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                        },
                                    ]}
                                >
                                    <Upload
                                        maxCount={1}
                                        accept={".csv"}
                                        beforeUpload={() => false}
                                    >
                                        <Button>Click to upload</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}