import {FilterKey} from "../../../models/filtered-table/filtered.table.props";
import {DeclarationStatuses} from "../../../models/declarations/declaration";
import {Translate} from "../../../hooks/translate.hook";
import {Country} from "../../../models/countries/country";

export const filtersFactory = (t: Translate, countries: Country[]): FilterKey[] => {
    return [
        {
            key: "id",
            label: "ID",
            type: "int"
        },
        {
            key: "number",
            label: t("DECLARATIONS.NUMBER"),
            type: "string"
        },
        {
            key: "status",
            label: t("STATUS"),
            type: "select",
            options: Object.values(DeclarationStatuses).map(value => ({value: value, label: t("DECLARATIONS.DECLARATION.STATUS." + value.toUpperCase())}))
        },
        {
            key: "providers",
            label: t("PROVIDERS.TITLE"),
            type: "select",
            options: [{value: "FTC_CPT", label: "FTC_CPT"}, {value: "ANH_CPT", label: "ANH_CPT"}, {value: "ZRP_CPT", label: "ZRP_CPT"}]
            // FTC_CPT, ANH_CPT, ZRP_CPTw
        },
        {
            key: "transportAgreement",
            label: t("DECLARATIONS.AGREEMENT"),
            type: "select",
            options: [{value: "FOB", label: "FOB"}, {value: "CPT", label: "CPT"}, {value: "CFR", label: "CFR"}, {value: "DAP", label: "DAP"}, {value: "FCA", label: "FCA"}]
        },
        {
            key: "originCountry",
            label: t("ORIGINAL_COUNTRY"),
            type: "select",
            options: countries.map(({id, name}) => ({value: id as string, label: name}))
        },
        {
            key: "price",
            label: t("TOTAL_AMOUNT"),
            type: "int"
        },
        {
            key: "invoiceCurrency",
            label: t("CURRENCY"),
            type: "select",
            options: [{value: "USD", label: "USD"}, {value: "RUB", label: "RUB"}, {value: "EUR", label: "EUR"}]
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date"
        }
    ];
}