import { AxiosInstance } from "axios";
import API from "../../utils/api";
import {PurchasingAlliance} from "../../models/settings/purchasing-alliances";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";

export class PurchasingAlliancesService {
    private api: AxiosInstance = API;

    getAll(type: string): Promise<PurchasingAlliance[]> {
        return this.api
            .get("settings/purchase-alliances/all/" + type)
            .then((x) => x.data);
    }

    getList(req: PaginatedRequest): Promise<PaginatedResult<PurchasingAlliance>> {
        return this.api
            .get("settings/purchase-alliances", {params: req.toParams()})
            .then((x) => x.data);
    }

    create(data: any): Promise<PurchasingAlliance> {
        return this.api
            .post("settings/purchase-alliances", data)
            .then((x) => x.data);
    }

    update(id: number, data: any): Promise<PurchasingAlliance> {
        return this.api
            .post("settings/purchase-alliances/" + id, data)
            .then((x) => x.data);
    }

    remove(id: number): Promise<any> {
        return this.api
            .delete("settings/purchase-alliances/" + id)
            .then((x) => x.data);
    }
}

export const purchasingAlliancesService = new PurchasingAlliancesService();
