import React, {useState} from "react";
import {useRequiredStore} from "../../../../../../utils/store";
import {Button, Descriptions, Tooltip, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserGroupsStoreContext} from "../../user.groups.store";
import {UsersGroupsModal} from "../../list/users.groups.modal";
import {observer} from "mobx-react";
import {PageHeader} from "@ant-design/pro-components";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {useNavigate} from "react-router";


export const GroupsDataHeader: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(UserGroupsStoreContext);
    const [isModalShown, setIsModalShown] = useState<boolean>(false);
    const navigate = useNavigate();

    return (
        <>
            <PageHeader
                style={{padding: 0}}
                ghost={false}
                title={<Typography.Title level={1} style={{margin: 0}}>{store.currentGroup?.name}</Typography.Title>}
                onBack={() => {
                    navigate("/users/groups");
                }}
            >
                <div className="data-descriptions-with-edit-wrapper">
                    <div className="data-content-wrapper">
                        <Descriptions
                            className="data-content-wrapper-descriptions"
                            size={"small"}
                            layout={"vertical"}
                            contentStyle={{fontSize: "17px"}}
                            labelStyle={{color: "#858585"}}
                            column={{xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1}}
                        >
                            <Descriptions.Item label={t("GROUP.ID")}>{store.currentGroup?.id}</Descriptions.Item>
                            <Descriptions.Item label={t("NAME")}>{store.currentGroup?.name}</Descriptions.Item>
                            <Descriptions.Item label={t("SYSTEM")}>{store.currentGroup?.system.name}</Descriptions.Item>
                        </Descriptions>
                        <Tooltip placement="topRight" title={t("EDIT")}>
                            <Button
                                className="btn data-description-btn"
                                onClick={() => setIsModalShown(true)}
                                icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                            />
                        </Tooltip>
                    </div>
                </div>
            </PageHeader>
            <UsersGroupsModal
                visible={isModalShown}
                onClose={() => setIsModalShown(false)}
                onSubmit={store.editGroup}
                item={{name: store.currentGroup!.name, system: store.currentGroup!.system.id}}
            />
        </>
    );
})
