import React, {useEffect, useMemo, useState} from "react";
import {ColumnsType} from "antd/lib/table";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {Col, Row, Table} from "antd";
import {TableRowSelection} from "antd/es/table/interface";
import {columnsFactory} from "./agent.data.invoices.new.invoices-table.columns";
import {Invoice} from "../../../../../../models/documents/invoices/invoice";
import {invoiceService} from "../../../../../../services/documents/invoices/invoices.service";

interface AgentDataInvoicesNewInvoicesTableProps {
    accountId: number;
    onChange?: (value: number | undefined) => void;
    value?: number
}

export const AgentDataInvoicesNewInvoicesTable: React.FC<AgentDataInvoicesNewInvoicesTableProps> = ({accountId, onChange, value}) => {

    const t = useTranslate();

    const [documents, setDocuments] = useState<Invoice[]>([]);

    const columns: ColumnsType<Invoice> = useMemo(() => columnsFactory(t), [t])

    const [loading, setLoading] = useState<boolean>(false);

    const rowSelection: TableRowSelection<Invoice> = {
        type: "radio",
        selectedRowKeys: value ? [value] : [],
        onChange: (selectedRowKeys: React.Key[], selectedRows: Invoice[]) => {
            onChange && onChange(selectedRowKeys[0] as number | undefined);
        }
    }

    useEffect(() => {
        setLoading(true);
        invoiceService.getAllNewInvoices(accountId).then(documents => {
            setDocuments(documents);
            setLoading(false);
        });
    }, [accountId])

    return (
        <Row>
            <Col span={12}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={documents}
                    pagination={false}
                    rowKey={"id"}
                    rowSelection={rowSelection}
                />
            </Col>
        </Row>
    );
}