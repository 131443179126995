import React, {useState} from "react";
import {QrCodeScanner} from "../../../../../common/qr-code-scanner/qr-code-scanner";
import {Space, Typography} from "antd";
import {useNavigate} from "react-router";
import {useOutletContext} from "react-router-dom";
import {ReviewContext} from "../../review.inventory-tasks.model";
import {UiInputWidgetPropertySearch} from "../../../../ui/input/widget/property-search/ui.input.widget.property-search";
import {WidgetPropValue} from "../../../../../common/ui/input/widget/widget";

export interface ScanStepProps {
}

export const ScanStep: React.FC<ScanStepProps> = () => {

    const ctx = useOutletContext<ReviewContext>();

    const [manually, setManually] = useState<boolean>(false);
    const navigate = useNavigate();

    const complete = (id: WidgetPropValue) => {
        navigate('/property/review/inventory-task/' + ctx.task.id + '/review/' + id)
    }

    if (manually) {
        return (
            <>
                <Typography.Title level={1} style={{marginBottom: 0}}>Please enter code</Typography.Title>
                <Typography.Paragraph>or <a onClick={() => setManually(false)}>Scan QR-code</a></Typography.Paragraph>
                <Space direction={"vertical"}>
                    <UiInputWidgetPropertySearch onChange={complete} />
                </Space>
            </>
        );
    }

    return (
        <>
            <Typography.Title level={1} style={{marginBottom: 0}}>Please scan QR-code</Typography.Title>
            <Typography.Paragraph>or <a onClick={() => setManually(true)}>Enter manually</a></Typography.Paragraph>
            <QrCodeScanner onDecode={v => complete(v)} />
        </>
    )
}