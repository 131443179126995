import React, {useState} from "react";
import {observer} from "mobx-react";
import {Col, Form, Input, Modal, Row} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Shipment} from "../../../../models/parcels/shipment";
import {shipmentsService} from "../../../../services/shipments/shipments.service";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";

interface EditModalProps {
    shipment: Shipment
    onClose: () => void
    onComplete: () => void
}

export const EditModal: React.FC<EditModalProps> = observer(({shipment, onClose, onComplete}) => {
    const t = useTranslate();

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("SHIPMENT.EDIT")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"order_data"}
                initialValues={{
                    reference: shipment.reference
                }}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        shipmentsService.edit(shipment.id, values)
                            .then(() => {
                                form.resetFields();
                                onComplete();
                                return onClose();
                            }, (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                            .then(() =>
                                // onClose(),
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="reference"
                            label={t("REFERENCE.TITLE")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input type="text" placeholder={t("REFERENCE.TITLE")}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
});
