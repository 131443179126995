import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {PriceList} from "../../models/price-lists/price.lists";


export class PriceListsService {
    api: AxiosInstance = API;

    getPriceLists(): Promise<PriceList[]> {
        return this.api.get<PriceList[]>("prices/all").then(x => x.data);
    }
}

export const priceListsService = new PriceListsService();

//
