import { FilterKey } from "../filtered-table/filtered.table.props";
import { FieldTypeName } from "./filter.field.type.name";
import { FilterFormValueFieldValues } from "./filter.form.value";

export interface FieldChoice {
    id?: number | string;
    name: string;
    value: any;
}

export interface FieldGroupedChoice {
    groupName: string;
    choices: FieldChoice[];
}

export type FieldConfigChoices = FieldChoice[] | FieldGroupedChoice[];

// конфигурация поля

export interface FieldConfig {
    name: string; // имя, которое уходит на бэк
    type: FieldTypeName; // название типа поля (ENUM)
    requireFields?: string[]; // поля, которые оно требует до того, пока может быть выбрано
    choices?: FieldConfigChoices;
    filterRegExp?: RegExp | string;
    //groupedChoices?: FieldGroupedChoice[];
    multiple?: boolean;
    choicesLoader?: (fieldValues: FilterFormValueFieldValues) => Promise<FieldConfigChoices>;
}

// конфигурация группы полей
// Передаю пропсами
export interface FieldGroupConfig {
    // поле, которое выводится в списке
    visibleName: string; // имя, которое видит клиент t(visibleName)
    name: string; // groupName
    fields: FieldConfig[]; // поля, которые группа в себя включает (в большинстве случаев это одно поле, но когда есть созависимые поля, то несколько)
}

export const createFieldGroupConfigFromFilterKeys = (filterKeys: FilterKey[]): FieldGroupConfig[] => {
    return filterKeys.map((filterKey) => ({
        name: filterKey.key,
        visibleName: filterKey.label,
        fields: [
            {
                name: filterKey.key,
                type: filterKey.type as FieldTypeName,
                multiple: filterKey.type === FieldTypeName.SELECT,
                choices: filterKey.options
                    ? filterKey.options.map((item) => ({ value: item.value, name: item.label }))
                    : [],
            },
        ],
    }));
};
