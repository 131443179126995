
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { Filters } from "../../../../models/filter/filter.props";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import { articlesPrisesService } from "../../../../services/articles/articles/articles.prices.service";
import { ArticlesPrices, Price } from "../../../../models/articles/articles.prices";
import { Column } from "../../../../models/core/columns";
import { displayPrice } from "../../../../models/prices/price.helper";
import { Link } from "react-router-dom";
import {Button, Checkbox, Col, Dropdown, Form, Input, Menu, Row, Select, Space, Typography} from "antd";
import { useRequiredStore } from "../../../../utils/store";
import { RootStoreContext } from "../../../../stores/root/root.store";
import { PaginatedRequest } from "../../../../models/core/paginated.request";
import { Export } from "../../../common/export/export";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
import { FilterKey } from "../../../../models/filtered-table/filtered.table.props";
import { ArticlesLinks } from "../articles-links/articles.links";
import {ArticlesExportModal} from "../export/articles.export.modal";

interface ArticlesPricesListProps extends ComponentPropsFromRoute {}
export const ArticlesPricesList: React.FC<ArticlesPricesListProps> = observer(({ name }) => {
    const {
        commonStore: { priceLists },
    } = useRequiredStore(RootStoreContext);
    const t = useTranslate();

    const [exportModalShown, setExportModalShown] = useState<boolean>(false);
    const [oldExportModalShown, setOldExportModalShown] = useState<boolean>(false);

    const [request, setRequest] = useState<PaginatedRequest | null>(null);

    const columns: any[] = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("CODE"),
            dataIndex: "name",
            key: "name",
            render: (name: string) =>
                name.toUpperCase() !== "DELIVERY" ? (
                    <Link style={{ whiteSpace: "nowrap" }} to={"/articles/" + name}>
                        {name}
                    </Link>
                ) : (
                    name
                ),
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "description",
        },
    ];

    const columnsConfigs = [
        {
            title: "ID",
            key: "id",
        },
        {
            title: "CODE",
            key: "name",
        },
        {
            title: "DESCRIPTION",
            key: "description",
        },
    ];

    const filters: FilterKey[] = [
        {
            key: "id",
            label: "ID",
            type: "string",
        },
        {
            key: "name",
            label: t("CODE"),
            type: "string",
        },
        {
            key: "description",
            label: t("DESCRIPTION"),
            type: "string",
        },
    ];

    if (priceLists) {
        priceLists.forEach((price) => {
            const column: Column = {
                title: price.name,
                dataIndex: price.id + "",
                key: price.id + "",
                render: (_, row: ArticlesPrices) => {
                    const foundItem: Price | undefined = row.prices.find((item) => item.price.name === price.name);
                    if (foundItem) {
                        return <>{displayPrice(foundItem.amount)}</>;
                    }
                    return <></>;
                },
            };
            const columnsConfig: { title: string; key: string } = {
                title: price.name,
                key: price.id + "",
            };
            columnsConfigs.push(columnsConfig);
            columns.push(column);
        });
        filters.push({
            key: "priceList",
            label: t("ARTICLES.PRICES.TITLE"),
            type: "select",
            options: priceLists.map((item) => ({
                label: item.name,
                value: item.id,
            })),
        });
    }

    const defaultValues = {
        prices: priceLists.map((item) => item.id),
        discount: "0",
    };

    const getRequest = (request: PaginatedRequest): void => {
        setRequest(request);
    };

    useEffect(() => {}, [request]);

    return (
        <Space direction="vertical" size={[50, 50]}>
            <ArticlesLinks />
            <Space direction="vertical" size={[0, 0]}>
                <Row justify="space-between" gutter={[0, 0]}>
                    <Typography.Title level={3}>{t("ARTICLES.PRICES.TITLE")}</Typography.Title>
                    <Dropdown
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                <Menu.Item
                                    key={1}
                                    onClick={() => setExportModalShown(true)}
                                >
                                    {t("EXPORT.FULL.TITLE")}
                                </Menu.Item>
                                <Menu.Item
                                    key={2}
                                    onClick={() => setOldExportModalShown(true)}
                                >
                                    {t("EXPORT.ONLY_PRICES.TITLE")}
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button
                            className={"table-page-button"}
                            type={"primary"}
                        >
                            {t("ACTION")}
                        </Button>
                    </Dropdown>
                </Row>

                <FilteredTable
                    reloadOnLngChange
                    getRequest={getRequest}
                    columns={columns}
                    columnsConfigs={{ columns: columnsConfigs, pageKey: name }}
                    filterKeys={filters}
                    loadDataCallback={(request) => articlesPrisesService.getAll(request)}
                />
            </Space>

            {exportModalShown ? (
                <ArticlesExportModal onClose={() => setExportModalShown(false)} />
            ) : null}

            {oldExportModalShown ? (
                <Export
                    title={"ARTICLES.PRICES.DOWNLOAD.TITLE"}
                    withBasicExportFields
                    isShown={true}
                    config={{
                        link: "/api/v3/articles/prices",
                    }}
                    additionalDefaultValues={defaultValues}
                    onClose={() => setOldExportModalShown(false)}
                >
                    <Col span={24}>
                        <Form.Item
                            label={t("DISCOUNT")}
                            name={"discount"}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                },
                            ]}
                        >
                            <Input placeholder={t("DISCOUNT")} type={"number"} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={t("ARTICLES.PRICES.TITLE")}
                            name={"prices"}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp={"children"}
                                mode="multiple"
                                placeholder={t("ARTICLES.PRICES.TITLE")}
                            >
                                {priceLists.map(({ id, name }) => (
                                    <Select.Option key={id} value={id}>
                                        {name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Export>
            ) : null}
        </Space>
    );
});
