import {DeclarationStatus} from "../../../models/declarations/declaration";
import {Translate} from "../../../hooks/translate.hook";
import {Link} from "react-router-dom";
import {DeclarationsStatus} from "../status/declaration.status";
import {Amount} from "../../../models/prices/price";
import {displayPrice} from "../../../models/prices/price.helper";
import Date from "../../date/date";
import React from "react";

export const columnsFactory = (t: Translate): any[] => {
    return [
        {
            title: "ID",
            dataIndex: "id",
            key: "id"
        },
        {
            title: t("DECLARATIONS.NUMBER"),
            dataIndex: "number",
            key: "number",
            render: (number: string) => <Link to={"/declaration/" + number}>{number}</Link>
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            render: (status: DeclarationStatus) => <DeclarationsStatus status={status} />
        },
        {
            title: t("PROVIDERS.TITLE"),
            dataIndex: "providers",
            key: "providers",
            render: (providers: string[]) => <>{providers.map((p: any) => p.code).join(", ")}</>
        },
        {
            title: t("DECLARATIONS.AGREEMENT"),
            dataIndex: "transportAgreement",
            key: "transportAgreement"
        },
        {
            title: t("ORIGINAL_COUNTRY"),
            dataIndex: "providersCountry",
            key: "providersCountry",
            render: (country: {id: string, name: string}) => <>{country.id}</>
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "price",
            key: "price",
            render: (value: Amount) => displayPrice(value)
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (item: string) => <Date dateString={item} showTime={true}/>
        }
    ];
}