import {FieldGroupConfig} from "../../../../models/filter/filter.group";
import {FieldTypeName} from "../../../../models/filter/filter.field.type.name";
import {Translate} from "../../../../hooks/translate.hook";
import {Warehouse} from "../../../../models/warehouses/warehouse";

export const filterGroupsFactory = (t: Translate, warehouses: Warehouse[]): FieldGroupConfig[] =>   [
    {
        name: "document",
        visibleName: "INVOICES.NUMBER",
        fields: [
            {
                name: "docNumber",
                type: FieldTypeName.STRING,
            },
        ],
    },
    {
        name: "order",
        visibleName: "ORDERS.ORDER.TITLE",
        fields: [
            {
                name: "id",
                type: FieldTypeName.STRING,
            },
        ],
    },
    {
        name: "client",
        visibleName: "CLIENT.NAME",
        fields: [
            {
                name: "clientName",
                type: FieldTypeName.STRING,
            },
        ],
    },
    {
        name: "totalAmount",
        visibleName: "TOTAL_AMOUNT",
        fields: [
            {
                name: "grandTotal",
                type: FieldTypeName.INTEGER,
            },
        ],
    },
    {
        name: "weight",
        visibleName: "WEIGHT_BRUTTO",
        fields: [
            {
                name: "totalGrossWeight",
                type: FieldTypeName.INTEGER,
            },
        ],
    },
    {
        name: "status",
        visibleName: t("STATUS"),
        fields: [
            {
                type: FieldTypeName.SELECT,
                name: "status",
                choices: ["opened", "closed", "pre_closed"].map((value) => ({
                    value: value,
                    name: t("INVOICES.STATUS." + value.toUpperCase()),
                }))
            }
        ]
    },
    {
        name: "date",
        visibleName: "INVOICES.DISPATCH.DISPATCH_DATE",
        fields: [
            {
                name: "date",
                type: FieldTypeName.DATE_RANGE,
            },
        ],
    },
    {
        name: "warehouses",
        visibleName: "WAREHOUSES.ID",
        fields: [
            {
                name: "warehouses",
                type: FieldTypeName.SELECT,
                multiple: true,
                choices: warehouses.map(({ name, id }) => ({ value: id, name })),
            },
        ],
    },
];