import {ColumnsType} from "antd/lib/table/interface";

export const columnsFactory = (): ColumnsType<any> => {
    return [
        {
            title: "SKU",
            dataIndex: "sku",
            key: "sku"
        },
        {
            title: "Field Name",
            dataIndex: "fieldName",
            key: "fieldName"
        },
        {
            title: "Old Value",
            dataIndex: "oldValue",
            key: "oldValue",
            onCell: () => ({style: {backgroundColor: "rgba(255,62,62,0.2)"}})
        },
        {
            title: "New Value",
            dataIndex: "newValue",
            key: "newValue",
            onCell: () => ({style: {backgroundColor: "rgba(62,255,107,0.2)"}})
        }
    ];
}