import React, {useMemo} from "react";
import {Button, Space} from "antd";
import { RouteConfig } from "../../../routers/routers";
import {useParams, useLocation} from "react-router-dom";
import {useTranslate} from "../../../hooks/translate.hook";
import { ExtendedSwitch } from "../../core/router/extended-switch/extended.switch";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import {isAllowed} from "../../../utils/helpers";
import {useNavigate} from "react-router";

export interface RouteTabsProps {
    customSpliceNum?: number;
    withoutRootTabPath?: boolean;
    routes: RouteConfig[];
}

const bindPathParams = (path: string, params: any) => {
    for (let key in params) {
        path = path.replace(":" + key, params[key]);
    }

    return path;
};

export const RouteButtons = <T extends object>({
                                                routes,
                                                withoutRootTabPath = false,
                                                customSpliceNum = 4,
                                            }: RouteTabsProps) => {

    const t = useTranslate();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const params = useParams();
    const rootTabPath = withoutRootTabPath ? pathname : pathname.split("/").slice(0, customSpliceNum).join("/");

    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);

    const tabs = useMemo(() => routes.filter(route => isAllowed(attributes, route.access) && !route.hidden), []);

    return (
        <Space direction={"vertical"} size={[40, 40]}>
            <Space direction={"horizontal"}>
                {tabs.map((route) => {
                    const key = bindPathParams(route.path, params);
                    return (
                        <Button
                            key={key}
                            onClick={() => navigate(key)}
                            type={rootTabPath === key ? "primary" : "default"}
                        >
                            {t(route.systemName)}
                        </Button>
                    );
                })}
            </Space>
            <ExtendedSwitch />
        </Space>
    );
};
