import React, {useMemo, useState} from "react";
import {PropertyInventoryTask, PropertyInventoryTaskPart} from "../../../../../models/property/property";
import {Table} from "antd";
import {columnFactory} from "./view.inventory-task.parts.columns";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {propertyService} from "../../../../../services/property/property.service";
import {downloadBlobFile} from "../../../../../utils/blob.download";
import {PartEditModal} from "./edit/part-edit.modal";
import {EditDataModal} from "../../part-write-off/edit-data-modal/edit-data.modal";
import {useNavigate} from "react-router";

interface InventoryTasksViewPartsProps {
    task: PropertyInventoryTask;
    reload: () => void;
}

export const InventoryTasksViewParts: React.FC<InventoryTasksViewPartsProps> = ({task, reload}) => {

    const t = useTranslate();
    const navigate = useNavigate();

    const [editModalItem, setEditModalItem] = useState<PropertyInventoryTaskPart | null>(null);
    const [documentModalItem, setDocumentModalItem] = useState<PropertyInventoryTaskPart | null>(null);

    const onDownload = (part: PropertyInventoryTaskPart) => {
        propertyService.downloadInventoryPartPDF(part.id).then(
            ({blob, filename}) => downloadBlobFile(blob, filename)
        );
    }

    const onDownloadWriteOffList = (part: PropertyInventoryTaskPart) => {
        propertyService.downloadInventoryPartWriteOffListPDF(part.id).then(
            ({blob, filename}) => downloadBlobFile(blob, filename)
        );
    }

    const onCreateWriteOff = (part: PropertyInventoryTaskPart) => {
        setDocumentModalItem(part);
    }

    const onDownloadIgnoredList = (part: PropertyInventoryTaskPart) => {
        propertyService.downloadInventoryPartIgnoredListPDF(part.id).then(
            ({blob, filename}) => downloadBlobFile(blob, filename)
        );
    }

    const onEdit = (part: PropertyInventoryTaskPart) => {
        setEditModalItem(part);
    }

    const onEditCompleted = () => {
        setEditModalItem(null);
        reload();
    }

    const onDocumentCreationCompleted = (part: PropertyInventoryTaskPart, result: boolean) => {
        setDocumentModalItem(null);
        if (result) {
            navigate('/property/inventory-tasks/part/' + part.id + '/write-off');
        } else {
            reload();
        }
    }

    const columns = useMemo(() => columnFactory(t, onDownload, onDownloadWriteOffList, onCreateWriteOff, onDownloadIgnoredList, onEdit), [t])

    return (
        <>
            <Table
                columns={columns}
                dataSource={task.parts}
                pagination={false}
            />
            {editModalItem ? (
                <PartEditModal
                    part={editModalItem}
                    onClose={onEditCompleted}
                />
            ) : null}
            {documentModalItem ? (
                <EditDataModal
                    part={documentModalItem}
                    onClose={onDocumentCreationCompleted}
                />
            ) : null}
        </>
    )
}