export const createPayloadFromFormData = (values: any): any => {
    return {
        name:            values.name,
        description:     values.description,
        placeAndAddress: values.placeAndAddress,
        startingAt:      values.startingAt.format('YYYY-MM-DD'),
        endingAt:        values.endingAt.format('YYYY-MM-DD'),
        generations:     values.generations.map((generation: any) => {
            const payload: any = {
                employee:        generation.employee,
                from:            generation.range[0].format('YYYY-MM-DD'),
                until:           generation.range[1].format('YYYY-MM-DD'),
                timeFromHour:    parseInt(generation.timeFrom.format('H')),
                timeFromMinute:  parseInt(generation.timeFrom.format('m')),
                timeUntilHour:   parseInt(generation.timeUntil.format('H')),
                timeUntilMinute: parseInt(generation.timeUntil.format('m'))
            }
            if (generation.lunchTimeFrom && generation.lunchTimeUntil) {
                payload.lunchTimeFromHour    = parseInt(generation.lunchTimeFrom.format('H'));
                payload.lunchTimeFromMinute  = parseInt(generation.lunchTimeFrom.format('m'));
                payload.lunchTimeUntilHour   = parseInt(generation.lunchTimeUntil.format('H'));
                payload.lunchTimeUntilMinute = parseInt(generation.lunchTimeUntil.format('m'));
            }
            return payload;
        })
    }
}