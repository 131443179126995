import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Space, Table} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {BranchDataWarehousesPriorityGroupsNewModal} from "./branch.data.warehouse.priority-groups.new.modal";
import {WarehousePriorityGroup} from "../../../../../models/warehouses/warehouse";
import {branchesService} from "../../../../../services/branches/branches.service";
import {LoadingSpin} from "../../../../common/loading-spin/loading.spin";
import {columnsFactory} from "./branch.data.warehouse.priority-groups.columns";

export const BranchDataWarehousesPriorityGroups: React.FC = () => {
    const {branchId} = useParams<{ branchId: string }>();
    const t = useTranslate();

    const [loading, setLoading] = useState<boolean>(true);

    const [editableGroup, setEditableGroup] = useState<WarehousePriorityGroup | null>(null);
    const [createModalShown, setCreateModalShown] = useState<boolean>(false);

    const [priorityGroups, setPriorityGroups] = useState<WarehousePriorityGroup[]>([]);

    const reloadGroups = () => {
        setLoading(true);
        branchesService.getAllWarehousePriorityGroups(branchId!).then(priorityGroups => {
            setPriorityGroups(priorityGroups);
            setLoading(false);
        });
    }

    useEffect(() => {
        reloadGroups();
    }, []);

    const onCompleteEdit = async (sourceGroup: WarehousePriorityGroup | null, group: WarehousePriorityGroup) => {
        let newGroup: WarehousePriorityGroup;

        if (sourceGroup) {
            newGroup = await branchesService.updateWarehousePriorityGroup(sourceGroup.id, group);
            reloadGroups();
        } else {
            newGroup = await branchesService.createWarehousePriorityGroup(branchId!, group);
            reloadGroups();
        }

        setCreateModalShown(false);

        return newGroup;
    }

    const onRuleEdit = (group: WarehousePriorityGroup | null) => {
        setCreateModalShown(true);
        setEditableGroup(group);
    }

    const onCancelRuleEdit = () => {
        setCreateModalShown(false);
        setEditableGroup(null);
    }

    const columns = useMemo(() => columnsFactory(t, onRuleEdit), []);

    if (loading) {
        return <LoadingSpin />
    }

    return <Space direction="vertical" size={[30, 30]}>
        <Space direction="horizontal" size={[10, 10]}>
            <Button className="ant-btn ant-btn-primary" onClick={() => onRuleEdit(null)}>
                {t("BRANCH.DATA.WAREHOUSES.PRIORITY_GROUPS.ADD")}
            </Button>
        </Space>
        <Table
            columns={columns}
            dataSource={priorityGroups}
            pagination={false}
        />
        {createModalShown ?
            <BranchDataWarehousesPriorityGroupsNewModal
                group={editableGroup}
                onSubmit={onCompleteEdit}
                onClose={() => onCancelRuleEdit()}
            />
            : null}
    </Space>
}