import React, {useState} from "react";
import {observer} from "mobx-react";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {Col, DatePicker, Form, Modal, Row} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {InvoiceData} from "../../../../models/documents/invoices/invoice-data/invoice.data";
import {invoiceService} from "../../../../services/documents/invoices/invoices.service";
import {notifications} from "../../../../services/notifications/notifications";
import dayjs from "dayjs";

interface InvoicesChangeDateModalProps {
    invoice: InvoiceData,
    visible: boolean,
    onClose: () => void,
    onSuccess: () => void
}
export const InvoicesChangeDateModal: React.FC<InvoicesChangeDateModalProps> = observer(({invoice, visible, onClose, onSuccess}) => {

    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();


    const dateFormat: string = "DD-MM-YYYY";


    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={t("INVOICES.SET-DATE.TITLE")}
            okText={t("OK")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"set_date_modal"}
                initialValues={{
                    date: dayjs()
                }}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        invoiceService.setDate(invoice.id.toString(), dayjs(values.date).format("YYYY-MM-DD"))
                            .then(() => {
                                form.resetFields();
                                onSuccess();
                                notifications.successfully()
                                return onClose();
                            }, (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                            .then(() =>
                                onClose(),
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="date"
                            label={t("DATE")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <DatePicker
                                disabled={loading}
                                style={{width: "100%"}}
                                placeholder={t("DATE")}
                                format={dateFormat}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
});
