import React, {useState} from "react";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {Amount} from "../../../../models/prices/price";
import {displayPrice} from "../../../../models/prices/price.helper";
import Date from "../../../date/date";
import {Button, Col, DatePicker, Form, Select, Space, Typography} from "antd";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import {useTranslate} from "../../../../hooks/translate.hook";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {ColumnsType} from "antd/es/table";
import {MergedInvoice} from "../../../../models/documents/invoices/merged-invoice/merged.invoice";
import {MergedInvoicesListStore} from "./merged.invoices.list.store";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {DocumentsMenu} from "../../../common/documents/menu/documents.menu";
import {DocumentStatus} from "../../../../models/documents/status";
import {DocumentsStatus} from "../../../common/documents/status/documents.status";
import {InvoicesDispatchModal} from "../invoices-dispatch-modal/invoices.dispatch.modal";
import {DebounceSelect} from "../../../common/debounce-select/debounce.select";
import {Export} from "../../../common/export/export";
import {mergedInvoiceService} from "../../../../services/documents/invoices/merged.invoices.service";
import {FieldGroupConfig} from "../../../../models/filter/filter.group";
import {FieldTypeName} from "../../../../models/filter/filter.field.type.name";
import {InvoicesExportModal} from "../invoices-export-modal/invoices.export.modal";
import dayjs from "dayjs";

interface MergedInvoicesListProps extends ComponentPropsFromRoute {}

export const MergedInvoicesList: React.FC<MergedInvoicesListProps> = observer(({ name }) => {
    const t = useTranslate();
    const [store] = useState(() => new MergedInvoicesListStore());

    const {
        commonStore: { warehouses, mergedInvoiceStatuses },
    } = useRequiredStore(RootStoreContext);

    const columns: ColumnsType<MergedInvoice> = [
        {
            title: t("INVOICES.NUMBER"),
            dataIndex: "docNumber",
            key: "docNumber",
            width: "140px",
            render: (docNumber, row) => <Link to={"/merged-invoices/" + row.id}>{docNumber}</Link>,
        },
        {
            title: t("INVOICING.CAN_BE_CLOSED_AFTER"),
            dataIndex: "canBeClosedAfter",
            key: "canBeClosedAfter",
            render: (date: string) => <Date dateString={date} />,
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            render: (status: DocumentStatus) => <DocumentsStatus status={status} />,
        },
        {
            title: t("CLIENT.NAME"),
            dataIndex: "agent",
            key: "order_client",
            render: (agent) => <Link to={"/clients/" + agent.number}>{agent.name}</Link>,
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "qty",
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "grandTotal",
            key: "grandTotal",
            render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
        },
        {
            title: t("TOTAL_PRICE"),
            dataIndex: "subtotal",
            key: "subtotal",
            render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
        },
        {
            title: "VAT",
            dataIndex: "subtotalVat",
            key: "subtotalVat",
            render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
        },
        {
            title: t("DATE"),
            dataIndex: "date",
            key: "date",
            render: (date: string) => <Date dateString={date} showTime withSeconds />,
        },
        // {
        //     title: t("ACTION"),
        //     key: "action",
        //     className: "table-button-cell",
        //     width: 50,
        //     render: (_: any, item) => (
        //         <Row justify="center">
        //             <Popconfirm
        //                 title={t("ARE_YOU_SURE")}
        //                 onConfirm={() => store.deleteMergedInvoice(item)}
        //                 placement="left"
        //                 okText={t("YES")}
        //                 cancelText={t("NO")}
        //             >
        //                 <Tooltip placement="topRight" title={t("REMOVE")}>
        //                     <Button className="table-btn btn-remove btn" type="default"
        //                             icon={<FontAwesomeIcon icon={["fas", "times"]}/>}/>
        //                 </Tooltip>
        //             </Popconfirm>
        //         </Row>
        //     )
        // }
    ];
    const columnsConfigs = [
        {
            title: "INVOICES.NUMBER",
            key: "docNumber",
        },
        {
            title: "CLIENT.NAME",
            key: "order_client",
        },
        {
            title: "QUANTITY",
            key: "qty",
        },
        {
            title: "TOTAL_AMOUNT",
            key: "grandTotal",
        },
        {
            title: "TOTAL_PRICE",
            key: "subtotal",
        },
        {
            title: "VAT.TITLE",
            key: "subtotalVat",
        },
        {
            title: "DATE",
            key: "date",
        },
    ];

    const filters: FieldGroupConfig[] = [
        {
            name: "number",
            visibleName: t("INVOICES.NUMBER"),
            fields: [
                {
                    type: FieldTypeName.STRING,
                    name: "number"
                }
            ]
        },
        {
            name: "status",
            visibleName: t("STATUS"),
            fields: [
                {
                    type: FieldTypeName.SELECT,
                    name: "status",
                    choices: mergedInvoiceStatuses.map((value) => ({
                        value: value,
                        name: t("INVOICES.STATUS." + value.toUpperCase()),
                    }))
                }
            ]
        },
        {
            name: "clientName",
            visibleName: t("CLIENT.NAME"),
            fields: [
                {
                    type: FieldTypeName.STRING,
                    name: "clientName"
                }
            ]
        },
        {
            name: "qty",
            visibleName: t("QUANTITY"),
            fields: [
                {
                    type: FieldTypeName.INTEGER,
                    name: "qty"
                }
            ]
        },
        {
            name: "grandTotal",
            visibleName: t("TOTAL_AMOUNT"),
            fields: [
                {
                    type: FieldTypeName.INTEGER,
                    name: "grandTotal"
                }
            ]
        },
        {
            name: "subtotal",
            visibleName: t("TOTAL_PRICE"),
            fields: [
                {
                    type: FieldTypeName.INTEGER,
                    name: "subtotal"
                }
            ]
        },
        {
            name: "subtotalVat",
            visibleName: "VAT.TITLE",
            fields: [
                {
                    type: FieldTypeName.INTEGER,
                    name: "subtotalVat"
                }
            ]
        },
        {
            name: "date",
            visibleName: t("DATE"),
            fields: [
                {
                    type: FieldTypeName.DATE_RANGE,
                    name: "date"
                }
            ]
        },
        {
            name: "canBeClosedNow",
            visibleName: t("INVOICES.CAN_BE_CLOSED_NOW"),
            fields: [
                {
                    type: FieldTypeName.BOOL,
                    name: "canBeClosedNow"
                }
            ]
        }
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: MergedInvoice[]) => {
            store.checkInvoicesToDispatch(selectedRowKeys);
        },
        getCheckboxProps: (record: MergedInvoice) => ({
            name: String(record.id),
            // disabled: record.status === "closed"
        }),
    };

    return (
        <Space direction="vertical" size={[50, 50]}>
            <DocumentsMenu />
            <Space direction="vertical" size={[0, 0]}>
                <Typography.Title level={3}>{t("INVOICES.MERGED.TITLE")}</Typography.Title>
                <FilteredTable
                    rowSelection={{
                        type: "checkbox",
                        selectedRowKeys: store.checkedInvoices,
                        ...rowSelection,
                    }}
                    columns={columns}
                    columnsConfigs={{ columns: columnsConfigs, pageKey: name }}
                    filterGroups={filters}
                    filterButtons={store.filterButtonConfigs}
                    dataHandler={store.dataHandler}
                    getRequest={store.setRequest}
                    buttons={[
                        <Button
                            key={0}
                            disabled={store.checkedInvoices.length === 0}
                            onClick={() => store.handleModal("export", true)}
                            type="primary"
                        >
                            {t("ORDERS.ORDER.VIEW.GENERATE_EXPORT_INVOICE")}
                        </Button>,
                        <Button
                            key={1}
                            disabled={store.checkedInvoices.length === 0}
                            onClick={() => store.handleModal("dispatch", true)}
                            type="primary"
                        >
                            {t("DISPATCH.DO_DISPATCH")}
                        </Button>,
                        <Button
                            key={2}
                            onClick={() => store.handleModal("download", true)}
                            type="primary"
                        >
                            {t("INVOICES.DISPATCH.DOWNLOAD")}
                        </Button>,
                    ]}
                />
            </Space>
            <InvoicesDispatchModal
                visible={store.dispatchModalShown}
                onClose={() => store.handleModal("dispatch", false)}
                onInvoicesDispatched={store.onDispatched}
                invoicesToDispatch={store.checkedInvoices}
                dispatchAware={mergedInvoiceService}
            />
            <InvoicesExportModal
                visible={store.exportInvoicesModalShown}
                onSuccess={values => store.createExportInvoices(values)}
                onClose={() => store.handleModal("export", false)}
            />
            <Export
                title={"INVOICES.DISPATCH.DOWNLOAD"}
                isShown={store.downloadModalShown}
                withBasicExportFields={false}
                onClose={() => store.handleModal("download", false)}
                config={{
                    link: "/api/v3/merged-invoices",
                }}
                additionalDefaultValues={{ date: [dayjs(), dayjs()] }}
                prefix={null}
            >
                <Col span={24}>
                    <Form.Item
                        name="date"
                        label={t("INVOICES.DISPATCH.DISPATCH_DATE")}
                        rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                    >
                        <DatePicker.RangePicker
                            style={{ width: "100%" }}
                            //placeholder={t("DATE")}
                            format={store.dateFormat}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="account" label={t("CLIENT.NAME")}>
                        <DebounceSelect
                            placeholder={t("CLIENT.SEARCH")}
                            style={{ width: "100%" }}
                            fetchOptions={store.searchAgents}
                            optionsValueWithLabel={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="warehouses" label={t("WAREHOUSES.TITLE")}>
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            optionFilterProp={"children"}
                            placeholder={t("WAREHOUSES.TITLE")}
                        >
                            {warehouses.map(({ id, name }) => (
                                <Select.Option key={id} value={id}>
                                    {name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Export>
        </Space>
    );
});
