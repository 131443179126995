import { OrderComment, OrderData, OrderDetailsItem, OrderItem } from "../order.data";
import { Button, Space, Tooltip } from "antd";
import Date from "../../../../components/date/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { TableFooterData } from "../../../table-footer/table.footer.data";

export const generateCommentsData = (
    comments: OrderComment[],
    removeAction: (comment: OrderComment) => Promise<void>,
    removeText: string
) => {
    return comments.map((comment) => ({
        author: comment.user.firstName + " " + comment.user.lastName,
        content: <p className="order-comment-content">{comment.message}</p>,
        datetime: (
            <Space align="center" size={[10, 10]} direction="horizontal">
                <Tooltip title={<Date dateString={comment.createdAt} showTime />}>
                    <Date dateString={comment.createdAt} showTime />
                </Tooltip>
                <Tooltip key={1} placement="topRight" title={removeText}>
                    <Button
                        onClick={() => removeAction(comment)}
                        className="btn btn-remove"
                        style={{ marginRight: "5px", border: "none", boxShadow: "none" }}
                        type="default"
                        size={"small"}
                        icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                    />
                </Tooltip>
            </Space>
        ),
    }));
};

export const generateDetailsData = (orderItems: OrderItem[]): OrderDetailsItem[] => {
    return orderItems.map((item) => ({
        id: item.id,
        articleName: item.articleName,
        articleDescription: item.description,
        price: item.price,
        priceType: item.priceType,
        qtyRequested: item.qtyRequested,
        quantity: item.qty,
        totalPrice: item.totalPrice,
        salePercentage: item.salePercentage,
    }));
};

// export const generateDetailsTableFooterData = (order: OrderData): TableFooterData => {
//
//     return {
//         qty: order.itemsQty,
//         taxPercent: order.vatPercent,
//         totalWeight: order.totalNetWeight + " / " + order.totalGrossWeight,
//         taxAmount: order.subtotalVat,
//         rounding: order.rounding,
//         totalAmount: order.subtotal,
//         grandTotalAmount: order.grandTotal,
//         salePercentage: order.salePercentage
//     };
// };
