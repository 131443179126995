import { AxiosInstance } from "axios";
import API from "../../../utils/api";
import { PaginatedRequest } from "../../../models/core/paginated.request";
import { PaginatedResult } from "../../../models/core/paginated.result";
import { Proforma } from "../../../models/documents/proformas/proforma";
import { ProformaData } from "../../../models/documents/proformas/proforma.data";
import { Order } from "../../../models/orders/orders-list/order";
import {OrderComment} from "../../../models/orders/order-data/order.data";

export class ProformasService {
    private api: AxiosInstance = API;

    public getProformas(request: PaginatedRequest): Promise<PaginatedResult<Proforma>> {
        return this.api
            .get<PaginatedResult<Proforma>>("documents/proforma", { params: request.toParams() })
            .then((x) => x.data);
    }

    getAllByAccount(request: PaginatedRequest, accountId: string): Promise<PaginatedResult<Proforma>> {
        request.addFilter("account", accountId)
        return this.api.get<PaginatedResult<Proforma>>(
            'documents/proforma',
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }


    public getProforma(id: string): Promise<ProformaData> {
        return this.api.get<ProformaData>("documents/proforma/" + id).then((x) => x.data);
    }

    public getIncludedOrders(id: number): Promise<Order[]> {
        return this.api.get<Order[]>("documents/proforma/" + id + "/orders").then((x) => x.data);
    }

    public getComments(id: number): Promise<OrderComment[]> {
        return this.api.get<OrderComment[]>("documents/proforma/" + id + "/comments").then((x) => x.data);
    }

    public removeIncludedOrder(proformaId: number, orderId: number) {
        return this.api.delete("documents/proforma/" + proformaId + "/orders/" + orderId);
    }

    public closeProforma(id: number) {
        return this.api.post("documents/proforma/" + id + "/close");
    }

    public addAllOrderToProforma(id: number) {
        return this.api.post("documents/proforma/" + id + "/all_orders");
    }

    public addOrdersToProforma(id: number, orders: number[]) {
        return this.api.post("documents/proforma/" + id + "/orders", { orders });
    }

    public getOpenedProformas(accountId: number) {
        return this.api.get<Proforma[]>("documents/proforma/opened/account/" + accountId).then((x) => x.data);
    }

    public updateProformaQuantity(proformaId: number, itemId: number, values: { qty: number }) {
        return this.api.post("documents/proforma/" + proformaId + "/item/" + itemId + "/qty", values);
    }

    public removeItem(proformaId: number, itemId: number) {
        return this.api.delete("documents/proforma/" + proformaId + "/item/" + itemId);
    }

    public cancelProforma(proformaId: number) {
        return this.api.delete("documents/proforma/" + proformaId);
    }

    public cancelProformaWithOrders(proformaId: number) {
        return this.api.delete("documents/proforma/" + proformaId + '/with-orders');
    }
}

export const proformasService = new ProformasService();
