import React, {useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Col, Form, Input, Modal, Row, Select} from "antd";
import {DeclarationProvider} from "../../../upload/declaration.upload.model";
import {UIInputWidgetCountry} from "../../../../common/ui/input/widget/country/ui.input.widget.country";
import {declarationsProvidersService} from "../../../../../services/declarations/declarations.providers.service";

const allowedTransportTypes = ['FOB', 'CPT', 'CFR', 'DAP', 'FCA'];

export interface DeclarationProviderEditModalProps {
    item: DeclarationProvider | null;
    onClose(): void;
}

export const DeclarationProviderEditModal: React.FC<DeclarationProviderEditModalProps> = ({item, onClose}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const initialValues: any = {};
    if (item) {
        initialValues.code = item.code;
        initialValues.name = item.name;
        initialValues.dispatchFrom = item.dispatchFrom;
        initialValues.transportType = item.transportType;
        initialValues.address = item.address;
        initialValues.city = item.city;
        initialValues.zip = item.zip;
        initialValues.country = item.country.id;
    }

    return (
        <Modal
            open={true}
            confirmLoading={loading}
            title={t("PROVIDERS.TITLE")}
            okText={item ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => onClose()}
        >
            <Form
                form={form}
                layout="vertical"
                name={"address_form"}
                initialValues={initialValues}
                onFinish={() =>
                    form.validateFields().then(async (values) => {
                        setLoading(true);
                        try {
                            await (item
                                    ? declarationsProvidersService.update(item.id, values)
                                    : declarationsProvidersService.create(values)
                            );
                            onClose();
                        }
                        catch (e: any) {
                            if (e.response?.data?.fields) {
                                form.setFields(e.response?.data?.fields);
                            }
                            setLoading(false);
                        }
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="code"
                            label={t("PROVIDERS.CODE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                placeholder={t("PROVIDERS.CODE")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label={t("NAME")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                placeholder={t("NAME")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="dispatchFrom"
                            label={t("AGREEMENTS.DISPATCH_PLACE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                placeholder={t("AGREEMENTS.DISPATCH_PLACE")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="transportType"
                            label={t("DECLARATION.PROVIDER.TRANSPORT_TYPE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Select
                                disabled={loading}
                                placeholder={t("DECLARATION.PROVIDER.TRANSPORT_TYPE")}
                            >
                                {allowedTransportTypes.map(type => (
                                    <Select.Option key={type} value={type}>{type}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="address"
                            label={t("ADDRESS")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                placeholder={t("ADDRESS")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="city"
                            label={t("CITY")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                placeholder={t("CITY")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="zip"
                            label={t("INDEX")}
                        >
                            <Input
                                disabled={loading}
                                placeholder={t("INDEX")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="country"
                            label={t("COUNTRY_CODE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <UIInputWidgetCountry />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
