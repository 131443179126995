import { RefObject } from "react";
import { DateFields } from "../../components/common/filtered-table/filter/popup/fields/date.fields";
import { GroupedSelectFields } from "../../components/common/filtered-table/filter/popup/fields/grouped.select.fields";
import { IntFields } from "../../components/common/filtered-table/filter/popup/fields/int.fields";
import { SelectFields } from "../../components/common/filtered-table/filter/popup/fields/select.fields";
import { StringFields } from "../../components/common/filtered-table/filter/popup/fields/string.fields";
import { FieldRuleName, filterRuleMap } from "./filter.field.rule";
import { FieldState } from "./filter.field.state";
import { FieldTypeName } from "./filter.field.type.name";
import { FieldChoice, FieldConfig, FieldConfigChoices, FieldGroupedChoice } from "./filter.group";
import dayjs from "dayjs";

const getLabelFromChoices = (choices: FieldChoice[], value: string): string => {
    const choice = choices.find((item) => item.value === value);
    if (choice) {
        return choice.name;
    }
    return "...";
};

const getLabelFromGroupedChoices = (groupedChoices: FieldGroupedChoice[], value: string): string => {
    let res = "...";
    groupedChoices.forEach((groupedChoice) => {
        groupedChoice.choices.forEach((choice) => {
            if (choice.value === value) {
                res = groupedChoice.groupName + " - " + choice.name;
            }
        });
    });
    return res;
};

export type FieldChangeCallback = (config: FieldConfig, value: any) => void;

export interface FieldType {
    name: FieldTypeName; // название типа
    supportedRules: FieldRuleName[];
    getDefaultValue: () => any;
    defaultRule?: FieldRuleName,
    render: (
        ref: any,
        config: FieldConfig,
        index: number,
        onChange: FieldChangeCallback,
        choices: FieldConfigChoices
    ) => JSX.Element; // вкрнутт компонент инпута или селекта или даты
    toResult: (state: FieldState) => { [k: string]: any };
    toUrl: (state: FieldState) => string; // преобразовать поле этого типа в URL
    fromUrl: (value: string, config: FieldConfig) => any; // преобразовать из URL в поле этого типа
    getDisplayValue: (state: FieldState, choices: FieldConfigChoices) => string; // label дял тэга фильтра
}

// конфигцрация
export const fieldTypes: Record<FieldTypeName, FieldType> = {
    [FieldTypeName.STRING]: {
        name: FieldTypeName.STRING,
        supportedRules: [FieldRuleName.LIKE, FieldRuleName.EQ, FieldRuleName.NEQ],
        defaultRule: FieldRuleName.LIKE,
        toResult: (state) => {
            const value = typeof state.value === "string" ? state.value.trim().replaceAll(state.config.filterRegExp || /&nbsp;/g, "") : state.value;
            return {
                [state.config.name]: filterRuleMap[state.rule].value + value,
            }
        },
        toUrl: (state) => state.value,
        fromUrl: (value) => value,
        getDisplayValue: (state) => {
            return filterRuleMap[state.rule].label + " " + state.value
        },
        getDefaultValue: () => "",
        render: (ref: RefObject<HTMLDivElement>, config: FieldConfig) => {
            return <StringFields key={config.name} popupRef={ref} config={config} />;
        },
    },
    [FieldTypeName.INTEGER]: {
        name: FieldTypeName.INTEGER,
        supportedRules: [
            FieldRuleName.EQ,
            FieldRuleName.NEQ,
            FieldRuleName.LT,
            FieldRuleName.LTE,
            FieldRuleName.GT,
            FieldRuleName.GTE,
        ],
        defaultRule: FieldRuleName.EQ,
        toResult: (state) => {
            const value = typeof state.value === "string" ? state.value.trim() : state.value;
            return {
                [state.config.name]: filterRuleMap[state.rule].value + value,
            }
        },
        toUrl: (state) => state.value,
        fromUrl: (value) => value,
        getDisplayValue: (state) => filterRuleMap[state.rule].label + " " + state.value,
        getDefaultValue: () => null,
        render: (ref: RefObject<HTMLDivElement>, config: FieldConfig) => {
            return <IntFields key={config.name} popupRef={ref} config={config} />;
        },
    },
    [FieldTypeName.STRICT_INTEGER]: {
        name: FieldTypeName.INTEGER,
        supportedRules: [],
        toResult: (state) => {
            const value = typeof state.value === "string" ? state.value.trim() : state.value;
            return {
                [state.config.name]: value,
            }
        },
        toUrl: (state) => state.value,
        fromUrl: (value) => value,
        getDisplayValue: (state) => state.value,
        getDefaultValue: () => null,
        render: (ref: RefObject<HTMLDivElement>, config: FieldConfig) => {
            return <IntFields key={config.name} popupRef={ref} config={config} />;
        },
    },
    [FieldTypeName.BOOL]: {
        name: FieldTypeName.BOOL,
        supportedRules: [],

        toResult: (state) => ({
            [state.config.name]: state.value ? "1" : "0",
        }),
        toUrl: (state) => (state.value ? "1" : "0"),
        fromUrl: (value) => value === "1",
        getDisplayValue: (state) => (state.value ? "YES" : "NO"),
        getDefaultValue: () => false,
        render: (ref: RefObject<HTMLDivElement>, config: FieldConfig, index, onChange) => {
            return <SelectFields
                shoudlDefaultOpen
                key={config.name}
                popupRef={ref}
                config={config}
                onChange={onChange}
                choices={[
                    {name: "YES", value: "1"},
                    {name: "NO", value: "0"}
                ]}
            />
        },
    },
    [FieldTypeName.DATE_RANGE]: {
        name: FieldTypeName.DATE_RANGE,
        supportedRules: [],
        toResult: (state) => ({
            [state.config.name]:
                dayjs(state.value[0]).format("YYYY-MM-DD") + "--" + dayjs(state.value[1]).format("YYYY-MM-DD"),
        }),
        toUrl: (state) =>
            dayjs(state.value[0]).format("YYYY-MM-DD") + "--" + dayjs(state.value[1]).format("YYYY-MM-DD"),
        fromUrl: (value) => {
            if (typeof value === 'object') {
                return value;
            }

            return value.split("--").map((dateString) => dayjs(dateString, "YYYY-MM-DD"));
        }, // use dayjs..,
        getDisplayValue: (state) => state.value[0].format("YYYY-MM-DD") + " - " + state.value[1].format("YYYY-MM-DD"), // use dayjs...
        getDefaultValue: () => [],
        render: (ref: RefObject<HTMLDivElement>, config: FieldConfig) => {
            return <DateFields key={config.name} popupRef={ref} config={config} />;
        },
    },
    [FieldTypeName.SELECT]: {
        name: FieldTypeName.SELECT,
        supportedRules: [],
        toResult: (state) => ({
            [state.config.name]: state.value,
        }),
        toUrl: (value) => (value.config.multiple ? value.value.join(",") : value.value),
        fromUrl: (value, config) => {
            if (typeof value === 'object') {
                return value;
            }

            return config.multiple ? value.split(",") : value
        },
        getDisplayValue: (state, choices) => {
            return state.config.multiple
                ? state.value?.map((value: any) => {
                      return getLabelFromChoices((choices as FieldChoice[]) || [], value);
                  })
                : getLabelFromChoices((choices as FieldChoice[]) || [], state.value);
        },
        getDefaultValue: () => null,
        render: (ref: RefObject<HTMLDivElement>, config: FieldConfig, index = 0, onChange, choices) => {
            return (
                <SelectFields
                    shoudlDefaultOpen={index === 0 && config.choices && config.choices?.length > 0}
                    key={config.name}
                    popupRef={ref}
                    config={config}
                    onChange={onChange}
                    choices={choices as FieldChoice[]}
                />
            );
        },
    },
    [FieldTypeName.GROUPED_SELECT]: {
        name: FieldTypeName.GROUPED_SELECT,
        supportedRules: [],
        toResult: (state) => ({
            [state.config.name]: state.value,
        }),
        toUrl: (value) => (value.config.multiple ? value.value.join(",") : value.value),
        fromUrl: (value, config) => {
            if (typeof value === 'object') {
                return value;
            }

            return config.multiple ? value.split(",") : value
        },
        getDisplayValue: (state, choices) => {
            return state.config.multiple
                ? state.value?.map((value: any) => {
                      return getLabelFromGroupedChoices((choices as FieldGroupedChoice[]) || [], value);
                  })
                : getLabelFromGroupedChoices((choices as FieldGroupedChoice[]) || [], state.value);
        },
        getDefaultValue: () => null,
        render: (ref: RefObject<HTMLDivElement>, config: FieldConfig, index = 0, onChange, choices) => {
            return (
                <GroupedSelectFields
                    shoudlDefaultOpen={index === 0 && config.choices && config.choices?.length > 0}
                    key={config.name}
                    popupRef={ref}
                    config={config}
                    onChange={onChange}
                    groupedChoices={(config.choices as FieldGroupedChoice[]) || []}
                />
            );
        },
    },
};
