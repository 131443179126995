import React, {useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Button, Card, Popconfirm, Space, Tooltip, Typography} from "antd";
import {AgentDataAccountsModal} from "./agent.data.accounts.modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Date from "../../../date/date";
import {useRequiredStore} from "../../../../utils/store";
import {AgentDataStoreContext} from "../agent.data.store";
import {CustomCard} from "../../../common/custom-card/custom.card";
import {observer} from "mobx-react";
import {CustomerAccountAccess} from "../../../../models/accounts/customer-account/access/customer.account.access";
import {useNavigate} from "react-router";

interface AgentDataAccountsCardProps {
    access: CustomerAccountAccess,
    onAdd: () => void
}

export const AgentDataAccountsCard: React.FC<AgentDataAccountsCardProps> = observer(({access, onAdd}) => {
    const t = useTranslate();
    const store = useRequiredStore(AgentDataStoreContext);
    const navigate = useNavigate();

    const [isModalShown, setIsModalShown] = useState<boolean>(false);


    const cardRowsFirst = [
        {key: "email"},
        {key: "createdAt", label: "CREATED_AT"}
    ]
    const cardData = {
        email: access.user.email,
        createdAt: <Date dateString={access.user.createdAt!} showTime={false}/>
    }

    return (
        <>
            <Card
                className={"card"}
                title={<Typography.Title level={5}>{access.user.firstName + " " + access.user.lastName}</Typography.Title>}
                extra={
                    <Space direction="horizontal" size={[5, 5]} align="center" style={{width: "auto"}}>
                        <Button
                            size={"small"}
                            className={"card-btn btn"}
                            onClick={() => navigate("/users/" + access.user.id)}
                            type="default"
                            icon={<FontAwesomeIcon icon={["fas", "eye"]}/>}
                        />
                        <Tooltip placement="topRight" title={t("EDIT")}>
                            <Button
                                size={"small"}
                                className={"card-btn btn"}
                                onClick={() => setIsModalShown(true)}
                                type="default"
                                icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                            />
                        </Tooltip>
                        <Popconfirm
                            title={t("ARE_YOU_SURE")}
                            onConfirm={() => store.removeAccount(access)}
                            placement="top"
                            okText={t("YES")}
                            cancelText={t("NO")}
                        >
                            <Tooltip placement="topRight" title={t("REMOVE")}>
                                <Button
                                    className={"card-btn btn-remove btn"}
                                    type="default"

                                    icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                                />
                            </Tooltip>
                        </Popconfirm>
                    </Space>
                }
            >
                <CustomCard data={cardData} rowKeys={cardRowsFirst}/>
                <table style={{marginTop: 10}}>
                    <thead>
                    <tr style={{width: "100%"}}>
                        <td style={{padding: "1px 20px 1px 1px", width: "50%"}}>{t("SYSTEM")}:</td>
                        <td style={{padding: "1px 3px 1px 1px", width: "50%"}}>{t("GROUP.TITLE")}:</td>
                    </tr>
                    </thead>
                    <tbody>
                    {access.systemAccesses.map(item => (
                        <tr key={item.system.id} style={{width: "100%"}}>
                            <td style={{padding: "1px 20px 1px 1px", color: "#939393", width: "50%"}}>{item.system.name}:</td>
                            <td style={{padding: "1px 3px 1px 1px", color: "#939393", width: "50%"}}>{item.group.name}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Card>
            <AgentDataAccountsModal
                access={access}
                visible={isModalShown}
                onClose={() => setIsModalShown(false)}
                onSubmit={store.updateAccount}
            />
        </>
    );
});
