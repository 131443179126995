import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {WidgetProps} from "../../../../../common/ui/input/widget/widget";
import {plannerService} from "../../../../../../services/planner/planner.service";
import {DepartmentFlatten} from "../../../../../../models/departments/department";
import {flattenDepartments} from "../../../../../../models/departments/helper";

export const UIInputWidgetDepartment: React.FC<WidgetProps> = ({value, onChange, multiple}) => {

    const [departments, setDepartments] = useState<DepartmentFlatten[]>([]);

    useEffect(() => {
        plannerService.getDepartments().then(departments => setDepartments(flattenDepartments(departments)));
    }, [])

    return (
        <Select
            mode={multiple ? "multiple" : undefined}
            onChange={onChange as any}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
        >
            {departments.map(department => (
                <Select.Option value={department.id}>{department.name}</Select.Option>
            ))}
        </Select>
    )
}