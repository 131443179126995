import React, {useState} from "react";
import {Col, Row} from "antd";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {WikiCategoryTree} from "../tree/tree";
import {ExtendedSwitch} from "../../core/router/extended-switch/extended.switch";
import {WikiTreeItem} from "../../../models/wiki/wiki";
import './main.scss';

interface WikiMainProps extends ComponentPropsFromRoute {
}

export const WikiMain: React.FC<WikiMainProps> = ({routeChildren}) => {

    const [overloadNodes, setOverloadNodes] = useState<WikiTreeItem[]>([]);

    return (
            <Row gutter={[40, 0]} wrap={false}>
                <Col style={{borderRight: '1px solid rgb(0,0,0,0.06)'}} flex="260px">
                    <WikiCategoryTree overloadNodes={overloadNodes} />
                </Col>
                <Col flex="auto">
                    <ExtendedSwitch
                        routers={routeChildren!}
                        context={{setOverloadNodes}}
                    />
                </Col>
            </Row>
    );
};
