import {Translate} from "../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/lib/table/interface";
import {SplitItemListItem} from "../model";
import {displayPrice} from "../../../../../models/prices/price.helper";
import Date from "../../../../date/date";
import {ClaimsItemType} from "../../../status/claim.item.status";
import {Button, Space} from "antd";

export const columnsFactory = (
        t: Translate,
        onRemove: (item: SplitItemListItem) => void
    ): ColumnsType<SplitItemListItem> => {
        return [
            {
                title: "Type",
                dataIndex: "type",
                key: "type",
                render: (type) => <ClaimsItemType type={type} />
            },
            {
                title: "Document",
                width: 150,
                dataIndex: "invoiceNumber",
                key: "invoiceNumber",
                render: (docNumber) => docNumber
            },
            {
                title: "Dispatch date",
                dataIndex: "invoiceDate",
                key: "invoiceDate",
                render: (date) => <Date dateString={date} />
            },
            {
                title: "Price",
                dataIndex: "price",
                key: "price",
                render: (price) => displayPrice(price)
            },
            {
                title: "Qty",
                dataIndex: "qty",
                key: "qty",
            },
            {
                title: "Actions",
                key: "actions",
                render: (_, item) => <Space>
                    <Button size={"small"} danger={true} onClick={() => onRemove(item)}>Remove</Button>
                </Space>
            }
        ]
}