import {AxiosInstance} from "axios";
import API from "../../utils/api";

export class AgentTypesService {
    api: AxiosInstance = API;

    //TODO Захардкоженные значения
    async getTypes(): Promise<string[]> {
        return ["legal", "individual", "legal_export", "individual_export", "legal_no_vat", "import_dutiable"];
    }


}

export const agentTypesService = new AgentTypesService();
