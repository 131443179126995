import React, {useState} from "react";
import {UsersAccessesListStore} from "./users.accesses.list.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Button, Row, Space, Spin, Table, Tabs, Tooltip} from "antd";
import {Observer, observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Access} from "../../../../../models/users/access/access";
import {UserAccessesModal} from "../accesses-modal/user.accesses.modal";
import {UserAccessesCategoryModal} from "../accesses-category-modal/user.accesses.category.modal";
import {createAccessCategoryMap} from "../../../../../models/users/access-category/access.category.helper";



export const UserAccessesList: React.FC = observer(() => {
    const t = useTranslate();
    const [store] = useState(() => new UsersAccessesListStore());

    const [categoriesModalShown, setCategoriesModalShown] = useState<boolean>(false);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "50px"
        },
        {
            title: t("NAME"),
            dataIndex: "name",
            key: "name"
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "description"
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            width: "55px",
            render: (_: any, row: Access) => (
                <Row justify={"center"}>
                    <Tooltip placement="topRight" title={t("EDIT")}>
                        <Button
                            className="table-btn btn"
                            onClick={() => store.openAccessEditModal(row)}
                            type="default"
                            icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                        />
                    </Tooltip>
                    <Observer>
                        {() => (
                            <UserAccessesModal
                                item={row}
                                accesses={store.accesses}
                                visible={store.isShownAccessEditModal(row)}
                                onClose={store.closeAccessEditModal}
                                onSubmit={store.postAccess}
                            />
                        )}
                    </Observer>
                </Row>
            )
        }
    ];

    if (store.loading) {
        return (
            <Space direction={"horizontal"} align={"center"}
                   style={{height: "calc(100vh - 90px)", justifyContent: "center"}}>
                <Spin/>
            </Space>
        )
    }

    return (
        <Space direction={"vertical"} size={[15, 15]}>
            <Row justify={"end"}>
                <Button style={{marginRight: 10}} onClick={() => store.openAccessCreateModal()} type="primary">{t("USERS.ACCESSES.ADD")}</Button>
                <Button onClick={() => setCategoriesModalShown(true)} type="primary">{t("USERS.CATEGORIES.SETTINGS")}</Button>
            </Row>
            <Tabs tabPosition={"top"}>
                {store.accesses!.map(({id, name, accesses}) => (
                    <Tabs.TabPane tab={name} key={id}>
                        <Table
                            scroll={{x: true}}
                            rowKey={"id"}
                            columns={columns}
                            dataSource={[...accesses]}
                            pagination={accesses.length >= 10 ? undefined : false}
                        />
                    </Tabs.TabPane>
                ))}
            </Tabs>
            <UserAccessesModal
                visible={store.isShownAccessCreateModal()}
                onClose={store.closeAccessCreateModal}
                onSubmit={store.postAccess}
                accesses={store.accesses}
            />

            <UserAccessesCategoryModal
                accessesCategory={createAccessCategoryMap(store.accesses!)}
                visible={categoriesModalShown}
                onClose={() => setCategoriesModalShown(false)}
                onCreate={store.createCategory}
                onEdit={store.editCategory}
            />
        </Space>
    )
});
