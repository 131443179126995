import React, {useEffect, useState} from "react";
import {Button, Col, Row, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconName} from "@fortawesome/fontawesome-svg-core";

interface TabView {
    node: React.ReactNode;
    icon: IconName;
    text?: string
    onSelect?: () => void;
    extra?: React.ReactNode;
}

interface TableViewSwitcherProps<K = string | number> {
    views: TabView[]
    extra?: React.ReactNode;
    defaultViewIndex: number;
    disabled: boolean;
    onChange?: (view: TabView) => void;
}

// const tabViewIconsMap: Record<TabView, IconName> = {
//     kanban: "border-all",
//     list: "list",
// }

const TableViewSwitcherButton: React.FC<{view: TabView, selectedView: TabView, onSelected: (view: TabView) => void }>
    = ({view, selectedView, onSelected}) => {

    return <Button
        type={view === selectedView ? "primary" : "default"}
        icon={<FontAwesomeIcon icon={["fas", view.icon]} size={"lg"}/>}
        onClick={() => onSelected(view)}
    >{view.text}</Button>;
}

export const TableViewSwitcher: React.FC<TableViewSwitcherProps> = ({views, extra, defaultViewIndex, disabled, onChange}) => {

    const [selectedViewIndex, setSelectedViewIndex] = useState<number>(defaultViewIndex);

    const setSelectedView = (selectedView: TabView, index: number) => {
        selectedView.onSelect && selectedView.onSelect();
        onChange && onChange(selectedView);
        setSelectedViewIndex(index);
    }

    const selectedView = views[selectedViewIndex];

    return <Row
        gutter={[0, 20]}
        justify={"space-between"}
        wrap
    >
        <Col xxl={22} xl={22} lg={22} md={24} sm={24} xs={24} flex={1}>
            <Space direction={"horizontal"}>
                {extra || ""}
                {selectedView.extra || ""}
            </Space>
        </Col>
        {!disabled ? (
        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} flex={1} style={{textAlign: "right"}}>
            <Space size={[5, 5]} style={{width: "auto"}} >
                {views.map((view, number) => (
                    <TableViewSwitcherButton key={number} view={view} selectedView={selectedView} onSelected={view => setSelectedView(view, number)} />))
                }
            </Space>
        </Col>): null}
        <Col span={24}>
            {selectedView.node}
        </Col>
    </Row>;
}