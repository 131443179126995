export const moneyFormat = (price: string | number, sign: string = '') => {
    const pieces = parseFloat(price as string).toFixed(2).split('')
    let ii = pieces.length - 3
    while ((ii-=3) > 0) {
        pieces.splice(ii, 0, ' ')
    }
    return sign + pieces.join('').replace('.', ',');
}

export const numberFormat = (price: string | number) => {
    return String(price).replace('.', ',');
}