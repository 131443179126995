import React, {useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Button, DatePicker, Form, Input, Typography} from "antd";
import {MarkdownTextarea} from "../../../core/markdown/markdown-textarea/markdown-textarea";
import {UIInputWidgetDepartment} from "../../ui/input/widget/department/ui.input.widget.department";
import {UIInputWidgetPriority} from "../../ui/input/widget/priority/ui.input.widget.priority";
import {UIInputWidgetAssignees} from "../../ui/input/widget/assignees/ui.inout.widget.assignees";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {plannerService} from "../../../../services/planner/planner.service";
import {useNavigate} from "react-router";

export const PlannerTasksCreate: React.FC<ComponentPropsFromRoute> = () => {

    const {
        authStore: { department },
    } = useRequiredStore(RootStoreContext);

    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const navigate = useNavigate();

    const initialValues = {}

    return (
        <>
            <Typography.Title level={2}>{t("PLANNER.TASK.NEW_TASK.TITLE")}</Typography.Title>
            <Form
                form={form}
                layout="vertical"
                name={"planner_task_form"}
                initialValues={initialValues}
                onFinish={() => form
                    .validateFields()
                    .then(async values => {
                        setLoading(true);
                        try {
                            const createdTask = await plannerService.createTask(values);
                            navigate('/planner/tasks/' + createdTask.id);
                        } catch (e: any) {
                            form.setFields(e.response?.data?.fields);
                        } finally {
                            setLoading(false);
                        }
                    })
                }
            >
                <Form.Item
                    label={t("PLANNER.TASK.FIELDS.NAME")}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <Input disabled={loading} />
                </Form.Item>
                <Form.Item
                    label={t("PLANNER.TASK.FIELDS.DESCRIPTION")}
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <MarkdownTextarea
                        fileUploader={f => plannerService.uploadFile(f)}
                        height={"400px"}
                        disabled={loading}
                    />
                </Form.Item>
                <Form.Item
                    label={t("PLANNER.TASK.FIELDS.DEPARTMENTS")}
                    name="departments"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <UIInputWidgetDepartment multiple={true} />
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(v1, v2) => v1.departments !== v2.departments}
                >
                    {({getFieldValue}) => (
                        <>
                            {(getFieldValue('departments') || []).includes(department!.id) ? (
                                <Form.Item
                                    label={t("PLANNER.TASK.FIELDS.ASSIGNEES")}
                                    name="assignees"
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                                            ),
                                        },
                                    ]}
                                >
                                    <UIInputWidgetAssignees multiple={true} />
                                </Form.Item>
                            ) : null}
                        </>
                    )}
                </Form.Item>
                <Form.Item
                    label={t("PLANNER.TASK.FIELDS.PRIORITY")}
                    name="priority"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <UIInputWidgetPriority />
                </Form.Item>
                <Form.Item
                    label={t("PLANNER.TASK.FIELDS.ESTIMATED_START_DATE")}
                    name="plannedStartDate"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <DatePicker disabled={loading}  />
                </Form.Item>
                <Form.Item
                    label={t("PLANNER.TASK.FIELDS.ESTIMATED_END_DATE")}
                    name="plannedEndDate"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <DatePicker disabled={loading}  />
                </Form.Item>
                <Form.Item>
                    <Button
                        type={"primary"}
                        onClick={() => form.submit()}
                        disabled={loading}
                    >
                        {t("SUBMIT")}
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
