import React, {useState} from 'react';
import {useTranslate} from "../../../../hooks/translate.hook";
import {Button, Checkbox, Col, Form, FormInstance, Input, notification, Row, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {generatePassword} from "../../../../utils/helpers";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {observer} from "mobx-react";
import "./my.page.details.scss";
import {userService} from "../../../../services/users/user.service";
import {UserContacts} from "../../../../models/users/user/extended.user";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";

export const MyPageDetails: React.FC = observer(() => {
    const t = useTranslate();
    const {authStore: {user}} = useRequiredStore(RootStoreContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [sendPasswordDisabled, setSendPasswordDisabled] = useState<boolean>(true);
    const [form] = Form.useForm();

    const openSuccessNotification = (type: string, message: string): void => {
        notification[type === "success" ? "success" : "error"]({
            message: type === "success" ? "Success" : "Error",
            description: message
        });
    }

    const setGeneratedPassword = (form: FormInstance) => {
        const generatedPassword = generatePassword();
        form.setFieldsValue({"password": generatedPassword});
    };

    if (!user) {
        return <LoadingSpin />
    }

    const defaultValues = {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        sendPassword: false
    }

    return (
        <Row>
            <Col xs={24} md={18} lg={12}>
                <Form
                    form={form}
                    layout="vertical"
                    name="departmentMain"
                    initialValues={defaultValues}
                    onFinish={() => form
                        .validateFields()
                        .then(values => {

                            const data: UserContacts = {
                                ...values,
                                password: values.password ? values.password.trim() : "",
                                sendPassword: values.sendPassword === undefined ? false : values.sendPassword
                            };

                            setLoading(true);

                            userService.editUser('me', data).then(
                                () => {
                                    openSuccessNotification("success", "User updated successfully")
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    setLoading(false);
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                            .then(() =>
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                        })
                    }
                >
                    <Row gutter={[10, 0]} align={"middle"}>
                        <Col span={12}>
                            <Form.Item
                                name={"firstName"}
                                label={t("FIRSTNAME")}
                                rules={[{
                                    required: true,
                                    message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                                }]}
                            >
                                <Input disabled={loading} type={"text"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={"lastName"}
                                label={t("LASTNAME")}
                                rules={[{
                                    required: true,
                                    message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                                }]}
                            >
                                <Input disabled={loading} type={"text"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={"email"}
                                label={t("EMAIL")}
                                rules={[{
                                    required: true,
                                    message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                                }]}
                            >
                                <Input disabled={loading} type={"text"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={"password"}
                                label={t("PASSWORD.TITLE")}
                            >
                                <Input.Password
                                    disabled={loading}
                                    placeholder={t("PASSWORD.EDIT")}
                                    allowClear
                                    addonAfter={
                                        <Tooltip title={t("PASSWORD.GENERATE")}>
                                            <FontAwesomeIcon onClick={() => setGeneratedPassword(form)} style={{cursor: "pointer"}} icon={["fas", "dice"]}/>
                                        </Tooltip>
                                    }
                                    onChange={event => {
                                        if (event.target.value.length === 0) {
                                            setSendPasswordDisabled(true);
                                            form.setFieldsValue({"sendPassword": false});
                                        } else {
                                            setSendPasswordDisabled(false);
                                            form.setFieldsValue({"sendPassword": true});
                                        }
                                    }}
                                    iconRender={visible => (
                                        visible ? (
                                            <Tooltip title={t("PASSWORD.HIDE")}>
                                                <FontAwesomeIcon style={{marginLeft: "3px"}} icon={["fas", "eye-slash"]}/>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title={t("PASSWORD.SHOW")}>
                                                <FontAwesomeIcon style={{marginLeft: "3px"}} icon={["fas", "eye"]}/>
                                            </Tooltip>
                                        )
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={loading}>{t("SAVE")}</Button>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={"sendPassword"} valuePropName={"checked"}>
                                <Checkbox className="send-password-checkbox" disabled={sendPasswordDisabled}>{t("PASSWORD.SEND_PASSWORD")}</Checkbox>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Col>
        </Row>
    );
});
