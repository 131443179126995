import React, {useState} from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { displayPrice } from "../../../../../models/prices/price.helper";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { useRequiredStore } from "../../../../../utils/store";
import { InvoiceDataStoreContext } from "../invoice.data.store";
import { TableFooter } from "../../../../common/table-footer/table.footer";
import { generateDocumentDetails } from "../../../../../models/documents/invoices/invoice-data/invoice.data.helper";
import { ExpandedTable } from "../../../../common/order-details/expanded.table";
import {InvoiceDetailsItem} from "../../../../../models/documents/invoices/invoice-data/invoice.data";
import {convertTableColumnsToColumnConfigs} from "../../../../../utils/table";
import {Button, Dropdown, Menu} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CreditInvoiceCreateModal} from "../../credit-invoice-create-modal/credit-invoice-create-modal";
import {useNavigate} from "react-router";
import {ColumnType} from "antd/es/table/interface";
import {InvoiceAddItemModal} from "../invoice-add-item-modal/invoice-add-item-modal";
import {orderDataService} from "../../../../../services/orders/order-data/order.data.service";
import {InvoiceUpdateWeightModal} from "../invoice-update-weight-modal/invoice-update-weight-modal";
import {nonStrictComparator, strictComparator} from "../../../../ui/table/filter-dropdown/filter-dropdown.comparator";
import {FilterDropdown} from "../../../../ui/table/filter-dropdown/filter-dropdown";


interface Column extends ColumnType<InvoiceDetailsItem> {
    editable?: boolean;
}

type Columns = Column[]

export const InvoiceDataOrderDetails: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(InvoiceDataStoreContext);
    const navigate = useNavigate();

    const [creditModalItem, setCreditModalItem] = useState<InvoiceDetailsItem | null>();
    const [editWeightModalItem, setEditWeightModalItem] = useState<InvoiceDetailsItem | null>();

    const [newItemModalShown, setNewItemModalShown] = useState<boolean>(false);

    const renderActions = (item: InvoiceDetailsItem) => (
        <Dropdown
            placement={"bottomRight"}
            trigger={["click"]}
            overlay={
                <Menu>
                    <Menu.Item
                        key={0}
                        icon={<FontAwesomeIcon icon={["fas", "money-check-alt"]} />}
                        onClick={() => setCreditModalItem(item)}
                    >
                        {t("DOCUMENTS.INVOICES.CREDIT.CREATE")}
                    </Menu.Item>
                    <Menu.Item
                        key={1}
                        icon={<FontAwesomeIcon icon={["fas", "balance-scale-left"]} />}
                        onClick={() => setEditWeightModalItem(item)}
                    >
                        {t("DOCUMENTS.INVOICES.WEIGHT.UPDATE")}
                    </Menu.Item>
                </Menu>
            }
        >
            <Button
                type={"text"}
                icon={<FontAwesomeIcon icon={["fas", "ellipsis-v"]}
                />}>
            </Button>
        </Dropdown>
    )

    const columns: Columns = [
        {
            title: t("ORDERS.ORDER.ID"),
            dataIndex: "referenceOrderId",
            key: "invoice_data_order_detail_referenceOrderId",
            width: 120,
            render: item => item ? <Link to={"/orders/" + item}>{item}</Link> : null,
            sorter: (a, b) => a.referenceOrderId > b.referenceOrderId ? 1 : -1,
            onFilter: nonStrictComparator(v => v.referenceOrderId),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("CLIENT.ORDER.NUMBER"),
            dataIndex: "referenceOrderNumber",
            key: "invoice_data_order_detail_referenceOrderNumber",
            width: 120,
            sorter: (a, b) => a.referenceOrderNumber > b.referenceOrderNumber ? 1 : -1,
            onFilter: nonStrictComparator(v => v.referenceOrderNumber),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("HS_CODE"),
            dataIndex: "hsCode",
            key: "hsCode",
            width: 120,
            sorter: (a, b) => a.hsCode > b.hsCode ? 1 : -1,
            onFilter: nonStrictComparator(v => v.hsCode),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "articleName",
            key: "invoice_data_order_detail_articleName",
            width: "135px",
            render: (article: string) => article.toUpperCase() !== "DELIVERY" ? (
                <Link to={"/articles/" + article} style={{ whiteSpace: "nowrap" }}>
                    {article}
                </Link>
            ) : t("DELIVERY.TITLE"),
            sorter: (a, b) => a.articleName > b.articleName ? 1 : -1,
            onFilter: nonStrictComparator(v => v.articleName),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "invoice_data_order_detail_articleDescription",
            width: "300px",
        },
        {
            title: t("PRICE"),
            dataIndex: "price",
            key: "invoice_data_order_detail_price",
            width: "167px",
            render: (item) => (item ? <>{displayPrice(item)}</> : null),
            sorter: (a, b) => a.price.value > b.price.value ? 1 : -1,
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "invoice_data_order_detail_quantity",
            width: "80px",
            editable: true,
            sorter: (a, b) => a.qty > b.qty ? 1 : -1,
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "totalPrice",
            key: "invoice_data_order_detail_totalPrice",
            width: "130px",
            render: (item) => (item ? <>{displayPrice(item)}</> : null),
            sorter: (a, b) => a.totalPrice.value > b.totalPrice.value ? 1 : -1,
        },
        {
            title: t("SALE_PERCENTAGE"),
            key: "salePercentage",
            dataIndex: "salePercentage",
            width: "70px",
            render: (item: string) => <span>{item || "0"}%</span>,
            sorter: (a, b) => a.salePercentage > b.salePercentage ? 1 : -1,
        },
        {
            title: '',
            dataIndex: "action",
            key: "action",
            width: "80px",
            render: (_: any, item) => renderActions(item)
        }
    ];

    const addItemToOrder = (values: any) => {
        return orderDataService
            .addOrderItem(values.order, {article: values.article, qty: values.qty})
            .then(v => {
                store.reload();
                return v;
            })
    }

    const onDelete = (row: InvoiceDetailsItem) => {
        return orderDataService
            .removeOrderItem(row.orderItem.id)
            .then(v => {
                store.reload();
                return v;
            })
    }

    const onSave = (_: number, values: any, row: InvoiceDetailsItem) => {
        return orderDataService
            .updateQty(row.orderItem.id, values.qty)
            .then(v => {
                store.reload();
                return v;
            })
    }

    return (
        <>
            <ExpandedTable<InvoiceDetailsItem>
                title="DETAILS"
                pageKey="invoice_data_order_detail"
                columns={columns}
                columnsConfig={convertTableColumnsToColumnConfigs(columns)}
                tableData={store.invoice!.items}
                tableLoading={store.loading}
                tableFooter={
                    <TableFooter data={generateDocumentDetails(store.invoice!)}>
                        {store.invoice?.status === "opened" || store.invoice?.status === "invalid" ? (
                            <Button
                                style={{ marginTop: 10 }}
                                type="primary"
                                onClick={() => setNewItemModalShown(true)}
                            >
                                {t("CART.ADD_ROW")}
                            </Button>
                        ) : null}
                    </TableFooter>
                }
                isEditable={store.invoice?.status === "opened" || store.invoice?.status === "invalid"}
                editableRowKey="id"
                getEditValues={item => ({qty: item.qty})}
                getSaveValues={item => ({qty: item.qty})}
                onSave={onSave}
                onDelete={onDelete}
                actionsWidth={150}
                additionalActions={renderActions}
            />
            {creditModalItem ? (
                <CreditInvoiceCreateModal
                    warehouse={store.invoice!.warehouse}
                    item={creditModalItem}
                    onClose={() => setCreditModalItem(null)}
                    onCompleted={(invoice) => {
                        navigate("/credit-invoices/" + invoice.id);
                    }}
                />
            ) : null}
            {editWeightModalItem ? (
                <InvoiceUpdateWeightModal
                    item={editWeightModalItem}
                    onClose={() => setEditWeightModalItem(null)}
                    onCompleted={() => store.reload()}
                />
            ) : null}
            {newItemModalShown ? (
                <InvoiceAddItemModal
                    onClose={() => setNewItemModalShown(false)}
                    onSubmit={addItemToOrder}
                    orders={store.invoice?.orders!}
                />
            ) : null}
        </>
    );
});
