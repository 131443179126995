import React, {useState} from "react";
import {useTranslate} from "../../../hooks/translate.hook";
import "./queue.job.scss"
import {Button, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Job} from "../../../models/queue/job";
import Date from "../../date/date";
import {queueService} from "../../../services/queue/queue.service";
import {notifications} from "../../../services/notifications/notifications";

interface QueueJobProps {
    job: Job;
}

export const QueueJob: React.FC<QueueJobProps> = ({job}) => {

    const t = useTranslate();

    const [disabled, setDisabled] = useState<boolean>(false);

    const replay = async () => {
        setDisabled(true)
        await queueService.replay(job.id);
        notifications.successfully()
    }

    const cancel = async () => {
        setDisabled(true)
        await queueService.cancel(job.id);
        notifications.successfully()
    }

    return (
        <div className={"queue-job queue-job-status-" + job.status}>
            <div className={"queue-job-title"}>
                <div className={"queue-job-title-class"}>{job.id}: {job.class}</div>
                <div className={"queue-job-title-status"}><Tag>{job.status.beautify()}</Tag></div>
            </div>
            <div className={"queue-job-message"}>
                <div className={"queue-job-message-date"}>
                    <Date dateString={job.createdAt} showTime />, {job.initiator ? (
                        <>{job.initiator.firstName} {job.initiator.lastName}</>
                ) : "-"}
                </div>
                <div className={"queue-job-message-payload"}>
                    <table>
                        {Object.keys(job.message).map(k => (
                            <tr>
                                <td width={"100px"}>{k}</td>
                                <td style={{wordBreak: "break-all"}}>{JSON.stringify(job.message[k])}</td>
                            </tr>
                        ))}
                    </table>
                </div>
                {job.lastErrorMessage ? (
                    <div className={"queue-job-message-last-error"}>{job.lastErrorMessage}</div>
                ) : null}
            </div>
            {job.status === "failed" ? (
                <div className={"queue-job-actions"}>
                    <Button
                        shape={"circle"}
                        icon={<FontAwesomeIcon icon={"ban"} />}
                        disabled={disabled}
                        onClick={cancel}
                    >Cancel</Button>
                    <Button
                        shape={"circle"}
                        icon={<FontAwesomeIcon icon={"reply"} />}
                        disabled={disabled}
                        onClick={replay}
                    >Replay</Button>
                </div>
            ) : null}
        </div>
    )
}