import React, {useCallback, useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Button, Form, Input, Typography} from "antd";
import {MarkdownTextarea} from "../../../core/markdown/markdown-textarea/markdown-textarea";
import {UIInputWidgetPriority} from "../../ui/input/widget/priority/ui.input.widget.priority";
import {plannerService} from "../../../../services/planner/planner.service";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {PlannerTask} from "../../../../models/planner/planner";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";

export const PlannerTasksEdit: React.FC<ComponentPropsFromRoute> = () => {

    const { id } = useParams();

    const [task, setTask] = useState<PlannerTask>();

    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const navigate = useNavigate();

    const reload = useCallback(() => {
        plannerService.getTask(parseInt(id!)).then(task => setTask(task))
    }, [id])

    useEffect(() => {
        reload();
    }, [])

    if (!task) {
        return <LoadingSpin />
    }

    const initialValues = {
        name: task.name,
        description: task.description,
        priority: task.priority
    }

    return (
        <>
            <Typography.Title level={2}>{t("PLANNER.TASK.EDIT_TASK.TITLE")} "{task.name}"</Typography.Title>
            <Form
                form={form}
                layout="vertical"
                name={"planner_task_form"}
                initialValues={initialValues}
                onFinish={() => form
                    .validateFields()
                    .then(async values => {
                        setLoading(true);
                        try {
                            await plannerService.updateTask(task.id, values);
                            navigate('/planner/tasks/' + task.id);
                        } catch (e: any) {
                            form.setFields(e.response?.data?.fields);
                        } finally {
                            setLoading(false);
                        }
                    })
                }
            >
                <Form.Item
                    label={t("PLANNER.TASK.FIELDS.NAME")}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <Input disabled={loading} />
                </Form.Item>
                <Form.Item
                    label={t("PLANNER.TASK.FIELDS.DESCRIPTION")}
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <MarkdownTextarea
                        fileUploader={f => plannerService.attachFile(task.id, false, f)}
                        height={"400px"}
                        disabled={loading}
                    />
                </Form.Item>
                <Form.Item
                    label={t("PLANNER.TASK.FIELDS.PRIORITY")}
                    name="priority"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <UIInputWidgetPriority />
                </Form.Item>
                <Form.Item>
                    <Button
                        type={"primary"}
                        onClick={() => form.submit()}
                        disabled={loading}
                    >
                        {t("SUBMIT")}
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
