import React, {useEffect, useMemo, useState} from "react";
import {Modal, Table} from "antd";
import {columnsFactory} from "./sources-list.columns";
import {sourcesService} from "../../../../../../services/documents/common/sources.service";
import {downloadBlobFile} from "../../../../../../utils/blob.download";
import {ViewModal} from "../view/view.modal";
import {CompareModal} from "../compare/compare.modal";
import {notifications} from "../../../../../../services/notifications/notifications";

interface SourcesListModalProps {
    documentId: number;
    onClose: () => void
}

export const SourcesListModal: React.FC<SourcesListModalProps> = ({documentId, onClose}) => {

    const download = async (id: number) => {
        const value = await sourcesService.downloadSource(id)
        downloadBlobFile(value.blob, value.filename)
    }

    const handle = async (id: number) => {
        await sourcesService.handle(id)
        reload();
        notifications.successfully()
    }

    const reload = () => {
        setReady(false);
        sourcesService.getSources(documentId).then(s => {setSources(s); setReady(true)})
    }

    const [viewModalSourceId, setViewModalSourceId] = useState<number>(0);

    const [compareModalSourceId, setCompareModalSourceId] = useState<number>(0);

    const [ready, setReady] = useState<boolean>(false);

    const [sources, setSources] = useState<any[]>([]);

    const columns = useMemo(() => columnsFactory(download, handle, setViewModalSourceId, setCompareModalSourceId), [documentId])

    useEffect(() => {
        reload();
    }, [documentId])

    return <>
        <Modal
            title={"Sources list"}
            visible={true}
            onCancel={onClose}
            width={"900px"}
            footer={false}
            bodyStyle={{padding: 5}}
        >
            {ready ? (
                <Table
                    dataSource={sources}
                    columns={columns}
                    pagination={false}
                />
            ) : (
                <>....</>
            )}
        </Modal>
        {viewModalSourceId !== 0 ? (
            <ViewModal sourceId={viewModalSourceId} onClose={() => setViewModalSourceId(0)} />
        ) : null}
        {compareModalSourceId !== 0 ? (
            <CompareModal sourceId={compareModalSourceId} onClose={() => setCompareModalSourceId(0)} />
        ) : null}
    </>
}