import {FilterDataHandler} from "../../models/filter/filter.data.handler";
import {Announcement, AnnouncementRequest} from "../../models/announcements/announcement";
import {announcementsService} from "../../services/announcements/announcements.service";
import {action, makeObservable, observable} from "mobx";
import {languagesService} from "../../services/languages/languages.service";
import {agentService} from "../../services/agents/agent.service";
import React from "react";

export class AnnouncementsStore {
    @observable
    public locales: string[] = [];

    @observable
    public createModalShown: boolean = false;

    @observable
    public editModalShown: boolean = false;

    @observable
    public editModalItem?: Announcement;

    public dataHandler: FilterDataHandler<Announcement>;

    constructor() {
        makeObservable(this);
        this.searchAgents = this.searchAgents.bind(this);
        this.updateAnnouncement = this.updateAnnouncement.bind(this);
        this.createAnnouncement = this.createAnnouncement.bind(this);
        this.openCreateModal = this.openCreateModal.bind(this);
        this.closeCreateModal = this.closeCreateModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);

        this.dataHandler = new FilterDataHandler<Announcement>(request => announcementsService.getAnnouncements(request));
        this.init();
    }



    public isEditModalShown(item: Announcement): boolean {
        return this.editModalShown && this.editModalItem === item;
    }

    public isCreateModalShown(): boolean {
        return this.createModalShown;
    }

    @action
    public openEditModal(item: Announcement): void {
        this.editModalShown = true;
        this.editModalItem = item;
    }

    @action
    public closeEditModal(): void {
        this.editModalShown = false;
    }

    @action
    public openCreateModal(): void {
        this.createModalShown = true;
    }

    @action
    public closeCreateModal(): void {
        this.createModalShown = false;
    }

    @action
    public setLocales(locales: string[]): void {
        this.locales = locales;
    }

    public async updateAnnouncement(request: AnnouncementRequest, item: Announcement) {
        const updatedItem = await announcementsService.editAnnouncement(request, item.id);
        this.dataHandler.updateItem(item, updatedItem);
    }

    public async createAnnouncement(request: AnnouncementRequest) {
        const createdItem = await announcementsService.createAnnouncement(request);
        this.dataHandler.addItem(createdItem);
    }

    public async removeAnnouncement(item: Announcement) {
        await announcementsService.removeAnnouncement(item.id);
        this.dataHandler.removeItem(item);
    }

    public async searchAgents(value: string) {
        const accounts = await agentService.searchWholesaleAccount(value);
        return accounts.map(account => ({value: account.id, label: account.agent.number + " - " + account.agent.name}));
    }

    private async init() {
        this.setLocales(await languagesService.getLocales());
    }

}

export const AnnouncementsStoreContext = React.createContext<null | AnnouncementsStore>(null);
