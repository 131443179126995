import React, {useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {useParams} from "react-router-dom";
import {Container} from "../../../models/containers/containers";
import {containersService} from "../../../services/containers/containers.service";
import {LoadingSpin} from "../../common/loading-spin/loading.spin";
import {Button, Descriptions, Dropdown, Menu, Row, Space, Typography} from "antd";
import Date from "../../date/date";
import {ContainersStatus} from "../common/status/container.status";
import {SetArrivedModal} from "./set-arrived/containers.set-arrived.modal";
import {RouteTabs} from "../../common/route-tabs/route.tabs";
import {notifications} from "../../../services/notifications/notifications";
import {Export} from "../../common/export/export";
import {ChangeArrivalDateModal} from "./change-arrival-date/containers.change-arrival-date.modal";
import {useTranslate} from "../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";
import {useNavigate} from "react-router";

export const ContainersView: React.FC<ComponentPropsFromRoute> = ({routeChildren}) => {

    const { id } = useParams<{ id: string }>();
    const t = useTranslate();
    const navigate = useNavigate();

    const [container, setContainer] = useState<Container>();
    const [setArrivedModalShown, setSetArrivedModalShown] = useState<boolean>(false);
    const [changeArrivalDateModalShown, setChangeArrivalDateModalShown] = useState<boolean>(false);
    const [exportOrdersModalShown, setExportOrdersModalShown] = useState<boolean>(false);

    const reload = () => {
        setContainer(undefined);
        containersService.get(parseInt(id!)).then(container => setContainer(container))
    }

    const setHandled = async () => {
        await containersService.setHandled(parseInt(id!));
        notifications.successfully();
        reload();
    }

    useEffect(() => {
        reload();
    }, [id])

    if (!container) {
        return <LoadingSpin/>
    }

    return <>

        <PageHeader
            style={{ padding: 0 }}
            ghost={false}
            title={
                <Typography.Title level={1} style={{ margin: 0 }}>
                    {t("CONTAINERS.CONTAINER.TITLE")} {container.name}
                </Typography.Title>
            }
            onBack={() => {
                navigate("/orders");
            }}
            extra={<ContainersStatus status={container.status} large={true} />}
        >
            <Row justify={"end"}>
                <Dropdown
                    trigger={["click"]}
                    overlay={
                        <Menu>
                            {container.status === "in_transit" ? (
                                <Menu.Item key={1} onClick={() => setSetArrivedModalShown(true)}>
                                    {t("CONTAINERS.CONTAINER.SET-ARRIVED.TITLE")}
                                </Menu.Item>
                            ) : null}
                            {container.status === "in_transit" ? (
                                <Menu.Item key={1} onClick={() => setChangeArrivalDateModalShown(true)}>
                                    {t("CONTAINERS.CONTAINER.CHANGE-ESTIMATED-ARRIVAL-DATE.TITLE")}
                                </Menu.Item>
                            ) : null}
                            {container.status === "arrived" ? (
                                <Menu.Item key={1} onClick={() => setHandled()}>
                                    {t("CONTAINERS.CONTAINER.SET-HANDLED.TITLE")}
                                </Menu.Item>
                            ) : null}
                            <Menu.Item key={1} onClick={() => setExportOrdersModalShown(true)}>
                                {t("CONTAINERS.CONTAINER.EXPORT-ORDERS.TITLE")}
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <Button type="primary">{t("ACTION")}</Button>
                </Dropdown>
            </Row>
        </PageHeader>

        <Space direction={"vertical"} size={[50, 50]} style={{marginTop: 20}}>

            <div className="data-descriptions-with-edit-wrapper">
                <div className="data-content-wrapper">
                    <Descriptions
                        className="data-content-wrapper-descriptions"
                        size={"small"}
                        layout={"vertical"}
                        contentStyle={{ fontSize: "17px" }}
                        labelStyle={{ color: "#858585" }}
                        column={{
                            xxl: 4,
                            xl: 4,
                            lg: 4,
                            md: 3,
                            sm: 2,
                            xs: 1,
                        }}
                    >
                        <Descriptions.Item label={t("CODE")}>
                            {container.name}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("ARRIVAL_DATE")}>
                            <Date dateString={container.arrivalDate} />
                        </Descriptions.Item>
                        <Descriptions.Item label={t("ESTIMATED_ARRIVAL_DATE")}>
                            <Date dateString={container.estimatedArrivalDate} />
                        </Descriptions.Item>
                        <Descriptions.Item label={t("LOAD_DATE")}>
                            <Date dateString={container.loadDate} />
                        </Descriptions.Item>
                        <Descriptions.Item label={t("WAREHOUSES.WAREHOUSE_TITLE")}>
                            {container.warehouse.name}
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </div>

            <RouteTabs
                routes={routeChildren!}
            />
        </Space>
        {setArrivedModalShown ? (
            <SetArrivedModal
                container={container}
                onClose={() => setSetArrivedModalShown(false)}
                onCompleted={() => reload()}
                />
        ) : null}
        {changeArrivalDateModalShown ? (
            <ChangeArrivalDateModal
                container={container}
                onClose={() => setChangeArrivalDateModalShown(false)}
                onCompleted={() => reload()}
            />
        ) : null}
        {exportOrdersModalShown ? (
            <Export
                prefix={null}
                title={"CONTAINERS.CONTAINER.EXPORT-ORDERS.TITLE"}
                isShown={true}
                withBasicExportFields
                config={{
                    link: "/api/v3/containers/" + id + "/orders",
                }}
                onClose={() => setExportOrdersModalShown(false)}
            />
        ) : null}
    </>
}