import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import LocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: localStorage.i18nextLng ? localStorage.i18nextLng : "en",
        // fallbackLng: langStore.keys,
        backend: {
            backends: [LocalStorageBackend],
            loadPath: "/i18n/{{lng}}.json",
            requestOptions: {
                cache: "no-store",
            },
        },
        interpolation: {
            escapeValue: false,
        },
        fallbackLng: "en",
    });

export default i18n;
