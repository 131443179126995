import React, {useMemo} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Typography} from "antd";
import {columnsFactory} from "./containers.items.columns";
import FilteredTable from "../../../../common/filtered-table/filtered.table";
import {FilterDataHandler} from "../../../../../models/filter/filter.data.handler";
import {containersService} from "../../../../../services/containers/containers.service";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {useParams} from "react-router-dom";

export const ContainersItems: React.FC<ComponentPropsFromRoute> = () => {

    const { id } = useParams<{ id: string }>();
    const t = useTranslate();
    const columns = useMemo(() => columnsFactory(t), []);
    const dataHandler = useMemo(() => new FilterDataHandler(r => containersService.getItems(parseInt(id!), r)), []);

    return <>
        <Typography.Title level={3}>{t("CONTAINERS.CONTAINER.ITEMS")}</Typography.Title>
        <FilteredTable
            dataHandler={dataHandler}
            columns={columns}
            pagination={false}
            listenToUrl={false}
        />
    </>
}