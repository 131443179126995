import React, {useMemo} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {BackorderItem} from "../../../../../models/backorder/backorder.item";
import {FilterDataHandler} from "../../../../../models/filter/filter.data.handler";
import {backorderService} from "../../../../../services/backorder/backorder.service";
import {columnsFactory} from "./agent.data.backorder-list.columns";
import FilteredTable from "../../../../common/filtered-table/filtered.table";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {useRequiredStore} from "../../../../../utils/store";
import {AgentDataStoreContext} from "../../agent.data.store";

interface AgentDataBackorderListProps extends ComponentPropsFromRoute {}

export const AgentDataBackorderList: React.FC<AgentDataBackorderListProps> = () => {

    const t = useTranslate();
    const store = useRequiredStore(AgentDataStoreContext);

    const dataHandler: FilterDataHandler<BackorderItem> = useMemo(() => new FilterDataHandler(request => backorderService.getList(store.account?.id!, request)), []);

    const columns = useMemo(() => columnsFactory(t), []);

    return (
        <>
            <FilteredTable
                columns={columns}
                dataHandler={dataHandler}
            />
        </>
    )
}