import React, {useState} from "react";
import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    Row, Select,
} from "antd";
import {useForm} from "antd/es/form/Form";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Slot} from "../../../../../models/calendar/slot";
import dayjs from "dayjs";
import {SlotStatus} from "../../calendar.helpers";
import {calendarService} from "../../../../../services/calendar/calendar.service";
import timezone from "dayjs/plugin/timezone";

interface EditSlotModalProps {
    slot: Slot;
    onCancel: () => void;
    onComplete: () => void;
}

dayjs.extend(timezone);

export const EditSlotModal: React.FC<EditSlotModalProps> = ({slot, onCancel, onComplete}) => {
    const t = useTranslate();

    const [form] = useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const initialValues: any = {
        status: slot.status,
        reservation: slot.reservation
    }

    const remove = async () => {
        try {
            setLoading(true)
            await calendarService.removeSlot(slot.id);
            onCancel()
            onComplete()
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            title={t('Edit Slot ' + dayjs.tz(slot.from, dayjs.tz.guess()).format('HH.mm') + ' - ' + dayjs.tz(slot.until, dayjs.tz.guess()).format('HH.mm'))}
            width={500}
            visible={true}
            onCancel={onCancel}
            onOk={() => {
                form.submit();
            }}
            destroyOnClose
            footer={node => (
                <>
                    <Button
                        disabled={loading}
                        onClick={remove}
                        danger
                    >{t('REMOVE')}</Button>
                    {node}
                </>
            )}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={async (values) => {
                    await form.validateFields();
                    try {
                        setLoading(true)
                        await calendarService.changeSlotStatus(slot.id, values)
                        onCancel()
                        onComplete()
                    } catch (e: any) {
                        form.setFields(e.response?.data?.fields);
                    } finally {
                        setLoading(false)
                    }
                }}
                autoComplete="off"
                initialValues={initialValues}
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            label={t('CALENDAR.SLOT.STATUS.TITLE')}
                            name={["status"]}
                            rules={[{ required: true }]}
                        >
                            <Select disabled={loading}>
                                {Object.entries(SlotStatus).map(([_, v]) => (
                                    <Select.Option key={v} value={v}>{t('CALENDAR.SLOT.STATUS.' + v.toUpperCase())}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Form.Item
                        noStyle
                        shouldUpdate={(p, n) => p.status !== n.status}
                    >
                        {() => form.getFieldValue('status') !== SlotStatus.FREE ? (
                            <>
                                <Col span={24}>
                                    <Form.Item
                                        name={["reservation", "companyName"]}
                                        label={t("COMPANY_NAME")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                            },
                                        ]}
                                    >
                                        <Input disabled={loading} type="text" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name={["reservation", "personName"]}
                                        label={t("COMMON.PERSON_NAME")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                            },
                                        ]}
                                    >
                                        <Input disabled={loading} type="text" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={["reservation", "email"]}
                                        label={t("EMAIL")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                            },
                                        ]}
                                    >
                                        <Input disabled={loading} type="email" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={["reservation", "phoneNumber"]}
                                        label={t("PHONE")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                            },
                                        ]}
                                    >
                                        <Input disabled={loading} type="text" />
                                    </Form.Item>
                                </Col>
                            </>
                        ) : null}
                    </Form.Item>
                </Row>
            </Form>
        </Modal>
    );
}