import {Package} from "../../../../../models/parcels/shipment";
import {Form, FormInstance, Input, Select, Space} from "antd";
import {PreShipmentParcelInfo} from "../../../../../models/parcels/pre-shipments";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {ColumnsType} from "antd/es/table";

export const columnsFactory = (parcels: PreShipmentParcelInfo[], packages: Package[], form: FormInstance, onChange: () => void): ColumnsType<PreShipmentParcelInfo> => [
    {
        title: 'Number',
        dataIndex: 'number',
        className: 'v-top',
        render: (number: string, info: PreShipmentParcelInfo) => <Space direction={"horizontal"} size={[10, 10]} style={{marginTop: 5}}>
            <FontAwesomeIcon icon={info.type} color={"#676767"} />
            <span>{number || "(will be generated)"}</span>
        </Space>
    },
    {
        title: 'Package',
        dataIndex: 'package',
        width: 160,
        render: (item, parcel, index) =>
            <Form.Item
                key={index}
                name={["parcels", index.toString(), 'package']}
                rules={[{ required: true, message: 'Required' }]}
            >
                <Select
                    onSelect={value => {
                        const pkg = packages.find(e => e.id === value);
                        if (pkg) {
                            onChange();
                            form.setFieldsValue({
                                "parcels": {
                                    [index.toString()]: {
                                        width: pkg.width,
                                        height: pkg.height,
                                        length: pkg.length
                                    }
                                }
                            })
                        }
                    }}
                >
                    {packages.map(item => {
                        return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                    })}
                </Select>
            </Form.Item>,
    },
    {
        title: 'Width (mm)',
        dataIndex: 'width',
        onCell: (data, index) => {
            const values: any = {}
            if (undefined !== index && form.getFieldValue(["parcels", index.toString(), 'package']) === 0) {
                values.style = {
                    width: 300,
                    position: "absolute",
                    zIndex: 10000
                }
            }

            return values
        },
        render: (item, parcel, index) => {
            if (undefined !== index && form.getFieldValue(["parcels", index.toString(), 'package']) === 0) {
                return (
                    <Form.Item
                        key={index}
                        name={["parcels", index.toString(), 'mergedTo']}
                        rules={[{required: true, message: 'Required'}]}
                    >
                        <Select
                        >
                            {parcels
                                .filter((value, valueIndex) =>
                                    value !== parcel && form.getFieldValue(["parcels", valueIndex.toString(), 'package']) !== 0)
                                .map(parcel => (
                                    <Select.Option value={parcel.number} key={parcel.number}>{parcel.number}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>);
            }

            return (
                <Form.Item
                    key={index}
                    name={["parcels", index.toString(), 'width']}
                    rules={[{required: true, message: 'Required'}]}
                >
                    <Input/>
                </Form.Item>);
        },
        width: 100
    },
    {
        title: 'Length (mm)',
        dataIndex: 'length',
        render: (item, parcel, index) => {

            if (undefined !== index && form.getFieldValue(["parcels", index.toString(), 'package']) === 0) {
                return <></>
            }

            return (
                <Form.Item
                    key={index}
                    name={["parcels", index.toString(), 'length']}
                    rules={[{required: true, message: 'Required'}]}
                >
                    <Input/>
                </Form.Item>
            )
        },
        width: 100
    },
    {
        title: 'Height (mm)',
        dataIndex: 'height',
        render: (item, parcel, index) => {

            if (undefined !== index && form.getFieldValue(["parcels", index.toString(), 'package']) === 0) {
                return <></>
            }

            return (
                <Form.Item
                    key={index}
                    name={["parcels", index.toString(), 'height']}
                    rules={[{required: true, message: 'Required'}]}
                >
                    <Input/>
                </Form.Item>
            )
        },
        width: 100
    },
    {
        title: 'Gross weight (kg)',
        dataIndex: 'weight',
        render: (item, parcel, index) => {

            if (undefined !== index && form.getFieldValue(["parcels", index.toString(), 'package']) === 0) {
                return <></>
            }

            return (
                <Form.Item
                    key={index}
                    name={["parcels", index.toString(), 'weight']}
                    rules={[{required: true, message: 'Required'}]}
                >
                    <Input/>
                </Form.Item>
            )
        },
        width: 100
    }
]