import React, {useMemo, useState} from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {SmartTable} from "../../common/smart-table/smart-table";
import {columnsFactory} from "./calendar.events.columns";
import {SmartTableDataHandler} from "../../common/smart-table/smart-table.data-handler";
import {eventsService} from "../../../services/calendar/events.service";
import {useTranslate} from "../../../hooks/translate.hook";
import {Button, Space, Typography} from "antd";
import {useNavigate} from "react-router";
import dayjs from "dayjs";
import {Slot} from "../../../models/calendar/slot";
import {NewEventModal} from "./new-event/new-event.modal";

export const Events: React.FC<ComponentPropsFromRoute> = ({name}) => {

    const navigate = useNavigate();
    const t = useTranslate();
    const columns = useMemo(() => columnsFactory(t), [t])
    const handler = useMemo(() => new SmartTableDataHandler(r => eventsService.getEvents(r)), []);

    const [newEventModalShown, setNewEventModalShown] = useState<boolean>(false);

    return (
        <Space direction="vertical" size={[0, 0]}>
            <Typography.Title level={3}>{t("CALENDAR.EVENTS.TITLE")}</Typography.Title>
            <Button type={"primary"} onClick={() => setNewEventModalShown(true)}>Create a Event</Button>
            <SmartTable
                handler={handler}
                columns={columns}
                actions={[
                    {
                        icon: "link",
                        title: "To Events",
                        onClick: i => navigate("/calendar?start=" + dayjs(i.startingAt).format('YYYY-MM-DD') + "&end=" + dayjs(i.endingAt).format('YYYY-MM-DD'))
                    }
                ]}
            />
            {newEventModalShown ? (
                <NewEventModal
                    onCancel={() => setNewEventModalShown(false)}
                    onComplete={() => handler.reloadItemsWithLastRequest()}
                />
            ) : null}
        </Space>
    )
}