import {Row, Col, Button, Table} from "antd";
import React, { useState } from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {NotCompletedOrderSummary} from "../../../../../models/orders/orders-cart/orders-checkout/orders.checkout.not_completed.order";
import { displayPrice } from "../../../../../models/prices/price.helper";
import "./orders.checkout.order.body.scss";
import {NumberInput} from "../../../../common/number-input/number.input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface OrdersCheckoutTableFooter {
    backorderSummary: NotCompletedOrderSummary;
    stockSummary: NotCompletedOrderSummary;
    orderDiscount: string | number;
    vatPercent: number;
    updateDiscount: (discount: number) => Promise<void>;
    openAddRowModal?: () => void;
}

export const OrdersCheckoutTableFooter: React.FC<OrdersCheckoutTableFooter> = ({
    backorderSummary,
    stockSummary,
    orderDiscount,
    vatPercent,
    updateDiscount,
    openAddRowModal
}) => {
    const t = useTranslate();
    const [discountEditing, setDiscountEditing] = useState<boolean>(false);
    const [discount, setDiscount] = useState<string | number>(orderDiscount);

    const onDiscountButtonClick = (): void => {
        setDiscountEditing(true);
    };

    const columns = [];
    columns.push({title: '', dataIndex: 'title'})
    if (backorderSummary.qty > 0) {
        columns.push({title: 'Back Order', dataIndex: 'backOrderValue'})
    }
    columns.push({title: 'Stock Order', dataIndex: 'stockValue'})

    return (
        <Row justify="space-between">
            <Col xxl={13} xl={12} md={12} sm={24} xs={24}>
                {openAddRowModal ? (
                    <Button
                        style={{ marginTop: 10 }}
                        type="primary"
                        onClick={openAddRowModal}
                    >
                        {t("CART.ADD_ROW")}
                    </Button>
                ) : null}
            </Col>
            <Col xxl={9} xl={10} md={12} sm={24} xs={24}>

                <Table
                    className={"order-checkout-table-summary"}
                    columns={columns}
                    dataSource={[
                        {
                            title: t("SHIPPED_QUANTITY"),
                            stockValue: stockSummary.qty,
                            backOrderValue: backorderSummary.qty
                        },
                        {
                            title: t("TOTAL_WEIGHT"),
                            stockValue: stockSummary.totalNetWeight,
                            backOrderValue: backorderSummary.totalNetWeight
                        },
                        {
                            title: t("TOTAL_PRICE"),
                            stockValue: displayPrice(stockSummary.subtotal),
                            backOrderValue: displayPrice(backorderSummary.subtotal)
                        },
                        {
                            title: "VAT(" + vatPercent + "%)",
                            stockValue: displayPrice(stockSummary.subtotalVat),
                            backOrderValue: displayPrice(backorderSummary.subtotalVat)
                        },
                        {
                            title: t("GRAND_TOTAL_PRICE"),
                            stockValue: displayPrice(stockSummary.grandTotal),
                            backOrderValue: displayPrice(backorderSummary.grandTotal)
                        },
                        {
                            title: t("SALE_PERCENTAGE"),
                            stockValue: stockSummary.salePercentage,
                            backOrderValue: backorderSummary.salePercentage
                        },
                        {
                            title: t("DISCOUNT_PERCENT"),
                            stockValue: <>
                                {discountEditing ? (
                                    <div className="discount-input-wrapper">
                                        <NumberInput
                                            value={discount}
                                            onChange={(value) =>
                                                setDiscount(value as any)
                                            }
                                            onKeyDown={(event) => {
                                                if (event.keyCode === 13) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            placeholder={"Discount"}
                                        />
                                        <Button
                                            type="primary"
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={["fas", "check"]}
                                                />
                                            }
                                            onClick={async () => {
                                                await updateDiscount(discount as number);
                                                setDiscountEditing(false);
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <Button
                                        type="link"
                                        onClick={onDiscountButtonClick}
                                        className="discount-btn"
                                    >
                                        {orderDiscount}%
                                    </Button>
                                )}
                            </>,
                        }
                    ]}
                    pagination={false}
                >
                </Table>
            </Col>
        </Row>
    );
};
