import {FieldGroupConfig} from "../../../../models/filter/filter.group";
import {FieldTypeName} from "../../../../models/filter/filter.field.type.name";
import {Translate} from "../../../../hooks/translate.hook";

export const filterGroupsFactory = (t: Translate): FieldGroupConfig[] => {
    return [
        {
            visibleName: 'ARCHIVE',
            name: 'replaced',
            fields: [
                {
                    name: 'replaced',
                    type: FieldTypeName.BOOL
                }
            ],
        },
        {
            visibleName: 'MONTH',
            name: 'month',
            fields: [
                {
                    name: 'month',
                    type: FieldTypeName.INTEGER
                },
                {
                    name: 'year',
                    type: FieldTypeName.INTEGER
                }
            ],
        }
    ]
}