import React, {RefObject, useRef} from "react";
import {observer} from "mobx-react";
import {Card, Checkbox, Row, Typography} from "antd";
import {displayPrice} from "../../../../../models/prices/price.helper";
import {OrdersCheckoutShipping} from "../../../../../models/orders/orders-cart/orders-checkout/orders.checkout.shipping";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../utils/store";
import {CustomCard} from "../../../../common/custom-card/custom.card";
import {OrdersCheckoutStoreContext} from "../orders.checkout.store";
import {NotCompletedOrder} from "../../../../../models/orders/orders-cart/orders-checkout/orders.checkout.not_completed.order";


interface OrdersCheckoutShippingsCardProps {
    shipping: OrdersCheckoutShipping,
    order: NotCompletedOrder
}
export const OrdersCheckoutShippingsCard: React.FC<OrdersCheckoutShippingsCardProps> = observer(({shipping, order}) => {
    const t = useTranslate();
    const store = useRequiredStore(OrdersCheckoutStoreContext);
    const checkboxRef = useRef(null) as RefObject<any>


    const cardRows = [
        {key: "estimateType", label: "SHIPPING.TYPE"},
        {key: "estimate", label: "SHIPPING.TIME"},
        {key: "packType", label: "SHIPPING.PACK_TYPE"},
    ];

    if (shipping.price.showCost) {
        cardRows.unshift({key: "price", label: "SHIPPING.PRICE"});
    }

    const cardData = {...shipping, price: shipping.price.cost.value === 0 ? t('FREE') : displayPrice(shipping.price.cost)}


    return (
        <Card
            hoverable
            className={"card"}
            onClick={() => store.selectShipping(order, shipping.id, !shipping.checked)}
            title={
                <Row align={"middle"} justify={"space-between"}>
                    <Typography.Text>{shipping.name}</Typography.Text>
                    <Checkbox
                        className={"large"}
                        ref={checkboxRef}
                        checked={shipping.checked}
                    />
                </Row>
            }
        >
            {shipping.id === "delay" ? (
                <>({t("SHIPPING.MERGED_SHIPPING.DESCRIPTION")})</>
            ) : shipping.id === "custom" ? (
                <>({t("SHIPPING.CUSTOM.DESCRIPTION")})</>
            ) : (
                <>
                    {shipping.price.costDescription ? (
                            <Typography.Paragraph type={"secondary"}>{shipping.price.costDescription}</Typography.Paragraph>
                        ) : null}
                    <CustomCard data={cardData} rowKeys={cardRows}/>
                </>
            )}
        </Card>
    );
})
