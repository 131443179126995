import React, {useMemo} from "react";
import {Button, Space, Tree, Popconfirm} from "antd";
import "./list.categories.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {WebSiteCategory} from "../../../../../models/web-site/web-site";
import {Link} from "react-router-dom";
import {webSiteService} from "../../../../../services/web-site/web-site.service";
import {notifications} from "../../../../../services/notifications/notifications";
import Title from "antd/es/typography/Title";
import {useNavigate} from "react-router";

interface ListCategoriesWebSiteSettingsProps {
    tree: WebSiteCategory[]
    onTreeShouldBeUpdated: () => void;
}

const treeItemToTreeData = (item: WebSiteCategory): any => {
    return {
        ...item,
        key: item.id,
        title: item.name,
        children: item.children ? item.children.map(s => treeItemToTreeData(s)) : []
    }
}

export const ListCategoriesWebSiteSettings: React.FC<ListCategoriesWebSiteSettingsProps> = ({tree, onTreeShouldBeUpdated}) => {

    const navigate = useNavigate();

    const treeData: any[] = useMemo(() => {
        return tree.map(item => treeItemToTreeData(item))
    }, [tree])

    const onRemove = async (item: WebSiteCategory) => {
        await webSiteService.removeCategory(item.id)
        notifications.successfully();
        onTreeShouldBeUpdated();
    }

    return (
        <Space direction={"vertical"}>
            <Title level={4}>{"Categories"}</Title>
            <Tree
                rootClassName={"website-tree"}
                defaultExpandAll
                showIcon
                showLine
                treeData={treeData}
                titleRender={v => (
                    <div className={"website-tree-item"} onClick={() => navigate('/settings/web-site/categories/' + v.id + '/pages')}>
                        <div className={"website-tree-item-title"}>{v.title || '(missing translate)'}</div>
                        <div className={"website-tree-item-acts"}>
                            <Space direction={"horizontal"} onClick={e => e.stopPropagation()}>
                                <Link to={"/settings/web-site/categories/" + v.id}>
                                    <Button
                                        icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                                        size={"small"}
                                        type={"text"}
                                    >
                                    </Button>
                                </Link>
                                <Popconfirm
                                    placement="topLeft"
                                    title={"Are you sure?"}
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={_ => onRemove(v)}
                                >
                                    <Button
                                        icon={<FontAwesomeIcon icon={["fas", "trash"]}/>}
                                        size={"small"}
                                        type={"text"}
                                    >
                                    </Button>
                                </Popconfirm>
                            </Space>
                        </div>
                    </div>
                )}
            />
            <Link to={"/settings/web-site/categories/new-category"}>
                <Button
                    block
                    type={"primary"}
                >
                    Add a new category
                </Button>
            </Link>
        </Space>
    );
}