import React, {useEffect, useMemo, useState} from "react";
import { observer } from "mobx-react";
import { Space, Typography } from "antd";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Invoice} from "../../../../models/documents/invoices/invoice";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
import { ColumnsType } from "antd/es/table";
import {DocumentsMenu} from "../../../common/documents/menu/documents.menu";
import {convertTableColumnsToColumnConfigs} from "../../../../utils/table";
import {columnsFactory} from "./list.columns";
import {FilterDataHandler} from "../../../../models/filter/filter.data.handler";
import {returnsService} from "../../../../services/documents/returns/returns.service";

interface ReturnsListProps extends ComponentPropsFromRoute {
}

export const ReturnsList: React.FC<ReturnsListProps> = observer(({ name }) => {
    const t = useTranslate();


    const columns: ColumnsType<Invoice> = useMemo(() => columnsFactory(t), []);

    const dataHandler = useMemo(() => new FilterDataHandler(request => returnsService.getAll(request)), [])

    return (
        <Space direction="vertical" size={[50, 50]}>
            <DocumentsMenu/>
            <Space direction="vertical" size={[0, 0]}>
                <Typography.Title level={3}>{t("DOCUMENTS.RETAIL.RETURN.LIST.TITLE")}</Typography.Title>
                <Space direction={"vertical"} size={[15, 15]}>
                    <FilteredTable
                        columns={columns}
                        columnsConfigs={{columns: convertTableColumnsToColumnConfigs(columns), pageKey: "id"}}
                        dataHandler={dataHandler}
                    />
                </Space>
            </Space>
        </Space>
    );
});
