import React, {useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space} from "antd";
import {ShippingRule} from "../../../../../models/shipping/shipping";
import {useRequiredStore} from "../../../../../utils/store";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {observer} from "mobx-react";
import {BranchDataStoreContext} from "../../branch.data.store";
import {currencies, Currency} from "../../../../../models/prices/price.helper";

export interface BranchDataRulesEditModalProps {
    rule: ShippingRule | null;
    onSubmit(sourceRule: ShippingRule | null, rule: ShippingRule): Promise<ShippingRule>;
    onClose(): void;
}

export const BranchDataRulesEditModal: React.FC<BranchDataRulesEditModalProps> = observer(({rule, onSubmit, onClose}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const rootStore = useRequiredStore(RootStoreContext);
    const langStore = rootStore.langStore;
    const branchStore = useRequiredStore(BranchDataStoreContext);

    const defaultValues: ShippingRule | {} = rule ? {...rule, cost: rule.cost.value} : {priceFrom: 0, priceUntil: 0, weightFrom: 0, weightUntil: 0, cost: 0, showCost: true};

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={
                rule ? t("BRANCH.DATA.SHIPPING.RULE.EDIT") : t("BRANCH.DATA.SHIPPING.RULE.ADD")
            }
            okText={rule ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => onClose()}
        >
            <Form
                form={form}
                layout="vertical"
                name={"address_form"}
                initialValues={defaultValues}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);

                        values = {
                            priceFrom: parseFloat(values.priceFrom),
                            priceUntil: parseFloat(values.priceUntil),
                            weightFrom: parseFloat(values.weightFrom),
                            weightUntil: parseFloat(values.weightUntil),
                            countries: values.countries || [],
                            cost: parseFloat(values.cost),
                            costDescription: values.costDescription,
                            showCost: values.showCost,
                            onSelectPopUpText: values.onSelectPopUpText
                        }

                        onSubmit(rule, values)
                            .catch(() => (e: any) => {
                                form.setFields(e.response?.data?.fields);
                                setLoading(false);
                            })
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            name="weightFrom"
                            label={t("SHIPPING.RULE.WEIGHT_FROM")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                addonAfter="kg."
                                disabled={loading}
                                type="number"
                                placeholder={t("SHIPPING.RULE.WEIGHT_FROM")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="weightUntil"
                            label={t("SHIPPING.RULE.WEIGHT_UNTIL")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                addonAfter="kg."
                                disabled={loading}
                                type="number"
                                placeholder={t("SHIPPING.RULE.WEIGHT_UNTIL")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="priceFrom"
                            label={t("SHIPPING.RULE.PRICE_FROM")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                addonAfter={currencies[branchStore.branch!.currencyCode.toUpperCase() as Currency]}
                                disabled={loading}
                                type="number"
                                placeholder={t("SHIPPING.RULE.PRICE_FROM")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="priceUntil"
                            label={t("SHIPPING.RULE.PRICE_UNTIL")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                addonAfter={currencies[branchStore.branch!.currencyCode.toUpperCase() as Currency]}
                                disabled={loading}
                                type="number"
                                placeholder={t("SHIPPING.RULE.PRICE_UNTIL")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Space direction={"vertical"} size={[10, 10]} style={{marginBottom: 15}}>
                            <Form.Item
                                name="countries"
                                label={t("COUNTRIES")}
                                style={{marginBottom: 0}}
                            >
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    loading={loading}
                                    placeholder={t("COUNTRIES")}
                                    maxTagCount={3}
                                >
                                    {langStore.countries.map(country => (
                                        <Select.Option key={country.id!} value={country.id!}>
                                            {country.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Space direction={"horizontal"} size={[10, 10]}>
                                <Button size={"middle"} onClick={() => form.setFieldsValue({countries: langStore.countries.map(c => c.id)})}>Select all</Button>
                            </Space>
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="cost"
                            label={t("COST")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                addonAfter={currencies[branchStore.branch!.currencyCode.toUpperCase() as Currency]}
                                disabled={loading}
                                type="number"
                                placeholder={t("COST")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="showCost"
                            label={t("SHIPPING.RULE.SHOW_COST")}
                            valuePropName={"checked"}
                        >
                            <Checkbox
                                disabled={loading}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="costDescription"
                            label={t("DESCRIPTION")}
                        >
                            <Input
                                disabled={loading}
                                placeholder={t("SHIPPING.RULE.SHOW_COST")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="onSelectPopUpText"
                            label={t("SHIPPING.RULE.ON_SELECT_POPUP_TEXT")}
                        >
                            <Input
                                disabled={loading}
                                placeholder={t("SHIPPING.RULE.ON_SELECT_POPUP_TEXT")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
});
