import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, Space, Typography} from "antd";
import {AgentNewContext} from "../../agent.new.modal";
import {useRequiredStore} from "../../../../../../utils/store";
import {RootStoreContext} from "../../../../../../stores/root/root.store";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {observer} from "mobx-react";

export const GeneralStep: React.FC = observer(() => {

    const {setStep, setValue, getValue, fields} = React.useContext(AgentNewContext);

    const { langStore, commonStore } = useRequiredStore(RootStoreContext);

    const t = useTranslate();

    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFields(fields);
    }, [fields])

    const defaultValues = {
        account: getValue('account'),
        agent: getValue('agent')
    }

    return <Space direction="vertical" size={[15, 15]} style={{marginTop: 40}}>

        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            name={"client_form"}
            initialValues={defaultValues}
            onFinish={async (values) => {
                await form.validateFields();
                setValue('account', values.account)
                setValue('agent', values.agent)
                setStep(1)
            }}
        >
            <Typography.Title level={3}>General</Typography.Title>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={24}>
                    <Row gutter={[30, 0]} align={"top"}>
                        <Col span={6}>
                            <Form.Item
                                name={["account", "legalType"]}
                                label={t("CLIENT.TYPE")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    disabled={loading || disabled}
                                    placeholder={t("CLIENT.TYPE")}
                                    showSearch
                                    allowClear
                                    optionFilterProp={"children"}
                                >
                                    {commonStore.agentTypes.map((item) => (
                                        <Select.Option key={item} value={item}>
                                            {item.beautify()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name={["account", "language"]}
                                label={t("LOCALES")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    placeholder={t("LOCALES")}
                                    disabled={loading || disabled}
                                >
                                    <Select.Option value={"en"}>EN</Select.Option>
                                    <Select.Option value={"bg"}>BG</Select.Option>
                                    <Select.Option value={"sr"}>SR</Select.Option>
                                    <Select.Option value={"de"}>DE</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name={["account", "bank"]}
                                label={t("BANK.NAME")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp={"children"}
                                    disabled={loading || disabled}
                                    placeholder={t("BANK.NAME")}
                                >
                                    {commonStore.banks.map((bank) => (
                                        <Select.Option key={bank.id} value={bank.id}>
                                            {bank.visibleName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name={["account", "orderType"]}
                                label={t("CLIENT.ORDER_TYPE.TITLE")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp={"children"}
                                    disabled={loading || disabled}
                                    placeholder={t("CLIENT.ORDER_TYPE.TITLE")}
                                >
                                    {commonStore.orderTypes.map((item) => (
                                        <Select.Option value={item} key={item}>
                                            {t("CLIENT.ORDER_TYPE." + item.toUpperCase())}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Typography.Title level={3}>Agent settings</Typography.Title>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={6}>
                    <Form.Item
                        name={["agent", "group"]}
                        label={t("CLIENT.GROUP")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            disabled={loading || disabled}
                            placeholder={t("CLIENT.GROUP")}
                        >
                            {commonStore.agentGroups.map((item) => (
                                <Select.Option key={item} value={item}>
                                    {item}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={["agent", "businessName"]}
                        label={t("BUSINESS_NAME")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input disabled={loading || disabled} type="text" placeholder={t("USE_LEGAL_NAME")} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={["agent", "name"]}
                        label={t("CLIENT.NAME")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input disabled={loading || disabled} type="text" placeholder={t("USE_LEGAL_NAME")} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={["agent", "visibleName"]}
                        label={t("AGENT.VISIBLE_NAME")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input disabled={loading || disabled} type="text" placeholder={t("AGENT.VISIBLE_NAME")} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={["agent", "country"]}
                        label={t("COUNTRY")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            placeholder={t("COUNTRY_SELECT")}
                            showSearch
                            optionFilterProp={"children"}
                        >
                            {langStore.countries.map(({ id, name }) => (
                                <Select.Option key={id as string} value={id as string}>
                                    {name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={["agent", "icAccessGroup"]}
                        label={t("CLIENT.IC.ACCESS_GROUP")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Select disabled={loading || disabled} placeholder={t("CLIENT.IC.ACCESS_GROUP")}>
                            {commonStore.agentICAccessGroups.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                </Col>
                <Col span={12}>
                    <Form.Item name={["agent", "comment"]} label={t("COMMENT")}>
                        <Input disabled={loading || disabled} type="text" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                </Col>
                <Col span={24}>
                    <Button type={"primary"} onClick={() => form.submit()}>Next</Button>
                </Col>
            </Row>
        </Form>
    </Space>
})