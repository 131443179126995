import { Col, Row, Space, Table, Typography } from "antd";
import { observer } from "mobx-react";
import React from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { HistoryArticleWarehouse } from "../../../../models/articles/articles.history";
import { FieldTypeName } from "../../../../models/filter/filter.field.type.name";
import { FieldGroupConfig } from "../../../../models/filter/filter.group";
import { RootStoreContext } from "../../../../stores/root/root.store";
import { useRequiredStore } from "../../../../utils/store";
import Filter from "../../../common/filtered-table/filter/filter";
import Date from "../../../date/date";
import { ArticleDataStoreContext } from "../article.data.store";

export const ArticleDataHistory: React.FC = observer(() => {
    const {
        commonStore: { warehouses },
    } = useRequiredStore(RootStoreContext);
    const store = useRequiredStore(ArticleDataStoreContext);
    const t = useTranslate();

    const columns = [
        {
            title: t("WAREHOUSES.NAME"),
            dataIndex: "warehouse",
            key: "warehouse",
            render: (item: HistoryArticleWarehouse) => <>{item.name}</>,
        },
        {
            title: t("QUANTITY"),
            dataIndex: "amount",
            key: "amount",
            className: "bold",
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date: string) => <Date dateString={date} showTime={false} />,
        },
    ];

    const filterGroups: FieldGroupConfig[] = [
        {
            name: "amount",
            visibleName: "QUANTITY",
            fields: [
                {
                    name: "amount",
                    type: FieldTypeName.INTEGER,
                },
            ],
        },
        {
            name: "warehouses",
            visibleName: "WAREHOUSES.NAME",
            fields: [
                {
                    name: "warehouses",
                    type: FieldTypeName.SELECT,
                    multiple: true,
                    choices: warehouses.map((warehouse) => ({
                        name: warehouse.name,
                        value: warehouse.id,
                    })),
                },
            ],
        },
        {
            name: "updatedAt",
            visibleName: "UPDATED_AT",
            fields: [
                {
                    name: "updatedAt",
                    type: FieldTypeName.DATE_RANGE,
                },
            ],
        },
    ];

    return (
        <Space direction={"vertical"} size={0}>
            <Typography.Title level={3}>{t("ARTICLES.CHANGE")}</Typography.Title>
            <Space direction="vertical" size={[20, 20]}>
                <Row>
                    <Col xs={24} sm={24} md={24}>
                        <Filter config={filterGroups} onUpdated={store.updateHistoryFilters} />
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    rowKey={"id"}
                    dataSource={[...store.articleHistory]}
                    loading={store.articleHistoryTableLoading}
                    scroll={{ x: true }}
                    pagination={{
                        position: ["bottomLeft"],
                        total: store.pagination?.total,
                        current: store.pagination?.page,
                        pageSize: store.pagination?.limit,
                        pageSizeOptions: store.limitVariants,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        onChange: (page) => store.setPage(page),
                        onShowSizeChange: (_, size) => store.setLimit(size),
                        showTotal: (total) => t("TOTAL_ITEMS") + ": " + total,
                    }}
                />
            </Space>
        </Space>
    );
});
