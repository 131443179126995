import React from "react";
import {makeObservable, observable, action} from "mobx";
import {
    UserAccessCategory,
    UserAccessCategoryInfo
} from "../../../../../models/users/access-category/user.access.category";
import {userAccessesService} from "../../../../../services/users/user-accesses/user.accesses.service";
import {Access, AccessRequest} from "../../../../../models/users/access/access";

export class UsersAccessesListStore {
    @observable
    public loading: boolean = false;

    @observable
    public accesses: UserAccessCategory[] | null = null;

    @observable
    public editingAccess: Access | null = null;

    @observable
    public editModalShown: boolean = false;
    
    @observable
    public createModalShown: boolean = false;

    constructor() {
        makeObservable(this);
        this.loadData();
        this.postAccess = this.postAccess.bind(this);
        this.closeAccessEditModal = this.closeAccessEditModal.bind(this);
        this.closeAccessCreateModal = this.closeAccessCreateModal.bind(this);
        this.createCategory = this.createCategory.bind(this);
        this.editCategory = this.editCategory.bind(this);
    }

    @action
    private setLoading(loading: boolean): void {
        this.loading = loading;
    }

    @action
    private setAccesses(accesses: UserAccessCategory[]): void {
        const obj: {[id: string]: any[]} = {};
        accesses.forEach(access => obj[access.id] = access.accesses);
        this.accesses = accesses;
    }

    @action
    public openAccessEditModal(item: Access): void {
        this.editModalShown = true;
        this.editingAccess = item;
    }
    
    @action
    public openAccessCreateModal(): void {
        this.createModalShown = true;
    }

    @action
    public closeAccessCreateModal(): void {
        this.createModalShown = false;
    }

    @action
    public closeAccessEditModal(): void {
        this.editModalShown = false;
    }

    @action
    private addToArray(item: any, items: any[]): void {
        items.push(item);
    }

    @action
    private removeItem(item: Access, itemsArray: Access[]): void {
        const index = itemsArray.indexOf(item);
        if (index !== -1) {
            itemsArray.splice(index, 1);
        }
    }

    @action
    private updateAccess(updatedAccess: Access, values: AccessRequest, access: Access): void {
        this.removeItem(access, this.accesses!.find(item => item.accesses.includes(access))!.accesses);
        this.addToArray(updatedAccess, this.accesses!.find(item => values.category === item.id)!.accesses)
    }

    @action
    private updateCategoryName(updatedCategory: UserAccessCategoryInfo): void {
        const index = this.accesses?.findIndex(item => item.id === updatedCategory.id);
        if (index !== -1 && index !== undefined) {
            this.accesses![index].name = updatedCategory.name;
        }
    }

    public isShownAccessCreateModal(): boolean {
        return this.createModalShown;
    }

    public isShownAccessEditModal(item: Access): boolean {
        return this.editModalShown && this.editingAccess === item;
    }
    
    public async postAccess(values: AccessRequest, access?: Access) {
        if (access) {
            const updatedAccess = await userAccessesService.postAccess(values, access?.id);
            this.updateAccess(updatedAccess, values, access);
        } else {
            const newAccess = await userAccessesService.postAccess(values);
            this.addToArray(newAccess, this.accesses!.find(item => values.category === item.id)!.accesses);
        }
    }

    public async createCategory(values: {name: string}) {
        const newCategory = await userAccessesService.postAccessCategory(values);
        this.addToArray({...newCategory, accesses: []}, this.accesses!);
    }

    public async editCategory(values: { editingCategoryId: string, name: string }) {
        const updatedCategory = await userAccessesService.postAccessCategory({name: values.name}, values.editingCategoryId);
        this.updateCategoryName(updatedCategory);
    }


    private async loadData() {
        this.setLoading(true);
        this.setAccesses(await userAccessesService.getAccessesCategory());
        this.setLoading(false);
    }
}

export const UsersAccessesListStoreContext = React.createContext<UsersAccessesListStore | null>(null);
