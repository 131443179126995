import React from "react";
import {observer} from "mobx-react";
import {Input, InputNumber, Space, Typography} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {OrderDeliveryCreateStoreContext} from "../order.delivery.create.store";
import "./order-delivery-price.scss";
import {currencies, Currency} from "../../../../models/prices/price.helper";

export const OrderDeliveryPrice: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(OrderDeliveryCreateStoreContext);
    return (
        <Space direction="vertical" size={[0, 0]}>
            <Typography.Title level={3}>
                {t("SHIPPING.PRICE")}
            </Typography.Title>
            <Input
                addonBefore={store.customAmount && store.customAmount.currencyCode ? currencies[(store.customAmount.currencyCode as any).toUpperCase() as Currency] : "EUR"}
                className="price-input"
                placeholder={t("PRICE")}
                value={store.customAmount?.value}
                inputMode="numeric"
                type="number"
                // downHandler={<></>}
                // upHandler={<></>}
                onChange={event => store.setCustomAmount({value: event.target.value as any, currencyCode: store.customAmount?.currencyCode || "eur"})}
            />
        </Space>
    )
})
