import React, {useState} from "react";
import {observer} from "mobx-react";
import {AgreementsListStore, AgreementsListStoreContext} from "./agreements.list.store";
import {Button, Popconfirm, Row, Tooltip, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {ColumnsType} from "antd/es/table";
import {Agreement} from "../../../../models/agreements/agreement";
import {FilterKey} from "../../../../models/filtered-table/filtered.table.props";
import {AgreementsModal} from "../agreements-modal/agreements.modal";
import Date from "../../../date/date";
import {useTranslate} from "../../../../hooks/translate.hook";

interface AgreementsProps extends ComponentPropsFromRoute {}
export const Agreements: React.FC<AgreementsProps> = observer(({name}) => {
    const t = useTranslate();
    // const {langStore} = useRequiredStore(RootStoreContext);
    const [store] = useState(() => new AgreementsListStore(t.i18n));

    const columns: ColumnsType<Agreement> = [
        {
            title: t("NAME"),
            dataIndex: "name",
            key: "name",
            render: (item) => t(item)
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "description",
            render: (item) => t(item)
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: value => <Date dateString={value} />
        },
        {
            title: t("UPDATED_AT"),
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: value => <Date  dateString={value} />
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "agreements_action",
            width: 100,
            render: (_, item) => {
                return (
                    <>
                        <Tooltip placement="topRight" title={t("EDIT")}>
                            <Button
                                className="table-btn btn"
                                onClick={async () => await store.onEditModalOpen(item)}
                                type="default"
                                icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                            />
                        </Tooltip>
                        {" "}
                        <Popconfirm
                            title={t("ARE_YOU_SURE")}
                            onConfirm={() => store.removeAgreement(item)}
                            placement="left"
                            okText={t("YES")}
                            cancelText={t("NO")}
                        >
                            <Tooltip placement="topRight" title={t("REMOVE")}>
                                <Button
                                    className="table-btn btn-remove btn"
                                    type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                                />
                            </Tooltip>
                        </Popconfirm>
                    </>

                )
            }
        }
    ];

    const filters: FilterKey[] = [
        {
            key: "name",
            label: t("NAME"),
            type: "string"
        },
        {
            key: "description",
            label: t("DESCRIPTION"),
            type: "string"
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date"
        },
        {
            key: "updatedAt",
            label: t("UPDATED_AT"),
            type: "date"
        }
    ];

    const columnsConfigs = [
        {
            title: "NAME",
            key: "name"
        },
        {
            title: "DESCRIPTION",
            key: "description"
        },
        {
            title: "CREATED_AT",
            key: "createdAt"
        },
        {
            title: "UPDATED_AT",
            key: "updatedAt"
        }
    ]

    return (
        <AgreementsListStoreContext.Provider value={store}>
            <Row>
                <Typography.Title level={3}>{t("MARKETING_AGREEMENT.LIST")}</Typography.Title>
                <Tooltip key={2} placement="topLeft" title={t("MARKETING_AGREEMENT.ADD")}>
                    <Button
                        className="btn"
                        style={{margin: "3px 0 0 5px"}}
                        onClick={store.openCreateModal}
                        type="default"
                        icon={<FontAwesomeIcon icon={["fas", "plus"]}/>}
                    />
                </Tooltip>
            </Row>
            <FilteredTable
                filterKeys={filters}
                dataHandler={store.dataHandler}
                columns={columns}
                columnsConfigs={{columns: columnsConfigs, pageKey: name}}
            />
            {store.modalShown ? (
                <AgreementsModal/>
            ) : null}
        </AgreementsListStoreContext.Provider>
    )
})