import { action, makeObservable, observable } from "mobx";
import { ArticleLockedStock } from "../../../../models/articles/articles.locked-stock";
import { articlesLockedStockService } from "../../../../services/articles/articles/articles.locked-stock.service";

export class ArticleLockedStockStore {
    @observable
    public loading: boolean = false;

    @observable
    public lockedStock: ArticleLockedStock[] = [];

    @observable
    public modalItem: ArticleLockedStock | null = null;

    constructor() {
        makeObservable(this);
        this.init();
    }

    @action
    public setModalItem(value: ArticleLockedStock | null): void {
        this.modalItem = value;
    }

    @action
    private setLoading(value: boolean): void {
        this.loading = value;
    }

    @action
    private setLockedStock(value: ArticleLockedStock[]) {
        this.lockedStock = value;
    }

    public openModal(item: ArticleLockedStock) {
        this.setModalItem(item);
    }

    public closeModal = () => {
        this.setModalItem(null);
    };

    private async init() {
        this.setLoading(true);
        this.setLockedStock(await articlesLockedStockService.getLockedStockData());
        this.setLoading(false);
    }
}
