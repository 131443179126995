import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, Space, Tooltip} from "antd";
import Title from "antd/es/typography/Title";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {webSiteService} from "../../../../../services/web-site/web-site.service";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {LoadingSpin} from "../../../../common/loading-spin/loading.spin";
import {WebSiteCategory} from "../../../../../models/web-site/web-site";
import { useOutletContext } from "react-router-dom";
import {chain, difference, isNil, times} from "lodash";
import {notifications} from "../../../../../services/notifications/notifications";
import {toJS} from "mobx";
import {languagesService} from "../../../../../services/languages/languages.service";

export const generateCategoriesTree = (categories: WebSiteCategory[], level: number = 0, exclude?: WebSiteCategory): React.ReactElement => {
    return <>
        {categories
            .filter(category => !exclude || exclude.id !== category.id)
            .map(category => (
                <React.Fragment key={category.id}>
                    <Select.Option value={category.id} key={category.id}>
                        {times(level).map(() => (<>-&nbsp;-&nbsp;</>))} {category.name}
                    </Select.Option>
                    {generateCategoriesTree(category.children, level + 1, exclude)}
                </React.Fragment>
            ))}
    </>
}

export const EditCategoriesWebSiteSettings: React.FC = () => {

    const { id } = useParams<{id: string}>();
    const [form] = Form.useForm();
    const t = useTranslate();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [ready, setReady] = useState<boolean>(false);
    const [category, setCategory] = useState<WebSiteCategory | null>(null);
    const [locales, setLocales] = useState<string[]>([]);

    const ctx = useOutletContext<{onTreeShouldBeUpdated: () => void, tree: WebSiteCategory[]}>();

    const getNotUsedLocales = (): string[] => {
        const usedLocales = chain(form.getFieldValue("translations"))
            .omitBy((value) => isNil(value) || isNil(value.locale))
            .map(value => value.locale)
            .value();

        return difference(toJS(locales), usedLocales);
    }

    useEffect(() => {
        setReady(false);
        if (id) {
            webSiteService.getCategory(parseInt(id)).then(category => {
                setReady(true);
                setCategory(category);
                form.setFieldsValue({
                    parent: category.parent,
                    translates: category.translates.map(e => ({locale: e.locale, value: e.value})),
                    extraFields: category.fields.map(e => ({name: e.name, visibleName: e.visibleName})),
                })
            });
        } else {
            setReady(true);
            setCategory(null);
            form.setFieldsValue({
                parent: "",
                translates: [{locale: t.i18n.language, value: ''}],
                extraFields: []
            });
        }
    }, [id])

    useEffect(() => {
        languagesService.getLocales().then(locales => setLocales(locales))
    }, [])

    if (!ready) {
        return (
            <LoadingSpin />
        )
    }

    return (
        <>
            <Form
                size={"middle"}
                form={form}
                layout="vertical"
                onFinish={() => form
                    .validateFields()
                    .then(async (values) => {
                        setLoading(true);
                        try {
                            if (id) {
                                await webSiteService.editCategory(parseInt(id), values);
                            } else {
                                await webSiteService.addCategory(values);
                            }
                            ctx.onTreeShouldBeUpdated();
                            notifications.successfully();
                            navigate('/settings/web-site');
                        } catch (e: any) {
                            if (e.response?.data.fields) {
                                form.setFields(e.response?.data?.fields);
                            }
                        } finally {
                            setLoading(false)
                        }
                    })
                }
            >
                <Space direction={"vertical"}>
                    <Title level={4}>{category ? "Edit category" : "New category"}</Title>
                    <Row gutter={[10, 10]} align={"middle"}>
                        <Col span={24}>
                            <Form.Item
                                name={["parent"]}
                                label={t("Parent category")}
                            >
                                <Select disabled={loading}>
                                    <Select.Option value={null} key={0}>
                                        (root)
                                    </Select.Option>
                                    {generateCategoriesTree(ctx.tree, 0, category || undefined)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Title level={5} style={{marginBottom: 25}}>Translations</Title>
                            <Form.List
                                name="translates"
                                rules={[{
                                    validator: async (_, names) => {
                                        if (!names || names.length === 0) {
                                            return Promise.reject(new Error(t('FROM.ERROR.PLEASE_ENTER_VALUE')));
                                        }
                                    }
                                }]}
                            >
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map(field => (
                                            <>
                                                <Row gutter={10} key={field.key}>
                                                    <Col span={4}>
                                                        <Form.Item
                                                            noStyle
                                                            shouldUpdate={(oldValue, newValue) => oldValue.changeNumberEnabled !== newValue.changeNumberEnabled}
                                                        >
                                                            {() => (
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[field.name, 'locale']}

                                                                    rules={[{
                                                                        required: true,
                                                                        message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                                                                    }]}
                                                                >
                                                                    <Select
                                                                        showSearch
                                                                        optionFilterProp={"children"}
                                                                    >
                                                                        {getNotUsedLocales().map(item => (
                                                                            <Select.Option key={item} value={item}>
                                                                                {item.toUpperCase()}
                                                                            </Select.Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={17}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'value']}

                                                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                                                        >
                                                            <Input placeholder={t("TRANSLATION")}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={3}>
                                                        <Tooltip placement="topRight" title={t('DELETE')}>
                                                            <Button
                                                                onClick={() => remove(field.name)}
                                                                icon={<FontAwesomeIcon icon={["fas", "trash"]}/>}
                                                                type="text"
                                                                style={{marginLeft: 'auto'}}
                                                                className="btn-flex-center"
                                                            />
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            </>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block
                                                    icon={<FontAwesomeIcon icon={["fas", "plus"]}/>}>
                                                Add translation
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                        <Col span={24}>
                            <Title level={5} style={{marginBottom: 25}}>Extra fields</Title>
                            <Form.List name="extraFields">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Row gutter={[10, 10]} key={key}>
                                                <Col xxl={11} xl={11} lg={11} md={24} sm={24} xs={24}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'name']}
                                                        rules={[{ required: true }]}
                                                    >
                                                        <Input placeholder="Technical name" disabled={loading} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={11} xl={11} lg={11} md={24} sm={24} xs={24}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'visibleName']}
                                                        rules={[{ required: true }]}
                                                    >
                                                        <Input placeholder="Visible name" disabled={loading} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={2} xl={2} lg={2} md={24} sm={24} xs={24}>

                                                    <Tooltip placement="topRight" title={t('DELETE')}>
                                                        <Button
                                                            onClick={() => remove(name)}
                                                            icon={<FontAwesomeIcon icon={["fas", "trash"]}/>}
                                                            type="text"
                                                            style={{marginLeft: 'auto'}}
                                                            className="btn-flex-center"
                                                        />
                                                    </Tooltip>

                                                </Col>
                                            </Row>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                onClick={() => add()}
                                                icon={<FontAwesomeIcon icon={["fas", "plus"]}/>}
                                                type="dashed"
                                                block
                                                disabled={loading}
                                            >
                                                Add a new extra field
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                        <Col span={24}>
                            <Button
                                type={"primary"}
                                disabled={loading}
                                onClick={() => form.submit()}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Space>
            </Form>
        </>
    )
}