import React, {useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Col, Form, Input, Modal, Row, Select, Tabs, Upload} from "antd";
import {observer} from "mobx-react";
import {branchesService} from "../../../../../services/branches/branches.service";

export interface BranchDataRulesImportModalProps {
    courierId: string;
    onComplete(): void;
    onClose(): void;
}

export const BranchDataRulesImportModal: React.FC<BranchDataRulesImportModalProps> = ({courierId, onComplete, onClose}) => {
    const t = useTranslate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Modal
            visible={true}
            title={t("BRANCH.DATA.SHIPPING.RULE.IMPORT.TITLE")}
            okText={t("IMPORT.DO_IMPORT")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => onClose()}
            width={"700px"}
            confirmLoading={loading}
        >
            <Tabs
                destroyInactiveTabPane={true}
            >
                <Tabs.TabPane tab={"Full"} key={0}>
                    <Form
                        form={form}
                        layout="vertical"
                        name={"address_form"}
                        onFinish={() =>
                            form.validateFields().then((values) => {
                                setLoading(true);
                                branchesService.fullImportShippingRules(courierId!, values.file)
                                    .then(
                                        () => {
                                            onComplete()
                                            onClose()
                                        }
                                    )
                                    .finally(
                                        () => {
                                            setLoading(false)
                                        }
                                    )
                            })
                        }
                    >
                        <Row gutter={10}>
                            <Col span={24}>
                                <Form.Item
                                    label={t("BRANCH.DATA.SHIPPING.RULE.IMPORT.FILE")}
                                    name="file"
                                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                                    valuePropName={""}
                                    getValueFromEvent={(e) => e.file || null}
                                >
                                    <Upload.Dragger
                                        beforeUpload={() => false}
                                        accept={".csv"}
                                        maxCount={1}
                                        disabled={loading}
                                    >
                                        <p className="ant-upload-text">
                                            Click or drag file to this area to upload.
                                            If weight or price does not matter, then you can set as "0"
                                        </p>
                                        <p className="ant-upload-hint">
                                            Required fields: countryCode; weightFrom; weightUntil; priceFrom; priceUntil; cost<br />
                                            Example row: EE;0,5;0,75;0;0;50
                                        </p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Tabs.TabPane>
                <Tabs.TabPane tab={"Short"} key={1}>
                    <Form
                        form={form}
                        layout="vertical"
                        name={"address_form"}
                        onFinish={() =>
                            form.validateFields().then((values) => {
                                setLoading(true);
                                branchesService.shortImportShippingRules(courierId!, values.file)
                                    .then(
                                        () => {
                                            onComplete()
                                            onClose()
                                        }
                                    )
                                    .finally(
                                        () => {
                                            setLoading(false)
                                        }
                                    )
                            })
                        }
                    >
                        <Row gutter={10}>
                            <Col span={24}>
                                <Form.Item
                                    label={t("BRANCH.DATA.SHIPPING.RULE.IMPORT.FILE")}
                                    name="file"
                                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                                    valuePropName={""}
                                    getValueFromEvent={(e) => e.file || null}
                                >
                                    <Upload.Dragger
                                        beforeUpload={() => false}
                                        accept={".csv"}
                                        maxCount={1}
                                    >
                                        <p className="ant-upload-text">
                                            Click or drag file to this area to upload.
                                            Each line must be splitted by 0,5 kg
                                        </p>
                                        <p className="ant-upload-hint">
                                            Required fields: shipping_price_country_code; shipping_price_weight; shipping_price_cost<br />
                                            Example row: EE;0,5;50
                                        </p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    );
};
