import React, {useEffect} from "react";
import {useParams, useLocation} from "react-router-dom";
import {useRequiredStore} from "../../../../../utils/store";
import {UserGroupsStoreContext} from "../user.groups.store";
import {Space, Spin} from "antd";
import {observer} from "mobx-react";
import {GroupsDataHeader} from "./groups-data-header/groups.data.header";
import {GroupsDataAccesses} from "./groups-data-accesses/groups.data.accesses";


export const UsersGroupsData: React.FC = observer(() => {
    const {groupsId} = useParams<{groupsId: string}>();
    const store = useRequiredStore(UserGroupsStoreContext);
    const match = useLocation();
    useEffect(() => {
        store.getCurrentGroup(groupsId!, match.pathname);

        return () =>  store.resetCurrentGroup();
    }, []);


    if (!store.currentGroup) {
        return (
            <Space direction={"horizontal"} align={"center"}
                   style={{height: "calc(100vh - 90px)", justifyContent: "center"}}>
                <Spin/>
            </Space>
        )
    }


    return (
        <Space direction={"vertical"} size={[50, 50]}>
            <GroupsDataHeader/>
            <GroupsDataAccesses/>
        </Space>
    );
});
