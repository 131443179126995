import {Translate} from "../../../hooks/translate.hook";
import {SmartColumn} from "../../common/smart-table/smart-table.model";
import {DeclarationProvider} from "../upload/declaration.upload.model";
import {FieldType} from "../../common/smart-table/smart-table.field-config";

export const columnsFactory = (t: Translate): SmartColumn<DeclarationProvider>[] => {
    return [
        {
            visibleName: "ID",
            dataIndex: "id",
            name: "id",
            type: FieldType.INTEGER,
            filterable: true
        },
        {
            visibleName: t("FULL_CODE"),
            dataIndex: "fullCode",
            name: "fullCode",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: t("SHORT_CODE"),
            dataIndex: "shortCode",
            name: "shortCode",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: "tarik",
            dataIndex: "tarik",
            name: "tarik",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: "lisa",
            dataIndex: "lisa",
            name: "lisa",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: "rahvuslik",
            dataIndex: "rahvuslik",
            name: "rahvuslik",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: t("CREATED_AT"),
            dataIndex: "createdAt",
            name: "createdAt",
            type: FieldType.DATETIME,
            filterable: true
        },
        {
            visibleName: t("UPDATED_AT"),
            dataIndex: "updatedAt",
            name: "updatedAt",
            type: FieldType.DATETIME,
            filterable: true
        }
    ]
}