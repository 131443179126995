import React from "react";
import {observer} from "mobx-react";
import {Space} from "antd";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {RouteTabs} from "../../../common/route-tabs/route.tabs";

interface OrdersCreateProps extends ComponentPropsFromRoute {}
export const OrdersCreate: React.FC<OrdersCreateProps> = observer(({routeChildren, name}) => {

    return (
        <Space direction={"vertical"} size={[60, 60]}>
            <RouteTabs
                routes={routeChildren!}
                withoutRootTabPath={true}
            />
        </Space>
    );
})
