import {Translate} from "../../../../hooks/translate.hook";
import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {MonthlyStatementItem} from "../../../../models/accounting/monthly-statements";
import {InvoiceDataDocument} from "../../../../models/documents/invoices/invoice-data/invoice.data";
import {Button, Space} from "antd";
import {displayPriceValue} from "../../../../models/prices/price.helper";
import {MonthlyStatementsItemStatus} from "../statements-item-status/statements-item-status";
import {Link} from "react-router-dom";
import Date from "../../../date/date";

export const columnsFactory = (t: Translate, checkAction: (id: number) => void, skipAction: (id: number) => void, disabled: boolean): ColumnsType<MonthlyStatementItem> => {
    return [
        {
            title: "ID",
            dataIndex: "id",
            key: "id"
        },
        {
            title: t("TYPE"),
            dataIndex: "type",
            key: "type"
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            render: (status) => <MonthlyStatementsItemStatus status={status} />
        },
        {
            title: t("DOCUMENT_NUMBER"),
            dataIndex: "invoiceDocument",
            key: "invoiceDocument",
            render: (document: InvoiceDataDocument, item) => {
                if (document) {
                    switch (item.type) {
                        case "invoice":
                            return <Link to={"/merged-invoices/" + document.id}>{document.docNumber}</Link>;
                        case "credit_invoice":
                            return <Link to={"/credit-invoices/" + document.id}>{document.docNumber}</Link>;
                    }
                }

                return item.invoiceNumber;
            }
        },
        {
            title: t("ACCOUNTING.STATEMENTS.ITEM.DATE"),
            key: "date",
            render: (_: any, item) => <>
                <Date dateString={item.date} showTime={false} />&nbsp;/&nbsp;
                <Date dateString={item.icDate} showTime={false} />&nbsp;/&nbsp;
                <Date dateString={item.invoiceDate} showTime={false} />
            </>
        },
        {
            title: t("ACCOUNTING.STATEMENTS.ITEM.SUBTOTAL"),
            key: "subtotal",
            render: (_: any, item) => [
                displayPriceValue(item.subtotal),
                displayPriceValue(item.icSubtotal),
                displayPriceValue(item.invoiceSubtotal)
            ].join(" / ")
        },
        {
            title: t("ACCOUNTING.STATEMENTS.ITEM.TAX"),
            key: "vat",
            render: (_: any, item) => [
                displayPriceValue(item.vat),
                displayPriceValue(item.icVat),
                displayPriceValue(item.invoiceVat)
            ].join(" / ")
        },
        {
            title: t("ACCOUNTING.STATEMENTS.ITEM.GRAND_TOTAL"),
            key: "grandTotal",
            render: (_: any, item) => [
                displayPriceValue(item.grandTotal),
                displayPriceValue(item.icGrandTotal),
                displayPriceValue(item.invoiceGrandTotal)
            ].join(" / ")
        },
        {
            title: t("ACTIONS.TITLE"),
            key: "actions",
            dataIndex: "id",
            render: (id: number) => <Space size={[10, 10]} direction={"horizontal"}>
                <Button onClick={() => checkAction(id)} size={"small"} type={"primary"} disabled={disabled}>
                    {t("CHECK")}
                </Button>
                <Button onClick={() => skipAction(id)} size={"small"} type={"primary"} disabled={disabled}>
                    {t("SKIP")}
                </Button>
            </Space>
        }
    ];
}