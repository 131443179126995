import React from "react";
import {StatusTag} from "../../../common/status/status";
import {ContainerStatus, containerStatusColor} from "../../../../models/containers/containers";

interface ClaimsItemAcceptStatusProps {
    status: ContainerStatus;
    large?: boolean;
}

export const ContainersStatus: React.FC<ClaimsItemAcceptStatusProps> = ({status, large = false}) => {
    return (
        <StatusTag<ContainerStatus> status={status} colorsMap={containerStatusColor} large={large} prefix="CONTAINERS.CONTAINER.STATUS"/>
    )
}