import React from "react";
import {ComponentPropsFromRoute} from "../../routers/routers";
import {RouteButtons} from "../common/route-tabs/route.buttons";
//import {useTranslate} from "../../hooks/translate.hook";

export const Property: React.FC<ComponentPropsFromRoute> = ({routeChildren}) => {

//    const t = useTranslate();

    return (
        <>
            <RouteButtons routes={routeChildren!} />
        </>

    )
}