import React, { useState } from "react";
import { observer, Observer } from "mobx-react";
import { Button, Checkbox, Col, Form, Input, Popconfirm, Row, Select, Space, Tooltip, Typography } from "antd";
import { find } from "lodash";
import {useTranslate} from "../../../../hooks/translate.hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyEditModal } from "../modal/languages.modal.key-edit";
import { LanguagesModel, Translation } from "../../../../models/languages/languages";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import { LanguagesListStore, LanguagesStoreContext } from "./languages.list.store";

import "./languages.scss";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
import { Export } from "../../../common/export/export";
import { LanguagesImportModal } from "../import-module/languages.import.modal";
import { FilterKey } from "../../../../models/filtered-table/filtered.table.props";
import { ColumnsType } from "antd/es/table";

interface LanguagesListProps extends ComponentPropsFromRoute {}
export const LanguagesList: React.FC<LanguagesListProps> = observer(({ name }) => {
    const t = useTranslate();
    const [store] = useState(() => new LanguagesListStore());
    const [exportModalShown, setExportModalShown] = useState<boolean>(false);
    const [importModalShown, setImportModalShown] = useState<boolean>(false);

    const columns: ColumnsType<LanguagesModel> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "5%",
        },
        {
            title: t("LANGUAGES.KEY"),
            dataIndex: "code",
            key: "code",
            width: "20%",
        },
        {
            title: t("SCOPES"),
            dataIndex: "scopes",
            key: "scopes",
            // width: "20%",
            render: (scopes: string[]) => <span>{scopes.length && scopes ? scopes.join(", ") : ""}</span>,
        },
        {
            title: localStorage.i18nextLng ? localStorage.i18nextLng.toUpperCase() : "EN",
            dataIndex: "translations",
            key: "translations",
            // width: "40%",
            render: (translations: Translation[]) => {
                const locale = localStorage.i18nextLng ?? "en";
                const translation = find(translations, (item) => item.locale === locale);
                return translation ? <span>{translation.value}</span> : <></>;
            },
        },
        {
            title: t("ACTION"),
            key: "action",
            className: "table-button-cell",
            width: 100,
            render: (text: string, item) => (
                <>
                    <Tooltip placement="topRight" title={t("EDIT")}>
                        <Button
                            className="table-btn btn"
                            onClick={() => store.openKeyEditModal(item)}
                            type="default"
                            icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                        />
                    </Tooltip>
                    <Observer>
                        {() =>
                            store.isShownKeyEditModal(item) ? (
                                <KeyEditModal
                                    item={item}
                                    locales={store.locales}
                                    scopes={store.scopes}
                                    isModalShown={store.isShownKeyEditModal(item)}
                                    onSuccess={(request, item) =>
                                        item ? store.updateKey(request, item) : Promise.reject()
                                    }
                                    onClose={store.closeKeyEditModal}
                                />
                            ) : (
                                <></>
                            )
                        }
                    </Observer>{" "}
                    {item.removable ? (
                        <Popconfirm
                            title={t("ARE_YOU_SURE")}
                            onConfirm={() => store.removeKey(item)}
                            placement="left"
                            okText={t("YES")}
                            cancelText={t("NO")}
                        >
                            <Tooltip placement="topRight" title={t("REMOVE")}>
                                <Button
                                    className="table-btn btn-remove btn"
                                    type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                                />
                            </Tooltip>
                        </Popconfirm>
                    ) : null}
                </>
            ),
        },
    ];

    const columnsConfig: { title: string; key: string }[] = [
        {
            title: "ID",
            key: "id",
        },
        {
            title: "LANGUAGES.KEY",
            key: "code",
        },
        {
            title: "SCOPES",
            key: "scopes",
        },
        {
            title: localStorage.i18nextLng ? localStorage.i18nextLng.toUpperCase() : "EN",
            key: "translations",
        },
    ];

    const filters: FilterKey[] = [
        {
            key: "id",
            label: "ID",
            type: "string",
        },
        {
            key: "value",
            label: t("LANGUAGES.TRANSLATION"),
            type: "string",
        },
        {
            key: "code",
            label: t("LANGUAGES.KEY"),
            type: "string",
        },
        {
            key: "scopes",
            label: t("SCOPES"),
            type: "select",
            options: store.scopes.map((item: string) => ({ value: item, label: item.beautify() })),
        },
        {
            key: "locales",
            label: t("LOCALES"),
            type: "select",
            options: store.locales.map((item: string) => ({ value: item, label: item.toUpperCase() })),
        },
    ];

    const defaultValues = {
        locales: ["en", "ru"],
        scopes: store.scopes,
    };

    return (
        <LanguagesStoreContext.Provider value={store}>
            <Row justify={"space-between"}>
                <Row>
                    <Typography.Title level={3} style={{ marginRight: 5 }}>
                        {t("LANGUAGES.TRANSLATIONS_TITLE")}
                    </Typography.Title>
                    <Tooltip placement="topLeft" title={t("LANGUAGES.ADD_TRANSLATION")}>
                        <Button
                            className="btn"
                            style={{ marginTop: 3 }}
                            icon={<FontAwesomeIcon icon={["fas", "plus"]} />}
                            type="primary"
                            onClick={store.openKeyCreateModal}
                        />
                    </Tooltip>
                </Row>
                <Space style={{ width: "auto" }} direction="horizontal" size={[5, 5]}>
                    <Button className={"table-page-button"} type={"primary"} onClick={() => setImportModalShown(true)}>
                        {t("IMPORT.DO_IMPORT")}
                    </Button>
                    <Button className={"table-page-button"} type={"primary"} onClick={() => setExportModalShown(true)}>
                        {t("LANGUAGES.DO_EXPORT")}
                    </Button>
                </Space>
            </Row>
            <FilteredTable
                filterKeys={filters}
                dataHandler={store.dataHandler}
                columns={columns}
                columnsConfigs={{ columns: columnsConfig, pageKey: name }}
            />
            {store.isShownKeyCreateModal() ? (
                <KeyEditModal
                    locales={store.locales}
                    scopes={store.scopes}
                    isModalShown={store.isShownKeyCreateModal()}
                    onSuccess={store.createKey}
                    onClose={store.closeKeyCreateModal}
                />
            ) : null}
            <Export
                title={"LANGUAGES.DO_EXPORT"}
                withBasicExportFields
                isShown={exportModalShown}
                config={{
                    link: "/api/v3/i18n/key",
                }}
                additionalDefaultValues={defaultValues}
                onClose={() => setExportModalShown(false)}
            >
                <Col span={24}>
                    <Form.Item
                        label={t("LOCALES")}
                        name={"locales"}
                        rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                    >
                        <Select
                            showSearch
                            mode={"multiple"}
                            placeholder={t("LOCALES")}
                            optionFilterProp={"children"}
                        >
                            {store.locales.map((locale) => (
                                <Select.Option key={locale} value={locale}>
                                    {locale.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={t("SCOPES")}
                        name={"scopes"}
                        rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                    >
                        <Select
                            showSearch
                            mode={"multiple"}
                            placeholder={t("SCOPES")}
                            optionFilterProp={"children"}
                        >
                            {store.scopes.map((scope) => (
                                <Select.Option key={scope} value={scope}>
                                    {scope.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={t("ONLY_MISSING_IN_LOCALE")} name={"onlyMissingInLocale"}>
                        <Select
                            allowClear
                            showSearch
                            placeholder={t("ONLY_MISSING_IN_LOCALE")}
                            optionFilterProp={"children"}
                        >
                            {store.locales.map((locale) => (
                                <Select.Option key={locale} value={locale}>
                                    {locale.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Export>

            <LanguagesImportModal visible={importModalShown} onClose={() => setImportModalShown(false)} />
        </LanguagesStoreContext.Provider>
    );
});
