import React from "react";
import { Alert, Col, message, notification, Row, Space, Upload } from "antd";
import { OrdersCreateTable } from "../table/orders.create.table";
import { OrdersCreateEditForm } from "./orders-create-edit-form/orders.create.edit.form";
import { observer } from "mobx-react";
import { useRequiredStore } from "../../../../../utils/store";
import { OrdersCreateStoreContext } from "../orders.create.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImportResponseError } from "../../../../../models/import/import.response";
import { OrdersCreateMissingItems } from "../missing-items/orders.create.missing.items";
import { OrdersCreateMissingItemsAlert } from "../missing-items-alert/orders.create.missing.items.alert";
import "./order.create.cart.scss";
import {OrderCreateAdditionalPercentAlert} from "../additional-percent-alert/order.create.additional-percent.alert";
import {IconType} from "antd/es/notification/interface";

export const OrdersCreateCart: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(OrdersCreateStoreContext);

    const openNotificationWithErrors = ({
        status,
        errors,
    }: {
        status: IconType;
        errors: ImportResponseError[];
    }) => {
        notification[status]({
            message: t(status.toUpperCase()),
            description: (
                <Space
                    style={{ width: "100%" }}
                    direction={"vertical"}
                    size={[10, 10]}
                >
                    {errors.map((error) => (
                        <Alert
                            key={error.message + error.line + error.content}
                            closable={errors.length > 1}
                            style={{ width: "100%" }}
                            type={status}
                            message={
                                "At " +
                                error.line +
                                ": " +
                                error.content +
                                " (" +
                                error.message +
                                ")"
                            }
                        />
                    ))}
                </Space>
            ),
            duration: 1000,
            className: "cart-notification",
        });
    };

    const key = "upload";
    const props = {
        name: "file",
        multiple: false,
        showUploadList: false,
        action: "accounts/" + store.account.id + "/cart/import",
        customRequest: async (x: any) => {
            try {
                message.loading({ content: t("LOADING") + "...", key });
                const res = await store.importCart(x.file, (percent) =>
                    x.onProgress({ percent })
                );
                x.onSuccess(res);
            } catch (e: any) {
                x.onError(e);
            }
        },
        async onChange(info: any) {
            const { status } = info.file;
            if (status === "done") {
                await store.reloadCart();
                message.success({
                    content: t("UPLOAD.SUCCESS"),
                    key,
                    duration: 2,
                });
            } else if (status === "error") {
                message.error({ content: t("UPLOAD.FAIL"), key, duration: 5 });
            }
        },
        onSuccess: async (res: any, y: File) => {
            if (res.status !== "ok") {
                await store.reloadCart();
                openNotificationWithErrors(res);
                return;
            }
            await store.reloadCart();
            message.success({ content: t("UPLOAD.SUCCESS"), key, duration: 2 });
        },
        onError: (err: any, body: any) => {},
    };

    return (
        <Row gutter={[20, 40]}>
            {store.missingItems.length > 0 ? (
                <Col span={24}>
                    <OrdersCreateMissingItems />
                </Col>
            ) : (
                <>
                    <Col span={24}>
                        <OrdersCreateEditForm />
                    </Col>
                    <Col span={24}>
                        <OrdersCreateMissingItemsAlert />
                        <OrderCreateAdditionalPercentAlert />
                    </Col>
                    <Col span={24}>
                        {store.tableData.length ? <OrdersCreateTable /> : null}
                    </Col>
                    <Col span={24}>
                        <Row justify="center">
                            <div className="cart-upload upload-wrapper">
                                <Upload.Dragger
                                    style={{ width: "100%" }}
                                    {...props}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <FontAwesomeIcon
                                            icon={["fas", "file-upload"]}
                                            size={"4x"}
                                        />
                                    </p>
                                    <p className="ant-upload-text cart-upload-text">
                                        {t("UPLOAD.TEXT")}
                                    </p>
                                    <p className="ant-upload-text cart-upload-text cart-upload-info">
                                        {t("ORDERS.CREATE.UPLOAD.INFO_TEXT")}
                                    </p>
                                </Upload.Dragger>
                            </div>
                        </Row>
                    </Col>
                </>
            )}
        </Row>
    );
});
