import { Department, DepartmentFlatten } from "./department";

export const flattenDepartments = (departments: Department[]): DepartmentFlatten[] => {
    const res: DepartmentFlatten[] = [];
    const flatten = (departments: any[], count: number) => {
        const childPrefix = Array.from("—".repeat(count)).join("");
        departments.forEach((department) => {
            if (department.children?.length) {
                res.push({ id: department.id, name: childPrefix + " " + department.name, department: department });
                flatten(department.children, count + 1);
            } else {
                res.push({ id: department.id, name: childPrefix + " " + department.name, department: department });
            }
        });
    };
    flatten(departments, 0);
    return res;
};
