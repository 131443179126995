import React from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { useRequiredStore } from "../../../../../utils/store";
import { OrderDataStoreContext } from "../../order.data.store";
import { observer } from "mobx-react";
import { displayPrice } from "../../../../../models/prices/price.helper";
import {generateDetailsData} from "../../../../../models/orders/order-data/order-data-helper/order.data.helper";
import { OrderDetailsItem } from "../../../../../models/orders/order-data/order.data";
import { displayOrderDetailsPrice } from "../../../../../models/orders/order-data/helper/order.data.helper";
import "./order.data.details.scss";
import { PricesLegend } from "../../../../common/prices-legend/prices.legend";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { ExpandedTable } from "../../../../common/order-details/expanded.table";
import { Button, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RootStoreContext } from "../../../../../stores/root/root.store";
import {convertTableColumnsToColumnConfigs} from "../../../../../utils/table";
import {nonStrictComparator} from "../../../../ui/table/filter-dropdown/filter-dropdown.comparator";
import {FilterDropdown} from "../../../../ui/table/filter-dropdown/filter-dropdown";
import {OrdersCheckoutTableFooter} from "../../../orders-create/checkout/orders-checkout-order/orders.checkout.order.table.footer";

export const OrderDataDetails: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(OrderDataStoreContext);
    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);
    const columns: ColumnsType<OrderDetailsItem> = [
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "articleName",
            key: "order_data_order_detail_articleName",
            width: "135px",
            render: (article: string) =>
                article.toUpperCase() === "DELIVERY" ? (
                    t("DELIVERY.TITLE")
                ) : (
                    <Link to={"/articles/" + article} style={{ whiteSpace: "nowrap" }}>
                        {article}
                    </Link>
                ),
            sorter: (a, b) => a.articleName > b.articleName ? 1 : -1,
            onFilter: nonStrictComparator(v => v.articleName),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "articleDescription",
            key: "order_data_order_detail_articleDescription",
            width: "300px",
        },
        {
            title: t("PRICE"),
            dataIndex: "price",
            key: "order_data_order_detail_price",
            width: "167px",
            render: (price, row) => {
                if (!price) {
                    return null;
                }
                return displayOrderDetailsPrice(
                    { type: row.priceType, price },
                    t("PRICES." + row.priceType.toUpperCase())
                );
            },
            sorter: (a, b) => a.price!.value > b.price!.value ? 1 : -1,
        },
        {
            title: t("ORDERS.REQUESTED_QUANTITY"),
            dataIndex: "qtyRequested",
            key: "order_data_order_detail_qtyRequested",
            width: "150px",
            sorter: (a, b) => a.qtyRequested > b.qtyRequested ? 1 : -1,
        },
        {
            title: t("QUANTITY"),
            dataIndex: "quantity",
            key: "order_data_order_detail_quantity",
            width: "125px",
            sorter: (a, b) => a.quantity > b.quantity ? 1 : -1,
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "totalPrice",
            key: "order_data_order_detail_totalPrice",
            width: "140px",
            render: (item) => (item ? <>{displayPrice(item)}</> : null),
            sorter: (a, b) => a.totalPrice!.value > b.totalPrice!.value ? 1 : -1,
        },
        {
            title: t("SALE_PERCENTAGE"),
            key: "salePercentage",
            dataIndex: "salePercentage",
            width: "90px",
            render: (item: number) => <span>{item || "0"}%</span>,
            sorter: (a, b) => a.salePercentage > b.salePercentage ? 1 : -1,
        },
    ];

    return (
        <>
            <ExpandedTable<OrderDetailsItem>
                title="DETAILS"
                pageKey="order_page_order_details"
                columns={columns}
                columnsConfig={convertTableColumnsToColumnConfigs(columns)}
                tableData={generateDetailsData(store.order!.items)}
                tableLoading={store.loading}
                tableFooter={
                    <OrdersCheckoutTableFooter
                        backorderSummary={store.order!.backorderSummary}
                        stockSummary={store.order!.stockSummary}
                        openAddRowModal={store.openAddModal}
                        orderDiscount={store.order!.discount}
                        vatPercent={store.order!.vatPercent}
                        updateDiscount={store.updateDiscount}
                    />
                }
                additionButtons={
                    attributes.includes("order_edit") &&
                    (store.order!.status === "not_completed" || store.order!.status === "not_confirmed" || store.order!.status === "confirmed" || store.order!.status === "ordered") ? (
                        <>
                            <Tooltip placement="topRight" title={t("EDIT")}>
                                <Button
                                    className="btn data-description-btn"
                                    onClick={store.showEditItemsTable}
                                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                                />
                            </Tooltip>
                        </>
                    ) : null
                }
            >
                <PricesLegend style={{ marginBottom: 24 }} />
            </ExpandedTable>
        </>
    );
});
