import React from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import "./violation-list.scss"
import {Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {Violation} from "../../../stores/violation-list/violation-list.store";

const ViolationListAction: React.FC<{icon: IconProp, onClick: () => any}> = ({icon, onClick}) => {
    return (
        <div className={"violation-list-header-action"} onClick={onClick}>
            <FontAwesomeIcon icon={icon}/>
        </div>
    )
}

const ViolationListShowButton: React.FC<{onClick: () => any}> = ({onClick}) => {
    return (
        <div className={"violation-list-show-button"} onClick={onClick}>
            Violation List
        </div>
    )
}

export const ViolationList: React.FC = observer(() => {

    const {violationListStore} = useRequiredStore(RootStoreContext)

    const columns: ColumnsType<Violation> = [
        {
            key: "id",
            title: "ID",
            dataIndex: "id"
        },
        {
            key: "violation",
            title: "Violation",
            dataIndex: "violation"
        },
    ]

    if (!violationListStore.shown) {
        return null;
    }

    if (violationListStore.hidden) {
        return <ViolationListShowButton onClick={() => violationListStore.open()} />
    }

    return (
        <div className={"violation-list"}>
            <div className={"violation-list-header"}>
                    <div className={"violation-list-header-title"}>
                        Violation List
                    </div>
                    <div className={"violation-list-header-actions"}>
                        <ViolationListAction icon={["fas", "minus"]} onClick={() => violationListStore.hide()} />
                        <ViolationListAction icon={["fas", "times"]} onClick={() => violationListStore.close()} />
                    </div>
            </div>
            <div className={"violation-list-body"}>
                <Table
                    columns={columns}
                    dataSource={violationListStore.violations}
                    pagination={false}
                />
            </div>
        </div>
    )
});