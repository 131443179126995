import React from "react";
import {Button, Collapse, Space} from "antd";
import {DeclarationUpload, UploadedDeclarationResponse} from "../declaration.upload.model";
import {DeclarationCartWrapper} from "../common/declaration-cart/declaration.cart.wrapper";

interface DeclarationSaveStepProps {
    params: DeclarationUpload,
    result: UploadedDeclarationResponse,
    onGoBack: () => void,
    namePrefix: string
}

export const DeclarationSaveStep: React.FC<DeclarationSaveStepProps> = ({params, result, onGoBack, namePrefix}) => {

    return (
        <Space direction={"vertical"} size={[20, 20]}>
            <Collapse style={{width: '100%'}}>
                {result.declarations.map(declaration => (
                    <DeclarationCartWrapper key={declaration.key} params={params} result={result} declaration={declaration} namePrefix={namePrefix} />
                ))}
            </Collapse>
            <Button onClick={() => onGoBack()}>Go Back</Button>
        </Space>
    )
}