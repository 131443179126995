import React, {useState} from "react";
import {Button, Col, Row, Space, Tooltip, Typography} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {OrdersCheckoutAddressesModal} from "./orders.checkout.addresses.modal";
import {OrdersCheckoutAddressesCard} from "./orders.checkout.addresses.card";
import {OrdersCheckoutAddress} from "../../../../../models/orders/orders-cart/orders-checkout/orders.checkout.address";
import {NotCompletedOrder} from "../../../../../models/orders/orders-cart/orders-checkout/orders.checkout.not_completed.order";

interface OrdersCheckoutAddressesProps {
    order: NotCompletedOrder,
    title: string,
    addresses: OrdersCheckoutAddress[],
    type: "shipping" | "billing",
    onCardClick: (order: NotCompletedOrder, address: OrdersCheckoutAddress, checked: boolean) => Promise<void>
}

export const OrdersCheckoutAddresses: React.FC<OrdersCheckoutAddressesProps> = observer((
    {
        type,
        title,
        order,
        addresses,
        onCardClick
    }) => {
    const t = useTranslate();

    const [modalShown, setModalShown] = useState<boolean>(false);


    return (
        <Space direction={"vertical"} size={[0, 0]}>
            <Row>
                <Typography.Title level={3}>{t(title)}</Typography.Title>
                <Tooltip key={2} placement="topLeft" title={t("ADDRESS_ADD")}>
                    <Button
                        type="default"
                        className="btn"
                        style={{margin: "4px 0 0 5px"}}
                        onClick={() => setModalShown(true)}
                        icon={<FontAwesomeIcon icon={["fas", "plus"]}/>}
                    />
                </Tooltip>
            </Row>
            <Row gutter={[30, 30]}>
                {addresses ? addresses.map((address, index) => (
                    <Col className={"card-col"} key={address.id ? address.id : index} xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                        <OrdersCheckoutAddressesCard
                            order={order}
                            address={address}
                            onClick={onCardClick}
                        />
                    </Col>
                )) : null}
            </Row>
            <OrdersCheckoutAddressesModal
                type={type}
                order={order}
                visible={modalShown}
                onClose={() => setModalShown(false)}
            />
        </Space>
    );
});
