import React, {useContext, useEffect} from "react";
import {Col, Form, Input, Row} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {useForm} from "antd/es/form/Form";
import {StepperContext, StepperContextValue} from "../../../../common/stepper2/stepper2";
import {CreateClaimItem} from "../../../../../models/claims/claims";
import {claimService} from "../../../../../services/claims/claims.service";
import {FileUploader} from "../../../../core/file-uploader/file-uploader";

export const DescriptionStep: React.FC = () => {

    const ctx = useContext<StepperContextValue<CreateClaimItem>>(StepperContext);

    const [form] = useForm();

    const t = useTranslate();

    useEffect(() => {
        ctx.setOnStepComplete(async () => {
            await form.validateFields();
            form.submit();
        })
    }, [ctx, form]);

    return (
        <Form
            form={form}
            initialValues={ctx.data}
            layout="vertical"
            onFinish={values => {
                ctx.data.data!.description = values.description;
                ctx.data.data!.images = values.images;
            }}
        >
            <Row gutter={10}>
                <Col span={24}>
                    <Form.Item
                        label={t('DESCRIPTION')}
                        name={['description']}
                        rules={[{ required: true }]}
                    >
                        <Input.TextArea style={{height: 140}} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col span={24}>
                    <Form.Item
                        label="Attachments"
                        name={['images']}
                    >
                        <FileUploader
                            action={"/api/v3/claim/attachment"}
                            onRemove={file => claimService.removeFile(parseInt(file.uid))}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}