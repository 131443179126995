import React, {useEffect, useState} from "react";
import {Button, Space, Typography} from "antd";
import {Link} from "react-router-dom";
import {useTranslate} from "../../../../hooks/translate.hook";
import {reportsService} from "../../../../services/reports/reports.service";

export const ReportsMainPageCategories: React.FC = () => {

    const t = useTranslate();

    const [categories, setCategories] = useState<Record<string, {name: string, attr: string}[]>>({});

    useEffect(() => {
        reportsService.getCategories().then(c => setCategories(c))
    }, [])

    return (
       <Space direction="vertical" size={[20, 20]}>
            {Object.keys(categories).map(categoryName => (
                <Space direction="vertical" size={[0, 0]}>
                    <Typography.Title level={3}>{t("REPORTS." + categoryName.toUpperCase() + ".TITLE")}</Typography.Title>
                    <Space direction="horizontal" size={[5, 5]} wrap>
                        {categories[categoryName].map(category => (
                            <Link to={"/reports/" + category.name}>
                                <Button type={"primary"}>
                                    {t("REPORTS." + category.name.toUpperCase() + ".TITLE")}
                                </Button>
                            </Link>
                        ))}
                    </Space>
                </Space>
            ))}
       </Space>
    )
}