import {ColumnsType} from "antd/es/table";
import {
    MergedInvoiceIncludedInvoice
} from "../../../../models/documents/invoices/merged-invoice/merged-invoice-data/merged.invoice.data";
import {Link} from "react-router-dom";
import React from "react";
import {Translate} from "../../../../hooks/translate.hook";
import {Amount} from "../../../../models/prices/price";
import {displayPrice} from "../../../../models/prices/price.helper";
import Date from "../../../date/date";

export const columnFactory = (t: Translate): ColumnsType<MergedInvoiceIncludedInvoice> => {
    return [
        {
            title: t("INVOICES.NUMBER"),
            dataIndex: "docNumber",
            key: "merged_invoice_page_included_invoices_number",
            width: "120px",
            render: (_: any, item) => <Link to={"/invoices/" + item.id}>{item.docNumber}</Link>
        },
        {
            title: t("INVOICES.PACKING_LIST_NR"),
            key: "merged_invoice_page_included_packing_list_number",
            width: "120px",
            render: (_: any, item) => item.packingListDocument?.docNumber ?
                <Link to={"/packing-lists/" + item.packingListDocument?.id}>{item.packingListDocument?.docNumber}</Link> : "-"
        },
        {
            title: t("INVOICES.DISPATCH.IS_DISPATCHED"),
            dataIndex: "dispatched",
            key: "merged_invoice_page_included_invoices_dispatched",
            width: 110,
            render: (item: boolean) => item ? t("INVOICES.DISPATCH.DISPATCHED") : t("INVOICES.DISPATCH.NOT_DISPATCHED")
        },
        {
            title: t("INVOICES.DISPATCH.DISPATCH_DATE"),
            dataIndex: "date",
            key: "merged_invoice_page_included_invoices_invoiceCreatedAt",
            width: 100,
            render: (date: string, row) => row.dispatched ? <Date dateString={date}/> : "-"
        },
        {
            title: t("TOTAL_PRICE"),
            dataIndex: "subtotal",
            key: "merged_invoice_page_included_invoices_subtotal",
            width: 100,
            render: (amount: Amount) => <span style={{fontWeight: "bold"}}>{displayPrice(amount)}</span>
        },
        {
            title: "VAT",
            dataIndex: "subtotalVat",
            key: "merged_invoice_page_included_invoices_subtotalVat",
            width: 100,
            render: (amount: Amount) => <span style={{fontWeight: "bold"}}>{displayPrice(amount)}</span>
        },
        {
            title: t("GRAND_TOTAL_PRICE"),
            dataIndex: "grandTotal",
            key: "merged_invoice_page_included_invoices_grandTotal",
            width: 100,
            render: (amount: Amount) => <span style={{fontWeight: "bold"}}>{displayPrice(amount)}</span>
        },
        {
            title: t("SALE_PERCENTAGE"),
            key: "salePercentage",
            dataIndex: "salePercentage",
            width: "80px",
            render: (item: string) => <span>{item || "0"}%</span>
        },
        {
            title: t("WEIGHT_BRUTTO"),
            dataIndex: "totalGrossWeight",
            key: "merged_invoice_page_included_invoices_totalGrossWeight",
            width: 80,
            render: (item: string) => item !== undefined ? item.replace(/\./g, ",") : null
        }
    ]
}