import { ExportFields } from "../../../models/export/epxort";
import { ExportFormat } from "../../../stores/common/common.store";

export class ExportStore {
    private filters: { [k: string]: any } = {};

    public defaultValue = {
        withHeaders: true,
        separator: ";",
        escapeChar: "\\",
        enclosure: '"',
        format: this.formats[0],
    };

    public downloadLink: string = "";

    constructor(
        private prefix: string | null,
        private link: string,
        private additionalDefaultValues: { [k: string]: any },
        private hasFiltersParams: boolean | undefined,
        private formats: ExportFormat[]
    ) {}

    public getFilters(value: { [k: string]: any } = {}): void {
        this.setFilters(value);
    }

    private setDownloadLink(link: string): void {
        this.downloadLink = link;
    }
    private setFilters(value: { [k: string]: any }) {
        this.filters = value;
    }

    private createQueryItem(key: string, value: any): string {
        if (this.prefix === null) {
            return key + "=" + encodeURIComponent(value);
        }
        return this.prefix + "[" + key + "]=" + encodeURIComponent(value);
    }

    private generateExportQueryString(values: ExportFields): string {
        const out: string[] = [];
        for (const key in values) {
            if (values.hasOwnProperty(key) && values[key]) {
                if (Array.isArray(values[key])) {
                    values[key].forEach((item: any) => {
                        out.push(this.createQueryItem(key, item).replace("=", "[]="));
                    });
                } else if (typeof values[key] === "object") {
                    if (values[key].value) {
                        out.push(this.createQueryItem(key, values[key].value));
                    }
                } else {
                    out.push(this.createQueryItem(key, values[key]));
                }
            }
        }
        return out.join("&");
    }

    private convertBooleanValuesToString(values: { [k: string]: any } = {}): any {
        const obj: { [k: string]: any } = {};
        Object.keys(values).forEach((key) => {
            if (values[key] === true) {
                obj[key] = "1";
            } else if (values[key] === false) {
                obj[key] = "0";
            } else {
                obj[key] = values[key];
            }
        });
        return obj;
    }

    private generateParamsQueryString(values: { [k: string]: any } = {}): string {
        const out: string[] = [];
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                if (Array.isArray(values[key])) {
                    values[key].forEach((item: any) => {
                        out.push(key + "[]=" + encodeURIComponent(item));
                    });
                } else {
                    out.push(key + "=" + encodeURIComponent(values[key]));
                }
            }
        }
        return out.join("&");
    }

    public getDownloadLink(values: any) {
        if (values.date) {
            if (Array.isArray(values.date)) {
                const from = values.date[0].format("YYYY-MM-DD");
                const until = values.date[1].format("YYYY-MM-DD");
                delete values.date;
                values.until = until;
                values.from = from;
            } else {
                values.date = values.date.format("YYYY-MM-DD");
            }
        }

        const exportQueryString = this.generateExportQueryString({
            ...this.convertBooleanValuesToString(values),
        });
        const url = this.generateParamsQueryString(this.filters);
        return url.length > 0
            ? this.link + "/export?" + exportQueryString + "&" + url
            : this.link + "/export?" + exportQueryString
    }
}
