import React, {useState} from "react";
import {Button, Descriptions, Dropdown, Menu, Row, Space, Tooltip, Typography} from "antd";
import {useNavigate} from "react-router";
import {Shipment} from "../../../models/parcels/shipment";
import Date from "../../date/date";
import {ShipmentsStatus} from "../shipments-status/shipments.status";
import {Link} from "react-router-dom";
import {shipmentsService} from "../../../services/shipments/shipments.service";
import {notifications} from "../../../services/notifications/notifications";
import {ChangeCarrierModal} from "./change-carrier-modal/change-carrier-modal";
import {ShipmentsDispatchModal} from "../shipments-dispatch-modal/shipments-dispatch-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EditModal} from "./edit-modal/edit-modal";
import {useTranslate} from "../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";

interface InvoiceDataHeaderProps {
    shipment: Shipment;
    reload: () => void;
}

export const ShipmentHeader: React.FC<InvoiceDataHeaderProps> = ({shipment, reload}) => {
    const t = useTranslate();

    const navigate = useNavigate();

    const [changeCarrierModalShown, setChangeCarrierModalShown] = useState<boolean>(false)

    const [dispatchModalShown, setDispatchModalShown] = useState<boolean>(false)

    const [editModalShown, setEditModalShown] = useState<boolean>(false)

    const createStickers = async () => {
        try {
            await shipmentsService.createStickers([shipment.id]);
            notifications.successfully();
        } catch (e: any) {
            notifications.message("error", e as string);
        }
        reload();
    }

    return (
        <>
            <PageHeader
                className="invoice-header"
                style={{ padding: 0 }}
                ghost={false}
                title={
                    <Typography.Title level={1} style={{ margin: 0 }}>
                        {t("SHIPMENTS.ITEM.TITLE")}
                    </Typography.Title>
                }
                onBack={() => {
                    navigate("/shipments");
                }}
                extra={<ShipmentsStatus status={shipment.status} large={true} />}
            >
                <Space direction="vertical" size={[15, 15]}>
                    <Row justify={"space-between"}>
                        <Space className={"invoice-data-header-buttons"} direction={"horizontal"} size={[20, 20]} wrap>
                            <Dropdown
                                placement={"bottomLeft"}
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        {shipment.orders.map((order) => (
                                            <Menu.Item key={order.id}>
                                                <Link
                                                    style={{ textAlign: "left" }}
                                                    className={"ant-btn ant-btn-link"}
                                                    to={"/orders/" + order.id}
                                                >
                                                    {order.id} ({order.status})
                                                </Link>
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                            >
                                <Button type="primary">{t("ORDERS.LIST")}</Button>
                            </Dropdown>
                        </Space>
                        <Space className={"invoice-data-header-buttons"} direction={"horizontal"} size={[20, 20]} wrap>
                            <Dropdown
                                placement={"bottomLeft"}
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        <Menu.Item onClick={() => createStickers()}>
                                            Create labels
                                        </Menu.Item>
                                        <Menu.Item onClick={() => setChangeCarrierModalShown(true)}>
                                            Change carrier & Create labels
                                        </Menu.Item>
                                        <Menu.Item>
                                            <a href={"/orders/shipments/labels?id[]=" + shipment.id}>
                                                Download labels
                                            </a>
                                        </Menu.Item>
                                        {shipment.procedureName === 'autodoc' ? (
                                            <Menu.Item>
                                                <a href={"/api/v3/orders/shipments/" + shipment.id + "/autodoc-psl"}>
                                                    Download AutoDoc PSL label
                                                </a>
                                            </Menu.Item>
                                        ) : null}
                                        <Menu.Item onClick={() => setDispatchModalShown(true)}>
                                            {t("DISPATCH.DO_DISPATCH")}
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button type="primary">{t("ACTIONS.TITLE")}</Button>
                            </Dropdown>
                        </Space>
                    </Row>

                    <div className="data-descriptions-with-edit-wrapper">
                        <div className="data-content-wrapper">
                            <Descriptions
                                className="data-content-wrapper-descriptions"
                                size={"small"}
                                layout={"vertical"}
                                contentStyle={{ fontSize: "17px" }}
                                labelStyle={{ color: "#858585" }}
                                column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
                            >
                                <Descriptions.Item label={t("SHIPMENTS.ITEM.TITLE")}>
                                    {shipment.number}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("REFERENCE.TITLE")}>
                                    {shipment.reference}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("SHIPMENTS.CARRIER.TITLE")}>
                                    {shipment.carrier.name}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("WAREHOUSES.WAREHOUSE_TITLE")}>
                                    {shipment.warehouse.name}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("DATE")}>
                                    <Date dateString={shipment.packedAt || shipment.createdAt} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t("NETTO_WEIGHT")}>
                                    {shipment.netWeight} kg.
                                </Descriptions.Item>
                                <Descriptions.Item label={t("WEIGHT_BRUTTO")}>
                                    {shipment.grossWeight} kg.
                                </Descriptions.Item>
                            </Descriptions>
                            <Tooltip placement="topRight" title={t("EDIT")}>
                                <Button
                                    className="data-description-btn btn"
                                    onClick={() => setEditModalShown(true)}
                                    type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </Space>
            </PageHeader>
            {changeCarrierModalShown ? (
                <ChangeCarrierModal
                    shipment={shipment}
                    onCancel={() => setChangeCarrierModalShown(false)}
                    onComplete={() => {
                        setChangeCarrierModalShown(false);
                        reload();
                        notifications.successfully();
                    }}
                />
            ) : null}
            {dispatchModalShown ? (
                <ShipmentsDispatchModal
                    shipments={[shipment.id]}
                    onClose={() => setDispatchModalShown(false)}
                    onSuccess={() => reload()}
                />
            ) : null}
            {editModalShown ? (
                <EditModal
                    shipment={shipment}
                    onClose={() => setEditModalShown(false)}
                    onComplete={() => reload()}
                />
            ) : null}
        </>
    );
}