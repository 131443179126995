import React from 'react';
import {Error} from "../../../models/errors/error";
import {IntegrationsUploadAction} from "./actions/integrations.upload.action";
import {DocumentsExchangeHandlingAction} from "./actions/documents.exchange-handling.action";

interface TypeConfig {
    [type: string]: React.FunctionComponent<any>
}

type GroupConfig = Record<string, TypeConfig>

export interface ActionProps {
    error: Error
}

const config: GroupConfig = {
    integrations: {
        upload: IntegrationsUploadAction
    },
    documents: {
        exchange: DocumentsExchangeHandlingAction,
        handling: DocumentsExchangeHandlingAction
    }
}

interface ErrorsListActionsProps {
    error: Error,
}
export const ErrorsListActions: React.FC<ErrorsListActionsProps> = ({error}) => {
    if (!error) {
        return <></>
    }
    if (!config[error.group] || !config[error.group][error.type]) {
        return <></>
    }
    const Actions: any = config[error.group][error.type];
    return <Actions error={error}/>
};
