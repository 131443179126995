import React from "react";
import {PlannerTaskPriority} from "../../../../../models/planner/planner";
import {Tag} from "antd";
import {IconName} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface TaskPriorityProps {
    priority: PlannerTaskPriority;
}

const colorMap: Record<PlannerTaskPriority, [string, IconName]> = {
    [PlannerTaskPriority.LOW]: ["green", "couch"],
    [PlannerTaskPriority.MIDDLE]: ["yellow", "exclamation-triangle"],
    [PlannerTaskPriority.HIGH]: ["red", "bolt"],
    [PlannerTaskPriority.EMERGENCY]: ["red", "bolt"],
}

export const TaskPriority: React.FC<TaskPriorityProps> = ({priority}) => {

    const [color, icon] = colorMap[priority];

    return (
        <Tag
            color={color}
            icon={<FontAwesomeIcon icon={["fas", icon]} />}
        >
            &nbsp;{priority.capitalizeFirstLetter()}
        </Tag>
    )
}
