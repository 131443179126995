import React from 'react';
import {AuthStore} from "../auth/auth.store";
import {MenuStore} from "../interface/menu-store";
import {PagesStore} from "../pages/pages.store";
import {LangStore} from "../lang/lang.store";
import {CommonStore} from "../common/common.store";
import {ConfirmStore} from "../confirm/confirm.store";
import {ViolationListStore} from "../violation-list/violation-list.store";
import {NavigateFunction} from "react-router/dist/lib/hooks";

export class RootStore {

    public pageStore: PagesStore;

    public commonStore: CommonStore;

    public authStore: AuthStore;

    public menuStore: MenuStore;

    public langStore: LangStore;

    public confirmStore: ConfirmStore;

    public violationListStore: ViolationListStore;

    constructor(navigate: NavigateFunction) {
        this.pageStore = new PagesStore();
        this.commonStore = new CommonStore(navigate);
        this.authStore = new AuthStore(this.commonStore);
        this.menuStore = new MenuStore();
        this.langStore = new LangStore();
        this.confirmStore = new ConfirmStore();
        this.violationListStore = new ViolationListStore();
    }

    public init() {
        this.authStore.init();
        this.langStore.init();
    }
}

export const RootStoreContext = React.createContext<RootStore | null>(null);
