import React from "react";
import { Space, Spin } from "antd";

export const LoadingSpin: React.FC = () => {
    return (
        <Space
            direction={"horizontal"}
            align={"center"}
            style={{
                height: "calc(100vh - 90px)",
                justifyContent: "center",
            }}
        >
            <Spin />
        </Space>
    );
};
