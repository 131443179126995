import React, {useEffect, useMemo, useState} from "react";
import {Claim, ClaimItem, ClaimItemType} from "../../../../models/claims/claims";
import {Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Space, Table, Tabs} from "antd";
import {createSplitItem, SplitItemListItem} from "./model";
import {SplitItemList} from "./item-list/item-list";
import {claimService} from "../../../../services/claims/claims.service";
import {dataFactory, TableLineData} from "./split.modal.data";
import {columnsFactory} from "./split.modal.columns";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useForm} from "antd/es/form/Form";

interface SplitModalProps {
    claim: Claim;
    item: ClaimItem;
    onClose: () => void,
    onComplete: () => void
}

export const SplitModal: React.FC<SplitModalProps> = ({claim, item, onClose, onComplete}) => {

    const t = useTranslate();

    const [customForm] = useForm();

    const [itemList, setItemList] = useState<SplitItemListItem[]>([]);

    const [mergedInvoices, setMergedInvoices] = useState<TableLineData[]>([]);

    const [usedQty, setUsedQty] = useState<number>(0);

    const updateItemList = (items: SplitItemListItem[]) => {
        setItemList(items);
        setUsedQty(items.reduce<number>((acc, val) => acc + val.qty, 0))
    }

    const onSelect = (item: TableLineData, value: number) => {

        item.qty -= value;

        const newItem: SplitItemListItem = {
            type: ClaimItemType.KNOWN,
            qty: value,
            invoiceNumber: item.invoice.docNumber,
            invoiceDate: item.invoice.date,
            price: item.price,
            item: item.item.id
        }
        updateItemList([...itemList, newItem])
    }

    const onManualItem = (values: any) => {
        const newItem: SplitItemListItem = {
            type: ClaimItemType.UNKNOWN,
            qty: values.qty,
            invoiceNumber: values.invoiceNumber,
            invoiceDate: values.invoiceDate,
            price: {value: values.price, currencyCode: claim.items[0]?.priceByItem.currencyCode || ''},
        }
        updateItemList([...itemList, newItem])
    }

    const onRemove = (item: SplitItemListItem) => {

        if (item.type === ClaimItemType.KNOWN) {
            const data = mergedInvoices.find(i => i.docNumber === item.invoiceNumber)
            if (data) {
                data.qty += item.qty;
            }
        }

        const idx = itemList.indexOf(item);
        if (-1 !== idx) {
            itemList.splice(idx, 1);
            updateItemList([...itemList])
        }
    }

    const complete = async () => {
        await claimService.split(claim.id, item.id, itemList)
        onComplete();
        onClose();
    }

    const allowedQty = item.qty - usedQty;

    const columns = useMemo(() => columnsFactory(t, allowedQty, onSelect), [itemList, allowedQty]);

    useEffect(() => {
        claimService
            .searchMergedInvoices(claim.account.id, item.article.id)
            .then(mergedInvoices => {
                const mergedInvoices2 = dataFactory(mergedInvoices);
                const itemList = [];
                if (item.invoiceDocumentNumber) {
                    const splitItem = createSplitItem(item);
                    itemList.push(splitItem)
                    if (item.type === ClaimItemType.KNOWN) {
                        const data = mergedInvoices2.find(i => i.docNumber === splitItem.invoiceNumber)
                        if (data) {
                            data.qty -= item.qty;
                        }
                    }
                }

                setMergedInvoices(mergedInvoices2);
                updateItemList(itemList)
            });
    }, [item])


    return (
        <Modal
            visible={true}
            width={1000}
            onCancel={onClose}
            title={"SPLIT"}
            footer={false}
        >
            <h2 style={{marginTop: 10}}>Items</h2>

            <SplitItemList itemList={itemList} onRemove={onRemove} />

            <p style={{marginTop: 10}}>
                Current Qty: {item.qty}, Used Qty: {usedQty}
            </p>

            <Button
                type={"primary"}
                onClick={() => complete()}
                disabled={itemList.length === 0}
            >Save</Button>

            <h2 style={{marginTop: 30}}>Add a new item (Allowed Qty: {allowedQty})</h2>

            <Tabs>
                <Tabs.TabPane tab={"Select"} key={1}>
                    <Table
                        columns={columns}
                        dataSource={mergedInvoices}
                        pagination={false}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={"Add manually"} key={2}>
                    <Space size={[10, 10]}>
                        <Form
                            form={customForm}
                            initialValues={{}}
                            layout="vertical"
                            onFinish={values => {
                                customForm.validateFields().then(() => {
                                    onManualItem(values);
                                    customForm.resetFields();
                                })
                            }}
                        >
                            <Row gutter={10}>
                                <Col span={24}>
                                    <Form.Item
                                        label={t('INVOICE.NUMBER_FEBEST')}
                                        name={['invoiceNumber']}
                                        rules={[{ required: true }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col span={12}>
                                    <Form.Item
                                        label={t('INVOICES.INVOICE.DATE')}
                                        name={['invoiceDate']}
                                        rules={[{ required: true }]}
                                    >
                                        <DatePicker style={{width: "100%"}} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={t('PRICE')}
                                        name={['price']}
                                        rules={[{ required: true }]}
                                    >
                                        <InputNumber style={{width: "100%"}} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={t('QUANTITY_LONG')}
                                        name={['qty']}
                                        rules={[{ required: true }]}
                                    >
                                        <InputNumber style={{width: "100%"}} max={allowedQty} min={1} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <Button
                                        type={"primary"}
                                        onClick={() => customForm.submit()}
                                    >
                                        Add
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Space>
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    )
}