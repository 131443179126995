import { action, makeObservable, observable } from "mobx";

export class PagesStore {

    @observable
    public fullWidth: boolean = false;

    @observable
    public routes: { [k: string]: { systemName: string; path: string } } = {};

    constructor() {
        makeObservable(this);
    }

    @action
    public setFullWidth(value: boolean): void {
        this.fullWidth = value;
    }

    @action
    public setRoutes(value: { [k: string]: { systemName: string; path: string } }): void {
        const oldValues = this.routes;
        Object.keys(value).forEach(key => {
            if (undefined !== oldValues[key]) {
                value[key] = oldValues[key];
            }
        })

        this.routes = value;
    }

    @action
    public updateRouteName(name: string, visibleName: any): void {
        this.routes[name] = {...this.routes[name], systemName: visibleName}
    }
}
