import React, {useState} from "react";
import {observer} from "mobx-react";
import {PackingListDataStore, PackingListDataStoreContext} from "./packing-list.data.store";
import {useParams, useLocation} from "react-router-dom";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {Space} from "antd";
import {PackingListDataHeader} from "./header/packing-list.data.header";
import {PayerSellerReceiverData} from "../../../common/payer-seller-receiver-data/payer.seller.receiver.data";
import {PackingListDataDetails} from "./details/packing-list.data.details";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {DocumentShipments} from "../../common/shipments/document.shipments";

export const PackingListData: React.FC = observer(() => {
    const {packingListId} = useParams<{packingListId: string}>();
    const {pageStore} = useRequiredStore(RootStoreContext);
    const match = useLocation();
    const [store] = useState(() => new PackingListDataStore(packingListId!, pageStore, match.pathname));

    if (store.loading) {
        return <LoadingSpin/>
    }

    return (
        <PackingListDataStoreContext.Provider value={store}>
            <Space direction="vertical" size={[50, 50]}>
                <PackingListDataHeader/>
                <PayerSellerReceiverData
                    payer={store.packingListData!.billingAddress}
                    receiver={store.packingListData!.shippingAddress}
                    seller={store.packingListData!.sellerAddress}
                    editPayerReceiver={store.editPayerReceiverData}
                    editSeller={store.editSellerData}
                />
                <DocumentShipments id={store.packingListData!.id} />
                <PackingListDataDetails/>
            </Space>
        </PackingListDataStoreContext.Provider>
    )
})
