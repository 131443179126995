import axios, {AxiosResponse} from 'axios';

declare module 'axios' {
    export interface AxiosRequestConfig {
        isAuthRequired?: boolean;
        allowNotFound?: boolean;
    }
}

const API = axios.create();

API.defaults.baseURL = '/api/v3';
API.defaults.isAuthRequired = true;

// NB! do not forget to use isAuthRequired: false if authentication not required

export interface BlobAndFilename {
    blob: Blob,
    filename: string,
}

export const getBlobAndFileName = (response: AxiosResponse<Blob>): BlobAndFilename => {
    const contentDisposition = response.headers["content-disposition"];
    const filename = contentDisposition.split("filename=")[1];
    return {
        blob: response.data,
        filename: filename,
    }
}

export default API;