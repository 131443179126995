import React from "react";
import {Space, Table, Typography} from "antd";
import {SmartLoggerData} from "../../../models/logger/logger.data";
import {ColumnsType} from "antd/es/table";
import {useTranslate} from "../../../hooks/translate.hook";
import "./smart.logger.changes.wrapper.scss";

interface LoggerChangesWrapperProps {
    row: SmartLoggerData
}
export const SmartLoggerChangesWrapper: React.FC<LoggerChangesWrapperProps> = ({row}) => {
    const t = useTranslate();
    const columns: ColumnsType<any> = row.type === "update" ? [
        {
            title: t("FIELD"),
            dataIndex: 0,
            key: 0,
            width: 250,
        },
        {
            title: t("OLD_VALUE"),
            dataIndex: 1,
            key: 1,
            width: 270,
            className: "logger-changes-table-cell old-value-col",
            render: item => item === "" ? <i>{"(empty)"}</i> : item
        },
        {
            title: t("NEW_VALUE"),
            dataIndex: 2,
            key: 2,
            // width: 250,
            className: "logger-changes-table-cell new-value-col",
            render: item => item === "" ? <i>{"(empty)"}</i> : item
        }
    ] : [
        {
            title: t("FIELD"),
            dataIndex: 0,
            key: 0,
            width: 200,
        },
        {
            title: t("FILTER.VALUE"),
            dataIndex: 2,
            key: 2,
        }
    ]
    return <div>
        <Table
            rowKey="0"
            className="smart-logger-changes-table"
            columns={columns}
            dataSource={row.changes}
            scroll={undefined}
            pagination={false}
            rowClassName={(record: string[]) => record[1] === "" ? "" : "update-row"}
        />
    </div>
}