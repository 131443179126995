import React, {useState} from "react";
import {observer} from "mobx-react";
import {useParams, useLocation} from "react-router-dom";
import {InvoiceDataStore, InvoiceDataStoreContext} from "./invoice.data.store";
import {Space, Spin} from "antd";
import {InvoiceDataHeader} from "./invoide-data-header/invoice.data.header";
import {PayerSellerReceiverData} from "../../../common/payer-seller-receiver-data/payer.seller.receiver.data";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {InvoiceDataOrderDetails} from "./invoice-data-order-details/invoice.data.order.details";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {DocumentShipments} from "../../common/shipments/document.shipments";
import {InvoiceDataOrders} from "./invoice-data-orders/invoice.data.orders";
import {useNavigate} from "react-router";

interface InvoiceDataProps extends ComponentPropsFromRoute {}
export const InvoiceData: React.FC<InvoiceDataProps> = observer(({requiredConfigs}) => {
    const {invoiceId} = useParams<{invoiceId: string}>();
    const {pageStore} = useRequiredStore(RootStoreContext);
    const match = useLocation();
    const navigate = useNavigate();
    const [store] = useState(() => new InvoiceDataStore(invoiceId!, pageStore, match.pathname, navigate));

    if (store.loading) {
        return (
            <Space direction={"horizontal"} align={"center"} style={{height: "calc(100vh - 90px)", justifyContent: "center"}}>
                <Spin/>
            </Space>
        );
    }

    return (
        <InvoiceDataStoreContext.Provider value={store}>
            <Space direction="vertical" size={[50, 50]}>
                <InvoiceDataHeader/>
                <PayerSellerReceiverData
                    payer={store.invoice!.billingAddress}
                    receiver={store.invoice!.shippingAddress}
                    seller={store.invoice!.sellerAddress}
                    editPayerReceiver={store.editPayerReceiverData}
                    editSeller={store.editSellerData}
                />
                <DocumentShipments id={store.invoice!.id} />
                <InvoiceDataOrders document={store.invoice!} reload={() => store.reload()} />
                <InvoiceDataOrderDetails/>
            </Space>
        </InvoiceDataStoreContext.Provider>
    );
})
