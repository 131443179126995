import React, {useState} from "react";
import {Col, Form, Input, Modal, Row, Upload} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {
    declarationsDocumentCodeFilesService
} from "../../../../services/declarations/declarations.document-code-files.service";

export interface Props {
    onClose(): void;
    onComplete(): void;
}

export const DocumentsCodeFilesUploadModal: React.FC<Props> = ({onClose, onComplete}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const initialValues: any = {};

    return (
        <Modal
            open={true}
            confirmLoading={loading}
            title={t("ADD")}
            okText={t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => onClose()}
        >
            <Form
                form={form}
                layout="vertical"
                name={"address_form"}
                initialValues={initialValues}
                onFinish={() =>
                    form.validateFields().then(async (values) => {
                        setLoading(true);
                        try {
                            await declarationsDocumentCodeFilesService.create(values);
                            onComplete();
                            onClose();
                        } finally {
                            setLoading(false);
                        }
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="documentCode"
                            label={t("DECLARATION.DOCUMENT_CODE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                placeholder={t("DECLARATION.DOCUMENT_CODE")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={t("FILE")}
                            name="file"
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                            valuePropName={""}
                            getValueFromEvent={(e) => e.file || null}
                        >
                            <Upload.Dragger
                                beforeUpload={() => false}
                                accept={".pdf"}
                                maxCount={1}
                                disabled={loading}
                            >
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
