import {WholesaleAccount} from "../../../../models/accounts/customer-account/wholesale-account/wholesale.account";
import {mergeWith} from "lodash";
export const resolveDefaults = (): any => {
    const def: any = {
        account: {
            legalType: 'legal',
            language: 'en',
            orderType: 'wholesale',
        },
        agent: {
            group: 'wholesale'
        },
        price: {
            discount: 0,
            paymentTerms: 0,
            applyMinimalPrice: true
        },
        documents: {
            documentType: 'ee_default',
            invoiceDocumentType: 'regular',
            invoicingPeriod: 'day',
            emailType: 'default',
            allowedMergedInvoices: true,
        },
        shipments: {
            shipmentProcedureName: 'default',
        },
    }

    return def;
}

export const resolveDefaultsForAccount = (acc: WholesaleAccount): any => {
    const def: any = {
        account: {
            legalType: acc.legalType,
            language: acc.language,
            bank: acc.bank?.id,
            orderType: acc.orderType,
        },
        agent: {
            group: acc.agent?.group,
            country: acc.agent?.country.id,
            number: acc.agent?.number,
            name: acc.agent?.name,
            businessName: acc.agent?.businessName,
            visibleName: acc.agent?.visibleName,
            comment: acc.agent?.comment,
            customsCode: acc.agent?.customsCode,
            regCode: acc.agent?.regCode,
            icAccessGroup: acc.agent?.icAccessGroup?.id,
            vat: acc.agent?.vat
        },
        shipments: {
            shipmentCondition: acc.shipmentCondition,
            shipmentProcedureName: acc.shipmentProcedureName,
            customShipper: acc.customShipper?.id,
        },
        price: {
            price: acc.price?.id,
            discount: acc.discount,
            paymentTerms: acc.paymentTerms,
            applyMinimalPrice: acc.applyMinimalPrice
        },
        documents: {
            documentType: acc.documentType,
            invoiceDocumentType: acc.invoiceDocumentType,
            invoicingPeriod: acc.invoicingPeriod,
            emailType: acc.emailType,
            allowedMergedInvoices: acc.allowedMergedInvoices,
            sendProforma: acc.sendProforma,
            sendProformaEmails: acc.sendProformaEmails,
            sendInvoice: acc.sendInvoice,
            sendInvoiceEmails: acc.sendInvoiceEmails,
            sendInvoiceFormat: acc.sendInvoiceFormat,
            sendPackingList: acc.sendPackingList,
            sendPackingListEmails: acc.sendPackingListEmails
        },
        warehouses: acc.warehouses
            .slice()
            .sort((a, b) => a.sort! - b.sort!)
            .map((item) => ({
                warehouse: item.warehouse.id,
                maxAmount: item.maxAmount,
                sort: item.sort ? item.sort : 0,
                transactionType: item.transactionType
            })),
    }

    const bAddr = acc.billingAddress;
    const sAddr = acc.shippingAddresses.find(a => a.default);
    if (bAddr && sAddr) {
        def.billingAddress = {...bAddr, country: bAddr.country.id}
        def.shippingAddress = {...sAddr, country: sAddr.country.id}
    }

    return mergeWith(def, resolveDefaults());
}