import React from 'react';
import {Modal} from 'antd';

interface PreviewImageProps {
    title: string;
    url: string;
    onClose: () => void;
}

const isImage = (url: string): boolean => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

export const PreviewImage: React.FC<PreviewImageProps> = ({title, url, onClose}) => {
    return (
        <Modal
            visible={true}
            title={title}
            footer={null}
            onCancel={onClose}
        >
            {isImage(url) ? (
                <img alt="example" style={{ width: '100%' }} src={url} />
            ) : (
                <>Preview is not available</>
            )}
        </Modal>
    );
};