import { FilterDataHandler } from "../../../../../models/filter/filter.data.handler";
import { MergedInvoice } from "../../../../../models/documents/invoices/merged-invoice/merged.invoice";
import { mergedInvoiceService } from "../../../../../services/documents/invoices/merged.invoices.service";
import { PaginatedRequest } from "../../../../../models/core/paginated.request";

export class AgentDataMergedInvoicesStore {
    dataHandler: FilterDataHandler<MergedInvoice>;

    request: PaginatedRequest = new PaginatedRequest();

    constructor(private accountId: number) {
        this.dataHandler = new FilterDataHandler<MergedInvoice>((request) =>
            mergedInvoiceService.getAgentMergedInvoicesByAccount(
                request,
                String(this.accountId)
            )
        );
    }

    public async deleteMergedInvoice(invoice: MergedInvoice) {
        this.dataHandler.setLoading(true);
        await mergedInvoiceService.deleteMergedInvoice(invoice.id);
        this.dataHandler.removeItem(invoice);
        await this.dataHandler.reloadItems(this.request);
        this.dataHandler.setLoading(false);
    }
}
