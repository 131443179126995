import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Form, InputNumber, Row, Select, Space, Typography} from "antd";
import {AgentNewContext} from "../../agent.new.modal";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../../utils/store";
import {RootStoreContext} from "../../../../../../stores/root/root.store";
import {paymentTermsDays} from "../../../agent-data-general/agent-data-price/agent.data.price";
import {observer} from "mobx-react";

export const PriceStep: React.FC = observer(() => {

    const t = useTranslate();
    const { commonStore } = useRequiredStore(RootStoreContext);

    const [loading, setLoading] = useState<boolean>(false);

    const [form] = Form.useForm();

    const {setStep, getValue, setValue, fields} = React.useContext(AgentNewContext);

    useEffect(() => {
        form.setFields(fields);
    }, [fields])

    const defaultValues = {
        price: getValue('price')
    }

    return <Space direction="vertical" size={[15, 15]} style={{marginTop: 40}}>
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            name={"client_form"}
            initialValues={defaultValues}
            onFinish={async (values) => {
                await form.validateFields();
                setValue('price', values.price)
                setStep(3)
            }}
        >
            <Typography.Title level={3}>{t("CLIENT.PRICE.TITLE")}</Typography.Title>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={24}>
                    <Row gutter={[30, 0]} align={"top"}>
                        <Col span={6}>
                            <Form.Item
                                name={["price", "price"]}
                                label={t("PRICE_LIST")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    placeholder={t("PRICE_LIST")}
                                    showSearch
                                    disabled={loading}
                                    optionFilterProp={"children"}
                                >
                                    {commonStore.priceLists.map((item) => (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[30, 0]} align={"top"}>
                        <Col span={6}>
                            <Form.Item
                                name={["price", "discount"]}
                                label={t("DISCOUNT")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <InputNumber disabled={loading} type="text" placeholder={t("EXAMPLE") + ": -10"} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name={["price", "paymentTerms"]}
                                label={t("PAYMENT_TERMS")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    disabled={loading}
                                >
                                    {paymentTermsDays.map(value =>
                                        <Select.Option value={value}>{value} {t("DAYS")}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[30, 0]} align={"top"}>
                        <Col span={24}>
                            <Form.Item name={["price", "applyMinimalPrice"]} valuePropName="checked">
                                <Checkbox>{t("CLIENT.APPLY_MINIMAL_PRICE")}</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[30, 0]} align={"top"}>
                        <Col span={24}>
                            <Space direction={"horizontal"} size={[10, 10]}>
                                <Button type={"default"} onClick={() => setStep(1)}>Back</Button>
                                <Button type={"primary"} onClick={() => form.submit()}>Next</Button>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    </Space>
})