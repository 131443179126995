import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {Country} from "../../models/countries/country";

export class CountriesService {
    api: AxiosInstance = API;

    getAll(): Promise<Country[]> {
        return this.api.get<Country[]>('countries').then(x => x.data);
    }

}

export const countriesService = new CountriesService();