import React, {useState} from "react";
import {Alert, Button, Card, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {actionsService} from "../../../services/actions/actions.service";
import {JobStatus} from "../../../models/queue/job";
import {queueService} from "../../../services/queue/queue.service";
import {useTranslate} from "../../../hooks/translate.hook";

interface SettingsActionProps {
    alias: string
}
export const SettingsAction: React.FC<SettingsActionProps> = ({alias}) => {
    const t = useTranslate();

    const [status, setStatus] = useState<JobStatus | null>(null);
    const [error, setError] = useState<string>("");

    const doAction = async () => {
        setStatus("awaiting")
        const action = await actionsService.run(alias);
        checkStatus(action.id)
    }

    const checkStatus = (id: number) => {
        setTimeout(() => {
            queueService.getJobStatus(id).then(resp => {
                setStatus(resp.status);
                setError(resp.error)
                if (resp.status === "running" || resp.status === "awaiting") {
                    checkStatus(id)
                }
            })
        }, 2000)
    }

    return <Card
        size={"default"}
        title={<><FontAwesomeIcon icon={["fas", "running"]} title={"sdf"} color={"#777"} /> {t("ACTIONS." + alias.toUpperCase() + ".TITLE")}</>}
        headStyle={{backgroundColor: "#F4F4F4"}}
    >
        <Space direction={"vertical"}>
            <span>{t("ACTIONS." + alias.toUpperCase() + ".DESCRIPTION")}</span>
            {status === "completed" ? (
                <Alert
                    type={"success"}
                    message={"Completed successfully"}
                    closable={true}
                />
            ) : null}
            {status === "failed" ? (
                <Alert
                    type={"error"}
                    message={"Job completed with errors: " + error}
                    closable={true}
                />
            ) : null}
            <Button
                type={"primary"}
                onClick={() => doAction()}
                loading={status === "running" || status === "awaiting"}
            >
                {t("ACTIONS.RUN")}
            </Button>
        </Space>
    </Card>
}