import React, { useState } from "react";
import { Error } from "../../../../models/errors/error";
import { Button, message, notification, Space, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useTranslate} from "../../../../hooks/translate.hook";
import { ActionProps } from "../errors.list.actions";
import {
    AgentDataIntegration,
    IntegrationConnectionTestValues,
} from "../../../../models/agents/agent-data/agent-data-integrations/agent.data.integration";
import { agentDataService } from "../../../../services/agents/agent.data.service";
import { AgentDataIntegrationsModal } from "../../../agents/agent-data/agent-data-accounts/agent-data-integrations/agent.data.integrations.modal";

interface IntegrationsUploadActionProps extends ActionProps {}
export const IntegrationsUploadAction: React.FC<IntegrationsUploadActionProps> = ({ error }) => {
    const t = useTranslate();
    const [integration, setIntegration] = useState<AgentDataIntegration | undefined>(undefined);
    const [modalShown, setModalShown] = useState<boolean>(false);

    const onUploadBtnClick = async () => {
        message.info(t("UPLOAD.BEGINS"), 1.5);
        try {
            await agentDataService.uploadIntegrationData(error.relationId as any);
            notification.success({
                message: t("SUCCESS"),
                description: t("CLIENT.INTEGRATIONS.UPLOAD.SUCCESS"),
            });
        } catch (e: any) {}
    };

    const onEditButtonClick = () => {
        agentDataService.getIntegration(error.relationId).then((integration) => {
            setIntegration(integration);
            setModalShown(true);
        });
    };

    const onSubmit = async (values: AgentDataIntegration, id: number) => {
        const integrationWithSortedFields = {
            ...values,
            fields: values.fields.map((field, index: number) => ({
                ...field,
                sort: index,
            })),
        };
        const updatedIntegration = await agentDataService.updateAgentIntegration(integrationWithSortedFields, id);
        setIntegration(updatedIntegration);
        setModalShown(false);
    };

    const testIntegrationConnection = async (values: IntegrationConnectionTestValues, id?: number) => {
        return await agentDataService.testIntegrationConnection(values, id);
    };

    return (
        <Space direction="horizontal" size={[5, 5]}>
            <Tooltip placement="topRight" title={t("UPLOAD.TITLE")}>
                <Button
                    className={"card-btn btn"}
                    onClick={onUploadBtnClick}
                    type="default"
                    size={"small"}
                    icon={<FontAwesomeIcon icon={["fas", "upload"]} />}
                />
            </Tooltip>
            <Tooltip placement="topRight" title={t("EDIT")}>
                <Button
                    className={"card-btn btn"}
                    onClick={onEditButtonClick}
                    type="default"
                    size={"small"}
                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                />
            </Tooltip>
            {modalShown ? (
                <AgentDataIntegrationsModal
                    visible={modalShown}
                    item={integration}
                    warehouses={integration!.account!.warehouses}
                    accountId={integration!.account.id}
                    onSubmit={onSubmit}
                    onClose={() => setModalShown(false)}
                    testConnection={testIntegrationConnection}
                />
            ) : null}
        </Space>
    );
};
