import React from "react";
import {makeObservable, observable, action} from "mobx";
import {SystemGroup} from "../../../../models/users/group/group";
import {groupsService} from "../../../../services/groups/groups.service";
import {AccessGroup, AccessGroupRequest} from "../../../../models/users/access-group/access.group";
import {UserAccessCategory, UserAccessCategoryMap} from "../../../../models/users/access-category/user.access.category";
import {userAccessesService} from "../../../../services/users/user-accesses/user.accesses.service";
import {Access} from "../../../../models/users/access/access";
import {getCheckedAccesses, createAccessMap} from "../../../../models/users/access-group/access.group.helper";
import {PagesStore} from "../../../../stores/pages/pages.store";

export class UserGroupsStore {

    @observable
    public loading: boolean = false;

    @observable
    public currentGroup: AccessGroup | null = null;

    @observable
    public allCategories: UserAccessCategory[] = [];

    @observable
    public accessesData: UserAccessCategoryMap = {};


    constructor(private pageStore: PagesStore) {
        makeObservable(this);
        this.createGroup = this.createGroup.bind(this);
        this.editGroup = this.editGroup.bind(this);
    }

    @action
    private setLoading(loading: boolean): void {
        this.loading = loading;
    }

    @action
    private setAccessesData(accessCategories: UserAccessCategory[], currentGroupAccesses: Access[]): void {
        accessCategories.forEach(item => {
            if (item.accesses.length) {
                this.accessesData[item.id] = {
                    accesses: createAccessMap(item.accesses, currentGroupAccesses),
                    title: item.name
                }
            }
        });
    }

    @action
    public setAccessChecked(checked: boolean, categoryId: string, accessId: string): void {
        this.accessesData[categoryId].accesses[accessId].checked = checked;
    }



    @action
    private addGroups(group: AccessGroup, systemGroups: SystemGroup[]): void {
        const index = systemGroups?.findIndex(item => item.id === group.system.id);
        if (index !== -1) {
            systemGroups![index!].groups.push({id: group.id, name: group.name});
        } else {
            systemGroups?.push({
                id: group.system.id,
                name: group.system.name,
                groups: [{id: group.id, name: group.name}]
            })
        }
    }

    @action
    private setCurrentGroup(group: AccessGroup | null): void {
        this.currentGroup = group;
    }

    @action
    private setAllCategories(categories: UserAccessCategory[]): void {
        this.allCategories = categories;
    }


    public async getCurrentGroup(id: string, url: string) {
        this.setLoading(true);
        this.setAllCategories(await userAccessesService.getAccessesCategory());
        this.setCurrentGroup(await groupsService.getGroup(id));
        this.setAccessesData(this.allCategories, this.currentGroup!.accessess);
        this.pageStore.updateRouteName(url, this.currentGroup?.name);
        this.setLoading(false);
    }

    public resetCurrentGroup(): void {
        this.setCurrentGroup(null);
    }


    public async editGroup(values: AccessGroupRequest) {
        const updatedGroup = await groupsService.editGroup(values, this.currentGroup!.id);
        this.setCurrentGroup(updatedGroup);
    }



    public async saveChanges() {
        await this.editGroup({
            name: this.currentGroup!.name,
            system: this.currentGroup!.system.id,
            accesses: getCheckedAccesses(this.accessesData)
        });
    }


    public async createGroup(values: AccessGroupRequest, systemGroups: SystemGroup[]) {
        const newGroup = await groupsService.createGroup(values);
        this.addGroups(newGroup, systemGroups);
        return newGroup;
    }


}

export const UserGroupsStoreContext = React.createContext<UserGroupsStore | null>(null);