import React from "react";
import {observer} from "mobx-react";
import {Button, Space, Typography} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import {myPageService} from "../../../../services/my-page/my.page.service";
import {ColumnsType} from "antd/es/table";
import {FileType} from "../../../../models/my-page/files";
import Date from "../../../date/date";
import {FilterKey} from "../../../../models/filtered-table/filtered.table.props";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
interface MyPageProps extends ComponentPropsFromRoute {}
export const MyPageFiles: React.FC<MyPageProps> = observer(({name}) => {
    const {authStore: {token}} = useRequiredStore(RootStoreContext);
    const t = useTranslate();

    const columns: ColumnsType<File> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (id: string) => id ? <a href={"/api/v3/files/" + id + "?token=" + token}>{id}</a> : null
        },
        {
            title: t("NAME"),
            dataIndex: "filename",
            key: "filename"
        },
        {
            title: t("TYPE"),
            dataIndex: "type",
            key: "type",
            render: (type: FileType) => type ? type.name : null
        },
        {
            title: t("UPDATED_AT"),
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (date: string) => <Date dateString={date}/>
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date: string) => <Date dateString={date}/>
        },
    ];

    const filters: FilterKey[] = [
        {
            label: "ID",
            key: "id",
            type: "string"
        },
        {
            label: t("NAME"),
            key: "filename",
            type: "string",
        },
        {
            label: t("TYPE"),
            key: "types",
            type: "select",
            options: []
        },
        {
            key: "updatedAt",
            label: t("UPDATED_AT"),
            type: "date"
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date"
        },
    ]

    const columnsConfigs: {key: string, title: string}[] = [
        {
            title: "ID",
            key: "id",
        },
        {
            title: "NAME",
            key: "filename",
        },
        {
            title: "TYPE",
            key: "type",
        },
        {
            title: "UPDATED_AT",
            key : "updatedAt",
        },
        {
            title: "CREATED_AT",
            key: "createdAt",
        }
    ];

    return (
        <Space direction={"vertical"} size={[0, 0]}>
            <Typography.Title level={3}>{t("MY_PAGE.FILES.TITLE")}</Typography.Title>
            <FilteredTable
                rowKey={"id"}
                filterKeys={filters}
                loadDataCallback={request => myPageService.getFiles(request)}
                columns={columns}
                columnsConfigs={{columns: columnsConfigs, pageKey: name}}
            />
        </Space>
    );
});
