import React from "react";
import {observer} from "mobx-react";
import {Checkbox, Col, Form, Input, Select} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";

interface ExportBasicFieldsProps {
    formats: {value: string, label: string}[]
}
export const ExportBasicFields: React.FC<ExportBasicFieldsProps> = observer(({formats}) => {
    const t = useTranslate();

    return (
        <>
            <Col span={24}>
                <Form.Item
                    name="withHeaders"
                    valuePropName={"checked"}
                >
                    <Checkbox>{t("DOWNLOAD.WITH_HEADERS")}</Checkbox>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    name="format"
                    label={t("DOWNLOAD.FORMAT")}
                    rules={[{required: true, message: t('FROM.ERROR.PLEASE_SELECT_VALUE')}]}
                >
                    <Select placeholder={t("")}>
                        {formats.map(item => (
                            <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate={((prevValues, nextValues) => prevValues.format !== nextValues.format)}>
                {({getFieldValue}) => getFieldValue("format") === "csv" ? (
                    <>
                        <Col span={12}>
                            <Form.Item
                                name="separator"
                                label={t("DOWNLOAD.COLUMN_SEPARATOR")}
                                rules={[{required: true, message: t('DOWNLOAD.FORM.FIELD_INVALID_VALUE'), max: 1, min: 1}]}
                            >
                                <Input type={"text"} placeholder={t("DOWNLOAD.COLUMN_SEPARATOR")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="escapeChar"
                                label={t("DOWNLOAD.ESCAPE_CHAR")}
                                rules={[{required: true, message: t('DOWNLOAD.FORM.FIELD_INVALID_VALUE'), max: 1, min: 1}]}
                            >
                                <Input type={"text"} placeholder={t("DOWNLOAD.ESCAPE_CHAR")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="enclosure"
                                label={t("DOWNLOAD.ENCLOSURE")}
                                rules={[{required: true, message: t('DOWNLOAD.FORM.FIELD_INVALID_VALUE'), max: 1, min: 1}]}
                            >
                                <Input type={"text"} placeholder={t("DOWNLOAD.ENCLOSURE")}/>
                            </Form.Item>
                        </Col>
                    </>
                ) : null}
            </Form.Item>
        </>
    )
})
