import React, { useState } from "react";
import {
    Button,
    Card,
    Descriptions,
    Popconfirm,
    Space,
    Tooltip,
    Typography,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { AgentDataContactsModal } from "./agent.data.contacts.modal";
import { useRequiredStore } from "../../../../../utils/store";
import { AgentDataStoreContext } from "../../agent.data.store";
import { CustomCard } from "../../../../common/custom-card/custom.card";
import { CustomerAccountContact } from "../../../../../models/accounts/customer-account/contact/customer.account.contact";

interface AgentDataContactsCardProps {
    contact?: CustomerAccountContact;
    changeable?: boolean;
}

export const AgentDataContactsCard: React.FC<AgentDataContactsCardProps> = ({
    contact, changeable = true
}) => {
    const t = useTranslate();
    const store = useRequiredStore(AgentDataStoreContext);

    const [isModalShown, setIsModalShown] = useState<boolean>(false);

    const cardRows = [
        { key: "position" },
        { key: "email" },
        { key: "workPhone" },
        { key: "mobilePhone" },
        { key: "characteristic" },
    ];

    if (contact) {
        return (
            <>
                <Card
                    className={"card"}
                    title={
                        <Typography.Title level={5}>
                            {contact.firstName + " " + contact.lastName}
                        </Typography.Title>
                    }
                    extra={
                        changeable ? (
                            <Space
                                style={{ width: "auto" }}
                                direction="horizontal"
                                align={"center"}
                                size={[5, 5]}
                            >
                                <Tooltip placement="topRight" title={t("EDIT")}>
                                    <Button
                                        className={"card-btn btn"}
                                        onClick={() => setIsModalShown(true)}
                                        type="default"
                                        size={"small"}
                                        icon={
                                            <FontAwesomeIcon
                                                icon={["fas", "pencil-alt"]}
                                            />
                                        }
                                    />
                                </Tooltip>
                                <Popconfirm
                                    title={t("ARE_YOU_SURE")}
                                    onConfirm={() => store.removeContact(contact)}
                                    placement="top"
                                    okText={t("YES")}
                                    cancelText={t("NO")}
                                >
                                    <Tooltip
                                        placement="topRight"
                                        title={t("REMOVE")}
                                    >
                                        <Button
                                            size={"small"}
                                            className={"card-btn btn-remove btn"}
                                            type="default"
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={["fas", "times"]}
                                                />
                                            }
                                        />
                                    </Tooltip>
                                </Popconfirm>
                            </Space>
                        ) : null
                    }
                >
                    <CustomCard data={contact} rowKeys={cardRows} />
                </Card>
                <AgentDataContactsModal
                    visible={isModalShown}
                    item={contact}
                    onSubmit={store.updateContact}
                    onClose={() => setIsModalShown(false)}
                />
            </>
        );
    }
    return (
        <>
            <Card
                className="card"
                title={t("CONTACTS.ADD")}
                extra={
                    <Tooltip key={2} placement="topLeft" title={t("ADD")}>
                        <Button
                            className="btn"
                            type="default"
                            icon={<FontAwesomeIcon icon={["fas", "plus"]} />}
                        />
                    </Tooltip>
                }
            >
                {t("CONTACTS.NO_DATA")}
            </Card>
        </>
    );
};
