import React, {useMemo, useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Col, Form, Input, Modal, Row, Select} from "antd";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../../models/core/response";
import {Access, AccessRequest} from "../../../../../models/users/access/access";
import {UserAccessCategory} from "../../../../../models/users/access-category/user.access.category";

interface UserAccessesModalProps {
    visible: boolean,
    onClose: () => void,
    onSubmit: (values: AccessRequest, access?: Access) => Promise<any>,
    item?: Access,
    accesses: UserAccessCategory[] | null
}

export const UserAccessesModal: React.FC<UserAccessesModalProps> = ({item, visible, onClose, onSubmit, accesses}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const defaultCategory = useMemo(() => item ? accesses?.find(access => access.accesses.includes(item)) : undefined, []);

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={item ? t("USERS.ACCESSES.EDIT") : t("USERS.ACCESSES.ADD")}
            okText={item ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"contacts_form"}
                initialValues={{...item, category: defaultCategory ? defaultCategory.id : null}}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        onSubmit(values, item ? item : undefined)
                            .then(
                                () => {
                                    !item && form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                            .then(() => {}, (e) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10} >
                    <Col span={12}>
                        <Form.Item
                            name="category"
                            label={t("GROUP.TITLE")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Select disabled={loading}  placeholder={t("GROUP.TITLE")}>
                                {accesses ? accesses.map(item => (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )) : null}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label={t("NAME")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("NAME")}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="description"
                            label={t("DESCRIPTION")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("DESCRIPTION")}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
