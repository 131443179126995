import { AxiosInstance } from "axios";
import API from "../../../utils/api";
import { PaginatedRequest } from "../../../models/core/paginated.request";
import { PaginatedResult } from "../../../models/core/paginated.result";
import { ArticlesHistory } from "../../../models/articles/articles.history";

export class ArticlesHistoryService {
    api: AxiosInstance = API;

    getAll(
        request: PaginatedRequest
    ): Promise<PaginatedResult<ArticlesHistory>> {
        return this.api
            .get<PaginatedResult<ArticlesHistory>>("articles/history", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }
}

export const articlesHistoryService = new ArticlesHistoryService();
