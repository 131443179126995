import { observer } from "mobx-react-lite";
import React from "react";
import {Space} from "antd";
import {OrderDataHeader} from "./order-data-header/order.data.header";
import {OrderDataComments} from "../order-data-comments/order-data-comments";
import {PayerSellerReceiverData} from "../../../common/payer-seller-receiver-data/payer.seller.receiver.data";
import {isAllowed} from "../../../../utils/helpers";
import {OrderDataShipments} from "../order-data-shipments/order.data.shipments";
import {OrderDataEditItemsTable} from "../order-data-edit-items-table/order.data.edit.items.table";
import {OrderDataDetails} from "./order-data-details/order.data.details";
import {OrderDataHistory} from "../order-data-history/order.data.history";
import {OrderDataLogger} from "../order-data-logger/order.data.logger";
import {useRequiredStore} from "../../../../utils/store";
import {OrderDataStoreContext} from "../order.data.store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {OrderDataDocuments} from "../order-data-documents/order-data-documents";

interface OrderDataRetailProps {
}

export const OrderDataWholesale: React.FC<OrderDataRetailProps> = observer(() => {

    const store = useRequiredStore(OrderDataStoreContext);
    const {
        authStore: { attributes }
    } = useRequiredStore(RootStoreContext);

    return (
        <Space direction="vertical" size={[50, 50]}>
            <OrderDataHeader />
            <OrderDataComments />
            <PayerSellerReceiverData
                payer={store.order!.payer}
                receiver={store.order!.receiver}
                seller={store.order!.seller}
                editPayerReceiver={store.editPayerReceiverData}
                editSeller={store.editSellerData}
                withAddressType={true}
            />
            {isAllowed(attributes, "shipment_management") ? <OrderDataShipments /> : null}
            <OrderDataDocuments order={store.order!} />
            {store.editingOrderItems ? <OrderDataEditItemsTable /> : <OrderDataDetails />}
            {isAllowed(attributes, "logger.list") ? <OrderDataHistory /> : null}
            <OrderDataLogger />
        </Space>
    );
});
