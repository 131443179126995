interface IdAndName {
    id: string,
    name: string
}

export interface Declaration {
    id: string,
    number: string,
    providers: string[],
    transportAgreement: string,
    originCountry: {
        id: string,
        name: string
    },
    providersCountry: IdAndName,
    price: number,
    invoiceCurrency: string,
    createdAt: string
    status: DeclarationStatus;
}

export enum DeclarationStatuses {
    AWAITING = "awaiting",
    VERIFIED = "verified",
    CANCELLED = "cancelled",
}

export type DeclarationStatus = DeclarationStatuses;

export type StatusColor = Record<DeclarationStatuses, string>;

export const statusColor: StatusColor = {
    awaiting: "gold",
    verified: "green",
    cancelled: "red",
}