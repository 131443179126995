import React from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Col, Modal, Row} from "antd";

interface OrdersListDeliveryOrderModalProps {
    visible: boolean;
    checkedOrders: number[];
    onOk: () => void;
    onClose: () => void;
}
export const OrdersListDeliveryOrderModal: React.FC<OrdersListDeliveryOrderModalProps> = ({visible, checkedOrders,  onClose, onOk}) => {
    const t = useTranslate();

    return (
        <Modal
            visible={visible}
            title={t("ORDERS.DELIVERY.CREATE")}
            okText={t("CREATE")}
            cancelText={t("CANCEL")}
            onOk={onOk}
            onCancel={() => onClose()}
        >
            <Row>
                <Col span={24}>
                <span style={{fontWeight: "bold"}}>
                    {t("ORDERS.PROFORMA.INCLUDED_ORDERS")}
                </span>: {checkedOrders.join(", ")}
                </Col>
            </Row>
        </Modal>

    )
}
