import React from "react";
import {WidgetProps} from "../widget";
import {Button, DatePicker, Space} from "antd";
import {createPreviousMonthRange, createThisMonthRange, createTodayRange} from "../../../../../../utils/date";

export const UiInputWidgetDateRange: React.FC<WidgetProps> = ({value, onChange}) => {

    return (
        <Space direction={"horizontal"}>
            <DatePicker.RangePicker
                allowClear={false}
                style={{ width: 280 }}
                onChange={onChange as any}
                value={value as any}
                placement={"bottomLeft"}
            />
            <Button type={"primary"} onClick={() => onChange!(createThisMonthRange())}>This month</Button>
            <Button type={"primary"} onClick={() => onChange!(createPreviousMonthRange())}>Previous month</Button>
            <Button type={"primary"} onClick={() => onChange!(createTodayRange())}>Today</Button>
        </Space>
    )
}