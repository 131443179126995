import React from "react";
import {WidgetProps} from "../widget";
import {DatePicker} from "antd";
import {useTranslate} from "../../../../../../hooks/translate.hook";

export const UIInputWidgetMonth: React.FC<WidgetProps> = ({value, onChange, withDefault, defaultOptions}) => {

    const dateFormat: string = "MM-YYYY";

    const t = useTranslate();

    return (
        <DatePicker
            picker="month"
            style={{width: 280}}
            placeholder={t("DATE")}
            allowClear={false}
            format={dateFormat}
            onChange={onChange as any}
            value={value as any}
        />
    )
}