import React from "react";
import {StatusTag} from "../../../../common/status/status";
import {
    PropertyInventoryTaskPropertyReviewStatus, reviewStatusColor,
} from "../../../../../models/property/property";

interface InventoryTaskReviewStatusProps {
    status: PropertyInventoryTaskPropertyReviewStatus;
    large?: boolean;
}
export const InventoryTaskReviewStatus: React.FC<InventoryTaskReviewStatusProps> = ({status, large = false}) => {
    return (
        <StatusTag<PropertyInventoryTaskPropertyReviewStatus> status={status} colorsMap={reviewStatusColor} large={large} prefix="PROPERTY.INVENTORY_PROPERTY_REVIEW.STATUS"/>
    )
}
