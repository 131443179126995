import React from "react";
import {action, makeObservable, observable} from "mobx";
import {BranchBank, BranchBankRequestData, BranchRequestValues} from "../../../../models/branches/branch-data";
import {branchesService} from "../../../../services/branches/branches.service";

export class BranchDataBanksStore {
    @observable
    public branchBanks: BranchBank[] = [];

    @observable
    public loading: boolean = false;

    constructor(private id: string) {
        makeObservable(this);
        this.init();
    }

    @action
    private setLoading(value: boolean): void {
        this.loading = value;
    }

    @action
    private setBranchBanks(value: BranchBank[]): void {
        this.branchBanks = value;
    }

    @action
    private removeBranchBank(bank: BranchBank): void {
        const index = this.branchBanks.indexOf(bank);
        if (index !== -1) {
            this.branchBanks.splice(index, 1);
        }
    }

    @action
    private addBranchBank(value: BranchBank): void {
        this.branchBanks.push(value);
    }

    @action
    private updateBranchBank(item: BranchBank): void {
        const index = this.branchBanks.findIndex((val) => val.id === item.id);
        if (index !== -1) {
            this.branchBanks[index] = item;
        }
    }

    public async removeBank(bank: BranchBank) {
        await branchesService.removeBranchBank(bank.id);
        this.removeBranchBank(bank);
    }

    public editBranchBank = async (
        values: BranchBankRequestData,
        item: BranchBank
    ) => {
        const updatedBranchBank = await branchesService.updateBranchesBank(
            item.id,
            values
        );
        this.updateBranchBank(updatedBranchBank);
    };

    public createBranchBank = async (values: BranchBankRequestData) => {
        const newBranchBank = await branchesService.createBranchesBank(
            this.id,
            values
        );
        this.addBranchBank(newBranchBank);
    };

    private async init() {
        this.setLoading(true);
        this.setBranchBanks(await branchesService.getBranchesBanks(this.id));
        this.setLoading(false);
    }
}

export const BranchDataBanksStoreContext = React.createContext<BranchDataBanksStore | null>(null);
