import React, {useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Col, DatePicker, Form, Modal, Row} from "antd";
import {AxiosError} from "axios";
import {Container} from "../../../../models/containers/containers";
import {ErrorResponseData} from "../../../../models/core/response";
import {containersService} from "../../../../services/containers/containers.service";
import dayjs from "dayjs";

interface SetArrivedModalProps {
    container: Container;
    onClose: () => void
    onCompleted: () => void
}
export const SetArrivedModal: React.FC<SetArrivedModalProps> = ({container, onClose, onCompleted}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("CONTAINERS.CONTAINER.SET-ARRIVED.TITLE")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"order_data"}
                initialValues={{
                    date: dayjs()
                }}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        containersService.setArrived(container.id, values.date)
                            .then(
                                () => {
                                    form.resetFields();
                                    onClose();
                                    onCompleted();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                            .then(() => {}, (e) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="date"
                            label={t("DATE")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <DatePicker style={{width: "100%"}} format={"DD-MM-YYYY"}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )

}