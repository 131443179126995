import React, {useEffect, useState} from "react";
import {WidgetProps} from "../widget";
import {Select} from "antd";
import {purchasingAlliancesService} from "../../../../../../services/settings/purchasing-alliances.service";
import {PurchasingAlliance} from "../../../../../../models/settings/purchasing-alliances";

interface Props extends WidgetProps {
    type: string;
}

export const UiInputWidgetPurchasingAlliance: React.FC<Props> = ({value, onChange, multiple, type}) => {

    const [alliances, setAlliances] = useState<PurchasingAlliance[]>([]);

    useEffect(() => {
        purchasingAlliancesService.getAll(type).then(alliance => {
            setAlliances(alliance);
        });
    }, [type]);

    return (
        <Select
            mode={multiple ? "multiple" : undefined}
            onChange={onChange as any}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
        >
            <Select.Option key={null} value={null}>(none)</Select.Option>
            {alliances.map(alliance =>
                <Select.Option key={alliance.id} value={alliance.id}>{alliance.name}</Select.Option>
            )}
        </Select>
    )
}