import {SmartColumn} from "../../../../common/smart-table/smart-table.model";
import {PropertyLocation} from "../../../../../models/property/property";
import {FieldType} from "../../../../common/smart-table/smart-table.field-config";
import {Translate} from "../../../../../hooks/translate.hook";

export const columnFactory = (t: Translate): SmartColumn<PropertyLocation>[] => {
    return [
        {
            name: 'code',
            visibleName: t('PROPERTY.LOCATION.CODE'),
            type: FieldType.STRING
        },
        {
            name: 'name',
            visibleName: t('PROPERTY.LOCATION.NAME'),
            type: FieldType.STRING
        }
    ]
}