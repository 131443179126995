import React, {useEffect} from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {RouteButtons} from "../../common/route-tabs/route.buttons";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";

export const PlannerSettings: React.FC<ComponentPropsFromRoute> = ({routeChildren}) => {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === '/planner/settings') {
            navigate('/planner/settings/tags');
        }
    }, [location.pathname, navigate])

    return (
        <RouteButtons routes={routeChildren!} />
    )
}
