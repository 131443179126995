import {PlannerTasksViewAcceptAction} from "./accept/task.view.accept.action";
import {PlannerTasksViewCancelAction} from "./cancel/task.view.cancel.action";
import {PlannerTasksViewDoneAction} from "./done/task.view.done.action";
import {PlannerTasksViewToProgressAction} from "./to-progress/task.view.to-progress.action";
import {PlannerTasksViewToReviewAction} from "./to-review/task.view.to-review.action";
import {Action} from "../model";

export const actions: Action[] = [
    PlannerTasksViewAcceptAction,
    PlannerTasksViewCancelAction,
    PlannerTasksViewDoneAction,
    PlannerTasksViewToProgressAction,
    PlannerTasksViewToReviewAction
]