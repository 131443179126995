import React, {useState} from "react";
import {
    Button,
    Col, DatePicker,
    Form, Input,
    Modal,
    Row, Space
} from "antd";
import {useForm} from "antd/es/form/Form";
import {useTranslate} from "../../../../hooks/translate.hook";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {
    UIInputWidgetPropertyResponsibleUser
} from "../../../property/ui/input/widget/property-responsible-user/ui.input.widget.property-responsible-user";
import TextArea from "antd/es/input/TextArea";
import {eventsService} from "../../../../services/calendar/events.service";
import {createPayloadFromFormData} from "./new-event.functions";

interface NewEventModalProps {
    onCancel: () => void;
    onComplete: () => void;
}

export const NewEventModal: React.FC<NewEventModalProps> = ({onCancel, onComplete}) => {
    const t = useTranslate();

    const [form] = useForm();
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Modal
            title={t('New Event')}
            width={900}
            visible={true}
            onCancel={onCancel}
            onOk={() => {
                form.submit();
            }}
            destroyOnClose
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={async (values) => {
                    await form.validateFields();
                    try {
                        setLoading(true)
                        await eventsService.createEvent(createPayloadFromFormData(values))
                        onCancel()
                        onComplete()
                    } catch (e: any) {
                        form.setFields(e.response?.data?.fields);
                    } finally {
                        setLoading(false)
                    }
                }}
                autoComplete="off"
                initialValues={{}}
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            label={t('CALENDAR.SLOT.EVENT.NAME')}
                            name={['name']}
                            rules={[{ required: true }]}
                        >
                            <Input disabled={loading} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={t('CALENDAR.SLOT.EVENT.DESCRIPTION')}
                            name={['description']}
                            rules={[{ required: true }]}
                        >
                            <TextArea disabled={loading} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={t('CALENDAR.SLOT.EVENT.PLACE_AND_ADDRESS')}
                            name={['placeAndAddress']}
                            rules={[{ required: true }]}
                        >
                            <TextArea disabled={loading} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t('CALENDAR.SLOT.EVENT.STARTING_AT')}
                            name={['startingAt']}
                            rules={[{ required: true }]}
                        >
                            <DatePicker disabled={loading} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t('CALENDAR.SLOT.EVENT.ENDING_AT')}
                            name={['endingAt']}
                            rules={[{ required: true }]}
                        >
                            <DatePicker disabled={loading} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <h4 style={{marginBottom: 10}}>Generate calendar slots</h4>
                        <Form.List name="generations">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Form.Item
                                                name={[name, 'employee']}
                                                rules={[{ required: true }]}
                                                style={{width: 150}}
                                            >
                                                <UIInputWidgetPropertyResponsibleUser />
                                            </Form.Item>
                                            <Form.Item
                                                name={[name, 'range']}
                                                rules={[{ required: true }]}
                                            >
                                                <DatePicker.RangePicker disabled={loading} />
                                            </Form.Item>
                                            <Form.Item
                                                name={[name, 'timeFrom']}
                                                rules={[{ required: true }]}
                                            >
                                                <DatePicker.TimePicker disabled={loading} showSecond={false}  placeholder={'Start'} />
                                            </Form.Item>
                                            <Form.Item
                                                name={[name, 'timeUntil']}
                                                rules={[{ required: true }]}
                                            >
                                                <DatePicker.TimePicker disabled={loading} showSecond={false}  placeholder={'End'} />
                                            </Form.Item>
                                            <Form.Item
                                                name={[name, 'lunchTimeFrom']}
                                            >
                                                <DatePicker.TimePicker disabled={loading} showSecond={false} placeholder={'Launch start'}  />
                                            </Form.Item>
                                            <Form.Item
                                                name={[name, 'lunchTimeUntil']}
                                            >
                                                <DatePicker.TimePicker disabled={loading} showSecond={false} placeholder={'Launch end'} />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}