import { fieldTypes } from "./filter.field.type.config";
import { FieldRuleName } from "./filter.field.rule";
import { FieldStateGroup as FieldGroupState } from "./filter.field.state";
import { FieldGroupConfig } from "./filter.group";
import { FieldTypeName } from "./filter.field.type.name";

export interface FieldGroupSnapshot {
    name: string;
    snapshots: FieldSnapshot[];
}

export interface FieldSnapshot {
    rule?: FieldRuleName;
    value: any;
    name: string;
}

export const createFieldGroupSnapshot = (groupState: FieldGroupState): FieldGroupSnapshot => {
    return {
        name: groupState.name,
        snapshots: groupState.states.map((state) => ({
            rule: state.rule,
            value:
                state.config.multiple && state.config.type === FieldTypeName.SELECT
                    ? state.value.join(",")
                    : state.value,
            name: state.config.name,
        })),
    };
};

export const restoreFieldGroupSnapshot = (snapshot: FieldGroupSnapshot, config: FieldGroupConfig): FieldGroupState => {
    return {
        name: snapshot.name,
        visibleName: config.visibleName,
        states: snapshot.snapshots.map((item) => {
            const fieldConfig = config.fields.find((configField) => configField.name === item.name)!;
            return {
                config: fieldConfig,
                rule: item.rule === undefined ? fieldTypes[fieldConfig.type].supportedRules[0] : item.rule,
                value: fieldTypes[fieldConfig.type].fromUrl(item.value, fieldConfig),
                choices: fieldConfig.choices,
            };
        }),
    };
};

export const restoreFieldGroupSnapshots = (
    snapshot: FieldGroupSnapshot[],
    configs: FieldGroupConfig[]
): FieldGroupState[] => {
    return snapshot.map((snapshot) =>
        restoreFieldGroupSnapshot(snapshot, configs.find((item) => snapshot.name === item.name)!)
    );
};
