import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";
import {Agreement, AgreementsRequest, AgreementsResponse} from "../../models/agreements/agreement";

export class AgreementsService {
    private api: AxiosInstance = API;

    getAgreements(request: PaginatedRequest): Promise<PaginatedResult<Agreement>> {
        return this.api.get<PaginatedResult<Agreement>>("marketing/agreements", {
            params: request.toParams()
        }).then(x => x.data);
    }

    getAgreement(id: number): Promise<AgreementsResponse> {
        return this.api.get<AgreementsResponse>("marketing/agreements/" + id).then(x => x.data);
    }

    createAgreement(data: AgreementsRequest): Promise<Agreement> {
        return this.api.post<Agreement>("marketing/agreements", data).then(x => x.data);
    }

    updateAgreement(id: number, data: AgreementsRequest): Promise<Agreement> {
        return this.api.post<Agreement>("marketing/agreements/" + id, data).then(x => x.data);
    }

    removeAgreement(id: number) {
        return this.api.delete("marketing/agreements/" + id);
    }
}

export const agreementsService = new AgreementsService();