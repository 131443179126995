import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Button, Col, Form, Input, Row, Select, Space, Spin, Tabs, Tag, Tooltip, Typography} from "antd";
import { AxiosError } from "axios";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { Link, useParams, useLocation } from "react-router-dom";
import { ErrorResponseData } from "../../../../../models/core/response";
import { renderSortButtons } from "../../../../../models/users/user/user.data.helper";
import { RootStoreContext } from "../../../../../stores/root/root.store";
import { useRequiredStore } from "../../../../../utils/store";
import { BranchDataDepartmentChildrenModal } from "./branch.data.department.children.modal";
import { BranchDataDepartmentStore, BranchDataDepartmentStoreContext } from "./branch.data.department.store";
import "./branch.data.department.scss";
import {DepartmentType} from "../../../../../models/departments/department";

const roleTypes = ["superior", "subordinate"]

export const BranchDataDepartment: React.FC = observer(() => {
    const params = useParams<{ branchId: string; departmentId: string }>();
    const departmentIdRef = useRef<string>(params.departmentId!);
    const t = useTranslate();
    const { pageStore } = useRequiredStore(RootStoreContext);
    const match = useLocation();
    const [store] = useState(
        () => new BranchDataDepartmentStore(params.branchId!, params.departmentId!, pageStore, match.pathname)
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [childrenModalVisible, setChildrenModalVisible] = useState<boolean>(false);

    const [form] = Form.useForm();

    useEffect(() => {
        if (departmentIdRef.current !== params.departmentId) {
            store.init(params.departmentId!, form);
            departmentIdRef.current = params.departmentId!;
        }
    }, [params, store, form]);

    if (store.loading) {
        return <Spin />;
    }

    const hideChildrenModal = () => {
        setChildrenModalVisible(false);
    };

    const defaultValues = {
        type: store.department?.type,
        name: store.department?.name,
        parent: store.department?.parent,
        children: store.department?.children.map((item) => ({ id: item.id, name: item.name })),
        roles: store.department?.roles
            ? [...store.department?.roles].slice().sort((a, b) => a.position! - b.position!)
            : [{ id: null, position: 0, name: "" }],
    };

    return (
        <BranchDataDepartmentStoreContext.Provider value={store}>
            <Typography.Title level={4}>
                {t('DEPARTMENT.TITLE')}: {store.department?.name}
            </Typography.Title>

            <Form.Provider
                onFormFinish={(name, { values, forms }) => {
                    if (name === "departmentChildren") {
                        const { departmentMain } = forms;
                        const children = departmentMain.getFieldValue("children") || [];
                        const foundItem = store.departments.find((item) => item.id === values.id);
                        departmentMain.setFieldsValue({
                            children: [...children, { id: foundItem?.id, name: foundItem?.name }],
                        });
                        setChildrenModalVisible(false);
                    }
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="departmentMain"
                    initialValues={defaultValues}
                    onFinish={() =>
                        form.validateFields().then((values) => {
                            setLoading(true);
                            store
                                .updateDepartment(params.departmentId, values)
                                .then(
                                    () => {
                                        return;
                                    },
                                    (e: AxiosError<ErrorResponseData>) => {
                                        setLoading(false);
                                        if (e.response?.data.fields) {
                                            form.setFields(e.response?.data?.fields);
                                        }
                                    }
                                )
                                .then(() => (e: any) => form.setFields(e.response?.data?.fields))
                                .finally(() => setLoading(false));
                        })
                    }
                >
                    <Space direction="vertical" size={[20, 20]}>
                        <Row gutter={[20, 20]}>
                            <Col xxl={6} xl={6} md={12} sm={12} xs={24}>
                                <Form.Item
                                    name="name"
                                    label={t("NAME")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                        },
                                    ]}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("NAME")} />
                                </Form.Item>
                            </Col>
                            <Col xxl={6} xl={6} md={12} sm={12} xs={24}>
                                <Form.Item
                                    name="type"
                                    label={t("TYPE")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                        },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        placeholder={t("TYPE")}
                                    >
                                        {Object.values(DepartmentType).map(type => {
                                            return <Select.Option key={type} value={type}>{type}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xxl={6} xl={6} md={12} sm={12} xs={24}>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                        prevValues.children !== currentValues.children
                                    }
                                >
                                    {({ getFieldValue }) => {
                                        const children = getFieldValue("children") || [];
                                        return (
                                            <Form.Item name="parent" label={t("DEPARTMENT.PARENT")}>
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    loading={loading}
                                                    optionFilterProp={"children"}
                                                    placeholder={t("DEPARTMENT.PARENT")}
                                                >
                                                    {store.departments?.map((department) => {
                                                        const disabled = children
                                                            .map((item: any) => item.id)
                                                            .includes(department.id);
                                                        return (
                                                            <Select.Option
                                                                disabled={disabled}
                                                                key={department.id}
                                                                value={department.id}
                                                            >
                                                                {department.name}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                    {/* {store.departments
                                                .filter(
                                                    (department) =>
                                                        !children
                                                            .map(
                                                                (item: { name: string; id: number }) => item.id
                                                            )
                                                            .includes(department.id) &&
                                                        department.id !== store.department?.id
                                                )
                                                .map((department) => (
                                                    <Select.Option key={department.id} value={department.id}>
                                                        {department.name.replace(/—/g, "")}
                                                    </Select.Option>
                                                ))} */}
                                                </Select>
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>
                            </Col>
                        </Row>
                        {/*<Row gutter={[10, 10]} style={{marginBottom: 20}}>*/}
                        {/*    */}
                        {/*</Row>*/}
                        <Row gutter={[20, 20]}>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Row gutter={[0, 0]}>
                                    <Typography.Title level={3} style={{ width: "100%", marginBottom: 20 }}>
                                        {t("ROLES.TITLE")}
                                    </Typography.Title>

                                    <Form.List name="roles">
                                        {(fields, { add, remove }, { errors }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <Row
                                                        style={{ width: "100%", marginRight: 0, marginLeft: 0 }}
                                                        justify={"space-between"}
                                                        gutter={5}
                                                        key={field.key}
                                                    >
                                                        {renderSortButtons(
                                                            form,
                                                            index,
                                                            fields.length,
                                                            loading,
                                                            "roles",
                                                            "roles"
                                                        )}
                                                        <Col span={12}>
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, "id"]}

                                                                style={{ display: "none" }}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, "name"]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t("FROM.ERROR.PLEASE_INPUT_VALUE"),
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    autoFocus={true}
                                                                    type="text"
                                                                    placeholder={t("DEPARTMENTS.ROLE.TITLE")}
                                                                    disabled={loading}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, "type"]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t("FROM.ERROR.PLEASE_INPUT_VALUE"),
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    placeholder={t("DEPARTMENTS.ROLE.TYPE.TITLE")}
                                                                    disabled={loading}
                                                                >
                                                                    {roleTypes.map(type => (
                                                                        <Select.Option
                                                                            key={type}
                                                                            value={type}
                                                                        >
                                                                            {t("DEPARTMENTS.ROLE.TYPE." + type.toUpperCase())}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col style={{ paddingRight: 0 }} span={2}>
                                                            <Tooltip placement="topRight" title={t("REMOVE")}>
                                                                <Button
                                                                    type="default"
                                                                    danger
                                                                    onClick={() => remove(field.name)}
                                                                    icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                                                                    style={{ width: "100%" }}
                                                                    className="btn-flex-center"
                                                                    disabled={loading}
                                                                />
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Form.Item noStyle>
                                                    <Form.ErrorList errors={errors} />
                                                </Form.Item>
                                                <Button
                                                    type="primary"
                                                    disabled={loading}
                                                    onClick={() => add()}
                                                    style={{ marginBottom: 10 }}
                                                    icon={<FontAwesomeIcon icon={["fas", "plus-circle"]}/>}
                                                >
                                                    {t("ADD")}
                                                </Button>
                                            </>
                                        )}
                                    </Form.List>
                                </Row>
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Typography.Title level={3} style={{ width: "100%", marginBottom: 20 }}>
                                    {t("CHILDREN.TITLE")}
                                </Typography.Title>
                                <Form.List name="children">
                                    {(fields, { add, remove }, { errors }) => (
                                        <>
                                            <ul className="children-list">
                                                {fields.map((field, index) => (
                                                    <Form.Item
                                                        noStyle
                                                        key={field.key}
                                                        shouldUpdate={(prevValues, curValues) =>
                                                            prevValues.children !== curValues.children
                                                        }
                                                    >
                                                        {({ getFieldValue }) => {
                                                            const child: { id: number; name: string } = getFieldValue([
                                                                "children",
                                                                field.name,
                                                            ]);
                                                            return (
                                                                <>
                                                                    <li key={child?.id}>
                                                                        <Link
                                                                            className="child-link"
                                                                            to={
                                                                                "/settings/branches/" +
                                                                                params.branchId +
                                                                                "/departments/" +
                                                                                child?.id
                                                                            }
                                                                            //
                                                                        >
                                                                            {child?.name}
                                                                        </Link>
                                                                        <Tooltip
                                                                            placement="topRight"
                                                                            title={t("REMOVE")}
                                                                        >
                                                                            <Button
                                                                                type="default"
                                                                                danger
                                                                                onClick={() => remove(field.name)}
                                                                                icon={
                                                                                    <FontAwesomeIcon
                                                                                        icon={["fas", "times"]}
                                                                                    />
                                                                                }
                                                                                // style={{ width: "100%" }}
                                                                                className="btn-flex-center"
                                                                                disabled={loading}
                                                                            />
                                                                        </Tooltip>
                                                                    </li>
                                                                </>
                                                            );
                                                        }}
                                                    </Form.Item>
                                                ))}
                                            </ul>
                                            <Form.Item noStyle>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>
                                            <Button
                                                type="primary"
                                                disabled={loading}
                                                onClick={() => setChildrenModalVisible(true)}
                                                style={{ marginBottom: 10 }}
                                                icon={<FontAwesomeIcon icon={["fas", "plus-circle"]}/>}
                                            >
                                                {t("ADD")}
                                            </Button>
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                            <Col span={24}>
                                <Button type="primary" block htmlType="submit">
                                    {t("SAVE")}
                                </Button>
                            </Col>
                        </Row>
                    </Space>
                </Form>
            </Form.Provider>
            <BranchDataDepartmentChildrenModal
                rootDepartmentId={store.department!.id}
                departments={store.departments}
                visible={childrenModalVisible}
                onCancel={hideChildrenModal}
                selectedChildren={form.getFieldsValue()?.children}
                parent={form.getFieldValue("parent")}
            />
        </BranchDataDepartmentStoreContext.Provider>
    );
});
