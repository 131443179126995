import {action, makeObservable, observable} from "mobx";
import {PaginatedRequest} from "../core/paginated.request";
import {PaginatedResult, Pagination} from "../core/paginated.result";

export class FilterDataHandler<T>
{
    @observable
    public loading: boolean = false;

    @observable
    public items: any[] = [];

    @observable
    public pagination?: Pagination;

    public lastRequest: PaginatedRequest | null = null;

    public dataLoader: (request: PaginatedRequest) => Promise<PaginatedResult<T>>;

    constructor(
        dataLoader: (request: PaginatedRequest) => Promise<PaginatedResult<T>>,
        public convertDataCallback?: (data: PaginatedResult<T>) => any
    ) {
        makeObservable(this);
        this.dataLoader = (request) => {
            this.lastRequest = request;
            return dataLoader(request);
        }
    }

    @action
    addItem(item: T): void {
        this.items.push(item);
        this.pagination!.limit += 1;
    }

    @action
    removeItem(item: T): void {
        const index = this.items.indexOf(item);
        if (index !== -1) {
            this.items.splice(index, 1);
            this.pagination!.limit -= 1;
        }
    }

    @action
    updateItem(item: T, updatedItem: T): void {
        const index = this.items.indexOf(item);
        if (index !== -1) {
            this.items[index] = updatedItem;
        }
    }

    @action
    setResult(result: PaginatedResult<T>) {
        this.items = result.items;
        this.pagination = result.meta;
    }

    @action
    public setLoading(value: boolean): void {
        this.loading = value;
    }

    public reloadWithLastRequest() {
        this.lastRequest && this.reloadItems(this.lastRequest);
    }

    async reloadItems(request: PaginatedRequest) {

        let result = await this.dataLoader(request);
        if (this.convertDataCallback) {
            result = this.convertDataCallback(result);
        }
        this.setResult(result);
    }
}