import React from "react";
import { observer } from "mobx-react";
import { Space } from "antd";
import { ComponentPropsFromRoute } from "../../../routers/routers";
import { RouteTabs } from "../../common/route-tabs/route.tabs";
import {MyPageHeader} from "./my-page-header/my.page.header";

interface MyPageProps extends ComponentPropsFromRoute {}
export const MyPage: React.FC<MyPageProps> = observer(({ name, routeChildren }) => {

    return (
        <Space direction={"vertical"} size={[50, 50]}>
            {/*<Typography.Title level={1}>{user?.firstName + " " + user?.lastName}</Typography.Title>*/}
            <MyPageHeader/>
            <RouteTabs routes={routeChildren!} />
            {/*<MyPageFiles pageName={name}/>*/}
            {/*<MyPageActivity/>*/}
        </Space>
    );
});
