import {useMemo} from "react";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import {ParsedQuery, parse, stringify} from "query-string";

export interface URLHookData {
    urlParams: ParsedQuery;
    setURLParams: (values: ParsedQuery, replace: boolean) => void;
}

export type ArrayFormat = 'bracket' | 'index' | 'comma' | 'separator' | 'bracket-separator' | 'colon-list-separator' | 'none';

export const useURLParams = (format: ArrayFormat = 'bracket') => {

    const navigate = useNavigate();
    const location = useLocation();
    const params = useMemo<ParsedQuery>(() => parse(location.search, {arrayFormat: format}), [location.search]);

    const historyPush = (values: ParsedQuery, replace: boolean = false) => {
        const qs = '?'+ stringify(values, {arrayFormat: format});

        if (location.search !== qs) {
            if (replace) {
                navigate(qs, {replace: true});
            } else {
                navigate(qs);
            }
        }
    }

    return {
        urlParams: params,
        setURLParams: historyPush
    }
}