import {ColorPicker, Form, Input, Modal, Tag} from "antd";
import React from "react";
import {ColorFactory} from "antd/es/color-picker/color";
import {useTranslate} from "../../../../hooks/translate.hook";
import {PlanerTag} from "../../../../models/departments/planer_tags";
import {plannerService} from "../../../../services/planner/planner.service";

interface SettingsTagsAddTagModalProps {
    editableTag: PlanerTag | null;
    onCancel: () => void;
    onComplete: () => void;
}
export const SettingsTagsAddTagModal: React.FC<SettingsTagsAddTagModalProps> = ({editableTag, onCancel, onComplete}) => {
    const t = useTranslate();
    const [form] = Form.useForm();

    const initialValues = editableTag
        ? {color: new ColorFactory(editableTag.color), name: editableTag.name}
        : {color: new ColorFactory('#C14C4C')}

    return (
        <Modal
            title={t("BRANCH.DATA.DEPARTMENT.PLANNER_TAGS.TITLE")}
            visible={true}
            onOk={() => form.submit()}
            onCancel={onCancel}
        >
            <Form
                form={form}
                layout="vertical"
                name="departmentAddPlannerTag"
                initialValues={initialValues}
                onFinish={() =>
                    form.validateFields().then(async (values) => {

                        const resultValue = {
                            ...values,
                            color: values.color.toHexString()
                        }

                        try {
                            if (editableTag) {
                                await plannerService.editPlannerTag(editableTag.id, resultValue);
                            } else {
                                await plannerService.addPlannerTag(resultValue);
                            }
                            onComplete();
                            onCancel();
                        } catch (e: any) {
                            form.setFields(e.response?.data?.fields || [])
                        }
                    })
                }
            >
                <Form.Item
                    shouldUpdate={() => true}
                    label={t("PREVIEW")}
                >
                    {() => (
                        <Tag color={form.getFieldValue('color')?.toHexString() || 'red'}>
                            {form.getFieldValue('name') || '(no title)'}
                        </Tag>
                    )}
                </Form.Item>
                <Form.Item
                    name="name"
                    label={t("NAME")}
                    rules={[
                        {
                            required: true,
                            message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="color"
                    label={t("COLOR")}
                    rules={[
                        {
                            required: true,
                            message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                        },
                    ]}
                >
                    <ColorPicker />
                </Form.Item>
            </Form>
        </Modal>
    );
};
