import React from "react";
import {PlannerTask} from "../../../../../models/planner/planner";
import {Space, Tag} from "antd";
import {PlanerTag} from "../../../../../models/departments/planer_tags";
import {plannerService} from "../../../../../services/planner/planner.service";

interface TaskProps {
    tag: PlanerTag;
    task?: PlannerTask;
    reload?: () => void;
}

export const TaskTag: React.FC<TaskProps> = ({task, tag, reload}) => {

    const remove = async () => {
        if (task && reload) {
            await plannerService.removeTag(task.id, tag.id);
            reload();
        }
    }

    return (
        <Tag
            key={tag.id}
            color={tag.color}
            closable={!!task}
            onClose={remove}
        >
            {tag.name}
        </Tag>
    )
}
