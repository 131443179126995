import React, {useEffect} from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";

interface OrdersListProps extends ComponentPropsFromRoute {}

export const OrdersList: React.FC<OrdersListProps> = ({ name }) => {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/orders") {
            navigate("/orders/list", {replace: true})
        }
    }, [navigate, location.pathname])

    return <></>
}