import React, {useState} from "react";
import {Button, Form, Space} from "antd";
import TextArea from "antd/es/input/TextArea";
import {UIInputCardList} from "../../../../../../common/ui/input/widget/card-list/ui.input.card-list";
import {
    UiInputNumberWithControls
} from "../../../../../../common/ui/input/widget/number-with-controls/ui.input.number-with-controls";
import {propertyService} from "../../../../../../../services/property/property.service";
import {notifications} from "../../../../../../../services/notifications/notifications";
import "./review.status.step.scss"
import {useTranslate} from "../../../../../../../hooks/translate.hook";
import {useOutletContext} from "react-router-dom";
import {ReviewStepContext} from "../review.step.context";
import {useNavigate} from "react-router";

export interface ReviewStepProps {
}

export const ReviewStatusStep: React.FC<ReviewStepProps> = () => {

    const t = useTranslate();
    const [form] = Form.useForm();
    const {property, blob} = useOutletContext<ReviewStepContext>();
    const [loading, setLoading] = useState<boolean>(false);
    const ctx = useOutletContext<ReviewStepContext>();
    const navigate = useNavigate();

    const complete = () => {
    }

    return (
        <Space direction={"vertical"}>
            {blob ? (
                <img width={"100%"} src={URL.createObjectURL(blob)}  alt={"Photo"} />
            ) : null}
            <Form
                form={form}
                layout="vertical"
                initialValues={{qty: 1}}
                onFinish={() => form
                    .validateFields()
                    .then(async values => {
                        try {
                            setLoading(true)
                            if (blob) {
                                values.photo = blob;
                            }
                            await propertyService.addTaskPropertyReview(property.id, values);
                            notifications.successfully();
                            if (ctx.backToStart) {
                                navigate('/property/review/inventory-task/' + ctx.task.id + '?backToStart=1');
                            } else {
                                navigate('/property/' + ctx.property.property.id);
                            }
                        }
                        catch (e: any) {
                            notifications.message("error", "Error occurred")
                        }
                        finally {
                            setLoading(false);
                            complete();
                        }
                    })
                }
            >
                <Form.Item
                    name={"qty"}
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <UiInputNumberWithControls min={1} max={(property.qty - property.lostQty - property.destroyedQty - property.confirmedQty)} />
                </Form.Item>
                <Form.Item
                    name={"status"}
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <UIInputCardList
                        cards={[
                            {title: 'Damaged', value: 'damaged', hint: String(property.destroyedQty), color: '#ed6531'},
                            {title: 'Found',   value: 'ok',      hint: String(property.confirmedQty), color: '#79b96f'},
                            {title: 'Lost',    value: 'lost',    hint: String(property.lostQty),      color: '#cc342f'}
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    name={"comment"}
                >
                    <TextArea placeholder={"Please comment"} />
                </Form.Item>
                <Form.Item
                >
                    <Button
                        block
                        type={"primary"}
                        onClick={() => form.submit()}
                        disabled={loading}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Space>
    )
}