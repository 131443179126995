import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {reportsService} from "../../../../services/reports/reports.service";
import {ReportConfig} from "../../../../models/reports/reports";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {SmartTable} from "../../../common/smart-table/smart-table";
import {SmartTableDataHandler} from "../../../common/smart-table/smart-table.data-handler";
import {SmartColumn} from "../../../common/smart-table/smart-table.model";
import {Button, Space} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {FieldType} from "../../../common/smart-table/smart-table.field-config";
import {ReportsReportResolvableFields} from "./resolvable-fields/reports.report.resolvable-fields";
import {ReportsReportExportModal} from "./export/reports.report.export.modal";
import {ReportsReportPreparingStatusModal} from "./preparing-status/reports.report.preparing-status.modal";
import {createColumns} from "./reports.report.utils";

export const ReportsReportView: React.FC = () => {
    const t = useTranslate();

    const { name } = useParams<{ name: string }>();

    const [config, setConfig] = useState<ReportConfig | null>(null);
    const [columns, setColumns] = useState<SmartColumn<any>[]>([]);

    const [extraFields, setExtraFields] = useState<Record<string, any>>({});

    const [shownExportModal, setShownExportModal] = useState<boolean>(false);
    const [shownPreparingStatusId, setShownPreparingStatusId] = useState<number>(0);

    const handler = useMemo(() => new SmartTableDataHandler(r => reportsService.getData(name!, r)), [name])

    useEffect(() => {
        reportsService.getConfig(name!).then(config => {
            setConfig(config)
            setColumns(createColumns(config.fields))
        });
    }, [name]);

    if (!config) {
        return <LoadingSpin />
    }

    return (
        <Space direction={"vertical"} size={[20, 20]}>
            <Button
                type={"primary"}
                onClick={() => setShownExportModal(true)}
            >
                {t("REPORTS.DOWNLOAD")}
            </Button>
            <ReportsReportResolvableFields
                config={config}
                defaultValues={extraFields}
                onValidated={setExtraFields}
            />
            <SmartTable
                handler={handler}
                columns={columns}
                filterGroups={config.fieldsGroups}
                setExtraFields={setExtraFields}
                setDefaultExtraFields={def => {setExtraFields(act => ({...act, ...def}))}}
                loadOnlyIfExtraFieldsIsSet={config.resolvableFields.length > 0}
                extraFields={extraFields}
                disabled={config.resolvableFields.length > 0 && Object.keys(extraFields).length === 0}
            />
            {shownExportModal ? (
                <ReportsReportExportModal
                    name={name!}
                    query={handler.lastRequest?.toParams()}
                    onClose={() => setShownExportModal(false)}
                    onComplete={(id) => setShownPreparingStatusId(id)}
                />
            ) : null}
            {shownPreparingStatusId ? (
                <ReportsReportPreparingStatusModal
                   id={shownPreparingStatusId}
                   onClose={() => setShownPreparingStatusId(0)}
                />
            ) : null}
        </Space>
    );
};