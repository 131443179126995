import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Divider, Space} from "antd";
import {BranchDataCourierForm} from "./branch.data.courier.form";
import {ShippingCourier} from "../../../../../models/shipping/shipping";
import {branchesService} from "../../../../../services/branches/branches.service";
import {LoadingSpin} from "../../../../common/loading-spin/loading.spin";
import {BranchDataRules} from "./branch.data.rules";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";
import {useNavigate} from "react-router";

export const BranchDataCourier: React.FC = () => {
    const {courierId} = useParams<{ courierId: string }>();
    const t = useTranslate();
    const navigate = useNavigate();

    const [courier, setCourier] = useState<ShippingCourier>();

    useEffect(() => {
        branchesService.getShippingCourier(courierId!).then(courier => setCourier(courier));
    }, [courierId])

    if (!courier) {
        return <LoadingSpin />
    }

    const onUpdate = async (data: ShippingCourier) => {
        return await branchesService.editShippingCourier(courier.id, data);
    };

    return <Space direction="vertical" size={[30, 30]}>
        <PageHeader
            style={{ padding: 0 }}
            title={courier.name}
            onBack={() => {
                navigate('/settings/branches');
            }}
        >
            <BranchDataCourierForm courier={courier} onSubmit={onUpdate} />
        </PageHeader>

        <Divider />
        <BranchDataRules />

    </Space>
}