import React, {useMemo} from "react";
import {Table} from "antd";
import {columnsFactory} from "./item-list.columns";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {SplitItemListItem} from "../model";

interface SplitItemListProps {
    itemList: SplitItemListItem[];
    onRemove: (item: SplitItemListItem) => void;
}
export const SplitItemList: React.FC<SplitItemListProps> = ({itemList, onRemove}) => {

    const t = useTranslate();

    const columns = useMemo(() => columnsFactory(t, onRemove), [onRemove]);

    return (
        <Table
            columns={columns}
            dataSource={itemList}
            pagination={false}
        />
    )
}