import React, {useMemo} from "react";
import {
    Checkbox,
    Col, DatePicker,
    Form,
    Input,
    InputNumber, Modal,
    Row,
} from "antd";
import {useTranslate} from "../../../hooks/translate.hook";
import {useForm} from "antd/es/form/Form";
import {ClaimItem, createEditRequestFromClaimItem, EditClaimItem} from "../../../models/claims/claims";
import {claimService} from "../../../services/claims/claims.service";

interface ClaimsEditModalProps {
    onComplete: () => void;
    onCancel: () => void;
    item: ClaimItem;
}

export const ClaimsEditModal: React.FC<ClaimsEditModalProps> = ({onComplete, onCancel, item}) => {
    const t = useTranslate();

    const [form] = useForm<EditClaimItem>();

    const initialValues = useMemo(() => createEditRequestFromClaimItem(item), [item]);

    return (
        <Modal
            title={t('CLAIMS.EDIT_ITEM')}
            width={600}
            visible={true}
            onCancel={onCancel}
            onOk={() => {
                form.submit();
            }}
            destroyOnClose
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={(modifiedValue) => {
                    form.validateFields().then(values => {
                        modifiedValue.data!.qty = parseInt(modifiedValue.data!.qty as any);
                        claimService.updateItem(item.id, modifiedValue).then(() => {
                            onComplete()
                        }, e => {
                            if (e.response?.data.fields) {
                                form.setFields(e.response?.data?.fields)
                            }
                        })
                    });
                }}
                autoComplete="off"
                initialValues={initialValues}
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            label={t('QTY')}
                            name={['data', 'qty']}
                            rules={[{ required: true }]}
                        >
                            <Input type={"number"} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            label={t('DESCRIPTION')}
                            name={['data', 'description']}
                            rules={[{ required: true }]}
                        >
                            <Input.TextArea style={{height: 140}} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={6}>
                        <Form.Item
                            label={t('CLAIMS.CLAIM.FITTING_DATE')}
                            name={['clarification', 'fittingDate']}
                        >
                            <DatePicker style={{width: "100%"}} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t('MILLAGE')}
                            name={['clarification', 'fittingOnMillage']}
                        >
                            <InputNumber style={{width: "100%"}} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t('CLAIMS.CLAIM.PROBLEM_DATE')}
                            name={['clarification', 'problemDate']}
                        >
                            <DatePicker style={{width: "100%"}} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t('MILLAGE')}
                            name={['clarification', 'problemOnMillage']}
                        >
                            <InputNumber style={{width: "100%"}} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={6}>
                        <Form.Item
                            label={t('BRAND')}
                            name={['clarification', 'carBrand']}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t('MODEL')}
                            name={['clarification', 'carModel']}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t('CLAIMS.CLAIM.CLARIFICATION')}
                            name={['clarification', 'carModelClarification']}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={t('YEAR')}
                            name={['clarification', 'carModelYear']}
                        >
                            <InputNumber style={{width: "100%"}} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name={['clarification', 'hasCrashedBoxRetention']}
                            valuePropName={"checked"}
                        >
                            <Checkbox>{t('CLAIMS.CLAIM.CRASHED_BOX')}</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name={['clarification', 'hasBadPackingRetention']}
                            valuePropName={"checked"}
                        >
                            <Checkbox>{t('CLAIMS.CLAIM.BAD_PACKING')}</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}