import React, {useEffect} from "react";
import {useRequiredStore} from "../../utils/store";
import {RootStoreContext} from "../../stores/root/root.store";
import {observer} from "mobx-react";
import {useNavigate} from "react-router";

const Index: React.FC = observer(() => {
    const {authStore: {isLoggedIn}} = useRequiredStore(RootStoreContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/orders', {replace: true});
        } else {
            navigate('/login', {replace: true});
        }
    }, [])

    return (
        <>We will be redirected...</>
    );
});

export default Index;
