import React from "react";
import { AgentDataAddressWrapper } from "./agent.data.address.wrapper";
import { useRequiredStore } from "../../../../utils/store";
import { AgentDataStoreContext } from "../agent.data.store";
import { Space } from "antd";
import { observer } from "mobx-react";
import { AgentDataContacts } from "./agent-data-contacts/agent.data.contacts";

export const AgentDataAddresses: React.FC = observer(() => {
    const store = useRequiredStore(AgentDataStoreContext);
    return (
        <Space direction="vertical" size={[50, 50]}>
            <AgentDataAddressWrapper
                addresses={[store.account?.billingAddress!]}
                type="billing"
                title="CLIENT.PAYER_DATA"
                changeable
                allowAddOrRemove={false}
            />
            <AgentDataAddressWrapper
                addresses={store.account?.shippingAddresses!}
                type="shipping"
                title="CLIENT.RECIPIENT_DATA"
                changeable
                allowAddOrRemove={true}
            />
            <AgentDataContacts />
        </Space>
    );
});
