import React, {useMemo, useState} from "react";
import {observer} from "mobx-react";
import {Space, Spin, Typography} from "antd";
import {OrdersCreateCart} from "../orders-create-body/orders.create.cart";
import {useRequiredStore} from "../../../../../utils/store";
import {OrdersCreateStore, OrdersCreateStoreContext} from "../orders.create.store";
import {AgentDataStoreContext} from "../../../../agents/agent-data/agent.data.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {
    OrderCreateType
} from "../../../../../models/orders/orders-cart/orders-create/orders-cart-create-helper/order.create.type";
import {useNavigate} from "react-router";

export const OrdersCreateBody: React.FC<ComponentPropsFromRoute> = observer(({requiredConfigs}) => {

    const t = useTranslate();
    const navigate = useNavigate();
    const agentStore = useRequiredStore(AgentDataStoreContext);
    const store = useMemo(
        () => new OrdersCreateStore(requiredConfigs!.type as OrderCreateType, agentStore.account!, agentStore.account!.agent.number, agentStore.updateAdvancedOrdersCount, navigate, t),
        [requiredConfigs!.type, agentStore.account!.agent.number]
    );

    if (!store.hasAllFields) {
        return <Typography.Title level={3}>{t("ORDERS.CREATE.NO_FIELDS_FMS")}</Typography.Title>
    }

    return (
        <OrdersCreateStoreContext.Provider value={store}>
            {store.notInitialized ? (
                <Space
                    direction="horizontal"
                    align="center"
                    style={{height: "calc(100vh - 180px)", justifyContent: "center"}}
                >
                    <Spin/>
                </Space>
            ) : <OrdersCreateCart/>}
        </OrdersCreateStoreContext.Provider>
    );
})