import React, {useEffect, useMemo, useState} from "react";
import { observer } from "mobx-react";
import { Button, Form, Popconfirm, Row, Space, Table, TableProps, Tooltip } from "antd";
import { EditableCell } from "../../orders/orders-create/create/table/editable-cell/editable.cell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Filter from "./filter/filter";
import { FilterButtonConfig, FilterItemState, FilterKey } from "../../../models/filtered-table/filtered.table.props";
import { FilteredTableStore, FilteredTableStoreContext } from "./filtered.table.store";
import { FilterDataHandler } from "../../../models/filter/filter.data.handler";
import { PaginatedRequest } from "../../../models/core/paginated.request";
import { PaginatedResult } from "../../../models/core/paginated.result";
import { ExpandableConfig } from "antd/es/table/interface";
import "./filter.table.scss";
import { ColumnsModal } from "./columns-modal/columns.modal";
import i18n from "i18next";
import { createFieldGroupConfigFromFilterKeys, FieldGroupConfig } from "../../../models/filter/filter.group";
import { FieldGroupSnapshot } from "../../../models/filter/filter.field.snapshot";
import {useTranslate} from "../../../hooks/translate.hook";

export interface FilteredTableProps<T = object, B = any> extends TableProps<T & ExpandableConfig<T>> {
    onSave?: (id: number, values: any) => Promise<any>;
    onDelete?: (deletingItem: any) => Promise<any>;
    getEditValues?: (row: T) => any;
    getSaveValues?: (row: T) => any;

    filterGroups?: FieldGroupConfig[];

    editableRowKey?: string;
    isEditable?: boolean;

    filterKeys?: FilterKey[];

    columnsConfigs?: {
        columns: { key: string; title: string }[];
        pageKey: string;
    };
    dataHandler?: FilterDataHandler<any>;
    buttons?: JSX.Element[];

    getRequest?: (request: PaginatedRequest) => void;
    loadDataCallback?: (request: PaginatedRequest) => Promise<PaginatedResult<any>>;
    defaultFilters?: FilterItemState[];
    filters?: FieldGroupSnapshot[];
    listenToUrl?: boolean;

    reloadOnLngChange?: boolean;
    filterButtons?: FilterButtonConfig<B>[];
    setFilterSnapshotsSetter?: (setter: (value: FieldGroupSnapshot[]) => void) => void;
}

const FilteredTable = observer(
    <T, B>({
        isEditable,
        dataHandler,
        columnsConfigs,
        filterKeys,
        buttons,
        editableRowKey,
        filters,
        rowKey = "id",
        defaultFilters = [],
        reloadOnLngChange = false,
        listenToUrl = true,
        filterButtons,

        filterGroups,

        setFilterSnapshotsSetter,
        loadDataCallback,
        onSave,
        onDelete,
        getEditValues,
        getSaveValues,
        getRequest,
        ...props
    }: FilteredTableProps<T>) => {

        const t = useTranslate();
        const [config, setConfig] = useState<FieldGroupConfig[]>(
            filterKeys ? createFieldGroupConfigFromFilterKeys(filterKeys) : []
        );

        const store = useMemo(() => {
            let columns = props.columns ? props.columns : [];

            if (!dataHandler) {
                if (!loadDataCallback) {
                    throw new Error("dataHandler or loadDataCallback must be set");
                }
                dataHandler = new FilterDataHandler(loadDataCallback);
            }

            return new FilteredTableStore<T, B>(
                !filterKeys && !filterGroups,
                listenToUrl,
                dataHandler,
                columns.map((item) => ({
                    title: String(item.title),
                    value: String(item.key),
                })),
                //defaultFilters,
                filters,
                columnsConfigs?.pageKey,
                filterButtons
            );
        }, [dataHandler]);

        useEffect(() => {
            if (filterKeys) {
                setConfig(createFieldGroupConfigFromFilterKeys(filterKeys));
            }
        }, [filterKeys]);

        useEffect(() => {
            if (getRequest) {
                getRequest(store.request);
            }
        }, [store.request]);

        useEffect(() => {
            if (setFilterSnapshotsSetter) {
                setFilterSnapshotsSetter(store.setFiltersSnapshots);
            }
        }, [store.setFiltersSnapshots]);

        useEffect(() => {
            window.addEventListener("popstate", store.reloadDataByPopState);

            return () => {
                window.removeEventListener("popstate", store.reloadDataByPopState);
            };
        }, []);

        useEffect(() => {
            if (reloadOnLngChange) {
                i18n.on("languageChanged", store.onLngChange);
                return () => i18n.off("languageChanged", store.onLngChange);
            }
        }, []);

        const [form] = Form.useForm();
        const [editingKey, setEditingKey] = useState<number | null>(null);

        // RECORD - объект строки в таблице
        const isEditing = (record: any) => record.id === editingKey;

        const edit = (row: any) => {
            form.setFieldsValue(getEditValues!(row));
            setEditingKey(row.id);
        };

        const save = async (id: number) => {
            try {
                const row = await form.validateFields();
                await onSave!(id, getSaveValues!(row));
                setEditingKey(null);
            } catch (errInfo) {}
        };

        if (isEditable && props.columns) {
            props.columns = props.columns.map((column) => {
                if (column.key !== "action") {
                    return column;
                }
                return {
                    title: t("ACTION"),
                    dataIndex: "action",
                    key: "action",
                    width: 100,
                    render: (_: any, record: any) => {
                        const editable = isEditing(record);
                        if (record[editableRowKey!]) {
                            return (
                                <>
                                    {editable ? (
                                        <Tooltip placement="topRight" title={t("SAVE")}>
                                            <Button
                                                className="table-btn btn"
                                                onClick={() => save(record.id)}
                                                type="default"
                                                icon={<FontAwesomeIcon icon={["fas", "check"]} />}
                                            />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip placement="topRight" title={t("EDIT")}>
                                            <Button
                                                className="table-btn btn"
                                                disabled={editingKey !== null}
                                                onClick={() => edit(record)}
                                                type="default"
                                                icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                                            />
                                        </Tooltip>
                                    )}{" "}
                                    {onDelete ? (
                                        <Popconfirm
                                            title={t("ARE_YOU_SURE")}
                                            onConfirm={() => onDelete(record)}
                                            placement="left"
                                            okText={t("YES")}
                                            cancelText={t("NO")}
                                        >
                                            <Tooltip placement="topRight" title={t("REMOVE")}>
                                                <Button
                                                    className="table-btn btn-remove btn"
                                                    disabled={editingKey !== null}
                                                    type="default"
                                                    icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                                                />
                                            </Tooltip>
                                        </Popconfirm>
                                    ) : null}
                                </>
                            );
                        }

                        return <></>;
                    },
                };
            });
        }

        const mergedColumns = props.columns!.map((col: any) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record: any) => ({
                    record,
                    inputType: "number",
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                }),
            };
        });

        const getColumns = (allColumns: any[]): any => {
            const filteredColumns = allColumns.filter(
                (item) => store.selectedColumns.includes(item.key) && item.key !== "action"
            );
            const actionColumn = allColumns.find((item) => item.key === "action");
            if (actionColumn) {
                filteredColumns.push(actionColumn);
            }
            return filteredColumns ? filteredColumns : [];
        };

        return (
            <FilteredTableStoreContext.Provider value={store}>
                <Space size={[10, 10]} direction={"vertical"} style={{ display: "flex" }}>
                    <div
                        className="filtered-table-buttons"
                        style={{
                            justifyContent: filterButtons && buttons ? "space-between" : "start",
                        }}
                    >
                        <div className="filter-buttons">
                            {filterButtons &&
                                filterButtons.map((filterButton) => {
                                    return (
                                        <Button
                                            key={filterButton.type}
                                            type={
                                                store.activeFilterButton === filterButton.type ? "primary" : "default"
                                            }
                                            onClick={() => store.onFilterButtonClick(filterButton.type)}
                                        >
                                            {t(filterButton.text)}
                                        </Button>
                                    );
                                })}
                        </div>
                        <div className="additional-buttons">{buttons && buttons.map((btn) => btn)}</div>
                    </div>
                    {filterKeys || columnsConfigs ? (
                        <Row justify={"space-between"} wrap={false}>
                            {filterKeys || filterGroups ? (
                                <Filter
                                    config={filterGroups && filterGroups?.length > 0 ? filterGroups : config}
                                    defaultValue={store.filtersSnapshots}
                                    onUpdated={store.onFilterUpdated}
                                    onInternalUpdated={store.onFilterInternalUpdated}
                                    onInit={store.onFilterInit}
                                />
                            ) : null}
                            {columnsConfigs ? (
                                <Button
                                    onClick={store.openColumnsSettingsModal}
                                    className={"table-columns-button"}
                                    icon={<FontAwesomeIcon icon={["fas", "cog"]} />}
                                />
                            ) : null}
                        </Row>
                    ) : null}
                    <Space
                        direction="vertical"
                        style={{
                            maxWidth: "100%",
                            overflowY: "auto",
                            display: "flex",
                        }}
                    >
                        <Form form={form} component={false}>
                            {props.expandable && store.dataHandler.loading ? (
                                <Table
                                    key="first"
                                    loading
                                    columns={isEditable ? getColumns(mergedColumns) : getColumns(props.columns!)}
                                />
                            ) : (
                                <Table
                                    {...props}
                                    key="second"
                                    rowKey={rowKey}
                                    columns={isEditable ? getColumns(mergedColumns) : getColumns(props.columns!)}
                                    components={isEditable ? { body: { cell: EditableCell } } : undefined}
                                    dataSource={[...store.dataHandler.items]}
                                    loading={store.dataHandler.loading}
                                    scroll={{ x: true }}
                                    pagination={{
                                        position: ["bottomLeft"],
                                        total: store.dataHandler.pagination?.total,
                                        current: store.dataHandler.pagination?.page,
                                        pageSize: store.dataHandler.pagination?.limit,
                                        pageSizeOptions: store.limitVariants,
                                        showSizeChanger: true,
                                        showQuickJumper: true,
                                        onChange: (page) => store.setPage(page),
                                        onShowSizeChange: (_, size) => store.setLimit(size),
                                        showTotal: (total) => t("TOTAL_ITEMS") + ": " + total,
                                    }}
                                />
                            )}
                        </Form>
                    </Space>
                </Space>

                {columnsConfigs ? (
                    <ColumnsModal
                        visible={store.columnsSettingsModalShown}
                        columns={columnsConfigs.columns}
                        selectedColumns={store.selectedColumns}
                        selectColumns={store.selectColumns}
                        onClose={store.closeColumnsSettingsModal}
                    />
                ) : null}
            </FilteredTableStoreContext.Provider>
        );
    }
);

export default FilteredTable;
