import {Translate} from "../../../../hooks/translate.hook";
import {ColumnsType} from "antd/es/table";
import {TableShipment} from "../../../../models/parcels/shipment";
import {Link} from "react-router-dom";
import {ShipmentsStatus} from "../../../shipments/shipments-status/shipments.status";
import Date from "../../../date/date";
import {Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export const columnsFactory = (t: Translate): ColumnsType<TableShipment> => {
    return  [
        {
            title: t("NUMBER"),
            dataIndex: "reference",
            key: "reference",
            width: 200,
            render: (reference, item) => <Link to={"/shipment/" + item.id}>{reference}</Link>
        },
        {
            title: t("COURIER.TITLE"),
            dataIndex: "carrier",
            key: "carrier",
            width: 110,
        },
        {
            title: t("SHIPMENTS.PARCELS.TRACK_CODE"),
            dataIndex: "trackCode",
            key: "trackCode",
            width: 250,
            render: (trackCode: string, row) => (
                <a href={row.trackingUrl} target="_blank" rel="noopener noreferrer">
                    {trackCode}
                </a>
            ),
        },
        {
            title: t("SHIPMENTS.PARCELS.NUMBER"),
            dataIndex: "number",
            key: "number",
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            render: (status: string) => (status ? <ShipmentsStatus status={status} /> : null),
        },
        {
            title: t("SHIPPED_AT"),
            dataIndex: "packedAt",
            key: "packedAt",
            render: (dateString: string | null) => {
                return dateString ? <Date dateString={dateString} showTime={false} /> : null;
            },
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            className: "table-button-cell",
            render: (_: any, item: TableShipment) =>
                !item.id.includes("CHILD") ? (
                    <Tooltip placement="topRight" title={t("SHIPMENTS.SHOW_DELIVERY_NODE")}>
                        <a
                            className="btn ant-btn btn-icon-link"
                            href={"/api/v3/shipments/" + item.id + "/delivery-note"}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={["fas", "file-pdf"]} />
                        </a>
                    </Tooltip>
                ) : null,
        },
    ];
}