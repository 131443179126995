import React, {useState} from "react";
import {observer} from "mobx-react";
import {LoginWrapper} from "../../login.wrapper";
import {ForgotPasswordCompleteStore} from "./forgot.password.complete.store";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Alert, Spin} from "antd";
import { Link } from "react-router-dom";

export const ForgotPasswordComplete: React.FC = observer(() => {
    const [store] = useState(() => new ForgotPasswordCompleteStore());
    const t = useTranslate();

    return (
        <LoginWrapper title="FORGOT_PASSWORD.TITLE">
            {!store.inited ? (
                <Spin/>
            ) : (
                <>
                    <Alert
                        description={
                            t(store.completed ?
                                "FORGOT_PASSWORD.COMPLETE.SUCCESS" :
                                "FORGOT_PASSWORD.COMPLETE.ERROR"
                            )
                        }
                        type={store.completed ? "success" : "error"}
                        message={t(store.completed ?"SUCCESS" : "ERROR")}
                        showIcon
                    />
                    {store.completed ? (
                        <Link
                            to="/login"
                            className="ant-btn ant-btn-primary register-btn"
                            style={{width: "100%", marginTop: 20}}
                        >
                            {t("LOGIN.LOG_IN")}
                        </Link>
                    ) : null}
                </>
            )}
        </LoginWrapper>
    )
})