import {action, makeObservable, observable} from "mobx";

export class ConfirmStore {

    @observable
    public shown: boolean = false;

    @observable
    public disabled: boolean = false;

    @observable
    public message: string = "";

    @observable
    private onSuccess?: () => Promise<any>;

    @observable
    private onCancel?: () => void;

    constructor() {
        makeObservable(this);
    }

    @action
    open(message: string, onSuccess: () => Promise<any>, onCancel: () => void) {
        this.shown     = true;
        this.disabled  = false;
        this.message   = message;
        this.onSuccess = onSuccess;
        this.onCancel  = onCancel;
    }

    @action
    success() {
        this.disabled = true;
        this.onSuccess && this.onSuccess().finally(() => this.close());
    }

    @action
    close() {
        this.onCancel && this.onCancel();
        this.shown = false
    }
}