import React, {useEffect, useState} from "react";
import {Button, List, Space, Upload} from "antd";
import {PlannerTask, PlannerTaskFile} from "../../../../../models/planner/planner";
import {plannerService} from "../../../../../services/planner/planner.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface PlannerTasksViewAttachmentsProps {
    task: PlannerTask;
    reload: () => void;
}

export const PlannerTasksViewAttachments: React.FC<PlannerTasksViewAttachmentsProps> = ({task}) => {

    const [attachments, setAttachments] = useState<PlannerTaskFile[]>([]);

    const reload = () => {
        plannerService.getAttachments(task.id).then(attachments => setAttachments(attachments));
    }

    useEffect(() => {
        reload();
    }, [])

    return (
        <Space direction={"vertical"}>
            {attachments.length > 0 ? (
                <List
                    bordered
                    dataSource={attachments}
                    renderItem={(attachment) => (
                        <List.Item
                            extra={
                                <Button
                                    icon={<FontAwesomeIcon icon={["fas", "trash"]} />}
                                    type={"text"}
                                    size={"small"}
                                    onClick={async () => {
                                        await plannerService.removeFile(attachment.hash)
                                        reload();
                                    }}
                                />
                            }>
                            <a href={"/api/v3/planner/tasks/download/" + attachment.hash + "/" + attachment.filename}>{attachment.filename}</a>
                        </List.Item>
                    )}
                />
            ) : null }
            <Upload
                onChange={async (f: any) => {
                    await plannerService.attachFile(task.id, true, f.file)
                    reload();
                }}
                beforeUpload={() => false}
                showUploadList={false}
                maxCount={1}
            >
                <Button
                    icon={<FontAwesomeIcon icon={["fas", "file-pdf"]} />}
                    type={"dashed"}
                >Attach file</Button>
            </Upload>
        </Space>
    )
}