import queryString from "query-string";

export const sortDirection = <T = any>(items: T[], formListIndex: number, direction: string): T[] => {
    return "up" === direction ? sortUp(items, formListIndex) : sortDown(items, formListIndex);
};

const sortDown = <T = any>(items: T[], formListIndex: number): T[] => {
    if (items.length <= formListIndex + 1) {
        return items;
    }
    const currentItem = items[formListIndex];
    items[formListIndex] = items[formListIndex + 1];
    items[formListIndex + 1] = currentItem;
    return items;
};

const sortUp = <T = any>(items: T[], formListIndex: number): T[] => {
    if (formListIndex < 1) {
        return items;
    }
    const currentItem = items[formListIndex];
    items[formListIndex] = items[formListIndex - 1];
    items[formListIndex - 1] = currentItem;
    return items;
};

export const splitNumber = (inputNumber: number | string): string => {
    return typeof inputNumber === "string"
        ? inputNumber.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        : String(inputNumber).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const isAllowed = (accesses: string[], access: string | undefined): boolean =>
    !access ? true : accesses.includes(access);

export const generatePassword = () => {
    const length = 16,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
};

export const getDataFromUrl = <T>(): T => {
    const params: any = queryString.parse(window.location.search.substr(1));
    return params as T;
};

export const updateUrlQueryString = (queryString: string): void => {
    window.history.pushState("", "", "?" + queryString);
};

export const getStringifiedQueryParams = (object: object): string => {
    return queryString.stringify(object);
};

export const getFromStorage = <T>(key: string, defaultValue: T, validate: (dataFromStorate: any) => boolean): T => {
    try {
        const localStorageData = localStorage.getItem(key);
        if (localStorageData === null || localStorageData === undefined) {
            throw new Error("Invalid or not existing data");
        }
        const desiredData = JSON.parse(localStorageData);
        if (!validate(desiredData)) {
            throw new Error("Invalid or not existing data");
        }
        return desiredData;
    } catch (error) {
        localStorage.removeItem(key);
        return defaultValue;
    }
};

export const getPropertyByPath = (obj: any, path: string) => {
    for (let i=0, splPath=path.split('.'), len=splPath.length; i<len; i++){
        obj = obj[splPath[i]];
    }
    return obj;
};