import React, {useEffect, useRef} from "react";
import {Observer, observer} from "mobx-react";
import {useTranslate} from "../../../hooks/translate.hook";
import {ColumnsType} from "antd/es/table";
import {Order, OrderAccount} from "../../../models/orders/orders-list/order";
import {Link} from "react-router-dom";
import {Col, Descriptions, Divider, Popover, Row, Space, Table, Tag, Typography} from "antd";
import {OrderListWarningIcon} from "../list/warning-icon/order.list.warning.icon";
import {Warehouse} from "../../../models/warehouses/warehouse";
import {OrderStatus} from "../../../stores/common/common.store";
import {OrdersStatus} from "../status/order.status";
import {Amount} from "../../../models/prices/price";
import {displayPrice} from "../../../models/prices/price.helper";
import Date from "../../date/date";
import {useRequiredStore} from "../../../utils/store";
import {OrderDeliveryCreateStoreContext} from "./order.delivery.create.store";
import {OrderDeliveryItem} from "../../../models/orders/order-delivery/order.delivery";

export const OrdersDeliveryList: React.FC = observer(() => {
    const store = useRequiredStore(OrderDeliveryCreateStoreContext)
    const t = useTranslate();

    const ref = useRef();

    useEffect(() => {
        const closePopupHandler = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (target.id !== "popover-wrapper") {
                store.closeAgreementsModal()
            }
        };

        document.addEventListener("mousedown", closePopupHandler);

        return () => {
            document.removeEventListener("mousedown", closePopupHandler);
        };
    }, []);

    const columns: ColumnsType<OrderDeliveryItem> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "100px",
            render: (id: number) => <Link to={"/orders/" + id}>{id}</Link>,
        },
        {
            title: t("CLIENT.NAME"),
            dataIndex: "account",
            key: "account",
            width: "255px",
            render: ({ hasAgreements, id }: OrderAccount, order) => (
                <Observer>
                    {() => (
                        <Space style={{ whiteSpace: "nowrap", width: "auto" }} direction="horizontal" size={[5, 5]}>
                            {hasAgreements ? (
                                <div id={"popover-wrapper"} ref={ref as any}>
                                    <Popover
                                        // getPopupContainer={() => ref.current as any}
                                        content={
                                            store.agreements === null ? null : (
                                                <Space direction="vertical" size={[0, 0]}>
                                                    {store.agreements.map((item, index) => {
                                                        return (
                                                            <Space key={item.id} direction="vertical">
                                                                <Tag>{item.category ? t("AGREEMENT." + item.category.toUpperCase()) : ""}</Tag>
                                                                <span>{item.content}</span>
                                                                {store.agreements?.length! > 1 && index !== store.agreements?.length! - 1 ? (
                                                                    <Divider style={{margin: "0px 0 12px 0"}} type={"horizontal"}/>
                                                                ) : null}
                                                            </Space>
                                                        )
                                                    })}
                                                </Space>
                                            )
                                        }
                                        trigger="click"
                                        visible={store.agreementsModalShown && (store.agreementsAccountId === order.id)}
                                    >
                                        <OrderListWarningIcon
                                            fill="#fc3"
                                            style={{marginTop: 4, cursor: "pointer"}}
                                            onClick={() => store.openAgreementsModal(id, order.id)}
                                        />
                                    </Popover>
                                </div>

                            ) : null}
                            <Link to={"/clients/" + order.agent.number}>{order.agent.name}</Link>
                        </Space>
                    )}
                </Observer>
            ),
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            width: "125px",
            render: (item: OrderStatus, row) => row.warnings ? (
                <Space direction="horizontal" align={"start"}>
                    <OrderListWarningIcon
                        fill="#d33"
                        style={{marginTop: 2, cursor: "pointer"}}
                        onClick={() => store.openWarningsModal(row.id)}
                    />
                    <OrdersStatus status={item} />
                </Space>
            ) : <OrdersStatus status={item} />,
        },
        {
            title: t("CLIENT.NUMBER"),
            dataIndex: "clientOrderId",
            key: "clientOrderId",
        },{
            title: t("QUANTITY"),
            dataIndex: "itemsQty",
            key: "itemsQty",
        },
        {
            title: t("TOTAL_PRICE"),
            dataIndex: "subtotal",
            key: "subtotal",
            render: (amount: Amount) => (
                <span style={{ fontWeight: "bold" }}>
                        {displayPrice(amount)}
                    </span>
            ),
        },
        {
            title: "VAT",
            dataIndex: "subtotalVat",
            key: "subtotalVat",
            render: (amount: Amount) => (
                <span style={{ fontWeight: "bold" }}>
                        {displayPrice(amount)}
                    </span>
            ),
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "grandTotal",
            key: "grandTotal",
            render: (amount: Amount) => (
                <span style={{ fontWeight: "bold" }}>
                        {displayPrice(amount)}
                    </span>
            ),
        },
        {
            title: t("WEIGHT") + " (" + t("KG") + ")",
            dataIndex: "totalGrossWeight",
            key: "totalGrossWeight",
            width: 130,
            render: (totalGrossWeight: string, row) => row.totalNetWeight + " / " + totalGrossWeight
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            width: 150,
            render: (item: string) => <Date dateString={item} showTime />,
        },
    ];

    return (
        <Space direction="vertical" size={[0, 0]}>
            <Typography.Title level={3}>{t("ORDERS.LIST")}</Typography.Title>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={store.deliveryOrder?.orders}
                pagination={false}
                scroll={{x: true}}
                footer={() => (
                    <Row justify={"end"}>
                        <Col xxl={9} xl={10} md={12} sm={24} xs={24}>
                            <Descriptions
                                size={"small"}
                                layout={"horizontal"}
                                contentStyle={{fontWeight: "bold"}}
                                column={1}
                                className="table-footer-descriptions"
                            >
                                <Descriptions.Item label={t("QUANTITY")}>{store.deliveryOrder?.totalQty !== undefined ? store.deliveryOrder.totalQty : "UNDEFINED"}</Descriptions.Item>
                                <Descriptions.Item label={t("TOTAL_AMOUNT")}>{store.deliveryOrder?.totalPrice !== undefined ? displayPrice(store.deliveryOrder?.totalPrice) : "UNDEFINED"}</Descriptions.Item>
                                <Descriptions.Item label={t("TOTAL_WEIGHT")}>{store.deliveryOrder?.totalWeight !== undefined ? String(store.deliveryOrder?.totalWeight).replaceAll(".", ",") : "UNDEFINED"} ({t("KG")})</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                )}
            />
        </Space>
    )
})
