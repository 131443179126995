import React from "react";
import {Steps} from "antd";

export interface StepConfig<T = any> {
    title: string;
    description: string;
    component: React.FC<T>,
    componentProps?: T
}

interface ShipModalProps {
    current: number;
    steps: StepConfig[]
}

export const Stepper: React.FC<ShipModalProps> = ({current, steps}) => {

    const stepConfig = steps[current];
    if (!stepConfig) {
        return <></>
    }

    const Component = stepConfig.component;

    return <>
        <Steps current={current} size={"small"}>
            {steps.map((step, key) => <Steps.Step key={key} title={step.title} description={step.description}/>)}
        </Steps>
        <Component {...stepConfig.componentProps} />
    </>
}