import React from "react";
import {generateEditOrderItemsTableData,} from "../../../../models/orders/orders-cart/orders-create/orders-cart-create-helper/orders.cart.create.helper";
import { OrdersCheckoutTable } from "../../orders-create/checkout/orders-checkout-order/orders-checkout-table/orders.checkout.table";
import { observer } from "mobx-react";
import { useRequiredStore } from "../../../../utils/store";
import { OrderDataStoreContext } from "../order.data.store";
import { OrdersCheckoutTableFooter } from "../../orders-create/checkout/orders-checkout-order/orders.checkout.order.table.footer";
import { OrdersArticleModal } from "../../orders-create/article-modal/orders.article.modal";
import { Button, Typography } from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";

export const OrderDataEditItemsTable: React.FC = observer(() => {
    const store = useRequiredStore(OrderDataStoreContext);
    const t = useTranslate();

    return (
        <>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
                <Typography.Title style={{ marginRight: 10 }} level={3}>
                    {t("DETAILS")}
                </Typography.Title>
                <Button type="primary" onClick={async () => await store.hideEditItemsTable()}>
                    {t("DONE")}
                </Button>
            </div>
            <OrdersCheckoutTable
                order={store.order}
                loading={store.loading}
                useBackorder={store.order!.conditions.useBackorder}
                generateData={generateEditOrderItemsTableData}
                deleteItem={store.deleteOrderItem}
                getStockArticleAmount={store.getStockArticleAmount}
                updateOrderItem={store.updateOrderItem}
                footerRender={() => (
                    <OrdersCheckoutTableFooter
                        stockSummary={store.order!.stockSummary}
                        backorderSummary={store.order!.backorderSummary}
                        openAddRowModal={store.openAddModal}
                        vatPercent={store.order!.vatPercent}
                        orderDiscount={store.order!.discount}
                        updateDiscount={store.updateDiscount}
                    />
                )}
            />
            {store.addOrderItemModalShown ? (
                <OrdersArticleModal
                    orderId={store.order!.id}
                    onClose={store.closeAddModal}
                    searchArticle={store.searchArticle}
                    onSubmit={store.addToCart}
                />
            ) : null}
        </>
    );
});
