import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Modal, Table } from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { RootStoreContext } from "../../../../../stores/root/root.store";
import { useRequiredStore } from "../../../../../utils/store";
import { ArticleLockedStock, ArticleLockedStockItem } from "../../../../../models/articles/articles.locked-stock";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import Date from "../../../../date/date";
import { articlesLockedStockService } from "../../../../../services/articles/articles/articles.locked-stock.service";
import { useVT } from "virtualizedtableforantd4";
import { OrdersStatus } from "../../../../orders/status/order.status";

interface ArticlesLockedStockModalProps {
    item: ArticleLockedStock;
    onClose: () => void;
}
export const ArticlesLockedStockModal: React.FC<ArticlesLockedStockModalProps> = observer(({ item, onClose }) => {
    const t = useTranslate();
    const {
        authStore: { branch },
    } = useRequiredStore(RootStoreContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [items, setItems] = useState<ArticleLockedStockItem[]>([]);
    const y = 350;
    const [vt] = useVT(() => ({ scroll: { y }, debug: true }), [y]);

    useEffect(() => {
        if (item) {
            setLoading(true);
            articlesLockedStockService.getLockedStockItemsData(item.id).then((items) => {
                setItems(items);
                setLoading(false);
            });
        }
    }, [item]);

    const columns: ColumnsType<ArticleLockedStockItem> = [
        {
            title: t("WAREHOUSES.WAREHOUSE_TITLE"),
            dataIndex: "warehouseName",
            key: "warehouseName",
            width: 150,
        },
        {
            title: t("BRANCH_SINGLE"),
            dataIndex: "branchId",
            key: "branchId",
            width: 120,
            render: (branchId) => branchId.toUpperCase(),
        },
        {
            title: t("AGENT_TITLE"),
            dataIndex: "agentName",
            key: "agentName",
            render: (agent) => (!!agent ? agent : "**********"),
        },
        {
            title: t("ORDERS.ORDER.TITLE"),
            dataIndex: "orderId",
            key: "orderId",
            render: (order, row) => (row.branchId !== branch ? order : <Link to={"/orders/" + order}>{order}</Link>),
        },
        {
            title: t("ORDERS.STATUS.TITLE"),
            dataIndex: "orderStatus",
            key: "orderStatus",
            render: (status) => <OrdersStatus status={status} />,
        },
        {
            title: t("LOCKED_STOCK"),
            dataIndex: "lockedAmount",
            key: "lockedAmount",
        },
        {
            title: t("DATE"),
            dataIndex: "date",
            key: "date",
            width: 120,
            render: (date) => (date ? <Date dateString={date} /> : "-"),
        },
    ];

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("ARTICLES.LOCKED_STOCK_DETAILS") + " " + item.articleName}
            okText={t("OK")}
            cancelText={t("CLOSE")}
            onOk={onClose}
            onCancel={onClose}
            width={1200}
        >
            <Table
                rowKey="orderId"
                loading={loading}
                columns={columns}
                dataSource={items}
                pagination={false}
                components={vt}
                scroll={{ y, x: true }}
            />
        </Modal>
    );
});
