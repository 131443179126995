import {DebounceSelect} from "../../../common/debounce-select/debounce.select";
import {SearchedUser} from "../../../../models/users/user/searched-user/searched.user";
import {userService} from "../../../../services/users/user.service";
import {UserContacts} from "../../../../models/users/user/extended.user";
import {useTranslate} from "../../../../hooks/translate.hook";
import React, {PropsWithChildren} from "react";

const searchUsers = async (value: string): Promise<any> => {
    const response: SearchedUser[] = await userService.searchUser(value, "employee");
    return response.map((item: UserContacts) => ({
        value: item.email,
        label: item.firstName + " " + item.lastName + " (" + item.email + ")",
        key: item.id,
    }));
}

interface UserSelectProps {
    disabled?: boolean;
    value?: string;
    onSelect?: (value: string) => void;
    onClear?: () => void;
    style?: React.CSSProperties;
    labelInValue?: boolean;
}

export const UserSelect: React.FC<PropsWithChildren<UserSelectProps>> = (props) => {

    const t = useTranslate();

    return <DebounceSelect
        allowClear
        placeholder={t("USER.SELECT")}
        style={{ width: "100%" }}
        fetchOptions={searchUsers}
        {...props}
    />
}