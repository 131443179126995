import React, {MouseEventHandler, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {Error, ErrorRelationType} from "../../../models/errors/error";
import {agentDataService} from "../../../services/agents/agent.data.service";
import {Typography} from "antd";
import {useNavigate} from "react-router";


const errorLinkConfig: Record<ErrorRelationType, (error: Error) => string> = {
    order: error => "/orders/" + error.relationId,
    integration: error => "/"
}

interface ErrorsListLinkProps {
    error: Error
}
export const ErrorsListLink: React.FC<ErrorsListLinkProps> = ({error}) => {
    const getPath = errorLinkConfig[error.relationType];
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const onIntegrationLinkClick: MouseEventHandler<HTMLAnchorElement> = (event): void => {
        event.preventDefault();
        setLoading(true);
        agentDataService.getIntegration(error.relationId).then(integration => {
            const link = "/clients/" + integration.account.agent.number + "/accounts";
            setLoading(false);
            navigate(link);
        });
    }

    if (error.relationId === "") {
        return <>(none)</>
    }

    if (!getPath) {
        return <span>{error.relationType + "_" + error.relationId}</span>
    }

    if (error.relationType === "integration") {
        return (
            <Typography.Link disabled={loading} onClick={onIntegrationLinkClick}>{error.relationType + "_" + error.relationId}</Typography.Link>
        )
    }

    return (
        <Link to={getPath(error)}>{error.relationType + "_" + error.relationId}</Link>
    )
};
