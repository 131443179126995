import { Space, Typography } from "antd";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { ComponentPropsFromRoute } from "../../../routers/routers";
import { LoadingSpin } from "../../common/loading-spin/loading.spin";
import { RouteTabs } from "../../common/route-tabs/route.tabs";
import { BranchDataStore, BranchDataStoreContext } from "./branch.data.store";
import { useRequiredStore } from "../../../utils/store";
import { RootStoreContext } from "../../../stores/root/root.store";

interface BranchDataProps extends ComponentPropsFromRoute {}

export const BranchData: React.FC<BranchDataProps> = observer(({ routeChildren }) => {
    const { branchId } = useParams<{ branchId: string }>();
    const { pageStore } = useRequiredStore(RootStoreContext);
    const match = useLocation();

    const [store] = useState(() => new BranchDataStore(branchId!, pageStore, match.pathname));

    if (store.loading) {
        return <LoadingSpin />;
    }

    return (
        <BranchDataStoreContext.Provider value={store}>
            <Space size={[10, 10]} direction={"vertical"}>
                <Typography.Title level={1}>{store.branch?.name}</Typography.Title>
                <RouteTabs
                    // removeLastParam
                    // withoutRootTabPath
                    customSpliceNum={5}
                    routes={routeChildren!}
                />
            </Space>
        </BranchDataStoreContext.Provider>
    );
});
