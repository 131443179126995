import React from "react";
import {observer} from "mobx-react";
import {Alert, Row, Space, Table, Typography} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../utils/store";
import {OrdersCreateStoreContext} from "../orders.create.store";
import {OrdersCartCalculatedPrice} from "../../../../../models/orders/orders-cart/orders-create/orders.cart";
import {useVT} from "virtualizedtableforantd4";
import {
    displayCustomPrice, generateCartMissingItemsTableDataItem,
} from "../../../../../models/orders/orders-cart/orders-create/orders-cart-create-helper/orders.cart.create.helper";
import {displayPrice} from "../../../../../models/prices/price.helper";
import {Amount} from "../../../../../models/prices/price";
import { Link } from "react-router-dom";
import {AgentDataStoreContext} from "../../../../agents/agent-data/agent.data.store";
import {ColumnsType} from "antd/es/table";
import {PricesLegend} from "../../../../common/prices-legend/prices.legend";


export const OrdersCreateMissingItems = observer(() => {
    const t = useTranslate();
    const agentDataStore = useRequiredStore(AgentDataStoreContext);
    const store = useRequiredStore(OrdersCreateStoreContext);
    const columns: ColumnsType<any> = [
        {
            title: t("ARTICLES.NAME"),
            dataIndex: "article_name",
            key: "article_name",
            width: "110px"
        },
        {
            title: t("ARTICLES.DESCRIPTION"),
            dataIndex: "article_description",
            key: "articleDescription",
            width: "300px"
        },
        {
            title: t("PRICE"),
            dataIndex: "price",
            key: "price",
            align: "right",
            width: "150px",
            render: (item: OrdersCartCalculatedPrice | undefined) => item ? displayCustomPrice(item, t("PRICES." + item.type.toUpperCase())) : null
        },
        {
            title: t("QUANTITY"),
            dataIndex: "quantity",
            key: "quantity",
            width: "100px"
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "total_amount",
            key: "total_amount",
            width: "150px",
            render: (item: Amount | undefined) => item ? <>{displayPrice(item)}</> : null
        }
    ];

    const y = 500;
    const [vt] = useVT(() => ({scroll: {y}, debug: true}), [y]);

    return (
        <Space direction="vertical" size={[20, 20]}>
            <Alert message={t("ORDER.CREATED.WITH_MISSING_ITEMS")} type="warning"/>
            <Link
                to={"/clients/" + agentDataStore.account?.agent.number + "/cart/checkout"}
                className="ant-btn ant-btn-primary"
            >
                {t("ORDER.PROCEED_WITH_CHECKOUT")}
            </Link>
            <Typography.Title level={4} style={{margin: 0}}>{t("MISSING_ITEMS")}</Typography.Title>
            <Table
                rowKey={"id"}
                columns={columns}
                components={vt}
                dataSource={store.missingItems.map(item => generateCartMissingItemsTableDataItem(item))}
                loading={store.loading}
                scroll={{x: true, y}}
                pagination={false}
            />
            <Link
                to={"/clients/" + agentDataStore.account?.agent.number + "/cart/checkout"}
                className="ant-btn ant-btn-primary"
            >
                {t("ORDER.PROCEED_WITH_CHECKOUT")}
            </Link>
        </Space>
    );
})
