import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {WidgetProps} from "../../../../../common/ui/input/widget/widget";
import {propertyService} from "../../../../../../services/property/property.service";
import {PropertyType} from "../../../../../../models/property/property";

export const UIInputWidgetPropertyType: React.FC<WidgetProps> = ({value, onChange, multiple}) => {

    const [types, setTypes] = useState<PropertyType[]>([]);

    useEffect(() => {
        propertyService.getAllTypes().then(types => {
            setTypes(types);
        });
    }, []);

    return (
        <Select
            mode={multiple ? "multiple" : undefined}
            onChange={onChange as any}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
        >
            {types.map(type =>
                <Select.Option key={String(type.id)} value={type.id}>{type.name}</Select.Option>)}
        </Select>
    )
}