import {Button, Space} from "antd";
import {OrdersNew} from "../../../orders-new/orders.new";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {NewOrder} from "../../../../../models/orders/orders-new/orders.new";
import {ordersListService} from "../../../../../services/orders/orders-list/orders.list.service";
import {LoadingSpin} from "../../../../common/loading-spin/loading.spin";
import dayjs from "dayjs";

export const OrdersListNew: React.FC = () => {
    const t = useTranslate();

    const [orders, setOrders] = useState<NewOrder[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [lastLoadedAt, setLastLoadedAt] = useState<string>("");

    const reload = () => {
        ordersListService.getNewOrders().then(orders => {
            setOrders(orders)
            setLoading(false)
            setLastLoadedAt(dayjs().format())
        });
    }

    useEffect(() => {
        reload();
        const interval = setInterval(() => {
            reload();
        }, 60000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    if (loading) {
        return <LoadingSpin />
    }

    return (
        <Space direction="vertical" size={[0, 0]}>
            <OrdersNew
                newOrders={orders}
                lastReloadedAt={lastLoadedAt}
                actions={row => <>
                    <Link to={"/clients/" + row.agentNumber + "/documents/new-sale-document"}>
                        <Button style={{ marginRight: 5 }} type="primary" size="small">
                            {t("ORDERS.CREATE_SALE_DOCUMENT")}
                        </Button>
                    </Link>
                </>}
            />
        </Space>
    )
}