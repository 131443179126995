import React, {useEffect, useState} from "react";
import {Space, Tabs} from "antd";
import {SelectCourierStepAuto} from "./select-courier/select-courier-auto";
import {SelectCourierStepManually} from "./select-courier/select-courier-manually";
import {Courier} from "../../../../../models/parcels/shipment";
import {shipmentsService} from "../../../../../services/shipments/shipments.service";

export const SelectCourierStep: React.FC = () => {

    const [couriers, setCouriers] = useState<Courier[]>([]);

    useEffect(() => {
        shipmentsService.getCouriers().then(couriers => setCouriers(couriers));
    }, [])

    if (0 === couriers.length) {
        return <>Loading...</>;
    }

    return <Space direction="vertical" size={[15, 15]}>
        <Tabs>
            <Tabs.TabPane tab={"Auto"} key={1}>
                <SelectCourierStepAuto couriers={couriers} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={"Manually"} key={2}>
                <SelectCourierStepManually couriers={couriers} />
            </Tabs.TabPane>
        </Tabs>
    </Space>
}