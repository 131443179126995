import React, {useMemo, useState} from "react";
import {Button, Col, Form, Row, Select, Space} from "antd";
import {ShipContext} from "../../ship-modal";
import {preShipmentsService} from "../../../../../../services/shipments/pre-shipments.service";
import {Courier} from "../../../../../../models/parcels/shipment";
import {ProductFields} from "../../additional-fields/product-fields";
import {LoadUnloadFields} from "../../additional-fields/load-unload-fields";
import {config} from "../../config";
import {IncotermsFields} from "../../additional-fields/incoterms-fields";
import {PickupDateFields} from "../../additional-fields/pickup-date-fields";

interface SelectCourierStepAutoProps {
    couriers: Courier[];
}

export const SelectCourierStepAuto: React.FC<SelectCourierStepAutoProps> = ({couriers}) => {

    const c = React.useContext(ShipContext);

    const [form] = Form.useForm();

    const courierList = useMemo(() => couriers.filter(c => c.automated), [couriers]);

    const [loading, setLoading] = useState<boolean>(false);

    return <Space direction="vertical" size={[15, 15]}>
        <Form
            form={form}
            layout="vertical"
            initialValues={{courier: courierList[0].id}}
            onFinish={values => {
                form.validateFields().then(async () => {
                    setLoading(true);
                    try {

                        const data: Record<string, any> = {courier: values.courier};
                        const usedConfig = config[values.courier];
                        if (usedConfig) {
                            if (usedConfig.useLoadUnload) {
                                data.loadData = {
                                    // load
                                    loadType       : values.loadType,
                                    loadFromHour   : parseInt(values.loadTimeFrom?.format('H') || 0),
                                    loadFromMinute : parseInt(values.loadTimeFrom?.format('m') || 0),
                                    loadToHour     : parseInt(values.loadTimeTo?.format('H') || 0),
                                    loadToMinute   : parseInt(values.loadTimeTo?.format('m') || 0),
                                    loadNotes      : values.loadNotes || '',
                                    // unload
                                    unloadType       : values.unloadType,
                                    unloadFromHour   : parseInt(values.unloadTimeFrom?.format('H') || 0),
                                    unloadFromMinute : parseInt(values.unloadTimeFrom?.format('m') || 0),
                                    unloadToHour     : parseInt(values.unloadTimeTo?.format('H') || 0),
                                    unloadToMinute   : parseInt(values.unloadTimeTo?.format('m') || 0),
                                    unloadNotes      : values.unloadNotes || '',
                                }
                                if (values.loadDate) {
                                    data.loadData.loadDate = values.loadDate.format('YYYY-MM-DD');
                                }
                                if (values.unloadDate) {
                                    data.loadData.unloadDate = values.unloadDate.format('YYYY-MM-DD');
                                }
                            }
                            if (usedConfig.usePickupDate) {
                                data.loadData = {
                                    loadDate: values.loadDate.format('YYYY-MM-DD')
                                }
                            }
                            if (usedConfig.useProduct) {
                                data.product = values.product;
                            }
                            if (usedConfig.useIncoterms) {
                                data.incoterms = values.incoterms;
                            }
                        }

                        c.onComplete(await preShipmentsService.batchShip(c.selectedDocuments, c.parcels, data, c.address!));
                    } finally {
                        setLoading(false);
                    }
                })
            }}
        >
            <Space direction="vertical" size={[15, 15]}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="courier"
                            label="Courier"
                        >
                            <Select disabled={loading}>
                                {courierList.map(courier =>
                                    <Select.Option value={courier.id}>{courier.name}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    noStyle={true}
                    shouldUpdate={(o, n) => o.courier !== n.courier}
                >
                    {() => (
                        <>
                            <ProductFields loading={loading} courierName={form.getFieldValue('courier')} />
                            <IncotermsFields loading={loading} courierName={form.getFieldValue('courier')} />
                            <LoadUnloadFields loading={loading} courierName={form.getFieldValue('courier')} />
                            <PickupDateFields loading={loading} courierName={form.getFieldValue('courier')} />
                        </>
                    )}
                </Form.Item>
            </Space>
            <Space direction="horizontal" size={[15, 15]}>
                <Button type={"primary"} onClick={() => form.submit()} disabled={loading}>Create shipment</Button>
                <Button type={"default"} onClick={() => c.setStep(2)} disabled={loading}>Go back</Button>
            </Space>
        </Form>
    </Space>
}