import React, {useEffect, useMemo, useState} from "react";
import {Alert, Modal, Table} from "antd";
import {sourcesService} from "../../../../../../services/documents/common/sources.service";
import {columnsFactory} from "./compare.columns";

interface CompareModalProps {
    sourceId: number;
    onClose: () => void
}

export const CompareModal: React.FC<CompareModalProps> = ({sourceId, onClose}) => {

    const [ready, setReady] = useState<boolean>(false);
    const [diffs, setDiffs] = useState<Record<string, any>[]>([]);
    const columns = useMemo(() => columnsFactory(), [])

    useEffect(() => {
        sourcesService.compare(sourceId).then(v => {setDiffs(v); setReady(true)})
    }, [sourceId])

    return <Modal
        title={"Compare source with document"}
        visible={true}
        onCancel={onClose}
        width={"900px"}
        footer={false}
        bodyStyle={{padding: 5}}
    >
        {ready ? (
            <>
                {diffs.length > 0 ? (
                    <Table
                        dataSource={diffs}
                        columns={columns}
                        pagination={false}
                    />
                ) : (
                    <Alert
                        banner
                        showIcon
                        type={"success"}
                        message={"OK"}
                        description={"File and document are the same"}
                    />
                )}
            </>
        ) : (
            <>Loading...</>
        )}
    </Modal>
}