import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";
import {
    Announcement,
    AnnouncementRequest,
    PageAnnouncement
} from "../../models/announcements/announcement";

export class AnnouncementsService {
    api: AxiosInstance = API;

    getAnnouncements(request: PaginatedRequest): Promise<PaginatedResult<Announcement>> {
        return this.api.get<PaginatedResult<Announcement>>(
            "announcements",
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }

    createAnnouncement(request: AnnouncementRequest): Promise<Announcement> {
        return this.api.post<Announcement>("announcements", request).then(x => x.data);
    }

    editAnnouncement(request: AnnouncementRequest, id: number): Promise<Announcement> {
        return this.api.post<Announcement>("announcements/" + id, request).then(x => x.data);
    }

    removeAnnouncement(id: number) {
        return this.api.delete("announcements/" + id);
    }

    getPageAnnouncement(pageName: string): Promise<PageAnnouncement[]> {
        return this.api.get<PageAnnouncement[]>("announcements/page/" + pageName).then(x => x.data);
    }
}


export const announcementsService = new AnnouncementsService();
