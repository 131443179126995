import {SmartColumn} from "../../common/smart-table/smart-table.model";
import {FieldType} from "../../common/smart-table/smart-table.field-config";
import {Translate} from "../../../hooks/translate.hook";

export const columnsFactory = (t: Translate): SmartColumn<Event>[] => {
    return [
        {
            name: 'name',
            visibleName: t('NAME'),
            type: FieldType.STRING
        }
    ]
}