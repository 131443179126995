import React, { useState } from "react";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import { useRequiredStore } from "../../../../utils/store";
import { RootStoreContext } from "../../../../stores/root/root.store";
import {useTranslate} from "../../../../hooks/translate.hook";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { Warehouse } from "../../../../models/warehouses/warehouse";
import { Amount } from "../../../../models/prices/price";
import { displayPrice } from "../../../../models/prices/price.helper";
import Date from "../../../date/date";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
import { AgentDataStoreContext } from "../agent.data.store";
import { FilterKey } from "../../../../models/filtered-table/filtered.table.props";
import { OrderStatus } from "../../../../stores/common/common.store";
import { Order } from "../../../../models/orders/orders-list/order";
import { OrdersStatus } from "../../../orders/status/order.status";
import { AgentDataOrdersStore } from "./agent.data.orders.store";
import { isAllowed } from "../../../../utils/helpers";
import { Alert, Button, Dropdown, Menu, Row, Space } from "antd";
import { OrdersListDeliveryOrderModal } from "../../../orders/list/delivery-order-modal/orders.list.delivery.order.modal";
import { observer } from "mobx-react";
import { OrdersListProformaModal } from "../../../orders/list/proforma-modal/orders.list.proforma.modal";
import {useNavigate} from "react-router";

interface AgentDataOrdersProps extends ComponentPropsFromRoute {}
export const AgentDataOrders: React.FC<AgentDataOrdersProps> = observer(({ name }) => {
    const {
        commonStore: { warehouses, orderStatuses },
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);
    const agentDataStore = useRequiredStore(AgentDataStoreContext);
    const t = useTranslate();
    const navigate = useNavigate();
    const [store] = useState(() => new AgentDataOrdersStore(agentDataStore.account!.id!, navigate));

    const allowedActions = store.actions.filter((action) => isAllowed(attributes, action.access));

    const columns: ColumnsType<Order> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "100px",
            render: (id: number) => <Link to={"/orders/" + id}>{id}</Link>,
        },
        {
            title: t("WAREHOUSES.WAREHOUSE_TITLE"),
            dataIndex: "warehouse",
            key: "warehouse",
            render: (item: Warehouse) => <>{item.name}</>,
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            render: (item: OrderStatus) => <OrdersStatus status={item} />,
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "grandTotal",
            key: "grandTotal",
            render: (item: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(item)}</span>,
        },
        {
            title: t("CLIENT.NUMBER"),
            dataIndex: "clientOrderId",
            key: "clientOrderId",
            width: "160px",
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            width: 150,
            render: (item: string) => <Date dateString={item} showTime />,
        },
    ];

    const columnsConfigs: { key: string; title: string }[] = [
        {
            title: "ID",
            key: "id",
        },
        {
            title: "INVOICES.NUMBER",
            key: "invoice",
        },
        {
            title: "WAREHOUSES.WAREHOUSE_TITLE",
            key: "warehouse",
        },
        {
            title: "STATUS",
            key: "status",
        },
        {
            title: "TOTAL_AMOUNT",
            key: "grandTotal",
        },
        {
            title: "CREATED_AT",
            key: "createdAt",
        },
    ];

    const filters: FilterKey[] = [
        {
            key: "invoice",
            label: t("INVOICES.NUMBER"),
            type: "string",
        },
        {
            key: "agentName",
            label: t("CLIENT.NAME"),
            type: "string",
        },
        {
            key: "warehouses",
            label: t("WAREHOUSES.ID"),
            type: "select",
            options: warehouses.map(({ name, id }) => ({
                value: id,
                label: name,
            })),
        },
        {
            key: "status",
            label: t("ORDERS.STATUS.TITLE"),
            type: "select",
            options: orderStatuses.map((item) => ({
                value: item,
                label: t("ORDERS.ORDER.STATUS." + item.toUpperCase()),
            })),
        },
        {
            key: "grandTotal",
            label: t("TOTAL_AMOUNT"),
            type: "int",
        },
        {
            key: "clientOrderId",
            label: t("ORDERS.ALT_NUMBER"),
            type: "string",
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date",
        },
    ];

    const rowSelection = {
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys: React.Key[], selectedRows: Order[]) => {
            store.checkOrders(selectedRowKeys);
        },
        getCheckboxProps: (record: Order) => ({
            name: String(record.id),
            // disabled: record.dispatched
        }),
    };

    return (
        <Space direction="vertical" size={[25, 25]}>
            <Row justify="end">
                {allowedActions.length > 0 ? (
                    <Dropdown
                        placement="bottomRight"
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                {allowedActions.map((action) => (
                                    <Menu.Item
                                        key={action.key}
                                        disabled={action.isDisabled()}
                                        onClick={action.onAction}
                                    >
                                        {t(action.text)}
                                    </Menu.Item>
                                ))}

                                {allowedActions.length > 0 ? (
                                    <Menu.Item
                                        key={"clear_all"}
                                        disabled={store.checkedOrders.length === 0}
                                        onClick={store.clearAllCheckedOrders}
                                    >
                                        {t("CLEAR_ALL")}
                                    </Menu.Item>
                                ) : null}
                            </Menu>
                        }
                    >
                        <Button type="primary">{t("ACTION")}</Button>
                    </Dropdown>
                ) : null}
            </Row>
            <Space direction="vertical" size={[0, 0]}>
                {allowedActions.length > 0 ? (
                    <Alert
                        message={t("ORDERS.PROFORMA.LENGTH") + ": " + store.checkedOrders.length}
                        type="info"
                        style={{ marginBottom: 20 }}
                    />
                ) : null}
                <FilteredTable
                    columns={columns}
                    columnsConfigs={{ columns: columnsConfigs, pageKey: name }}
                    filterKeys={filters}
                    dataHandler={store.dataHandler}
                    rowSelection={
                        allowedActions.length > 0
                            ? {
                                  type: "checkbox",
                                  selectedRowKeys: [...store.checkedOrders],
                                  ...rowSelection,
                              }
                            : undefined
                    }
                />
            </Space>
            {store.createProformaModalShown ? (
                <OrdersListProformaModal
                    orders={store.dataHandler.items}
                    checkedOrdersIds={store.checkedOrders}
                    onAddToProforma={store.addOrdersToProforma}
                    description={
                        <>
                            <span style={{ fontWeight: "bold" }}>{t("ORDERS.PROFORMA.INCLUDED_ORDERS")}</span>:{" "}
                            {store.checkedOrders.join(", ")}
                        </>
                    }
                    onSubmit={store.createProforma}
                    onClose={store.closeCreateProformaModal}
                />
            ) : null}

            <OrdersListDeliveryOrderModal
                checkedOrders={store.checkedOrders as number[]}
                visible={store.deliveryOrderModalShown}
                onOk={store.handleDeliveryModalSubmit}
                onClose={store.closeDeliveryOrderModal}
            />
        </Space>
    );
});
