import React from "react";
import {observer} from "mobx-react";
import {Button, Descriptions, Dropdown, Menu, notification, Row, Space, Tooltip, Typography} from "antd";
import {Link} from "react-router-dom";
import {useRequiredStore} from "../../../../../utils/store";
import {MergedInvoiceDataStoreContext} from "../merged.invoice.data.store";
import Date from "../../../../date/date";
import {Export} from "../../../../common/export/export";
import {MergedInvoiceStatus} from "../../../../../models/documents/invoices/merged-invoice/merged-invoice-data/merged.invoice.data";
import {MergedInvoiceDataConfirmModal} from "./merged.invoice.data.confirm.modal";
import {RecalcWeightModal} from "../../../../common/recalc-weight-modal/recalc.weight.modal";
import {DocumentsStatus} from "../../../../common/documents/status/documents.status";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MergedInvoiceEditModal} from "../merged-invoice-edit-modal/merged-invoice-edit-modal";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";
import {useNavigate} from "react-router";
import {MergedInvoicesChangeDateModal} from "../../merged-invoices-change-date-modal/merged-invoices.change-date.modal";

export const MergedInvoiceDataHeader: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(MergedInvoiceDataStoreContext);
    const navigate = useNavigate();

    const openNotification = (type: "success" | "error", message: string): void => {
        notification[type]({
            message: t(type.toUpperCase()),
            description: t(message),
            duration: 2,
        });
    };

    return (
        <>
            <PageHeader
                className="invoice-header"
                style={{padding: 0}}
                ghost={false}
                title={
                    <Typography.Title
                        level={1}
                        style={{margin: 0}}
                    >
                        {t("INVOICE.MERGED.TITLE") + " " + store.invoice?.docNumber}
                    </Typography.Title>
                }
                onBack={() => {
                    navigate("/merged-invoices");
                }}
                extra={<DocumentsStatus large status={store.invoice?.status!} />}
            >
                <Space direction="vertical" size={[15, 15]}>

                    <Row justify={"space-between"}>
                        <Space
                            wrap
                            className="invoice-data-header-buttons"
                            direction="horizontal"
                            size={[20, 20]}
                        >
                            <Dropdown trigger={["click"]} overlay={
                                <Menu>
                                    <Menu.Item key={0}>
                                        <a
                                            style={{textAlign: "left"}}
                                            target="_blank"
                                            className={"ant-btn ant-btn-link"}
                                            href={"/documents/" + store.invoice!.id}
                                        >
                                            {t("DOCUMENTS.FILES.SHOW_PDF")}
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item
                                        key={1}
                                        onClick={() => store.handleModal("export", true)}
                                    >
                                        {t("ORDERS.ORDER.DOWNLOAD_CSV_INVOICE")}
                                    </Menu.Item>
                                    <Menu.Item key={2}>
                                        <a
                                            style={{textAlign: "left"}}
                                            target="_blank"
                                            className={"ant-btn ant-btn-link"}
                                            href={"/api/v3/documents/merged-invoices/" + store.invoice!.id + "/e-arve"}
                                        >
                                            {t("ORDERS.ORDER.DOWNLOAD_E_INVOICE")}
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key={3}>
                                        <a
                                            style={{textAlign: "left"}}
                                            target="_blank"
                                            className={"ant-btn ant-btn-link"}
                                            href={"/api/v3/documents/merged-invoices/" + store.invoice!.id + "/customs"}
                                        >
                                            {t("ORDERS.ORDER.DOWNLOAD_CUSTOMS_INVOICE")}
                                        </a>
                                    </Menu.Item>
                                </Menu>
                            }
                            >
                                <Button> {t("ORDERS.ORDER.VIEW.INVOICE")}</Button>
                            </Dropdown>

                            {store.invoice?.customTotalGrossWeight ? (
                                <a
                                    href={"/api/v3/documents/merged-invoices/" + store.invoice?.id + "/export-invoice"}
                                >
                                    <Button type={"primary"}>
                                        {t("ORDERS.ORDER.VIEW.GENERATE_EXPORT_INVOICE")}
                                    </Button>                                </a>
                            ) : null}
                        </Space>
                        <Dropdown
                            placement={"bottomRight"}
                            trigger={["click"]}
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key={1}
                                    >
                                        {store.invoice!.status === MergedInvoiceStatus.CLOSED ? (
                                            <Button
                                                type="link"
                                                style={{color: "inherit"}}
                                                onClick={() => store.unconfirm(openNotification)}
                                            >
                                                {t("INVOICES.UNCONFIRM.ACTION")}
                                            </Button>
                                        ) : (
                                            <Button
                                                type="link"
                                                style={{color: "inherit"}}
                                                onClick={store.openConfirmModal}
                                            >
                                                {t("INVOICES.CONFIRM.ACTION")}
                                            </Button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item
                                        key={2}
                                    >
                                        <Button
                                            type="link"
                                            style={{color: "inherit"}}
                                            onClick={() => store.handleModal("editWeight", true)}
                                        >
                                            {t("RECALCULATE_WEIGHT.TITLE")}
                                        </Button>
                                    </Menu.Item>
                                    <Menu.Item key={3}>
                                        <Button
                                            type="link"
                                            style={{color: "inherit"}}
                                            onClick={() => store.handleModal("setDate", true)}
                                        >
                                            {t("INVOICES.SET-DATE.TITLE")}
                                        </Button>
                                    </Menu.Item>
                                    <Menu.Item key={4}>
                                        <Button
                                            type="link"
                                            style={{color: "inherit"}}
                                            onClick={() => store.sendDocumentToClient()}
                                        >
                                            {t("DOCUMENTS.SEND_TO_CLIENT")}
                                        </Button>
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button loading={store.deleteButtonDisabled} type={"primary"}>{t("ACTION")}</Button>
                        </Dropdown>

                    </Row>

                    <div className="data-descriptions-with-edit-wrapper">
                        <div className="data-content-wrapper">
                            <Descriptions
                                className="data-content-wrapper-descriptions"
                                size={"small"}
                                layout={"vertical"}
                                contentStyle={{fontSize: "17px"}}
                                labelStyle={{color: "#858585"}}
                                column={{xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1}}
                            >
                                {/*<Descriptions.Item label={t("ORDERS.ORDER.NUMBER")}>*/}
                                {/*    <Link*/}
                                {/*        className="link"*/}
                                {/*        to={"/orders/" + store.invoice?.masterOrder.id}*/}
                                {/*    >*/}
                                {/*        {store.invoice?.masterOrder.id}*/}
                                {/*    </Link>*/}
                                {/*</Descriptions.Item>*/}
                                <Descriptions.Item label={t("CLIENT.NUMBER")}>
                                    <Link
                                        className="link"
                                        to={"/clients/" + store.invoice?.agent?.number}
                                    >
                                        {store.invoice?.agent.number} ({store.invoice?.agent.name})
                                    </Link>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={t("DATE")}
                                >
                                    <Date dateString={store.invoice!.date} showTime withSeconds/>
                                </Descriptions.Item>
                                <Descriptions.Item label={t("PAYMENT_TERMS")}>
                                    {store.invoice?.paymentTerms}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("SHIPMENT_CONDITION")}>
                                    {store.invoice?.shipmentCondition}
                                </Descriptions.Item>
                                {/*<Tooltip placement="topRight" title={t("EDIT")}>*/}
                                {/*    <Button*/}
                                {/*        className="data-description-btn btn"*/}
                                {/*        onClick={() => store.handleModal("edit", true)}*/}
                                {/*        type="default"*/}
                                {/*        icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}*/}
                                {/*    />*/}
                                {/*</Tooltip>*/}
                                {store.invoice?.customTotalGrossWeight ? (
                                    <Descriptions.Item label={t("CUSTOM_WEIGHT")}>
                                        {store.invoice?.customTotalGrossWeight} {t("KG")}
                                    </Descriptions.Item>
                                ) : null}
                            </Descriptions>
                            <Tooltip placement="topRight" title={t("EDIT")}>
                                <Button
                                    className="data-description-btn btn"
                                    onClick={() => store.handleModal("edit", true)}
                                    type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </Space>
            </PageHeader>
            <MergedInvoiceEditModal
                visible={store.editModalShown}
                onClose={() => store.handleModal("edit", false)}
            />
            <RecalcWeightModal
                visible={store.editWeightModalShown}
                defaultWeight={store.invoice?.totalGrossWeight}
                onClose={() => store.handleModal("editWeight", false)}
                onSubmit={store.recalcWeight}
            />
            <MergedInvoicesChangeDateModal
                invoice={store.invoice!}
                visible={store.setDateModalShown}
                onClose={() => store.handleModal("setDate", false)}
                onSuccess={() => store.reload()}
            />
            {store.invoice ? (
                <Export
                    title={"ORDERS.ORDER.DOWNLOAD_CSV_INVOICE"}
                    isShown={store.exportModalShown}
                    withBasicExportFields
                    config={{
                        // "/documents/order/" + store.invoice!.order.id + "/invoice?token=" + token
                        link: "/api/v3/documents/merged-invoices/" + store.invoice!.id,
                    }}
                    onClose={() => store.handleModal("export", false)}
                />
            ) : null}
            <MergedInvoiceDataConfirmModal visible={store.confirmModalShown} onClose={store.closeConfirmModal}/>
            {/*<MergedInvoiceDataHeaderModal visible={store.editModalShown}*/}
            {/*                              onClose={() => store.handleModal("edit", false)}/>*/}
        </>

    );
})
