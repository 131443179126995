import React from "react";
import {PlannerTask, PlannerTaskSystemStatus} from "../../../../../../../models/planner/planner";
import {PlannerTasksViewAction} from "../task.view.action";
import {Action} from "../../model";
import {plannerService} from "../../../../../../../services/planner/planner.service";

export const PlannerTasksViewToProgressAction: Action = ({task, reload}) => {

    const run = async () => {
        await plannerService.toProgress(task.id);
        reload();
    }

    return (
        <PlannerTasksViewAction
            title={"TO_PROGRESS"}
            run={run}
        />
    )
}

PlannerTasksViewToProgressAction.isApplicable = (task: PlannerTask) => {
    return [
        PlannerTaskSystemStatus.TODO,
        PlannerTaskSystemStatus.ON_REVIEW,
        PlannerTaskSystemStatus.DONE,
        PlannerTaskSystemStatus.CANCELLED,
    ].includes(task.status);
}