import React from "react";
import {observer} from "mobx-react";
import {Space, Typography} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {Logger} from "../../../common/logger/logger";

export const MyPageActivity: React.FC = observer(() => {
    const {authStore: {user}} = useRequiredStore(RootStoreContext);
    const t = useTranslate();
    return (
        <Space direction={"vertical"} size={[0, 0]}>
            <Typography.Title level={3}>{t("MY_PAGE.ACTIVITY")}</Typography.Title>
            <Logger additionalFilters={{user: user?.id}}/>
        </Space>
    );
});
