import React from 'react';
import {Upload, Button} from "antd";
import { UploadOutlined } from '@ant-design/icons';

export interface UIInputWidgetFileProps {
    value?: File;
    onChange?: (value: File) => void;
}

export const UIInputWidgetFile: React.FC<UIInputWidgetFileProps> = ({value, onChange}) => {
    return (
        <Upload
            beforeUpload={() => false}
            multiple={false}
            onChange={e => onChange ? onChange(e.file as any) : () => null}
            maxCount={1}
        >
            <Button
                icon={<UploadOutlined />}
            >
                Upload
            </Button>
        </Upload>
    )
};