import React, {useEffect} from "react";
import {RouteTabs} from "../../common/route-tabs/route.tabs";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {Row, Space, Typography} from "antd";
import {useTranslate} from "../../../hooks/translate.hook";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";

export const ShipmentsMain: React.FC<ComponentPropsFromRoute> = ({ name, routeChildren }) => {

    const location = useLocation();
    const navigate  = useNavigate();

    const t = useTranslate();

    const {authStore} = useRequiredStore(RootStoreContext);

    useEffect(() => {
        if (location.pathname === "/shipments") {
            if (authStore.department?.type === "retail") {
                navigate("/shipments/retail");
            } else {
                navigate("/shipments/wholesale")
            }
        }
    }, [location.pathname])

    return (
        <Space direction="vertical" size={[0, 0]}>
            <Row justify={"space-between"}>
                <Typography.Title level={3}>{t("SHIPMENTS.TITLE")}</Typography.Title>
            </Row>
            <RouteTabs routes={routeChildren!} />
        </Space>
    )
}