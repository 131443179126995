import React from "react";
import {WidgetProps} from "../widget";
import {UIInputWidgetWarehouse} from "../../../../../ui/input/widget/warehouse/ui.input.widget.warehouse";
export const WarehouseWidget: React.FC<WidgetProps> = ({onChange, value, multiple}) => {

    return (
        <UIInputWidgetWarehouse
            onChange={onChange as any}
            value={value as any}
            multiple={multiple}
        />
    )
}