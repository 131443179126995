import { AxiosInstance } from "axios";
import API, {BlobAndFilename, getBlobAndFileName} from "../../utils/api";
import {
    ReportCategory, ReportConfig, ReportResult,
} from "../../models/reports/reports";
import { PaginatedRequest } from "../../models/core/paginated.request";
import { PaginatedResult } from "../../models/core/paginated.result";
import {ArticleStockQuery} from "../../models/reports/article-stock/article-stock";

export class ReportsService {
    private api: AxiosInstance = API;

    getCategories(): Promise<Record<string, ReportCategory[]>> {
        return this.api
            .get("reports/categories")
            .then((x) => x.data);
    }

    getConfig(name: string): Promise<ReportConfig> {
        return this.api
            .get("reports/report/" + name + "/config")
            .then((x) => x.data);
    }

    getHistory(reportName: string, request: PaginatedRequest): Promise<PaginatedResult<ReportResult>> {
        return this.api
            .get("reports/report/" + reportName + "/history", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }

    getResult(id: number): Promise<ReportResult> {
        return this.api
            .get("reports/result/" + id)
            .then((x) => x.data);
    }

    download(id: number): Promise<BlobAndFilename> {
        return this.api
            .get("reports/result/" + id + "/download", {responseType: "blob"})
            .then(getBlobAndFileName);
    }

    getData(name: string, request: PaginatedRequest): Promise<PaginatedResult<any>> {
        return this.api
            .get("reports/report/" + name + "/data", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }

    getArticleStocks(query: ArticleStockQuery): Promise<any[]> {
        return this.api
            .get<any[]>("reports/article-stock", {
                params: query,
            })
            .then((x) => x.data);
    }
}
export const reportsService = new ReportsService();
