import React, {CSSProperties} from "react";
import {observer} from "mobx-react";
import {Space, Tag} from "antd";
import "./prices.legend.scss";
import {useTranslate} from "../../../hooks/translate.hook";

interface PricesLegendProps {
    style?: CSSProperties
}
export const PricesLegend: React.FC<PricesLegendProps> = observer(({style}) => {
    const t = useTranslate();
    return (
        <Space style={style ? style : undefined} direction={"horizontal"} size={[10, 10]} wrap>
            <div>
                <Tag className="price-tag" color={"gold"}>CC$</Tag> - <span>{t("PRICES.CLIENT_PRICE")}</span>
            </div>
            <div>
                <Tag className="price-tag" color={"red"}>M$</Tag> - <span>{t("PRICES.MINIMUM_PRICE")}</span>
            </div>
            <div>
                <Tag className="price-tag" color={"red"}>C$</Tag> - <span>{t("PRICES.CUSTOM_PRICE")}</span>
            </div>
            <div>
                <Tag className="price-tag" color={"red"}>C%</Tag> - <span>{t("PRICES.CUSTOM_DISCOUNT")}</span>
            </div>
        </Space>
    );
})
