import {SmartColumn} from "../../../common/smart-table/smart-table.model";
import {PropertyDocumentItem, PropertyInventoryTaskPart} from "../../../../models/property/property";
import {Translate} from "../../../../hooks/translate.hook";
import {FieldType} from "../../../common/smart-table/smart-table.field-config";
import {Link} from "react-router-dom";
import {SystemUser} from "../../../../models/users/user/system.user";

export const columnFactory = (t: Translate): SmartColumn<PropertyDocumentItem>[] => {
    return [
        {
            name: 'number',
            visibleName: t('NUMBER'),
            dataIndex: 'number',
            type: FieldType.STRING,
            filterable: true
        },
        {
            name: 'date',
            visibleName: t('DATE'),
            dataIndex: 'date',
            type: FieldType.DATETIME,
            filterable: true
        },
        {
            name: 'accountant',
            visibleName: t('USER.TITLE'),
            dataIndex: 'accountant',
            type: FieldType.CUSTOM,
            render: (u: SystemUser) => u.firstName + ' ' + u.lastName,
        },
        {
            name: 'taskPart',
            visibleName: t('PROPERTY.INVENTORY_TASK.TITLE'),
            type: FieldType.CUSTOM,
            dataIndex: 'taskPart',
            render: (part: PropertyInventoryTaskPart) => {
                if (part) {
                    return <Link to={"/property/inventory-tasks/part/" + part.id + "/write-off"}>{part.task.year}</Link>
                }
                return '-';
            }
        },
    ]
}