import React, {useState} from "react";
import {Button, Input, Form, Space, Checkbox} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../utils/store";
import {OrderDataStoreContext} from "../../order.data.store";

import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../../models/core/response";


export const OrderDataCommentsForm: React.FC = () => {
    const t = useTranslate();
    const store = useRequiredStore(OrderDataStoreContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [rowsNum, setRowsNum] = useState<number>(1);
    const [form] = Form.useForm();

    return (
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            name={"order_comment_form"}
            onFinish={() => form
                .validateFields()
                .then(values => {
                    setLoading(true);
                    store.makeComment(values)
                        .then(
                            () => {
                                form.resetFields();
                                setRowsNum(1);
                            },
                            (e: AxiosError<ErrorResponseData>) => {
                                setLoading(false);
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                        .then(() =>
                            (e: any) => form.setFields(e.response?.data?.fields))
                        .finally(() => setLoading(false));
                })
            }
        >
            <Form.Item
                noStyle
                name="message"
                rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
            >
                <Input.TextArea
                    rows={rowsNum}
                    onFocus={() => setRowsNum(5)}
                    onBlur={(event) => {
                        if (!event.target.value.trim()) {
                            setRowsNum(1);
                        }
                    }}
                />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={true}>
                {({getFieldValue}) => getFieldValue("message") ? (
                        <Form.Item style={{marginTop: 10}} name={"userVisibility"} valuePropName={"checked"}>
                            <Checkbox>{t("COMMENTS.USER_VISIBLE")}</Checkbox>
                        </Form.Item>
                    )
                    : null
                }
            </Form.Item>
            <Form.Item noStyle shouldUpdate={true}>
                {({getFieldValue}) => getFieldValue("message") ? (
                        <Form.Item>
                            <Button htmlType="submit" loading={loading} type="primary">
                                {t("COMMENTS.ADD")}
                            </Button>
                        </Form.Item>
                    )
                    : null
                }
            </Form.Item>
        </Form>
    );
};
