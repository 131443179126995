import React, { RefObject, useRef } from "react";
import { FieldsProps } from "../filter.popup";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import { Form, Select } from "antd";
import { FieldChangeCallback } from "../../../../../../models/filter/filter.field.type.config";
import { FieldChoice } from "../../../../../../models/filter/filter.group";

interface SelectFieldsProps extends FieldsProps {
    shoudlDefaultOpen?: boolean;
    choices: FieldChoice[];
    onChange: FieldChangeCallback;
}
export const SelectFields: React.FC<SelectFieldsProps> = ({
    popupRef,
    config,
    choices,
    shoudlDefaultOpen = false,
    onChange,
}) => {
    const t = useTranslate();
    const inputRef = useRef() as RefObject<any>;

    return (
        <Form.Item
            className={"filterValueSelector"}
            name={["fields", config.name, "values"]}
            rules={[
                {
                    required: true,
                    message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                },
            ]}
        >
            <Select
                defaultOpen={shoudlDefaultOpen}
                autoFocus={shoudlDefaultOpen}
                ref={inputRef}
                mode={config.multiple ? "multiple" : undefined}
                placeholder={t("FILTER.SELECT_FIELD")}
                showSearch
                optionFilterProp={"children"}
                getPopupContainer={() => popupRef.current as HTMLElement}
                onChange={(value: any) => {
                    onChange(config, value);
                }}
                disabled={choices.length === 0}
            >
                {choices.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                        {item.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};
