import {Alert, Form, Modal} from "antd";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import React from "react";
import {
    CustomerAccountAddress
} from "../../../../../../models/accounts/customer-account/address/customer.account.address";
import {CardSelect} from "../../../../../common/ui/form/card-select/card-select";

interface SelectAddressModalProps {
    addresses: CustomerAccountAddress[];
    useAs: string;
    onClose: () => void;
    onComplete: (address: number) => void;
}

export const SelectAddressModal: React.FC<SelectAddressModalProps> = ({addresses, useAs, onClose, onComplete}) => {

    const t = useTranslate();
    const [form] = Form.useForm();

    return (
        <Modal
            visible={true}
            width={1100}
            style={{ top: 20 }}
            title={t("Select " + useAs + " address")}
            okText={t("SUBMIT")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Alert
                type={"warning"}
                message={"Please select " + useAs + " address. Address will be applied to all selected orders"}
                style={{marginBottom: 30}}
            />

            <Form
                size={"middle"}
                form={form}
                layout="vertical"
                name={"client_form"}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        onComplete(values.address);
                        onClose();
                    })
                }
            >
                <Form.Item
                    name="address"
                    label={t("ADDRESS")}
                    rules={[{required: true, message: t('FROM.ERROR.PLEASE_SELECT_VALUE')}]}
                    style={{marginBottom: 0}}
                >
                    <CardSelect
                        data={addresses}
                        valueKey={"id"}
                        titleKey={"city"}
                        rowKeys={[
                            {key: "companyName", label: "COMPANY_NAME"},
                            {key: "firstName"},
                            {key: "lastName"},
                            {key: "vat", label: "VAT.TITLE"},
                            {key: "customsCode", label: "EORI"},
                            {key: "regCode", label: "REG_NUMBER"},
                            {key: "country.name", label: "COUNTRY"},
                            {key: "region"},
                            {key: "city"},
                            {key: "index"},
                            {key: "address"}
                        ]}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}