import React, { useState } from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Form,
    Modal,
    Row,
    Col,
    Button,
    DatePicker,
    Divider,
    Tooltip,
} from "antd";
import { AxiosError } from "axios";
import { ErrorResponseData } from "../../../../../../models/core/response";
import { useRequiredStore } from "../../../../../../utils/store";
import { AgentDataThresholdsStoreContex } from "../agent.data.thresholds.store";
import { NumberInput } from "../../../../../common/number-input/number.input";

export const AgentDataThresholdsModal: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(AgentDataThresholdsStoreContex);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const onSubmit = store.modalItem ? store.editThreshold : store.addThreshold;

    const defaultValues = !store.modalItem
        ? { thresholds: [{ threshold: undefined, percentage: 0 }] }
        : {
              thresholds: [
                  {
                      threshold: store.modalItem.threshold.value,
                      percentage: store.modalItem.percentage,
                  },
                  ...store.modalItem.children.map(
                      ({ threshold, percentage }) => ({
                          threshold: threshold.value,
                          percentage,
                      })
                  ),
              ],
          };

    return (
        <Modal
            visible={store.modalShown}
            confirmLoading={loading}
            title={t(
                store.modalItem
                    ? "CLIENT.THRESHOLDS.EDIT"
                    : "CLIENT.THRESHOLDS.ADD"
            )}
            okText={t(store.modalItem ? "EDIT" : "ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                store.closeModal();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"contacts_form"}
                initialValues={defaultValues}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        onSubmit(values)
                            .then(
                                () => {
                                    form.resetFields();
                                    return store.closeModal();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(
                                            e.response?.data?.fields
                                        );
                                    }
                                }
                            )
                            .then(
                                () => {},
                                (e) => form.setFields(e.response?.data?.fields)
                            )
                            .finally(() => setLoading(false));
                    })
                }
            >
                {!store.modalItem ? (
                    <Form.Item
                        name="year"
                        label={t("YEAR")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                            },
                        ]}
                    >
                        <DatePicker.YearPicker style={{ width: "100%" }} />
                    </Form.Item>
                ) : null}
                <Form.List
                    name="thresholds"
                    rules={[
                        {
                            validator: async (rule, names) => {
                                try {
                                    if (!names || names.length === 0) {
                                        return Promise.reject(
                                            t("FROM.ERROR.PLEASE_ADD_VALUE")
                                        );
                                    }
                                } catch (error) {
                                    throw new Error(error as string);
                                }
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Row
                                    justify="space-between"
                                    gutter={[10, 5]}
                                    key={field.key}
                                >
                                    <Col span={fields.length > 1 ? 11 : 12}>
                                        <Form.Item
                                            {...field}
                                            label={
                                                index === 0
                                                    ? t("THRESHOLD")
                                                    : null
                                            }
                                            name={[field.name, "threshold"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t(
                                                        "FROM.ERROR.PLEASE_ENTER_VALUE"
                                                    ),
                                                },
                                            ]}
                                        >
                                            <NumberInput />
                                        </Form.Item>
                                    </Col>
                                    <Col span={fields.length > 1 ? 11 : 12}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, "percentage"]}
                                            label={
                                                index === 0
                                                    ? t("PERCENTAGE")
                                                    : null
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t(
                                                        "FROM.ERROR.PLEASE_ENTER_VALUE"
                                                    ),
                                                },
                                            ]}
                                        >
                                            <NumberInput />
                                        </Form.Item>
                                    </Col>
                                    {fields.length > 1 ? (
                                        <Col span={2}>
                                            <Tooltip
                                                placement="topRight"
                                                title={t("DELETE")}
                                            >
                                                <Button
                                                    disabled={loading}
                                                    type="default"
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                    icon={
                                                        <FontAwesomeIcon
                                                            icon={[
                                                                "fas",
                                                                "times",
                                                            ]}
                                                        />
                                                    }
                                                    danger
                                                    style={{
                                                        width: "100%",
                                                        marginTop:
                                                            index === 0
                                                                ? 30
                                                                : 0,
                                                    }}
                                                    className="btn-flex-center"
                                                />
                                            </Tooltip>
                                        </Col>
                                    ) : null}
                                    {fields.length > 1 &&
                                    index !== fields.length - 1 ? (
                                        <Col span={24}>
                                            <Divider
                                                style={{
                                                    margin: "0 0 20px 0",
                                                }}
                                                type="horizontal"
                                            />
                                        </Col>
                                    ) : null}
                                </Row>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    disabled={loading}
                                    onClick={() => add()}
                                    block
                                    icon={
                                        <FontAwesomeIcon
                                            icon={["fas", "plus-circle"]}
                                        />
                                    }
                                >
                                    {t("ADD")}
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
});
