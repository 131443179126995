import React, {useEffect, useState} from "react";
import {WidgetProps} from "../../../../../../common/smart-table/filter/dropdown/widget/widget";
import {Select} from "antd";
import {PropertyType} from "../../../../../../../models/property/property";
import {propertyService} from "../../../../../../../services/property/property.service";

export const PropertyTypeWidget: React.FC<WidgetProps> = ({onChange, value}) => {

    const [values, setValues] = useState<PropertyType[]>([]);

    useEffect(() => {
        propertyService.getAllTypes().then(values => {
            setValues(values);
        });
    }, []);

    return (
        <Select
            mode={"multiple"}
            onChange={onChange as any}
            value={value.map(v => parseInt(v as string))}
            style={{width: '250px'}}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
        >
            {values.map(v =>
                <Select.Option key={v.id} value={v.id}>{v.name}</Select.Option>)}
        </Select>
    )
}