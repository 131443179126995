import React, {useEffect, useState} from "react";
import {WidgetProps} from "../widget";
import {Select} from "antd";
import {eventsService} from "../../../../../../services/calendar/events.service";
import {Event} from "../../../../../../models/calendar/calendar";

export const UiInputWidgetUpcomingEvent: React.FC<WidgetProps> = ({value, onChange, multiple}) => {

    const [events, setEvents] = useState<Event[]>([]);

    useEffect(() => {
        eventsService.getUpcomingEvents().then(events => setEvents(events))
    }, [])

    return (
        <Select
            mode={multiple ? "multiple" : undefined}
            onChange={onChange as any}
            value={value as any}
            style={{width: '250px'}}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
        >
            {events.map(event =>
                <Select.Option key={event.id} value={event.id}>{event.name}</Select.Option>)}
        </Select>
    )
}