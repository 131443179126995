import React, {useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Col, Form, Input, Modal, Row} from "antd";
import {DeclarationsDeclarant} from "../../../../../models/declarations/declarations.declarants";
import {declarationsDeclarantsService} from "../../../../../services/declarations/declarations.declarants.service";

export interface DeclarationDeclarantEditModalProps {
    item: DeclarationsDeclarant | null;
    onClose(): void;
}

export const DeclarationDeclarantEditModal: React.FC<DeclarationDeclarantEditModalProps> = ({item, onClose}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const initialValues: any = {};
    if (item) {
        initialValues.firstName = item.firstName;
        initialValues.lastName = item.lastName;
        initialValues.isikCode = item.isikCode;
        initialValues.city = item.city;
        initialValues.email = item.email;
        initialValues.phone = item.phone;
    }

    return (
        <Modal
            open={true}
            confirmLoading={loading}
            title={t("DECLARATIONS.DECLARANTS")}
            okText={item ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => onClose()}
        >
            <Form
                form={form}
                layout="vertical"
                name={"address_form"}
                initialValues={initialValues}
                onFinish={() =>
                    form.validateFields().then(async (values) => {
                        setLoading(true);
                        try {
                            await (item
                                    ? declarationsDeclarantsService.update(item.id, values)
                                    : declarationsDeclarantsService.create(values)
                            );
                            onClose();
                        }
                        catch (e: any) {
                            if (e.response?.data?.fields) {
                                form.setFields(e.response?.data?.fields);
                            }
                            setLoading(false);
                        }
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="firstName"
                            label={t("FIRSTNAME")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                placeholder={t("FIRSTNAME")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="lastName"
                            label={t("LASTNAME")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                placeholder={t("LASTNAME")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="isikCode"
                            label={"Isikukood"}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                placeholder={"isikCode"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="email"
                            label={t("EMAIL")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                placeholder={"email"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="phone"
                            label={t("PHONE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                placeholder={"phone"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="city"
                            label={t("CITY")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                placeholder={"city"}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
