import React, { useState } from "react";
import { observer } from "mobx-react";
import {
    Button,
    Form,
    Popconfirm,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import { Link } from "react-router-dom";
import { OrdersCheckoutTableItem } from "../../../../../../models/orders/orders-cart/orders-checkout/orders.checkout.table";
import {displayCustomPrice } from "../../../../../../models/orders/orders-cart/orders-create/orders-cart-create-helper/orders.cart.create.helper";
import { Amount } from "../../../../../../models/prices/price";
import { displayPrice } from "../../../../../../models/prices/price.helper";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import { NotCompletedOrder } from "../../../../../../models/orders/orders-cart/orders-checkout/orders.checkout.not_completed.order";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditableCell } from "../../../create/table/editable-cell/editable.cell";
import { maxAmount } from "../../../../../../utils/validators";
import { OrdersCartCalculatedPrice } from "../../../../../../models/orders/orders-cart/orders-create/orders.cart";
import {OrdersUpdateRequest} from "../../../../../../models/orders/order-update/order.update";

interface OrdersCheckoutTableProps {
    order: any;
    loading: boolean;
    useBackorder: boolean;
    deleteItem: (orderId: number, itemId: number) => Promise<void>;
    getStockArticleAmount: (articleId: number, order: NotCompletedOrder) => Promise<{stock: number}>;
    updateOrderItem:(itemId: string | number, requestValues: OrdersUpdateRequest, orderId: number) => Promise<void>;
    generateData: (order: any) => OrdersCheckoutTableItem[],
    footerRender: () => React.ReactNode;
}
export const OrdersCheckoutTable: React.FC<OrdersCheckoutTableProps> = observer(({ order, loading, deleteItem, getStockArticleAmount, updateOrderItem, generateData, footerRender, useBackorder}) => {
        const t = useTranslate();
        const [form] = Form.useForm();
        const [editingKey, setEditingKey] = useState<string | number>("");
        const [stockAmount, setStockAmount] = useState<number | undefined>(
            undefined
        );

        const getRowTextColor = (row: OrdersCheckoutTableItem) => row.valid === undefined || row.valid ? undefined : "#bdbdbd";

        const isRowDisabled = (row: OrdersCheckoutTableItem): boolean =>
            !!(!row.quantity && row.id);

        const columns: any[] = [
            {
                title: t("ARTICLES.TITLE_SINGLE"),
                dataIndex: "articleName",
                key: "articleName",
                width: "110px",
                render: (name: string, row: OrdersCheckoutTableItem) =>
                    !row.valid ? (
                        <span
                            style={{
                                whiteSpace: "nowrap",
                                color: getRowTextColor(row),
                            }}
                        >
                            {name}
                        </span>
                    ) : name.toUpperCase() !== "DELIVERY" ? (
                        <Link to={"/articles/" + name}>{name}</Link>
                    ) : name,
            },
            {
                title: t("DESCRIPTION"),
                dataIndex: "articleDescription",
                key: "articleDescription",
                width: "300px",
                render: (description: string, row: OrdersCheckoutTableItem) =>
                    row.valid === undefined || row.valid ? (
                        description
                    ) : (
                        <Space direction="vertical" size={[5, 5]}>
                            <Typography.Text
                                style={{ color: "#bdbdbd" }}
                                delete
                            >
                                {description}
                            </Typography.Text>
                            <Tag color={"error"}>
                                {t("ARTICLES.ARTICLE_NOT_AVAILABLE")}
                            </Tag>
                        </Space>
                    ),
            },
            {
                title: t("PRICE"),
                dataIndex: "price",
                key: "price",
                align: "right",
                width: "130px",
                editable: true,
                validationRules: [
                    {
                        required: true,
                        message: t("FROM.ERROR.ENTER_VALUE"),
                    },
                ],
                render: (
                    item: OrdersCartCalculatedPrice | undefined,
                    row: OrdersCheckoutTableItem
                ) =>
                    item ? (
                        <span
                            className={
                                isRowDisabled(row) ? "disabled" : undefined
                            }
                        >
                            {displayCustomPrice(
                                item,
                                t("PRICES." + item.type.toUpperCase())
                            )}
                        </span>
                    ) : null,
            },
            {
                title: t("QUANTITY"),
                dataIndex: "quantity",
                key: "quantity",
                width: "120px",
                editable: true,
                hint:
                    useBackorder ? undefined : t("MAX_AMOUNT_SHORT") + " " + stockAmount + " " + t("PCS"),
                validationRules: [
                    {
                        validator: maxAmount(stockAmount!, t),
                    },

                    {
                        required: true,
                        message: t("FROM.ERROR.ENTER_VALUE"),
                    },
                ],
                render: (item: string, row: OrdersCheckoutTableItem) => (
                    <span style={{ color: getRowTextColor(row), borderBottom: '1px dotted #000000' }}>
                        <Tooltip title={"Stock Qty: " + row.stockQty + ", Back Qty: " + row.backOrderedQty}>
                            {item}
                        </Tooltip>
                    </span>
                ),
            },
            {
                title: t("TOTAL_AMOUNT"),
                dataIndex: "totalPrice",
                key: "totalPrice",
                width: "150px",
                render: (
                    item: Amount | undefined,
                    row: OrdersCheckoutTableItem
                ) =>
                    item ? (
                        <span style={{ color: getRowTextColor(row) }}>
                            {displayPrice(item)}
                        </span>
                    ) : null,
            },
            {
                title: t("SALE_PERCENTAGE"),
                key: "salePercentage",
                dataIndex: "price",
                width: "90px",
                render: (
                    item: OrdersCartCalculatedPrice | undefined,
                    row: OrdersCheckoutTableItem
                ) =>
                    item ? (
                        <span>
                            {item.salePercentage || "0"}%
                        </span>
                    ) : null,
            },
            {
                title: t("ACTION"),
                dataIndex: "action",
                key: "action",
                width: "100px",
                render: (_: any, record: OrdersCheckoutTableItem) => {
                    const editable = isEditing(record);
                    if (record.articleName) {
                        return (
                            <Space
                                style={{ width: "auto" }}
                                direction="horizontal"
                                size={[5, 5]}
                            >
                                {editable ? (
                                    <Tooltip
                                        placement="topRight"
                                        title={t("SAVE")}
                                    >
                                        <Button
                                            className="table-btn btn"
                                            onClick={() => save(record)}
                                            type="default"
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={["fas", "check"]}
                                                />
                                            }
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        placement="topRight"
                                        title={t("EDIT")}
                                    >
                                        <Button
                                            className="table-btn btn"
                                            disabled={editingKey !== ""}
                                            onClick={() => edit(record)}
                                            type="default"
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={["fas", "pencil-alt"]}
                                                />
                                            }
                                        />
                                    </Tooltip>
                                )}

                                <Popconfirm
                                    title={t("ARE_YOU_SURE")}
                                    onConfirm={() =>
                                        deleteItem(
                                            order.id,
                                            record.id
                                        )
                                    }
                                    placement="left"
                                    okText={t("YES")}
                                    cancelText={t("NO")}
                                    disabled={
                                        editingKey !== "" ||
                                        order.items.length === 1
                                    }
                                >
                                    <Tooltip
                                        placement="topRight"
                                        title={t("REMOVE")}
                                    >
                                        <Button
                                            className="table-btn btn btn-remove "
                                            disabled={
                                                editingKey !== "" ||
                                                order.items.length === 1
                                            }
                                            type="default"
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={["fas", "times"]}
                                                />
                                            }
                                        />
                                    </Tooltip>
                                </Popconfirm>
                            </Space>
                        );
                    }

                    return <></>;
                },
            },
        ];

        const isEditing = (record: OrdersCheckoutTableItem) =>
            record.id === editingKey;

        const edit = async (
            record: OrdersCheckoutTableItem & { id: React.Key }
        ) => {
            if (useBackorder) {
                setStockAmount(-1);
            } else {
                const {stock: stockAmount} = await getStockArticleAmount(
                    record.articleId,
                    order
                );
                setStockAmount(stockAmount + record.quantity);
            }

            // record[]
            form.setFieldsValue({
                price: record.price.amount.value,
                quantity: record.quantity,
            });

            setEditingKey(record.id);
        };

        const save = async (record: OrdersCheckoutTableItem) => {
            try {
                const row = await form.validateFields();
                // quantity
                if (
                    record.price.amount.value !== parseFloat(row.price) ||
                    record.quantity !== parseInt(row.quantity, 10)
                ) {
                    await updateOrderItem(
                        record.id,
                        {
                            customAmountValue: row.price,
                            qty: row.quantity,
                        },

                        order.id
                    );
                }
                setEditingKey("");
            } catch (errInfo) {}
        };

        const mergedColumns = columns.map((col: any) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record: OrdersCheckoutTableItem) => ({
                    record,
                    inputType: "text",
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    hint: col.hint,
                    rules: col.validationRules || [],
                }),
            };
        });

        return (
            <Form form={form} component={false}>
                <Table
                    rowKey="id"
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    dataSource={[...generateData(order)]}
                    loading={loading}
                    scroll={{ x: true, y: 500 }}
                    virtual
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    pagination={false}
                    footer={footerRender}
                />
            </Form>
        );
    }
);
