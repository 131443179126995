import React, {useState} from "react";
import {ComponentPropsFromRoute} from "../../../../../../../routers/routers";
import {PhotoCapture} from "../../../../../../common/photo-capture/photo-capture";
import {Button, Space, Typography} from "antd";
import {useOutletContext} from "react-router-dom";
import {ReviewStepContext} from "../review.step.context";
import {useNavigate} from "react-router";

export const ReviewTakePhotoStep: React.FC<ComponentPropsFromRoute> = () => {

    const [blob, setBlob] = useState<Blob | null>();
    const ctx = useOutletContext<ReviewStepContext>();
    const navigate = useNavigate();

    const onReady = () => {
        if (blob) {
            ctx.blob = blob;
            navigate('/property/review/inventory-task/' + ctx.task.id +'/review/' + ctx.property.property.id + '/status');
        }
    }

    const onTakeOtherPhoto = () => {
        setBlob(null)
    }

    if (blob) {
        return <Space direction={"vertical"}>
            <Typography.Title level={1} style={{marginBottom: 0}}>Please check the photo quality</Typography.Title>
            <img width={"100%"} src={URL.createObjectURL(blob)}  alt={"Photo"} />
            <Space direction={"horizontal"}>
                <Button onClick={onReady} type={"primary"}>Use this photo</Button>
                <Button onClick={onTakeOtherPhoto} danger>Take another photo</Button>
            </Space>
        </Space>
    }

    return <>
        <Typography.Title level={1} style={{marginBottom: 0}}>Please take a photo of property</Typography.Title>
        <Typography.Paragraph>Just click to image</Typography.Paragraph>
        <PhotoCapture onCaptured={blob => setBlob(blob)} />
    </>
}