import {FilterButtonConfig} from "../../../models/filtered-table/filtered.table.props";

export enum Filters {
    ALL = "all",
    POTENTIAL = "potential",
    ACTIVE = "active",
    CANCELLED = "cancelled",
    INACTIVE = "inactive",
    SUSPENDED_SHIPMENT = "suspended_shipment",
}

export const filterButtonConfigs: FilterButtonConfig<Filters>[] = [
    {
        type: Filters.ALL,
        text: "ALL",
        filters: [],
    },
    {
        type: Filters.ACTIVE,
        text: "CLIENT.ACTIVE",
        filters: [
            {
                name: "statuses",
                snapshots: [
                    {
                        name: "statuses",
                        rule: undefined,
                        value: 'active',
                    },
                ],
            },
        ],
    },
    {
        type: Filters.POTENTIAL,
        text: "CLIENT.POTENTIAL",
        filters: [
            {
                name: "statuses",
                snapshots: [
                    {
                        name: "statuses",
                        rule: undefined,
                        value: 'potential',
                    },
                ],
            },
        ],
    },
    {
        type: Filters.CANCELLED,
        text: "CLIENT.CANCELLED",
        filters: [
            {
                name: "statuses",
                snapshots: [
                    {
                        name: "statuses",
                        rule: undefined,
                        value: 'cancelled',
                    },
                ],
            },
        ],
    },
    {
        type: Filters.INACTIVE,
        text: "CLIENT.INACTIVE",
        filters: [
            {
                name: "statuses",
                snapshots: [
                    {
                        name: "statuses",
                        rule: undefined,
                        value: 'inactive',
                    },
                ],
            },
        ],
    },
    {
        type: Filters.SUSPENDED_SHIPMENT,
        text: "CLIENT.SUSPENDED_SHIPMENT",
        filters: [
            {
                name: "statuses",
                snapshots: [
                    {
                        name: "statuses",
                        rule: undefined,
                        value: 'suspended_shipment',
                    },
                ],
            },
        ],
    }
]
