import React from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import Date from "../../../date/date";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import {warehousesService} from "../../../../services/warehouses/warehouses.service";
import {Branch} from "../../../../models/branches/branch";
import {Typography} from "antd";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {FilterKey} from "../../../../models/filtered-table/filtered.table.props";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";

interface WarehousesListProps extends ComponentPropsFromRoute{}
export const WarehousesList: React.FC<WarehousesListProps> = observer(({name}) => {
    const t = useTranslate();

    const {
        commonStore: { branches },
    } = useRequiredStore(RootStoreContext);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id"
        },
        {
            title: t("WAREHOUSES.NAME"),
            dataIndex: "name",
            key: "name"
        },
        {
            title: t("WAREHOUSES.AGENCY_NAME"),
            dataIndex: "branch",
            key: "branch",
            render: (item: Branch) => <>{item.name}</>
        },
        {
            title: t("1C.NAME"),
            dataIndex: "ocName",
            key: "ocName"
        },
        {
            title: t("1C.CODE"),
            dataIndex: "ocCode",
            key: "ocCode"
        },
        {
            title: t("WAREHOUSES.STATUS"),
            dataIndex: "status",
            key: "status"
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (item: string) => <Date dateString={item} showTime={true}/>
        },
        {
            title: t("UPDATED_AT"),
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (item: string) => <Date dateString={item} showTime={true}/>
        }
    ];

    const columnsConfigs = [
        {
            title: "ID",
            key: "id"
        },
        {
            title: "WAREHOUSES.NAME",
            key: "name"
        },
        {
            title: "WAREHOUSES.AGENCY_NAME",
            key: "branch",
        },
        {
            title: "1C.NAME",
            key: "ocName"
        },
        {
            title: "1C.CODE",
            key: "ocCode"
        },
        {
            title: "WAREHOUSES.STATUS",
            key: "status"
        },
        {
            title: "CREATED_AT",
            key: "createdAt",
        },
        {
            title: "UPDATED_AT",
            key: "updatedAt",
        }
    ]

    const filters: FilterKey[] = [
        {
            key: "id",
            label: "ID",
            type: "string"
        },
        {
            key: "name",
            label: t("WAREHOUSES.NAME"),
            type: "string"
        },
        {
            key: "branch",
            label: t("WAREHOUSES.AGENCY_NAME"),
            type: "select",
            options: branches.map(({ id, name }) => ({ value: id, label: name })),
        },
        {
            key: "ocName",
            label: t("1C.NAME"),
            type: "string",
        },
        {
            key: "ocCode",
            label: t("1C.CODE"),
            type: "string"
        },
        {
            key: "status",
            label: t("WAREHOUSES.STATUS"),
            type: "select",
            options: [{value: "active", label: "Active"}, {value: "inactive", label: "Inactive"}]
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date"
        },
        {
            key: "updatedAt",
            label: t("UPDATED_AT"),
            type: "date"
        }
    ];


    return (
        <>
            <Typography.Title level={3}>{t("WAREHOUSES.LIST")}</Typography.Title>
            <FilteredTable columnsConfigs={{columns: columnsConfigs, pageKey: name}} columns={columns} filterKeys={filters} loadDataCallback={r => warehousesService.getAll(r)}/>
        </>
    );

});
