import React from "react";
import {WidgetProps} from "./widget";
import {DatePicker} from "antd";
import dayjs from "dayjs";
export const DateRangeWidget: React.FC<WidgetProps> = ({onChange, value}) => {

    let pickerValue: any = [];
    if (value.length === 1) {
        pickerValue.push(
            pickerValue = String(value[0]).split('--').map(v => dayjs(v))
        )
    }

    return (
        <DatePicker.RangePicker
            defaultOpen
            style={{ width: "100%" }}
            format="DD-MM-YYYY"
            inputReadOnly
            onChange={v => onChange([v ? v.map(t => t?.format('YYYY-MM-DD')).join("--") : ""])}
            value={pickerValue}
        />
    )
}