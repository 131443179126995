import {SmartColumn} from "../../../common/smart-table/smart-table.model";
import {Property, PropertyLocation} from "../../../../models/property/property";
import {FieldType} from "../../../common/smart-table/smart-table.field-config";
import {Translate} from "../../../../hooks/translate.hook";
import {PropertyType} from "../common/property-type/property-type";
import {PropertyTypeWidget} from "../../ui/smart-table/filter/widget/property-type/property-type.widget";
import {PropertyLocationWidget} from "../../ui/smart-table/filter/widget/property-location/property-location.widget";
import {ResponsiblePersonWidget} from "../../ui/smart-table/filter/widget/responsible-person/responsible-person.widget";

export const columnFactory = (t: Translate): SmartColumn<Property>[] => {
    return [
        {
            name: 'code',
            visibleName: t('PROPERTY.PROPERTY.CODE'),
            type: FieldType.OBJECT,
            options: {t: 'property', f: 'id'},
            filterable: true
        },
        {
            name: 'type',
            visibleName: t('PROPERTY.PROPERTY.TYPE'),
            type: FieldType.STRING_ARRAY,
            render: (t) => <PropertyType type={t} />,
            filterable: true,
            widget: PropertyTypeWidget
        },
        {
            name: 'description',
            visibleName: t('PROPERTY.PROPERTY.DESCRIPTION'),
            type: FieldType.STRING,
            filterable: true
        },
        {
            name: 'qty',
            visibleName: t('PROPERTY.PROPERTY.QTY'),
            type: FieldType.INTEGER,
            filterable: true
        },
        {
            name: 'supplier',
            visibleName: t('PROPERTY.PROPERTY.SUPPLIER'),
            type: FieldType.STRING,
            filterable: true
        },
        {
            name: 'purchaseDate',
            visibleName: t('PROPERTY.PROPERTY.PURCHASE_DATE'),
            type: FieldType.DATETIME,
            filterable: true
        },
        {
            name: 'responsiblePerson',
            visibleName: t('PROPERTY.PROPERTY.RESPONSIBLE_PERSON'),
            type: FieldType.INTEGER_ARRAY,
            render: v => v.firstName + ' ' + v.lastName,
            filterable: true,
            widget: ResponsiblePersonWidget
        },
        {
            name: 'location',
            visibleName: t('PROPERTY.PROPERTY.LOCATION'),
            type: FieldType.STRING_ARRAY,
            filterable: true,
            render: (v: PropertyLocation) => '(' + v.code + ') ' + v.name,
            widget: PropertyLocationWidget
        },
        {
            name: 'createdAt',
            visibleName: t('CREATED_AT'),
            type: FieldType.DATETIME,
            filterable: true
        }
    ]
}