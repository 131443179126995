import React, {useEffect, useState} from "react";
import {Button, Modal, Space, Spin} from "antd";
import {reportsService} from "../../../../../services/reports/reports.service";
import {downloadBlobFile} from "../../../../../utils/blob.download";


interface ReportsReportPreparingStatusModalProps {
    id: number;
    onClose: () => void;
}

export const ReportsReportPreparingStatusModal: React.FC<ReportsReportPreparingStatusModalProps> = ({id, onClose}) => {

    const [ready, setReady] = useState<boolean>(false);

    const download = async (id: number) => {
        const data = await reportsService.download(id);
        downloadBlobFile(data.blob, data.filename);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            reportsService.getResult(id).then(r => {
                if (r.status === 'completed') {
                    setReady(true);
                    clearInterval(interval);
                }
            })

        }, 2000);
        return () => clearInterval(interval);
    }, [id])

    return (
        <Modal
            destroyOnClose
            visible={true}
            title={"Preparing.."}
            onCancel={onClose}
            width={500}
            footer={false}
            centered
        >
            <Space direction="vertical" style={{ width: '100%', padding: 40, textAlign: "center" }}>
                {ready ? (
                    <Button onClick={() => download(id)}>Download</Button>
                ) : (
                    <Spin tip="Preparing report. You can close this window and download later in the HISTORY section" size="large">
                        <div className="content" />
                    </Spin>
                )}
            </Space>
        </Modal>
    );
}