import React from 'react';
import {CustomUploadFile, FileUploader} from "../../../../../core/file-uploader/file-uploader";

export interface UIWidgetImageProps {
    action: string;
    value?: CustomUploadFile[];
    onChange?: (value: CustomUploadFile[]) => void;
    onRemove?: (value: CustomUploadFile) => Promise<any>;
}

export const UIInputWidgetImage: React.FC<UIWidgetImageProps> = ({value, onChange, onRemove, action}) => {
    return (
        <FileUploader
            action={action}
            value={value}
            onChange={onChange}
            onRemove={onRemove}
            maxCount={1}
        />
    )
};