import {ClaimItemAttachment} from "../../../../../models/claims/claims";
import {UploadFile} from "antd/es/upload/interface";

export const createFileList = (attachments: ClaimItemAttachment[]): UploadFile[] => {
    return attachments.map(attachment => {

        return {
            uid: attachment.id.toString(),
            name: attachment.fileName,
            fileName: attachment.fileName,
            url: '/api/v3/claim/attachment/' + attachment.hash + '/' + attachment.fileName,
            thumbUrl: '/api/v3/claim/attachment/' + attachment.hash + '/' + attachment.fileName,
            status: 'done',
            size: 0,
            type: ''
        }
    })
}