import React from "react";
import {Descriptions} from "antd";
import Date from "../../../../date/date";
import {TaskStatus} from "../../common/status/task.status";
import {TaskPriority} from "../../common/priority/task.priority";
import {TaskType} from "../../common/type/task.type";
import {PlannerTask, PlannerTaskSystemStatus} from "../../../../../models/planner/planner";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {TaskAssignees} from "../../common/assignees/task.assignees";
import {TaskDepartments} from "../../common/departments/task.departments";
import {TaskTags} from "../../common/tags/task.tags";

interface PlannerTasksViewDescriptionsProps {
    task: PlannerTask;
    reload: () => void;
}

export const PlannerTasksViewDescriptions: React.FC<PlannerTasksViewDescriptionsProps> = ({task, reload}) => {

    const t = useTranslate();

    const items = [
        {key: 1, label: "Type",                                             children: <TaskType type={task.type} />},
        {key: 2, label: t('PLANNER.TASK.FIELDS.STATUS'),               children: <TaskStatus status={task.status} />},
        {key: 3, label: t('PLANNER.TASK.FIELDS.PRIORITY'),             children: <TaskPriority priority={task.priority} />},
        {key: 4, label: t('PLANNER.TASK.FIELDS.TAGS'),                 children: <TaskTags task={task} reload={reload} />},
        {key: 5, label: t('PLANNER.TASK.FIELDS.AUTHOR'),               children: task.author.firstName + ' ' + task.author.lastName},
        {key: 6, label: t('PLANNER.TASK.FIELDS.DEPARTMENTS'),          children: <TaskDepartments task={task} reload={reload} />},
        {key: 7, label: t('PLANNER.TASK.FIELDS.ASSIGNEES'),            children: <TaskAssignees task={task} reload={reload} />},
        {key: 8, label: t('PLANNER.TASK.FIELDS.ESTIMATED_START_DATE'), children: <Date dateString={task.plannedStartDate} showTime />},
        {key: 9, label: t('PLANNER.TASK.FIELDS.ESTIMATED_END_DATE'),   children: <Date dateString={task.plannedEndDate} showTime />},
    ]

    if (task.status === PlannerTaskSystemStatus.DONE) {
        items.push(
            {key: 9, label: t('PLANNER.TASK.FIELDS.REAL_START_DATE'), children: <Date dateString={task.realStartDate} showTime />},
            {key: 10, label: t('PLANNER.TASK.FIELDS.REAL_END_DATE'),   children: <Date dateString={task.realEndDate} showTime />}
        )
    }

    return (
        <Descriptions
            layout="vertical"
            column={1}
            size={"small"}
            items={items}
        />
    )
}