import React from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {Outlet} from "react-router-dom";

interface OrdersMainProps extends ComponentPropsFromRoute {}
export const OrdersMain: React.FC<OrdersMainProps> = ({}) => {

    return <>
        <Outlet />
    </>
}