import React, {useMemo} from "react";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {Table} from "antd";
import {columnsFactory} from "./codes-list.columns";
import {UploadedDeclaration} from "../../../declaration.upload.model";

interface DeclarationCartCodesListProps {
    declaration: UploadedDeclaration
}

export const DeclarationCartCodesList: React.FC<DeclarationCartCodesListProps> = ({declaration}) => {

    const t = useTranslate();

    const columns = useMemo(() => columnsFactory(declaration, t), [])

    return (
        <>
            <Table
                columns={columns}
                dataSource={declaration.parts}
                pagination={false}
                style={{width: '100%'}}
            ></Table>
        </>
    )
}