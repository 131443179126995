import React, {RefObject, useRef} from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {displayPrice} from "../../../../models/prices/price.helper";
import {OrderDeliveryCourier} from "../../../../models/orders/order-delivery/order.delivery";
import {Card, Checkbox, Row, Typography} from "antd";
import {CustomCard} from "../../../common/custom-card/custom.card";
import {useRequiredStore} from "../../../../utils/store";
import {OrderDeliveryCreateStoreContext} from "../order.delivery.create.store";

interface OrdersDeliveryOptionCardProps {
    option: OrderDeliveryCourier
}
export const OrdersDeliveryOptionCard: React.FC<OrdersDeliveryOptionCardProps> = observer(({option}) => {
    const t = useTranslate();
    const store = useRequiredStore(OrderDeliveryCreateStoreContext);
    const checkboxRef = useRef(null) as RefObject<any>

    const cardRows = [
        {key: "price", label: "SHIPPING.PRICE"},
        {key: "estimateType", label: "SHIPPING.TYPE"},
        {key: "estimate", label: "SHIPPING.TIME"},
        {key: "packType", label: "SHIPPING.PACK_TYPE"},
    ];

    const cardData = {...option, price: option.price.cost.value === 0 ? t('FREE') : displayPrice(option.price.cost)}


    return (
        <Card
            hoverable
            className={"card"}
            onClick={() => store.selectShipping(option.id, !option.checked)}
            title={
                <Row align={"middle"} justify={"space-between"}>
                    <Typography.Text>{option.name}</Typography.Text>
                    <Checkbox
                        className={"large"}
                        ref={checkboxRef}
                        checked={option.checked}
                    />
                </Row>
            }
        >
            {option.id === "delay" ? (
                <>({t("SHIPPING.MERGED_SHIPPING.DESCRIPTION")})</>
            ) : option.id === "custom" ? (
                <>({t("SHIPPING.CUSTOM.DESCRIPTION")})</>
            ) : (
                <CustomCard data={cardData} rowKeys={cardRows}/>
            )}
        </Card>
    )
})
