import React from "react";
import {Form, Modal, Tabs} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {UserAccessesCategoryCreateForm} from "./accesses-category-create-form/user.accesses.category.create.form";
import {UserAccessesCategoryEditForm} from "./acccesses-categoy-edit-form/user.accesses.category.edit.form";


interface UserAccessesCategoryModalProps {
    accessesCategory: {[id: string]: string},
    visible: boolean,
    onClose: () => void,
    onCreate: (values: {name: string, editingCategoryId?: string}) => Promise<any>,
    onEdit: (values: {editingCategoryId: string, name: string}) => Promise<any>
}

export const UserAccessesCategoryModal: React.FC<UserAccessesCategoryModalProps> = ({accessesCategory, visible, onClose, onCreate, onEdit}) => {
    const t = useTranslate();
    const [form] = Form.useForm();
    return (
        <Modal
            visible={visible}
            footer={null}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Tabs tabPosition={"top"} defaultActiveKey={"1"}>
                <Tabs.TabPane tab={t("ADD")} key={1}>
                    <UserAccessesCategoryCreateForm form={form} onClose={onClose} onSubmit={onCreate}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("EDIT")} key={2}>
                    <UserAccessesCategoryEditForm form={form} accessesCategory={accessesCategory} onClose={onClose} onSubmit={onEdit}/>
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    )
}
