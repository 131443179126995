import React from "react";
import { observer } from "mobx-react";
import { Alert } from "antd";
import { useRequiredStore } from "../../../../../utils/store";
import { OrdersCreateStoreContext } from "../orders.create.store";
import {useTranslate} from "../../../../../hooks/translate.hook";

export const OrdersCreateMissingItemsAlert: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(OrdersCreateStoreContext);

    if (!store.missingItemsAlertVisible) {
        return null;
    }
    return (
        <Alert
            type="warning"
            message={t("ORDERS.CREATE.MISSING_ITEMS.TITLE")}
            showIcon
        />
    );
});
