import {ColumnsType} from "antd/es/table";
import {Invoice, InvoicePackingListDocument} from "../../../../models/documents/invoices/invoice";
import {Link} from "react-router-dom";
import {DocumentMasterOrder} from "../../../../models/documents/master-order";
import {Amount} from "../../../../models/prices/price";
import {displayPrice} from "../../../../models/prices/price.helper";
import Date from "../../../date/date";
import React from "react";
import {Translate} from "../../../../hooks/translate.hook";
import {DocumentStatus} from "../../../../models/documents/status";
import {DocumentsStatus} from "../../../common/documents/status/documents.status";

export const columnsFactory = (t: Translate): ColumnsType<Invoice> => {
    return [
        {
            title: t("INVOICES.NUMBER"),
            dataIndex: "docNumber",
            key: "invoices_docNumber",
            width: "260px",
            render: (docNumber: string, row) => <Link to={"/credit-invoices/" + row.id}>{docNumber}</Link>
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            render: (status: DocumentStatus) => <DocumentsStatus status={status} />,
        },
        // {
        //     title: t("ORDERS.ORDER.TITLE"),
        //     dataIndex: "masterOrder",
        //     key: "invoices_masterOrder",
        //     width: "100px",
        //     render: (item) => <Link to={"/orders/" + item.id}>{item.id}</Link>,
        // },
        // {
        //     title: t("INVOICES.PACKING_LIST_NR"),
        //     dataIndex: "packingListDocument",
        //     key: "invoices_packingListDocument",
        //     width: "150px",
        //     render: (packingListDocument: InvoicePackingListDocument) => packingListDocument ? (
        //         <Link to={"/packing-lists/" + packingListDocument.id}>{packingListDocument.docNumber}</Link>
        //     ) : null
        // },
        {
            title: t("CLIENT.NAME"),
            dataIndex: "agent",
            key: "invoices_masterOrder_client",
            render: (agent) => (
                <Link to={"/clients/" + agent.number}>{agent.name}</Link>
            ),
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "grandTotal",
            key: "invoices_grandTotal",
            render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
        },
        {
            title: t("INVOICES.DISPATCH.DISPATCH_DATE"),
            dataIndex: "date",
            key: "invoices_date",
            render: (date: string) => <Date dateString={date} showTime withSeconds/>,
        },
    ];
}