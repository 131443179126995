import React, {useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Col, DatePicker, Form, Modal, Row, Tabs} from "antd";
import {downloadService} from "../../../../../services/documents/common/download.service";
import {downloadBlobFile} from "../../../../../utils/blob.download";
import dayjs from "dayjs";

interface PDFModalProps {
    onClose: () => void;
    selectedDocs: number[];
    type: "invoice" | "merged_invoice" | "credit_invoice" | "proforma"
    accountId: number;
}
export const PDFModal: React.FC<PDFModalProps> = ({ onClose, selectedDocs, type, accountId }) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [selectedDocsMode, setSelectedDocsMode] = useState<boolean>(false);

    const defaultValue = {
        date:  dayjs()
    }

    const onOk = async () => {
        if (selectedDocsMode) {
            setLoading(true);
            try {
                await downloadService
                    .downloadAsPDFBySelectedIds(selectedDocs)
                    .then(r => downloadBlobFile(r.blob, r.filename))
            } finally {
                setLoading(false);
            }
        } else {
            form.submit();
        }
    }

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("DOCUMENTS.EXPORT.AS_PDF" )}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={onOk}
            onCancel={onClose}
        >
            <Tabs
                onChange={k => setSelectedDocsMode(k === "selected")}
            >
                <Tabs.TabPane
                    tab={t('DOCUMENTS.EXPORT.TYPE.BY_DATE_RANGE')}
                    key={"range"}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        name={"integrations_form"}
                        initialValues={defaultValue}
                        onFinish={() => form
                            .validateFields()
                            .then(async values => {
                                setLoading(true);
                                try {
                                    await downloadService
                                        .downloadAsPDFByDateRange(values.date[0], values.date[1], type, accountId)
                                        .then(r => downloadBlobFile(r.blob, r.filename))
                                } finally {
                                    setLoading(false)
                                }
                            })
                        }
                    >
                        <Form.Item
                            label={t("DATE")}
                            name="date"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "FROM.ERROR.PLEASE_SELECT_VALUE"
                                    ),
                                },
                            ]}
                        >
                            <DatePicker.RangePicker
                                showNow
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Form>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={t('DOCUMENTS.EXPORT.TYPE.ONLY_SELECTED') + ' (' + selectedDocs.length + ')'}
                    key={"selected"}
                    disabled={0 === selectedDocs.length}
                >
                    <Row gutter={10}>
                        <Col span={24}>
                            <span style={{fontWeight: "bold"}}>Will be included</span>: {selectedDocs.join(", ")}
                        </Col>
                    </Row>
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    );
}