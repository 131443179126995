import {observer} from "mobx-react";
import {Alert, Col, Row, Space, Spin, Typography} from "antd";
import {
    OrdersCheckoutShippingsCard
} from "../../orders-create/checkout/orders-checkout-shippings/orders.checkout.shippings.card";
import React from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {OrderDeliveryCreateStoreContext} from "../order.delivery.create.store";
import {OrdersDeliveryOptionCard} from "./orders.delivery.option.card";

export const OrdersDeliveryOptions: React.FC = observer(() => {
    const store = useRequiredStore(OrderDeliveryCreateStoreContext);
    const t = useTranslate();


    return (
        <Space direction="vertical" size={[0, 0]}>
            <Typography.Title level={3}>{t("CART.DELIVERY.OPTIONS.TITLE")}</Typography.Title>
            <Row gutter={[30, 30]}>
                {store.deliveryOrder?.couriers ? store.deliveryOrder.couriers.map(courier => (
                    <Col className={"card-col"} key={courier.id} xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                        <OrdersDeliveryOptionCard option={courier}/>
                    </Col>
                )) : <Spin/>}
            </Row>
        </Space>
    )
})
