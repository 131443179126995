import React from "react";
import {Translate} from "../../../../hooks/translate.hook";
import {ClaimStatus, ClaimType} from "../../../../models/claims/claims";
import {FieldTypeName} from "../../../../models/filter/filter.field.type.name";
import {FieldGroupConfig} from "../../../../models/filter/filter.group";

export const filterGroupsFactory = (t: Translate): FieldGroupConfig[] => {
    return [
        {
            visibleName: 'STATUS',
            name: 'statuses',
            fields: [
                {
                    name: 'statuses',
                    type: FieldTypeName.SELECT,
                    choices: Object.values(ClaimStatus).map(value => {
                        return {
                            name: t('CLAIMS.CLAIM.STATUS.' + value.toUpperCase()),
                            value: value
                        }
                    }),
                    multiple: true
                }
            ],
        },
        {
            visibleName: 'DOCUMENT_NUMBER',
            name: 'documentNumber',
            fields: [
                {
                    name: 'documentNumber',
                    type: FieldTypeName.STRING
                }
            ]
        },
        {
            visibleName: 'TOTAL_PRICE',
            name: 'subtotal',
            fields: [
                {
                    name: 'subtotal',
                    type: FieldTypeName.INTEGER
                }
            ]
        },
        {
            visibleName: 'CREATED_AT',
            name: 'createdAt',
            fields: [
                {
                    name: 'createdAt',
                    type: FieldTypeName.DATE_RANGE
                }
            ]
        }
    ];
}