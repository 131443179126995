import React from "react";
import { observer } from "mobx-react";
import { useRequiredStore } from "../../../../../utils/store";
import { ProformaDataStoreContext } from "../proforma.data.store";
import {Alert, Button, Descriptions, Dropdown, Menu, Popconfirm, Row, Space, Typography} from "antd";
import { Link } from "react-router-dom";
import { DocumentsStatus } from "../../../../common/documents/status/documents.status";
import Date from "../../../../date/date";
import { RootStoreContext } from "../../../../../stores/root/root.store";
import { isAllowed } from "../../../../../utils/helpers";
import {Export} from "../../../../common/export/export";
import {
    OrderDataDocumentButtons
} from "../../../../orders/order-data/order-data-wholesale/order-data-header/order-data-header-document-buttons/order.data.document.buttons";
import {SourcesListModal} from "../../../common/sources/modal/sources-list/sources-list.modal";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";
import {useNavigate} from "react-router";

export const ProformaDataHeader: React.FC = observer(() => {
    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);
    const store = useRequiredStore(ProformaDataStoreContext);
    const t = useTranslate();
    const navigate = useNavigate();

    return (
        <>
            <PageHeader
                className="invoice-header"
                style={{ padding: 0 }}
                ghost={false}
                title={
                    <Typography.Title level={1} style={{ margin: 0 }}>
                        {t("PROFORMA.TITLE") + " " + store.proformaData?.docNumber}
                    </Typography.Title>
                }
                onBack={() => {
                    navigate("/proformas");
                }}
                extra={<DocumentsStatus large status={store.proformaData!.status} />}
            >
                <Space direction="vertical" size={[20, 20]} style={{marginBottom: '20px'}}>
                    {store.includedOrders.some(order => order.comments.length > 0) ? (
                        <Alert
                            showIcon
                            type="warning"
                            message={t("WARNING")}
                            description={
                                <span>{t('PROFORMA.ORDER_CONTAINS_COMMENTS')}</span>
                            }
                        />
                    ) : null}
                </Space>

                    <Row justify={"space-between"} style={{marginBottom: 20}}>
                    <Space className={"invoice-data-header-buttons"} direction={"horizontal"} size={[20, 20]} wrap>
                        <OrderDataDocumentButtons order={null} masterOrderId={null} includedOrdersIds={store.proformaData?.orders || []} showProformas={false}>
                            <Dropdown
                                placement={"bottomRight"}
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        <Menu.Item key={0}>
                                            <a
                                                style={{ textAlign: "left" }}
                                                target="_blank"
                                                className={"ant-btn ant-btn-link"}
                                                href={"/documents/" + store.proformaData!.id}
                                            >
                                                {t("DOCUMENTS.FILES.SHOW_PDF")}
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item key={2}
                                        >
                                            <a
                                                style={{ textAlign: "left" }}
                                                target="_blank"
                                                className={"ant-btn ant-btn-link"}
                                                onClick={() => store.setExportModalShown(true)}
                                            >
                                                {t("DOWNLOAD.CSV_EXCEL")}
                                            </a>
                                        </Menu.Item>
                                        {/*<Menu.Item key={1} onClick={() => store.handleModal("export", true)}>*/}
                                        {/*    {t("DOWNLOAD.CSV_EXCEL")}*/}
                                        {/*</Menu.Item>*/}
                                    </Menu>
                                }
                            >
                                <Button> {t("ORDERS.ORDER.VIEW.PROFORMA")}</Button>
                            </Dropdown>
                        </OrderDataDocumentButtons>
                        {isAllowed(attributes, "document_proforma_create") ? (
                            <a
                                style={{ textAlign: "left" }}
                                target="_blank"
                                className={"ant-btn ant-btn-primary"}
                                href={"/api/v3/collect/proforma/" + store.proformaData!.id + "/pdf"}
                            >
                                {t("ORDERS.COLLECT_LIST")}
                            </a>
                        ) : null}
                        {isAllowed(attributes, "document_proforma_create") && store.proformaData?.status === "opened" ? (
                            <Popconfirm
                                title={t("ARE_YOU_SURE")}
                                onConfirm={store.closeProforma}
                                placement="top"
                                okText={t("YES")}
                                cancelText={t("NO")}
                            >
                                <Button type="primary">{t("PROFORMAS.CLOSE")}</Button>
                            </Popconfirm>
                        ) : null}
                        {isAllowed(attributes, "document_proforma_create") && store.proformaData?.status === "opened" ? (
                            <Dropdown overlay={
                                <Menu>
                                    <Menu.Item key="0" onClick={store.cancelProformaWithOrders}>
                                        {t('PROFORMA.CANCEL_AND_CANCEL_ORDER')}
                                    </Menu.Item>
                                    <Menu.Item key="1" onClick={store.cancelProforma}>
                                        {t('PROFORMA.CANCEL')}
                                    </Menu.Item>
                                </Menu>
                            } trigger={['click']}>
                                <Button danger type="primary">
                                    {t("CANCEL")}
                                </Button>
                            </Dropdown>
                        ) : null}
                    </Space>
                    {isAllowed(attributes, "faker") ? (
                        <Dropdown
                            placement={"bottomRight"}
                            trigger={["click"]}
                            overlay={
                                <Menu>
                                    <Menu.Item key={3} onClick={() => store.setSourcesListShown(true)}>
                                        Show Sources List
                                    </Menu.Item>
                                    <Menu.Item key={4} onClick={() => store.sendDocumentToClient()}>
                                        {t("DOCUMENTS.SEND_TO_CLIENT")}
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button type={"primary"}>{t("ACTION")}</Button>
                        </Dropdown>
                    ) : null}
                </Row>

                <div className="data-content-wrapper">
                    <Descriptions
                        className="data-content-wrapper-descriptions"
                        size={"small"}
                        layout={"vertical"}
                        contentStyle={{ fontSize: "17px" }}
                        labelStyle={{ color: "#858585" }}
                        column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
                    >
                        <Descriptions.Item label={t("ORDERS.ORDER.NUMBER")}>
                            <Link className="link" to={"/orders/" + store.proformaData?.masterOrder.id}>
                                {store.proformaData?.masterOrder.id}
                            </Link>
                        </Descriptions.Item>
                        {/*<Descriptions.Item*/}
                        {/*    label={t("PACKING_LIST")}*/}
                        {/*>*/}
                        {/*    {store.invoice?.mas.packingList ? store.invoice?.order.packingList.docNumber : null}*/}
                        {/*</Descriptions.Item>*/}
                        <Descriptions.Item label={t("CLIENT.NUMBER")}>
                            <Link
                                className="link"
                                to={"/clients/" + store.proformaData?.agent.number}
                            >
                                {store.proformaData?.agent.number} (
                                {store.proformaData?.agent.name})
                            </Link>
                        </Descriptions.Item>
                        <Descriptions.Item label={t("SHIPPING_WAREHOUSE")}>
                            {store.proformaData?.warehouse.name}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("WEIGHT_BRUTTO")}>
                            {store.proformaData?.totalGrossWeight} {t("KG")}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("PAYMENT_TERMS")}>
                            {store.proformaData?.paymentTerms}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("DATE")}>
                            <Date dateString={store.proformaData?.date!} showTime withSeconds />
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </PageHeader>
            <>
                {store.sourcesListShown ? (
                    <SourcesListModal
                        documentId={store.proformaData!.id}
                        onClose={() => store.setSourcesListShown(false)}
                    />
                ) : null}
                {store.proformaData!.id ? (
                    <Export
                        title={"ORDERS.ORDER.DOWNLOAD_CSV_INVOICE"}
                        isShown={store.exportModalShown}
                        withBasicExportFields
                        config={{
                            link: "/api/v3/documents/proforma/" + store.proformaData!.id,
                        }}
                        onClose={() => store.setExportModalShown(false)}
                    />
                ) : null}
            </>
        </>
    );
});
