import React, {useEffect} from "react";
import {RouteTabs} from "../common/route-tabs/route.tabs";
import {ComponentPropsFromRoute} from "../../routers/routers";
import {useLocation} from "react-router-dom";
import {useRequiredStore} from "../../utils/store";
import {RootStoreContext} from "../../stores/root/root.store";
import {useNavigate} from "react-router";

export const Planner: React.FC<ComponentPropsFromRoute> = ({routeChildren}) => {

    const location = useLocation();
    const navigate = useNavigate();

    const {
        authStore: { role },
    } = useRequiredStore(RootStoreContext);

    useEffect(() => {
        if (location.pathname === '/planner') {
            if (role === 'superior') {
                navigate('/planner/tasks-in-department');
            } else {
                navigate('/planner/tasks');
            }
        }
    }, [location.pathname, navigate, role])

    return (
        <RouteTabs routes={routeChildren!} />
    )
}
