import React from "react";
import {Link, useLocation} from "react-router-dom";
import {Button, Space} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";

interface DocumentsMenuItem {
    name: string,
    url: string;
}

const config: DocumentsMenuItem[] = [
    { name: "ORDERS.TITLE", url: "/orders" },
    { name: "PROFORMAS.TITLE", url: "/proformas" },
    { name: "INVOICES.TITLE", url: "/invoices" },
    { name: "INVOICES.MERGED.TITLE", url: "/merged-invoices" },
    { name: "PACKING_LISTS.TITLE", url: "/packing-lists" },
    { name: "CREDITS.TITLE", url: "/credit-invoices" },
    { name: "RETURNS.TITLE", url: "/returns" },
]
interface DocumentsMenuProps {
    includeRetail?: boolean;
}
export const DocumentsMenu: React.FC<DocumentsMenuProps> = ({includeRetail}) => {
    const t = useTranslate();
    const {pathname} = useLocation();

    return (
        <Space direction="horizontal" size={[10, 10]} style={{ width: "auto" }} wrap>
            {config.map(item => (
                <Link
                    to={item.url}
                >
                    <Button type={(pathname.startsWith(item.url)) ? "primary" : "default"}>
                        {t(item.name)}
                    </Button>
                </Link>
            ))}
        </Space>
    )
}
