import {action, makeObservable, observable} from "mobx";
import {forgotPasswordService} from "../../../../services/forgot-password/forgot.password.service";
import queryString from "query-string";

export class ForgotPasswordCompleteStore {
    @observable
    public inited: boolean = false;

    @observable
    public completed: boolean = false;

    constructor() {
        makeObservable(this);
        this.init();
    }

    @action
    public setCompleted(value: boolean): void {
        this.inited = true;
        this.completed = value;
    }

    private async init() {
        const params: any = queryString.parse(window.location.search.substr(1));
        try {
            await forgotPasswordService.checkIfCompleted({token: params.token});
            this.setCompleted(true);
        } catch (e: any) {
            this.setCompleted(false);
        }
    }
}