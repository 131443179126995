import {Translate} from "../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {WarehousePriority, WarehousePriorityGroup} from "../../../../../models/warehouses/warehouse";
import {Button, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const columnsFactory = (t: Translate, onEdit: (group: WarehousePriorityGroup) => void): ColumnsType<WarehousePriorityGroup> => {
    return [
        {
            title: "Name",
            key: "name",
            dataIndex: "name",
            render: (name: string) => name,
            width: 150
        },
        {
            title: "Priority",
            key: "warehouses",
            dataIndex: "warehouses",
            render: (warehouses: WarehousePriority[]) => warehouses.map(w => w.warehouse.name).join(", "),
            width: 150
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            width: 100,
            className: "table-button-cell",
            render: (_: any, group) => (
                <>
                    <Tooltip placement="topRight" title={t("EDIT")}>
                        <Button
                            onClick={() => onEdit(group)}
                            className="table-btn btn-remove btn"
                            type="default"
                            icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                        />
                    </Tooltip>
                </>
            ),
        }
    ];
}