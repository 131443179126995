import React, {useEffect} from "react";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {RouteTabs} from "../../../../common/route-tabs/route.tabs";
import {Space, Typography} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";

export const PurchasingAlliancesHome: React.FC<ComponentPropsFromRoute> = ({routeChildren}) => {

    const t = useTranslate();
    const navigate  = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/settings/common/purchasing-alliances") {
            navigate("/settings/common/purchasing-alliances/tier1", {replace: true})
        }
    }, [location.pathname])

    return (
        <Space direction={"vertical"} size={[10, 10]}>
            <Typography.Title level={3}>{t("PURCHASING-ALLIANCES.TITLE")}</Typography.Title>
            <RouteTabs routes={routeChildren!} customSpliceNum={5}></RouteTabs>
        </Space>
    )
}