import React from "react";
import {action, makeObservable, observable} from "mobx";
import {MergedInvoiceData} from "../../../../models/documents/invoices/merged-invoice/merged-invoice-data/merged.invoice.data";
import {
    PayerOrReceiverDetails,
    PayerReceiverFormValues,
    SellerDetails
} from "../../../../models/payer-receiver-seller/payer.reveiver.seller";
import {payerReceiverSellerService} from "../../../../services/payer-receiver-seller/payer.receiver.seller.service";
import {mergedInvoiceService} from "../../../../services/documents/invoices/merged.invoices.service";
import {PagesStore} from "../../../../stores/pages/pages.store";
import {addressService} from "../../../../services/documents/common/address.service";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {senderService} from "../../../../services/documents/common/sender.service";
import {notifications} from "../../../../services/notifications/notifications";

type MergedInvoiceModalType = "export" | "edit" | "editWeight" | "setDate"

export class MergedInvoiceDataStore {
    @observable
    public editModalShown: boolean = false;

    @observable
    public exportModalShown: boolean = false;

    @observable
    public editWeightModalShown: boolean = false;

    @observable
    public invoice: MergedInvoiceData | null = null;

    @observable
    public loading: boolean = false;

    @observable
    public setDateModalShown: boolean = false;

    @observable
    public masterOrderId: number | null = null;

    @observable
    public checkedInvoices: React.Key[] = [];

    @observable
    public deleteButtonDisabled: boolean = false;

    @observable
    public confirmModalShown: boolean = false;

    constructor(private id: string, private pageStore: PagesStore, private url: string, private navigate: NavigateFunction) {
        this.editSellerData = this.editSellerData.bind(this);
        this.editPayerReceiverData = this.editPayerReceiverData.bind(this);
        this.deleteInvoices = this.deleteInvoices.bind(this);
        this.editInvoice  = this.editInvoice.bind(this);
        this.setEditModalShown = this.setEditModalShown.bind(this);
        this.setExportModalShown = this.setExportModalShown.bind(this);
        this.setEditWeightModalShown = this.setEditWeightModalShown.bind(this);
        this.setSetDateModalShown = this.setSetDateModalShown.bind(this);
        this.deleteInvoice = this.deleteInvoice.bind(this);
        this.recalcWeight = this.recalcWeight.bind(this);
        makeObservable(this);
        this.init();
    }

    @action
    private setConfirmModalShown(value: boolean): void {
        this.confirmModalShown = value;
    }

    @action
    public setInvoice(invoice: MergedInvoiceData): void {
        this.invoice = invoice;
    }

    @action
    public setLoading(loading: boolean): void {
        this.loading = loading;
    }

    @action
    public setDeleteButtonDisabled(value: boolean): void {
        this.deleteButtonDisabled = value;
    }

    @action
    private setSellerData(seller: SellerDetails): void {
        this.invoice!.sellerAddress = seller;
    }

    @action
    private setBillingData(data: PayerOrReceiverDetails): void {
        this.invoice!.billingAddress = data;
    }

    @action
    private setShippingData(data: PayerOrReceiverDetails): void {
        this.invoice!.shippingAddress = data;
    }

    @action
    public setMasterOrderId(id: number | null): void {
        this.masterOrderId = id;
    }

    @action
    private setCheckedInvoices(invoices: React.Key[]): void {
        this.checkedInvoices = invoices;
    }

    @action
    public setExportModalShown(value: boolean): void {
        this.exportModalShown = value;
    }

    @action
    public setSetDateModalShown(value: boolean): void {
        this.setDateModalShown = value;
    }

    @action
    public setEditModalShown(value: boolean): void {
        this.editModalShown = value;
    }

    @action
    public setEditWeightModalShown(value: boolean): void {
        this.editWeightModalShown = value;
    }

    public checkInvoicesToDispatch(invoices: React.Key[]): void {
        this.setCheckedInvoices(invoices);
    }
    
    public async deleteInvoices() {
        this.setInvoice(await mergedInvoiceService.excludeInvoicesFromMergedInvoice(this.id, this.checkedInvoices as number[]));
        this.setCheckedInvoices([]);
    }

    public async addInvoices(values: {invoices: number[]}) {
        this.setInvoice(await mergedInvoiceService.addInvoicesToMergedInvoice(this.id, values));
    }

    public async editSellerData(values: SellerDetails) {
        await this.setSellerData(await payerReceiverSellerService.updateSellerData(this.invoice!.id, values));
    }

    public async editPayerReceiverData(type: "payer" | "receiver", values: PayerReceiverFormValues) {
        if (type === "payer") {
            this.setBillingData(await addressService.updateBillingAddress(this.invoice!.id, values));
        } else {
            this.setShippingData(await addressService.updateShippingAddress(this.invoice!.id, values));
        }
    }

    // public getMasterOrderId(): number | null {
    //     if (this.invoice?.orders && this.invoice.orders.length) {
    //         const order = this.invoice.orders.find(order => order === this.invoice?.order.id);
    //         return order ? order : null;
    //     }
    //     return null;
    // }

    public openConfirmModal = () => {
        this.setConfirmModalShown(true);
    }

    public closeConfirmModal = () => {
        this.setConfirmModalShown(false);
    }

    public async editInvoice(values: {number: string, date: string}) {
        await mergedInvoiceService.editInvoice(this.id, values);
        this.setInvoice(await mergedInvoiceService.getMergedInvoice(this.id));
    }

    public handleModal(modalType: MergedInvoiceModalType, open: boolean): void {
        const modalsMap: {[type: string]: (value: boolean) => void} = {
            export: this.setExportModalShown,
            edit: this.setEditModalShown,
            editWeight: this.setEditWeightModalShown,
            setDate: this.setSetDateModalShown
        }
        if (modalsMap[modalType]) {
            const handler = modalsMap[modalType];
            handler(open);
        }
    }

    public async deleteInvoice() {
        this.setDeleteButtonDisabled(true);
        await mergedInvoiceService.deleteMergedInvoice(this.invoice!.id);
        this.setDeleteButtonDisabled(false);
        this.navigate("/merged-invoices");
    }

    private async init() {
        await this.reload();
        this.pageStore.updateRouteName(this.url, this.invoice?.docNumber);
    }

    public async reload() {
        this.setLoading(true);
        this.setInvoice(await mergedInvoiceService.getMergedInvoice(this.id));
        this.setLoading(false);
    }

    public async confirm(openNotification: (type: "success" | "error", message: string) => void, values: {date: string}) {
        await mergedInvoiceService.confirm(this.id, values);
        this.setInvoice(await mergedInvoiceService.getMergedInvoice(this.id));
        openNotification("success", "INVOICES.CONFIRM.SUCCESS")
    }

    public async unconfirm(openNotification: (type: "success" | "error", message: string) => void) {
        await mergedInvoiceService.unconfirm(this.id);
        this.setInvoice(await mergedInvoiceService.getMergedInvoice(this.id));
        openNotification("success", "INVOICES.UNCONFIRM.SUCCESS")
    }

    public async recalcWeight(
        values: {weight: string},
        openNotification: (type: "success" | "error", message: string) => void
    ) {
        try {
            await mergedInvoiceService.recalcWeight(this.id!, values)
            this.setInvoice(await mergedInvoiceService.getMergedInvoice(this.id));
            openNotification("success", "RECALC_WEIGHT.SUCCESS");
        } catch (e: any) {
        }
    }

    public async sendDocumentToClient() {
        await senderService.send(parseInt(this.id));
        notifications.successfully()
    }
}

export const MergedInvoiceDataStoreContext = React.createContext<null | MergedInvoiceDataStore>(null);
