import React, {useState} from "react";
import {Department} from "../../../../../models/departments/department";
import {Button, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {plannerService} from "../../../../../services/planner/planner.service";
import {PlannerTask} from "../../../../../models/planner/planner";

interface TaskAssigneesProps {
    task: PlannerTask;
    department: Department;
    reload: () => void;
}

export const TaskDepartment: React.FC<TaskAssigneesProps> = ({task, department, reload}) => {

    const [disabled, setDisabled] = useState<boolean>(false);

    const remove = async () => {
        setDisabled(true);
        try {
            await plannerService.removeDepartment(task.id, {department: department.id});
            reload();
        } finally {
            setDisabled(false);
        }
    }

    return (
        <Space direction={"horizontal"}>
            <div>{department.name}</div>
            <Button
                disabled={disabled}
                onClick={remove}
                icon={<FontAwesomeIcon icon={["fas", "ban"]}/>}
                type={"text"}
                size={"small"}
            />
        </Space>
    )
}
