import React, {ReactElement} from "react";
import { Alert, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { NewOrder } from "../../../models/orders/orders-new/orders.new";
import { Link } from "react-router-dom";
import {displayPrice} from "../../../models/prices/price.helper";
import Date from "../../date/date";
import {useTranslate} from "../../../hooks/translate.hook";

interface OrdersNewProps {
    newOrders: NewOrder[];
    lastReloadedAt: string;
    actions: (row: NewOrder) => ReactElement;
}
export const OrdersNew: React.FC<OrdersNewProps> = ({ newOrders, lastReloadedAt, actions }) => {
    const t = useTranslate();
    const columns: ColumnsType<NewOrder> = [
        {
            title: t("CLIENT.NUMBER"),
            key: "agentNumber",
            dataIndex: "agentNumber",
            width: 150,
            render: (number: string) => <Link to={"/clients/" + number}>{number}</Link>,
        },
        {
            title: t("CLIENT.NAME"),
            key: "agentName",
            dataIndex: "agentName",
            width: 240,
            render: (_: string, row) => <Link to={"/clients/" + row.agentNumber}>{row.agentName}</Link>,
        },
        {
            title: t("TOTAL_ORDERS"),
            key: "totalOrders",
            dataIndex: "totalOrders",
            width: 140,
        },
        {
            title: t("TOTAL_PRICE"),
            key: "totalOrders",
            width: 140,
            render: (_, row) => displayPrice(row.subtotal)
        },
        {
            title: t("PAYMENT_TERMS"),
            key: "paymentTerms",
            dataIndex: "paymentTerms",
            width: 160,
        },
        {
            title: t("ACTION"),
            key: "action",
            dataIndex: "action",
            // width: 290,
            render: (_, row) => (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {actions(row)}
                </div>
            ),
        },
    ];
    return (
        <>
            <Typography.Title level={3}>{t("ORDERS.NEW")}</Typography.Title>
            <div
                style={{marginBottom: 5}}
            >
                Last updated: <Date dateString={lastReloadedAt} showTime />
            </div>
            {newOrders.length === 0 ? (
                <Alert type="info" message={t("ORDERS.NO_DATA_NEW")} />
            ) : (
                <Table
                    rowKey="accountId"
                    columns={columns}
                    dataSource={newOrders}
                    scroll={undefined}
                    pagination={false}
                />
            )}
        </>
    );
};
