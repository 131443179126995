import React, {useEffect, useMemo, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {useParams} from "react-router-dom";
import {PropertyInventoryTask} from "../../../../models/property/property";
import {propertyService} from "../../../../services/property/property.service";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {ExtendedSwitch} from "../../../core/router/extended-switch/extended.switch";
import {ReviewContext} from "./review.inventory-tasks.model";
import {useURLParams} from "../../../../hooks/url-params.hook";

export const InventoryTasksReview: React.FC<ComponentPropsFromRoute> = () => {

    const {urlParams} = useURLParams();
    const {id} = useParams<{ id: string }>();
    const [task, setTask] = useState<PropertyInventoryTask | null>(null);
    const context = useMemo<ReviewContext | null>(() => task ? ({
        task: task,
        backToStart: urlParams.backToStart === '1'
    }) : null, [task])

    useEffect(() => {
        propertyService.getInventoryTask(parseInt(id!)).then(task => setTask(task));
    }, [id]);

    if (!context) {
        return (
            <LoadingSpin/>
        )
    }

    return (
        <ExtendedSwitch
            context={context}
        />
    )
}