import React, {useEffect, useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Button, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select, Tooltip, Typography} from "antd";
import {AxiosError} from "axios";
import {PriceList} from "../../../../../models/price-lists/price.lists";
import {branchesService} from "../../../../../services/branches/branches.service";
import {ErrorResponseData} from "../../../../../models/core/response";
import {currencies} from "../../../../../models/prices/price.helper";
import dayjs from "dayjs";

interface EditModalModalProps {
    onCancel: () => void;
    onComplete: () => void;
    priceList: PriceList;
    branchId: string;
}

export const EditModal: React.FC<EditModalModalProps> = ({onCancel, onComplete, priceList, branchId}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const [prices, setPrices] = useState<PriceList[]>([]);

    useEffect(() => {
        branchesService.getAllPrices(branchId!).then(prices => setPrices(prices.filter(p => p.id !== priceList.id)));
    }, [branchId])

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("BRANCH.DATA.PRICES.EDIT.MODAL.TITLE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onCancel();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    name: priceList.name,
                    ocName: priceList.ocName,
                    active: priceList.active,
                    currencyCode: priceList.currencyCode,
                    nextPrice: priceList.nextPrice?.id,
                    applyNextPriceAt: priceList.applyNextPriceAt ? dayjs(priceList.applyNextPriceAt) : null
                }}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        branchesService.edit(priceList.id, values)
                            .then(
                                () => {
                                    onCancel();
                                    onComplete();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(
                                            e.response?.data?.fields
                                        );
                                    }
                                }
                            )
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label={t("NAME")}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="ocName"
                            label={t("1C.NAME")}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="currencyCode"
                            label={t("CURRENCY")}
                        >
                            <Select
                                placeholder={t("CURRENCY")}
                                disabled={loading}
                            >
                                {Object.keys(currencies).map((currency) => (
                                    <Select.Option value={currency} key={currency}>
                                        {currency.toUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="active"
                            label={t("ACTIVE")}
                            valuePropName={"checked"}
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="nextPrice"
                            label={t("BRANCH.DATA.PRICES.NEXT_PRICE.TITLE")}
                        >
                            <Select>
                                {prices.map(price => (
                                    <Select.Option value={price.id}>{price.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="applyNextPriceAt"
                            label={t("BRANCH.DATA.PRICES.NEXT_PRICE.APPLY_AT")}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
