import React, {useState} from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../utils/store";
import {MergedInvoiceDataStoreContext} from "../merged.invoice.data.store";
import {Col, DatePicker, Form, Modal, notification, Row} from "antd";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../../models/core/response";
import dayjs from "dayjs";


interface MergedInvoiceDataConfirmModalProps {
    visible: boolean,
    onClose: () => void,
}
export const MergedInvoiceDataConfirmModal: React.FC<MergedInvoiceDataConfirmModalProps> = observer(({visible, onClose}) => {
    const t = useTranslate();
    const store = useRequiredStore(MergedInvoiceDataStoreContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const dateFormat: string = "DD-MM-YYYY";

    const openNotification = (type: "success" | "error", message: string): void => {
        notification[type]({
            message: t(type.toUpperCase()),
            description: t(message),
            duration: 2,
        });
    };


    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={t("MERGED_INVOICES.CONFIRM.TITLE")}
            okText={t("INVOICES.CONFIRM.ACTION")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"merged_invoice_confirm_modal"}
                initialValues={{
                    date: dayjs()
                }}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        store.confirm(openNotification, {date:  dayjs(values.date).format("YYYY-MM-DD")})
                            .then(() => {
                                form.resetFields();
                                return onClose();
                            }, (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                            .then(() =>
                                    onClose(),
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="date"
                            label={t("DATE")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <DatePicker
                                disabled={loading}
                                style={{width: "100%"}}
                                placeholder={t("DATE")}
                                format={dateFormat}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
})
