import { AxiosInstance } from "axios";
import API from "../../utils/api";
import {WebSiteCategory, WebSitePage} from "../../models/web-site/web-site";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";

export class WebSiteService {
    api: AxiosInstance = API;

    addPage(values: any): Promise<WebSitePage> {
        return this.api.post<WebSitePage>("web-site/page", values).then((x) => x.data);
    }

    getPage(id: number): Promise<WebSitePage> {
        return this.api.get<WebSitePage>("web-site/page/" + id).then((x) => x.data);
    }

    editPage(id: number, values: any): Promise<WebSitePage> {
        return this.api.post<WebSitePage>("web-site/page/" + id, values).then((x) => x.data);
    }

    removePage(id: number): Promise<void> {
        return this.api.delete("web-site/page/" + id);
    }

    getCategoriesTree(): Promise<WebSiteCategory[]> {
        return this.api.get<WebSiteCategory[]>("web-site/category/tree").then((x) => x.data);
    }

    getCategory(id: number): Promise<WebSiteCategory> {
        return this.api.get<WebSiteCategory>("web-site/category/" + id).then((x) => x.data);
    }

    addCategory(values: any): Promise<WebSiteCategory> {
        return this.api.post<WebSiteCategory>("web-site/category", values).then((x) => x.data);
    }

    editCategory(id: number, values: any): Promise<WebSiteCategory> {
        return this.api.post<WebSiteCategory>("web-site/category/" + id, values).then((x) => x.data);
    }

    removeCategory(id: number): Promise<void> {
        return this.api.delete("web-site/category/" + id);
    }

    getAllPagesInCategory(id: number, request: PaginatedRequest): Promise<PaginatedResult<WebSitePage>> {
        return this.api
            .get("web-site/category/" + id + "/pages", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }

    uploadFile(file: File): Promise<any> {
        const formData = new FormData();
        formData.append("file", file);

        return this.api
            .post('web-site/upload', formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            })
            .then(x => x.data);
    }
}

export const webSiteService = new WebSiteService();
