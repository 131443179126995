import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, Col, DatePicker, Divider, Form, Modal, Row, Table, Typography } from "antd";
import { AxiosError } from "axios";
import { ErrorResponseData } from "../../../../models/core/response";
import {useTranslate} from "../../../../hooks/translate.hook";
import { Order } from "../../../../models/orders/orders-list/order";
import { Proforma } from "../../../../models/documents/proformas/proforma";
import { proformasService } from "../../../../services/documents/proformas/proformas.service";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { Amount } from "../../../../models/prices/price";
import { displayPrice } from "../../../../models/prices/price.helper";
import Date from "../../../date/date";
import { useVT } from "virtualizedtableforantd4";
import { OrderStatuses } from "../../../../stores/common/common.store";
import {useNavigate} from "react-router";
import dayjs from "dayjs";

const allowedStatuses: OrderStatuses[] = [OrderStatuses.NOT_CONFIRMED];

interface OrdersListProformaModalProps {
    orders: Order[];
    checkedOrdersIds: React.Key[];
    description: React.ReactElement;
    onAddToProforma: (id: number) => Promise<void>;
    onSubmit: (values: any) => Promise<void>;
    onClose: () => void;
}
export const OrdersListProformaModal: React.FC<OrdersListProformaModalProps> = ({
    checkedOrdersIds = [],
    orders = [],
    description = null,
    onSubmit,
    onAddToProforma,
    onClose,
}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [proformas, setProformas] = useState<Proforma[]>([]);
    const [selectedProforma, setSelectedProforma] = useState<Proforma | null>(null);
    const navigate = useNavigate();

    const y = 350;
    const [vt] = useVT(() => ({ scroll: { y }, debug: true }), [y]);

    const columns: ColumnsType<Proforma> = [
        {
            title: t("DOCUMENT_NUMBER"),
            dataIndex: "docNumber",
            key: "proformas_docNumber",
            // width: "140px",
            render: (item: string | null, record) => (item ? <Link to={"/proformas/" + record.id}>{item}</Link> : null),
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "grandTotal",
            key: "proformas_grandTotal",
            width: "120px",
            render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
        },
        {
            title: t("DATE"),
            dataIndex: "date",
            key: "proformas_date",
            render: (date: string | null) => (date ? <Date dateString={date} showTime withSeconds /> : null),
        },
    ];

    const showErrorMessage = useMemo(() => {
        const checkedOrders = orders.filter((order) => checkedOrdersIds.includes(order.id));

        return checkedOrders.some(
            (order) =>
                !allowedStatuses.includes(order.status) ||
                order.warehouse.id !== checkedOrders[0].warehouse.id ||
                order.account.id !== checkedOrders[0].account.id
        );

    }, [checkedOrdersIds, orders]);

    useEffect(() => {
        if (!showErrorMessage && checkedOrdersIds.length !== 0) {
            proformasService
                .getOpenedProformas(orders.filter((item) => checkedOrdersIds.includes(item.id))[0].account.id)
                .then((proformas) => {
                    setProformas(proformas);
                });
        }
    }, [showErrorMessage, checkedOrdersIds, orders]);

    const defaultValue = {
        date: dayjs(),
    };

    const cancel = (): void => {
        onClose();
        form.resetFields();
    };

    const addToProforma = async () => {
        await onAddToProforma(selectedProforma!.id);
        navigate("/proformas/" + selectedProforma!.id);
    };

    console.log(showErrorMessage);

    return (
        <Modal
            destroyOnClose
            style={{ top: 10 }}
            visible={true}
            confirmLoading={loading}
            title={t("ORDERS.PROFORMA.CREATE")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onCancel={cancel}
            footer={
                <Row gutter={[0, 2]} justify="end">
                    <Button onClick={cancel}>{t("CANCEL")}</Button>
                    <Button disabled={showErrorMessage} type="primary" onClick={() => form.submit()}>
                        {t("CREATE")}
                    </Button>
                </Row>
            }
        >
            {showErrorMessage ? (
                <Alert type="info" message={t("PROFORMAS.CREATE_ADD_ERROR_MESSAGE")} />
            ) : (
                <>
                    <Typography.Title level={5}>{t("PROFORMAS.ADD_ORDERS")}</Typography.Title>
                    {proformas.length > 0 ? (
                        <>
                            <Table
                                rowKey="id"
                                rowSelection={{
                                    type: "radio",
                                    onChange: (selectedRowKeys: React.Key[], proformas: Proforma[]) => {
                                        setSelectedProforma(proformas[0]);
                                    },
                                }}
                                columns={columns}
                                dataSource={[...proformas]}
                                components={vt}
                                scroll={{ y, x: true }}
                                pagination={false}
                                style={{ marginBottom: 10 }}
                            />
                            <Button
                                style={{ marginLeft: "auto", display: "block" }}
                                disabled={!selectedProforma}
                                type="primary"
                                onClick={addToProforma}
                            >
                                {t("ADD")}
                            </Button>
                            <Divider />
                        </>
                    ) : (
                        <span>{t("PROFORMAS.NO_CREATED_PROFORMAS")}</span>
                    )}
                    <Typography.Title level={5}>{t("PROFORMAS.CREATE_PROFORMAS")}</Typography.Title>
                    <Form
                        form={form}
                        layout="vertical"
                        name={"integrations_form"}
                        initialValues={defaultValue}
                        onFinish={() =>
                            form.validateFields().then((values) => {
                                setLoading(true);
                                onSubmit(values)
                                    .then(
                                        () => {
                                            form.resetFields();
                                            return onClose();
                                        },
                                        (e: AxiosError<ErrorResponseData>) => {
                                            if (e.response?.data.fields) {
                                                form.setFields(e.response?.data?.fields);
                                            }
                                        }
                                    )
                                    .then(
                                        () => {},
                                        (e) => form.setFields(e.response?.data?.fields)
                                    )
                                    .finally(() => setLoading(false));
                            })
                        }
                    >
                        <Row gutter={10}>
                            <Col span={24}>
                                <Form.Item
                                    name="date"
                                    label={t("DATE")}
                                    rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                                >
                                    <DatePicker
                                        disabled={loading}
                                        style={{ width: "100%" }}
                                        placeholder={t("DATE")}
                                        format="DD-MM-YYYY"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>{description}</Col>
                        </Row>
                    </Form>
                </>
            )}
        </Modal>
    );
};
