import React from "react";
import {WidgetProps} from "../widget";
import {DebounceSelect} from "../../../../debounce-select/debounce.select";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {agentService} from "../../../../../../services/agents/agent.service";

const clientOptionsFetcher = async (value: string) => {
    const accounts = await agentService.searchWholesaleAccount(value);
    return accounts.map(account => ({
        value: account.id,
        label: account.agent.number + " - " + account.agent.name
    }));
};

export const UIInputWidgetClient: React.FC<WidgetProps> = ({value, onChange}) => {

    const t = useTranslate();

    return (
        <DebounceSelect
            placeholder={t("CLIENT.SEARCH")}
            fetchOptions={clientOptionsFetcher}
            labelInValue={false}
            allowClear={true}
            value={value}
            onChange={onChange}
            style={{width: "280px"}}
        />
    )
}