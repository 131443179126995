import { branchesService } from "./../../../../../services/branches/branches.service";
import { action, makeObservable, observable } from "mobx";
import { warehousesService } from "../../../../../services/warehouses/warehouses.service";
import React from "react";
import { WarehouseExpanded } from "../../../../../models/warehouses/warehouses";
import { PagesStore } from "../../../../../stores/pages/pages.store";
import { Branch } from "../../../../../models/branches/branch";
import {notifications} from "../../../../../services/notifications/notifications";
import {NavigateFunction} from "react-router/dist/lib/hooks";

export class BranchDataWarehouseStore {
    @observable
    public loading: boolean = false;

    @observable
    public warehouse: WarehouseExpanded | null = null;

    @observable
    public branches: Branch[] = [];

    constructor(private branchId: string, private id: string, private pageStore: PagesStore, private url: string, private navigate: NavigateFunction) {
        makeObservable(this);
        this.init();
    }

    @action
    public setBranches(value: Branch[]): void {
        this.branches = value;
    }

    @action
    public setWarehouse(value: WarehouseExpanded): void {
        this.warehouse = value;
    }

    @action
    private setLoading(value: boolean): void {
        this.loading = value;
    }

    public editWarehouse = async (values: any) => {
        const warehouse = await warehousesService.updateBranchWarehouse(this.branchId, this.id, values);
        this.setWarehouse(warehouse);
        notifications.successfully();
    };

    public createWarehouse = async (values: any) => {
        const warehouse = await warehousesService.createBranchWarehouse(this.branchId, values);
        this.navigate("/settings/branches/" + this.branchId + "/warehouses/" + warehouse.id);
        notifications.successfully();
    };

    private async init() {
        this.setLoading(true);
        this.setBranches(await branchesService.getAllBranches());
        if (this.id !== "new") {
            const warehouse = await warehousesService.getBranchWarehouse(this.branchId, this.id);
            this.setWarehouse(warehouse);
            this.pageStore.updateRouteName(this.url, this.warehouse?.name);
        }
        this.setLoading(false);
    }
}

export const BranchDataWarehouseStoreContext = React.createContext<BranchDataWarehouseStore | null>(null);
