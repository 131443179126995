import React, {useMemo} from "react";
import {Modal, Table} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {DeclarationItem, UploadedDeclaration} from "../../upload/declaration.upload.model";
import {columnsFactory} from "./declaration.included-rows.columns";

interface DocumentFilesUploadModalProps {
    declaration: UploadedDeclaration;
    onClose: () => void;
}

export const DeclarationIncludedCodesModal: React.FC<DocumentFilesUploadModalProps> = ({declaration, onClose}) => {

    const t = useTranslate();

    const columns = useMemo(() => columnsFactory(t), [])

    const items = useMemo(() => {
        let result: DeclarationItem[] = [];
        declaration.parts.forEach(part => result = [...result, ...part.items])
        return result
    }, [declaration])

    return (
        <Modal
            width={"100%"}
            visible={true}
            title={t("Included Rows")}
            footer={false}
            onCancel={() => onClose()}
        >
            <Table
                columns={columns}
                dataSource={items}
                pagination={false}
            ></Table>
        </Modal>
    )
}