import React from "react";
import {Col, DatePicker, Form, Row} from "antd";
import {config} from "../config";
import {useTranslate} from "../../../../../hooks/translate.hook";

export interface UsePickupDateFieldsProps {
    loading: boolean;
    courierName: string;
}

export const PickupDateFields: React.FC<UsePickupDateFieldsProps> = ({loading, courierName}) => {

    const t = useTranslate();

    if (!config[courierName]?.usePickupDate) {
        return null;
    }

    return (
        <>
            <Row gutter={[20, 10]}>
                <Col>
                    <Form.Item
                        name="loadDate"
                        label="Pickup Date"
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <DatePicker disabled={loading}  />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}