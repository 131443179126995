import {Export} from "../../../../common/export/export";
import {Checkbox, Col, Form} from "antd";
import React from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import API from "../../../../../utils/api";

interface ReportsReportExportModalProps {
    name: string;
    query: any;
    onClose: () => void;
    onComplete: (id: number) => void;
}

export const ReportsReportExportModal: React.FC<ReportsReportExportModalProps> = ({name, query, onClose, onComplete}) => {
    const t = useTranslate();

    const onExportComplete = (link: string) => {
        API.get(link).then((r => {
            onComplete(r.data.id);
            onClose();
        }));
    }

    return (
        <Export
            title={"REPORTS.ORDERS.INVOICES.TITLE"}
            withBasicExportFields
            isShown={true}
            config={{
                link: "/reports/report/" + name + "/data",
                query: query,
            }}
            onClose={onClose}
            onComplete={onExportComplete}
        >
            <Col span={24}>
                <Form.Item
                    name={"onlySelectedPage"}
                    valuePropName={"checked"}
                >
                    <Checkbox>
                        {t(
                            "ARTICLES.WAREHOUSES.DOWNLOAD.SELECTED_PAGE"
                        )}
                    </Checkbox>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    name={"useFilters"}
                    valuePropName={"checked"}
                >
                    <Checkbox>
                        {t("ARTICLES.WAREHOUSES.DOWNLOAD.USE_FILTERS")}
                    </Checkbox>
                </Form.Item>
            </Col>
        </Export>
    )
}