import React, {PropsWithChildren, useEffect} from "react";
import ReactDOM from "react-dom";

interface PortalProps {
    parent?: HTMLElement,
    className: string
}
export const ArticleDataImagePortal: React.FC<PropsWithChildren<PortalProps>> = ({className, parent, children}) => {
    const el = React.useMemo(() => document.createElement("div"), []);
    useEffect(() => {
        const target = parent ? parent : document.body;
        const classList = ["portal-container"];
        if (className) className.split(" ").forEach((item) => classList.push(item));
        classList.forEach((item) => el.classList.add(item));
        target.appendChild(el);
        return () => {
            target.removeChild(el);
        };
    }, [el, parent, className])
    return <>{ReactDOM.createPortal(children, el)}</>;
}
