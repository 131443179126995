import {
    ThresholdsCreateRequestValues,
    ThresholdsCreateRequestValuesThreshold,
} from "./../../models/thresholds/threshold";
import { PaginatedResult } from "./../../models/core/paginated.result";
import { PaginatedRequest } from "./../../models/core/paginated.request";
import { AxiosInstance } from "axios";
import API from "../../utils/api";
import { Threshold } from "../../models/thresholds/threshold";

export class ThresholdsService {
    private api: AxiosInstance = API;

    getThresholds(
        request: PaginatedRequest,
        accountId: number
    ): Promise<PaginatedResult<Threshold>> {
        return this.api
            .get<PaginatedResult<Threshold>>(
                "accounts/" + accountId + "/aftersale/configs",
                {
                    params: request.toParams(),
                }
            )
            .then((x) => x.data);
    }

    createThreshold(
        accountId: number,
        values: ThresholdsCreateRequestValues
    ): Promise<Threshold> {
        return this.api
            .post("accounts/" + accountId + "/aftersale/configs", values)
            .then((x) => x.data);
    }

    updateThreshold(
        id: number,
        values: { thresholds: ThresholdsCreateRequestValuesThreshold[] }
    ): Promise<Threshold> {
        return this.api
            .post("aftersale/configs/" + id, values)
            .then((x) => x.data);
    }

    removeThreshold(id: number): Promise<void> {
        return this.api
            .delete<void>("aftersale/configs/" + id)
            .then((x) => x.data);
    }
}

export const thresholdsService = new ThresholdsService();
