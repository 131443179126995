import API from "../../utils/api";
import {AxiosInstance} from "axios";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";
import {Error} from "../../models/errors/error";

export class ErrorsService {
    private api: AxiosInstance = API;

    public getGroups(): Promise<string[]> {
        return this.api.get<string[]>("warnings/groups").then(x => x.data)
    }

    public getErrors(request: PaginatedRequest, group?: string): Promise<PaginatedResult<Error[]>> {
        if (group) {
            request.addFilter("group", group);
        }
        return this.api.get<PaginatedResult<Error[]>>(
            "warnings", {
                params: request.toParams()
            }
        ).then(x => x.data)
    }
}

export const errorsService = new ErrorsService();
