import React, {useState} from "react";
import {Button, Col, Descriptions, Row, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserDataCreateModal} from "../user-data-create-form/user.data.create.modal";
import {UserDataConnectModal} from "../user-data-connect-modal/user.data.connect.modal";
import {UserModal} from "../../user-modal/user.modal";
import {useRequiredStore} from "../../../../../utils/store";
import {UserDataStoreContext} from "../user.data.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";
import {useNavigate} from "react-router";


export const UserDataHeader: React.FC = () => {
    const t = useTranslate();
    const navigate = useNavigate();
    const store = useRequiredStore(UserDataStoreContext);

    const [isCreateModalShown, setIsCreateModalShown] = useState<boolean>(false);
    const [isConnectModalShown, setIsConnectModalShown] = useState<boolean>(false);
    const [isEditModalShown, setIsEditModalShown] = useState<boolean>(false);

    return (
      <>
          <Row align={"middle"} justify={"space-between"}>
              <Col md={24} lg={24} xl={24}>
                  <PageHeader
                      style={{padding: 0}}
                      ghost={false}
                      title={store.userData?.firstName + " " + store.userData?.lastName}
                      onBack={() => {
                          navigate("/users");
                      }}
                      extra={[
                          <Tooltip key={1} placement="topRight" title={t("EDIT")}>
                              <Button
                                  onClick={() => setIsEditModalShown(true)}
                                  type="default"
                                  icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                              />
                          </Tooltip>,
                          <Tooltip key={2} placement="topRight" title={t("USER.PROFILE.CONNECT_TITLE")}>
                              <Button
                                  onClick={() => setIsConnectModalShown(true)}
                                  type="default"
                                  icon={<FontAwesomeIcon icon={["fas", "link"]}/>}
                              />
                          </Tooltip>,
                          <Tooltip key={3} placement="topRight" title={t("USER.PROFILE.ADD")}>
                              <Button
                                  onClick={() => setIsCreateModalShown(true)}
                                  type="default"
                                  icon={<FontAwesomeIcon icon={["fas", "plus"]}/>}
                              />
                          </Tooltip>
                      ]}
                  >
                      <Descriptions
                          labelStyle={{color: "#858585"}}
                          size={"small"}
                          column={1}
                      >
                          <Descriptions.Item label={t("EMAIL")}>{store.userData?.email}</Descriptions.Item>
                      </Descriptions>
                  </PageHeader>
              </Col>
          </Row>
          <UserDataCreateModal
              user={store.userData!}
              visible={isCreateModalShown}
              onClose={() => setIsCreateModalShown(false)}
          />
          <UserDataConnectModal
              visible={isConnectModalShown}
              onClose={() => setIsConnectModalShown(false)}
          />
          <UserModal
            visible={isEditModalShown}
            userData={{
                id: store.userData!.id,
                firstName: store.userData!.firstName,
                lastName: store.userData!.lastName,
                email: store.userData!.email
            }}
            onSubmit={store.editUser}
            onClose={() => setIsEditModalShown(false)}
          />
      </>
    );
}
