import {Translate} from "../../../../../hooks/translate.hook";
import {FilterKey} from "../../../../../models/filtered-table/filtered.table.props";

export const filtersFactory = (t: Translate): FilterKey[] => {
    return [
        {
            key: "id",
            label: "ID",
            type: "string",
        },
        {
            key: "orderId",
            label: t("ORDERS.ORDER.TITLE"),
            type: "string",
        },
        {
            key: "docNumber",
            label: t("INVOICES.NUMBER"),
            type: "string",
        },
        // {
        //     key: "packingList",
        //     label: t("PACKING_LIST"),
        //     type: "string"
        // },
        {
            key: "grandTotal",
            label: t("TOTAL_AMOUNT"),
            type: "int",
        },
        // {
        //     key: "warehouses",
        //     label: t("WAREHOUSES.TITLE"),
        //     type: "select",
        //     options: warehouses.map(({name, id}) => ({
        //         value: id,
        //         label: name
        //     }))
        // },
        {
            key: "totalBruttoWeight",
            label: t("WEIGHT_BRUTTO"),
            type: "int",
        },
        {
            key: "clientPaymentTerms",
            label: t("PAYMENT_TERMS"),
            type: "int",
        },
        {
            key: "isDispatched",
            label: t("INVOICES.DISPATCH.IS_DISPATCHED"),
            type: "select",
            options: [
                { value: "1", label: t("INVOICES.DISPATCH.DISPATCHED") },
                {
                    value: "0",
                    label: t("INVOICES.DISPATCH.NOT_DISPATCHED"),
                },
            ],
        },
        {
            key: "date",
            label: t("INVOICES.DISPATCH.DISPATCH_DATE"),
            type: "date",
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date",
        }
    ];
}