import React, {useMemo} from "react";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {Link, useParams} from "react-router-dom";
import Title from "antd/es/typography/Title";
import {Button, Space} from "antd";
import {SmartTable} from "../../../../common/smart-table/smart-table";
import {SmartTableDataHandler} from "../../../../common/smart-table/smart-table.data-handler";
import {columnFactory} from "./list.pages.columns";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {webSiteService} from "../../../../../services/web-site/web-site.service";

export const ListPagesWebSiteSettings: React.FC<ComponentPropsFromRoute> = () => {

    const translate = useTranslate();
    const { id } = useParams<{id: string}>();

    const columns = useMemo(() => columnFactory(translate), []);
    const handler = useMemo(() => new SmartTableDataHandler(r => webSiteService.getAllPagesInCategory(parseInt(id!), r)), [id]);

    return (
        <Space direction={"vertical"}>
            <Title level={4}>{"Pages in selected category"}</Title>
            <Link to={"/settings/web-site/categories/" + id + "/new-page"}>
                <Button type={"primary"}>Create a new page</Button>
            </Link>
            <SmartTable
                handler={handler}
                columns={columns}
            />
        </Space>
    );
}