import React, { MouseEventHandler, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Link, useLocation } from "react-router-dom";
import { useRequiredStore } from "../../utils/store";
import { RootStoreContext } from "../../stores/root/root.store";
import {RouteConfig, routers} from "../../routers/routers";
import febest_favicon from "./febest_favicon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isAllowed } from "../../utils/helpers";
import {Button} from "antd";
import "./navigation.scss";
import {useTranslate} from "../../hooks/translate.hook";
const NavigationItemWithImg = ({ path, img, onClick }: any) => {
    return (
        <React.Fragment>
            <div className={"nav-item menu-logo"}>
                <Link to={path} className={"red"} onClick={onClick}>
                    <div className={"nav-img-container"}>
                        <img src={img} alt={"FMS Logo"} />
                    </div>
                </Link>
            </div>
        </React.Fragment>
    );
};

const NavigationItemButton = ({
    name,
    iconName,
    className = "blue",
    elementModifier = "",
    onClick,
    text,
    danger,
}: any) => {
    const onClickHandler: MouseEventHandler<HTMLAnchorElement> = (event) => {
        event.preventDefault();
        onClick();
    };

    return (
        <div
            className={
                "nav-item" + (elementModifier ? " " + elementModifier : "")
            }
        >
            <a
                className={danger ? "color-danger red" : className}
                onClick={onClickHandler}
            >
                <div
                    className={"nav-img-container"}
                    style={{ alignItems: "center", justifyContent: "center" }}
                >
                    {iconName ? (
                        <FontAwesomeIcon icon={["fas", iconName]} size={"lg"} />
                    ) : null}
                    {text ? (
                        <span className={"nav-initials"}>{text}</span>
                    ) : null}
                </div>
                <span>{name}</span>
            </a>
        </div>
    );
};

const NavigationItem = ({
    path,
    name,
    iconName,
    className = "blue",
    elementModifier = "",
    onClick,
    text,
    danger,
}: any) => {
    const { pathname } = useLocation();
    const [_, pageRoute] = path.split("/");
    const [__, routeFromPathName] = pathname.split("/");
    const active = routeFromPathName.indexOf(pageRoute.slice(0, -1)) >= 0;

    return (
        <div
            className={
                "nav-item" + (elementModifier ? " " + elementModifier : "")
            }
        >
            <Link
                to={path}
                className={
                    danger
                        ? "red color-danger" + (active ? " active" : "")
                        : className + (active ? " active" : "")
                }
                onClick={onClick}
            >
                <div
                    className={"nav-img-container"}
                    style={{ alignItems: "center", justifyContent: "center" }}
                >
                    {iconName ? (
                        <FontAwesomeIcon icon={["fas", iconName]} size={"lg"} />
                    ) : null}
                    {text ? (
                        <span className={"nav-initials"}>{text}</span>
                    ) : null}
                </div>
                <span>{name}</span>
            </Link>
        </div>
    );
};

const Logout = ({
                    onClick,

                }: any) => {
    const iconName="power-off",
        className = "red",
        elementModifier="nav-logout-button";

    return (
        <div
            className={
                "nav-item" + (elementModifier ? " " + elementModifier : "")
            }
        >
            <button onClick={onClick}>
                <div
                    className={"nav-img-container"}
                    style={{ alignItems: "center", justifyContent: "center" }}
                >
                    <FontAwesomeIcon icon={["fas", iconName]} size={"lg"} />
                </div>
            </button>
        </div>
    )
}


const NavigationCollapseButton = ({ openCloseState, onClick }: any) => {
    const iconName = openCloseState ? "chevron-left" : "chevron-right";
    return (
        <div className={"nav-item nav-roll-up-menu-button"}>
            <button onClick={onClick}>
                <div className={"nav-img-container"}>
                    <FontAwesomeIcon icon={["fas", iconName]} size={"lg"} />
                </div>
            </button>
        </div>
    );
};

const NavigationOpenMenuButton = ({ onClick, fullScreenModeState }: any) => {
    const iconName = fullScreenModeState ? "times" : "bars";
    const [hidden, setHidden] = useState(window.innerWidth > 840);

    useEffect(() => {
        const resize = (event: any) => {
            setHidden(event.currentTarget.innerWidth > 840);
        };

        window.addEventListener("resize", resize);
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);

    return (
        <div
            style={{ display: hidden ? "none" : "flex" }}
            className={"nav-item nav-open-close-menu-button"}
        >
            <button
                onClick={onClick}
                className={fullScreenModeState ? "red" : "blue"}
            >
                <div className={"nav-img-container"}>
                    <FontAwesomeIcon icon={["fas", iconName]} size={"lg"} />
                </div>
            </button>
        </div>
    );
};

const LeftSideNavigationPanel = observer(({ routers }: any) => {
    const t = useTranslate();
    const store = useRequiredStore(RootStoreContext);
    const [modalShown, setModalShown] = useState<boolean>(false);

    const resize = (event: any) => store.menuStore.setState(event);

    window.addEventListener("resize", resize);

    useEffect(() => {
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, [resize]);

    return (
        <>
            <div className={"navigation " + store.menuStore.getStateForMenu}>
                <div className={"nav-main-container"}>
                    <NavigationItemWithImg
                        path={"/"}
                        img={febest_favicon}
                        onClick={(event: any) =>
                            store.menuStore.toggleFullScreenModeState(event)
                        }
                    />
                    <div className={"nav-items-container"}>
                        {routers.map((route: RouteConfig, key: number) =>
                            route.viewInNavigationPanel === true &&
                            isAllowed(
                                store.authStore.attributes,
                                route.access
                            ) ? (
                                <NavigationItem
                                    className={
                                        route.name === "my_page" &&
                                        store.authStore.isUserFaked
                                            ? "red"
                                            : undefined
                                    }
                                    key={key}
                                    path={route.path}
                                    name={t(route.systemName)}
                                    danger={
                                        route.name === "my_page" &&
                                        store.authStore.isUserFaked
                                    }
                                    iconName={
                                        !route.iconName
                                            ? null
                                            : route.iconName.length
                                            ? route.iconName
                                            : null
                                    }
                                    text={
                                        route.name === "my_page"
                                            ? store.authStore.user!
                                                  .firstName[0] +
                                              store.authStore.user!.lastName[0]
                                            : null
                                    }
                                    onClick={(event: any) =>
                                        store.menuStore.toggleFullScreenModeState(
                                            event
                                        )
                                    }
                                />
                            ) : null
                        )}
                    </div>
                    <NavigationOpenMenuButton
                        onClick={(event: any) =>
                            store.menuStore.toggleFullScreenModeState(event)
                        }
                        fullScreenModeState={
                            store.menuStore.fullScreenModeState
                        }
                    />
                    <Logout onClick={store.authStore.logout} />
                    <NavigationCollapseButton
                        openCloseState={store.menuStore.openCloseState}
                        onClick={() => store.menuStore.toggleOpenCloseState()}
                    />
                </div>
            </div>
        </>
    );
});

export const LeftSideNavigation = observer(() => {
    const store = useRequiredStore(RootStoreContext);

    return (
        <React.Fragment>
            {store.authStore.isLoggedIn ? (
                <LeftSideNavigationPanel routers={routers} />
            ) : null}
        </React.Fragment>
    );
});

export default LeftSideNavigation;
