import React, {useMemo, useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Modal} from "antd";
import { observer } from "mobx-react";
import {WholesaleAccount} from "../../../../models/accounts/customer-account/wholesale-account/wholesale.account";
import {StepConfig, Stepper} from "../../../common/stepper/stepper";
import {GeneralStep} from "./steps/general/agent.new.step.general";
import {PriceStep} from "./steps/price/agent.new.step.price";
import {ShipmentsStep} from "./steps/shpiments/agent.new.step.shipments";
import {DocumentsStep} from "./steps/documents/agent.new.step.documents";
import {AddressesStep} from "./steps/addresses/agent.new.step.addresses";
import {AccountStep} from "./steps/accounts/agent.new.step.account";
import {resolveDefaults, resolveDefaultsForAccount} from "./agent.new.functions";
import {WarehousesStep} from "./steps/warehouses/agent.new.step.warehouses";

interface AgentDataHeaderModalProps {
    data?: WholesaleAccount | null;
    visible: boolean;
    onClose: () => void;
    onComplete: (values: any) => Promise<any>;
}

interface AgentNewContextValue {
    step: number;
    setStep: (step: number) => void;
    onComplete: () => Promise<any>;
    setValue: (key: string, value: any) => any;
    getValue: (key: string) => any;
    fields: any;
}

export const stepBlockMap: Record<string, number> = {
    "account": 0,
    "agent": 0,
    "warehouses": 1,
    "price": 2,
    "documents": 3,
    "shipments": 4,
    "billingAddress": 5,
    "shippingAddress": 5,
    "user": 6
}

const steps: StepConfig<{}>[] = [
    {
        title: 'General',
        description: '',
        component: GeneralStep
    },
    {
        title: 'Warehouse',
        description: '',
        component: WarehousesStep
    },
    {
        title: 'Price',
        description: '',
        component: PriceStep
    },
    {
        title: 'Documents',
        description: '',
        component: DocumentsStep
    },
    {
        title: 'Shipments',
        description: '',
        component: ShipmentsStep
    },
    {
        title: 'Addresses',
        description: '',
        component: AddressesStep
    },
    {
        title: 'Account',
        description: '',
        component: AccountStep
    }
]

export const AgentNewContext = React.createContext<AgentNewContextValue>({
    step: 0,
    fields: null,
    setStep: (step: number) => null,
    onComplete: () => Promise.reject(),
    setValue: (key: string, value: any) => null,
    getValue: (key: string) => null,
});

export const AgentNewModal: React.FC<AgentDataHeaderModalProps> = observer(
    ({ visible, onClose, onComplete, data }) => {
        const t = useTranslate();
        const [step, setStep] = useState<number>(0);
        const [fields, setFields] = useState<any[]>([]);
        const values = useMemo<Record<string, any>>(() => {
            if (data) {
                return resolveDefaultsForAccount(data);
            }
            return resolveDefaults();
        }, [data?.id])

        return (
            <Modal
                title={data ? "Accept request" : "Create a new agent"}
                visible={visible}
                onCancel={onClose}
                width={"950px"}
                footer={false}
                maskClosable={false}
            >
                <AgentNewContext.Provider value={{
                    fields,
                    step,
                    setStep,
                    onComplete: async () => {
                        try {
                            setFields([]);
                            await onComplete(values)
                            onClose()
                        } catch (e: any) {
                            const fields = e.response?.data?.fields;
                            if (fields) {
                                setFields(fields)
                                const step = stepBlockMap[fields[0]?.name[0]];
                                if (step) {
                                    setStep(step)
                                }
                            }
                        }
                    },
                    setValue: (key: string, value: any) => values[key] = value,
                    getValue: (key: string) => values[key] || null
                }}>
                    <Stepper current={step} steps={steps} />
                </AgentNewContext.Provider>
            </Modal>
        );
    }
);
