import {OrderDocumentWithType} from "../../../../models/orders/order-data/order.data";
import {Document} from "../../../../models/orders/order-data/order.data";

const prefixMap: Record<Document, string> = {
    [Document.PROFORMA]: 'proformas',
    [Document.INVOICE]: 'invoices',
    [Document.MERGED_INVOICE]: 'merged-invoices',
    [Document.CREDIT_INVOICE]: 'credit-invoices',
    [Document.PACKING_LIST]: 'packing-lists',
    [Document.RETURN]: 'returns'
}

export const generateDocumentUrl = (document: OrderDocumentWithType) => {
    return '/' + prefixMap[document.type] + '/' + document.id;
}