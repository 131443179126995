import React, {useState} from "react";
import {Input} from "antd";
import {WidgetProps} from "../model/model";

export const StringWidget: React.FC<WidgetProps> = ({onChange, value, placeholder}) => {

    const [realValue, setRealValue] = useState<string>(((value as any) as string || "").slice(1))

    return (
        <Input
            variant={"filled"}
            placeholder={placeholder}
            value={realValue}
            onChange={e => setRealValue(e.target.value)}
            onPressEnter={e => {
                let fieldValue = (e.target as any).value;
                if (fieldValue) {
                    fieldValue = '%' + fieldValue;
                }

                return onChange ? onChange(fieldValue || undefined) : null
            }}
        />
    )
}