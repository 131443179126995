import {Translate} from "../../../hooks/translate.hook";
import {SmartColumn} from "../../common/smart-table/smart-table.model";
import {DeclarationProvider} from "../upload/declaration.upload.model";
import {FieldType} from "../../common/smart-table/smart-table.field-config";

export const columnsFactory = (t: Translate): SmartColumn<DeclarationProvider>[] => {
    return [
        {
            visibleName: "ID",
            dataIndex: "id",
            name: "id",
            type: FieldType.INTEGER,
            filterable: true
        },
        {
            visibleName: t("FIRSTNAME"),
            dataIndex: "firstName",
            name: "firstName",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: t("LASTNAME"),
            dataIndex: "lastName",
            name: "lastName",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: "Isikukood",
            dataIndex: "isikCode",
            name: "isikCode",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: t("EMAIL"),
            dataIndex: "email",
            name: "email",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: t("PHONE"),
            dataIndex: "phone",
            name: "phone",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: t("CITY"),
            dataIndex: "city",
            name: "city",
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: t("CREATED_AT"),
            dataIndex: "createdAt",
            name: "createdAt",
            type: FieldType.DATETIME,
            filterable: true
        },
        {
            visibleName: t("UPDATED_AT"),
            dataIndex: "updatedAt",
            name: "updatedAt",
            type: FieldType.DATETIME,
            filterable: true
        }
    ]
}