import React, {useCallback, useState} from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../../utils/store";
import {AgentDataStoreContext} from "../../agent.data.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Button, Checkbox, Col, Form, Input, Row, Select, Typography} from "antd";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {isAllowed} from "../../../../../utils/helpers";
import {agentService} from "../../../../../services/agents/agent.service";
import {accountService} from "../../../../../services/accounts/account.service";
import {notifications} from "../../../../../services/notifications/notifications";
import {
    UiInputWidgetPurchasingAlliance
} from "../../../../common/ui/input/widget/settings/ui.input.widget.purchasing-alliance";

export const AgentDataAgentAgent: React.FC<{}> = observer(({}) => {
    const {account} = useRequiredStore(AgentDataStoreContext);
    const { langStore, commonStore, authStore } = useRequiredStore(RootStoreContext);

    const t = useTranslate();

    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);

    const [form] = Form.useForm();

    if (!account) {
        return <></>;
    }

    const submit = async (values: any) => {
        setLoading(true)
        try {
            await accountService.updateAgent(account.id, values)
            notifications.successfully()
        } catch (e: any) {
            if (e.response?.data.fields) {
                form.setFields(e.response?.data?.fields);
            }
        } finally {
            setLoading(false);
        }
    }

    const agent = account.agent;

    const defaultValues = {
        group: agent.group,
        country: agent.country.id,
        number: agent.number,
        name: agent.name,
        businessName: agent.businessName,
        visibleName: agent.visibleName,
        comment: agent.comment,
        icAccessGroup: agent.icAccessGroup?.id,
        purchasingAlliance: agent.purchasingAlliance?.id,
        purchasingAlliance2: agent.purchasingAlliance2?.id
    }

    const onChange = useCallback((value: string) => {
        if (value.length >= 3 || account?.agent.number !== value) {
            agentService.getByNumber(value)
                .then(agent => {
                    if (-1 !== agent.branches.findIndex(branch => branch.id === authStore.branch)) {
                        form.setFields([{name: ['agent', 'number'], errors: [t('AGENTS.DUPLICATE_ERROR')]}])
                    } else {
                        form.setFieldsValue({agent: {...agent, country: agent?.country?.id}})
                    }
                })
        }
    }, [])

    return <>
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            name={"client_form"}
            initialValues={defaultValues}
            onFinish={async (values) => {
                values.bank = values.bank?.id
                await form.validateFields();
                await submit(values)
            }}
        >
            <Typography.Title level={3}>Agent settings</Typography.Title>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={6}>
                    <Form.Item
                        name={["group"]}
                        label={t("CLIENT.GROUP")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            disabled={loading || disabled}
                            placeholder={t("CLIENT.GROUP")}
                        >
                            {commonStore.agentGroups.map((item) => (
                                <Select.Option key={item} value={item}>
                                    {item}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(oldValue, newValue) =>
                            oldValue.changeNumberEnabled !== newValue.changeNumberEnabled
                        }
                    >
                        {() => (
                            <Form.Item
                                name={["number"]}
                                label={t("CLIENT.CODE")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Input
                                    onChange={event => onChange(event.target.value)}
                                    disabled={
                                        !account ? false : !form.getFieldValue("changeNumberEnabled")
                                    }
                                    type="text"
                                    placeholder={t("CLIENT.CODE")}
                                />

                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item noStyle name="changeNumberEnabled" valuePropName={"checked"}>
                        <Checkbox
                            className="checkbox-small"
                            disabled={loading || disabled}
                            onChange={(e) =>
                                !e.target.checked &&
                                form.setFieldsValue({
                                    number: account?.agent.number,
                                })
                            }
                        >
                            {t("CLIENT.CHANGE_CODE")}
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={["businessName"]}
                        label={t("BUSINESS_NAME")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input disabled={loading || disabled} type="text" placeholder={t("USE_LEGAL_NAME")} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={["country"]}
                        label={t("COUNTRY")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            placeholder={t("COUNTRY_SELECT")}
                            showSearch
                            disabled={loading || !!(!isAllowed(authStore.attributes, "faker") && account)}
                            optionFilterProp={"children"}
                        >
                            {langStore.countries.map(({ id, name }) => (
                                <Select.Option key={id as string} value={id as string}>
                                    {name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={["name"]}
                        label={t("CLIENT.NAME")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input disabled={loading || disabled} type="text" placeholder={t("USE_LEGAL_NAME")} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={["visibleName"]}
                        label={t("AGENT.VISIBLE_NAME")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input disabled={loading || disabled} type="text" placeholder={t("AGENT.VISIBLE_NAME")} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                </Col>
                <Col span={12}>
                    <Form.Item name={["comment"]} label={t("COMMENT")}>
                        <Input disabled={loading || disabled} type="text" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={["icAccessGroup"]}
                        label={t("CLIENT.IC.ACCESS_GROUP")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Select disabled={loading || disabled} placeholder={t("CLIENT.IC.ACCESS_GROUP")}>
                            {commonStore.agentICAccessGroups.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={18}>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={["purchasingAlliance"]}
                        label={t("CLIENT.PURCHASING_ALLIANCE.TIER1")}
                    >
                        <UiInputWidgetPurchasingAlliance type={'tier1'} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={["purchasingAlliance2"]}
                        label={t("CLIENT.PURCHASING_ALLIANCE.TIER2")}
                    >
                        <UiInputWidgetPurchasingAlliance type={'tier2'} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Button type={"primary"} onClick={() => form.submit()}>{t("SAVE")}</Button>
                </Col>
            </Row>
        </Form>
    </>
});
