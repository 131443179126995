import {SystemUser} from "../users/user/system.user";
import {InvoiceDataDocument} from "../documents/invoices/invoice-data/invoice.data";

export interface UploadMonthlyStatement {
    year: number;
    month: number;
    file: File;
}

export enum MonthlyStatementItemStatus {
    OK                 = "ok",
    SKIPPED            = "skipped",
    NOT_EXISTS         = "not_exists",
    RETAIL_INVOICE     = "retail_invoice",
    INVOICE_NOT_FOUND  = "invoice_not_found",
    DIFFERENT_MONTH    = "different_month",
    DIFFERENT_DATE     = "err_date",
    DIFFERENT_SUBTOTAL = "err_subtotal",
    DIFFERENT_TAX      = "err_tax",
    DIFFERENT_GRAND_TOTAL = "err_grand_total",
    DOCUMENT_1C_NOT_FOUND = "1c_not_found",
    DIFFERENT_1C_DATE     = "1c_err_date",
    DIFFERENT_1C_SUBTOTAL = "1c_err_subtotal",
    DIFFERENT_1C_TAX      = "1c_err_tax",
    DIFFERENT_1C_GRAND_TOTAL = "1c_err_grand_total",
}

export enum MonthlyStatementItemType {
    INVOICE        = "invoice",
    CREDIT_INVOICE = "credit_invoice"
}

export type MonthlyStatementItemStatusColor = Record<MonthlyStatementItemStatus, string>;

export const monthlyStatementItemStatusColor: MonthlyStatementItemStatusColor =
    {
        ok:                "green",
        skipped:           "green",
        not_exists:        "red",
        retail_invoice:    "green",
        invoice_not_found: "orange",
        different_month:   "orange",
        err_subtotal:      "red",
        err_tax:           "red",
        err_grand_total:   "red",
        err_date:          "red",
        "1c_not_found":       "red",
        "1c_err_subtotal":    "red",
        "1c_err_tax":         "red",
        "1c_err_grand_total": "red",
        "1c_err_date":        "red"
    }

export interface MonthlyStatement {
    id: number;
    year: number;
    month: number;
    replaced: boolean;
    accountant: SystemUser;
    mismatches: number;
    items: MonthlyStatementItem[];
}

export interface MonthlyStatementItem {
    type: string;
    invoiceNumber: string;
    invoiceDocument?: InvoiceDataDocument;
    status: MonthlyStatementItemStatus;
    invoiceSubtotal: number;
    invoiceVat: number;
    invoiceGrandTotal: number;
    invoiceDate: string;
    subtotal: number;
    vat: number;
    grandTotal: number;
    date: string;
    icSubtotal: number;
    icVat: number;
    icGrandTotal: number;
    icDate: string;
}