import React from "react";
import {observer} from "mobx-react";
import {Button, Modal, Row, Typography} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../utils/store";
import {OrdersCheckoutStoreContext} from "../orders.checkout.store";

export const OrdersCheckoutCancelOrderModal: React.FC = observer(() => {
    const store = useRequiredStore(OrdersCheckoutStoreContext)
    const t = useTranslate();

    return (
        <Modal
            centered
            visible={store.cancelOrderModalShown}
            title={t("ORDERS.CANCEL.TITLE")}
            onCancel={store.closeCancelOrderModal}
            footer={
                <Row justify="end">
                    <Button type="primary" onClick={store.moveOrderToCart}>
                        {t("ORDERS.CANCEL.MOVE_TO_CART")}
                    </Button>
                    <Button danger type="primary" onClick={store.cancelOrder}>
                        {t("ORDERS.CANCEL.TITLE")}
                    </Button>
                </Row>
            }
        >
            <Typography.Paragraph>
                {t("ORDERS.CANCEL.TEXT")}
            </Typography.Paragraph>
        </Modal>
    )
})