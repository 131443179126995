import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Select} from "antd";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import {useTranslate} from "../../../hooks/translate.hook";
import {fakerService} from "../../../services/faker/faker.service";

export const SwitchBranchSubMenu: React.FC = observer(() => {

    const t = useTranslate();
    const { commonStore, authStore } = useRequiredStore(RootStoreContext);

    const [disabled, setDisabled] = useState<boolean>(false);
    const [value, setValue] = useState<string>("");

    const onSelect = async (value: string) => {
        setDisabled(true)
        setValue(value)
        await fakerService.switchBranch(value);
        window.location.reload();
    }

    const onClear = async () => {
        setDisabled(true)
        setValue(value)
        await fakerService.resetBranch();
        window.location.reload();
    }

    useEffect(() => {
        setValue(authStore.branch)
    }, [authStore.branch])

    return (
        <Select
            disabled={disabled}
            style={{width: 300}}
            allowClear
            placeholder={t("BRANCHES.TITLE")}
            showSearch
            onSelect={onSelect}
            onClear={onClear}
            value={value}
        >
            {commonStore.branches.map((branch) => (
                <Select.Option key={branch.id} value={branch.id}>
                    {branch.name}
                </Select.Option>
            ))}
        </Select>
    )
});