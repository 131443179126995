import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {Button, Col, Form, Row, Space, Typography} from "antd";
import {AgentNewContext} from "../../agent.new.modal";
import {WarehousesFormList} from "../../../../../common/warehouses-form-list/warehouses.form.list";
import {UIInputWidgetWarehouse} from "../../../../../common/ui/input/widget/warehouse/ui.input.widget.warehouse";
import {WarehouseWithSort} from "../../../../../../models/warehouses/warehouse";

export const WarehousesStep: React.FC = observer(() => {

    const {setStep, setValue, getValue, fields} = React.useContext(AgentNewContext);

    const t = useTranslate();

    const [loading, setLoading] = useState<boolean>(false);

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFields(fields);
    }, [fields])

    const defaultValues = {
        warehouses: getValue('warehouses'),
        defaultWarehouse: getValue('defaultWarehouse')
    }

    return <Space direction="vertical" size={[15, 15]} style={{marginTop: 40}}>
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            initialValues={defaultValues}
            onFinish={async (values) => {
                await form.validateFields();
                setValue('warehouses', values.warehouses)
                setValue('defaultWarehouse', values.defaultWarehouse)
                setStep(2)
            }}
        >
            <Typography.Title level={3}>{t("CLIENT.WAREHOUSES.TITLE")}</Typography.Title>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={12}>
                    <WarehousesFormList disabled={loading} form={form} withMaxAmount={true} withTransactionType={true} />
                </Col>
            </Row>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={12}>
                    <Form.Item
                        noStyle
                        shouldUpdate={true}
                    >
                        {() => (
                            <Form.Item
                                name={["defaultWarehouse"]}
                                label={t("CLIENT.WAREHOUSES.DEFAULT_WAREHOUSE")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <UIInputWidgetWarehouse allowedOnly={(form.getFieldValue('warehouses') || []).map((w: WarehouseWithSort) => w.warehouse)} />
                            </Form.Item>
                        )}
                    </Form.Item>                </Col>
            </Row>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={24}>
                    <Space direction={"horizontal"} size={[10, 10]}>
                        <Button type={"default"} onClick={() => setStep(0)}>Back</Button>
                        <Button type={"primary"} onClick={() => form.submit()}>Next</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    </Space>
});
