import React from "react";
import {ColumnsType} from "antd/es/table";
import { Collapse, Popover, Space, Tag } from "antd";
import {useTranslate} from "../../../hooks/translate.hook";
import "./smart-logger.scss";
import {SmartLoggerTableWrapper} from "../../common/smart-logger-table-wrapper/smart.logger.table.wrapper";
import {SmartLoggerData} from "../../../models/logger/logger.data";
import {loggerService} from "../../../services/logger/logger.service";
import {SmartLoggerChangesWrapper} from "../../smart-logger/smart-logger-changes/smart.logger.changes.wrapper";

const typeColors: { [key: string]: string } = {
    update: "gold",
    create: "green",
    remove: "red",
};

export interface SmartLoggerProps {
    entityName: string;
    entityId: string;
}

export const SmartLogger: React.FC<SmartLoggerProps> = ({entityName, entityId}) => {

    const t = useTranslate();

    const columns: ColumnsType<SmartLoggerData> = [
        {
            title: t("TYPE"),
            dataIndex: "type",
            key: "logger_type",
            render: (item) => <Tag color={typeColors[item]}>{item.beautify()}</Tag>,
        },
        {
            title: t("LOGGER.ENTITY.NAME"),
            dataIndex: "entity_name",
            key: "logger_entity_name",
            render: (item) => <strong>{item}</strong>,
        },
        {
            title: t("LOGGER.ENTITY.ID"),
            dataIndex: "entity_id",
            key: "logger_entity_id",
        },
        {
            title: t("UPDATED_AT"),
            dataIndex: "created_at",
            key: "logger_created_at",
            render: (item: string) => <strong>{item}</strong>,
        },
        {
            title: t("ACTION"),
            dataIndex: "action_name",
            key: "logger_action_name",
            render: (item: string, row) => {
                const attributes =
                    !Array.isArray(row.action_params) && Object.keys(row.action_params).length > 0
                        ? (row.action_params as { [k: string]: any })
                        : [];
                return !Array.isArray(attributes) ? (
                    <Popover
                        key={row.id + "_" + item}
                        trigger="click"
                        placement="bottom"
                        content={
                            <Space direction="vertical" size={[5, 5]}>
                                {Object.keys(attributes).map((key, index) => (
                                    <p key={key + "_" + index + "_" + row.id} style={{ margin: 0 }}>
                                        <span>{key}: </span>
                                        <span>{attributes[key]}</span>
                                    </p>
                                ))}
                            </Space>
                        }
                        title={<strong>{t("LOGGER.ATTRIBUTES")}</strong>}
                    >
                        <span style={{ cursor: "pointer", borderBottom: "1px dotted #000", paddingBottom: 2 }}>
                            {item}
                        </span>
                    </Popover>
                ) : (
                    item
                );
            },
        },
        {
            title: t("USERNAME"),
            dataIndex: "username",
            key: "logger_username",
        },
        {
            title: t("IP_ADDRESS"),
            dataIndex: "ip_address",
            key: "logger_ip_address",
        },
        {
            title: t("SYSTEM"),
            dataIndex: "system",
            key: "logger_system",
        },
    ];

    return (
        <Collapse
            className="logger-collapse"
            // style={{width: "100%"}}
            // expandIconPosition="right"
            destroyInactivePanel={false}
            bordered={false}
        >
            <Collapse.Panel
                className="logger-collapse-panel"
                forceRender={false}
                key={0}
                header={t("CHANGES_LIST")}
            >
                <SmartLoggerTableWrapper<SmartLoggerData>
                    rowKey="id"
                    columns={columns}
                    // columnsConfigs={{columns: columnsConfig, pageKey: "order_data_logger"}}
                    loadDataCallback={(request) =>
                        loggerService.getSmartLoggerData(request, {
                            parent_entity_name: entityName,
                            parent_entity_id: entityId,
                        })
                    }
                    expandableConfig={{
                        rowExpandable: (record: SmartLoggerData) => record.changes.length > 0,
                        expandedRowRender: (record: SmartLoggerData) => (
                            <SmartLoggerChangesWrapper row={record} key={record.id} />
                        ),
                    }}
                    listenToUrl={false}
                />
            </Collapse.Panel>
        </Collapse>
    );
}