import React, {useMemo} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { useParams } from "react-router-dom";
import {columnsFactory} from "./branch.data.employees.columns";
import {SmartTableDataHandler} from "../../../common/smart-table/smart-table.data-handler";
import {SmartTable} from "../../../common/smart-table/smart-table";
import {branchesService} from "../../../../services/branches/branches.service";
export const BranchDataEmployees: React.FC = () => {
    const {branchId} = useParams<{ branchId: string }>();
    const t = useTranslate();

    const columns = useMemo(() => columnsFactory(t), [])
    const dataHandler = useMemo(() => new SmartTableDataHandler(r => branchesService.getEmployees(branchId!, r)), [])

    return (
        <SmartTable
            handler={dataHandler}
            columns={columns}
        />
    )
}