import { action, makeObservable, observable } from "mobx";
import { userService } from "../../../../services/users/user.service";
import { ExtendedUser, UserAccess, UserContacts } from "../../../../models/users/user/extended.user";
import { FoundUsers } from "../../../../models/users/user/found-user/found.user";
import { FMSAccountRequest } from "../../../../models/users/user/user-data-fms/requests/requests";
import { WSAccountRequest } from "../../../../models/users/user/user-data-ws/requests/requests";
import { RetailAccountRequest } from "../../../../models/users/user/user-data-retail/requests/requests";
import { agentService } from "../../../../services/agents/agent.service";
import React from "react";
import { documentTypesService } from "../../../../services/document-types/document.types.service";
import { SearchedUser } from "../../../../models/users/user/searched-user/searched.user";
import { PagesStore } from "../../../../stores/pages/pages.store";

export class UserDataStore {
    public types: ["employee"] = ["employee"];

    public foundUsers: FoundUsers = {};

    public documentTypes: string[] = [];

    @observable
    public userData: ExtendedUser | null = null;

    @observable
    public loading: boolean = false;

    constructor(private id: string, private pageStore: PagesStore, private url: string) {
        makeObservable(this);

        this.deleteAccess = this.deleteAccess.bind(this);
        this.searchUsers = this.searchUsers.bind(this);
        this.editUser = this.editUser.bind(this);

        this.createAccount = this.createAccount.bind(this);
        this.editAccount = this.editAccount.bind(this);
        this.connectAccounts = this.connectAccounts.bind(this);

        this.searchAgents = this.searchAgents.bind(this);

        this.loadData();
    }

    @action
    private removeItem(item: any, items: any): void {
        const index = items.indexOf(item);
        if (index !== -1) {
            items.splice(index, 1);
        }
    }

    @action
    private setLoading(loading: boolean): void {
        this.loading = loading;
    }

    @action
    private setUserData(userData: ExtendedUser): void {
        this.userData = userData;
    }

    @action
    public async createAccount(
        values: FMSAccountRequest | WSAccountRequest | RetailAccountRequest,
        type: "employee" | "retail" | "wholesale" | "customer"
    ) {
        const response = await userService.postAccount({ userId: this.userData!.id, values, type });
        this.userData?.accesses.push(response);
    }

    @action
    public async editAccount(
        values: FMSAccountRequest | WSAccountRequest | RetailAccountRequest,
        type: "employee" | "retail" | "wholesale" | "customer",
        accountId: string
    ) {
        const response = await userService.postAccount({ userId: this.userData!.id, type, values, accountId });

        const index = this.userData?.accesses.findIndex((item) => item.account.id === response.account.id);
        if (index) {
            this.userData!.accesses[index].account = response.account;
            this.userData!.accesses[index].systemAccesses = response.systemAccesses;
        }
    }

    @action
    private addAccesses(accesses: UserAccess[]): void {
        this.userData!.accesses = [...this.userData!.accesses, ...accesses];
    }

    private setDocumentTypes(types: string[]): void {
        this.documentTypes = types;
    }

    public async editUser(values: UserContacts) {
        const editedUser = await userService.editUser(this.userData!.id, values);
        this.setUserData(editedUser);
    }

    public async deleteAccess(access: UserAccess) {
        await userService.revokeAccess(this.userData!.id, "" + access.account.id!);
        this.removeItem(access, this.userData!.accesses);
    }

    public setFoundUsers(users: SearchedUser[]): void {
        users.forEach((user: SearchedUser) => {
            this.foundUsers[user.id] = user.accesses.map((access) => {
                return access.account;
            });
        });
    }

    public async searchUsers(value: string) {
        const response: SearchedUser[] = await userService.searchUser(value);
        this.setFoundUsers(response);
        return response.map((item: any) => ({ value: item.id, label: item.firstName + " " + item.lastName }));
    }

    public async searchAgents(value: string) {
        const accounts = await agentService.searchWholesaleAccount(value);
        return accounts.map(account => ({value: account.agent.id, label: account.agent.number + " - " + account.agent.name}));
    }

    public async connectAccounts(accounts: number[]) {
        const response = await userService.connectAccounts(accounts, this.userData!.id);
        this.addAccesses(response);
    }

    public async setPrimaryAccount(access: UserAccess) {
        await userService.setPrimaryAccount(this.userData!.id, access.account.id)
        await  this.reloadUser();
    }

    public async reloadUser() {
        this.setLoading(true);
        this.setUserData(await userService.getById(this.id));
        this.setLoading(false);
    }

    public async loadData() {
        this.setLoading(true);
        this.setUserData(await userService.getById(this.id));
        this.setDocumentTypes(await documentTypesService.getDocumentTypes());
        this.pageStore.updateRouteName(this.url, this.userData?.firstName + " " + this.userData?.lastName);
        this.setLoading(false);
    }
}

export const UserDataStoreContext = React.createContext<UserDataStore | null>(null);
