import React from "react";
import FilteredTable, { FilteredTableProps } from "../filtered-table/filtered.table";
import { FilterKey } from "../../../models/filtered-table/filtered.table.props";
import { ExpandableConfig } from "antd/es/table/interface";
import { observer } from "mobx-react";
import { FilterDataHandler } from "../../../models/filter/filter.data.handler";
import { PaginatedRequest } from "../../../models/core/paginated.request";
import { PaginatedResult } from "../../../models/core/paginated.result";

interface SmartLoggerTableWrapperProps<T> extends FilteredTableProps<T> {
    rowKey: string;
    filterKeys?: FilterKey[];
    columnsConfigs?: { columns: { title: string; key: string }[]; pageKey: string };
    expandableConfig: ExpandableConfig<T>;
    dataHandler?: FilterDataHandler<T>;
    loadDataCallback?: (request: PaginatedRequest) => Promise<PaginatedResult<T>>;
}
export const SmartLoggerTableWrapper = observer(
    <T extends object>({
        rowKey,
        columns,
        filterKeys,
        columnsConfigs,
        expandableConfig,
        dataHandler,
        loadDataCallback,
        ...props
    }: SmartLoggerTableWrapperProps<T>) => {
        return (
            <FilteredTable
                className="smart-logger-table"
                rowKey={rowKey}
                columns={columns}
                filterKeys={filterKeys}
                columnsConfigs={columnsConfigs}
                dataHandler={dataHandler}
                loadDataCallback={loadDataCallback}
                expandable={expandableConfig}
                {...props}
            />
        );
    }
);
