import {ComponentPropsFromRoute} from "../../../../routers/routers";
import React from "react";
import {Col, Row, Typography} from "antd";
import {SmartLogger} from "../../../logger/smart-logger/smart-logger";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {AgentDataStoreContext} from "../agent.data.store";
import {observer} from "mobx-react";

interface AgentDataLoggerProps extends ComponentPropsFromRoute {}
export const AgentDataLogger: React.FC<AgentDataLoggerProps> = observer(({ name }) => {

    const store = useRequiredStore(AgentDataStoreContext);
    const t = useTranslate();

    return (
        <Row gutter={[10, 30]}>
            {store.account ? (
                <Col span={24}>
                    <Typography.Title level={3}>{t("ACCOUNTS.ACCOUNT.TITLE")}</Typography.Title>
                    <SmartLogger
                        entityName={"Core\\Account\\WholesaleAccount"}
                        entityId={store.account.id.toString()}
                    />
                </Col>
            ) : null}
            {store.account?.agent ? (
                <Col span={24}>
                    <Typography.Title level={3}>{t("AGENT_TITLE")}</Typography.Title>
                    <SmartLogger
                        entityName={"Agent\\Agent"}
                        entityId={store.account.agent.id.toString()}
                    />
                </Col>
            ) : null}
        </Row>
    )
})