import React from "react";
import {Checkbox, Col, Row, Space, Typography} from "antd";
import {AccessMap} from "../../../../../../../models/users/access/access";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../../../../utils/store";
import {UserGroupsStoreContext} from "../../../user.groups.store";
import "./groups.data.accesses.item.scss";

interface GroupsDataAccessesItemProps {
    id: string,
    accessesMap: AccessMap
}

export const GroupsDataAccessesItem: React.FC<GroupsDataAccessesItemProps> = observer(({id, accessesMap}) => {
    const store = useRequiredStore(UserGroupsStoreContext);

    return (
        <Space direction="vertical" size={[10, 10]}>
            <Typography.Title style={{marginBottom: 0}} level={4}>{store.accessesData[id].title}</Typography.Title>
            <Row gutter={[10, 20]}>
                {Object.keys(accessesMap).map(key => (
                    <Col key={key} xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                        <Checkbox
                            className="group-access-checkbox"
                            checked={accessesMap[key].checked}
                            onChange={e => store.setAccessChecked(e.target.checked, id, key)}
                        >
                            {accessesMap[key].description}
                        </Checkbox>
                    </Col>
                ))}
            </Row>
        </Space>
    );
});
