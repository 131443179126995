import React from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../utils/store";
import {FilteredTableStoreContext} from "../filtered.table.store";
import {Checkbox, Col, Form, Modal, Row, Select} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import "./columns.modal.scss";

interface ColumnsModalProps {
    visible: boolean
    columns: {title: string, key: string}[],
    selectedColumns: string[],
    selectColumns: (values: {columns: string[]}) => void,
    onClose: () => void
    hiddenColumns?: string[]
}

export const ColumnsModal: React.FC<ColumnsModalProps> = ({columns, visible, selectedColumns, selectColumns, onClose, hiddenColumns = []}) => {
    const t = useTranslate();
    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            title={t("TABLE_SETTINGS")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"accounts_form"}
                initialValues={{columns: selectedColumns.filter(item => item !== "action" || !hiddenColumns?.includes(item))}}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        selectColumns(values)
                        onClose();
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="columns"
                            label={t("COLUMNS")}
                            rules={[{
                                required: true,
                                message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                validator: async (rule, columns) => {
                                    try {
                                        if (!columns || columns.length === 0) {
                                            return Promise.reject((t('FROM.ERROR.PLEASE_ADD_VALUE')));

                                        }
                                    } catch (error) {
                                        throw new Error(error as string);
                                    }
                                }
                            }]}
                        >
                            <Checkbox.Group className={"columns-modal-group"}>
                                {columns.map(item => (
                                    <Checkbox className={"columns-modal-checkbox"} key={item.key} value={item.key}>{t(item.title)}</Checkbox>
                                ))}
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}