import { Alert, Space, Tooltip, Typography } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { TableShipment } from "../../../../models/parcels/shipment";
import {columnsFactory} from "./document.shipments.columns";
import {shipmentsService} from "../../../../services/documents/common/shipments.service";
import {convertDataForTable} from "./document.shipments.helper";

interface DocumentShipmentsProps {
    id: number;
}

export const DocumentShipments: React.FC<DocumentShipmentsProps> = ({id}) => {
    const t = useTranslate();

    const [shipments, setShipments] = useState<TableShipment[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const columns: ColumnsType<TableShipment> = useMemo(() => columnsFactory(t), []);

    useEffect(() => {
        setLoading(true);
        shipmentsService.getDocumentShipments(id).then(v => {
            setShipments(convertDataForTable(v))
            setLoading(false)
        })
    } , [id])

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <Space direction="vertical" size={[10, 10]}>
            <Typography.Title level={3}>{t("SHIPMENTS.TITLE")}</Typography.Title>
            {shipments.length === 0 ? (
                <Alert type="info" message={t("SHIPMENTS.NO_SHIPMENTS")} />
            ) : (
                <Table columns={columns} dataSource={shipments} pagination={false} />
            )}
        </Space>
    );
};
