import React, {useState} from "react";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../models/core/response";
import {Col, Form, Modal, Row, Select} from "antd";
import {useTranslate} from "../../../hooks/translate.hook";
import {useRequiredStore} from "../../../utils/store";
import {MissingItemsStoreContext} from "./missing.items.part.store";
import {WarehouseWithSort} from "../../../models/warehouses/warehouse";

interface MissingItemsAddAllModalProps {
    visible: boolean;
    warehouses: WarehouseWithSort[],
    onClose: () => void;
}

export const MissingItemsAddAllAvailableModal: React.FC<MissingItemsAddAllModalProps> = ({visible, warehouses, onClose}) => {
    const store = useRequiredStore(MissingItemsStoreContext);
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    return (
        <Modal
            destroyOnClose
            visible={visible}
            confirmLoading={loading}
            title={t("WISH_LIST.ADD_ALL_AVAILABLE_TO_BASKET")}
            okText={t("WISH_LIST.ADD_TO_ORDER")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"wish_list_form"}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        store.addAllToCart(values)
                            .then(
                                () => {
                                    form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                            .then(() => {
                            }, (e) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="warehouses"
                            label={t("WAREHOUSES.TITLE")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}

                        >
                            <Select
                                allowClear
                                defaultOpen
                                autoFocus
                                mode="multiple"
                                disabled={loading}
                                placeholder={t("WAREHOUSES.TITLE")}
                                getPopupContainer={trigger => trigger.parentElement}
                            >
                                {warehouses.map(({warehouse}) => (
                                    <Select.Option
                                        key={warehouse.id}
                                        value={warehouse.id}
                                    >
                                        {warehouse.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}