import React from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Parcel} from "../../../../models/parcels/shipment";
import {Card, Col, Row, Space, Statistic} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ShipmentBox} from "./shipment-box";
import {Value} from "../../../common/value/value";

interface InvoiceDataHeaderProps {
    parcel: Parcel;
}

export const ShipmentBoxCard: React.FC<InvoiceDataHeaderProps> = ({parcel}) => {
    const t = useTranslate();

    return (
        <Card
            title={<><FontAwesomeIcon icon={"box"} color={"#676767"} /><span style={{marginLeft: 10}}>Box # {parcel.number}</span></>}
            bordered={false}
            headStyle={{color: '#fff', backgroundColor: '#4a4a4a'}}
            bodyStyle={{padding: "10px 10px", backgroundColor: '#f7f7f7'}}
        >
            <Row gutter={[40, 20]}>
                <Col span={18}>
                    <ShipmentBox parcel={parcel} showInfo={false} />
                </Col>
                <Col span={6}>
                    <Space direction={"vertical"} size={[10, 10]} style={{margin: 10}}>
                        <Statistic
                            title="Track Number"
                            valueRender={() => <Value value={parcel.trackCode} />}
                        />
                        <Statistic
                            title="Box Number"
                            valueRender={() => <Value value={parcel.boxCode} />}
                        />
                        <Statistic
                            title="Net weight"
                            value={parcel.netWeight}
                            precision={3} suffix={"kg."}
                        />
                        <Statistic
                            title="Gross weight"
                            value={parcel.grossWeight}
                            precision={3}
                            suffix={"kg."}
                        />
                    </Space>
                </Col>
            </Row>
        </Card>
    );
}