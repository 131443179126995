import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { OrderDataStore, OrderDataStoreContext } from "./order.data.store";
import { useParams, useLocation } from "react-router-dom";
import { Space, Spin } from "antd";
import { useRequiredStore } from "../../../utils/store";
import { RootStoreContext } from "../../../stores/root/root.store";
import {OrderDataWholesale} from "./order-data-wholesale/order.data.wholesale";
import {OrderDataRetail} from "./order-data-retail/order.data.retail";

export const OrderData: React.FC = observer(() => {
    const { orderId } = useParams<{ orderId: string }>();
    const {
        authStore: { attributes },
        pageStore,
    } = useRequiredStore(RootStoreContext);
    const match = useLocation();
    const [store] = useState(() => new OrderDataStore(pageStore, match.pathname, attributes));

    useEffect(() => {
        store.setOrderId(orderId!);
    }, [orderId]);

    if (store.loading || !store.order) {
        return (
            <Space
                direction={"horizontal"}
                align={"center"}
                style={{
                    height: "calc(100vh - 90px)",
                    justifyContent: "center",
                }}
            >
                <Spin />
            </Space>
        );
    }

    return (
        <OrderDataStoreContext.Provider value={store}>
            {(store.order.orderType === "wholesale" || store.order.orderType === "wholesale_dropship") ?
                <OrderDataWholesale /> : <OrderDataRetail />
            }
        </OrderDataStoreContext.Provider>
    );
});
