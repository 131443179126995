import React from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../utils/store";
import {ArticleDataStoreContext} from "../article.data.store";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Space, Table, Typography} from "antd";

export const ArticleDataAnalogues: React.FC = observer(() => {
    const store = useRequiredStore(ArticleDataStoreContext);
    const t = useTranslate();

    const columns: any[] = [
        {
            title: t("ARTICLES.MANUFACTURER"),
            dataIndex: "brandName",
            key: "brandName"
        },
        {
            title: t("NAME"),
            dataIndex: "name",
            key: "name"
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "description"
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status"
        },
    ]

    return (
        <Space direction={"vertical"} size={0}>
            <Typography.Title level={3}>{t("ARTICLES.ANALOGUES")}</Typography.Title>
            <Table
                columns={columns}
                rowKey={"id"}
                dataSource={[...store.article!.analogues]}
                loading={store.loading}
                scroll={{x: true}}
                pagination={false}
            />
        </Space>
    );

})
