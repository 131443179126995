import React, {useMemo} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Table, Typography} from "antd";
import {OrderData, OrderDocumentWithType} from "../../../../models/orders/order-data/order.data";
import {Document} from "../../../../models/orders/order-data/order.data";
import {ColumnsType} from "antd/es/table";
import Date from "../../../date/date";
import {DocumentsStatus} from "../../../common/documents/status/documents.status";
import {generateDocumentUrl} from "./order-data-documents.helper";
import {Link} from "react-router-dom";

interface OrderDataDocumentsProps {
    order: OrderData;
}

export const OrderDataDocuments: React.FC<OrderDataDocumentsProps> = ({order}) => {
    const t = useTranslate();

    const documents = useMemo(() => {
        const result: OrderDocumentWithType[] = [];
        Object.keys(order.documents).forEach((documentType) => {
            order.documents[documentType as Document]!.forEach((document) => {
                result.push({...document, type: documentType as Document})
            })
        })

        return result;
    }, [order]);

    const columns: ColumnsType<OrderDocumentWithType> = [
        {
            title: 'Number',
            dataIndex: 'docNumber',
            key: 'docNumber',
            render: (number, document) => <Link to={generateDocumentUrl(document)}>{number}</Link>
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type) => t('DOCUMENT.TYPE.' + type.toUpperCase() + '.TITLE')
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => <DocumentsStatus status={status} />
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: date => <Date dateString={date} />
        }
    ]

    if (documents.length === 0) {
        return null;
    }

    return (
        <>
            <Typography.Title level={3}>{t("ORDERS.ORDER.DOCUMENTS.TITLE")}</Typography.Title>
            <Table
                style={{width: '50%'}}
                dataSource={documents}
                columns={columns}
                pagination={false}
            >
            </Table>
        </>
    );
}