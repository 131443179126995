import React, { RefObject, useEffect, useRef } from "react";
import { FieldsProps } from "../filter.popup";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import { Input, Form } from "antd";

export const StringFields: React.FC<FieldsProps> = ({ popupRef, config }) => {
    const t = useTranslate();
    const inputRef = useRef() as RefObject<any>;

    useEffect(() => {
        inputRef.current.focus();
    }, [inputRef.current]);

    return (
        <Form.Item
            name={["fields", config.name, "values"]}
            noStyle
            rules={[
                {
                    required: true,
                    message: "Enter value",
                },
            ]}
        >
            <Input ref={inputRef} style={{ width: "67%" }} placeholder={t("FILTER.VALUE")} />
        </Form.Item>
    );
};
