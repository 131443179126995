import {Checkbox, message, Modal, Upload} from "antd";
import React, {useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useRequiredStore} from "../../../../utils/store";
import {LanguagesStoreContext} from "../list/languages.list.store";

interface LanguagesImportModuleProps {
    visible: boolean;
    onClose: () => void;
}
export const LanguagesImportModal: React.FC<LanguagesImportModuleProps> = ({visible, onClose}) => {
    const t = useTranslate();
    const store = useRequiredStore(LanguagesStoreContext);
    const [withUpdateValue, setWithUpdateValue] = useState<boolean>(false);

    const key = "upload";
    const props = {
        name: "file",
        multiple: false,
        showUploadList: false,
        action: "i18n/key/import",
        customRequest: async (x: any) => {
            try {
                message.loading({content: t("LOADING") + "...", key});
                const res = await store.importLocales({file: x.file, withUpdate: withUpdateValue}, percent => x.onProgress({percent}));
                x.onSuccess(res);
            } catch (e: any) {
                x.onError(e);
            }
        },
        async onChange(info: any) {
            const {status} = info.file;
            if (status === "done") {
                await store.updateItems();
                message.success({content: t("UPLOAD.SUCCESS"), key, duration: 2});
            } else if (status === "error") {
                message.error({content: t("UPLOAD.FAIL"), key, duration: 5});
            }
        },
        onSuccess: async () => {
            onClose();
            await store.updateItems();
            message.success({content: t("UPLOAD.SUCCESS"), key, duration: 2});
        },
        onError: (err: any, body: any) => {
            message.error({content: t("UPLOAD.FAIL"), key, duration: 5});
        }
    };


    return (
        <Modal
            visible={visible}
            title={t("LANGUAGES.IMPORT")}
            onCancel={() => {
                onClose();
            }}
            footer={false}
            centered
        >
            <div className="upload-wrapper">
                <Upload.Dragger style={{width: "100%"}} {...props}>
                    <p className="ant-upload-drag-icon">
                        <FontAwesomeIcon icon={["fas", "file-upload"]} size={"4x"}/>
                    </p>
                    <p className="ant-upload-text">{t("UPLOAD.TEXT")}</p>
                </Upload.Dragger>
            </div>
            <Checkbox
                style={{marginTop: 10}}
                onChange={e => setWithUpdateValue(e.target.checked)}
                value={withUpdateValue}
            >
                {t("LANGUAGES.WITH_UPDATE")}
            </Checkbox>
        </Modal>
    )
}