import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {Carier} from "../../models/cariers/carier";

export class CariersService {
    private api: AxiosInstance = API;

    public getCariers(): Carier[] {
        return [{name: "Omniva", id: "omniva"}, {name: "DHL", id: "dhl"}, {name: "GLS", id: "gls"}];
        // return this.api.get<Carier[]>()
    }
}

export const cariersService = new CariersService();