import React, {useMemo} from "react";
import {observer} from "mobx-react";
import {ExportBlob} from "../../../common/export-blob/export.blob";
import {Button, Checkbox, Col, Form, Input, Row, Select, Tooltip} from "antd";
import {renderSortButtons} from "../../../../models/users/user/user.data.helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {ArticlesExportModalStore} from "./articles.export.modal.store";
import {toJS} from "mobx";

interface ArticlesExportModalProps {
    onClose: () => void;
}

export const ArticlesExportModal: React.FC<ArticlesExportModalProps> = observer(({onClose}) => {

    const t = useTranslate();

    const [form] = Form.useForm();

    const store = useMemo(() => new ArticlesExportModalStore(), []);

    const {
        commonStore: { exportFormats: formats },
    } = useRequiredStore(RootStoreContext);

    const getWarehouseName = (stockFieldName: string): string => {
        const id = stockFieldName.replace("stock_", "");
        const warehouse = store.warehouses.find((warehouse) => warehouse.id === id);
        if (warehouse) {
            return warehouse.name;
        }
        return "";
    };

    const getPriceName = (priceFieldName: string): string => {
        const id = priceFieldName.replace("price_", "");
        const price = !!id ? store.prices.find((item) => item.id === parseInt(id, 10)) : undefined;
        if (price) {
            return price.name;
        }
        return "";
    };

    const getOptionLabel = (name: string): string => {
        const option = store.options.find((item) => item.value === name);
        if (option) {
            return option.label;
        }
        return name;
    };

    const getColLen = (rowName?: string): number => {
        if (rowName && (rowName.includes("price_") || rowName.includes("stock_") || rowName === "cars")) {
            return 7;
        }
        return 11;
    };

    if (!store.ready) {
        return <></>;
    }

    return (
        <ExportBlob<{ values: any }>
            title="EXPORT.TITLE"
            okText="DOWNLOAD.DO_DOWNLOAD"
            submitting={store.submitting}
            form={form}
            setSubmitting={store.setSubmitting}
            onInit={store.getExportDefaultValues}
            onSubmit={store.export}
            onClose={onClose}
        >
            <Row gutter={[5, 5]}>
                <Col span={24}>
                    <Form.Item
                        name="language"
                        label={t("LANGUAGES.SINGLE_TITLE")}
                        rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                    >
                        <Select disabled={store.submitting} placeholder={t("LANGUAGES.SINGLE_TITLE")}>
                            {store.locales.map((locale) => (
                                <Select.Option value={locale} key={locale}>
                                    {locale.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.List
                        name={"fields"}
                        rules={[
                            {
                                validator: async (rule, names) => {
                                    try {
                                        if (!names || names.length === 0) {
                                            return Promise.reject(t("FROM.ERROR.PLEASE_ADD_VALUE"));
                                        }
                                    } catch (error) {
                                        throw new Error(error as string);
                                    }
                                },
                            },
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                <Row>
                                    <Col span={4} />

                                    <Col span={7}>
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {t("COLUMN.NAME")}
                                                </span>
                                    </Col>
                                    <Col span={11}>
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {t("COLUMN.DATA_SOURCE")}
                                                </span>
                                    </Col>
                                </Row>
                                {fields.map((field, index) => (
                                    <Row justify="space-between" gutter={[5, 0]} key={field.key} align="top">
                                        <Form.Item noStyle shouldUpdate={true}>
                                            {({ getFieldValue, setFieldsValue, getFieldsValue }) => (
                                                <>
                                                    {renderSortButtons(
                                                        form,
                                                        index,
                                                        fields.length,
                                                        store.submitting,
                                                        "fields",
                                                        "fields"
                                                    )}
                                                    <Col span={7}>
                                                        <Form.Item
                                                            {...field}
                                                            
                                                            name={[field.name, "visibleName"]}
                                                        >
                                                            <Input
                                                                placeholder={t("COLUMN.NAME")}
                                                                disabled={store.submitting}
                                                                type="text"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        span={getColLen(
                                                            getFieldValue(["fields", index, "name"])
                                                        )}
                                                    >
                                                        <Form.Item noStyle shouldUpdate>
                                                            {() => {
                                                                return (  <Form.Item
                                                                    {...field}
                                                                    
                                                                    name={[field.name, "name"]}
                                                                >
                                                                    {getFieldValue(["fields", index, "name"]) !==
                                                                    undefined &&
                                                                    (getFieldValue(["fields", index, "name"]) === "" ||
                                                                        ![...store.options]
                                                                            .map((item) => item.value)
                                                                            .includes(
                                                                                getFieldValue(["fields", index, "name"])
                                                                            )) ? (
                                                                        <Input
                                                                            disabled={store.submitting}
                                                                            type="text"
                                                                            placeholder="Custom"
                                                                            autoFocus={true}
                                                                        />
                                                                    ) : (
                                                                        <Select
                                                                            placeholder={t("COLUMN.DATA_SOURCE")}
                                                                            disabled={store.submitting}
                                                                            options={store.options}
                                                                            onSelect={(value: string) => {
                                                                                const fields: {
                                                                                    name: string;
                                                                                    visibleName: string;
                                                                                }[] = form.getFieldsValue().fields;
                                                                                const fieldItem = fields[index];
                                                                                fields[index] = {
                                                                                    ...fieldItem,
                                                                                    visibleName:
                                                                                        fieldItem?.name?.includes(
                                                                                            "stock_"
                                                                                        )
                                                                                            ? getWarehouseName(
                                                                                                fieldItem?.name || ""
                                                                                            )
                                                                                            : fieldItem?.name?.includes(
                                                                                                "price_"
                                                                                            )
                                                                                                ? getPriceName(
                                                                                                    fieldItem?.name
                                                                                                )
                                                                                                : getOptionLabel(
                                                                                                    fieldItem?.name
                                                                                                ),
                                                                                };
                                                                                setFieldsValue({
                                                                                    ...form.getFieldsValue(),
                                                                                    fields,
                                                                                });
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Form.Item>)
                                                            }}
                                                        </Form.Item>
                                                    </Col>
                                                    {getFieldValue(["fields", index, "name"])?.includes(
                                                        "stock_"
                                                    ) ||
                                                    getFieldValue(["fields", index, "name"])?.includes(
                                                        "price_"
                                                    )  ||
                                                    getFieldValue(["fields", index, "name"]) === "cars" ? (
                                                        <Col span={4}>
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) => {
                                                                    const fieldRowName = getFieldValue([
                                                                        "fields",
                                                                        index,
                                                                        "name",
                                                                    ]);
                                                                    const isStockOrPrice =
                                                                        fieldRowName?.includes("stock_") ||
                                                                        fieldRowName?.includes("price_") ||
                                                                        fieldRowName === 'cars';

                                                                    return isStockOrPrice ? (
                                                                        <Tooltip
                                                                            placement="top"
                                                                            title={
                                                                                fieldRowName?.includes("stock_") || fieldRowName === "cars"
                                                                                    ? t("MAX_AMOUNT") +
                                                                                    " (" +
                                                                                    t("PCS") +
                                                                                    ")"
                                                                                    : fieldRowName?.includes(
                                                                                        "price_"
                                                                                    )
                                                                                        ? t("DISCOUNT") + " (%)"
                                                                                        : ""
                                                                            }
                                                                        >
                                                                            <Form.Item
                                                                                {...field}
                                                                                
                                                                                name={[field.name, "extra"]}
                                                                            >
                                                                                <Input
                                                                                    disabled={
                                                                                        !fieldRowName ||
                                                                                        !isStockOrPrice ||
                                                                                        store.submitting
                                                                                    }
                                                                                    placeholder={
                                                                                        fieldRowName?.includes(
                                                                                            "stock_"
                                                                                        )
                                                                                            ? t("MAX_AMOUNT") +
                                                                                            " (" +
                                                                                            t("PCS") +
                                                                                            ")"
                                                                                            : fieldRowName?.includes(
                                                                                                "price_"
                                                                                            )
                                                                                                ? t("DISCOUNT") +
                                                                                                " (%)"
                                                                                                : ""
                                                                                    }
                                                                                    type="number"
                                                                                />
                                                                            </Form.Item>
                                                                        </Tooltip>
                                                                    ) : null;
                                                                }}
                                                            </Form.Item>
                                                        </Col>
                                                    ) : null}

                                                    <Col span={2}>
                                                        <Form.Item {...field}>
                                                            <Tooltip placement="topRight" title={t("DELETE")}>
                                                                <Button
                                                                    disabled={store.submitting}
                                                                    type="default"
                                                                    onClick={() => remove(field.name)}
                                                                    icon={
                                                                        <FontAwesomeIcon
                                                                            icon={["fas", "times"]}
                                                                        />
                                                                    }
                                                                    danger
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                    className="btn-flex-center"
                                                                />
                                                            </Tooltip>
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            )}
                                        </Form.Item>
                                    </Row>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        disabled={store.submitting}
                                        onClick={() => {
                                            add({});
                                        }}
                                        block
                                        icon={<FontAwesomeIcon icon={["fas", "plus-circle"]} />}
                                    >
                                        {t("ADD_FIELD")}
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Col>
                <Col span={24}>
                    <Form.Item name={["config", "withHeaders"]} valuePropName={"checked"}>
                        <Checkbox disabled={store.submitting}>{t("DOWNLOAD.WITH_HEADERS")}</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={["excludeCatalogHidden"]} valuePropName={"checked"}>
                        <Checkbox disabled={store.submitting}>{t("DOWNLOAD.EXCLUDE_CATALOG_HIDDEN")}</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={["excludeStatisticHidden"]} valuePropName={"checked"}>
                        <Checkbox disabled={store.submitting}>{t("DOWNLOAD.EXCLUDE_STATISTIC_HIDDEN")}</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={["config", "format"]}
                        label={t("DOWNLOAD.FORMAT")}
                        rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                    >
                        <Select disabled={store.submitting} placeholder={t("DOWNLOAD.FORMAT")}>
                            {formats.map((item) => (
                                <Select.Option key={item} value={item}>
                                    {item.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </ExportBlob>
    );
})