import React, {useEffect, useMemo, useState} from "react";
import { observer } from "mobx-react";
import { Space, Typography } from "antd";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Invoice} from "../../../../models/documents/invoices/invoice";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
import { ColumnsType } from "antd/es/table";
import {DocumentsMenu} from "../../../common/documents/menu/documents.menu";
import {convertTableColumnsToColumnConfigs} from "../../../../utils/table";
import {columnsFactory} from "./credit-invoices.list.columns";
import {FilterDataHandler} from "../../../../models/filter/filter.data.handler";
import {creditInvoicesService} from "../../../../services/documents/invoices/credit.invoices.service";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {filterGroupsFactory} from "./credit-invoices.list.filter-groups";

interface InvoicesListProps extends ComponentPropsFromRoute {
}

export const CreditInvoicesList: React.FC<InvoicesListProps> = observer(({ name }) => {
    const t = useTranslate();

    const {
        commonStore: { warehouses },
    } = useRequiredStore(RootStoreContext);

    const columns: ColumnsType<Invoice> = useMemo(() => columnsFactory(t), []);

    const dataHandler = useMemo(() => new FilterDataHandler(request => creditInvoicesService.getAll(request)), [])

    const filterGroups = useMemo(() => filterGroupsFactory(t, warehouses), [])

    return (
        <Space direction="vertical" size={[50, 50]}>
            <DocumentsMenu/>
            <Space direction="vertical" size={[0, 0]}>
                <Typography.Title level={3}>{t("INVOICES.LIST")}</Typography.Title>
                <Space direction={"vertical"} size={[15, 15]}>
                    <FilteredTable
                        columns={columns}
                        columnsConfigs={{columns: convertTableColumnsToColumnConfigs(columns), pageKey: "id"}}
                        dataHandler={dataHandler}
                        filterGroups={filterGroups}
                    />
                </Space>
            </Space>
        </Space>
    );
});
