import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {PaginatedResult} from "../../models/core/paginated.result";
import {DeclarationsDeclarant} from "../../models/declarations/declarations.declarants";
import {PaginatedRequest} from "../../models/core/paginated.request";

export class DeclarationsDeclarantsService {
    api: AxiosInstance = API;

    getList(): Promise<DeclarationsDeclarant[]> {
        return this.api.get<DeclarationsDeclarant[]>('declarations/declarants/all').then(x => x.data);
    }

    getAll(request: PaginatedRequest): Promise<PaginatedResult<DeclarationsDeclarant>> {
        return this.api.get<PaginatedResult<DeclarationsDeclarant>>(
            'declarations/declarants',
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }

    create(data: any): Promise<DeclarationsDeclarant> {
        return this.api.post<DeclarationsDeclarant>('declarations/declarants', data).then(x => x.data);
    }

    update(id: number, data: any): Promise<DeclarationsDeclarant> {
        return this.api.post<DeclarationsDeclarant>('declarations/declarants/' + id, data).then(x => x.data);
    }

    remove(id: number): Promise<any> {
        return this.api.delete<any>('declarations/declarants/' + id).then(x => x.data);
    }
}

export const declarationsDeclarantsService = new DeclarationsDeclarantsService();
