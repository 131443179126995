import React, {useMemo} from "react";
import {WikiArticleAccess, WikiArticleAccessLevel} from "../../../../models/wiki/wiki";
import {Button, Col, Divider, Row, Space} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./permissions.scss"
import {System} from "../../../../models/systems/system/system";

interface WikiPagePermissionsProps {
    accesses: WikiArticleAccess[]
    onEdit: (access: WikiArticleAccess) => void;
    onRemove: (access: WikiArticleAccess) => void;
}

interface PermissionGroup {
    system: System;
    permissions: WikiArticleAccess[];
}

const createPermissionGroups = (accesses: WikiArticleAccess[]) : PermissionGroup[] => {

    const groups: PermissionGroup[] = [];

    accesses.forEach(access => {
        let group = groups.find(item => access.system.id === item.system.id);
        if (!group) {
            group = {system: access.system, permissions: []};
            groups.push(group);
        }

        group.permissions.push(access);
    })

    groups.forEach(group => {
        group.permissions.sort((a, b) => {
            return (a.branch?.id || 0) > (b.branch?.id || 0) ? 1 : -1;
        })
    })

    return groups;
}

export const WikiPagePermissions: React.FC<WikiPagePermissionsProps> = ({accesses, onEdit, onRemove}) => {

    const groups = useMemo<PermissionGroup[]>(() => createPermissionGroups(accesses), [accesses]);

    return <Space direction={"vertical"} className={"wiki-permissions"}>
        {groups.length > 0 ?
            groups.map(group => (
                <React.Fragment key={group.system.id}>
                    <div className={"wiki-permission-system"}>{group.system.name}</div>
                    {group.permissions.map((permission, key) => (
                        <Row gutter={[10, 10]} wrap={false} key={key} className={"wiki-permission"}>
                            <Col flex={"50px"}>
                                <div className={"wiki-permission-branch"}>
                                    {permission.branch?.id.toUpperCase() || "ALL"}
                                </div>
                            </Col>
                            <Col flex={"auto"}>
                                {permission.department?.name || "All departments"}<br />
                                <span style={{color: "#999"}}>{permission.departmentRole?.name || "All roles"}</span>
                            </Col>
                            <Col flex={"80px"}>
                                <Space direction={"horizontal"} wrap={false}>
                                    <div className={"wiki-permission-icon allow"}>
                                        <FontAwesomeIcon icon={["fas", "eye"]} />
                                    </div>
                                    <div className={"wiki-permission-icon " + (permission.level === WikiArticleAccessLevel.EDITABLE ? "allow": "disallow")}>
                                        <FontAwesomeIcon icon={["fas", "pencil-alt"]} />
                                    </div>
                                </Space>
                            </Col>
                            <Space
                                className={"wiki-permission-actions"}
                                direction={"horizontal"}
                                style={{justifyContent: "center"}}
                                split={<Divider />}
                            >
                                <Button
                                    type={"primary"}
                                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                                    onClick={() => onEdit(permission)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    type={"primary"}
                                    icon={<FontAwesomeIcon icon={["fas", "ban"]} />}
                                    danger={true}
                                    onClick={() => onRemove(permission)}
                                >
                                    Remove
                                </Button>
                            </Space>
                        </Row>
                    ))}
                </React.Fragment>
            )) : (
                <>Access denied to everyone (except you)</>
            )}
    </Space>
}