import React, { useState } from "react";
import { observer } from "mobx-react";
import { ProformaDataStore, ProformaDataStoreContext } from "./proforma.data.store";
import { useParams, useLocation } from "react-router-dom";
import { LoadingSpin } from "../../../common/loading-spin/loading.spin";
import { Space } from "antd";
import { PayerSellerReceiverData } from "../../../common/payer-seller-receiver-data/payer.seller.receiver.data";
import { ProformaDataHeader } from "./header/proforma.data.header";
import { ProformaDataDetails } from "./details/proforma.data.details";
import { useRequiredStore } from "../../../../utils/store";
import { RootStoreContext } from "../../../../stores/root/root.store";
import { isAllowed } from "../../../../utils/helpers";
import { ProformaDataIncludedOders } from "./included-orders/proforma.data.included-orders";
import {DocumentShipments} from "../../common/shipments/document.shipments";

export const ProformaData: React.FC = observer(() => {
    const { proformaId } = useParams<{ proformaId: string }>();
    const {
        pageStore,
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);
    const match = useLocation();
    const [store] = useState(() => new ProformaDataStore(proformaId!, pageStore, match.pathname, attributes));

    if (store.loading) {
        return <LoadingSpin />;
    }

    return (
        <ProformaDataStoreContext.Provider value={store}>
            <Space direction="vertical" size={[50, 50]}>
                <ProformaDataHeader />
                <PayerSellerReceiverData
                    payer={store.proformaData!.billingAddress}
                    receiver={store.proformaData!.shippingAddress}
                    seller={store.proformaData!.sellerAddress}
                    editPayerReceiver={store.editPayerReceiverData}
                    editSeller={store.editSellerData}
                />
                <DocumentShipments id={store.proformaData!.id} />
                {isAllowed(attributes, "document_proforma_create") ? <ProformaDataIncludedOders /> : null}
                <ProformaDataDetails />
            </Space>
        </ProformaDataStoreContext.Provider>
    );
});
