import React, {useState} from "react";
import {Col, Form, Modal, Row} from "antd";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../../models/core/response";
import {DebounceSelect} from "../../../../common/debounce-select/debounce.select";
import {NumberInput} from "../../../../common/number-input/number.input";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../utils/store";
import {AgentDataStoreContext} from "../../agent.data.store";
import {displayCurrencyCode} from "../../../../../models/prices/price.helper";

interface AgentDataCustomPriceModalProps {
    visible: boolean,
    onClose: () => void
}

export const AgentDataCustomPriceModal: React.FC<AgentDataCustomPriceModalProps> = ({visible, onClose}) => {
    const t = useTranslate();
    const store = useRequiredStore(AgentDataStoreContext);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);


    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title="Add item"
            okText={t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                size={"middle"}
                form={form}
                layout="vertical"
                name={"add_to_cart_form"}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                            store.createCustomPrice({article: values.article.value, amount: values.amount})
                            .then(
                                () => {
                                    form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    setLoading(false);
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                            .then(() =>
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={[10, 0]} align={"middle"}>
                    <Col span={24}>
                        <Form.Item
                            name={"article"}
                            label={t("ARTICLES.TITLE")}
                            rules={[{
                                required: true,
                                message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                            }]}
                        >
                            <DebounceSelect
                                placeholder={t("ARTICLES.SEARCH")}
                                style={{width: "100%"}}
                                disabled={loading}
                                fetchOptions={store.searchArticle}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"amount"}
                            label={t("PRICE")}
                            rules={[{
                                required: true,
                                message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                            }]}
                            initialValue={1}
                        >
                            <NumberInput addonBefore={displayCurrencyCode(store.account?.currencyCode!)}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
