import {AgentDataAccountRequest} from "./agent-data-accounts/agent.data.account.request";
import {AgentDataAccountFormValues} from "./agent-data-accounts/agent.data.account.form.values";

export const createAccessRequest = (
    {
        firstName,
        lastName,
        email,
        password,
        systemAccesses,
        sendPassword
    }: AgentDataAccountFormValues): AgentDataAccountRequest => {
    return {
        user: {
            firstName,
            lastName,
            email,
            password,
            sendPassword
        },
        systemAccesses
    }
};
