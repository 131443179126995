import React from "react";
import {observer} from "mobx-react";
import {Collapse, Row, Space, Table, Typography} from "antd";
import {useRequiredStore} from "../../../../utils/store";
import {ArticleDataStore, ArticleDataStoreContext} from "../article.data.store";
import {useTranslate} from "../../../../hooks/translate.hook";
import "./article.vehicles.scss";


export const ArticleVehicles: React.FC = observer(() => {
    const store = useRequiredStore(ArticleDataStoreContext);
    const t = useTranslate();

    const columns = [
        {
            title: t("NAME"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("YEARS"),
            dataIndex: "years",
            key: "years",
            width: "130px",
            render: (item: string) => <span style={{whiteSpace: "nowrap"}}>{item}</span>
        },
        {
            title: t("ARTICLES.SUBMODEL"),
            dataIndex: "engine",
            key: "engine",
        },
        {
            title: t("ARTICLES.FUEL_TYPE"),
            dataIndex: "fuelType",
            key: "fuelType"
        }
    ];

    if (!store.vehicles || !store.vehicles.length) {
        return null;
    }

    return (
        <Space size={[0, 0]} direction={"vertical"} className={"article"}>
            <Typography.Title level={3}>{t("ARTICLES.USED_IN")}</Typography.Title>
            <div>
                {store.vehicles.map((vehicle, index) => (
                    <Collapse className="article_collapse-inner" key={vehicle.name} expandIconPosition="right" bordered={false}>
                        <Collapse.Panel key={index} header={<span style={{fontWeight: "bold"}}>{vehicle.name}</span>}>
                            {vehicle.models.map((model, i) => (
                                <Collapse className="article_collapse-inner article_collapse-model" key={model.name} expandIconPosition="right" bordered={false}>
                                    <Collapse.Panel key={i} header={model.name}>
                                        <Table
                                            rowKey={"number"}
                                            columns={columns}
                                            dataSource={model.variants}
                                            scroll={{x: 700}}
                                            pagination={false}
                                        />
                                    </Collapse.Panel>
                                </Collapse>
                            ))}
                        </Collapse.Panel>
                    </Collapse>
                ))}
            </div>

            {/*<Collapse className="article_vehicle-collapse article_collapse" style={{width: "100%"}} expandIconPosition="right" bordered={false}>*/}
            {/*    <Collapse.Panel key={0} header={t("ARTICLES.USED_IN")}>*/}
            {/*        */}
            {/*    </Collapse.Panel>*/}
            {/*</Collapse>*/}
        </Space>
    );
})
