import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {Space} from "antd";
import {PayerSellerReceiverData} from "../../../common/payer-seller-receiver-data/payer.seller.receiver.data";
import {PayerReceiverFormValues, SellerDetails} from "../../../../models/payer-receiver-seller/payer.reveiver.seller";
import {payerReceiverSellerService} from "../../../../services/payer-receiver-seller/payer.receiver.seller.service";
import {ReturnDataHeader} from "./header/header";
import {Details} from "./details/details";
import {returnsService} from "../../../../services/documents/returns/returns.service";
import {Return} from "../../../../models/documents/returns/returns";
import {DocumentFiles} from "../../common/files/document.files";

export const ReturnData: React.FC = () => {

    const {id} = useParams<{id: string}>();

    const [returnDocument, setReturnDocument] = useState<Return|null>(null);

    const reload = useCallback(() => {
        returnsService.getInvoice(id!).then(creditInvoice => setReturnDocument(creditInvoice));
    }, []);

    useEffect(() => {
        reload();
    }, [id]);


    if (!returnDocument) {
        return <LoadingSpin />
    }

    const editSellerData = async (values: SellerDetails) => {
        returnDocument.masterOrder.seller = await payerReceiverSellerService.updateSellerData(returnDocument.masterOrder.id, values);
        setReturnDocument({...returnDocument});
    };

    const editPayerReceiverData = async (type: "payer" | "receiver", values: PayerReceiverFormValues) => {
        returnDocument.masterOrder.receiver = await payerReceiverSellerService.updatePayerReceiveData(returnDocument.masterOrder.id, type, values);
        setReturnDocument({...returnDocument});
    };

    return <Space direction="vertical" size={[50, 50]}>
        <ReturnDataHeader document={returnDocument} reload={reload} />
        <DocumentFiles id={returnDocument.id} />
        <PayerSellerReceiverData
            payer={returnDocument.masterOrder.payer}
            receiver={returnDocument.masterOrder!.receiver}
            seller={returnDocument.masterOrder!.seller}
            editPayerReceiver={editPayerReceiverData}
            editSeller={editSellerData}
        />
        <Details document={returnDocument} />
    </Space>
}