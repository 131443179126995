import {AxiosInstance} from "axios";
import API, {BlobAndFilename, getBlobAndFileName} from "../../utils/api";
import {Slot} from "../../models/calendar/slot";
import {RangeType} from "../../components/calendar/calendar/calendar.helpers";

export class CalendarService {
    private api: AxiosInstance = API;

    getSlots([start, end]: RangeType): Promise<Slot[]> {
        if (!start || !end) {
            throw new Error('Failed to get without range');
        }
        return this.api.get("calendar/slots", {params: {
            start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD')}}).then((x) => x.data);
    }

    download([start, end]: RangeType): Promise<BlobAndFilename> {
        if (!start || !end) {
            throw new Error('Failed to get without range');
        }
        return this.api
            .get("calendar/slots/xlsx", {
                responseType: "blob",
                params: {
                    start: start.format('YYYY-MM-DD'),
                    end: end.format('YYYY-MM-DD')
                }
            })
            .then(getBlobAndFileName);
    }

    createSlot(data: any): Promise<Slot> {
        return this.api.post("calendar/slots", data).then((x) => x.data);
    }

    changeSlotStatus(id: number, data: any): Promise<Slot> {
        return this.api.post("calendar/slots/" + id + "/status", data).then((x) => x.data);
    }

    removeSlot(id: number): Promise<any> {
        return this.api.delete("calendar/slots/" + id).then((x) => x.data);
    }
}

export const calendarService = new CalendarService();
