import React, { useState } from "react";
import {useTranslate} from "../../hooks/translate.hook";
import { ColumnsType } from "antd/es/table";
import { SmartLoggerData } from "../../models/logger/logger.data";
import { ComponentPropsFromRoute } from "../../routers/routers";
import { SmartLoggerStore } from "./smart.logger.store";
import { observer } from "mobx-react";
import { Popover, Space, Tag, Typography } from "antd";
import { SmartLoggerChangesWrapper } from "./smart-logger-changes/smart.logger.changes.wrapper";
import "./smart.logger.scss";
import { SmartLoggerTableWrapper } from "../common/smart-logger-table-wrapper/smart.logger.table.wrapper";
import { isAllowed } from "../../utils/helpers";
import { useRequiredStore } from "../../utils/store";
import { RootStoreContext } from "../../stores/root/root.store";
import { FieldGroupConfig } from "../../models/filter/filter.group";
import { FieldTypeName } from "../../models/filter/filter.field.type.name";

interface SmartLoggerProps extends ComponentPropsFromRoute {}

const typeColors: { [key: string]: string } = {
    update: "gold",
    create: "green",
    remove: "red",
};
export const SmartLogger: React.FC<SmartLoggerProps> = observer(({ name }) => {
    const t = useTranslate();
    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);
    const [store] = useState(() => new SmartLoggerStore());

    if (!isAllowed(attributes, "smartlogger.list")) {
        return null;
    }
    const columns: ColumnsType<SmartLoggerData> = [
        {
            title: t("TYPE"),
            dataIndex: "type",
            key: "logger_type",
            render: (item) => <Tag color={typeColors[item]}>{item.beautify()}</Tag>,
        },
        {
            title: t("LOGGER.ENTITY.NAME"),
            dataIndex: "entity_name",
            key: "logger_entity_name",
            render: (item) => <strong>{item}</strong>,
        },
        {
            title: t("LOGGER.ENTITY.ID"),
            dataIndex: "entity_id",
            key: "logger_entity_id",
        },
        {
            title: t("UPDATED_AT"),
            dataIndex: "created_at",
            key: "logger_created_at",
            render: (item: string) => <strong>{item}</strong>,
        },
        {
            title: t("ACTION"),
            dataIndex: "action_name",
            key: "logger_action_name",
            render: (item: string, row) => {
                const attributes =
                    !Array.isArray(row.action_params) && Object.keys(row.action_params).length > 0
                        ? (row.action_params as { [k: string]: any })
                        : [];
                return !Array.isArray(attributes) ? (
                    <Popover
                        key={row.id + "_" + item}
                        trigger="click"
                        placement="bottom"
                        content={
                            <Space direction="vertical" size={[5, 5]}>
                                {Object.keys(attributes).map((key, index) => (
                                    <p key={key + "_" + index + "_" + row.id} style={{ margin: 0 }}>
                                        <span>{key}: </span>
                                        <span>{attributes[key]}</span>
                                    </p>
                                ))}
                            </Space>
                        }
                        title={<strong>{t("LOGGER.ATTRIBUTES")}</strong>}
                    >
                        <span
                            style={{
                                cursor: "pointer",
                                borderBottom: "1px dotted #000",
                                paddingBottom: 2,
                            }}
                        >
                            {item}
                        </span>
                    </Popover>
                ) : (
                    item
                );
            },
        },
        {
            title: t("USERNAME"),
            dataIndex: "username",
            key: "logger_username",
        },
        {
            title: t("IP_ADDRESS"),
            dataIndex: "ip_address",
            key: "logger_ip_address",
        },
        {
            title: t("SYSTEM"),
            dataIndex: "system",
            key: "logger_system",
        },
    ];

    const columnsConfig = [
        {
            title: "TYPE",
            key: "logger_type",
        },
        {
            title: "LOGGER.ENTITY.NAME",
            key: "logger_entity_name",
        },
        {
            title: "LOGGER.ENTITY.ID",
            key: "logger_entity_id",
        },
        {
            title: "UPDATED_AT",
            key: "logger_created_at",
        },
        {
            title: "ACTION",
            key: "logger_action_name",
        },
        {
            title: "USERNAME",
            key: "logger_username",
        },
        {
            title: "IP_ADDRESS",
            key: "logger_ip_address",
        },
        {
            title: "SYSTEM",
            key: "logger_system",
        },
    ];

    const filterGroups: FieldGroupConfig[] = [
        {
            name: "updatedAt",
            visibleName: "UPDATED_AT",
            fields: [
                {
                    name: "updatedAt",
                    type: FieldTypeName.DATE_RANGE,
                },
            ],
        },
        {
            name: "entity",
            visibleName: "LOGGER.ENTITY.TITLE",
            fields: [
                {
                    name: "parent_entity_name",
                    type: FieldTypeName.SELECT,
                    choices: store.entities.map((item) => ({
                        value: item,
                        name: item,
                    })),
                },
                {
                    name: "parent_entity_id",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "type",
            visibleName: "TYPE",
            fields: [
                {
                    name: "type",
                    type: FieldTypeName.SELECT,
                    choices: [
                        { name: "Create", value: "create" },
                        { name: "Update", value: "update" },
                        { name: "Remove", value: "remove" },
                    ],
                },
            ],
        },
        {
            name: "username",
            visibleName: "USERNAME",
            fields: [
                {
                    name: "username",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "ip_address",
            visibleName: "IP_ADDRESS",
            fields: [
                {
                    name: "ip_address",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "system",
            visibleName: "SYSTEM",
            fields: [
                {
                    name: "system",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "action",
            visibleName: "ACTION",
            fields: [
                {
                    name: "action_name",
                    type: FieldTypeName.STRING,
                    choices: store.actions.map((item) => ({
                        value: item,
                        name: item,
                    })),
                },
            ],
        },
    ];

    return (
        <>
            <Typography.Title level={3}>{t("LOGGER.LIST")}</Typography.Title>
            <SmartLoggerTableWrapper<SmartLoggerData>
                rowKey="id"
                columns={columns}
                filterGroups={filterGroups}
                columnsConfigs={{ columns: columnsConfig, pageKey: name! }}
                dataHandler={store.dataHandler}
                expandableConfig={{
                    defaultExpandAllRows: true,
                    rowExpandable: (record: SmartLoggerData) => record.changes.length > 0,
                    expandedRowRender: (record: SmartLoggerData) => (
                        <SmartLoggerChangesWrapper row={record} key={record.id} />
                    ),
                }}
            />
        </>
    );
});
