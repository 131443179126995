import React, {useState} from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../../utils/store";
import {AgentDataStoreContext} from "../../agent.data.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {Button, Checkbox, Col, Form, InputNumber, Row, Space, Typography} from "antd";
import {WarehousesFormList} from "../../../../common/warehouses-form-list/warehouses.form.list";
import {accountService} from "../../../../../services/accounts/account.service";
import {notifications} from "../../../../../services/notifications/notifications";
import {WarehouseWithSort} from "../../../../../models/warehouses/warehouse";
import {UIInputWidgetWarehouse} from "../../../../common/ui/input/widget/warehouse/ui.input.widget.warehouse";

export const AgentDataWarehouses: React.FC<ComponentPropsFromRoute> = observer(({name}) => {
    const {account} = useRequiredStore(AgentDataStoreContext);
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    if (!account) {
        return <></>;
    }

    const submit = async (values: any) => {
        setLoading(true)
        try {
            await accountService.updateWarehouses(account.id, values)
            notifications.successfully()
        } catch (e: any) {
            if (e.response?.data.fields) {
                form.setFields(e.response?.data?.fields);
            }
        } finally {
            setLoading(false);
        }
    }

    const defaultValues = {
        defaultWarehouse: account.defaultWarehouse?.id,
        warehouses: account.warehouses
            .slice()
            .sort((a, b) => a.sort! - b.sort!)
            .map((item) => ({
                warehouse: item.warehouse.id,
                maxAmount: item.maxAmount,
                sort: item.sort ? item.sort : 0,
                transactionType: item.transactionType
            })),
        inTransit: {
            enabled: account.inTransitEnabled,
            maxQty: account.inTransitMaxQty
        },
        backorder: {
            enabled: account.backorderWarehouseEnabled,
            ttl: account.backorderWarehouseOrderTtl
        }
    }

    return <>
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            name={"client_form"}
            initialValues={defaultValues}
            onFinish={async (values) => {
                await form.validateFields();
                values.warehouses.forEach((w: WarehouseWithSort, i: number) => {w.sort = i});
                await submit(values)
            }}
        >
            <Typography.Title level={3}>{t("CLIENT.WAREHOUSES.TITLE")}</Typography.Title>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={12}>
                    <Space size={[10, 10]} direction={"vertical"}>
                        <WarehousesFormList disabled={loading} form={form} withMaxAmount={true} withTransactionType={true} />
                    </Space>
                </Col>
            </Row>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={12}>
                    <Form.Item
                        noStyle
                        shouldUpdate={true}
                        >
                        {() => (
                            <Form.Item
                                name={["defaultWarehouse"]}
                                label={t("CLIENT.WAREHOUSES.DEFAULT_WAREHOUSE")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <UIInputWidgetWarehouse allowedOnly={(form.getFieldValue('warehouses') || []).map((w: WarehouseWithSort) => w.warehouse)} />
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Typography.Title level={3}>{t("CLIENT.WAREHOUSES.IN_TRANSIT.TITLE")}</Typography.Title>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={24}>
                    <Form.Item name={["inTransit", "enabled"]} valuePropName="checked">
                        <Checkbox>{t("ENABLED")}</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={6}>
                    <Form.Item
                        name={["inTransit", "maxQty"]}
                        label={t("MAX_AMOUNT")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <InputNumber disabled={loading} placeholder={t("EXAMPLE") + ": 100"} />
                    </Form.Item>
                </Col>
            </Row>
            <Typography.Title level={3}>{t("CLIENT.WAREHOUSES.BACKORDER.TITLE")}</Typography.Title>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={24}>
                    <Form.Item name={["backorder", "enabled"]} valuePropName="checked">
                        <Checkbox>{t("ENABLED")}</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={6}>
                    <Form.Item
                        name={["backorder", "ttl"]}
                        label={t("CLIENT.WAREHOUSES.BACKORDER.TTL")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <InputNumber disabled={loading} placeholder={t("EXAMPLE") + ": 100"} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={6}>
                    <Button type={"primary"} onClick={() => form.submit()}>{t("SAVE")}</Button>
                </Col>
            </Row>
        </Form>
    </>
});
