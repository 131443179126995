import { action, makeObservable, observable } from "mobx";
import { PackingListData } from "../../../../models/documents/packing-list/packing-list.data";
import { packingListService } from "../../../../services/documents/packing-lists/packing-list.service";
import React from "react";
import {
    PayerOrReceiverDetails,
    PayerReceiverFormValues,
    SellerDetails,
} from "../../../../models/payer-receiver-seller/payer.reveiver.seller";
import { payerReceiverSellerService } from "../../../../services/payer-receiver-seller/payer.receiver.seller.service";
import { PagesStore } from "../../../../stores/pages/pages.store";
import {addressService} from "../../../../services/documents/common/address.service";
import {notifications} from "../../../../services/notifications/notifications";

export class PackingListDataStore {
    @observable
    public packingListData: PackingListData | null = null;

    @observable
    public loading: boolean = false;

    @observable
    public exportModalShown: boolean = false;

    @observable
    public sourcesListShown: boolean = false;

    constructor(private id: string, private pageStore: PagesStore, private url: string) {
        makeObservable(this);
        this.editSellerData = this.editSellerData.bind(this);
        this.editPayerReceiverData = this.editPayerReceiverData.bind(this);
        this.setSourcesListShown = this.setSourcesListShown.bind(this);
        this.makeAsReadyToShip = this.makeAsReadyToShip.bind(this);
        this.init();
    }

    @action
    private setPackingListData(value: PackingListData): void {
        this.packingListData = value;
    }

    @action
    private setLoading(value: boolean): void {
        this.loading = value;
    }

    @action
    private setSellerData(seller: SellerDetails): void {
        this.packingListData!.sellerAddress = seller;
    }

    @action
    private setBillingData(data: PayerOrReceiverDetails): void {
        this.packingListData!.billingAddress = data;
    }

    @action
    private setShippingData(data: PayerOrReceiverDetails): void {
        this.packingListData!.shippingAddress = data;
    }

    @action
    private setExportModalShown(value: boolean): void {
        this.exportModalShown = value;
    }

    public openExportModal = (): void => {
        this.setExportModalShown(true);
    };

    public closeExportModal = (): void => {
        this.setExportModalShown(false);
    };

    @action
    public setSourcesListShown(value: boolean) {
        this.sourcesListShown = value;
    }

    public editSellerData = async (values: SellerDetails) => {
        await this.setSellerData(
            await addressService.updateSellerAddress(this.packingListData!.id, values)
        );
    };

    public async editPayerReceiverData(type: "payer" | "receiver", values: PayerReceiverFormValues) {
        if (type === "payer") {
            this.setBillingData(await addressService.updateBillingAddress(this.packingListData!.id, values));
        } else {
            this.setShippingData(await addressService.updateShippingAddress(this.packingListData!.id, values));
        }
    }

    public async makeAsReadyToShip() {
        await packingListService.makeAsReadyToShip(this.id)
        notifications.successfully()
    }

    private async init() {
        this.setLoading(true);
        this.setPackingListData(await packingListService.getPackingList(this.id));
        this.pageStore.updateRouteName(this.url, this.packingListData?.docNumber);
        this.setLoading(false);
    }
}

export const PackingListDataStoreContext = React.createContext<PackingListDataStore | null>(null);
