import {ColumnsType} from "antd/es/table";
import React from "react";
import {Translate} from "../../../../hooks/translate.hook";
import {DocumentFile} from "../../../../models/documents/common/files";
import {Button, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const columnsFactory = (t: Translate, removeAction: (id: number) => void): ColumnsType<DocumentFile> => {

    return [
        {
            title: t("FILE"),
            dataIndex: 'filename',
            key: 'filename',
            render: (filename, file) => <a href={"/api/v3/documents/files/" + file.id + "/" + filename}>{filename}</a>
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: 'comment',
            key: 'comment',
            className: 'pre'
        },
        {
            title: t("ACTIONS.TITLE"),
            key: "actions",
            dataIndex: "id",
            render: (id: number) => <Space size={[10, 10]} direction={"horizontal"}>
                <Button onClick={() => removeAction(id)} size={"small"} type={"primary"} danger={true} icon={<FontAwesomeIcon icon={["fas", "trash"]} />} />
            </Space>
        }
    ]
}