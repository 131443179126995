import React from "react";
import {WidgetProps} from "../widget";
import {DatePicker} from "antd";
import dayjs from 'dayjs';


export const UIInputWidgetYear: React.FC<WidgetProps> = ({value, onChange, withDefault, defaultOptions}) => {

    return (
        <DatePicker
            picker="year"
            allowClear={false}
            onChange={(v, p) => onChange!(p as any)}
            value={dayjs(value as string)}
        />
    )
}