import React, {lazy, LazyExoticComponent} from "react";
import {Space} from "antd";
import {graphService} from "../../../../services/graph/graph.service";
import {ReportPaymentsByMonthItem, ReportPaymentsStats} from "../../../../models/reports/reports";
import {ReportsMainPageStats} from "../reports-main-page-stats/reports.main.page.stats";
import {FieldGroupConfig} from "../../../../models/filter/filter.group";
import {FieldTypeName} from "../../../../models/filter/filter.field.type.name";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {observer} from "mobx-react";
import {GraphDataWithMeta} from "../../../../models/graph/graph";
import {ChartConfigDataset} from "../../../../models/reports/reports.chart.configs";
import {GraphType} from "../../../common/graph/graph";

const Chart: LazyExoticComponent<GraphType<ReportPaymentsByMonthItem, ReportPaymentsStats>> = lazy(() => import("../../../common/graph/graph"));

const getReportPaymentsGraphValues = (
    data: GraphDataWithMeta<ReportPaymentsByMonthItem, ReportPaymentsStats>
): ChartConfigDataset[] => {
    return [
        {
            data: data.items.map((item) => item.value),
            label: "",
            fill: "start",
        },
    ];
    // return data.items.map((item) => item.value);
}

const getReportPaymentsGraphLabels = (
    data: GraphDataWithMeta<ReportPaymentsByMonthItem, ReportPaymentsStats>
): string[] => {
    return data.items.map((item) => item.month + " - " + item.year);
}

const getCurrencyCode = (meta: ReportPaymentsStats): string => {
    return meta.currencyCode;
}

export const ReportsMainPageChart = observer(() => {

    const {
        commonStore: { warehouses },
    } = useRequiredStore(RootStoreContext);

    const filterGroups: FieldGroupConfig[] = [
        {
            name: "warehouses",
            visibleName: "WAREHOUSES.TITLE",
            fields: [
                {
                    name: "warehouses",
                    type: FieldTypeName.SELECT,
                    multiple: true,
                    choices: warehouses.map((warehouse) => ({
                        name: warehouse.name,
                        value: warehouse.id,
                    })),
                },
            ],
        },
    ];

    return (
        <Space direction="vertical" size={[0, 0]}>
            <Chart
                filterGroups={filterGroups}
                title="REPORTS.WS_SALES_TITLE"
                yAxeLabel="REPORTS.AMOUNT"
                xAxeLabel="REPORTS.MONTHS"
                loadDataCallback={(request) =>
                    graphService.getPaymentsByMonth<ReportPaymentsByMonthItem, ReportPaymentsStats>(request)
                }
                getGraphData={getReportPaymentsGraphValues}
                getGraphLabelsData={getReportPaymentsGraphLabels}
                getCurrencyCode={getCurrencyCode}
                meta={ReportsMainPageStats}
            />
        </Space>
    );
});
