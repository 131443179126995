import React, {useEffect, useState} from "react";
import EditorJS from "@editorjs/editorjs";
import Table from '@editorjs/table'
import Header from '@editorjs/header'
import CodeTool from '@editorjs/code'
import ImageTool from '@editorjs/image'
import AttachesTool from '@editorjs/attaches'

import './editor.scss'

interface EditorProps {
    value?: string | EditorJS;
    onChange?: (editor: EditorJS) => void;
    uploadFile?: (file: File) => any;
}

export const Editor: React.FC<EditorProps> = ({value, onChange, uploadFile}) => {

    const [ref, setRef] = useState<HTMLDivElement | null>();

    const [realValue, setRealValue] = useState<any | undefined>();

    useEffect(() => {
        if (typeof value === 'string') {
            setRealValue(JSON.parse(value));
        }
    }, [value])

    useEffect(() => {
        if (!ref) {
            return;
        }

        const element = document.createElement('div');
        ref.appendChild(element)

        const config = {
            data: realValue,
            holder: element,
            tools: {
                code: CodeTool,
                header: {
                    class: Header,
                    config: {
                        placeholder: 'Enter a header',
                        levels: [2, 3, 4],
                        defaultLevel: 3
                    }
                },
                table: {
                    class: Table,
                    inlineToolbar: true,
                    config: {
                        rows: 2,
                        cols: 3,
                    }
                },
                attaches: {
                    class: AttachesTool,
                    config: {
                        uploader: {
                            uploadByFile: (file: File) => {
                                if (uploadFile) {
                                    return uploadFile(file);
                                }
                            }
                        }
                    }
                },
                image: {
                    class: ImageTool,
                    config: {
                        uploader: {
                            uploadByFile: (file: File) => {
                                if (uploadFile) {
                                    return uploadFile(file);
                                }
                            }
                        }
                    }
                }
            }
        };

        const editor = new EditorJS(config);

        if (onChange) {
            setTimeout(() => {
                onChange(editor)
            }, 0)
        }

        return () => {
            editor.isReady.then(() => {
                editor.destroy()
                ref.removeChild(element)
            });
        }
    }, [ref, realValue])

    return (
        <div className={"editor"}>
            <div ref={r => setRef(r)}></div>
        </div>
    )
}