import {ClaimItem, ClaimItemType} from "../../../../models/claims/claims";
import {Amount} from "../../../../models/prices/price";

export interface SplitItemListItem {
    type: ClaimItemType;
    qty: number;
    invoiceNumber: string;
    invoiceDate: string;
    price: Amount;
    item?: number;
}

export const createSplitItem = (claimItem: ClaimItem): SplitItemListItem => {
    const value: SplitItemListItem = {
        type: claimItem.type,
        qty: claimItem.qty,
        invoiceNumber: claimItem.invoiceDocumentNumber,
        invoiceDate: claimItem.invoiceDate,
        price: claimItem.priceByItem
    }

    if (claimItem.invoiceDocument && claimItem.saleDocumentItem) {
        value.item = claimItem.saleDocumentItem!.id;
    }

    return value;
}
