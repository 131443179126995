import React, {useEffect, useState} from "react";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {Col, Form, Row} from "antd";
import {DebounceSelect} from "../../../../../common/debounce-select/debounce.select";
import {agentService} from "../../../../../../services/agents/agent.service";
import {useRequiredStore} from "../../../../../../utils/store";
import {UserDataStoreContext} from "../../user.data.store";

interface UserDataConnectModalProps {
    setOnSubmit: (onSubmit: () => void) => void,
    onClose:() => void
}

export const ConnectByAgentTab: React.FC<UserDataConnectModalProps> = ({setOnSubmit, onClose}) => {
    const t = useTranslate();
    const [form] = Form.useForm();
    const store = useRequiredStore(UserDataStoreContext);
    const [loading, setLoading] = useState<boolean>(false);

    const searchAgents = async (value: string) => {
        const accounts = await agentService.searchWholesaleAccount(value, true);
        return accounts.map(account => ({value: account.id, label: "[" + account.branch!.id.toUpperCase() + "] " + account.agent.number + " - " + account.agent.name}));
    };

    useEffect(() => {
        setOnSubmit(() => () => {
            form.submit();
        });
    },[])

    return (
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            name={"connect_form"}
            onFinish={() => form
                .validateFields()
                .then(values => {
                    store.connectAccounts([values.account])
                        .then(
                            () => onClose(),
                            (e: any) => form.setFields(e.response?.data?.fields)
                        )
                        .finally(() => setLoading(false));
                })
            }
        >
            <Row gutter={[10, 0]} align={"middle"}>
                <Col span={24}>
                    <Form.Item
                        name={"account"}
                        label={t("CLIENT.SEARCH")}
                        rules={[{
                            required: true,
                            message: t("FROM.ERROR.PLEASE_SELECT_VALUE")
                        }]}
                    >
                        <DebounceSelect
                            disabled={loading}
                            autoFocus
                            placeholder={t("CLIENT.SEARCH")}
                            fetchOptions={searchAgents}
                            labelInValue={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
