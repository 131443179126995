import { FieldStateGroup } from "./filter.field.state";
import { fieldTypes } from "./filter.field.type.config";
import { FilterValues } from "./filter.props";

export const createRequestFilters = (stateGroups: FieldStateGroup[]): FilterValues => {
    let requestFilters: any = {};

    stateGroups.forEach((group) => {
        group.states.forEach((state) => {
            requestFilters = {
                ...requestFilters,
                ...fieldTypes[state.config.type].toResult(state),
            };
        });
    });
    return requestFilters;
};
