import React from "react";
import { observer } from "mobx-react";
import { useRequiredStore } from "../../../../../utils/store";
import { OrdersAdvancedOrderBody } from "./orders.checkout.order.body";
import { OrdersCheckoutResult } from "../orders-checkout-result/orders.checkout.result";
import { OrdersCheckoutStoreContext } from "../orders.checkout.store";
import { NotCompletedOrder } from "../../../../../models/orders/orders-cart/orders-checkout/orders.checkout.not_completed.order";

interface OrdersCheckoutAdvancedOrderProps {
    advancedOrder: NotCompletedOrder;
}

export const OrdersCheckoutAdvancedOrder: React.FC<OrdersCheckoutAdvancedOrderProps> =
    observer(({ advancedOrder }) => {
        const store = useRequiredStore(OrdersCheckoutStoreContext);

        return (
            <>
                {!store.ordersResult[advancedOrder.id] ? (
                    <OrdersAdvancedOrderBody order={advancedOrder} />
                ) : (
                    <OrdersCheckoutResult advancedOrder={advancedOrder} />
                )}
            </>
        );
    });
