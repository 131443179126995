import React, {useState} from "react";
import {Checkbox, Col, Form, Input, Modal, Row, Select} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../utils/store";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../../models/core/response";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {OrdersCheckoutStoreContext} from "../orders.checkout.store";
import {NotCompletedOrder} from "../../../../../models/orders/orders-cart/orders-checkout/orders.checkout.not_completed.order";

interface OrdersCheckoutAddressesModalProps {
    type: "shipping" | "billing",
    order: NotCompletedOrder,
    visible: boolean,
    onClose: () => void
}

export const OrdersCheckoutAddressesModal: React.FC<OrdersCheckoutAddressesModalProps> = (
    {
        type,
        order,
        visible,
        onClose
    }) => {
    const t = useTranslate();
    const rootStore = useRequiredStore(RootStoreContext);
    const langStore = rootStore.langStore;
    const store = useRequiredStore(OrdersCheckoutStoreContext);


    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();


    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={t("ADDRESS_ADD")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                size={"middle"}
                form={form}
                layout="vertical"
                name={"edit_user_form"}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        if (!values.add_to_client) {
                            setLoading(true);
                            delete values.add_to_client;
                            store.addCustomAddress({
                                ...values,
                                type,
                                country: {id: values.country, name: langStore.countries[values.country]},
                                custom: true,
                                checked: true
                            }, order);
                            form.resetFields();
                            setLoading(false);
                            onClose();
                        } else {
                            setLoading(true);
                            delete values.add_to_client;
                            store.addNewAgentAddress({...values, type})
                                .then(
                                    () => {
                                        form.resetFields();
                                        return onClose();
                                    },
                                    (e: AxiosError<ErrorResponseData>) => {
                                        setLoading(false);
                                        if (e.response?.data.fields) {
                                            form.setFields(e.response?.data?.fields);
                                        }
                                    })
                                .then(() =>
                                    (e: any) => form.setFields(e.response?.data?.fields))
                                .finally(() => setLoading(false));
                        }
                    })
                }
            >
                <Row gutter={[10, 0]} align={"middle"}>
                    <Col span={12}>
                        <Form.Item style={{marginBottom: "10px"}} valuePropName={"checked"} name="add_to_client">
                            <Checkbox style={{width: "100%"}} disabled={loading}>{t("CART.ADD_TO_CLIENT")}</Checkbox>
                        </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.add_to_client !== nextValues.add_to_client}>
                        {({getFieldValue}) => getFieldValue("add_to_client") ? (
                            <Col span={24}>
                                <Form.Item
                                    name="name"
                                    label={t("CART.ADDRESS_LABEL")}
                                    rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("CART.ADDRESS_LABEL")}/>
                                </Form.Item>
                            </Col>
                        ) : null}
                    </Form.Item>
                    <Col span={24}>
                        <Form.Item
                            name="companyName"
                            label={t("COMPANY_NAME")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("COMPANY_NAME")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="firstName"
                            label={t("FIRSTNAME")}
                            required={false}
                        >
                            <Input disabled={loading} type="text" placeholder={t("FIRSTNAME")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="lastName"
                            label={t("LASTNAME")}
                            required={false}
                        >
                            <Input disabled={loading} type="text" placeholder={t("LASTNAME")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="vat"
                            label={"VAT"}
                        >
                            <Input disabled={loading} type="text" placeholder={"EE00000000"}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="customsCode"
                            label={"EORI"}
                        >
                            <Input disabled={loading} type="text" placeholder={"EORI"}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="regCode"
                            label={t("REG_NUMBER")}
                        >
                            <Input disabled={loading} type="text" placeholder={t("REG_NUMBER")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="country"
                            label={t("COUNTRY")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Select
                                disabled={loading}
                                showSearch
                                optionFilterProp={"children"}
                                placeholder={t("COUNTRY_SELECT")}
                            >
                                {langStore.countries.map(({id, name}) => (
                                    <Select.Option
                                        key={id as string}
                                        value={id as string}
                                    >
                                        {name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="region"
                            label={t("REGION")}
                        >
                            <Input disabled={loading} type="text" placeholder={t("REGION")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="city"
                            label={t("CITY")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("CITY")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="index"
                            label={t("INDEX")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("INDEX")}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="address"
                            label={t("ADDRESS")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("ADDRESS")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="phone"
                            label={t("PHONE")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("PHONE")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="email"
                            label={t("EMAIL")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("EMAIL")}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
