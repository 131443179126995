import React from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {WholesaleAccount} from "../../../../../models/accounts/customer-account/wholesale-account/wholesale.account";
import {Descriptions} from "antd";
import {Link} from "react-router-dom";


interface UserDataWSCartProps {
    account: WholesaleAccount
}
export const UserDataWSCart: React.FC<UserDataWSCartProps> = observer(({account}) => {
    const t = useTranslate();

    return (
        <Descriptions
            className="data-content-wrapper-descriptions"
            size={"small"}
            layout={"vertical"}
            contentStyle={{ fontSize: "14px" }}
            labelStyle={{ color: "#858585" }}
            column={{ xxl: 6, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
        >
            <Descriptions.Item label={t("CLIENT.NUMBER")}><Link to={"/clients/" + account.agent.number}>{account.agent.name} ({account?.agent.number})</Link></Descriptions.Item>
            <Descriptions.Item label={"VAT"}>{account.agent.vat || "-"}</Descriptions.Item>
            <Descriptions.Item label={"EORI"}>{account.agent.customsCode || "-"}</Descriptions.Item>
            <Descriptions.Item label={t("REG_NUMBER")}>{account.agent.regCode || "-"}</Descriptions.Item>
            <Descriptions.Item label={t("DEPARTMENT_ID")}>{account.agent.group}</Descriptions.Item>
            <Descriptions.Item label={t("ORIGINAL_COUNTRY")}>
                {account?.agent?.country?.name || '-'}
            </Descriptions.Item>
        </Descriptions>
    );
});
