import { getFromStorage } from "./../../../../utils/helpers";
import { action, makeObservable, observable } from "mobx";
import { FilterDataHandler } from "../../../../models/filter/filter.data.handler";
import { Order } from "../../../../models/orders/orders-list/order";
import { ordersListService } from "../../../../services/orders/orders-list/orders.list.service";
import React from "react";
import { Action } from "../../../../models/action/action";
import { proformasService } from "../../../../services/documents/proformas/proformas.service";
import {stringify} from "query-string";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import dayjs from "dayjs";

export enum AgentOrdersListActionKeys {
    DELIVERY_ORDER_CREATE,
    DOCUMENT_PROFORMA_CREATE,
}
export const checkIfKeysValid = (data: any): boolean => {
    if (!Array.isArray(data)) {
        return false;
    }
    if (data.length !== 0 && data.some((item) => typeof item !== "number")) {
        return false;
    }
    return true;
};
export class AgentDataOrdersStore {
    static CHECKED_ORDERS_KEY = "agent_checked_orders";

    @observable
    public checkedOrders: React.Key[] = getFromStorage(AgentDataOrdersStore.CHECKED_ORDERS_KEY, [], checkIfKeysValid);

    @observable
    public createProformaModalShown: boolean = false;

    @observable
    public deliveryOrderModalShown: boolean = false;

    dataHandler: FilterDataHandler<Order>;

    public actions: Action<AgentOrdersListActionKeys>[] = [
        {
            key: AgentOrdersListActionKeys.DOCUMENT_PROFORMA_CREATE,
            text: "ORDERS.PROFORMA.CREATE",
            access: "document_proforma_create",
            isDisabled: () => !this.checkedOrders.length,
            onAction: () => this.setCreateProformaModalShown(true),
        },
        {
            key: AgentOrdersListActionKeys.DELIVERY_ORDER_CREATE,
            text: "ORDERS.DELIVERY.CREATE",
            access: "",
            isDisabled: () => !this.checkedOrders.length,
            onAction: () => this.setDeliveryOrderModalShown(true),
        },
    ];

    constructor(private accountId: number, private navigate: NavigateFunction) {
        makeObservable(this);
        this.dataHandler = new FilterDataHandler<Order>((request) =>
            ordersListService.getAgentOrdersByAccount(request, this.accountId + "")
        );
    }

    @action
    private setCreateProformaModalShown(value: boolean): void {
        this.createProformaModalShown = value;
    }

    @action
    public setDeliveryOrderModalShown(value: boolean): void {
        this.deliveryOrderModalShown = value;
    }

    @action
    private setCheckedOrders(selectedRows: React.Key[]): void {
        this.checkedOrders = selectedRows;
    }

    public checkOrders(selectedRows: React.Key[]): void {
        this.setCheckedOrders(selectedRows);
        localStorage.setItem(AgentDataOrdersStore.CHECKED_ORDERS_KEY, JSON.stringify(this.checkedOrders));
    }

    public clearAllCheckedOrders = (): void => {
        this.setCheckedOrders([]);
        localStorage.removeItem(AgentDataOrdersStore.CHECKED_ORDERS_KEY);
    };

    public openDeliveryOrderModal = (): void => {
        this.setDeliveryOrderModalShown(true);
    };

    public closeDeliveryOrderModal = (): void => {
        this.setDeliveryOrderModalShown(false);
    };

    public openCreateProformaModal = (): void => {
        this.setCreateProformaModalShown(true);
    };

    public closeCreateProformaModal = (): void => {
        this.setCreateProformaModalShown(false);
    };

    public handleDeliveryModalSubmit = () => {
        this.navigate("/orders/delivery?" + stringify({ orders: this.checkedOrders as number[] }));
        this.clearAllCheckedOrders();
    };

    public createProforma = async (values: { date: string; orders: number[] }) => {
        const result = await ordersListService.createProformaByOrders({
            ...values,
            date: dayjs(values.date).format("YYYY-MM-DD"),
            orders: this.checkedOrders as number[],
        });
        this.clearAllCheckedOrders();
        this.navigate("/proformas/" + result.id);
    };

    public addOrdersToProforma = async (id: number) => {
        await proformasService.addOrdersToProforma(id, this.checkedOrders as number[]);
    };
}
