import {Button, Popconfirm, Space, Table, Tag, Tooltip, Typography} from "antd";
import { ColumnsType } from "antd/lib/table";
import { observer } from "mobx-react";
import React from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { Link } from "react-router-dom";
import { Order, OrderAccount } from "../../../../../models/orders/orders-list/order";
import { Amount } from "../../../../../models/prices/price";
import { displayPrice } from "../../../../../models/prices/price.helper";
import { OrderStatus } from "../../../../../stores/common/common.store";
import { useRequiredStore } from "../../../../../utils/store";
import { OrdersStatus } from "../../../../orders/status/order.status";
import { ProformaDataStoreContext } from "../proforma.data.store";
import Date from "../../../../date/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {OrderComment} from "../../../../../models/orders/order-data/order.data";

export const ProformaDataIncludedOders: React.FC = observer(() => {
    const store = useRequiredStore(ProformaDataStoreContext);
    const t = useTranslate();

    const columns: ColumnsType<Order> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "90px",
            render: (id: number) => <Link to={"/orders/" + id}>{id}</Link>,
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "grandTotal",
            key: "grandTotal",
            width: "120px",
            render: (item: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(item)}</span>,
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            width: "125px",
            render: (item: OrderStatus, row) => <OrdersStatus status={item} />,
        },
        {
            title: t("COMMENTS.TITLE"),
            dataIndex: "comments",
            key: "comments",
            width: "125px",
            render: (item: OrderComment[], row) => item.length > 0 ?
                <Tag color={"red"} style={{whiteSpace: 'normal'}}>{item.map((comment) => comment.message).join(', ')}</Tag> : <>-</>
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            width: 150,
            render: (item: string) => <Date dateString={item} showTime />,
        },
    ];

    if (store.proformaData?.status === "opened") {
        columns.push({
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            width: 100,
            render: (_: any, row) =>
                store.proformaData?.status !== "closed" ? (
                    <Popconfirm
                        title={t("ARE_YOU_SURE")}
                        onConfirm={() => store.removeIncludedOrder(row)}
                        placement="top"
                        okText={t("YES")}
                        cancelText={t("NO")}
                    >
                        <Tooltip placement="topRight" title={t("REMOVE")}>
                            <Button
                                className={"card-btn btn-remove btn"}
                                type="default"
                                icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                            />
                        </Tooltip>
                    </Popconfirm>
                ) : null,
        });
    }
    return (
        <Space direction="vertical" size={[0, 0]}>
            <Typography.Title level={3}>{t("PROFORMAS.INCLUDED_ORDERS.TITLE")}</Typography.Title>
            <Table rowKey="id" columns={columns} dataSource={[...store.includedOrders]} pagination={false} />
        </Space>
    );
});
