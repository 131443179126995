import {AxiosInstance} from "axios";
import API from "../../../utils/api";
import {Aggregator} from "../../../models/transaction/aggregator";
import {System} from "../../../models/systems/system/system";

export class TransactionService {
    private api: AxiosInstance = API;

    setTransaction(documentId: number, transactionID: string, aggregatorId: string): Promise<void> {
        return this.api
            .post('documents/' + documentId + '/transaction', {id: transactionID, aggregator: aggregatorId})
            .then(x => x.data);
    }

    getAggregators(): Promise<Aggregator[]> {
        return this.api.get<System[]>("transaction/aggregators").then(x => x.data);
    }
}

export const transactionService = new TransactionService();