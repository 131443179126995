import React, { useState } from "react";
import { Observer, observer } from "mobx-react";
import {useTranslate} from "../../../hooks/translate.hook";
import FilteredTable from "../../common/filtered-table/filtered.table";
import { WarehousesTaxesItem, WarehouseTaxes } from "../../../models/warehouses/warehouses.taxes";
import { Country } from "../../../models/countries/country";
import { Branch } from "../../../models/branches/branch";
import { useRequiredStore } from "../../../utils/store";
import { RootStoreContext } from "../../../stores/root/root.store";
import { Button, Popconfirm, Row, Tooltip, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WarehousesTaxesStore } from "./warehouses.taxes.store";
import { WarehousesTaxesModal } from "./warehouses-taxes-modal/warehouses.taxes.modal";
import { ComponentPropsFromRoute } from "../../../routers/routers";
import { FilterKey } from "../../../models/filtered-table/filtered.table.props";

interface WarehousesTaxesProps extends ComponentPropsFromRoute {}
export const WarehousesTaxes: React.FC<WarehousesTaxesProps> = observer(({ name }) => {
    const t = useTranslate();
    const [store] = useState(() => new WarehousesTaxesStore());
    const {
        langStore,
        commonStore: { warehouses, branches },
    } = useRequiredStore(RootStoreContext);

    const columns = [
        {
            title: "VAT",
            dataIndex: "vatNumber",
            key: "vatNumber",
        },
        {
            title: t("TAX.PERCENT"),
            dataIndex: "taxPercent",
            key: "taxPercent",
            width: "120px",
            render: (item: string) => <>{item}%</>,
        },
        {
            title: t("TAX.COEFFICIENT"),
            dataIndex: "taxCoefficient",
            key: "taxCoefficient",
            width: "140px",
        },
        {
            title: t("COUNTRY"),
            dataIndex: "country",
            key: "country",
            render: (item: Country) => (item ? <>{item.name}</> : t("COUNTRY_ALL")),
        },
        {
            title: t("WAREHOUSES.NAME"),
            dataIndex: "warehouse",
            key: "warehouse",
            render: (item: WarehousesTaxesItem) => <>{item.name}</>,
        },
        {
            title: t("CLIENT.TYPE"),
            dataIndex: "type",
            key: "type",
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            className: "table-button-cell",
            render: (_: any, item: WarehouseTaxes) => (
                <>
                    <Tooltip placement="topRight" title={t("EDIT")}>
                        <Button
                            className="table-btn btn"
                            onClick={() => store.openEditModal(item)}
                            type="default"
                            icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                        />
                    </Tooltip>
                    <Observer>
                        {() => (
                            <WarehousesTaxesModal
                                item={item}
                                countries={langStore.countries}
                                types={store.types}
                                visible={store.isEditModalShown(item)}
                                onSuccess={(request, item) =>
                                    item ? store.updateItem(request, item) : Promise.reject()
                                }
                                onClose={store.closeEditModal}
                            />
                        )}
                    </Observer>{" "}
                    <Popconfirm
                        title={t("ARE_YOU_SURE")}
                        onConfirm={() => store.removeItem(item)}
                        placement="left"
                        okText={t("YES")}
                        cancelText={t("NO")}
                    >
                        <Tooltip placement="topRight" title={t("REMOVE")}>
                            <Button
                                className="table-btn btn-remove btn"
                                type="default"
                                icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                            />
                        </Tooltip>
                    </Popconfirm>
                </>
            ),
        },
    ];

    const columnsConfigs = [
        {
            title: "WAREHOUSES.AGENCY_NAME",
            key: "branch",
        },
        {
            title: "VAT.TITLE",
            key: "vatNumber",
        },
        {
            title: "TAX.PERCENT",
            key: "taxPercent",
        },
        {
            title: "TAX.COEFFICIENT",
            key: "taxCoefficient",
        },
        {
            title: "COUNTRY",
            key: "country",
        },
        {
            title: "WAREHOUSES.NAME",
            key: "warehouse",
        },
        {
            title: "CLIENT.TYPE",
            key: "type",
        },
    ];

    const filters: FilterKey[] = [
        {
            key: "id",
            label: "ID",
            type: "string",
        },
        {
            key: "vatNumber",
            label: "VAT.TITLE",
            type: "string",
        },
        {
            key: "taxCoefficient",
            label: t("TAX.COEFFICIENT"),
            type: "int",
        },
        {
            key: "countries",
            label: t("COUNTRIES"),
            type: "select",
            options: langStore.countries.map(({ id, name }) => ({ value: id as string, label: name })),
        },
    ];

    if (warehouses && branches && store.types) {
        filters.splice(
            1,
            0,
            ...([
                {
                    key: "warehouses",
                    label: t("WAREHOUSES.TITLE"),
                    type: "select",
                    options: warehouses.map(({ id, name }) => ({ value: id, label: name })),
                },
                {
                    key: "branches",
                    label: t("WAREHOUSES.AGENCY_NAME"),
                    type: "select",
                    options: branches.map(({ id, name }) => ({ value: id, label: name })),
                },
                {
                    key: "types",
                    label: t("CLIENT.TYPE"),
                    type: "select",
                    options: store.types.map((type) => ({ value: type, label: type })),
                },
            ] as FilterKey[])
        );
    }

    return (
        <>
            <Row>
                <Typography.Title level={3}>{t("WAREHOUSES.TAXES.TITLE")}</Typography.Title>
                <Tooltip key={2} placement="topLeft" title={t("WAREHOUSES.TAXES.CREATE")}>
                    <Button
                        className="btn"
                        style={{ margin: "3px 0 0 5px" }}
                        onClick={store.openCreateModal}
                        type="default"
                        icon={<FontAwesomeIcon icon={["fas", "plus"]} />}
                    />
                </Tooltip>
            </Row>
            <FilteredTable
                columns={columns}
                columnsConfigs={{ columns: columnsConfigs, pageKey: name }}
                filterKeys={filters}
                dataHandler={store.dataHandler}
            />
            <WarehousesTaxesModal
                countries={[{ id: null, name: t("COUNTRY_ALL") }, ...langStore.countries]}
                types={store.types}
                visible={store.isCreateModalShown()}
                onClose={store.closeCreateModal}
                onSuccess={store.createItem}
            />
        </>
    );
});
