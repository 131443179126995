import React from "react";
import {CardSelectSelectableCard} from "./card-select-selectable-card";
import {CardSelectData, CardSelectRowKey} from "./card-select.model";
import {Row, Col} from "antd";

interface CardSelectProps {
    data: CardSelectData[],
    valueKey: string;
    titleKey: string;
    rowKeys: CardSelectRowKey[];
    onChange?: (value: number | string) => void,
    value?: number | string
}
export const CardSelect: React.FC<CardSelectProps> = ({data, titleKey, valueKey, rowKeys, onChange, value}) => {

    return (
      <Row gutter={[30, 30]}>
          {data.map(item => (
              <Col className={"card-col"} key={item[valueKey]} xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                  <CardSelectSelectableCard
                      title={item[titleKey]}
                      data={item}
                      rowKeys={rowKeys}
                      onClick={() => onChange!(item[valueKey])}
                      value={value === item[valueKey]}
                  />
              </Col>
          ))}
      </Row>
    );
}