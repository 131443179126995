import {Link} from "react-router-dom";
import React from "react";
import {Translate} from "../../../../hooks/translate.hook";
import {SmartColumn} from "../../../common/smart-table/smart-table.model";
import {FieldType} from "../../../common/smart-table/smart-table.field-config";

export const columnsFactory = (t: Translate): SmartColumn<any>[] => {
    return [
        {
            visibleName: "ID",
            name: "id",
            type: FieldType.STRING,
            render: (id: number) => <Link to={"/users/" + id}>{id}</Link>,
        },
        {
            visibleName: t("FIRSTNAME"),
            name: "firstName",
            type: FieldType.STRING,
        },
        {
            visibleName: t("LASTNAME"),
            name: "lastName",
            type: FieldType.STRING,
        },
        {
            visibleName: t("EMAIL"),
            name: "email",
            type: FieldType.STRING,
        },
        {
            visibleName: t("DEPARTMENT.TITLE"),
            name: "departmentName",
            type: FieldType.STRING,
            render: (v, o) => v + " (" + o.departmentType +  ")"
        },
        {
            visibleName: t("DEPARTMENTS.ROLE.TITLE"),
            name: "roleName",
            type: FieldType.STRING,
        },
        // {
        //     visibleName: t("CREATED_AT"),
        //     name: "createdAt",
        //     type: FieldType.DATETIME
        // },
    ]
}