import React, {useMemo, useState} from "react";
import {Button, Space, Steps} from "antd";

export interface StepConfig<T = any> {
    title: string;
    description: string;
    component: React.FC<T>,
    componentProps?: T,
    hideControls?: boolean
}

interface StepperProps<T> {
    steps: StepConfig[]
    onFinish: (data: T) => void;
    data: T;
}

export class StepperContextValue<T> {

    private onStepComplete: () => Promise<any> = () => Promise.reject();

    constructor(
        public data: T,
        private stepsQty: number,
        private step: number,
        private setStep: (step: number) => void,
    ) {
    }

    static create(data: any, stepsQty: number) {
        return new StepperContextValue(
            data,
            stepsQty,
            0,
            () => null
        );
    }

    goNext() {
        this.setStep(++this.step)
    }

    handleOnStepCompleteAndGoNext() {
        this
            .onStepComplete()
            .then(() => this.goNext())
    }

    finish(onSuccess: (data: T) => any) {
        this
            .onStepComplete()
            .then(() => onSuccess(this.data));
    }

    goBack() {
        this.setStep(--this.step);
    }

    isNotFirstStep(): boolean {
        return this.step !== 0;
    }

    isLastStep(): boolean {
        return this.step + 1 === this.stepsQty;
    }

    setOnStepComplete(fn: () => Promise<any>) {
        this.onStepComplete = fn;
    }
}

export const StepperContext = React.createContext(StepperContextValue.create({}, 0));

export const Stepper = <T extends unknown>({steps, data, onFinish}: StepperProps<T>): JSX.Element => {

    const [step, setStep] = useState<number>(0);

    const contextValue = useMemo(() => new StepperContextValue(data, steps.length, step, setStep), []);

    const stepConfig = steps[step];
    if (!stepConfig) {
        return <></>
    }

    const Component = stepConfig.component;

    return <>
        <Steps current={step} size={"small"}>
            {steps.map((step, key) =>
                <Steps.Step
                    key={key}
                    title={step.title}
                    description={step.description}
                />
            )}
        </Steps>
        <div style={{marginTop: 20}}>
            <StepperContext.Provider value={contextValue}>
                <Component {...stepConfig.componentProps} />
            </StepperContext.Provider>
        </div>
        <Space direction={"horizontal"}>
            {!stepConfig.hideControls && contextValue.isNotFirstStep() ? (
                <Button onClick={() => contextValue.goBack()}>Go Back</Button>
            ) : null}
            {!stepConfig.hideControls && !contextValue.isLastStep() ? (
                <Button onClick={() => contextValue.handleOnStepCompleteAndGoNext()} type={"primary"}>Next</Button>
            ) : null}
            {contextValue.isLastStep() ? (
                <Button onClick={() => contextValue.finish(onFinish)} type={"primary"}>Submit</Button>
            ) : null}
        </Space>
    </>
}