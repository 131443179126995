import React, {useMemo, useState} from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import { articlesPriceListsService } from "../../../../services/articles/articles/articles.price-lists.service";
import {Space, Typography} from "antd";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
import {ArticlesLinks} from "../articles-links/articles.links";
import {convertTableColumnsToColumnConfigs} from "../../../../utils/table";
import {branchesService} from "../../../../services/branches/branches.service";
import {downloadBlobFile} from "../../../../utils/blob.download";
import {EditModal} from "../../../branches/data/branch-data-prices/edit/edit.modal";
import {UploadModal} from "../../../branches/data/branch-data-prices/upload/upload.modal";
import {FilterDataHandler} from "../../../../models/filter/filter.data.handler";
import {PriceList} from "../../../../models/price-lists/price.lists";
import {columnsFactory} from "./articles.price-lists.list.columns";
import {filtersFactory} from "./articles.price-lists.list.filters";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {isAllowed} from "../../../../utils/helpers";

interface ArticlesPriceListsListProps extends ComponentPropsFromRoute {}
export const ArticlesPricesListsList: React.FC<ArticlesPriceListsListProps> =
    observer(({ name }) => {

        const t = useTranslate();

        const [editModalItem, setEditModalItem] = useState<PriceList | null>(null);
        const [uploadModalItem, setUploadModalItem] = useState<PriceList | null>(null);

        const {
            authStore: { branch, attributes },
        } = useRequiredStore(RootStoreContext);

        const download = async (price: PriceList) => {
            const data = await branchesService.downloadPrice(price.id);
            downloadBlobFile(data.blob, data.filename)
        }

        const columns = useMemo(() => columnsFactory(
            t,
            setEditModalItem,
            setUploadModalItem,
            download,
            isAllowed(attributes, 'price_list_management'),
            isAllowed(attributes, 'price_list_management_all_branch'),
            branch
        ),[]);
        const filters = useMemo(() => filtersFactory(t), []);
        const dataHandler = useMemo(() => new FilterDataHandler(r => articlesPriceListsService.getAll(r)), [])

        return (
            <Space direction="vertical" size={[50, 50]}>
                <ArticlesLinks/>
                <Space direction="vertical" size={[0, 0]}>
                    <Typography.Title level={3}>
                        {t("PRICE_LISTS")}
                    </Typography.Title>
                    <FilteredTable
                        columns={columns}
                        filterKeys={filters}
                        dataHandler={dataHandler}
                        columnsConfigs={{ columns: convertTableColumnsToColumnConfigs(columns), pageKey: name }}
                    />
                </Space>
                {editModalItem ? (
                    <EditModal
                        priceList={editModalItem}
                        onCancel={() => setEditModalItem(null)}
                        onComplete={() => dataHandler.reloadWithLastRequest()}
                        branchId={editModalItem.branch.id}
                    />
                ) : null}
                {uploadModalItem ? (
                    <UploadModal
                        priceList={uploadModalItem}
                        onCancel={() => setUploadModalItem(null)}
                        onComplete={() => dataHandler.reloadWithLastRequest()}
                    />
                ) : null}
            </Space>
        );
    });
