import React from "react";
import {Col, Form, Row, Select} from "antd";
import {config} from "../config";
import {useTranslate} from "../../../../../hooks/translate.hook";

export interface ProductFieldsProps {
    loading: boolean;
    courierName: string;
}

const incoterms: string[] = [
    'DAP', 'EXW', 'FCA', 'FAS', 'FOB', 'CFR', 'CIF', 'CPT', 'CIP', 'DAF', 'DES', 'DEQ', 'DDU', 'DDP'
]

export const IncotermsFields: React.FC<ProductFieldsProps> = ({loading, courierName}) => {

    const t = useTranslate();

    if (!config[courierName]?.useIncoterms) {
        return null;
    }

    return (
        <Row>
            <Col span={24}>
                <Form.Item
                    name="incoterms"
                    label="Incoterms"
                    rules={[
                        {
                            required: true,
                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                        },
                    ]}
                >
                    <Select disabled={loading}>
                        {incoterms.map(term =>
                            <Select.Option value={term}>{term}</Select.Option>)}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    )
}