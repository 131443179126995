import {AxiosInstance} from "axios";
import API from "../../../utils/api";
import {UserAccessCategory, UserAccessCategoryInfo} from "../../../models/users/access-category/user.access.category";
import {Access, AccessRequest} from "../../../models/users/access/access";

export class UserAccessesService {
    api: AxiosInstance = API;

    getAccessesCategory(): Promise<UserAccessCategory[]> {
        return this.api.get<UserAccessCategory[]>("/access/category").then(x => x.data);
    }

    postAccessCategory(values: {name: string}, id?: string): Promise<UserAccessCategoryInfo> {
        const url = id ? "/access/category/" + id : "/access/category";
        return this.api.post<UserAccessCategoryInfo>(url, values).then(x => x.data);
    }

    postAccess(request: AccessRequest, id?: number): Promise<Access> {
        const url = id ? "/access/" + id : "/access";
        return this.api.post<Access>(url, request).then(x => x.data);
    }

}

export const userAccessesService = new UserAccessesService();
