import React, {useEffect, useState} from "react";
import {Alert, Col, Form, Input, InputNumber, Modal, Row, Select, Space} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {CreditInvoice} from "../../../../models/documents/invoices/credit-invoice/credit.invoice";
import {Warehouse} from "../../../../models/warehouses/warehouse";
import {warehousesService} from "../../../../services/warehouses/warehouses.service";
import TextArea from "antd/es/input/TextArea";
import {Claim, ClaimCreditGroup, ClaimItem} from "../../../../models/claims/claims";
import {claimService} from "../../../../services/claims/claims.service";

const DESCRIPTIONS = [
    "Delivery compensation",
    "Warranty case\nGoods are damaged and should be disposed",
    "Goods returned to the warehouse",
    "Goods are lost in transit",
    "Other"
]

interface CreditInvoiceCreateModalProps {
    claim: Claim;
    items: ClaimItem[];
    onClose: () => void,
    onCompleted: (document: CreditInvoice) => void
}
export const CreditInvoiceCreateModal: React.FC<CreditInvoiceCreateModalProps> = ({claim, items, onClose, onCompleted}) => {

    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
    const [creditGroups, setCreditGroups] = useState<ClaimCreditGroup[]>([]);

    useEffect(() => {
        warehousesService.getAllWarehouses().then(warehouses => setWarehouses(warehouses));

        if (items.length > 0) {
            claimService.getCreditGroupsWithItems(claim.id, items.map(i => i.id)).then(creditGroups => setCreditGroups(creditGroups)).catch(() => onClose());
        } else {
            claimService.getCreditGroups(claim.id).then(creditGroups => setCreditGroups(creditGroups)).catch(() => onClose());
        }

    }, [claim.id])

    const complete = (document: CreditInvoice) => {
        onCompleted(document);
        onClose();
    };

    if (!creditGroups.length || !warehouses.length) {
        return null;
    }

    const defaultValues: any = {items: {}};
    creditGroups.forEach(group => {
        defaultValues.items[group.key] = {
            vatPercent: group.vatPercent,
            vatNumber: group.vatNumber
        }
    })

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("DOCUMENTS.INVOICES.CREDIT.CREATE")}
            okText={t("CREATE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"dispatch_modal"}
                initialValues={defaultValues}
                onFinish={() => form
                    .validateFields()
                    .then(values => {

                        const resultGroups = Object.keys(values.items).map((key: any, idx: number) => {
                            const item = values.items[key];
                            return {
                                key: key,
                                warehouse: creditGroups[idx].warehouse.id,
                                usedWarehouse: item.warehouse,
                                description: item.description === "Other" ? item.customDescription : item.description,
                                vatNumber: item.vatNumber,
                                vatPercent: parseFloat(item.vatPercent)
                            }
                        })

                        const result: any = {
                            groups: resultGroups
                        }

                        if (items.length > 0) {
                            result.items = items.map(i => i.id)
                            claimService.createCreditWithItems(claim.id, result)
                                .then(complete)
                                .finally(() => setLoading(false));
                        } else {
                            claimService.createCredit(claim.id, result)
                                .then(complete)
                                .finally(() => setLoading(false));
                        }

                        setLoading(true);

                    })
                }
            >
                <Space
                    direction={"vertical"}
                    size={[15, 15]}
                    >
                    <Alert message={"Will ge generated " + creditGroups.length + " credit invoice(s), please fill additional data"} />

                    {creditGroups.map((group, idx) =>
                        <Row gutter={10}>
                            <h4 style={{borderLeft: "2px solid #000000", paddingLeft: 10, margin: "15px 5px"}}>
                                Group {idx+1} [{group.currencyCode}] - {group.warehouse.name}, VAT: {group.vatNumber}, {group.vatPercent}%
                            </h4>
                            <Col span={24}>
                                <Form.Item
                                    name={["items", group.key, "warehouse"]}
                                    label={t("WAREHOUSES.WAREHOUSE_TITLE")}
                                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                                >
                                    <Select>
                                        {warehouses.map(warehouse => (
                                            <Select.Option value={warehouse.id}>{warehouse.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={["items", group.key, "description"]}
                                    label={t("DESCRIPTION")}
                                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                                >
                                    <Select>
                                        {DESCRIPTIONS.map(value => (
                                            <Select.Option value={value}>{value}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                    shouldUpdate
                                >
                                    {() => form.getFieldValue(["items", group.key, "description"]) === 'Other' ? (
                                        <Form.Item
                                            name={["items", group.key, "customDescription"]}
                                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                                        >
                                            <TextArea />
                                        </Form.Item>
                                    ) : null}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={["items", group.key, "vatNumber"]}
                                    label={"Seller "  + t("VAT.NUMBER")}
                                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={["items", group.key, "vatPercent"]}
                                    label={t("VAT.RATE")}
                                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                                >
                                    <InputNumber min={0} max={100} />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                </Space>

            </Form>
        </Modal>
    )
}