import {RouteObject} from "react-router/dist/lib/context";
import {routers} from "./routers";
import {ExtendedRoute} from "../components/core/router/extended-route/extended.route";

export const createRouteConfig = (config = routers): RouteObject[] => {
    return config.map(route => ({
        path: route.path,
        element: <ExtendedRoute config={route} />,
        children: route.children
            ? createRouteConfig(route.children)
            : []
    }));
}