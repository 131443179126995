import React from "react";
import {InvoiceData} from "../../../../models/documents/invoices/invoice-data/invoice.data";
import {action, makeObservable, observable} from "mobx";
import {invoiceService} from "../../../../services/documents/invoices/invoices.service";
import {
    PayerOrReceiverDetails,
    PayerReceiverFormValues,
    SellerDetails
} from "../../../../models/payer-receiver-seller/payer.reveiver.seller";
import {PagesStore} from "../../../../stores/pages/pages.store";
import {addressService} from "../../../../services/documents/common/address.service";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {notifications} from "../../../../services/notifications/notifications";
import {AxiosError} from "axios";
import {senderService} from "../../../../services/documents/common/sender.service";

export type InvoiceModalType = "export" | "dispatch" | "editWeight" | "edit" | "setDate" | "sourcesList" | "wrongDelivery";

export class InvoiceDataStore {
    @observable
    public invoice: InvoiceData | null = null;

    @observable
    public loading: boolean = false

    @observable
    public dispatchModalShown: boolean = false;

    @observable
    public setDateModalShown: boolean = false;

    @observable
    public exportModalShown: boolean = false;

    @observable
    public editWeightModalShown: boolean = false;

    @observable
    public editModalShown: boolean = false;

    @observable
    public wrongDeliveryModalShown: boolean = false;

    @observable
    public wrongDeliveryReason: string = "";

    @observable
    public wrongDeliveryTextReason: string = "";

    @observable
    public sourcesListShown: boolean = false;

    @observable
    public masterOrderId: number | null = null;

    @observable
    public recalculatingWeight: boolean = false;


    constructor(private id: string, private pageStore: PagesStore, private url: string, private navigate: NavigateFunction) {
        makeObservable(this);
        this.editSellerData = this.editSellerData.bind(this);
        this.editPayerReceiverData = this.editPayerReceiverData.bind(this);
        this.setExportModalShown = this.setExportModalShown.bind(this);
        this.setEditModalShown = this.setEditModalShown.bind(this);
        this.setEditWeightModalShown = this.setEditWeightModalShown.bind(this);
        this.setDispatchModalShown = this.setDispatchModalShown.bind(this);
        this.setSetDateModalShown = this.setSetDateModalShown.bind(this);
        this.setSourcesListShown = this.setSourcesListShown.bind(this);
        this.setWrongDeliveryModalShown = this.setWrongDeliveryModalShown.bind(this);
        this.onUploadErrorResolveCompleted = this.onUploadErrorResolveCompleted.bind(this);
        this.onUploadError = this.onUploadError.bind(this);
        this.recalcWeight = this.recalcWeight.bind(this);
        this.edit = this.edit.bind(this);
        this.init();
    }

    @action
    public setRecalculatingWeight(value: boolean): void {
        this.recalculatingWeight = value;
    }

    @action
    public setMasterOrderId(id: number | null): void {
        this.masterOrderId = id;
    }

    @action
    public setDispatchModalShown(value: boolean): void {
        this.dispatchModalShown = value;
    }

    @action
    public setSetDateModalShown(value: boolean): void {
        this.setDateModalShown = value;
    }

    @action
    public setExportModalShown(value: boolean): void {
        this.exportModalShown = value;
    }

    @action
    public setEditWeightModalShown(value: boolean): void {
        this.editWeightModalShown = value;
    }

    @action
    public setEditModalShown(value: boolean): void {
        this.editModalShown = value;
    }

    @action
    public setSourcesListShown(value: boolean) {
        this.sourcesListShown = value;
    }

    @action
    public setWrongDeliveryModalShown(value: boolean) {
        this.wrongDeliveryModalShown = value;
    }

    @action
    public setInvoice(invoice: InvoiceData): void {
        if (invoice.status === "sync") {
            setTimeout(() => this.reloadInvoice(), 2000)
        }
        this.invoice = invoice;
    }

    @action
    public setLoading(loading: boolean): void {
        this.loading = loading;
    }

    @action
    private setSellerData(seller: SellerDetails): void {
        this.invoice!.sellerAddress = seller;
    }

    @action
    private setBillingData(data: PayerOrReceiverDetails): void {
        this.invoice!.billingAddress = data;
    }

    @action
    private setShippingData(data: PayerOrReceiverDetails): void {
        this.invoice!.shippingAddress = data;
    }

    public async editSellerData(values: SellerDetails) {
        await this.setSellerData(await addressService.updateSellerAddress(this.invoice!.id, values));
    }

    public async editPayerReceiverData(type: "payer" | "receiver", values: PayerReceiverFormValues) {
        if (type === "payer") {
            this.setBillingData(await addressService.updateBillingAddress(this.invoice!.id, values));
        } else {
            this.setShippingData(await addressService.updateShippingAddress(this.invoice!.id, values));
        }
    }

    public handleModal(modalType: InvoiceModalType, open: boolean): void {
        const modalsMap: {[type: string]: (value: boolean) => void} = {
            edit: this.setEditModalShown,
            export: this.setExportModalShown,
            dispatch: this.setDispatchModalShown,
            editWeight: this.setEditWeightModalShown,
            setDate: this.setSetDateModalShown,
            sourcesList: this.setSourcesListShown,
            wrongDelivery: this.setWrongDeliveryModalShown
        }
        if (modalsMap[modalType]) {
            const handler = modalsMap[modalType];
            handler(open);
        }
    }

    // public getMasterOrderId(): number | null {
    //     if (this.invoice?.orders && this.invoice.orders.length) {
    //         const order = this.invoice.orders.find(order => order === this.invoice?.order.id);
    //         return order ? order : null;
    //     }
    //     return null;
    // }

    public async recalcWeight(
        values: {weight: string},
        openNotification: (type: "success" | "error", message: string) => void
    ) {
        this.setRecalculatingWeight(true);
        try {
            await invoiceService.recalcWeight(this.id!, values)
            await this.setInvoice(await invoiceService.getInvoice(this.id));
            this.setRecalculatingWeight(false);
            openNotification("success", "RECALC_WEIGHT.SUCCESS");
        } catch (e: any) {
            this.setRecalculatingWeight(false);
        }
    }

    public async edit(
        values: {paymentTerms: number, shipmentCondition: string},
    ) {
        await invoiceService.edit(this.id!, values)
        await this.setInvoice(await invoiceService.getInvoice(this.id));
    }

    public async cancelInvoice(openNotification: (type: "success" | "error", message: string) => void) {
        await invoiceService.cancelInvoice(this.id!)
        await this.setInvoice(await invoiceService.getInvoice(this.id));
        openNotification("success", "INVOICE.CANCEL.SUCCESS");
    }

    public async cancelInvoiceWithOrders(openNotification: (type: "success" | "error", message: string) => void) {
        await invoiceService.cancelInvoiceWithOrders(this.id!)
        await this.setInvoice(await invoiceService.getInvoice(this.id));
        openNotification("success", "INVOICE.CANCEL.SUCCESS");
    }

    public async moveToCart() {
        await invoiceService.moveInvoiceToCart(this.id!);
        this.navigate('/clients/' + this.invoice?.agent.number + '/cart');
    }

    public async moveToCartWithClear() {
        await invoiceService.moveInvoiceToCartWithClear(this.id!);
        this.navigate('/clients/' + this.invoice?.agent.number + '/cart');
    }

    @action
    public onUploadError(reason: string, textReason: string) {
        this.wrongDeliveryModalShown = true;
        this.wrongDeliveryReason = reason;
        this.wrongDeliveryTextReason = textReason;
    }

    @action
    public onUploadErrorResolveCompleted(skipDeliveryCheck: boolean, addForgotDeliveryOrders: boolean) {
        this.wrongDeliveryModalShown = false;
        this.uploadInvoice(skipDeliveryCheck, addForgotDeliveryOrders);
    }

    public async uploadInvoice(skipDeliveryCheck: boolean, addForgotDeliveryOrders: boolean) {
        try {
            await invoiceService.uploadInvoice(this.id!, {skipDeliveryCheck, addForgotDeliveryOrders})
            await this.setInvoice(await invoiceService.getInvoice(this.id));
            notifications.message("success", "UPLOAD.SUCCESS");
        }
        catch (e: any) {
            switch (e.response?.data.reason) {
                case 'COMBINE_DELIVERY_AND_NOT_DELIVERY':
                case 'NOT_ALL_DELIVERY_INCLUDED':
                    this.onUploadError(e.response?.data.reason, e.response?.data.message)
                    break;
            }
        }
    }

    public async sendDocumentToClient() {
        await senderService.send(parseInt(this.id));
        notifications.successfully()
    }

    private async init() {
        return this.reload()
    }

    public async reload() {
        this.setLoading(true);
        await this.reloadInvoice();
        this.pageStore.updateRouteName(this.url, this.invoice?.docNumber);
        // this.setMasterOrderId(this.getMasterOrderId());
        this.setLoading(false);
    }

    public async reloadInvoice() {
        this.setInvoice(await invoiceService.getInvoice(this.id));
    }

}

export const InvoiceDataStoreContext = React.createContext<null | InvoiceDataStore>(null);
