import {Button, Card, Col, Row, Space, Spin} from "antd";
import { observer } from "mobx-react";
import React, { useState } from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { BranchDataBanksCard } from "./branch.data.banks.card";
import { BranchDataBanksModal } from "./branch.data.banks.modal";
import {useParams} from "react-router-dom";
import {BranchDataBanksStore, BranchDataBanksStoreContext} from "./branch.data.banks.store";

export const BranchDataBanks: React.FC = observer(() => {
    const { branchId } = useParams<{ branchId: string }>();
    const t = useTranslate();
    const [store] = useState(() => new BranchDataBanksStore(branchId!));
    const [modalShown, setModalShown] = useState<boolean>(false);

    if (store.loading) {
        return <Spin/>
    }
    return (
        <BranchDataBanksStoreContext.Provider value={store}>
                <Space direction="vertical" size={[20, 20]}>
                    <Button
                        onClick={() => setModalShown(true)}
                        type="primary"
                    >
                        {t("BRANCH.DATA.BANKS.ADD")}
                    </Button>
                <Row gutter={[30, 30]}>
                    {store.branchBanks.length ? (
                        store.branchBanks.map((branchBank, index) => (
                            <Col
                                key={branchBank.id}
                                className={"card-col"}
                                xxl={8}
                                xl={8}
                                lg={12}
                                md={12}
                                sm={24}
                                xs={24}
                            >
                                <BranchDataBanksCard bank={branchBank} />
                            </Col>
                        ))
                    ) : (
                        <Col
                            className={"card-col"}
                            xxl={8}
                            xl={8}
                            lg={12}
                            md={12}
                            sm={24}
                            xs={24}
                        >
                            <Card className="card">{t("NO_DATA")}</Card>
                        </Col>
                    )}
                </Row>
            </Space>
            <BranchDataBanksModal
                visible={modalShown}
                onClose={() => setModalShown(false)}
            />
        </BranchDataBanksStoreContext.Provider>
    );
});
