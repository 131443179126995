import {UserContacts} from "../users/user/extended.user";
import {System} from "../systems/system/system";
import {Branch} from "../branches/branch";
import {Department} from "../departments/department";
import {DepartmentRole} from "../departments/roles";

export interface WikiTreeItem {
    id: number;
    title: string;
    leaf: boolean;
    children: WikiTreeItem[];
    parent: number;
}

export interface WikiArticle {
    id: number;
    title: string;
    description: string;
    content: string;
    author: UserContacts;
    lastEditor: UserContacts;
    lastEditedAt: string;
    editable: boolean;
}

export interface WikiEditableArticle {
    id: number;
    author: UserContacts;
    contents: WikiEditableArticleContent[]
    defaultContent: WikiEditableArticleContent;
    accesses: WikiArticleAccess[];
    lastEditor: UserContacts;
    lastEditedAt: string;
    leaf: boolean;
    parent?: number;
}

export interface WikiEditableArticleContent {
    title: string;
    description: string;
    body: string;
    language: string;
}

export enum WikiArticleAccessLevel {
    EDITABLE = "editable",
    READABLE = "readable"
}

export interface WikiArticleAccess {
    id?: number;
    level: WikiArticleAccessLevel;
    system: System;
    branch: Branch | null;
    department: Department | null;
    departmentRole: DepartmentRole | null;
}