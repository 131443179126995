import React, {useEffect, useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Col, Form, Input, Modal, Row} from "antd";
import {
    UIInputWidgetPropertyResponsibleUser
} from "../../ui/input/widget/property-responsible-user/ui.input.widget.property-responsible-user";
import {propertyService} from "../../../../services/property/property.service";
import {PropertyType} from "../../../../models/property/property";
import "./create.inventory-task.modal.scss"

interface CreateInventoryTaskModalProps {
    onClose: () => void;
}

export const CreateInventoryTaskModal: React.FC<CreateInventoryTaskModalProps> = ({ onClose }) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const [types, setTypes] = useState<PropertyType[]>([]);

    useEffect(() => {
        propertyService.getAllTypes().then(types => {
            setTypes(types);
        });
    }, []);

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("PROPERTY.INVENTORY_TASKS_LIST.TITLE")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={onClose}
            maskClosable={false}
            width={700}
            className={"inventory-task-modal"}
        >
            {types.length > 0 ? (
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        items: types.map(v => ({type: v.id, responsiblePersons: []}))
                    }}
                    onFinish={() => form
                        .validateFields()
                        .then(async values => {
                            setLoading(true);
                            try {
                                await propertyService.createInventoryTask(values.items)
                                onClose();
                            }
                            catch (e: any) {
                                form.setFields(e.response?.data?.fields || [])
                            } finally {
                                setLoading(false);
                            }
                        })
                    }
                >
                    <div className={"inventory-task-modal-rows"}>
                        {types.map((type, key) => (
                            <Row gutter={[0, 0]} key={key}>
                                <Col span={10}>
                                    <Form.Item
                                        noStyle
                                        name={['items', key, 'type']}
                                    >
                                        <Input type={"hidden"} value={type.id} />
                                    </Form.Item>
                                    {type.name}
                                </Col>
                                <Col span={14}>
                                    <Form.Item
                                        name={['items', key, 'responsiblePersons']}
                                        rules={[{ required: true, message: 'Missing users' }]}
                                        style={{width: '100%'}}
                                    >
                                        <UIInputWidgetPropertyResponsibleUser multiple />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}
                    </div>
                </Form>
            ) : (
                <>Loading...</>
            )}
        </Modal>
    );
}