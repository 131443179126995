import React, {useState} from "react";
import {DebounceSelect} from "../../../common/debounce-select/debounce.select";
import {useTranslate} from "../../../../hooks/translate.hook";
import {articlesService} from "../../../../services/articles/articles.service";

interface ArticleSearchProps {
    value?: number;
    onChange?: (value: number) => void
    autoFocus?: boolean;
    selectStyle?: React.CSSProperties;
}

export const ArticleSearch: React.FC<ArticleSearchProps> = ({value, onChange, autoFocus, selectStyle, ...props}) => {

    const t = useTranslate();

    const fetchOptions = async (search: string): Promise<any[]> => {
        const articles = await articlesService.searchArticle(search);
        return articles.map(({ id, name, description }) => ({
            value: id,
            label: name + " - " + description,
        }));
    }

    return <DebounceSelect
        autoFocus={autoFocus}
        placeholder={t("ARTICLES.SEARCH")}
        style={selectStyle}
        fetchOptions={fetchOptions}
        allowClear={true}
        value={value}
        onChange={onChange}
        labelInValue={false}
        {...props}
    />
}