import { makeObservable, observable, action } from "mobx";
import { WarehouseExpanded } from "../../../../models/warehouses/warehouses";
import { branchesService } from "../../../../services/branches/branches.service";
import { FilterDataHandler } from "../../../../models/filter/filter.data.handler";
import { warehousesService } from "../../../../services/warehouses/warehouses.service";

export class BranchDataWarehousesStore {
    @observable
    public loading: boolean = false;

    @observable
    public warehouses: WarehouseExpanded[] = [];

    public dataHandler: FilterDataHandler<WarehouseExpanded>;

    constructor(private id: string) {
        makeObservable(this);

        this.dataHandler = new FilterDataHandler<WarehouseExpanded>((request) =>
            branchesService.getWarehouses(this.id, request)
        );
    }

    @action
    public setLoading(value: boolean): void {
        this.loading = value;
    }

    @action
    private setWarehouses(value: WarehouseExpanded[]): void {
        this.warehouses = value;
    }
}
