import React, {useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Button, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select, Tooltip, Typography, Upload} from "antd";
import {AxiosError} from "axios";
import {PriceList} from "../../../../../models/price-lists/price.lists";
import {branchesService} from "../../../../../services/branches/branches.service";
import {ErrorResponseData} from "../../../../../models/core/response";
import dayjs from "dayjs";

interface NextPriceModalProps {
    onCancel: () => void;
    onComplete: () => void;
    priceList: PriceList;
}

export const UploadModal: React.FC<NextPriceModalProps> = ({onCancel, onComplete, priceList}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("BRANCH.DATA.PRICES.UPLOAD.MODAL.TITLE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onCancel();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    applyForParentPrice: true,
                    applyNextPriceAt: dayjs().add(1, 'M').startOf('month')
                }}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        branchesService.uploadPrice(priceList.id, values)
                            .then(
                                () => {
                                    onCancel();
                                    onComplete();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(
                                            e.response?.data?.fields
                                        );
                                    }
                                }
                            )
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>

                        <Form.Item
                            label={t("BRANCH.DATA.PRICES.UPLOAD.FILE")}
                            name="file"
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                            valuePropName={""}
                            getValueFromEvent={(e) => e.file || null}
                        >
                            <Upload.Dragger
                                beforeUpload={() => false}
                                accept={".csv"}
                                maxCount={1}
                                disabled={loading}
                            >
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Required fields: article;price<br />
                                    Example row: MCP-001;5
                                </p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="applyForParentPrice"
                            label={t("BRANCH.DATA.PRICES.NEXT_PRICE.APPLY_FOR_PARENT_PRICE")}
                            valuePropName={"checked"}
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>
                    <Form.Item
                        noStyle
                        shouldUpdate={(p, n) => p.applyForParentPrice !== n.applyForParentPrice}
                    >
                        {() => form.getFieldValue("applyForParentPrice") ? (
                            (
                                <Col span={24}>
                                    <Form.Item
                                        name="applyNextPriceAt"
                                        label={t("BRANCH.DATA.PRICES.NEXT_PRICE.APPLY_AT")}
                                    >
                                        <DatePicker />
                                    </Form.Item>
                                </Col>
                            )
                        ) : null}
                    </Form.Item>
                </Row>
            </Form>
        </Modal>
    );
}
