import React from "react";
import {darcula} from 'react-syntax-highlighter/dist/esm/styles/prism'
import {Prism, SyntaxHighlighterProps} from "react-syntax-highlighter";

const SyntaxHighlighter = Prism as typeof React.Component<SyntaxHighlighterProps>;

export const RenderCode: React.FC<any> = ({node, inline, className, children, ...props}) => {
    const match = /language-(\w+)/.exec(className || '')

    return !inline && match ? (
        <SyntaxHighlighter
            children={String(children).replace(/\n$/, '')}
            style={darcula}
            language={match[1]}
            PreTag="div"
            {...props}
        />
    ) : (
        <code className={className} {...props}>
            {children}
        </code>
    )
}