import React from "react";
import {PropertyType as T} from "../../../../../models/property/property";
import {Tag} from "antd";

interface PropertyTypeProps {
    type: T;
}

const colorMap: [number, string][] = [
    [7, "blue"],
    [6, "lime"],
    [5, "green"],
    [4, "cyan"],
    [3, "orange"],
    [2, "yellow"],
    [1, "gold"]
]

const getColor = (type: T): string => {
    for (const [key, value] of colorMap) {
        if (type.id % key === 0) {
            return value;
        }
    }

    return colorMap[1][1];
}

export const PropertyType: React.FC<PropertyTypeProps> = ({type}) => {

    return (
        <Tag
            color={getColor(type)}
        >
            {type.name}
        </Tag>
    )
}