import {Translate} from "../../../../hooks/translate.hook";
import {PropertyInventoryTaskProperty} from "../../../../models/property/property";
import {SmartColumn} from "../../../common/smart-table/smart-table.model";
import {FieldType} from "../../../common/smart-table/smart-table.field-config";
import {InventoryTaskInventoryStatus} from "../common/inventory-task-inventory-status/inventory-task-inventory.status";
import {
    TaskPropertyStatusWidget
} from "../../ui/smart-table/filter/widget/task/property-status/task.property-status.widget";
import {ResponsiblePersonWidget} from "../../ui/smart-table/filter/widget/responsible-person/responsible-person.widget";
import {PropertyLocationWidget} from "../../ui/smart-table/filter/widget/property-location/property-location.widget";

export const columnFactory = (t: Translate): SmartColumn<PropertyInventoryTaskProperty>[] => {
    return [
        {
            name: 'code',
            visibleName: t('PROPERTY.PROPERTY.CODE'),
            type: FieldType.OBJECT,
            options: {t: 'property', f: 'property.id'},
            filterable: true
        },
        {
            name: 'description',
            visibleName: t('PROPERTY.PROPERTY.DESCRIPTION'),
            type: FieldType.STRING,
            filterable: true
        },
        {
            name: 'status',
            visibleName: t('STATUS'),
            type: FieldType.STRING_ARRAY,
            filterable: true,
            render: s => <InventoryTaskInventoryStatus status={s} />,
            widget: TaskPropertyStatusWidget
        },
        {
            name: 'responsiblePerson',
            visibleName: t('PROPERTY.PROPERTY.RESPONSIBLE_PERSON'),
            type: FieldType.INTEGER_ARRAY,
            render: (_, v) => v.property.responsiblePerson.firstName + ' ' + v.property.responsiblePerson.lastName,
            filterable: true,
            widget: ResponsiblePersonWidget
        },
        {
            name: 'location',
            visibleName: t('PROPERTY.PROPERTY.LOCATION'),
            type: FieldType.STRING_ARRAY,
            filterable: true,
            render: (_, v) => '(' + v.property.location.code + ') ' + v.property.location.name,
            widget: PropertyLocationWidget
        },
        {
            name: 'destroyedQty',
            visibleName: t('PROPERTY.INVENTORY_TASK_PROPERTY_DAMAGED_QTY.TITLE'),
            type: FieldType.INTEGER,
            filterable: true
        },
        {
            name: 'lostQty',
            visibleName: t('PROPERTY.INVENTORY_TASK_PROPERTY_LOST_QTY.TITLE'),
            type: FieldType.INTEGER,
            filterable: true
        },
        {
            name: 'writeOffQty',
            visibleName: t('PROPERTY.INVENTORY_TASK_PROPERTY_WRITE_OFF_QTY.TITLE'),
            type: FieldType.INTEGER,
            filterable: false,
            render: v => <b>{v}</b>
        },
        {
            name: 'writeOffReason',
            visibleName: t('REASON'),
            type: FieldType.STRING,
            filterable: false
        }
    ]
}