import React from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {Col, Row, Space, Typography, Divider} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Link} from "react-router-dom";

const { Title } = Typography;

interface WikiDefaultPageProps extends ComponentPropsFromRoute {
}

export const WikiDefaultPage: React.FC<WikiDefaultPageProps> = () => {

    const t = useTranslate();

    return (
        <Row wrap={false} gutter={[40, 0]}>
            <Col flex="auto">
                <Title level={2}>WIKI</Title>
                {t("WIKI.MAIN.CONTENT")}
            </Col>
            <Col flex="250px">
                <Space direction={"vertical"} split={<Divider orientation="left" />}>
                    <Space direction={"vertical"}>
                        <Link to="/wiki/new-page" className="ant-btn ant-btn-primary" style={{width: '100%'}}>
                            {t("WIKI.CMD.ADD_PAGE")}
                        </Link>
                    </Space>
                </Space>
            </Col>
        </Row>
    )
}