import {ColumnsType} from "antd/lib/table/interface";

export type ColumnsConfig = { key: string; title: string }[];

export const convertTableColumnsToColumnConfigs = <T>(columns: ColumnsType<T>): ColumnsConfig => {
    return columns.map(column => {
        return {
            key: column.key as string,
            title: column.title as string
        }
    })
}