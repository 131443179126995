import { notification } from 'antd';

export const copyToClipboard = async (text: string) => {
    try {
        await navigator.clipboard.writeText(text)
        notification.success({message: "Success copy to clipboard"})
    }
    catch (e: any) {
        notification.error({message: "Failed to copy"})
    }
}