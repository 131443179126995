import React, { RefObject, useEffect, useRef } from "react";
import { FieldsProps } from "../filter.popup";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import { DatePicker, Form } from "antd";

export const DateFields: React.FC<FieldsProps> = ({ popupRef, config }) => {
    const t = useTranslate();
    const inputRef = useRef() as RefObject<any>;

    useEffect(() => {
        inputRef.current.focus();
    }, [inputRef.current]);

    return (
        <Form.Item
            className={"filterDatePicker"}
            name={["fields", config.name, "values"]}
            rules={[
                {
                    required: true,
                    message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                },
            ]}
        >
            <DatePicker.RangePicker
                defaultOpen
                ref={inputRef}
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                inputReadOnly
                getPopupContainer={() => popupRef.current as HTMLElement}
            />
        </Form.Item>
    );
};
