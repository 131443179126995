export enum FieldRuleName {
    EQ = "=",
    NEQ = "!=",
    LT = "<",
    GT = ">",
    LTE = "<=",
    GTE = ">=",
    LIKE = "%",
}

export interface FilterRule {
    label: string;
    value: string;
}

export type FilterRuleMap = Record<FieldRuleName, FilterRule>;

export const filterRuleMap: FilterRuleMap = {
    [FieldRuleName.EQ]: {
        value: "",
        label: "=",
    },
    [FieldRuleName.NEQ]: {
        value: "!=",
        label: "!=",
    },
    [FieldRuleName.LT]: {
        value: "<",
        label: "<",
    },
    [FieldRuleName.GT]: {
        value: ">",
        label: ">",
    },
    [FieldRuleName.LTE]: {
        value: "<=",
        label: "<=",
    },
    [FieldRuleName.GTE]: {
        value: ">=",
        label: ">=",
    },
    [FieldRuleName.LIKE]: {
        value: "%",
        label: "Like",
    },
};
