import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, Space, Typography} from "antd";
import {AgentNewContext} from "../../agent.new.modal";
import {useRequiredStore} from "../../../../../../utils/store";
import {RootStoreContext} from "../../../../../../stores/root/root.store";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {observer} from "mobx-react";

export const ShipmentsStep: React.FC = observer(() => {

    const {setStep, getValue, setValue, fields} = React.useContext(AgentNewContext);

    const { commonStore } = useRequiredStore(RootStoreContext);

    const t = useTranslate();

    const [loading, setLoading] = useState<boolean>(false);

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFields(fields);
    }, [fields])

    const defaultValues = {
        shipments: getValue('shipments')
    }

    return <Space direction="vertical" size={[15, 15]} style={{marginTop: 40}}>
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            name={"client_form"}
            initialValues={defaultValues}
            onFinish={async (values) => {
                await form.validateFields();
                setValue('shipments', values.shipments)
                setStep(5)
            }}
        >
            <Typography.Title level={3}>{t("CLIENT.SHIPMENTS.TITLE")}</Typography.Title>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={12}>
                    <Row gutter={[30, 0]} align={"top"}>
                        <Col span={24}>
                            <Form.Item name={["shipments", "shipmentCondition"]} label={t("SHIPMENT_CONDITION")}>
                                <Input disabled={loading} type="text" placeholder={t("SHIPMENT_CONDITION")} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name={["shipments", "shipmentProcedureName"]} label={t("SHIPMENT_PROCEDURE")}>
                                <Select
                                    disabled={loading}
                                    placeholder={t("SHIPMENT_PROCEDURE")}
                                >
                                    {["default", "autodoc"].map((procedure) => (
                                        <Select.Option key={procedure} value={procedure}>
                                            {procedure}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name={["shipments", "customShipper"]} label={t("CLIENT.CUSTOM_SHIPPER")}>
                                <Select allowClear loading={loading} placeholder={t("CLIENT.CUSTOM_SHIPPER")}>
                                    {commonStore.shippers.map(({ id, companyName, personName }) => (
                                        <Select.Option key={id} value={id}>
                                            {companyName} ({personName})
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={24}>
                    <Space direction={"horizontal"} size={[10, 10]}>
                        <Button type={"default"} onClick={() => setStep(2)}>Back</Button>
                        <Button type={"primary"} onClick={() => form.submit()}>Next</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    </Space>
})