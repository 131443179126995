import React, { useState } from 'react';
import {Button, Upload} from 'antd';
import {UploadFile} from "antd/es/upload/interface";
import {UploadChangeParam} from "antd/lib/upload";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PreviewImage} from "../preview-image/preview-image";

export interface FileUploaderProps<T> {
    action: string;
    value?: CustomUploadFile[];
    onChange?: (value: CustomUploadFile[]) => void;
    onRemove?: (value: CustomUploadFile) => Promise<any>;
    maxCount?: number;
    disableUpload?: boolean;
    data?: Record<string, unknown>;
}

export interface CustomUploadFile extends UploadFile {
}

export interface Response {
    id: number;
    filename: string;
    url: string;
}

export const FileUploader = <T extends Response>({value, onChange, onRemove, action, maxCount, disableUpload, data}: FileUploaderProps<T>) => {

    const [previewFile, setPreviewFile] = useState<UploadFile<T>|null>(null);

    const onUploadChange = (event: UploadChangeParam<UploadFile<T>>) => {

        if (event.file.response) {
            const resp = event.file.response;
            const newFile: CustomUploadFile | undefined = event.fileList.find(file => file.uid === event.file.uid);
            if (newFile) {
                newFile.url = resp.url;
                newFile.fileName = resp.filename;
                newFile.uid = resp.id.toString();
            }
        }

        onChange && onChange(event.fileList);
    };

    const onPreview = (file: CustomUploadFile): any => {
        setPreviewFile(file);
    };

    const closePreview = (): any => {
        setPreviewFile(null);
    };

    return (
        <>
            <Upload
                maxCount={maxCount}
                action={action}
                listType="picture-card"
                fileList={value}
                onChange={onUploadChange}
                onPreview={onPreview}
                onRemove={onRemove}
                showUploadList={{
                    showDownloadIcon: true,
                    showPreviewIcon: true,
                    showRemoveIcon: true,
                }}
                data={data}
            >
                {disableUpload ? null : (
                    <Button type={"text"} icon={<FontAwesomeIcon icon={["fas", "upload"]} />}>Upload</Button>
                )}
            </Upload>
            {!!previewFile ? (
                <PreviewImage
                    onClose={closePreview}
                    title={previewFile?.name || ''}
                    url={previewFile?.url || ''}
                />
            ) : null}
        </>
    );
};