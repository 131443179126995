import React, {useState} from "react";
import {PlannerTask, PlannerTaskSystemStatus} from "../../../../../../../models/planner/planner";
import {PlannerTasksViewAction} from "../task.view.action";
import {Action} from "../../model";
import {PlannerTasksViewAcceptModal} from "./task.view.accept.modal";
import {SystemUserRole} from "../../../../../../../models/users/user/system.user-with-attributes";

export const PlannerTasksViewAcceptAction: Action = ({task, reload}) => {

    const [modalShown, setModalShown] = useState<boolean>(false);

    const run = () => {
        setModalShown(true);
        return Promise.resolve()
    }

    const onClose = () => {
        setModalShown(false)
        reload();
    }

    return (
        <>
            <PlannerTasksViewAction
                title={"ACCEPT"}
                run={run}
            />
            {modalShown ? (
                <PlannerTasksViewAcceptModal
                    task={task}
                    onClose={onClose}
                />
            ) : null}
        </>
    )
}

PlannerTasksViewAcceptAction.isApplicable = (task: PlannerTask, role: SystemUserRole) => {
    return role === "superior" && [
        PlannerTaskSystemStatus.PENDING,
    ].includes(task.status);
}