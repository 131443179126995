import { Space } from "antd";
import React from "react";
import "./reports.main.page.scss";
import {ReportsMainPageCategories} from "./reports-main-page-categories/reports.main.page.categories";
import {ReportsMainPageChart} from "./reports-main-page-chart/reports.main.page.chart";

export const ReportsMainPage: React.FC = () => {

    return (
        <Space direction="vertical" size={[50, 50]}>
            <ReportsMainPageCategories />
            <ReportsMainPageChart />
        </Space>
    );
};