import React, {useEffect, useState} from "react";
import {Button, List, Space, Tooltip, Typography} from "antd";
import {ClaimCommentsForm} from "./claim.comments.form";
import {Claim, ClaimComment} from "../../../../models/claims/claims";
import {claimService} from "../../../../services/claims/claims.service";
import Date from "../../../date/date";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Comment} from "@ant-design/compatible";

export interface ClaimCommentsFormProps {
    claim: Claim;
}

export const ClaimComments: React.FC<ClaimCommentsFormProps> = ({claim}) => {
    const t = useTranslate();

    const [comments, setComments] = useState<ClaimComment[]>([]);

    const onComment = (comment: ClaimComment): void => {
        setComments([...comments, comment])
    }

    const onRemove = async (comment: ClaimComment) => {
        await claimService.removeComment(comment.id);
        const newComments = [...comments];
        const idx = newComments.indexOf(comment);
        if (-1 !== idx) {
            newComments.splice(idx, 1);
            setComments(newComments);
        }
    }

    useEffect(() => {
        claimService.getClaimComments(claim.id).then(comments => setComments(comments));
    }, [claim.id]);

    return (
        <>
            <Typography.Title level={3} style={comments.length ? {marginBottom: 10} : undefined}>{t("COMMENTS.TITLE")}</Typography.Title>

            {comments.length ? (
                <List
                    className="comment-list"
                    itemLayout="horizontal"
                    dataSource={comments}
                    renderItem={comment => (
                        <li>
                            <Comment
                                author={comment.user ? (comment.user.firstName + " " + comment.user.lastName) : "System"}
                                content={comment.text}
                                datetime={(
                                    <Space align="center" size={[10, 10]} direction="horizontal">
                                        <Tooltip title={<Date dateString={comment.createdAt} showTime />}>
                                            <Date dateString={comment.createdAt} showTime />
                                        </Tooltip>
                                        <Tooltip key={1} placement="topRight" title={t('REMOVE')}>
                                            <Button
                                                onClick={() => onRemove(comment)}
                                                className="btn btn-remove"
                                                style={{ marginRight: "5px", border: "none", boxShadow: "none" }}
                                                type="default"
                                                size={"small"}
                                                icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                                            />
                                        </Tooltip>
                                    </Space>
                                )}
                            />
                        </li>
                    )}
                />
            ) : null}

            <ClaimCommentsForm claim={claim} onCompleted={onComment} />
        </>
    );
};
