import React from "react";
import {ColumnFilterItem} from "antd/lib/table/interface";
import {Input, Select} from "antd";

interface FilterDropdownSelectorProps {
    items: ColumnFilterItem[] | undefined
    onChange: (value: string[]) => void
    onConfirm: () => void
}

export const FilterDropdownSelector: React.FC<FilterDropdownSelectorProps> = ({items, onChange, onConfirm}) => {
    if (!items || items.length === 0) {
        return <Input
            onChange={e => onChange(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => onConfirm()}
            placeholder={"Please input"}
        />
    }

    return <Select
        onChange={e => onChange(e as string[])}
        placeholder={"Please select"}
        mode={"multiple"}
        style={{width: "100%"}}
    >
        {items.map(item => (
            <Select.Option key={item.value as string} value={item.value as string}>{item.text}</Select.Option>
        ))}
    </Select>
}