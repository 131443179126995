import API, {BlobAndFilename, getBlobAndFileName} from "../../../utils/api";
import {AxiosInstance} from "axios";
import {PaginatedResult} from "../../../models/core/paginated.result";
import {PackingList} from "../../../models/documents/packing-list/packing-list";
import {PaginatedRequest} from "../../../models/core/paginated.request";
import {PackingListData} from "../../../models/documents/packing-list/packing-list.data";

export class PackingListService {
    private api: AxiosInstance = API;

    public getPackingLists(request: PaginatedRequest): Promise<PaginatedResult<PackingList>> {
        return this.api.get<PaginatedResult<PackingList>>(
            "documents/packing-list",
            {params: request.toParams()}
        ).then(x => x.data);
    }

    public getPackingList(id: string): Promise<PackingListData> {
        return this.api.get<PackingListData>("documents/packing-list/" + id).then(x => x.data);
    }

    public customDownload(id: string): Promise<BlobAndFilename> {
        return this.api.get("documents/packing-list/" + id + "/custom-download", {responseType: "blob"}).then(getBlobAndFileName);
    }

    public makeAsReadyToShip(id: string): Promise<any> {
        return this.api.post("documents/packing-list/" + id + "/pre-shipment");
    }
}

export const packingListService = new PackingListService();
