import React, {useEffect, useMemo, useState} from "react";
import {ReportConfig, ReportConfigResolvableField} from "../../../../../models/reports/reports";
import {Space} from "antd";
import {ReportsReportResolvableField} from "./resolvable-field/reports.report.resolvable-field";
import {config, ResolvableFieldConfig} from "./reports.report.resolvable-fields.config";

interface ReportsReportResolvableFieldsProps {
    config: ReportConfig;
    defaultValues: Record<string, any>;
    onValidated: (values: Record<string, any>) => void;
}

export const ReportsReportResolvableFields: React.FC<ReportsReportResolvableFieldsProps> = ({config: cfg, defaultValues, onValidated}) => {

    const [value, setValue] = useState<Record<string, any>>({});

    const fieldConfigMap = useMemo(() => {
        const result: Record<string, ResolvableFieldConfig> = {};
        cfg.resolvableFields.forEach(field => {
            field.fields.forEach(name => {
                result[name] = config[field.type]
            });
        });
        return result;
    }, [cfg])

    const fieldSetUpConfigMap = useMemo(() => {
        const result: Record<string, ReportConfigResolvableField> = {};
        cfg.resolvableFields.forEach(field => {
            result[field.name] = field;
        });
        return result;
    }, [cfg])

    useEffect(() => {
        const importedValues: any = {};
        Object.keys(defaultValues).forEach(key => {
            importedValues[key] = fieldConfigMap[key].import(defaultValues[key])
        })

        let defaultsUpdated = false;
        cfg.resolvableFields.forEach(rf => {
            if (rf.withDefault) {
                let setDefaults = false;
                rf.fields.forEach(f => {
                    if (!importedValues.hasOwnProperty(f)) {
                        setDefaults = true;
                        return false;
                    }
                })
                if (setDefaults) {
                    defaultsUpdated = true;
                    const defaults = config[rf.type].getDefaults(rf);
                    Object.keys(defaults).forEach(key => {
                        importedValues[key] = defaults[key]
                    })
                }
            }
        })

        if (defaultsUpdated) {
            setValueAndValidate(importedValues)
        } else {
            setValue(importedValues)
        }
    }, [defaultValues])

    if (!cfg.resolvableFields.length) {
        return <></>
    }

    const setValueAndValidate = (values: Record<string, any>) => {
        setValue(oldValues => {
            const newValues = {...oldValues, ...values}
            let valid = true;
            Object.keys(fieldConfigMap).forEach(fieldName => {
                if (!newValues[fieldName] && !fieldSetUpConfigMap[fieldName]?.allowEmpty) {
                    return valid = false;
                }
            })

            if (valid) {
                const validatedValue: any = {};
                Object.keys(newValues).forEach(key => {
                    validatedValue[key] = fieldConfigMap[key].export(newValues[key])
                })
                onValidated(validatedValue);
            }

            return newValues;
        });
    }

    return (
        <Space direction={"horizontal"}>
            {cfg.resolvableFields.map((field, key) => (
                <ReportsReportResolvableField
                    value={value}
                    setValue={fv => setValueAndValidate(fv)}
                    field={field}
                    key={key}
                />
            ))}
        </Space>
    )
}