import React, {useEffect, useState} from "react";
import {Col, Form, Modal, Row, Select} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Warehouse} from "../../../../models/warehouses/warehouse";
import {warehousesService} from "../../../../services/warehouses/warehouses.service";
import {Claim, ClaimItem} from "../../../../models/claims/claims";
import {claimService} from "../../../../services/claims/claims.service";

interface SetWarehouseModalProps {
    claim: Claim;
    items: ClaimItem[];
    onClose: () => void,
    onCompleted: () => void
}
export const SetWarehouseModal: React.FC<SetWarehouseModalProps> = ({claim, items, onClose, onCompleted}) => {

    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);

    useEffect(() => {
        warehousesService.getAllWarehouses().then(warehouses => setWarehouses(warehouses));
    }, [])

    const complete = () => {
        onCompleted();
        onClose();
    };

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("CLAIM.SET_WAREHOUSES")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"dispatch_modal"}
                initialValues={{}}
                onFinish={() => form
                    .validateFields()
                    .then(values => {

                        const dto = {
                            warehouse: values.warehouse,
                            items: items.map(i => i.id)
                        };

                        claimService.batchSetWarehouse(claim.id, dto)
                            .then(complete)
                            .finally(() => setLoading(false));

                        setLoading(true);

                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="warehouse"
                            label={t("WAREHOUSES.WAREHOUSE_TITLE")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <Select>
                                {warehouses.map(warehouse => (
                                    <Select.Option value={warehouse.id}>{warehouse.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}