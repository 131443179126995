import React from "react";
import {observer} from "mobx-react";
import {ExpandedTable} from "../../../../common/order-details/expanded.table";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../utils/store";
import { ColumnsType } from "antd/lib/table";
import {PackingListDetail} from "../../../../../models/documents/packing-list/packing-list.data";
import {Link} from "react-router-dom";
import {PackingListDataStoreContext} from "../packing-list.data.store";
import {PackingListDataDetailsTableFooter} from "./packing-list.data.details.table-footer";
import {nonStrictComparator} from "../../../../ui/table/filter-dropdown/filter-dropdown.comparator";
import {FilterDropdown} from "../../../../ui/table/filter-dropdown/filter-dropdown";

export const PackingListDataDetails: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(PackingListDataStoreContext);

    const columns: ColumnsType<PackingListDetail> = [
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "articleName",
            key: "packing-list_data_detail_articleName",
            width: "135px",
            render: (article: string) => article.toUpperCase() !== "DELIVERY" ? (
                <Link to={"/articles/" + article} style={{ whiteSpace: "nowrap" }}>
                    {article}
                </Link>
            ) : t("DELIVERY.TITLE"),
            sorter: (a, b) => a.articleName > b.articleName ? 1 : -1,
            onFilter: nonStrictComparator(v => v.articleName),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "packing-list_data_detail_articleDescription",
            width: "200px",
        },
        {
            title: t("HS_CODE"),
            dataIndex: "hs",
            key: "packing-list_data_detail_hs",
            width: "120px",
            sorter: (a, b) => a.hs > b.hs ? 1 : -1,
            onFilter: nonStrictComparator(v => v.hs),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "packing-list_data_detail_quantity",
            width: "100px",
            sorter: (a, b) => a.qty > b.qty ? 1 : -1,
        },
        {
            title: t("BOX_NUMBER"),
            dataIndex: "boxNumber",
            width: "150px",
            key: "packing-list_data_detail_totalPrice",
            sorter: (a, b) => a.boxNumber > b.boxNumber ? 1 : -1,
            onFilter: nonStrictComparator(v => v.boxNumber),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("PALLET_NUMBER"),
            dataIndex: "palletNumber",
            width: "150px",
            key: "packing-list_data_detail_palletNumber",
            sorter: (a, b) => a.palletNumber > b.palletNumber ? 1 : -1,
            onFilter: nonStrictComparator(v => v.palletNumber),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("WEIGHT"),
            dataIndex: "grossWeightTotal",
            key: "packing-list_data_detail_grossWeightTotal",
            width: "150px",
            render: (_: any, row) => <>{row.netWeightTotal || ""} / {row.grossWeightTotal || ""}</>,
            sorter: (a, b) => a.grossWeightTotal > b.grossWeightTotal ? 1 : -1,
        },
        {
            title: t("CLIENT.ORDER.NUMBER"),
            dataIndex: "referenceOrderNumber",
            key: "packing-list_data_detail_referenceOrderNumber",
            width: 120,
            sorter: (a, b) => a.referenceOrderNumber > b.referenceOrderNumber ? 1 : -1,
            onFilter: nonStrictComparator(v => v.referenceOrderNumber),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("ORDERS.ORDER.ID"),
            dataIndex: "referenceOrderId",
            key: "packing-list_data_detail_referenceOrderId",
            width: 120,
            render: item => item ? <Link to={"/orders/" + item}>{item}</Link> : null,
            sorter: (a, b) => a.referenceOrderId > b.referenceOrderId ? 1 : -1,
            onFilter: nonStrictComparator(v => v.referenceOrderId),
            filterDropdown: FilterDropdown,
        },
    ];

    const columnsConfig = [
        {
            title: "ARTICLES.TITLE_SINGLE",
            key: "packing-list_data_detail_articleName",
        },
        {
            title: "DESCRIPTION",
            key: "packing-list_data_detail_articleDescription",
        },

        {
            title: "QUANTITY",
            key: "packing-list_data_detail_quantity",
        },
        {
          title: "HS_CODE",
          key: "packing-list_data_detail_hs"
        },
        {
            title: "BOX_NUMBER",
            key: "packing-list_data_detail_totalPrice"
        },
        {
            title: "PALLET_NUMBER",
            key: "packing-list_data_detail_palletNumber"
        },
        {
            title: "WEIGHT",
            key: "packing-list_data_detail_grossWeightTotal",
        },
        {
            title: "CLIENT.ORDER.NUMBER",
            key: "packing-list_data_detail_referenceOrderNumber",
        },
        {
            title: "ORDERS.ORDER.ID",
            key: "packing-list_data_detail_referenceOrderId",
        },
    ]

    return (
        <ExpandedTable<PackingListDetail>

            title="DETAILS"
            pageKey="packing-list_data_detail"
            columns={columns}
            columnsConfig={columnsConfig}
            tableData={store.packingListData!.items}
            tableLoading={store.loading}
            tableFooter={<PackingListDataDetailsTableFooter/>}
        />
    )
})
