import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Form, Input, InputNumber, Row, Space, Typography, Upload} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {CardSelect} from "../../../common/ui/form/card-select/card-select";
import {DeclarationsDeclarant} from "../../../../models/declarations/declarations.declarants";
import {declarationsDeclarantsService} from "../../../../services/declarations/declarations.declarants.service";
import {declarationService} from "../../../../services/declarations/declarations.services";
import {DeclarationUpload, UploadedDeclarationResponse} from "../declaration.upload.model";

interface DeclarationUploadStepProps {
    onUploaded: (params: DeclarationUpload, result: UploadedDeclarationResponse, namePrefix: string) => void
    initialValues: DeclarationUpload | null;
}

export const DeclarationUploadStep: React.FC<DeclarationUploadStepProps> = ({onUploaded, initialValues}) => {

    const [form] = Form.useForm();

    const t = useTranslate();

    const [declarants, setDeclarants] = useState<DeclarationsDeclarant[]>([]);

    const onComplete = (values: DeclarationUpload, namePrefix: string) => {
        declarationService.uploadDeclaration(values).then(r => onUploaded(values, r, namePrefix))
    }

    useEffect(() => {
        declarationsDeclarantsService.getList().then(v => setDeclarants(v))
    }, [])

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                name={"dispatch_modal"}
                initialValues={initialValues || {splitByPiecesAndBoxes: true, splitByOriginCountry: true, splitByIncoterms: true, splitByCodes: true, splitByCodesQty: true, splitByCodesQtyMax: 99}}
                onFinish={(v) => {
                    form.validateFields().then(() => {
                        const values = {...v}
                        delete values.namePrefix;
                        onComplete(values, v.namePrefix);
                    })
                }}
                >
                <Space direction={"vertical"} size={[20, 20]}>
                    <Typography.Title level={4} style={{ margin: 0 }}>
                        {t('DECLARATIONS.UPLOAD.FILE')}
                    </Typography.Title>
                    <Row gutter={[20, 20]} style={{width: '100%'}}>
                        <Col span={24}>
                            <Form.Item
                                label="File"
                                name="file"
                                rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                                valuePropName={""}
                                getValueFromEvent={(e) => e.file || null}
                            >
                                <Upload.Dragger
                                    beforeUpload={() => false}
                                    accept={".csv"}
                                    maxCount={1}
                                    disabled={false}
                                    style={{width: 500}}
                                >
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support only a CSV file</p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Typography.Title level={4} style={{ margin: 0 }}>
                        {t('DECLARATIONS.UPLOAD.DATA')}
                    </Typography.Title>
                    <Row gutter={[20, 20]} style={{width: '100%'}}>
                        <Col span={24}>
                            <Form.Item
                                name="namePrefix"
                                label={t("DECLARATIONS.NAME_PREFIX")}
                                rules={[{required: true, message: t('FROM.ERROR.PLEASE_SELECT_VALUE')}]}
                                style={{marginBottom: 0}}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="declarant"
                                label={t("DECLARATIONS.DECLARANT")}
                                rules={[{required: true, message: t('FROM.ERROR.PLEASE_SELECT_VALUE')}]}
                                style={{marginBottom: 0}}
                            >
                                <CardSelect
                                    data={declarants}
                                    valueKey={"id"}
                                    titleKey={"city"}
                                    rowKeys={[{key: 'firstName'}, {key: 'lastName'}, {key: 'email'}, {key: 'phone'}]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Typography.Title level={4} style={{ margin: 0 }}>
                        {t('DECLARATIONS.UPLOAD.OPTIONS')}
                    </Typography.Title>
                    <Row gutter={[10, 10]}>
                        <Col span={24}>
                            <Form.Item
                                name="splitByPiecesAndBoxes"
                                valuePropName={"checked"}
                                noStyle
                            >
                                <Checkbox>{t("DECLARATIONS.SPLIT.BY_PIECES_AND_BOXES")}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="splitByOriginCountry"
                                valuePropName={"checked"}
                                noStyle
                            >
                                <Checkbox>{t("DECLARATIONS.SPLIT.BY_ORIGIN_COUNTRY")}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="splitByIncoterms"
                                valuePropName={"checked"}
                                noStyle
                            >
                                <Checkbox>{t("DECLARATIONS.SPLIT.BY_INCOTERMS")}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Space direction={"horizontal"}>
                                <Form.Item
                                    name="splitByCodesQty"
                                    valuePropName={"checked"}
                                    noStyle
                                >
                                    <Checkbox>{t("DECLARATIONS.SPLIT.BY_CODES")}</Checkbox>
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                    shouldUpdate
                                >
                                    {(f) => (
                                        <Form.Item
                                            name="splitByCodesQtyMax"
                                            noStyle
                                        >
                                            <InputNumber style={{width: 60}} disabled={!f.getFieldValue('splitByCodesQty')} />
                                        </Form.Item>
                                    )}
                                </Form.Item>
                            </Space>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button
                                type={"primary"}
                                onClick={() => form.submit()}
                            >{t('UPLOAD.TITLE')}</Button>
                        </Col>
                    </Row>
                </Space>
            </Form>
        </>
    )
};