import React, {useMemo, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Button, Col, Row, Space, Typography} from "antd";
import {CreateInventoryTaskModal} from "../create/create.inventory-task.modal";
import {propertyService} from "../../../../services/property/property.service";
import {SmartTableDataHandler} from "../../../common/smart-table/smart-table.data-handler";
import {columnFactory} from "./list.inventory-tasks.columns";
import {SmartTable} from "../../../common/smart-table/smart-table";

export const InventoryTasksList: React.FC<ComponentPropsFromRoute> = () => {

    const t = useTranslate();

    const handler = useMemo(() => new SmartTableDataHandler(r => propertyService.getAllInventoryTasks(r)), []);
    const columns = useMemo(() => columnFactory(t), [t])

    const [createModalShown, setCreateModalShown] = useState<boolean>(false);

    const onCreateModalClose = async () => {
        setCreateModalShown(false);
        await handler.reloadItemsWithLastRequest();
    }

    return (
        <>
            <Space size={[10, 10]} direction={"vertical"}>
                <Typography.Title level={3} style={{margin: "5 0px"}}>
                    {t("PROPERTY.INVENTORY_TASKS_LIST.TITLE")}
                </Typography.Title>
                <Button
                    type={"primary"}
                    onClick={() => setCreateModalShown(true)}
                >
                    Create a new Task
                </Button>
                <Row>
                    <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                        <SmartTable
                            columns={columns}
                            handler={handler}
                        />
                    </Col>
                </Row>
            </Space>
            {createModalShown ? (
                <CreateInventoryTaskModal onClose={onCreateModalClose} />
            ) : null}
        </>
    )
}