import {System} from "../../models/systems/system/system";
import {SystemGroup} from "../../models/users/group/group";
import {AxiosInstance} from "axios";
import API from "../../utils/api";

export class SystemsService {
    api: AxiosInstance = API;

    getSystems(): Promise<System[]> {
        return this.api.get<System[]>("systems").then(x => x.data);
    }
    getSystemGroups(): Promise<SystemGroup[]> {
        return this.api.get<SystemGroup[]>("systems/groups").then(x => x.data);
    }
}

export const systemsService = new SystemsService();
