import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { displayPrice } from "../../../../../models/prices/price.helper";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { TableFooter } from "../../../../common/table-footer/table.footer";
import {ExpandedTable, ExpandedTableColumnsType} from "../../../../common/order-details/expanded.table";
import {CreditInvoice, CreditInvoiceItem} from "../../../../../models/documents/invoices/credit-invoice/credit.invoice";
import {generateDocumentDetails} from "../credit-invoice-data.helper";
import {convertTableColumnsToColumnConfigs} from "../../../../../utils/table";
import {creditInvoicesService} from "../../../../../services/documents/invoices/credit.invoices.service";
import {nonStrictComparator} from "../../../../ui/table/filter-dropdown/filter-dropdown.comparator";
import {FilterDropdown} from "../../../../ui/table/filter-dropdown/filter-dropdown";

export interface CreditInvoiceDataDetailsProps {
    document: CreditInvoice;
    reload: () => void;
}

export const CreditInvoiceDataDetails: React.FC<CreditInvoiceDataDetailsProps> = observer(({document, reload}) => {
    const t = useTranslate();

    const selectEditValues = (item: CreditInvoiceItem) => {
        return { qty: item.qty };
    };

    const onSave = async (id: number, item: any) => {
        await creditInvoicesService.setQty(document.id, id, parseInt(item.qty));
        reload();
    }

    const onDelete = async (item: CreditInvoiceItem) => {
        await creditInvoicesService.removeItem(document.id, item.id);
        reload();
    }

    const onEdit = (item: CreditInvoiceItem) => {
        return Promise.resolve({qty: item.qty});
    }

    const columns: ExpandedTableColumnsType<CreditInvoiceItem> = [
        {
            title: t("INVOICES.NUMBER"),
            dataIndex: "invoiceDocumentItem",
            key: "invoiceDocumentItem",
            width: 120,
            render: (item, i) => item ? <Link to={"/invoices/" + item.document.id}>{item.document.docNumber}</Link> : i.invoiceDocumentNumber,
            sorter: (a, b) => a.invoiceDocumentNumber > b.invoiceDocumentNumber ? 1 : -1,
            onFilter: nonStrictComparator(v => v.invoiceDocumentNumber),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("ORDERS.ORDER.ID"),
            dataIndex: "referenceOrderId",
            key: "referenceOrderId",
            width: 120,
            render: item => item ? <Link to={"/orders/" + item}>{item}</Link> : '-',
            sorter: (a, b) => a.referenceOrderId > b.referenceOrderId ? 1 : -1,
            onFilter: nonStrictComparator(v => v.referenceOrderId),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "articleName",
            key: "articleName",
            width: "135px",
            render: (article: string) => article.toUpperCase() !== "DELIVERY" ? (
                <Link to={"/articles/" + article} style={{ whiteSpace: "nowrap" }}>
                    {article}
                </Link>
            ) : t("DELIVERY.TITLE"),
            sorter: (a, b) => a.articleName > b.articleName ? 1 : -1,
            onFilter: nonStrictComparator(v => v.articleName),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "description",
            width: "250px",
        },
        {
            title: t("PRICE"),
            dataIndex: "price",
            key: "price",
            width: "167px",
            render: (item) => (item ? <>{displayPrice(item)}</> : null),
            sorter: (a, b) => a.price.value > b.price.value ? 1 : -1,
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "qty",
            width: "100px",
            editable: true,
            sorter: (a, b) => a.qty > b.qty ? 1 : -1,
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "totalPrice",
            key: "totalPrice",
            width: "140px",
            render: (item) => (item ? <>{displayPrice(item)}</> : null),
            sorter: (a, b) => a.totalPrice.value > b.totalPrice.value ? 1 : -1,
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            width: "250px"
        }
    ];

    return (
        <ExpandedTable<CreditInvoiceItem>
            isEditable={document.status === "opened" || document.status === "reopened"}
            editableRowKey="qty"
            getEditValues={selectEditValues}
            getSaveValues={selectEditValues}
            onSave={onSave}
            onDelete={onDelete}
            onEdit={onEdit}
            title="DETAILS"
            pageKey="credit_invoice_data_order_detail"
            columns={columns}
            columnsConfig={convertTableColumnsToColumnConfigs(columns)}
            tableData={document.items}
            tableLoading={false}
            tableFooter={<TableFooter data={generateDocumentDetails(document)} />}
        />
    );
});
