import React, {useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {AgentDataStoreContext} from "../agent.data.store";
import {Checkbox, Col, Form, FormInstance, Input, Modal, Row, Tooltip, Typography} from "antd";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {AgentDataAccountFormValues} from "../../../../models/agents/agent-data/agent-data-accounts/agent.data.account.form.values";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {observer} from "mobx-react";
import {SystemAccesses} from "../../../users/users/system-accesses/system.accesses";
import {CustomerAccountAccess} from "../../../../models/accounts/customer-account/access/customer.account.access";
import {generatePassword} from "../../../../utils/helpers";

interface AgentDataAccountsModalProps {
    visible: boolean,
    access?: CustomerAccountAccess,
    onSubmit: (accountData: AgentDataAccountFormValues) => Promise<any>,
    onClose: () => void
}

export const setGeneratedPassword = (form: FormInstance, fieldName: string | string[] = "password") => {
    const generatedPassword = generatePassword();
    form.setFields([{"name": fieldName, "value": generatedPassword}]);
};

export const AgentDataAccountsModal: React.FC<AgentDataAccountsModalProps> = observer(({
                                                                                           visible,
                                                                                           access,
                                                                                           onSubmit,
                                                                                           onClose
                                                                                       }) => {
    const t = useTranslate();
    const store = useRequiredStore(AgentDataStoreContext);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    let defaultValues: any;

    if (access) {
        defaultValues = {
            ...access.user,
            sendPassword: true,
            systemAccesses: access.systemAccesses.map(item => ({system: item.system.id, group: item.group.id}))
        };
        // defaultValues.group = access.group.id;
    } else {
        defaultValues = {sendPassword: true, systemAccesses: []};
    }

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={access ? t("ACCOUNTS.EDIT") : t("ACCOUNTS.ADD")}
            okText={access ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"accounts_form"}
                initialValues={defaultValues}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        if (access) {
                            values.id = access.user.id;
                        }
                        onSubmit({
                            ...values,
                            password: values.password ? values.password.trim() : "",
                            sendPassword: values.sendPassword === undefined ? false : values.sendPassword,
                        })
                            .then(
                                () => {
                                    form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                            .then(() => {
                            }, (e) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            name="firstName"
                            label={t("FIRSTNAME")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("FIRSTNAME")}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name={"lastName"}
                            label={t("LASTNAME")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Input disabled={loading} placeholder={t("LASTNAME")} type={"text"}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"email"}
                            label={t("EMAIL")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Input disabled={loading} placeholder={t("EMAIL")} type={"text"}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"password"}
                            label={t("PASSWORD.TITLE")}
                        >
                            <Input.Password
                                disabled={loading}
                                placeholder={t("PASSWORD.EDIT")}
                                allowClear
                                addonAfter={
                                    <Tooltip title={t("PASSWORD.GENERATE")}>
                                        <FontAwesomeIcon onClick={() => setGeneratedPassword(form)}
                                                         style={{cursor: "pointer"}} icon={["fas", "dice"]}/>
                                    </Tooltip>
                                }
                                iconRender={visible => (
                                    visible ? (
                                        <Tooltip title={t("PASSWORD.HIDE")}>
                                            <FontAwesomeIcon style={{marginLeft: "3px"}} icon={["fas", "eye-slash"]}/>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title={t("PASSWORD.SHOW")}>
                                            <FontAwesomeIcon style={{marginLeft: "3px"}} icon={["fas", "eye"]}/>
                                        </Tooltip>
                                    )
                                )}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item noStyle
                                   shouldUpdate={(prevValues, nextValues) => prevValues.password !== nextValues.password}>
                            {({getFieldValue}) => getFieldValue("password") ? (
                                <Form.Item name={"sendPassword"} valuePropName={"checked"}>
                                    <Checkbox disabled={loading}>{t("PASSWORD.SEND_PASSWORD")}</Checkbox>
                                </Form.Item>
                            ) : null}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Typography.Title level={5}>{t("USER.PROFILE.SYSTEM_ACCESSES")}</Typography.Title>
                    </Col>
                    <Col span={24}>
                        <SystemAccesses
                            loading={loading}
                            form={form}
                            type={store.account!.type}
                            defaultSelectedSystems={access ? access!.systemAccesses.map(item => item.system.id) : []}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
});
