import {Alert, Space, Typography} from "antd";
import React, {useEffect, useMemo, useRef} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import FilteredTable from "../../../../common/filtered-table/filtered.table";
import {Order} from "../../../../../models/orders/orders-list/order";
import {ActiveOrdersFilterButton, OrdersListStoreContext} from "../orders.list.store";
import {OrdersListDeliveryOrderModal} from "../../delivery-order-modal/orders.list.delivery.order.modal";
import {OrderListSummaryModal} from "../../summary-modal/orders.list.summary.modal";
import {OrderWarnings} from "../../../order-data/order-data-warnings/order.data.warnings";
import {ColumnsType} from "antd/es/table";
import {columnsFactory} from "../orders.list.columns";
import i18n from "i18next";
import {FieldGroupConfig} from "../../../../../models/filter/filter.group";
import {FieldTypeName} from "../../../../../models/filter/filter.field.type.name";
import {useRequiredStore} from "../../../../../utils/store";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {isAllowed} from "../../../../../utils/helpers";
import {convertTableColumnsToColumnConfigs} from "../../../../../utils/table";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {observer} from "mobx-react";

export const OrdersListBasic: React.FC<ComponentPropsFromRoute> = observer(({name}) => {

    const t = useTranslate();

    const {
        commonStore: { warehouses, orderStatuses },
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);
    const titleWrapperRef = useRef<HTMLDivElement>(null);
    const store = useRequiredStore(OrdersListStoreContext);

    const ref = useRef();

    const columns: ColumnsType<Order> = useMemo(() => columnsFactory(store, ref, t), [i18n.language, store, ref]);

    const allowedActions = store.actions.filter((action) => isAllowed(attributes, action.access));

    useEffect(() => {
        const closePopupHandler = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (target.id !== "popover-wrapper") {
                store.closeAgreementsModal();
                store.closeCommentsModal();
            }
        };

        document.addEventListener("mousedown", closePopupHandler);

        return () => {
            document.removeEventListener("mousedown", closePopupHandler);
        };
    }, []);


    const filterGroups: FieldGroupConfig[] = [
        {
            name: "id",
            visibleName: "ID",
            fields: [
                {
                    name: "id",
                    type: FieldTypeName.INTEGER,
                },
            ],
        },
        {
            name: "type",
            visibleName: "ORDER.TYPE",
            fields: [
                {
                    name: "type",
                    type: FieldTypeName.SELECT,
                    multiple: false,
                    choices: [
                        { name: 'Regular',   value: 'regular' },
                        { name: 'Pre-Order', value: 'reservation' },
                        { name: 'Backorder', value: 'backorder' }
                    ]
                },
            ],
        },
        {
            name: "agentName",
            visibleName: "CLIENT.NAME",
            fields: [
                {
                    name: "agentName",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "account",
            visibleName: "CLIENT.ACCOUNT.ID",
            fields: [
                {
                    name: "account",
                    type: FieldTypeName.INTEGER,
                },
            ],
        },
        {
            name: "agentGroups",
            visibleName: t("CLIENT.GROUP"),
            fields: [
                {
                    name: "agentGroups",
                    type: FieldTypeName.SELECT,
                    multiple: true,
                    choices: [
                        { value: "wholesale", name: "Wholesale" },
                        { value: "retail", name: "Retail" }
                    ]
                }
            ],
        },
        {
            name: "trackingNumber",
            visibleName: "SHIPMENTS.PARCELS.TRACK_CODE",
            fields: [
                {
                    name: "trackingNumber",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "warehouses",
            visibleName: "WAREHOUSES.ID",
            fields: [
                {
                    name: "warehouses",
                    type: FieldTypeName.SELECT,
                    multiple: true,
                    choices: warehouses.map(({ name, id }) => ({ value: id, name })),
                },
            ],
        },
        {
            name: "status",
            visibleName: "ORDERS.STATUS.TITLE",
            fields: [
                {
                    name: "status",
                    type: FieldTypeName.SELECT,
                    multiple: true,
                    choices: orderStatuses.map((status) => ({
                        value: status,
                        name: t("ORDERS.ORDER.STATUS." + status.toUpperCase()),
                    })),
                },
            ],
        },
        {
            name: "grandTotal",
            visibleName: "TOTAL_AMOUNT",
            fields: [
                {
                    name: "grandTotal",
                    type: FieldTypeName.INTEGER,
                },
            ],
        },
        {
            name: "clientOrderId",
            visibleName: "ORDERS.ALT_NUMBER",
            fields: [
                {
                    name: "clientOrderId",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "createdAt",
            visibleName: "CREATED_AT",
            fields: [
                {
                    name: "createdAt",
                    type: FieldTypeName.DATE_RANGE,
                },
            ],
        },
    ];

    const rowSelection = {
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys: React.Key[], selectedRows: Order[]) => {
            store.checkOrders(selectedRowKeys);
        },
    };

    return (
        <Space direction="vertical" size={[0, 0]}>
                <div ref={titleWrapperRef}>
                    <Space direction="vertical" size={[0, 0]}>
                        <Typography.Title level={3}>{t("ORDERS.LIST")}</Typography.Title>
                        {allowedActions.length > 0 ? (
                            <Alert
                                message={t("ORDERS.PROFORMA.LENGTH") + ": " + store.checkedOrders.length}
                                type="info"
                                style={{ marginBottom: 20 }}
                            />
                        ) : null}
                        <FilteredTable<Order, ActiveOrdersFilterButton>
                            rowKey="id"
                            getRequest={store.setRequest}
                            setFilterSnapshotsSetter={store.setSnapshotsSetter}
                            columns={columns}
                            columnsConfigs={{ columns: convertTableColumnsToColumnConfigs(columns), pageKey: name }}
                            filterGroups={filterGroups}
                            dataHandler={store.dataHandler}
                            filterButtons={store.filterButtonConfigs}
                            rowSelection={
                                allowedActions.length > 0
                                    ? {
                                        type: "checkbox",
                                        selectedRowKeys: [...store.checkedOrders],
                                        ...rowSelection,
                                    }
                                    : undefined
                            }
                        />
                    </Space>
                </div>
                <OrdersListDeliveryOrderModal
                    checkedOrders={store.checkedOrders as number[]}
                    visible={store.deliveryOrderModalShown}
                    onOk={store.handleDeliveryModalSubmit}
                    onClose={store.closeDeliveryOrderModal}
                />
                {store.summaryModalShown ? (
                    <OrderListSummaryModal
                        checkedOrders={store.checkedOrders as number[]}
                        onClose={() => store.setSummaryModalShown(false)}
                    />
                ) : null}
                {store.warnings !== null ? (
                    <OrderWarnings
                        visible={store.warningsModalShown}
                        warnings={store.warnings}
                        onCancel={store.closeWarningsModal}
                    />
                ) : null}
        </Space>
    )
})