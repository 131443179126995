import React from "react";
import {WidgetProps} from "./widget";
import {Input} from "antd";
import {useTranslate} from "../../../../../../hooks/translate.hook";
export const StringWidget: React.FC<WidgetProps> = ({onChange, value}) => {

    const t = useTranslate();

    return (
        <Input
            placeholder={t("FILTER.VALUE")}
            onChange={v => onChange([v.target.value])}
            value={value[0]}
        />
    )
}