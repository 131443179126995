import { observer } from "mobx-react";
import React, { RefObject, useRef } from "react";
import {
    ProgressBarItem,
    ProgressBarPoint,
} from "../../../models/progress-bar/progress-bar";
import "./progress-bar.scss";
import { useCanvas } from "./use-canvas";

export const easeOutQuart = (x: number): number => {
    return 1 - Math.pow(1 - x, 4);
};

interface ProgressBarProps {
    points: ProgressBarPoint[];
    bars: ProgressBarItem[];
    coefficientToMaxValue: number;
}
export const ProgressBar: React.FC<ProgressBarProps> = observer(
    ({ points, bars, coefficientToMaxValue = 1.2 }) => {
        const containerRef = useRef(null) as RefObject<HTMLDivElement>;

        const maxValue =
            Math.max(
                ...bars.map((item) => item.value),
                ...points.map((item) => item.value)
            ) * coefficientToMaxValue;

        const progressXStart = 4;
        const progressLineHeight = 8; // height one of lines
        const progressLinePadding = 3;
        const labelLineWidth = 4;
        const labelLineBaseHeight = 10;
        const labelPadding = 10;
        const labelTextHeight = 10;
        const textFontMinSize = 8;

        const drawLabels = (
            ctx: CanvasRenderingContext2D,
            frameCount: number
        ): void => {
            const valueToCanvasWidthRatio = maxValue / ctx.canvas.clientWidth;

            const labels: { x: number; label: string }[] = points.map(
                ({ value, label }): { x: number; label: string } => {
                    return {
                        x: value / valueToCanvasWidthRatio,
                        label: label,
                    };
                }
            );

            const textFontSize = Math.max(
                Math.ceil(ctx.canvas.clientWidth / 100),
                textFontMinSize
            )

            // print labels
            labels.forEach(({ x, label }, idx) => {

                ctx.beginPath();
                ctx.moveTo(x, labelTextHeight + labelPadding);
                ctx.lineTo(x, labelLineBaseHeight + labelTextHeight + labelPadding + (bars.length * progressLineHeight) + ((bars.length -1) * progressLinePadding)
                );
                ctx.lineWidth = labelLineWidth;
                ctx.strokeStyle =
                    "rgba(0, 0, 0, " + Math.min(0.2, frameCount / 100) + ")";
                ctx.lineCap = "round";
                // ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
                ctx.font = textFontSize + "px Arial";
                ctx.textAlign = "center";

                ctx.fillText(
                    label,
                    x,
                    idx % 2
                        ? labelTextHeight // above
                        : labelLineBaseHeight + labelTextHeight * 2 + labelPadding * 2 + (bars.length * progressLineHeight) + ((bars.length -1) * progressLinePadding) // under
                );

                ctx.stroke();
            });
        };

        const drawBars = (
            ctx: CanvasRenderingContext2D,
            frameCount: number
        ): void => {

            let barYBaseLine = (labelLineBaseHeight / 2) + (progressLineHeight / 2 ) + labelTextHeight + labelPadding;

            // print background bars
            bars.forEach((_, index) => {
                ctx.beginPath();
                // ctx.globalCompositeOperation = "destination-over";
                ctx.moveTo(
                    progressXStart,
                    barYBaseLine + (index * (progressLineHeight + (index === 0 ? 0 : progressLinePadding)))
                );
                ctx.lineTo(
                    ctx.canvas.clientWidth - 4,
                    barYBaseLine + (index * (progressLineHeight + (index === 0 ? 0 : progressLinePadding)))
                );
                ctx.lineWidth = progressLineHeight;

                ctx.strokeStyle = "#F2F2F2";
                ctx.lineCap = "round";
                ctx.stroke();
            });

            bars.forEach((item, index) => {
                ctx.beginPath();
                // ctx.globalCompositeOperation = "destination-over";
                ctx.moveTo(
                    progressXStart,
                    barYBaseLine + (index * (progressLineHeight + (index === 0 ? 0 : progressLinePadding)))
                );
                ctx.lineTo(
                    Math.min(
                        (item.value * ctx.canvas.clientWidth) / maxValue,
                        ((item.value * ctx.canvas.clientWidth) / maxValue) *
                            (frameCount / 50)
                    ),
                    // (item.amount * ctx.canvas.clientWidth) / maxAmount,
                    barYBaseLine + (index * (progressLineHeight + (index === 0 ? 0 : progressLinePadding)))
                );
                ctx.lineWidth = progressLineHeight;
                ctx.strokeStyle = item.color;
                ctx.lineCap = "round";
                ctx.stroke();
            });
            //
            // // draw hints
            // bars.forEach((item, index) => {
            //     if (item.hint) {
            //         ctx.beginPath();
            //         ctx.moveTo(
            //             progressXStart - 2,
            //             // progressYStart * (index + 1) + paddingTop
            //             hintYStart + (index > 0 ? 20 : 0)
            //         );
            //         ctx.lineTo(50, hintYStart + (index > 0 ? 20 : 0));
            //         ctx.lineWidth = 4;
            //         ctx.strokeStyle = item.color;
            //         ctx.lineCap = "round";
            //         ctx.font =
            //             Math.max(
            //                 Math.ceil(ctx.canvas.clientWidth / 65),
            //                 textFontMinSize
            //             ) + "px Arial";
            //         ctx.fillText(
            //             item.hint,
            //             60,
            //             hintYStart + (index > 0 ? 20 : 0) + textFontMinSize / 2
            //         );
            //         ctx.stroke();
            //     }
            // });
        };

        const canvasRef = useCanvas({
            renderFunctions: [drawBars, drawLabels],
            animationCountStop: 250,
        });

        let dpr = window.devicePixelRatio || 1;

        return (
            <div ref={containerRef} className="progress-bar-wrapper">
                <canvas
                     height={
                         dpr * (labelLineBaseHeight + labelTextHeight * 2 + labelPadding * 2 + (bars.length * progressLineHeight) + ((bars.length - 1) * progressLinePadding))
                     }
                     className="progress-bar"
                     ref={canvasRef}
                />
            </div>
        );
    }
);
