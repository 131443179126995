import React, {useMemo, useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {UserDataFMSCart} from "../user-data-card/user.data.fms.card";
import {Col, Form, Modal, Row, Select} from "antd";
import {createAccountRequest} from "../../../../../models/users/user/user.data.helper";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../../models/core/response";
import {useRequiredStore} from "../../../../../utils/store";
import {UserDataStoreContext} from "../user.data.store";
import {observer} from "mobx-react";
import {ExtendedUser} from "../../../../../models/users/user/extended.user";

interface UserDataCreateFormProps {
    user: ExtendedUser;
    visible: boolean,
    onClose: () => void,
}

export const UserDataCreateModal: React.FC<UserDataCreateFormProps> = observer(({visible, onClose, user}) => {
    const t = useTranslate();
    const store = useRequiredStore(UserDataStoreContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const SelectedSystemOptions: any = {
        employee: UserDataFMSCart
    }


    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={t("USER.PROFILE.ADD")}
            okText={t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            width={700}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                size={"middle"}
                form={form}
                layout="vertical"
                name={"create_form"}
                initialValues={{systemAccesses: []}}
                onFinish={() => form
                    .validateFields()
                    .then((values) => {
                        setLoading(true);
                        store.createAccount(createAccountRequest(values.type)(values), values.type)
                            .then(
                                () => {
                                    form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    setLoading(false);
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                            .then(() =>
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row justify={"space-between"} gutter={[20, 0]} style={{width: "100%"}} align={"top"}>
                    <Col xxl={24} xl={24} md={24} sm={24} xs={24}>
                        <Col xxl={24} xl={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name={"type"}
                                label={t("TYPE")}
                                rules={[{
                                    required: true,
                                    message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                                }]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp={"children"}
                                    disabled={loading}
                                    placeholder={t("TYPE")}
                                >
                                    {store.types.map(type => (
                                        <Select.Option
                                            key={type}
                                            value={type}
                                        >
                                            {type.beautify()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Col>
                    <Form.Item
                        style={{width: "100%"}}
                        noStyle
                        shouldUpdate={(prevValue, curValue) => prevValue.type !== curValue.type}
                    >
                        {({getFieldValue}) => {
                            const type = getFieldValue("type");
                            const Options = SelectedSystemOptions[type];
                            return type ? <Options defaultSelectedSystems={[]} form={form} disabled={loading} type={type} user={user} /> : null
                        }}
                    </Form.Item>
                </Row>
            </Form>
        </Modal>
    );
});
