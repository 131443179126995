import React, {PropsWithChildren, useEffect, useState} from "react";
import {unified} from 'unified'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'

import {VFile} from "vfile";
import {Node} from "./node";
import Title from "antd/es/typography/Title";

interface MarkdownHeadersProps {
    value: string;
}

const parse = async (value: string) => {
    const processor = await unified()
        .use(remarkParse)
        .use(remarkRehype);

    const file = new VFile()
    file.value = value;

    const result = processor.parse(file)

    const rootNode = new Node('Root');

    let currentNode = rootNode;
    let depth = 0;

    if (result.type === 'root') {

        for (const nodeKey in result.children) {
            const node = result.children[nodeKey]
            if (node.type === 'heading') {

                let parentNode = currentNode;
                if (node.depth <= depth) {
                    for (let i = -1; i < depth - node.depth; i++) {
                        if (!parentNode.parent) {
                            break;
                        }
                        parentNode = parentNode.parent;
                    }
                }

                const value = node.children
                    .filter(item => item.type === 'text')
                    .map((item: any) => item.value || "?").join(" ");

                const newNode = new Node(value, parentNode);

                parentNode.addChild(newNode);

                depth = node.depth;
                currentNode = newNode;
            }
        }
    }

    return rootNode;
}

const GenerateTree = (node: Node): React.ReactElement | null => {

    if (node.children.length === 0) {
        return null;
    }

    return <ul>
            {node.children.map(childNode => (
                <React.Fragment key={childNode.value}>
                    <li>{childNode.value}</li>
                    {GenerateTree(childNode)}
                </React.Fragment>
            ))}
    </ul>
}

export const MarkdownHeaders: React.FC<PropsWithChildren<MarkdownHeadersProps>> = ({value}) => {

    const [rootNode, setRootNode] = useState<Node>();

    useEffect(() => {
        parse(value).then(node => setRootNode(node));
    }, [value])

    if (!rootNode || rootNode.children.length === 0) {
        return <i>content will be generated after add at least one header</i>;
    }

    return (<>
        {GenerateTree(rootNode)}
    </>)
}
