import {AxiosInstance} from "axios";
import API from "../../utils/api";

export class StatusesService {
    api: AxiosInstance = API;

    //TODO Захардкоженные значения
    async getStatuses(): Promise<string[]> {
        return ["active", "inactive", "potential", "suspended_shipment"];
    }
}

export const statusesService = new StatusesService();