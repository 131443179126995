import {AxiosInstance} from "axios";
import API, {BlobAndFilename, getBlobAndFileName} from "../../utils/api";
import {PaginatedResult} from "../../models/core/paginated.result";
import {Declaration} from "../../models/declarations/declaration";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {
    DeclarationSave,
    DeclarationUpload, UploadedDeclaration,
    UploadedDeclarationResponse
} from "../../components/declarations/upload/declaration.upload.model";

export class DeclarationsService {
    api: AxiosInstance = API;

    getByNumber(number: string): Promise<UploadedDeclaration> {
        return this.api.get<UploadedDeclaration>('declarations/' + number).then(x => x.data);
    }

    getAll(request: PaginatedRequest): Promise<PaginatedResult<Declaration>> {
        return this.api.get<PaginatedResult<Declaration>>(
            'declarations',
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }

    uploadDeclaration(dto: DeclarationUpload): Promise<UploadedDeclarationResponse> {
        const formData = new FormData();
        Object.keys(dto).forEach(key => {
            // @ts-ignore
            let value = dto[key];
            // @ts-ignore
            if (typeof dto[key] === 'boolean') {
                // @ts-ignore
                value = dto[key] ? 1 : 0;
            }
            formData.append(key, value);
        })

        return this.api
            .post('declarations/upload', formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            })
            .then(x => x.data);
    }

    saveDeclaration(dto: DeclarationSave): Promise<UploadedDeclaration> {
        return this.api
            .post('declarations/save', dto)
            .then(x => x.data);
    }

    downloadAsXML(number: string, maxDocuments: number): Promise<BlobAndFilename> {
        return this.api.get("declarations/" + number + "/xml", {responseType: "blob", params: {maxDocuments}}).then(getBlobAndFileName);
    }

    downloadSourceCSV(number: string): Promise<BlobAndFilename> {
        return this.api.get("declarations/" + number + "/csv", {responseType: "blob"}).then(getBlobAndFileName);
    }

    downloadAllAsXML(numbers: number[], maxDocuments: number): Promise<BlobAndFilename> {
        return this.api.get("declarations/all-xml", {responseType: "blob", params: {decl: numbers, maxDocuments}}).then(getBlobAndFileName);
    }

    downloadAllSourceCSV(numbers: number[]): Promise<BlobAndFilename> {
        return this.api.get("declarations/all-csv", {responseType: "blob", params: {decl: numbers}}).then(getBlobAndFileName);
    }

    downloadAllJSON(numbers: number[]): Promise<BlobAndFilename> {
        return this.api.get("declarations/all-json", {responseType: "blob", params: {decl: numbers}}).then(getBlobAndFileName);
    }

    downloadCompareTable(number: string): Promise<BlobAndFilename> {
        return this.api.get("declarations/" + number + "/check-table", {responseType: "blob"}).then(getBlobAndFileName);
    }

    cancel(number: string): Promise<any> {
        return this.api.post("declarations/" + number + "/cancel");
    }

    handleXML(number: string, file: File): Promise<any> {
        const formData = new FormData();
        formData.append("file", file);

        return this.api
            .post("declarations/" + number + "/handle-xml", formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            })
            .then(x => x.data);
    }
}

export const declarationService = new DeclarationsService();
