import {ColumnsType} from "antd/es/table";
import {Link} from "react-router-dom";
import {Amount} from "../../../../../models/prices/price";
import {displayPrice} from "../../../../../models/prices/price.helper";
import Date from "../../../../date/date";
import {Button, Space, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Translate} from "../../../../../hooks/translate.hook";
import {Proforma} from "../../../../../models/documents/proformas/proforma";

export const columnsFactory = (t: Translate): ColumnsType<Proforma> => {
    return [
        {
            title: t("INVOICES.NUMBER"),
            dataIndex: "docNumber",
            key: "docNumber",
            width: "180px",
            render: (number: string, item: Proforma) => <Link to={"/proformas/" + item.id}>{number}</Link>,
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "grandTotal",
            key: "grandTotal",
            render: (amount: Amount) => (
                <span style={{ fontWeight: "bold" }}>
                        {displayPrice(amount)}
                    </span>
            ),
        },
        {
            title: t("TOTAL_PRICE"),
            dataIndex: "subtotal",
            key: "subtotal",
            render: (amount: Amount) => (
                <span style={{ fontWeight: "bold" }}>
                        {displayPrice(amount)}
                    </span>
            ),
        },
        {
            title: "VAT",
            dataIndex: "subtotalVat",
            key: "subtotalVat",
            render: (amount: Amount) => (
                <span style={{ fontWeight: "bold" }}>
                        {displayPrice(amount)}
                    </span>
            ),
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date: string) => <Date dateString={date} showTime />,
        },
        {
            title: t("ACTION"),
            key: "action",
            className: "table-button-cell",
            width: 50,
            render: (_: any, item) => (
                <Space direction={"horizontal"}>
                    <Tooltip placement="topRight" title={t("DOWNLOAD.DO_DOWNLOAD")}>
                        <a href={"/documents/" + item.id} target={"_blank"}>
                            <Button
                                className="table-btn btn-remove btn"
                                type="default"
                                icon={
                                    <FontAwesomeIcon
                                        icon={["fas", "file-pdf"]}
                                    />
                                }
                            />
                        </a>
                    </Tooltip>
                </Space>
            ),
        },
    ];
}