import React, {useMemo, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Button, Space} from "antd";
import {SmartTable} from "../../../../common/smart-table/smart-table";
import {SmartTableDataHandler} from "../../../../common/smart-table/smart-table.data-handler";
import {columnsFactory} from "../../../../calendar/events/calendar.events.columns";
import {PurchasingAlliancesModal} from "../modal/purchasing-alliances.modal";
import {purchasingAlliancesService} from "../../../../../services/settings/purchasing-alliances.service";
import {PurchasingAlliance} from "../../../../../models/settings/purchasing-alliances";
import {notifications} from "../../../../../services/notifications/notifications";

interface PurchasingAlliancesProps extends ComponentPropsFromRoute {}

export const PurchasingAlliances: React.FC<PurchasingAlliancesProps> = ({requiredConfigs}) => {

    const type: string = requiredConfigs?.type;

    const t = useTranslate();
    const [editModalShown, setEditModalShown] = useState<boolean>(false);
    const [editableItem, setEditableItem] = useState<PurchasingAlliance | null>(null);

    const columns = useMemo(() => columnsFactory(t), [t])
    const handler = useMemo(() => new SmartTableDataHandler(r => {
        r.addFilter('type', type);
        return purchasingAlliancesService.getList(r)
    }), [type]);

    const onRemove = async (alliance: PurchasingAlliance) => {
        await purchasingAlliancesService.remove(alliance.id)
        await handler.reloadItemsWithLastRequest()
        notifications.successfully()
    }

    return <Space direction="vertical" size={[10, 10]}>
        <Button type={"primary"} onClick={() => {
            setEditableItem(null);
            setEditModalShown(true)
        }}>{t('CREATE')}</Button>
        <SmartTable
            handler={handler}
            columns={columns}
            actions={[
                {
                    icon: 'edit',
                    title: t('EDIT'),
                    onClick: (item) => {
                        setEditableItem(item);
                        setEditModalShown(true);
                    }
                },
                {
                    icon: 'remove',
                    title: t('REMOVE'),
                    onClick: onRemove
                }
            ]}
        />
        {editModalShown ? (
            <PurchasingAlliancesModal
                alliance={editableItem}
                type={type}
                onCancel={() => setEditModalShown(false)}
                onComplete={() => handler.reloadItemsWithLastRequest()}
            />
        ) : null}
    </Space>
}