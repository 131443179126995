import React from "react";
import {WidgetProps, WidgetPropValue} from "../../../../common/ui/input/widget/widget";
import {ReportConfigResolvableField, ReportConfigResolvableFieldType} from "../../../../../models/reports/reports";
import {UIInputWidgetArticle} from "../../../../common/ui/input/widget/article/ui.input.widget.article";
import {UIInputWidgetYear} from "../../../../common/ui/input/widget/year/ui.input.widget.year";
import {UIInputWidgetWarehouse} from "../../../../common/ui/input/widget/warehouse/ui.input.widget.warehouse";
import {UIInputWidgetMonth} from "../../../../common/ui/input/widget/month/ui.input.widget.month";
import {UiInputWidgetDateRange} from "../../../../common/ui/input/widget/date-range/ui.input.widget.date-range";
import {UIInputWidgetCountry} from "../../../../common/ui/input/widget/country/ui.input.widget.country";
import {UIInputWidgetClient} from "../../../../common/ui/input/widget/client/ui.input.widget.client";
import dayjs from "dayjs";
import {UIInputWidgetPrice} from "../../../../common/ui/input/widget/price/ui.input.widget.price";

export interface ResolvableFieldConfig {
    component: React.FC<WidgetProps>;
    transform: (field: ReportConfigResolvableField, value: WidgetPropValue) => Record<string, any>;
    reverseTransform: (field: ReportConfigResolvableField, values: Record<string, any>) => WidgetPropValue;
    export: (value: any) => any;
    import: (value: any) => any;
    getDefaults: (field: ReportConfigResolvableField) => Record<string, any>;
}

export const config: Record<ReportConfigResolvableFieldType, ResolvableFieldConfig> = {
    article: {
        component: UIInputWidgetArticle,
        transform: (f, v) => ({[f.fields[0]]: v}),
        reverseTransform: (f, v) => (v[f.fields[0]]),
        export: v => v,
        import: v => v,
        getDefaults: f => ({[f.fields[0]]: ""})
    },
    account: {
        component: UIInputWidgetClient,
        transform: (f, v) => ({[f.fields[0]]: v}),
        reverseTransform: (f, v) => (v[f.fields[0]]),
        export: v => v,
        import: v => v,
        getDefaults: f => ({[f.fields[0]]: ""})
    },
    price: {
        component: UIInputWidgetPrice,
        transform: (f, v) => ({[f.fields[0]]: v}),
        reverseTransform: (f, v) => (v[f.fields[0]]),
        export: v => v,
        import: v => v,
        getDefaults: f => ({[f.fields[0]]: ""})
    },
    country: {
        component: UIInputWidgetCountry,
        transform: (f, v) => {
            return {[f.fields[0]]: v}
        },
        reverseTransform: (f, v) => {
            return v[f.fields[0]]
        },
        export: v => {
            return v;
        },
        import: v => {
            let x = Array.isArray(v) ? v : [v];
            return x;
        },
        getDefaults: f => ({[f.fields[0]]: ""})
    },
    warehouse: {
        component: UIInputWidgetWarehouse,
        transform: (f, v) => ({[f.fields[0]]: v}),
        reverseTransform: (f, v) => (v[f.fields[0]]),
        export: v => v,
        import: v => Array.isArray(v) ? v : [v],
        getDefaults: f => {
            let value: any;
            if (f.multiple) {
                value = f.defaultOptions;
            } else {
                value = f.defaultOptions[0];
            }
            return {
                [f.fields[0]]: value
            }
        }
    },
    month: {
        component: UIInputWidgetMonth,
        transform: (f, v) => ({[f.fields[0]]: v}),
        reverseTransform: (f, v) => (v[f.fields[0]]),
        export: v => v.format('YYYY-MM-DD'),
        import: v => dayjs(v),
        getDefaults: f => {
            const date = dayjs();
            if (f.defaultOptions[0]) {
                date.add(f.defaultOptions[0], 'months');
            }
            return {
                [f.fields[0]]: date
            }
        }
    },
    year: {
        component: UIInputWidgetYear,
        transform: (f, v) => ({[f.fields[0]]: v}),
        reverseTransform: (f, v) => (v[f.fields[0]]),
        export: v => v,
        import: v => v,
        getDefaults: f => {
            const date = dayjs();
            if (f.defaultOptions[0]) {
                date.add(f.defaultOptions[0], 'years');
            }
            return {
                [f.fields[0]]: date.format('YYYY')
            }
        }
    },
    'date-range': {
        component: UiInputWidgetDateRange,
        transform: (f, v: any) => ({[f.fields[0]]: v[0], [f.fields[1]]: v[1]}),
        reverseTransform: (f, v) => ([v[f.fields[0]], v[f.fields[1]]]),
        export: v => v.format('YYYY-MM-DD'),
        import: v => dayjs(v),
        getDefaults: f => {
            let left = dayjs();
            let right = dayjs();
            if (f.defaultOptions[0]) {
                left = left
                    .add(f.defaultOptions[0], f.defaultOptions[1])
                    .startOf(f.defaultOptions[1]);
            }
            if (f.defaultOptions[2]) {
                right = right
                    .add(f.defaultOptions[2], f.defaultOptions[3])
                    .endOf(f.defaultOptions[3]);
            }
            return {
                [f.fields[0]]: left,
                [f.fields[1]]: right,
            }
        }
    }
}