import { Form, FormInstance, Input, Select } from "antd";
import React, { RefObject, useEffect } from "react";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import { filterRuleMap } from "../../../../../../models/filter/filter.field.rule";
import { FieldChangeCallback, fieldTypes } from "../../../../../../models/filter/filter.field.type.config";
import { FieldConfig, FieldConfigChoices } from "../../../../../../models/filter/filter.group";

interface SelectedFilterProps {
    form: FormInstance;
    field: FieldConfig;
    index: number;
    choices: FieldConfigChoices;
    popupRef: RefObject<HTMLDivElement>;
    onChange: FieldChangeCallback;
}
export const SelectedFilter: React.FC<SelectedFilterProps> = ({ field, form, index, choices, popupRef, onChange }) => {
    const t = useTranslate();
    const fieldType = fieldTypes[field.type];

    useEffect(() => {
        const rule = form.getFieldValue(["fields", field.name, "rule"]);
        form.setFieldsValue({ fields: { [field.name]: { rule: rule || fieldTypes[field.type].defaultRule } } });
    }, [field]);

    if (fieldType.supportedRules.length === 0) {
        return fieldTypes[field.type].render(popupRef, field, index, onChange, choices);
    }

    return (
        <Form.Item className={"filterValueWithQueryTypeContainer"}>
            <Input.Group compact>
                <Form.Item
                    noStyle
                    name={["fields", field.name, "rule"]}
                    rules={[
                        {
                            required: false,
                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                        },
                    ]}
                >
                    <Select
                        placeholder={t("FILTER.SELECT_FIELD")}
                        style={{ width: "33%" }}
                        getPopupContainer={() => popupRef!.current as HTMLDivElement}
                    >
                        {fieldTypes[field.type].supportedRules.map((rule) => {
                            return (
                                <Select.Option key={rule} value={rule}>
                                    {filterRuleMap[rule].label}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>

                {fieldTypes[field.type].render(popupRef, field, index, onChange, choices)}
            </Input.Group>
        </Form.Item>
    );
};
