import React, {useMemo, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {propertyService} from "../../../../../services/property/property.service";
import {SmartTableDataHandler} from "../../../../common/smart-table/smart-table.data-handler";
import {SmartTable} from "../../../../common/smart-table/smart-table";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Button, Col, Row, Space, Typography} from "antd";
import {columnFactory} from "./list.type.columns";
import {PropertyType} from "../../../../../models/property/property";
import {EditPropertyTypeModal} from "../edit/edit.type.modal";
import {notifications} from "../../../../../services/notifications/notifications";

export const PropertyTypeList: React.FC<ComponentPropsFromRoute> = () => {

    const t = useTranslate();

    const handler = useMemo(() => new SmartTableDataHandler(r => propertyService.getTypes(r)), []);
    const columns = useMemo(() => columnFactory(t), [t])

    const [editablePropertyType, setEditablePropertyType] = useState<PropertyType | null>(null);
    const [editableModalShown, setEditableModalShown] = useState<boolean>(false);

    const openEditableModal = (type: PropertyType | null) => {
        setEditablePropertyType(type);
        setEditableModalShown(true);
    }

    const remove = async (type: PropertyType) => {
        await propertyService.removeType(type.id);
        await handler.reloadItemsWithLastRequest();
        notifications.successfully();
    }

    const onModalClosed = async () => {
        setEditableModalShown(false);
        await handler.reloadItemsWithLastRequest();
    }

    return (
        <>
            <Space size={[10, 10]} direction={"vertical"}>
                <Typography.Title level={3} style={{margin: "5 0px"}}>
                    {t("PROPERTY.PROPERTY_TYPES_LIST.TITLE")}
                </Typography.Title>
                <Button
                    type={"primary"}
                    onClick={() => openEditableModal(null)}
                >
                    Create a new type
                </Button>
                <Row>
                    <Col span={12}>
                        <SmartTable
                            columns={columns}
                            handler={handler}
                            actions={[
                                {
                                    title: 'Edit',
                                    icon: 'edit',
                                    onClick: (v) => openEditableModal(v)
                                },
                                {
                                    title: 'Remove',
                                    icon: 'trash',
                                    onClick: (v) => remove(v)
                                }
                            ]}
                        />
                    </Col>
                </Row>
            </Space>
            {editableModalShown ? (
                <EditPropertyTypeModal onClose={onModalClosed} type={editablePropertyType} />
            ) : null}
        </>
    )
}