export {}
declare global {
    interface String {
        beautify() : string;
        replaceUnderscoreWithSpace(): string;
        capitalizeFirstLetter(): string
    }
}

String.prototype.capitalizeFirstLetter = function (): string {
    if (this.length === 0) {
        return "";
    }
    return this[0].toUpperCase() + this.slice(1);
}

String.prototype.replaceUnderscoreWithSpace = function (): string {
    return this.replaceAll("_", " ");
}

String.prototype.beautify = function(): string {
    return this.replaceUnderscoreWithSpace().capitalizeFirstLetter();
}