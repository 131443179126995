import React, {useRef, useState} from "react";
import { observer } from "mobx-react";
import {Button, Dropdown, Menu, Space} from "antd";
import { useRequiredStore } from "../../../../utils/store";
import { RootStoreContext } from "../../../../stores/root/root.store";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
import { DebounceSelect } from "../../../common/debounce-select/debounce.select";
import { agentService } from "../../../../services/agents/agent.service";
import { OrdersListStore, OrdersListStoreContext } from "./orders.list.store";
import { isAllowed } from "../../../../utils/helpers";
import { DocumentsMenu } from "../../../common/documents/menu/documents.menu";
import "./order.list.scss";
import {RouteTabs} from "../../../common/route-tabs/route.tabs";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useNavigate} from "react-router";

interface WholesaleOrdersListProps extends ComponentPropsFromRoute {}

export const WholesaleOrdersList: React.FC<WholesaleOrdersListProps> = observer(({ name, routeChildren }) => {
    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);
    const titleWrapperRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [store] = useState(() => new OrdersListStore(attributes, titleWrapperRef, navigate));
    const t = useTranslate();
    const [clicked, setClicked] = useState<boolean>(false);
    const searchAgents = async (value: string) => {
        const accounts = await agentService.searchWholesaleAccount(value);
        return accounts.map(account => ({value: account.agent.number, label: account.agent.number + " - " + account.agent.name}));
    };

    const allowedActions = store.actions.filter((action) => isAllowed(attributes, action.access));

    return (
        <OrdersListStoreContext.Provider value={store}>
            <Space direction="vertical" size={[50, 50]}>
                <Space direction={"vertical"} size={[10, 10]}>
                    <Space style={{ justifyContent: "space-between" }} size={[10, 10]} wrap>
                        <Space direction={"horizontal"} wrap size={[10, 10]}>
                            <Button type="primary" onClick={() => setClicked((prevState) => !prevState)}>
                                {t("ORDERS.CREATE.TITLE")}
                            </Button>
                            <DocumentsMenu />
                        </Space>

                        {allowedActions.length > 0 ? (
                            <Dropdown
                                placement="bottomRight"
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        {allowedActions.map((action) => (
                                            <Menu.Item
                                                key={action.key}
                                                disabled={action.isDisabled()}
                                                onClick={action.onAction}
                                            >
                                                {t(action.text)}
                                            </Menu.Item>
                                        ))}

                                        {allowedActions.length > 0 ? (
                                            <Menu.Item
                                                key={"clear_all"}
                                                disabled={store.checkedOrders.length === 0}
                                                onClick={store.clearAllCheckedOrders}
                                            >
                                                {t("CLEAR_ALL")}
                                            </Menu.Item>
                                        ) : null}
                                    </Menu>
                                }
                            >
                                <Button type="primary">{t("ACTION")}</Button>
                            </Dropdown>
                        ) : null}
                    </Space>
                    {clicked ? (
                        <DebounceSelect
                            autoFocus
                            placeholder={t("CLIENT.SEARCH")}
                            style={{ width: "280px" }}
                            onChange={(value) => {
                                navigate("/clients/" + value.value + "/cart");
                            }}
                            fetchOptions={searchAgents}
                        />
                    ) : null}
                </Space>

                <RouteTabs
                    routes={routeChildren!}
                />

                {/*{attributes.includes("document_proforma_create") ? (*/}
                {/*    <Space direction="vertical" size={[0, 0]}>*/}
                {/*        <OrdersNew*/}
                {/*            newOrders={store.newOrders}*/}
                {/*            actions={row => <>*/}
                {/*                <Button style={{ marginRight: 5 }} type="primary" size="small" onClick={() => store.selectNewOrders(row)}>*/}
                {/*                    {t("ORDERS.SHOW_NEW")}*/}
                {/*                </Button>*/}
                {/*                <Button type="primary" size="small" onClick={() => store.openNewOrdersProforma(row)}>*/}
                {/*                    {t("ORDERS.NEW_CREATE")}*/}
                {/*                </Button>*/}
                {/*            </>}*/}
                {/*        />*/}
                {/*    </Space>*/}
                {/*) : null}*/}
            </Space>
        </OrdersListStoreContext.Provider>
    );
});
