import React, {useState} from "react";
import {Col, Form, Input, Modal, Row} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {observer} from "mobx-react";
import {branchesService} from "../../../../services/branches/branches.service";
import {useParams} from "react-router-dom";

export interface BranchDataCouriersTestModalProps {
    onClose(): void;
    onComplete(): void;
}

export const BranchDataCouriersCreateModal: React.FC<BranchDataCouriersTestModalProps> = observer(({onClose, onComplete}) => {
    const t = useTranslate();
    const {branchId} = useParams<{ branchId: string }>();

    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    return <Modal
        visible={true}
        confirmLoading={loading}
        title={t("Create a new courier")}
        okText={t("Create")}
        onOk={() => form.submit()}
        onCancel={() => onClose()}
        width={500}
    >
        <Form
            form={form}
            layout="vertical"
            name={"address_form"}
            onFinish={() =>
                form.validateFields().then((values) => {
                    branchesService.createCourier(branchId!, values.name)
                        .then(() => {
                            onClose();
                            onComplete();
                        })
                        .finally(() => setLoading(false));
                })
            }
        >
            <Row gutter={10}>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label={t("NAME")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input disabled={loading} type="text" placeholder={t("NAME")} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal>
})