import {CartViewFilter} from "../../../common/cards-view/model/model";
import {TaskPriorityWidget} from "../../ui/cards-view/filter/widget/task/task-priority/task.priority.widget";
import {Translate} from "../../../../hooks/translate.hook";
import {TaskStatusWidget} from "../../ui/cards-view/filter/widget/task/task-status/task.status.widget";
import {TaskTagsWidget} from "../../ui/cards-view/filter/widget/task/task-tags/task.tags.widget";
import {TaskTypeWidget} from "../../ui/cards-view/filter/widget/task/task-type/task.type.widget";
import {StringWidget} from "../../../common/cards-view/widget/string.widget";
import {TaskDepartmentWidget} from "../../ui/cards-view/filter/widget/task/task-department/task.department.widget";

export const filtersFactory = (t: Translate): CartViewFilter[] => {
    return [
        {
            name: 'status',
            placeholder: t('PLANNER.TASK.FIELDS.TYPE'),
            widget: TaskTypeWidget,
            line: 0
        },
        {
            name: 'department',
            placeholder: t('PLANNER.TASK.FIELDS.DEPARTMENT'),
            widget: TaskDepartmentWidget,
            line: 1
        },
        {
            name: 'priority',
            placeholder: t('PLANNER.TASK.FIELDS.PRIORITY'),
            widget: TaskPriorityWidget,
            line: 1
        },
        {
            name: 'name',
            placeholder: t('PLANNER.TASK.FIELDS.NAME'),
            widget: StringWidget,
            line: 1
        },
        {
            name: 'author',
            placeholder: t('PLANNER.TASK.FIELDS.AUTHOR'),
            widget: StringWidget,
            line: 1
        },
        {
            name: 'status',
            placeholder: t('PLANNER.TASK.FIELDS.STATUS'),
            widget: TaskStatusWidget,
            line: 1
        },
        {
            name: 'tags',
            placeholder: t('PLANNER.TASK.FIELDS.TAGS'),
            widget: TaskTagsWidget,
            line: 1
        },
    ]
}