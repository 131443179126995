import { ArticleLockedStockItem } from "./../../../models/articles/articles.locked-stock";
import { AxiosInstance } from "axios";
import { ArticleLockedStock } from "../../../models/articles/articles.locked-stock";
import API from "../../../utils/api";

export class ArticlesLockedStockService {
    private api: AxiosInstance = API;

    public getLockedStockData(): Promise<ArticleLockedStock[]> {
        return this.api.get("article/locked-stock").then((x) => x.data);
    }

    public getLockedStockItemsData(id: number): Promise<ArticleLockedStockItem[]> {
        return this.api.get<ArticleLockedStockItem[]>("article/" + id + "/locked-stock").then((x) => x.data);
    }
}

export const articlesLockedStockService = new ArticlesLockedStockService();
