import React from "react";
import {PlannerTaskType} from "../../../../../models/planner/planner";
import {Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconName} from "@fortawesome/fontawesome-svg-core";

interface TaskTypeProps {
    type: PlannerTaskType;
}

const colorMap: Record<PlannerTaskType, [string, IconName]> = {
    [PlannerTaskType.ISSUE]: ["red", "box-tissue"],
    [PlannerTaskType.TASK]: ["red", "tasks"],
}

export const TaskType: React.FC<TaskTypeProps> = ({type}) => {

    const [color, icon] = colorMap[type];

    return (
        <Tag color={color} icon={<FontAwesomeIcon icon={["fas", icon]} />}>
            &nbsp;{type.capitalizeFirstLetter()}
        </Tag>
    )
}
