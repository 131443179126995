import React, {PropsWithChildren, useCallback, useState} from "react";
import {Proforma} from "../../../../models/documents/proformas/proforma";
import {List, Popover, Space} from "antd";
import {OrderListWarningIcon} from "../../../orders/list/warning-icon/order.list.warning.icon";
import {OrderComment} from "../../../../models/orders/order-data/order.data";
import {proformasService} from "../../../../services/documents/proformas/proformas.service";
import {Comment} from "@ant-design/compatible";

interface ProformaCommentsPopoverProps {
    document: Proforma;
}
export const ProformaCommentsPopover: React.FC<PropsWithChildren<ProformaCommentsPopoverProps>> = ({document, children}) => {

    const [comments, setComments] = useState<OrderComment[]>([]);

    const onClick = useCallback(() => {
        if (comments.length > 0) {
            setComments([]);
        } else {
            proformasService.getComments(document.id).then((comments) => {
                setComments(comments)
            })
        }
    }, [comments]);

    return (<Space direction="horizontal" align={"start"}>
                {children}
                {document.hasComments ? (
                    <Popover trigger={"click"}
                             afterVisibleChange={(value) => value || setComments([])}
                             content={
                                 <Space style={{ maxWidth: 300 }} direction="vertical" size={[0, 0]}>
                                     <h3>Comments</h3>
                                     {comments.length === 0 ? null : (
                                         <List
                                             className="comment-list"
                                             itemLayout="horizontal"
                                             dataSource={comments}
                                             renderItem={item => (
                                                 <li>
                                                     <Comment
                                                         className={"modal-comment"}
                                                         author={item.user.firstName + ' ' + item.user.lastName}
                                                         content={item.message}
                                                         datetime={item.createdAt}
                                                     />
                                                 </li>
                                             )}
                                         />
                                     )}
                                 </Space>
                             }>
                        <OrderListWarningIcon
                            fill="#19f"
                            style={{ marginTop: 2, cursor: "pointer" }}
                            onClick={onClick}
                        />
                    </Popover>
                ) : null}
            </Space>
    );
}