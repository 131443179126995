import React, {useState} from "react";
import './menu.scss'
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import {observer} from "mobx-react";
import {RootMenuItem} from "./menu-item";
import {SwitchBranchSubMenu} from "./switch-branch.submenu";
import {SwitchUserSubMenu} from "./switch-user.submenu";

interface MenuProps {

}

export const RootMenu: React.FC<MenuProps> = observer(() => {

    const LC_NAME = "rmo";

    const [shown, setShown] = useState<boolean>(localStorage.getItem(LC_NAME) === '1');
    const {authStore: {branch, user}} = useRequiredStore(RootStoreContext);

    const setState = (state: boolean) => {
        setShown(state);
        localStorage.setItem(LC_NAME, state ? '1' : '0');
    }

    if (!shown) {
        return (
            <div
                className={"root-menu-open-lnk"}
                onClick={() => setState(true)}
            >
                {branch.toUpperCase()}
            </div>
        )
    }

    return (
        <div
            className={"root-menu"}
        >
            <ul>
                <RootMenuItem subMenu={SwitchBranchSubMenu}>Switch branch <span className={"highlighted"}> ({branch.toUpperCase()})</span></RootMenuItem>
                <RootMenuItem subMenu={SwitchUserSubMenu}>Switch user  <span className={"highlighted"}>({user?.firstName} {user?.lastName.substr(0, 1)}.)</span></RootMenuItem>
                <RootMenuItem to={"/errors/all"}>Errors (0)</RootMenuItem>
                <RootMenuItem to={"/settings/logger"}>Logger</RootMenuItem>
                <RootMenuItem to={"/settings/languages"}>Translations</RootMenuItem>
                <RootMenuItem to={"/settings/branches"}>Branches</RootMenuItem>
                <RootMenuItem>Web-Site</RootMenuItem>
                <RootMenuItem onClick={() => setState(false)}>➟</RootMenuItem>
            </ul>
        </div>
    )
});