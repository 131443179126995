import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";
import {File, FileType} from "../../models/my-page/files";

export class MyPageService {

    api: AxiosInstance = API;

    getFiles(request: PaginatedRequest): Promise<PaginatedResult<File>> {
        return this.api.get<PaginatedResult<File>>(
            "files",
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }

    getFileTypes(): Promise<FileType[]> {
        return this.api.get<FileType[]>("files/types").then(x => x.data);
    }
}

export const myPageService: MyPageService = new MyPageService();
