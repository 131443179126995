import { Form, message, Modal, Row, Spin } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { AxiosError } from "axios";
import { observer } from "mobx-react";
import React, { PropsWithChildren, useState } from "react";
import {useTranslate} from "../../../hooks/translate.hook";
import { ErrorResponseData } from "../../../models/core/response";
import { ExportBlobStore } from "./export.blob.store";
export type Messages = Record<
    "loading" | "success" | "error",
    (content: string, key: string, duration?: number) => void
>;
interface ExportBlobProps<V = any> {
    form: FormInstance;
    title: string;
    okText: string;
    submitting: boolean;
    onSubmit: (values: V) => Promise<any>;
    onClose: () => void;
    setSubmitting: (value: boolean) => void;
    onInit?: () => Promise<any> | any;
}
export const ExportBlob = observer(
    <V extends object>({
        children,
        form,
        title,
        okText,
        submitting,
        onSubmit,
        setSubmitting,
        onInit,
        onClose,
    }: PropsWithChildren<ExportBlobProps<V>>) => {
        const t = useTranslate();
        const messages: Messages = {
            loading: (content, key) => {
                message.loading({ content: t(content), key });
            },
            success: (content, key, duration) => {
                message.success({ content: t(content), key, duration });
            },
            error: (content, key, duration) => {
                message.error({ content: t(content), key, duration });
            },
        };
        const [store] = useState(() => new ExportBlobStore(onSubmit, messages, onInit));

        if (store.loading) {
            return <Spin />;
        }

        return (
            <Modal
                visible={true}
                destroyOnClose={true}
                confirmLoading={submitting}
                title={t(title)}
                okText={t(okText)}
                cancelText={t("CANCEL")}
                onOk={() => form.submit()}
                onCancel={() => {
                    onClose();
                    form.resetFields();
                }}
                width={650}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name={"integrations_form"}
                    initialValues={store.defaultValues}
                    onFinish={() =>
                        form.validateFields().then((values) => {
                            setSubmitting(true);
                            store
                                .submit(values)
                                .then(
                                    () => {
                                        form.resetFields();
                                        return onClose();
                                    },
                                    (e: AxiosError<ErrorResponseData>) => {
                                        if (e.response?.data.fields) {
                                            form.setFields(e.response?.data?.fields);
                                        }
                                    }
                                )
                                .then(
                                    () => {},
                                    (e) => form.setFields(e.response?.data?.fields)
                                )
                                .finally(() => setSubmitting(false));
                        })
                    }
                >
                    {children}
                </Form>
            </Modal>
        );
    }
);
