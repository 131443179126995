import React, {useEffect, useState} from "react";
import {Button, Select, Space} from "antd";
import {FilterDropdownProps, Key} from "antd/lib/table/interface";
import {FieldConfig, FieldType} from "../../smart-table.field-config";
import {filterRuleMap} from "../rule/smart-table.filter.rule";
import {smartSplitToRuleAndValue} from "../../smart-table.utils";
import {SmartColumn} from "../../smart-table.model";

interface FilterDropdownPropsWithType extends FilterDropdownProps {
    config: FieldConfig
    column: SmartColumn<any>
}

export const FilterDropdown: React.FC<FilterDropdownPropsWithType> = ({ config, column, setSelectedKeys, selectedKeys, confirm, close }) => {

    const [value, setValue] = useState<Key[]>([]);
    const [rule, setRule] = useState<string>("");

    let {widget: Widget , rules} = config;

    if (column.widget) {
        Widget = column.widget;
    }

    const onConfirm = () => {
        setSelectedKeys(value.map(v => rule + v))
        confirm();
    }
    const onChange = (value: string[]) => {
        setValue(value)
    }

    useEffect(() => {
        const value: Key[] = [];
        selectedKeys.forEach(key => {
            const [r, v] = smartSplitToRuleAndValue(String(key), config)
            value.push(v)
            if (r) {
                setRule(r)
            }
        })
        setValue(value)
    }, [])

    return <Space direction={"horizontal"} size={[10, 10]} style={{justifyContent: "space-between", padding: "5px 5px"}}>
        {rules.length > 0 ? (
            <Select onSelect={v => setRule(v as string)} value={rule}>
                {rules.map((rule, key) => (
                    <Select.Option key={key} value={filterRuleMap[rule].value}>{filterRuleMap[rule].label}</Select.Option>
                ))}
            </Select>
        ) : null}

        <Widget
            value={value}
            onChange={onChange}
            multiple={column.type === FieldType.CUSTOM_ARRAY}
        />

        <Button
            onClick={() => onConfirm()}
            disabled={value.length === 0}
            size={"middle"}
            type={"primary"}
        >
            OK
        </Button>
        <Button
            onClick={() => {
                setSelectedKeys([]);
                setValue([]);
                confirm();
                close();
            }}
            size={"middle"}
            danger={true}
        >
            Clear
        </Button>
    </Space>
}