import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";
import {MissingItem} from "../../models/missing-items/missing.item";

export class MissingItemsService {
    private api: AxiosInstance = API;

    getMissingItems(request: PaginatedRequest, account: number): Promise<PaginatedResult<MissingItem>> {
        return this.api.get<PaginatedResult<MissingItem>>("accounts/" + account + "/cart/missing-items", {
            params: request.toParams()
        }).then(x => x.data);
    }

    hideItem(id: number, account: number): Promise<void> {
        return this.api.post("accounts/" + account + "/cart/missing-items/" + id + "/hide");
    }

    hideAllItems(account: number) {
        return this.api.delete("accounts/" + account + "/cart/missing-items");
    }

    showItem(id: number, account: number): Promise<void> {
        return this.api.delete("accounts/" + account + "/cart/missing-items/" + id + "/hide");
    }

    takeToCart(articles: number[], account: number) {
        return this.api.post("accounts/" + account + "/cart/missing-items/take", {items: articles})
    }

    takeAllToCart(data: {warehouses: number[]},  account: number) {
        return this.api.post(
            "accounts/" + account + "/cart/missing-items/move/warehouses",
            data
        );
    }

    createOrder(items: number[], account: number): Promise<any> {
        return this.api.post("accounts/" + account + "/cart/missing-items/create-order", {items: items})
    }

    createOrderWithFilters(filters: any, account: number): Promise<any> {
        return this.api.post("accounts/" + account + "/cart/missing-items/create-order/filter", {}, {params: filters})
    }

    createOrderWithoutReservation(items: number[], account: number): Promise<any> {
        return this.api.post("accounts/" + account + "/cart/missing-items/create-order/without-reservation", {items: items})
    }

    createOrderWithoutReservationWithFilters(filters: any, account: number): Promise<any> {
        return this.api.post("accounts/" + account + "/cart/missing-items/create-order/filter/without-reservation", {}, {params: filters})
    }

    createReservation(items: number[], account: number): Promise<any> {
        return this.api.post("accounts/" + account + "/cart/missing-items/create-order/reservation", {items: items})
    }

    createReservationWithFilters(filters: any, account: number): Promise<any> {
        return this.api.post("accounts/" + account + "/cart/missing-items/create-order/filter/reservation", {}, {params: filters})
    }
}

export const missingItemService = new MissingItemsService();