import React from "react";
import {useTranslate} from "../../../hooks/translate.hook";

export interface YesNoProps {
    value: boolean;
}

export const YesNo: React.FC<YesNoProps> = ({value}) => {
    const t = useTranslate();

    return t(value ? 'YES' : 'NO');
}