import React, {useMemo, useState} from "react";
import { observer } from "mobx-react";
import { useRequiredStore } from "../../../../../utils/store";
import { AgentDataStoreContext } from "../../agent.data.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import FilteredTable from "../../../../common/filtered-table/filtered.table";
import { ComponentPropsFromRoute } from "../../../../../routers/routers";
import {Alert, Button, Dropdown, Menu, Row, Space} from "antd";
import {FilterDataHandler} from "../../../../../models/filter/filter.data.handler";
import {convertTableColumnsToColumnConfigs} from "../../../../../utils/table";
import {columnsFactory} from "./agent.data.proformas.columns";
import {filtersFactory} from "./agent.data.proformas.filters";
import {proformasService} from "../../../../../services/documents/proformas/proformas.service";
import {Proforma} from "../../../../../models/documents/proformas/proforma";
import {TableRowSelection} from "antd/lib/table/interface";
import {PDFModal} from "../pdf-modal/pdf.modal";

interface AgentDataCreditInvoicesProps extends ComponentPropsFromRoute {
}

export const AgentDataProformas: React.FC<AgentDataCreditInvoicesProps> =
    observer(({ name }) => {
        const agentDataStore = useRequiredStore(AgentDataStoreContext);

        const t = useTranslate();

        const dataHandler = useMemo(
            () =>
                new FilterDataHandler<Proforma>((request) =>
                    proformasService.getAllByAccount(
                        request,
                        String(agentDataStore.account!.id)
                    )
                ),
            []
        );

        const columns = useMemo(() => columnsFactory(t), []);
        const filters = useMemo(() => filtersFactory(t), []);

        const [exportAsPDFModalShown, setExportAsPDFModalShown] = useState<boolean>(false);
        const [selectedDocs, setSelectedDocs] = useState<any[]>([]);

        const rowSelection: TableRowSelection<Proforma> = {
            preserveSelectedRowKeys: true,
            type: "checkbox",
            selectedRowKeys: selectedDocs,
            onChange: (selectedRowKeys: React.Key[], selectedRows: Proforma[]) => {
                setSelectedDocs(selectedRowKeys)
            },
        };

        return (
            <Space direction="vertical" size={[10, 10]}>
                <Row justify={"end"}>
                    <Dropdown
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                <Menu.Item
                                    key={0}
                                    onClick={() => setExportAsPDFModalShown(true)}
                                >
                                    {t('DOCUMENTS.EXPORT.AS_PDF')}
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button type={"primary"}>
                            {t("ACTION")}
                        </Button>
                    </Dropdown>
                </Row>

                <Alert
                    message={t("INVOICES.MERGED_INVOICE.LENGTH") + ": " + selectedDocs.length}
                    type="info"
                />

                <FilteredTable
                    columns={columns}
                    columnsConfigs={{ columns: convertTableColumnsToColumnConfigs(columns), pageKey: name }}
                    filterKeys={filters}
                    dataHandler={dataHandler}
                    rowSelection={rowSelection}
                />
                {exportAsPDFModalShown ? (
                    <PDFModal
                        onClose={() => setExportAsPDFModalShown(false)}
                        accountId={agentDataStore.account!.id}
                        selectedDocs={selectedDocs}
                        type={"proforma"}
                    />
                ) : null}
            </Space>
        );
    });
