import {Translate} from "../../../hooks/translate.hook";
import {ColumnsType} from "antd/lib/table/interface";
import {
    CreateClaimItem,
    CreateClaimItemData,
    CreateClaimItemWithInvoice, CreateClaimItemWithoutInvoice
} from "../../../models/claims/claims";
import {Link} from "react-router-dom";
import React from "react";
import {Button, Space} from "antd";
import {displayPrice} from "../../../models/prices/price.helper";
import Date from "../../date/date";

type ItemHandler = (item: CreateClaimItem) => void;

export const columnsFactory = (t: Translate, removeItem: ItemHandler): ColumnsType<CreateClaimItem> => {
    return [
        {
            title: "#",
            key: "number",
            render: (_, __, number) => number + 1
        },
        {
            title: t('ARTICLES.TITLE_SINGLE'),
            dataIndex: "data",
            key: "sku",
            render: (_: CreateClaimItemData, item) => {
                return <Link className={"link"} to={"/catalog/article/" + item.data!.sku}>{item.data!.sku}</Link>
            }
        },
        {
            title: t('PRICE'),
            dataIndex: "data",
            key: "price",
            render: (_: CreateClaimItemData, item) => {
                if (item.type === "known") {
                    const data = item.data as CreateClaimItemWithInvoice;
                    return <>{displayPrice(data.item?.price!)}</>
                } else {
                    const data = item.data as CreateClaimItemWithoutInvoice;
                    return <>{displayPrice({value: data.price, currencyCode: data.currencyCode})}</>
                }
            }
        },
        {
            title: t('QUANTITY_LONG'),
            dataIndex: "data",
            key: "qty",
            render: (data: CreateClaimItemData) => data.qty

        },
        {
            title: t('INVOICE.NUMBER_FEBEST'),
            dataIndex: "data",
            key: "invoiceNumber",
            render: (_: CreateClaimItemData, item) => {
                if (item.type === "known") {
                    const data = item.data as CreateClaimItemWithInvoice;
                    return <Link className={"link"} to={"/final-invoices/" + data.invoice?.docNumber}>{data.invoice?.docNumber}</Link>
                } else {
                    const data = item.data as CreateClaimItemWithoutInvoice;
                    return <>{data.invoiceNumber}</>
                }
            }
        },
        {
            title: t('INVOICES.INVOICE.DATE'),
            dataIndex: "data",
            key: "invoiceDate",
            render: (_: CreateClaimItemData, item) => {
                if (item.type === "known") {
                    const data = item.data as CreateClaimItemWithInvoice;
                    return <Date dateString={data.invoice?.date!} />
                } else {
                    const data = item.data as CreateClaimItemWithoutInvoice;
                    return <Date dateString={data.invoiceDate.format()} />
                }
            }
        },
        {
            key: "actions",
            render: (_, item) => (
                <Space direction={"horizontal"}>
                    <Button size={"small"} onClick={() => removeItem(item)}>{t('REMOVE')}</Button>
                </Space>
            )
        }
    ];
}