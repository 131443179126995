import { action, computed, makeObservable, observable } from "mobx";

export class MenuStore {
    @observable
    private _state: string = localStorage.state || "";
    private _prevState: string = localStorage.prevState || "";

    @observable
    private _openCloseState: boolean = localStorage.openCloseState
        ? localStorage.openCloseState === "1"
        : false;

    @observable
    private _fullScreenModeState: boolean = localStorage.fullScreenModeState
        ? localStorage.fullScreenModeState === "1"
        : false;

    private CHANGE_STATE_BASE: number = 1280;
    private ROLLED_UP_MENU: string = "rolled-up-menu";
    private ROLLED_DOWN_MENU: string = "rolled-down-menu";
    private COLLAPSED_MENU: string = "collapsed-menu";
    private FULL_SCREEN_MENU: string = "full-screen-menu";

    private ANIMATION: any = {};

    constructor() {
        makeObservable(this);
        this.initAnimationObject();
        if (
            !localStorage.state ||
            !localStorage.prevState ||
            !localStorage.openCloseState ||
            !localStorage.fullScreenModeState
        ) {
            this.setResizeListener();
        }
    }

    private initAnimationObject() {
        // records naming: From state to state
        // if you unnecessary use any state composition, do not create it config
        this.ANIMATION[this.ROLLED_DOWN_MENU + this.ROLLED_UP_MENU] = {
            menu: "nav-animation-from-rolled-down-to-rolled-up",
            container: "container-animation-from-rolled-down-to-rolled-up",
        };

        this.ANIMATION[this.ROLLED_UP_MENU + this.ROLLED_DOWN_MENU] = {
            menu: "nav-animation-from-rolled-up-to-rolled-down",
            container: "container-animation-from-rolled-up-to-rolled-down",
        };

        this.ANIMATION[this.COLLAPSED_MENU + this.FULL_SCREEN_MENU] = {
            menu: "nav-animation-from-collapsed-to-full-screen",
            container: "",
        };

        this.ANIMATION[this.FULL_SCREEN_MENU + this.COLLAPSED_MENU] = {
            menu: "nav-animation-from-full-screen-to-collapsed",
            container: "",
        };
    }

    @computed
    public get openCloseState(): boolean {
        return this._openCloseState && this._state === this.ROLLED_UP_MENU;
    }

    @computed
    public get getStateForMenu(): string {
        return (
            (this.ANIMATION[this._prevState + this._state]
                ? this.ANIMATION[this._prevState + this._state].menu + " "
                : "") + this._state
        );
    }

    @computed
    public get getStateForContainer(): string {
        return (
            (this.ANIMATION[this._prevState + this._state]
                ? this.ANIMATION[this._prevState + this._state].container + " "
                : "") + this._state
        );
    }

    @action
    public toggleOpenCloseState() {
        if (this._state !== this.COLLAPSED_MENU) {
            this._openCloseState = !this._openCloseState;
            localStorage.setItem(
                "openCloseState",
                this._openCloseState ? "1" : "0"
            );
            const state = this._openCloseState
                ? this.ROLLED_UP_MENU
                : this.ROLLED_DOWN_MENU;
            this._prevState = this.state;
            this.state = state;
        }
    }

    private setResizeListener() {
        window.addEventListener("resize", (event: any) => this.setState(event));
        this.state = this.calculateState(window.innerWidth);
    }

    public set state(state: string) {
        this._prevState = this._state;
        // localStorage.setItem("prevState", this._prevState)
        this._state = state;
        localStorage.setItem("state", this._state);
    }

    public get state(): string {
        return this._state;
    }

    private calculateState(width: number): string {
        return width <= this.CHANGE_STATE_BASE
            ? this.COLLAPSED_MENU
            : this._openCloseState
            ? this.ROLLED_UP_MENU
            : this.ROLLED_DOWN_MENU;
    }

    @action
    public setState(event: any) {
        const win = event.currentTarget;
        const state = this.calculateState(win.innerWidth);
        if (this._state !== state) {
            this.state = state;
        }
    }

    @action
    public toggleFullScreenModeState(event: any) {
        const isItOpenCloseButton =
            event.currentTarget.parentNode.classList.contains(
                "nav-open-close-menu-button"
            );
        if (
            (this._state === this.COLLAPSED_MENU && isItOpenCloseButton) ||
            this._state === this.FULL_SCREEN_MENU
        ) {
            this._fullScreenModeState = !this._fullScreenModeState;
            localStorage.setItem(
                "fullScreenModeState",
                this._fullScreenModeState ? "1" : "0"
            );
            this.state = this._fullScreenModeState
                ? this.FULL_SCREEN_MENU
                : this.COLLAPSED_MENU;
        }
    }

    @computed
    public get fullScreenModeState(): boolean {
        return this._fullScreenModeState;
    }
}
