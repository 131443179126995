import React, {useEffect, useState} from "react";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {Alert, Checkbox, Col, Form, Row} from "antd";
import {DebounceSelect} from "../../../../../common/debounce-select/debounce.select";
import {useRequiredStore} from "../../../../../../utils/store";
import {UserDataStoreContext} from "../../user.data.store";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../../../models/core/response";
import {UserAccess} from "../../../../../../models/users/user/extended.user";
import {FoundUsersAccount} from "../../../../../../models/users/user/found-user/found.user";

interface UserDataConnectModalProps {
    setOnSubmit: (onSubmit: () => void) => void,
    onClose:() => void
}

export const ConnectByUserTab: React.FC<UserDataConnectModalProps> = ({setOnSubmit, onClose}) => {
    const t = useTranslate();
    const store = useRequiredStore(UserDataStoreContext);
    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);
    const [accounts, setAccounts] = useState<FoundUsersAccount[]>([])
    const [userAccountsSearchResultMessage, setUserAccountsSearchResultMessage] = useState<string>("");

    const filterFoundUsers = (accesses: UserAccess[], foundUsers: FoundUsersAccount[]): FoundUsersAccount[] => {
        const systemAccesses = accesses.map((item) => item.account.type);
        const filteredFoundUsers = foundUsers.filter((item) => item.type === 'wholesale' || !systemAccesses.includes(item.type));
        if (filteredFoundUsers) {
            return filteredFoundUsers;
        }
        return [];
    };

    const defaultValues = {
        accesses: []
    };

    useEffect(() => {
        setOnSubmit(() => () => {
            form.submit();
        });
    },[setOnSubmit, form])

    return (
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            name={"connect_form"}
            initialValues={defaultValues}
            onFinish={() => form
                .validateFields()
                .then(values => {
                    setLoading(true);
                    const request = {...values};
                    delete request.profile;
                    store.connectAccounts(request.accounts)
                        .then(
                            () => {
                                form.resetFields();
                                setUserAccountsSearchResultMessage("");
                                return onClose();
                            },
                            (e: AxiosError<ErrorResponseData>) => {
                                setLoading(false);
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                        .then(() =>
                            (e: any) => form.setFields(e.response?.data?.fields))
                        .finally(() => setLoading(false));
                })
            }
        >
            <Row gutter={[10, 0]} align={"middle"}>
                <Col span={24}>
                    <Form.Item
                        name={"profile"}
                        label={t("USER.SELECT")}
                        rules={[{
                            required: true,
                            message: t("FROM.ERROR.PLEASE_SELECT_VALUE")
                        }]}
                    >
                        <DebounceSelect
                            placeholder={t("USER.SELECT")}
                            style={{width: "100%"}}
                            disabled={loading}
                            fetchOptions={store.searchUsers}
                            onSelect={value => {
                                setUserAccountsSearchResultMessage("");
                                const foundAccounts = filterFoundUsers(store.userData!.accesses, store.foundUsers[value.value]);
                                setAccounts(foundAccounts)
                                if (!foundAccounts.length) {
                                    setUserAccountsSearchResultMessage(t("NO_ACCOUNTS"));
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
                {accounts.length ? (
                    <Col span={24}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(oldValue, newValue) => oldValue.accounts !== newValue.accounts}
                        >
                            {() => (
                                <Form.Item
                                    rules={[{
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_SELECT_VALUE")
                                    }]}
                                    name={"accounts"}>
                                    <Checkbox.Group>
                                        {accounts.map(account => (
                                            <Checkbox
                                                key={account.id}
                                                value={account.id}
                                                disabled={loading}
                                            >
                                                {account.type.toUpperCase()}
                                            </Checkbox>
                                        ))}
                                    </Checkbox.Group>
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                ) : null}
                {userAccountsSearchResultMessage.length ? (
                    <Col span={24}>
                        <Alert type={"warning"} message={userAccountsSearchResultMessage}/>
                    </Col>
                ) : null}
            </Row>
        </Form>
    );
};
