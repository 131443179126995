import {YesNo} from "../../../common/yes-no/yes-no";
import React from "react";
import {Translate} from "../../../../hooks/translate.hook";
import {Button, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PriceList} from "../../../../models/price-lists/price.lists";
import Date from "../../../date/date";
export const columnsFactory = (
    t: Translate,
    setEditModalItem: (value: PriceList | null) => void,
    setUploadModalShown: (value: PriceList | null) => void,
    download: (value: PriceList) => void,
    allowChange: boolean,
    allowChangeSkipBranch: boolean,
    branchId: string
) => [
    {
        title: "ID",
        dataIndex: "id",
        key: "id",
    },
    {
        title: t("NAME"),
        dataIndex: "name",
        key: "name",
    },
    {
        title: t("1C.NAME"),
        dataIndex: "ocName",
        key: "ocName",
    },
    {
        title: t("CURRENCY"),
        dataIndex: "currencyCode",
        key: "currencyCode",
        render: (item: string) => <>{item.toUpperCase()}</>,
    },
    {
        title: t("BRANCH.DATA.PRICES.NEXT_PRICE.TITLE"),
        dataIndex: "nextPrice",
        key: "nextPrice",
        render: (item: PriceList | null) => item?.name || "(none)",
    },
    {
        title: t("BRANCH.DATA.PRICES.NEXT_PRICE.APPLY_AT"),
        dataIndex: "applyNextPriceAt",
        key: "applyNextPriceAt",
        render: (item: string) => (
            <Date dateString={item} />
        ),
    },
    {
        title: t("ACTIVE"),
        dataIndex: "active",
        key: "active",
        render: (item: boolean) => (
            <YesNo value={item} />
        ),
    },
    {
        title: t("ACTIONS.TITLE"),
        key: "actions",
        render: (_: any, item: PriceList) => {

            const list = [];

            if (allowChangeSkipBranch || (allowChange && branchId === item.branch.id)) {
                list.push(
                    <Button
                        className="table-btn btn-remove btn"
                        type="default"
                        icon={<FontAwesomeIcon icon={["fas", "edit"]} />}
                        onClick={()=> setEditModalItem(item)}
                    />,
                    <Button
                        className="table-btn btn-remove btn"
                        type="default"
                        icon={<FontAwesomeIcon icon={["fas", "upload"]} />}
                        onClick={()=> setUploadModalShown(item)}
                    />
                )
            }

            list.push(<Button
                className="table-btn btn-remove btn"
                type="default"
                icon={<FontAwesomeIcon icon={["fas", "download"]} />}
                onClick={()=> download(item)}
            />);

            return (
                <Space>{list}</Space>
            )

        },
    },
];
