import React, {useEffect, useState} from "react";
import {Descriptions, Modal, Tabs} from "antd";
import {sourcesService} from "../../../../../../services/documents/common/sources.service";
import {ViewArrayAsTable} from "./view-array-as-table";
import { Prism, SyntaxHighlighterProps } from 'react-syntax-highlighter';
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";

const SyntaxHighlighter = Prism as typeof React.Component<SyntaxHighlighterProps>;

interface ViewModalProps {
    sourceId: number;
    onClose: () => void
}

export const ViewModal: React.FC<ViewModalProps> = ({sourceId, onClose}) => {

    const [content, setContent] = useState<Record<string, any>>({});

    useEffect(() => {
        sourcesService.downloadSource(sourceId).then(s => s.blob.text().then(v => setContent(JSON.parse(v))))
    }, [sourceId])

    return <Modal
        title={"View source"}
        visible={true}
        onCancel={onClose}
        width={"900px"}
        footer={false}
    >
        <Tabs>
            <Tabs.TabPane key={0} tab={"JSON"}>
                <SyntaxHighlighter
                    style={dark}
                    language={"json"}
                    PreTag="pre"
                >
                    {JSON.stringify(content, null, 4)}
                </SyntaxHighlighter>
            </Tabs.TabPane>
            <Tabs.TabPane key={1} tab={"Preview"}>
                <Descriptions
                    bordered
                    size={"small"}
                    column={1}
                >
                    {Object.keys(content).map(key =>
                        <Descriptions.Item label={key}>
                            {Array.isArray(content[key]) ? <ViewArrayAsTable items={content[key]} /> : <>{content[key]}</>}
                        </Descriptions.Item>
                    )}
                </Descriptions>
            </Tabs.TabPane>
        </Tabs>
    </Modal>
}