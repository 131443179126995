import {action, makeObservable, observable} from "mobx";
import {FilterDataHandler} from "../../../../models/filter/filter.data.handler";
import {Agreement, AgreementsRequest, AgreementsResponse} from "../../../../models/agreements/agreement";
import {agreementsService} from "../../../../services/agreements/agreements.service";
import {PaginatedRequest} from "../../../../models/core/paginated.request";
import React from "react";
import {i18n} from "i18next";
import {languagesService} from "../../../../services/languages/languages.service";
import {WarehouseTaxes} from "../../../../models/warehouses/warehouses.taxes";
import {warehousesTaxesService} from "../../../../services/warehouses/warehouses-taxes/warehouses.taxes.service";

export class AgreementsListStore {
    public dataHandler: FilterDataHandler<Agreement>
    private request = new PaginatedRequest();
    
    @observable
    public modalShown: boolean = false;

    @observable
    public tableItemAgreement: Agreement | null = null;

    @observable
    public locales: string[] = [];

    @observable
    public editModalItem: AgreementsResponse | null = null;
    
    constructor(private readonly i18n: i18n) {
        makeObservable(this);
        this.dataHandler = new FilterDataHandler<Agreement>(request => agreementsService.getAgreements(request));
        this.openCreateModal = this.openCreateModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.createAgreement = this.createAgreement.bind(this);
        this.updateAgreement = this.updateAgreement.bind(this);
        this.loadLocales();
    }

    @action
    public setLocales(value: string[]): void {
        this.locales = value;
    }

    @action
    public setTableItemAgreement(value: Agreement): void {
        this.tableItemAgreement = value;
    }

    public async onEditModalOpen(item: Agreement) {
        const agreement = await agreementsService.getAgreement(item.id);
        this.openEditModal(agreement);
        this.setTableItemAgreement(item);
    }

    @action
    public openEditModal(item: AgreementsResponse): void {
        this.modalShown = true;
        this.editModalItem = item;
    }

    @action
    public openCreateModal(): void {
        this.modalShown = true;
    }

    @action
    public closeEditModal(): void {
        this.modalShown = false;
        this.editModalItem = null;
        this.tableItemAgreement = null;
    }

    public async loadLocales() {
        this.setLocales(await languagesService.getLocales());
    }

    public async updateAgreement(values: AgreementsRequest) {
        this.dataHandler.setLoading(true);
        if (this.tableItemAgreement) {
            const updatedItem = await agreementsService.updateAgreement(this.tableItemAgreement.id, values);
            await this.i18n.reloadResources();
            this.dataHandler.updateItem(this.tableItemAgreement, updatedItem);
        }
        this.dataHandler.setLoading(false);
    }

    public async createAgreement(values: AgreementsRequest) {
        this.dataHandler.setLoading(true);
        const newItem = await agreementsService.createAgreement(values);
        await this.i18n.reloadResources();
        this.dataHandler.addItem(newItem)
        this.dataHandler.setLoading(false);
    }

    public async removeAgreement(item: Agreement) {
        this.dataHandler.setLoading(true);
        await agreementsService.removeAgreement(item.id);
        this.dataHandler.removeItem(item);
        this.dataHandler.setLoading(false);
    }

}

export const AgreementsListStoreContext = React.createContext<null | AgreementsListStore>(null) as React.Context<AgreementsListStore>;