import React, {useEffect, useState} from "react";
import {WidgetProps} from "../../../../../../../common/cards-view/model/model";
import {Select} from "antd";
import {plannerService} from "../../../../../../../../services/planner/planner.service";
import {DepartmentFlatten} from "../../../../../../../../models/departments/department";
import {flattenDepartments} from "../../../../../../../../models/departments/helper";

export const TaskDepartmentWidget: React.FC<WidgetProps> = ({onChange, value, placeholder}) => {

    const [departments, setDepartments] = useState<DepartmentFlatten[]>([]);

    useEffect(() => {
        plannerService.getMyDepartments().then(departments => setDepartments(flattenDepartments(departments)));
    }, [])

    return (
        <Select
            mode={"multiple"}
            onChange={onChange as any}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
            placeholder={placeholder}
            maxTagCount={1}
            variant={"filled"}
        >
            {departments.map(department => (
                <Select.Option value={department.id}>{department.name}</Select.Option>
            ))}
        </Select>
    )
}