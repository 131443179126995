import React, {useState} from "react";
import {observer} from "mobx-react";
import {LoginWrapper} from "../login.wrapper";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../models/core/response";
import {Alert, Button, Form, Input, Select, Space} from "antd";
import {useTranslate} from "../../../hooks/translate.hook";
import {ForgotPasswordStore} from "./forgot.password.store";
import { Link } from "react-router-dom";

export const ForgotPassword: React.FC = observer(() => {
    const [store] = useState(() => new ForgotPasswordStore());
    const t = useTranslate();
    const [form] = Form.useForm();

    return (
        <LoginWrapper title="FORGOT_PASSWORD.TITLE">
            {!store.sent ? (
                <Form
                    form={form}
                    name="normal_login"
                    className="login-form"
                    layout="vertical"
                    onFinish={() => form
                        .validateFields()
                        .then(values => {
                            store.sendEmail(values)
                                .then(() => {
                                    form.resetFields();
                                }, (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                        })
                    }
                >
                    <Form.Item
                        name="username"
                        rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        help={t("FORGOT_PASSWORD.HELP")}
                    >
                        <Input
                            type="email"
                            placeholder={t("EMAIL")}
                            disabled={store.disabled}
                            style={{marginBottom: 10}}
                        />
                    </Form.Item>
                    <Form.Item style={{margin: "20px 0 0 0"}}>
                        <Button style={{width: "100%"}} disabled={store.disabled} type="primary" htmlType="submit">{t("FORGOT_PASSWORD.SEND")}</Button>
                    </Form.Item>
                </Form>
            ) : (
                <Alert description={t("FORGOT_PASSWORD.SENT")} type="success" message={t("SUCCESS")} showIcon/>
            )}
            <Link
                className="ant-btn ant-btn-primary"
                to="/login"
                style={{marginTop: 10, width: "100%"}}
            >
                {t("LOGIN.TITLE")}
            </Link>
        </LoginWrapper>
    );
})