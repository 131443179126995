import { getFromStorage } from "../../../../utils/helpers";
import { action, makeObservable, observable } from "mobx";
import queryString from "query-string";
import React, { RefObject } from "react";
import { Action } from "../../../../models/action/action";
import { PaginatedRequest } from "../../../../models/core/paginated.request";
import { Error } from "../../../../models/errors/error";
import { FilterDataHandler } from "../../../../models/filter/filter.data.handler";
import { FieldGroupSnapshot } from "../../../../models/filter/filter.field.snapshot";
import { FilterButtonConfig } from "../../../../models/filtered-table/filtered.table.props";
import { Order, OrderListClientAgreement } from "../../../../models/orders/orders-list/order";
import { orderDataService } from "../../../../services/orders/order-data/order.data.service";
import { ordersListService } from "../../../../services/orders/orders-list/orders.list.service";
import { checkIfKeysValid } from "../../../agents/agent-data/agent-data-orders/agent.data.orders.store";
import { OrderStatuses } from "../../../../stores/common/common.store";
import {OrderComment} from "../../../../models/orders/order-data/order.data";
import {downloadBlobFile} from "../../../../utils/blob.download";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import dayjs from "dayjs";

export enum OrdersFilterButtonsTypes {
    NOT_CONFIRMED_ORDERS = "not_confirmed_orders",
    NEW_ORDERS = "new_orders",
    IN_PROCESS_ORDERS = "in_process_orders",
    PROCESSED_ORDERS = "processed_orders",
    ALL_ORDERS = "all_orders",
    CANCELLED_ORDERS = "cancelled_orders",
    NOT_COMPLETED_ORDERS = "not_completed",
    PRE_ORDERS = "pre_orders",
    BACKORDERS = "backorders",
}

export type ActiveOrdersFilterButton = OrdersFilterButtonsTypes;

export enum OrdersListActionKeys {
    DOCUMENT_PROFORMA_CREATE,
    DELIVERY_ORDER_CREATE,
    CANCEL_ORDERS,
    SHOW_SUMMARY_INFO,
    CREATE_IC_EXPORT_FILE
}

export class OrdersListStore {
    static CHECKED_ORDERS_KEY = "checked_orders";

    private notConfirmedOrdersStatuses: string[] = ["not_confirmed"];
    private newOrdersStatuses: string[] = ["not_confirmed", "confirmed", "ordered"];
    private inProcessOrdersStatuses: string[] = ["in_progress", "collected", "packed"];
    private processedOrdersStatuses: string[] = ["shipped", "delivered"];
    private cancelledOrdersStatuses: string[] = ["cancelled"];
    private notCompletedOrdersStatuses: string[] = ["not_completed"];

    public dataHandler: FilterDataHandler<Order>;

    public request: PaginatedRequest | null = null;

    @observable
    public deliveryOrderModalShown: boolean = false;

    @observable
    public summaryModalShown: boolean = false;

    @observable
    public activeFilterButton: ActiveOrdersFilterButton = OrdersFilterButtonsTypes.NEW_ORDERS;

    @observable
    public filters: FieldGroupSnapshot[] = [];

    @observable
    public checkedOrders: React.Key[] = getFromStorage<React.Key[]>(
        OrdersListStore.CHECKED_ORDERS_KEY,
        [],
        checkIfKeysValid
    );

    @observable
    public createProformaModalShown: boolean = false;

    @observable
    public warningsModalShown: boolean = false;

    @observable
    public commentsModalShown: boolean = false;

    @observable
    public warnings: Error[] | null = null;

    @observable
    public comments: OrderComment[] | null = null;

    @observable
    public agreementsModalShown: boolean = false;

    @observable
    public agreements: OrderListClientAgreement[] | null = null;

    @observable
    public agreementsAccountId: number | null = null;

    @observable
    public commentOrderId: number | null = null;

    public filterSnapshotSetter: null | ((value: FieldGroupSnapshot[]) => void) = null;

    public filterButtonConfigs: FilterButtonConfig<ActiveOrdersFilterButton>[] = [
        {
            type: OrdersFilterButtonsTypes.ALL_ORDERS,
            text: "ORDERS.ALL",
            filters: [],
        },
        {
            type: OrdersFilterButtonsTypes.NOT_CONFIRMED_ORDERS,
            text: "ORDERS.NOT_CONFIRMED",
            filters: [
                {
                    name: "status",
                    snapshots: [
                        {
                            name: "status",
                            rule: undefined,
                            value: this.notConfirmedOrdersStatuses.join(","),
                        },
                    ],
                },
            ],
        },

        {
            type: OrdersFilterButtonsTypes.NEW_ORDERS,
            text: "ORDERS.NEW",
            filters: [
                {
                    name: "status",
                    snapshots: [
                        {
                            name: "status",
                            rule: undefined,
                            value: this.newOrdersStatuses.join(","),
                        },
                    ],
                },
            ],
        },
        {
            type: OrdersFilterButtonsTypes.IN_PROCESS_ORDERS,
            text: "ORDERS.IN_PROCESS",
            filters: [
                {
                    name: "status",
                    snapshots: [
                        {
                            name: "status",
                            rule: undefined,
                            value: this.inProcessOrdersStatuses.join(","),
                        },
                    ],
                },
            ],
        },
        {
            type: OrdersFilterButtonsTypes.PROCESSED_ORDERS,
            text: "ORDERS.PROCESSED",
            filters: [
                {
                    name: "status",
                    snapshots: [
                        {
                            name: "status",
                            rule: undefined,
                            value: this.processedOrdersStatuses.join(","),
                        },
                    ],
                },
            ],
        },
        {
            type: OrdersFilterButtonsTypes.CANCELLED_ORDERS,
            text: "ORDERS.CANCELLED",
            filters: [
                {
                    name: "status",
                    snapshots: [
                        {
                            name: "status",
                            rule: undefined,
                            value: this.cancelledOrdersStatuses.join(","),
                        },
                    ],
                },
            ],
        },
        {
            type: OrdersFilterButtonsTypes.NOT_COMPLETED_ORDERS,
            text: "ORDERS.NOT_COMPLETED",
            filters: [
                {
                    name: "status",
                    snapshots: [
                        {
                            name: "status",
                            rule: undefined,
                            value: this.notCompletedOrdersStatuses.join(","),
                        },
                    ],
                },
            ],
        },
        {
            type: OrdersFilterButtonsTypes.PRE_ORDERS,
            text: "ORDERS.PRE-ORDERS.TITLE",
            filters: [
                {
                    name: "type",
                    snapshots: [
                        {
                            name: "type",
                            rule: undefined,
                            value: "reservation",
                        },
                    ],
                },
            ],
        },
        {
            type: OrdersFilterButtonsTypes.BACKORDERS,
            text: "ORDERS.BACKORDERS.TITLE",
            filters: [
                {
                    name: "type",
                    snapshots: [
                        {
                            name: "type",
                            rule: undefined,
                            value: "backorder",
                        },
                    ],
                },
            ],
        },
    ];

    public actions: Action<OrdersListActionKeys>[] = [
        {
            key: OrdersListActionKeys.SHOW_SUMMARY_INFO,
            text: "ORDERS.SHOW_SUMMARY_INFO",
            access: "",
            isDisabled: () => !this.checkedOrders.length,
            onAction: () => this.setSummaryModalShown(true),
        },
        {
            key: OrdersListActionKeys.DOCUMENT_PROFORMA_CREATE,
            text: "ORDERS.PROFORMA.CREATE",
            access: "document_proforma_create",
            isDisabled: () => !this.checkedOrders.length,
            onAction: () => this.setCreateProformaModalShown(true),
        },
        {
            key: OrdersListActionKeys.DELIVERY_ORDER_CREATE,
            text: "ORDERS.DELIVERY.CREATE",
            access: "",
            isDisabled: () => !this.checkedOrders.length,
            onAction: () => this.setDeliveryOrderModalShown(true),
        },
        {
            key: OrdersListActionKeys.CREATE_IC_EXPORT_FILE,
            text: "ORDERS.IC_EXPORT_FILE.CREATE",
            access: "",
            isDisabled: () => !this.checkedOrders.length,
            onAction: () => this.downloadICExportCSV()
        },
        {
            key: OrdersListActionKeys.CANCEL_ORDERS,
            text: "ORDERS.CANCEL.TITLE",
            access: "",
            isDisabled: () =>
                !this.checkedOrders.length || this.attributes.includes("document_proforma_create")
                    ? this.dataHandler.items
                          .filter((item) => this.checkedOrders.includes(item.id))
                          .every(
                              (item) =>
                                  item.status === OrderStatuses.NOT_COMPLETED ||
                                  item.status === OrderStatuses.NOT_CONFIRMED ||
                                  item.status === OrderStatuses.CONFIRMED
                          )
                    : !this.dataHandler.items
                          .filter((item) => this.checkedOrders.includes(item.id))
                          .every((item) => item.status === "not_completed" || item.status === "not_confirmed"),
            onAction: () => this.cancelOrders(),
        },
    ];

    constructor(private attributes: string[], private titleWrapperRef: RefObject<HTMLDivElement>, private navigate: NavigateFunction) {
        makeObservable(this);
        this.setRequest = this.setRequest.bind(this);
        this.dataHandler = new FilterDataHandler<Order>((request) => ordersListService.getAllWholesaleOrders(request));
        this.clearAllCheckedOrders = this.clearAllCheckedOrders.bind(this);
        this.downloadICExportCSV = this.downloadICExportCSV.bind(this);
    }

    @action
    public setAgreementsAccountId(value: number | null): void {
        this.agreementsAccountId = value;
    }

    @action
    public setAgreementsModalShown(value: boolean): void {
        this.agreementsModalShown = value;
    }

    @action
    public setAgreements(value: OrderListClientAgreement[] | null): void {
        this.agreements = value;
    }

    @action
    public setWarnings(value: Error[] | null): void {
        this.warnings = value;
    }

    @action
    public setComments(value: OrderComment[] | null): void {
        this.comments = value;
    }

    @action
    public setCommentOrdertId(value: number | null): void {
        this.commentOrderId = value;
    }

    @action
    private setCreateProformaModalShown(value: boolean): void {
        this.createProformaModalShown = value;
    }

    @action
    public setDeliveryOrderModalShown(value: boolean): void {
        this.deliveryOrderModalShown = value;
    }

    @action
    public setSummaryModalShown(value: boolean): void {
        this.summaryModalShown = value;
    }

    @action
    private setCheckedOrders(selectedRows: React.Key[]): void {
        this.checkedOrders = selectedRows;
    }

    @action
    public setWarningsModalShown(value: boolean): void {
        this.warningsModalShown = value;
    }

    @action
    public setCommentsModalShown(value: boolean): void {
        this.commentsModalShown = value;
    }

    public setSnapshotsSetter = (filterSnapshotSetter: (value: FieldGroupSnapshot[]) => void) => {
        this.filterSnapshotSetter = filterSnapshotSetter;
    };

    public setRequest(value: PaginatedRequest | null): void {
        this.request = value;
    }

    public checkOrders(selectedRows: React.Key[]): void {
        this.setCheckedOrders(selectedRows);
        localStorage.setItem(OrdersListStore.CHECKED_ORDERS_KEY, JSON.stringify(this.checkedOrders));
    }

    public clearAllCheckedOrders(): void {
        this.setCheckedOrders([]);
        localStorage.removeItem(OrdersListStore.CHECKED_ORDERS_KEY);
    }

    public openCreateProformaModal = (): void => {
        this.setCreateProformaModalShown(true);
    };

    public closeCreateProformaModal = (): void => {
        this.setCreateProformaModalShown(false);
    };

    public openDeliveryOrderModal = (): void => {
        this.setDeliveryOrderModalShown(true);
    };

    public closeDeliveryOrderModal = (): void => {
        this.setDeliveryOrderModalShown(false);
    };

    public createProforma = async (values: { date: string; orders: number[] }) => {
        const result = await ordersListService.createProformaByOrders({
            ...values,
            date: dayjs(values.date).format("YYYY-MM-DD"),
            orders: this.checkedOrders as number[],
        });
        this.clearAllCheckedOrders();
        this.navigate("/proformas/" + result.id);
    };

    public async openWarningsModal(id: number) {
        this.setWarnings(await orderDataService.getWarnings(id));
        this.setWarningsModalShown(true);
    }

    public closeWarningsModal = () => {
        this.setWarningsModalShown(false);
        this.setWarnings(null);
    };

    public async openCommentsModal(id: number) {
        this.setComments(await orderDataService.getComments(id));
        this.setCommentsModalShown(true);
        this.setCommentOrdertId(id);
    }

    public closeCommentsModal = () => {
        this.setCommentsModalShown(false);
        this.setComments(null);
        this.setCommentOrdertId(null);
    };

    public async openAgreementsModal(id: number, orderId: number) {
        this.setAgreements(await ordersListService.getClientAgreements(id));
        this.setAgreementsModalShown(true);
        this.setAgreementsAccountId(orderId);
    }

    public closeAgreementsModal = () => {
        this.setAgreementsAccountId(null);
        this.setAgreementsModalShown(false);
        this.setAgreements(null);
    };

    public handleDeliveryModalSubmit = () => {
        this.navigate("/orders/delivery?" + queryString.stringify({ orders: this.checkedOrders as number[] }));
        this.clearAllCheckedOrders();
    };

    public cancelOrders = async () => {
        await ordersListService.cancelOrders(this.checkedOrders as any);
        this.clearAllCheckedOrders();
        this.dataHandler.reloadItems(this.request!);
    };

    public downloadICExportCSV = async () => {
        const {blob, filename} = await ordersListService.createICExportCSV(this.checkedOrders as any);
        downloadBlobFile(blob, filename)
        this.clearAllCheckedOrders();
    };
}

export const OrdersListStoreContext = React.createContext<OrdersListStore | null>(null);
