import { ColumnsType } from "antd/lib/table";
import React from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { Link } from "react-router-dom";
import {ExpandedTable} from "../../../../common/order-details/expanded.table";
import {OrdersStatus} from "../../../../orders/status/order.status";
import {OrderStatus} from "../../../../../stores/common/common.store";
import {Amount} from "../../../../../models/prices/price";
import {displayPrice} from "../../../../../models/prices/price.helper";
import {Warehouse} from "../../../../../models/warehouses/warehouse";
import Date from "../../../../date/date";
import {InvoiceData} from "../../../../../models/documents/invoices/invoice-data/invoice.data";
import {convertTableColumnsToColumnConfigs} from "../../../../../utils/table";
import {OrderData} from "../../../../../models/orders/order-data/order.data";
import {invoiceService} from "../../../../../services/documents/invoices/invoices.service";

interface InvoiceDataOrdersProps {
    document: InvoiceData
    reload: () => void;
}

export const InvoiceDataOrders: React.FC<InvoiceDataOrdersProps> = ({document, reload}) => {
    const t = useTranslate();

    const columns: ColumnsType<any> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "160px",
            render: (id: string) => (
                <Link className="link" to={"/orders/" + id}>
                    {id}
                </Link>
            ),
        },
        {
            title: t("ORDERS.ALT_NUMBER"),
            dataIndex: "clientOrderId",
            key: "orders_clientOrderId",
            width: "220px"
        },
        {
            title: t("ORDERS.STATUS.TITLE"),
            dataIndex: "status",
            key: "orders_status",
            render: (status: OrderStatus) => <OrdersStatus status={status} />,
            width: "220px"
        },
        {
            title: t("WAREHOUSES.WAREHOUSE_TITLE"),
            dataIndex: "warehouse",
            key: "orders_warehouse",
            render: (warehouse: Warehouse) => <>{warehouse.name}</>,
            width: "120px"
        },
        {
            title: t("GRAND_TOTAL_PRICE"),
            dataIndex: "grandTotal",
            key: "orders_grandTotal",
            render: (total: Amount) => <>{displayPrice(total)}</>,
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "orders_createdAt",
            render: (date: string) => <Date dateString={date} showTime />,
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            width: "120px",
        }
    ];

    const onDelete = async (row: OrderData) => {
        await invoiceService.removeOrder(document.id, row.id)
        reload()
    }

    return (
        <ExpandedTable<any>
            pageKey={"orders"}
            title="PROFORMAS.INCLUDED_ORDERS.TITLE"
            columns={columns}
            columnsConfig={convertTableColumnsToColumnConfigs(columns)}
            tableData={document.included_orders}
            isVirtualList={false}
            tableLoading={false}
            editableRowKey="id"
            isEditable={true}
            onDelete={document.status === "opened" || document.status === "invalid" ? onDelete : undefined}
        />
    );
};
