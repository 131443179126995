import {AxiosInstance} from "axios";
import API from "../../../utils/api";
import {
    PayerOrReceiverDetails,
    PayerReceiverFormValues, SellerDetails
} from "../../../models/payer-receiver-seller/payer.reveiver.seller";

export class AddressService {
    api: AxiosInstance = API;

    updateSellerAddress(documentId: number, values: SellerDetails): Promise<SellerDetails> {
        return this.api.post<SellerDetails>("documents/" + documentId + "/seller", values).then(x => x.data);
    }

    updateBillingAddress(documentId: number, values: PayerReceiverFormValues): Promise<PayerOrReceiverDetails> {
        return this.api.post<PayerOrReceiverDetails>("documents/" + documentId + "/billing", values).then(x => x.data);
    }

    updateShippingAddress(documentId: number, values: PayerReceiverFormValues): Promise<PayerOrReceiverDetails> {
        return this.api.post<PayerOrReceiverDetails>("documents/" + documentId + "/shipping", values).then(x => x.data);
    }
}

export const addressService = new AddressService();
