import { Amount } from "../../prices/price";
import { OrderAccount } from "../orders-list/order";
import { Warehouse } from "../../warehouses/warehouse";
import { Country } from "../../countries/country";
import { PayerOrReceiverDetails, SellerDetails } from "../../payer-receiver-seller/payer.reveiver.seller";
import { OrderStatus } from "../../../stores/common/common.store";
import {Agent} from "../../accounts/customer-account/wholesale-account/wholesale.account";
import {DocumentStatus} from "../../documents/status";
import {Container} from "../../containers/containers";
import {NotCompletedOrderSummary} from "../orders-cart/orders-checkout/orders.checkout.not_completed.order";

export interface OrderItem {
    id: number;
    articleId: number,
    articleName: string;
    description: string;
    price: Amount;
    priceType: PriceType;
    qtyRequested: number;
    qty: number;
    totalPrice: Amount;
    clientPrice: Amount;
    salePercentage: number;
}
export type PriceType = "custom_price" | "custom_discount" | "client_price" | "minimal_price" | "increased";

export interface OrderDetailsItem {
    id: number;
    articleName: string;
    articleDescription: string;
    price?: Amount;
    priceType: PriceType;
    qtyRequested: number;
    quantity: number;
    totalPrice?: Amount;
    salePercentage: number;
}

export interface OrderCommentUser {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: string;
}

export interface OrderComment {
    id: number;
    user: OrderCommentUser;
    message: string;
    createdAt: string;
}

export interface OrderDataConditions {
    clientBusinessName: string;
    clientDiscount: number;
    clientName: string;
    clientNumber: string;
    clientPaymentTerms: number;
    clientType: string;
    country: Country;
    customsCode: string;
    regCode: string;
    vat: string;
    clientShipmentCondition: string;
    useBackorder: boolean;
}

export interface OrderDocument {
    id: number;
    docNumber: string;
    status: DocumentStatus
}

export interface OrderDocumentWithType extends OrderDocument {
    type: Document;
}

export interface OrderWarning {
    id: number;
    type: string;
    reason: string;
    message: string;
    createdAt: string;
}

export type OrderType = "wholesale" | "wholesale_dropship" | "retail";

export interface OrderPlatform {
    id: number;
    name: string;
}

export interface OrderData {
    id: number;
    deliveryOrder: OrderData;
    deliveryOrderForOrders: OrderData[];
    parentId: number;
    children: OrderData[];
    orderType: OrderType;
    invoice: null | { id: number; number: number };
    sourceId: string;
    platform: OrderPlatform;
    externalSystemOrderId: string;
    mergedOrderId: number | null;
    items: OrderItem[];
    clientOrderId: string;
    conditions: OrderDataConditions;
    procedureType: string;
    account: OrderAccount;
    agent: Agent;
    discount: string;
    warehouse: Warehouse;
    container?: Container;
    status: OrderStatus;
    itemsQty: number;
    seller: SellerDetails;
    receiver: PayerOrReceiverDetails;
    payer: PayerOrReceiverDetails;
    comments: OrderComment[];
    vatPercent: number;
    syncedWithPrimarySystem: boolean;
    locked: boolean;
    lockedUntil: string | null;
    lockReason: string | null;
    warnings: boolean;
    hasComments: boolean;
    documents: OrderDocuments;
    orders?: number[]
    syncAllowed: boolean;
    paymentAggregator: string;
    transactionID: string;
    WMSTaskNumber: string;
    salePercentage: number;
    completedAt: string;
    stockSummary: NotCompletedOrderSummary;
    backorderSummary: NotCompletedOrderSummary;
}

export interface OrderMetaWarehouseAvailable {
    name: string;
    available: boolean;
    stock: number;
}

export type OrderMetaWarehousesAvailable = {[K: string]: OrderMetaWarehouseAvailable};

interface ShippingCourier extends Amount {
    name: string;
}

export interface OrderMeta {
    iAv: {[K: number]: OrderMetaWarehousesAvailable};
    wAv: OrderMetaWarehousesAvailable;
    couriers: ShippingCourier[];
}

export enum Document {
    PROFORMA= "proforma",
    INVOICE = "invoice",
    PACKING_LIST = "packing_list",
    MERGED_INVOICE = "merged_invoice",
    CREDIT_INVOICE = "credit_invoice",
    RETURN = "return"
}

export type OrderDocuments = Partial<Record<Document, OrderDocument[]>>

// export interface OrderDocuments {
//     proforma: OrderDocument[],
//     invoice: OrderDocument[],
//     packingList: OrderDocument[],
//     mergedInvoice: OrderDocument[]
// }
