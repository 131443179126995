import {MenuProps} from "antd";
import {ArticleFile} from "../../../../../models/articles/article.files";
import {Translate} from "../../../../../hooks/translate.hook";
import dayjs from "dayjs";

const sortFn = (f1: ArticleFile, f2: ArticleFile) => {
    if (f1.type === f2.type) {
        return 0;
    }
    return f1.type > f2.type ? 1 : -1;
}

export const generateItems = (
    setExportModalShown: (value: boolean) => void,
    getDownloadUrl: (type: string) => string,
    list: ArticleFile[],
    t: Translate
): MenuProps['items'] => {
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: t("EXPORT.TITLE"),
            onClick: () => setExportModalShown(true)
        }
    ];

    list.sort(sortFn).forEach(file => {
        items.push({
            key: file.type,
            label: <a href={getDownloadUrl(file.type)}>
                {t('ARTICLE.DOWNLOAD.' + file.type.toUpperCase()) + ' (' + dayjs(file.date).format('DD.MM.YYYY HH:MM') + ')'}
            </a>,
        })
    })

    return items;
}