import React from "react";
import {Col, Form, Row, Select} from "antd";
import {config} from "../config";
import {useTranslate} from "../../../../../hooks/translate.hook";

export interface ProductFieldsProps {
    loading: boolean;
    courierName: string;
}

export const ProductFields: React.FC<ProductFieldsProps> = ({loading, courierName}) => {

    const t = useTranslate();

    if (!config[courierName]?.useProduct) {
        return null;
    }

    const products = config[courierName].products!;

    return (
        <Row>
            <Col span={24}>
                <Form.Item
                    name="product"
                    label="Product"
                    rules={[
                        {
                            required: true,
                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                        },
                    ]}
                >
                    <Select disabled={loading}>
                        {Object.keys(products).map(product =>
                            <Select.Option value={product}>{products[product]}</Select.Option>)}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    )
}