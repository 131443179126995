import React, {useState} from "react";
import {observer} from "mobx-react";
import {Col, DatePicker, Form, Modal, Row} from "antd";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../../utils/store";
import {OrderDataStoreContext} from "../../../order.data.store";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../../../models/core/response";
import dayjs from "dayjs";

interface OrderDataHeaderModalProps {
    visible: boolean,
    onClose: () => void
}

export const OrderDataSetDateModal: React.FC<OrderDataHeaderModalProps> = observer(({visible, onClose}) => {
    const t = useTranslate();
    const store = useRequiredStore(OrderDataStoreContext);

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={t("ORDER.CHANGE_DATE.TITLE")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"order_data"}
                initialValues={{
                    date: dayjs(store.order?.completedAt)
                }}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                            store.editDate(values.date)
                            .then(() => {
                                form.resetFields();
                                return onClose();
                            }, (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                            .then(() =>
                                // onClose(),
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="date"
                            label={t("DATE")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <DatePicker placeholder={t("DATE")}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
});
