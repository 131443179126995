import React, {useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Button, Card, Col, Form, notification, Popconfirm, Row, Space, Tooltip, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserDataFMSCart} from "./user.data.fms.card";
import {UserDataWSCart} from "./user.data.ws.card";
import {createAccountRequest, createDefaultValues} from "../../../../../models/users/user/user.data.helper";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../../models/core/response";
import {UserAccess} from "../../../../../models/users/user/extended.user";
import {useRequiredStore} from "../../../../../utils/store";
import {UserDataStoreContext} from "../user.data.store";

interface UserDataCardProps {
    type: "employee" | "retail" | "wholesale" | "customer",
    access: UserAccess,
}

const typesConfig: {[K: string]: {component: React.FC<any>, editable: boolean}} = {
    employee: {component: UserDataFMSCart, editable: true},
    wholesale: {component: UserDataWSCart, editable: false},
}

export const UserDataCard: React.FC<UserDataCardProps> = ({type, access}) => {
    const store = useRequiredStore(UserDataStoreContext);

    const t = useTranslate();

    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const openSuccessNotification = (type: string, message: string): void => {
        notification[type === "success" ? "success" : "error"]({
            message: type === "success" ? "Success" : "Error",
            description: message
        });
    }

    const typeConfig = typesConfig[type];

    if (!typeConfig) {
        return <>ERROR</>;
    }

    const OptionFields = typeConfig.component;

    return (
        <Card
            // account
            title={<Typography.Title level={5}>
                [{access.account.branch!.id.toUpperCase()}] {type.beautify() + " Profile"} {access.primary ? (<>(default)</>) : null}
            </Typography.Title>}
            style={{marginBottom: 20}}
            extra={
                <Space size={[10, 10]}>
                    {!access.primary ? (
                        <Popconfirm
                            title={t("ARE_YOU_SURE")}
                            onConfirm={() => store.setPrimaryAccount(access)}
                            placement="top"
                            okText={t("YES")}
                            cancelText={t("NO")}
                        >
                            <Tooltip placement="topRight" title={t("DEFAULT")}>
                                <Button type="default" icon={<FontAwesomeIcon icon={["fas", "check"]}/>}/>
                            </Tooltip>
                        </Popconfirm>
                    ) : null}
                    <Popconfirm
                        title={t("ARE_YOU_SURE")}
                        onConfirm={() => store.deleteAccess(access)}
                        placement="top"
                        okText={t("YES")}
                        cancelText={t("NO")}
                    >
                        <Tooltip placement="topRight" title={t("REMOVE")}>
                            <Button type="default" icon={<FontAwesomeIcon icon={["fas", "times"]}/>}/>
                        </Tooltip>
                    </Popconfirm>
                </Space>
            }
        >
            {typeConfig.editable ? (<Form
                size={"middle"}
                form={form}
                layout="vertical"
                name={type + "_form"}
                initialValues={createDefaultValues(access, type, access.systemAccesses)}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        store.editAccount(createAccountRequest(type)(values), type, ""+ access.account.id!)
                            .then(
                                () => {
                                    return openSuccessNotification("success", "Account edited successfully")
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    setLoading(false);
                                    openSuccessNotification("error", e.message)
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                            .then(() =>
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row justify={"space-between"} gutter={[30, 0]} style={{width: "100%"}} align={"top"}>
                    <OptionFields defaultSelectedSystems={access.systemAccesses ? access.systemAccesses.map(item => item.system.id) : []} form={form} type={type} disabled={loading}/>
                    <Col span={24}>
                        <Form.Item style={{marginBottom: 0}}>
                            <Button
                                loading={loading}
                                style={{display: "block", marginLeft: "auto"}}
                                htmlType={"submit"}
                                type={"primary"}
                            >
                                {t("SAVE")}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>) : (
                <OptionFields account={access.account}/>
            )}
        </Card>
    );
}
