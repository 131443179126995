import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";
import {SmartLoggerData, LoggerFilters, LoggerData} from "../../models/logger/logger.data";

export class LoggerService {
    api: AxiosInstance = API;

    getData(request: PaginatedRequest, filters: LoggerFilters): Promise<PaginatedResult<LoggerData[]>> {
        if (filters.additionalFilters) {
            Object.keys(filters.additionalFilters).forEach(key => {
                request.addFilter(key, filters.additionalFilters![key]);
            });
        }

        if (filters.relationId && filters.relationType) {
            request.addFilter('relationId', String(filters.relationId));
            request.addFilter("relationType", filters.relationType);
        }

        return this.api.get<PaginatedResult<LoggerData[]>>(
            "logger",
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }


    getSmartLoggerData(request: PaginatedRequest, defaultFilters?: {[k: string]: any}): Promise<PaginatedResult<SmartLoggerData>> {
        if (defaultFilters) {
            Object.keys(defaultFilters).forEach(key => {
                request.addFilter(key, defaultFilters[key])
            });
        }
        return this.api.get<PaginatedResult<SmartLoggerData>>("smart-logger", {
            params: request.toParams()
        }).then(x => x.data);
    }

    getEntities(): Promise<string[]> {
        return this.api.get<string[]>("smart-logger/entities").then(x => x.data)
    }

    getActions(): Promise<string[]> {
        return this.api.get<string[]>("smart-logger/actions").then(x => x.data);
    }

}

export const loggerService = new LoggerService();
