import {AxiosInstance} from "axios";
import API from "../../../utils/api";
import {DocumentFile, UploadRequest} from "../../../models/documents/common/files";

export class FilesService {
    private api: AxiosInstance = API;

    uploadFile(documentId: number, dto: UploadRequest): Promise<DocumentFile> {

        const formData = new FormData();

        formData.append('file', dto.file);
        formData.append('comment', dto.comment);

        return this.api
            .post('documents/' + documentId + '/files', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(x => x.data);
    }

    getFiles(documentId: number): Promise<DocumentFile[]> {
        return this.api
            .get('documents/' + documentId + '/files')
            .then(x => x.data);
    }

    deleteFile(fileId: number): Promise<void> {
        return this.api
            .delete('documents/files/' + fileId)
            .then(x => x.data);
    }
}

export const filesService = new FilesService();