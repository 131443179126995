import React, {PropsWithChildren, useEffect, useState} from "react";
import {ArticleDataImagePortal} from "../article.data.image.portal";
import "./article.image.modal.scss";
import "wicg-inert";

interface ArticleImageModalProps {
    opened: boolean,
    onClose: () => void
}
export const ArticleImageModal: React.FC<PropsWithChildren<ArticleImageModalProps>> = ({opened, onClose, children}) => {
    const [active, setActive] = useState<boolean>(false);
    const backdrop = React.useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const { current } = backdrop;
        const transitionEnd = () => setActive(opened);
        const keyHandler = (e: KeyboardEvent) => [27].indexOf(e.which) >= 0 && onClose();
        const clickHandler = (e: MouseEvent) => e.target === current && onClose();

        if (current) {
            current.addEventListener("transitionend", transitionEnd);
            current.addEventListener("click", clickHandler);
            window.addEventListener("keyup", keyHandler);
        }
        if (opened) {
            window.setTimeout(() => {
                if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                }
                setActive(opened);
                document.querySelector("#root")!.setAttribute("inert", "true");
            }, 10);
        }

        return () => {
            if (current) {
                current.removeEventListener("transitionend", transitionEnd);
                current.removeEventListener("click", clickHandler);
            }
            document.querySelector("#root")!.removeAttribute("inert");
            window.removeEventListener("keyup", keyHandler);
        };
    }, [opened, onClose]);


    return (
        <>
            {(opened || active) && (
                <ArticleDataImagePortal className="modal-portal">
                    <div ref={backdrop} className={(active && opened) ? "backdrop active" : "backdrop"}>
                        {children}
                        {/*<div className="modal-content">{children}</div>*/}
                    </div>
                </ArticleDataImagePortal>
            )}
        </>
    );
}
