import {AxiosInstance} from "axios";
import API, {BlobAndFilename, getBlobAndFileName} from "../../../utils/api";
import dayjs from "dayjs";

export class DownloadService {
    api: AxiosInstance = API;

    downloadAsPDFByDateRange(from: dayjs.Dayjs, until: dayjs.Dayjs, type: string, accountId: number): Promise<BlobAndFilename> {
        return this.api
            .get("documents/pdf-archive/by-date-range", {
                responseType: "blob",
                params: {
                    type: type,
                    from: from.format("YYYY-MM-DD"),
                    until: until.format("YYYY-MM-DD"),
                    account: accountId
                }
            })
            .then(getBlobAndFileName);
    }

    downloadAsPDFBySelectedIds(documentIds: number[]): Promise<BlobAndFilename> {
        return this.api.get("documents/pdf-archive/by-ids", {
                responseType: "blob",
                params: {
                    documents: documentIds
                }
            })
            .then(getBlobAndFileName);
    }
}

export const downloadService = new DownloadService();
