import React from "react";
import {ReactComponent as ErrorIcon} from "./order_warning_icon.svg";

interface OrderListWarningIconProps {
    fill: string
    style?: any
    onClick: () => void
}
export const OrderListWarningIcon: React.FC<OrderListWarningIconProps> = ({style = {}, fill, onClick}) => {
    return <ErrorIcon onClick={onClick} fill={fill} style={style} />
}

