export class Node {

    public value: string;

    public parent: Node|null = null;

    public children: Node[] = [];

    constructor(value: string, parent: Node|null = null) {
        this.value = value;
        this.parent = parent;
    }

    addChild(child: Node) {
        this.children.push(child);
    }
}