import React, {useState} from "react";
import {Form, Modal} from "antd";
import {PlannerTask} from "../../../../../models/planner/planner";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {plannerService} from "../../../../../services/planner/planner.service";
import {UIInputWidgetDepartment} from "../../../ui/input/widget/department/ui.input.widget.department";

interface PlannerTasksAssigneeAddModalProps {
    onClose: () => void;
    task: PlannerTask;
}

export const PlannerTasksDepartmentAddModal: React.FC<PlannerTasksAssigneeAddModalProps> = ({task, onClose}) => {

    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const initialValues = {}

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("PLANNER.TASK.ADD_DEPARTMENT.TITLE")}
            okText={t("OK")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={onClose}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name={"planner_task_form"}
                initialValues={initialValues}
                onFinish={() => form
                    .validateFields()
                    .then(async values => {
                        setLoading(true);
                        try {
                            await plannerService.addDepartment(task.id, values);
                            onClose();
                        } catch (e: any) {
                            form.setFields(e.response?.data?.fields);
                        } finally {
                            setLoading(false);
                        }
                    })
                }
            >
                <Form.Item
                    label={t("PLANNER.TASK.FIELDS.DEPARTMENTS")}
                    name="department"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <UIInputWidgetDepartment />
                </Form.Item>
            </Form>
        </Modal>
    )
}
