import React, {useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Alert, Button, Card, Descriptions, Space, Typography} from "antd";
import {
    Claim,
    ClaimItem,
    claimItemAcceptCardColor,
    ClaimItemAcceptStatus,
    ClaimItemReject,
    EditClaimItem,
} from "../../../../models/claims/claims";
import Date from "../../../date/date";
import {YesNo} from "../../../common/yes-no/yes-no";
import {Link} from "react-router-dom";
import {Value} from "../../../common/value/value";
import {ClaimPartsImages} from "./images/claim.parts.images";
import {ClaimsItemAcceptStatus, ClaimsItemType} from "../../status/claim.item.status";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {displayPrice} from "../../../../models/prices/price.helper";
import {claimService} from "../../../../services/claims/claims.service";
import {ClaimRejectModal} from "../../common/claim.reject.modal";
import {notifications} from "../../../../services/notifications/notifications";
import {ClaimsEditModal} from "../../edit/claims.create.modal";
import {SplitModal} from "../../actions/split/split.modal";

enum RejectModalState {
    closed,
    requestClarification,
    reject
}

export interface ClaimPartProps {
    claim: Claim;
    item: ClaimItem;
    number?: number;
    onUpdate: () => void;
}

export const ClaimPart: React.FC<ClaimPartProps> = ({claim, item, number, onUpdate}) => {
    const t = useTranslate();

    const [showExtra, setShowExtra] = useState<boolean>(false);

    const [rejectModalState, setRejectModalState] = useState<RejectModalState>(RejectModalState.closed);

    const [editModalShown, setEditModalShown] = useState<boolean>(false);

    const [splitModalShown, setSplitModalShown] = useState<boolean>(false);

    const onComplete = async (value: ClaimItemReject) => {
        if (rejectModalState === RejectModalState.reject) {
            await claimService.rejectItem(item.id, value);
        } else if (rejectModalState === RejectModalState.requestClarification) {
            await claimService.needClarificationItem(item.id, value);
        }
        onUpdate();
        notifications.successfully();
    }

    const onEditCompleted = async () => {
        onUpdate();
        setEditModalShown(false);
    }

    const accept = async () => {
        await claimService.acceptItem(item.id);
        onUpdate();
        notifications.successfully();
    }

    return (
        <Card
            title={
                <>{number ? (number + ": ") : null}
                    <Link className={"link"} to={"/articles/" + item.article.name}>{item.article.name}</Link></>
            }
            headStyle={{backgroundColor: claimItemAcceptCardColor[item.status] + "12"}}
            style={{borderLeft: "5px solid " + claimItemAcceptCardColor[item.status]}}
            extra={
                <Space direction={"horizontal"} size={[10, 10]}>
                    <ClaimsItemType type={item.type} />
                    <ClaimsItemAcceptStatus status={item.status} />
                </Space>
            }
            actions={[
                <Button
                    size={"middle"}
                    icon={<FontAwesomeIcon icon={["fas", "check-circle"]} title={"sdf"} color={"green"} />}
                    onClick={accept}
                >
                    {t('CLAIMS.CLAIM.ACCEPT')}
                </Button>,
                <Button
                    size={"middle"}
                    icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} title={"sdf"} color={"red"} />}
                    onClick={() => setRejectModalState(RejectModalState.reject)}
                >
                    {t('CLAIMS.CLAIM.REJECT')}
                </Button>,
                <Button
                    size={"middle"}
                    icon={<FontAwesomeIcon icon={["fas", "question-circle"]} title={"sdf"} color={"#777"} />}
                    onClick={() => setRejectModalState(RejectModalState.requestClarification)}
                >
                    {t('REQUEST_MORE_INFO')}
                </Button>,
                <Button
                    size={"middle"}
                    icon={<FontAwesomeIcon icon={["fas", "info"]} title={"sdf"} color={"#777"} />}
                    onClick={() => setEditModalShown(true)}
                >
                    {t('EDIT')}
                </Button>,
                <Button
                    size={"middle"}
                    icon={<FontAwesomeIcon icon={["fas", "info"]} title={"sdf"} color={"#777"} />}
                    onClick={() => setSplitModalShown(true)}
                >
                    {t('CLAIMS.SPLIT')}
                </Button>
            ]}
        >
            <Space size={[25, 25]} direction={"vertical"}>
                {ClaimItemAcceptStatus.CLARIFICATION_AWAITING === item.status ? (
                    <Alert
                        message={t("CLAIMS.ITEM.REJECT_REASON." + item.rejectionReason.toUpperCase())}
                        showIcon
                        description={item.rejectionExplanation}
                        type="warning"
                        action={
                            <Button size="small" danger onClick={() => setEditModalShown(true)}>
                                {t('ADD_MORE_INFO')}
                            </Button>
                        }
                    />
                ) : null}

                {ClaimItemAcceptStatus.REJECTED === item.status ? (
                    <Alert
                        message={t("CLAIMS.ITEM.REJECT_REASON." + item.rejectionReason.toUpperCase())}
                        showIcon
                        description={item.rejectionExplanation}
                        type="error"
                    />
                ) : null}

                <Descriptions
                    title={t('GENERAL_INFO')}
                    bordered
                    layout={"horizontal"}
                    size={"small"}
                    column={1}
                >
                    <Descriptions.Item label={t('PRICE')}>
                        {displayPrice(item.priceByItem)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('QUANTITY_LONG')}>
                        {item.qty} pcs.
                    </Descriptions.Item>
                    <Descriptions.Item label={t('INVOICE.TITLE')}>
                        {item.invoiceDocument ? (
                            <Link className={"link"} to={"/merged-invoices/" + item.invoiceDocument.id}>{item.invoiceDocument.docNumber}</Link>
                        ) : <>-</>}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('INVOICE.SALE.TITLE')}>
                        {item.saleDocument ? (
                            <Link className={"link"} to={"/invoices/" + item.saleDocument.id}>{item.saleDocument.docNumber}</Link>
                        ) : <>-</>}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('INVOICE.CREDIT.NUMBER_FEBEST')}>
                        {item.creditInvoiceDocument ? (<Link className={"link"} to={"/credit-invoices/" + item.creditInvoiceDocument.id}>{item.creditInvoiceDocument.docNumber}</Link>) : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('CLAIMS.CLAIM.CRASHED_BOX')}>
                        <YesNo value={item.hasCrashedBoxRetention} />
                    </Descriptions.Item>
                    <Descriptions.Item label={t('CLAIMS.CLAIM.BAD_PACKING')}>
                        <YesNo value={item.hasBadPackingRetention} />
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions
                    title={t('INVOICES.INVOICE_DATA.TITLE')}
                    bordered
                    layout={"horizontal"}
                    size={"small"}
                    column={1}
                >
                    <Descriptions.Item label={t('INVOICE.NUMBER_FEBEST')}>
                        <Link className={"link"} to={"/merged-invoices?filter=" + encodeURIComponent('number__number=' + item.invoiceDocumentNumber + '|%')}>{item.invoiceDocumentNumber}</Link>
                    </Descriptions.Item>
                    <Descriptions.Item label={t('INVOICES.INVOICE.DATE')}>
                        <Date dateString={item.invoiceDate} showTime />
                    </Descriptions.Item>
                </Descriptions>
                {showExtra ? (
                    <>
                        <Descriptions
                            title={<>{t('CLAIMS.CLAIM.FITTED_TO')}  <Button size={"small"} onClick={() => setShowExtra(false)}>{t('CLAIMS.CLAIM.HIDE_CLARIFICATION')}</Button></>}
                            bordered
                            layout={"horizontal"}
                            size={"small"}
                            column={2}
                        >
                            <Descriptions.Item label={t('BRAND')} span={2}>
                                <Value value={item.carBrand} />
                            </Descriptions.Item>
                            <Descriptions.Item label={t('MODEL')} span={2}>
                                <Value value={item.carModel} />
                            </Descriptions.Item>
                            <Descriptions.Item label={t('CLAIMS.CLAIM.CLARIFICATION')} span={2}>
                                <Value value={item.carModelClarification} />
                            </Descriptions.Item>
                            <Descriptions.Item label={t('YEAR')} span={2}>
                                <Value value={item.carModelYear} />
                            </Descriptions.Item>
                            <Descriptions.Item label={t('CLAIMS.CLAIM.FITTING_DATE')}>
                                <Date dateString={item.fittingDate} showTime />
                            </Descriptions.Item>
                            <Descriptions.Item label={t('MILLAGE')}>
                                <Value value={item.fittingOnMillage} />
                            </Descriptions.Item>
                            <Descriptions.Item label={t('CLAIMS.CLAIM.PROBLEM_DATE')}>
                                <Date dateString={item.problemDate} showTime />
                            </Descriptions.Item>
                            <Descriptions.Item label={t('MILLAGE')}>
                                <Value value={item.problemOnMillage} />
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                ): (
                    <Button type={"primary"} size={"small"} onClick={() => setShowExtra(true)}>{t('CLAIMS.CLAIM.SHOW_CLARIFICATION')}</Button>
                ) }
                <div>
                    <Typography.Title level={4} style={{fontSize: '16px'}}>{t('DESCRIPTION')}</Typography.Title>
                    {item.description}
                </div>
                <ClaimPartsImages item={item} />
            </Space>

            <ClaimRejectModal
                title={t('SELECT_REASON')}
                visible={rejectModalState !== RejectModalState.closed}
                onComplete={onComplete}
                onClose={() => setRejectModalState(RejectModalState.closed)}
            />

            {editModalShown ? (
                <ClaimsEditModal
                    onComplete={onEditCompleted}
                    onCancel={() => setEditModalShown(false)}
                    item={item}
                />
            ) : null}
            {splitModalShown ? (
                <SplitModal
                    onClose={() => setSplitModalShown(false)}
                    onComplete={() => onUpdate()}
                    claim={claim}
                    item={item}
                />
            ) : null}
        </Card>
    );
}