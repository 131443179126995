import React from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { Space, Typography } from "antd";
import { displayPrice } from "../../../../models/prices/price.helper";
import { MetaProps } from "../../../common/graph/graph";
import { ReportPaymentsStats } from "../../../../models/reports/reports";

export const ReportsMainPageStats: React.FC<MetaProps<ReportPaymentsStats>> =
    observer(({ meta: { total, currentMonthTotal, currencyCode } }) => {
        const t = useTranslate();

        return (
            <Space direction="vertical" size={[0, 0]}>
                <Typography.Paragraph className="reports-total-amount">
                    {t("REPORTS.SOLD.TOTAL")} - {displayPrice(total)}
                </Typography.Paragraph>
                <Typography.Paragraph className="reports-total-amount">
                    {t("REPORTS.SOLD.THIS_MONTH")} -{" "}
                    {displayPrice(currentMonthTotal)}
                </Typography.Paragraph>
            </Space>
        );
    });
