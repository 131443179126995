import React, { useState } from "react";
import { ActionProps } from "../errors.list.actions";
import { orderDataService } from "../../../../services/orders/order-data/order.data.service";
import { Button, notification, Tooltip } from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface DocumentsExchangeHandlingActionProps extends ActionProps {}
export const DocumentsExchangeHandlingAction: React.FC<DocumentsExchangeHandlingActionProps> = ({ error }) => {
    const t = useTranslate();
    const [resetting, setResetting] = useState<boolean>(false);

    const reset = async () => {
        setResetting(true);
        try {
            await orderDataService.syncOrder(error.relationId as any);
            setResetting(false);
            notification.success({
                message: t("SUCCESS"),
                description: t("ORDERS.ORDER.RESET_SUCCESS"),
                duration: 2,
            });
        } catch (e: any) {
            setResetting(false);
            notification.error({
                message: t("ERROR"),
                description: t("ORDERS.ORDER.RESET_ERROR"),
                duration: 2,
            });
        }
    };

    return (
        <div style={{ width: "100%", display: "flex" }}>
            <Tooltip placement="topRight" title={t("ORDERS.ORDER.UPDATE_DOCS")}>
                <Button
                    disabled={resetting}
                    className={"btn"}
                    onClick={reset}
                    type="default"
                    size={"small"}
                    icon={<FontAwesomeIcon icon={["fas", "sync-alt"]} />}
                />
            </Tooltip>
        </div>
    );
};
