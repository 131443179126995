import React from "react";
import {StatusTag} from "../../../../common/status/status";
import {
    PropertyInventoryTaskPropertyStatus,
    propertyStatusColor
} from "../../../../../models/property/property";

interface InventoryTaskInventoryStatusProps {
    status: PropertyInventoryTaskPropertyStatus;
    large?: boolean;
}
export const InventoryTaskInventoryStatus: React.FC<InventoryTaskInventoryStatusProps> = ({status, large = false}) => {
    return (
        <StatusTag<PropertyInventoryTaskPropertyStatus> status={status} colorsMap={propertyStatusColor} large={large} prefix="PROPERTY.INVENTORY_TASK_PROPERTY.STATUS"/>
    )
}
