import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {Button, Space, Tag} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import {ColumnsType} from "antd/es/table";
import {
    MonthlyStatement,
    MonthlyStatementItem,
    MonthlyStatementItemStatus
} from "../../../../models/accounting/monthly-statements";
import i18n from "i18next";
import {ColumnsConfig, convertTableColumnsToColumnConfigs} from "../../../../utils/table";
import {FilterDataHandler} from "../../../../models/filter/filter.data.handler";
import {monthlyStatementService} from "../../../../services/accounting/monthly-statement.service";
import {columnsFactory} from "./statements-view.items.columns";
import {useParams} from "react-router-dom";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {FieldGroupConfig} from "../../../../models/filter/filter.group";
import {filterGroupsFactory} from "./statements-view.items.filter-groups";
import {FilterButtonConfig} from "../../../../models/filtered-table/filtered.table.props";
import {FieldGroupSnapshot} from "../../../../models/filter/filter.field.snapshot";
import {notifications} from "../../../../services/notifications/notifications";
import {Export} from "../../../common/export/export";
import {PageHeader} from "@ant-design/pro-components";

export enum FilterButtonsTypes {
    OKAY = "okay",
    NOT_EXISTS = "not_exists",
    NOT_OKAY = "not_okay",
    NOT_FOUND = "not_found",
    RETAIL = "retail",
    SKIPPED = "skipped",
    DIFF_MONTH = "diff_month"
}

export const AccountingMonthlyStatementsView: React.FC<ComponentPropsFromRoute> = ({name}) => {

    const { id } = useParams<{ id: string }>();

    const t = useTranslate();

    const [disabled, setDisabled] = useState<boolean>(false);

    const dataHandler: FilterDataHandler<MonthlyStatementItem> = useMemo(() => new FilterDataHandler(request => monthlyStatementService.getAllItemsForStatement(parseInt(id!), request)), []);

    const checkAction = useCallback((id: number) => {
        setDisabled(true);
        monthlyStatementService.recheck(id).then(item => {
            notifications.message(
                item.status === MonthlyStatementItemStatus.OK ? "success" : "error",
                "ACCOUNTING.STATEMENTS.ITEM.NEW_STATUS",
                {"NEW_STATUS": t("ACCOUNTING.STATEMENTS.ITEM.STATUS." + item.status.toUpperCase())}
            );
            dataHandler.reloadWithLastRequest();
            setDisabled(false);
        });
    }, []);

    const skipAction = useCallback((id: number) => {
        setDisabled(true);
        monthlyStatementService.skip(id).then(item => {
            notifications.successfully();
            dataHandler.reloadWithLastRequest();
            setDisabled(false);
        });
    }, []);

    const columns: ColumnsType<MonthlyStatementItem> = useMemo(() => columnsFactory(t, checkAction, skipAction, disabled), [i18n.language, disabled]);
    const columnsConfigs: ColumnsConfig = useMemo(() => convertTableColumnsToColumnConfigs<MonthlyStatementItem>(columns), [i18n.language])
    const fcgConfigs: FieldGroupConfig[] = useMemo(() => filterGroupsFactory(t), []);

    const [statement, setStatement] = useState<MonthlyStatement | null>(null);

    const [exportModalShown, setExportModalShown] = useState<boolean>(false);

    const filterButtonConfigs: FilterButtonConfig<FilterButtonsTypes>[] = [
        {
            type: FilterButtonsTypes.OKAY,
            text: "OKAY",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: MonthlyStatementItemStatus.OK
                        },
                    ],
                },
            ],
        },
        {
            type: FilterButtonsTypes.NOT_OKAY,
            text: "MISSMATCHES",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: [
                                MonthlyStatementItemStatus.DIFFERENT_DATE,
                                MonthlyStatementItemStatus.DIFFERENT_GRAND_TOTAL,
                                MonthlyStatementItemStatus.DIFFERENT_SUBTOTAL,
                                MonthlyStatementItemStatus.DIFFERENT_TAX,
                                MonthlyStatementItemStatus.DIFFERENT_1C_DATE,
                                MonthlyStatementItemStatus.DIFFERENT_1C_GRAND_TOTAL,
                                MonthlyStatementItemStatus.DIFFERENT_1C_SUBTOTAL,
                                MonthlyStatementItemStatus.DIFFERENT_1C_TAX,
                            ].join(","),
                        },
                    ],
                },
            ],
        },
        {
            type: FilterButtonsTypes.DIFF_MONTH,
            text: "ACCOUNTING.STATEMENTS.ITEM.STATUS.DIFFERENT_MONTH",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: MonthlyStatementItemStatus.DIFFERENT_MONTH
                        },
                    ],
                },
            ],
        },
        {
            type: FilterButtonsTypes.SKIPPED,
            text: "ACCOUNTING.STATEMENTS.ITEM.STATUS.SKIPPED",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: MonthlyStatementItemStatus.SKIPPED
                        },
                    ],
                },
            ],
        },
        {
            type: FilterButtonsTypes.RETAIL,
            text: "ACCOUNTING.STATEMENTS.ITEM.STATUS.RETAIL_INVOICE",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: [
                                MonthlyStatementItemStatus.RETAIL_INVOICE
                            ].join(",")
                        },
                    ],
                },
            ],
        },
        {
            type: FilterButtonsTypes.NOT_FOUND,
            text: "ACCOUNTING.STATEMENTS.ITEM.STATUS.INVOICE_NOT_FOUND",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: [
                                MonthlyStatementItemStatus.INVOICE_NOT_FOUND,
                                MonthlyStatementItemStatus.DOCUMENT_1C_NOT_FOUND,
                            ].join(",")
                        },
                    ],
                },
            ],
        },
        {
            type: FilterButtonsTypes.NOT_EXISTS,
            text: "ACCOUNTING.STATEMENTS.ITEM.STATUS.NOT_EXISTS",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: MonthlyStatementItemStatus.NOT_EXISTS
                        },
                    ],
                },
            ],
        }
    ];

    const defaultFilters: FieldGroupSnapshot[] = [
        {
            name: "statuses",
            snapshots: [
                {
                    rule: undefined,
                    name: "statuses",
                    value: [
                        MonthlyStatementItemStatus.DIFFERENT_DATE,
                        MonthlyStatementItemStatus.DIFFERENT_GRAND_TOTAL,
                        MonthlyStatementItemStatus.DIFFERENT_SUBTOTAL,
                        MonthlyStatementItemStatus.DIFFERENT_TAX
                    ].join(",")
                },
            ],
        }
    ]

    useEffect(() => {
        monthlyStatementService.get(parseInt(id!)).then(statement => setStatement(statement))
    }, [id])

    if (!statement) {
        return <LoadingSpin />;
    }

    return <Space direction="vertical" size={[20, 20]}>

        <PageHeader
            style={{padding: 0}}
            title={"Statement"}
            className="site-page-header"
            subTitle={<>Month: {statement.month}/{statement.year}</>}
            tags={<Tag color="blue">{statement.mismatches} (mismatches)</Tag>}
        />

        <Button
            onClick={() => setExportModalShown(true)}
            type={"primary"}
        >
            {t("REPORTS.DOWNLOAD")}
        </Button>

        <FilteredTable
            columns={columns}
            columnsConfigs={{columns: columnsConfigs, pageKey: name}}
            dataHandler={dataHandler}
            filterGroups={fcgConfigs}
            filterButtons={filterButtonConfigs}
            filters={defaultFilters}
        />

        <Export
            title={"DOWNLOAD.DO_DOWNLOAD"}
            isShown={exportModalShown}
            withBasicExportFields
            config={{
                link: '/api/v3/accounting/monthly-statement/' + id + '/items',
                query: dataHandler.lastRequest?.toParams()
            }}
            onClose={() => setExportModalShown(false)}
        />
    </Space>;
}