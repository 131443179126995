import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import { find } from "lodash";
import { Observer, observer } from "mobx-react";
import React, { useState } from "react";
import {useTranslate} from "../../hooks/translate.hook";
import { Link } from "react-router-dom";
import { Agent } from "../../models/accounts/customer-account/wholesale-account/wholesale.account";
import { Announcement, AnnouncementMessage } from "../../models/announcements/announcement";
import { FieldTypeName } from "../../models/filter/filter.field.type.name";
import { FieldGroupConfig } from "../../models/filter/filter.group";
import { Warehouse } from "../../models/warehouses/warehouse";
import { ComponentPropsFromRoute } from "../../routers/routers";
import { RootStoreContext } from "../../stores/root/root.store";
import { useRequiredStore } from "../../utils/store";
import FilteredTable from "../common/filtered-table/filtered.table";
import { AnnouncementsModal } from "./announcements-modal/announcements.modal";
import { AnnouncementsStore, AnnouncementsStoreContext } from "./announcements.store";
import Date from "../date/date";

interface AnnouncementsProps extends ComponentPropsFromRoute {}
export const Announcements: React.FC<AnnouncementsProps> = observer(({ name }) => {
    const {
        commonStore: { warehouses },
    } = useRequiredStore(RootStoreContext);
    const t = useTranslate();
    const [store] = useState(() => new AnnouncementsStore());

    const columns: any[] = [
        {
            title: t("ANNOUNCEMENTS.MESSAGE"),
            dataIndex: "messages",
            key: "messages",
            render: (translations: AnnouncementMessage[]) => {
                const locale = localStorage.i18nextLng ?? "en";
                const translation = find(translations, (item) => item.language === locale);
                return translation ? <span style={{ whiteSpace: "pre-wrap" }}>{translation.shortDescription}</span> : <></>;
            },
        },
        {
            title: t("ANNOUNCEMENTS.STARTED_AT"),
            dataIndex: "startedAt",
            key: "startedAt",
            render: (date: string) => <Date dateString={date} showTime />,
        },
        {
            title: t("ANNOUNCEMENTS.ENDED_AT"),
            dataIndex: "endedAt",
            key: "endedAt",
            render: (date: string) => <Date dateString={date} showTime />,
        },
        {
            title: t("ANNOUNCEMENTS.DUPLICATE_IN_CART"),
            dataIndex: "duplicateInCart",
            key: "duplicateInCart",
            render: (item: boolean) => t(item ? "YES" : "NO"),
        },
        {
            title: t("CLIENT.NUMBER"),
            dataIndex: "account",
            key: "account",
            render: (account: { id: number; agent: Agent }) =>
                account ? <Link to={"/clients/" + account.agent.number}>{account.agent.number}</Link> : null,
        },
        {
            title: t("CLIENT.NAME"),
            dataIndex: "account",
            key: "account",
            render: (account: { id: number; agent: Agent }) => (account ? <span>{account.agent.name}</span> : null),
        },
        {
            title: t("WAREHOUSES.TITLE"),
            dataIndex: "warehouses",
            key: "warehouses",
            render: (item: Warehouse[]) => <>{item ? (
                item.map((item) => item.name).join(", ")
            ): null}</>,
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            render: (_: any, item: Announcement) => (
                <>
                    <Tooltip placement="topRight" title={t("EDIT")}>
                        <Button
                            className="table-btn btn"
                            onClick={() => store.openEditModal(item)}
                            type="default"
                            icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                        />
                    </Tooltip>
                    <Observer>
                        {() => (
                            <AnnouncementsModal
                                item={item}
                                visible={store.isEditModalShown(item)}
                                onSuccess={(request, item) =>
                                    item ? store.updateAnnouncement(request, item) : Promise.reject()
                                }
                                onClose={store.closeEditModal}
                                locales={store.locales}
                            />
                        )}
                    </Observer>{" "}
                    <Popconfirm
                        title={t("ARE_YOU_SURE")}
                        onConfirm={() => store.removeAnnouncement(item)}
                        placement="left"
                        okText={t("YES")}
                        cancelText={t("NO")}
                    >
                        <Tooltip placement="topRight" title={t("REMOVE")}>
                            <Button
                                className="table-btn btn-remove btn"
                                type="default"
                                icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                            />
                        </Tooltip>
                    </Popconfirm>
                </>
            ),
        },
    ];

    const columnsConfigs: { title: string; key: string }[] = [
        {
            title: "ANNOUNCEMENTS.MESSAGE",
            key: "messages",
        },
        {
            title: "HIDDEN",
            key: "hidden",
        },
        {
            title: "ANNOUNCEMENTS.PAGE_NAME",
            key: "pageName",
        },
        {
            title: "CLIENT.NUMBER",
            key: "account",
        },
        {
            title: "WAREHOUSES.TITLE",
            key: "warehouses",
        },
    ];

    const filterGroups: FieldGroupConfig[] = [
        {
            name: "warehouses",
            visibleName: "WAREHOUSES.TITLE",
            fields: [
                {
                    name: "warehouses",
                    type: FieldTypeName.SELECT,
                    multiple: true,
                    choices: warehouses.map((warehouse) => ({
                        name: warehouse.name,
                        value: warehouse.id,
                    })),
                },
            ],
        },
        {
            name: "message",
            visibleName: "ANNOUNCEMENTS.MESSAGE",
            fields: [
                {
                    name: "message",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "hidden",
            visibleName: "HIDDEN",
            fields: [
                {
                    name: "hidden",
                    type: FieldTypeName.SELECT,
                    multiple: false,
                    choices: [
                        { value: "0", name: t("NO") },
                        { value: "1", name: t("YES") },
                    ],
                },
            ],
        },
        {
            name: "pageName",
            visibleName: "ANNOUNCEMENTS.PAGE_NAME",
            fields: [
                {
                    name: "pageName",
                    type: FieldTypeName.STRING,
                },
            ],
        },
    ];

    return (
        <AnnouncementsStoreContext.Provider value={store}>
            <Space size={[10, 10]} direction={"vertical"}>
                <FilteredTable
                    filterGroups={filterGroups}
                    dataHandler={store.dataHandler}
                    columns={columns}
                    columnsConfigs={{ columns: columnsConfigs, pageKey: name }}
                    buttons={[
                        <Button key={1} type="primary" onClick={store.openCreateModal} className={"filter-table-btn "}>
                            {t("ANNOUNCEMENTS.ADD")}
                        </Button>,
                    ]}
                />
                <AnnouncementsModal
                    visible={store.isCreateModalShown()}
                    onSuccess={store.createAnnouncement}
                    onClose={store.closeCreateModal}
                    locales={store.locales}
                />
            </Space>
        </AnnouncementsStoreContext.Provider>
    );
});
