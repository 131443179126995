import { observer } from "mobx-react";
import {Button, Descriptions, Dropdown, Menu, Row, Space, Typography} from "antd";
import React from "react";
import { useRequiredStore } from "../../../../../utils/store";
import { PackingListDataStoreContext } from "../packing-list.data.store";
import { Link } from "react-router-dom";
import { DocumentsStatus } from "../../../../common/documents/status/documents.status";
import Date from "../../../../date/date";
import { Export } from "../../../../common/export/export";
import {isAllowed} from "../../../../../utils/helpers";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {SourcesListModal} from "../../../common/sources/modal/sources-list/sources-list.modal";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";
import {useNavigate} from "react-router";
import {packingListService} from "../../../../../services/documents/packing-lists/packing-list.service";
import {downloadBlobFile} from "../../../../../utils/blob.download";

export const PackingListDataHeader = observer(() => {
    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);
    const store = useRequiredStore(PackingListDataStoreContext);
    const t = useTranslate();
    const navigate = useNavigate();

    const customDownload = () => {
        packingListService.customDownload(String(store.packingListData?.id)).then(
            res => downloadBlobFile(res.blob, res.filename)
        )
    }

    return (
        <>
            <PageHeader
                className="invoice-header"
                style={{ padding: 0 }}
                ghost={false}
                title={
                    <Typography.Title level={1} style={{ margin: 0 }}>
                        {t("PACKING_LIST") + " " + store.packingListData?.docNumber}
                    </Typography.Title>
                }
                onBack={() => {
                    navigate("/packing-lists");
                }}
                extra={<DocumentsStatus large status={store.packingListData!.status} />}
            >
                <Row justify={"space-between"} style={{marginBottom: 20}}>
                    <Space className={"invoice-data-header-buttons"} direction={"horizontal"} size={[20, 20]} wrap>
                        <Dropdown
                            placement={"bottomLeft"}
                            trigger={["click"]}
                            overlay={
                                <Menu>
                                    <Menu.Item key={0}>
                                        <a
                                            rel="noopener noreferrer"
                                            style={{ textAlign: "left" }}
                                            target="_blank"
                                            className={"ant-btn ant-btn-link"}
                                            href={"/documents/" + store.packingListData!.id}
                                        >
                                            {t("DOCUMENTS.FILES.SHOW_PDF")}
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key={1} style={{ padding: "4px 15px" }} onClick={store.openExportModal}>
                                        {t("DOWNLOAD.CSV_EXCEL")}
                                    </Menu.Item>
                                    <Menu.Item key={2} style={{ padding: "4px 15px" }} onClick={customDownload}>
                                        {t("DOWNLOAD.PACKING_LIST.CUSTOM_DOWNLOAD")}
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button> {t("ORDERS.ORDER.VIEW.PACKING_LIST")}</Button>
                        </Dropdown>
                        {store.packingListData?.invoiceDocument ? (
                            <Dropdown
                                placement={"bottomRight"}
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        <Menu.Item key={0}>
                                            <Link
                                                to={"/invoices/" + store.packingListData.invoiceDocument.id}
                                                style={{ textAlign: "left" }}
                                                className={"ant-btn ant-btn-link"}
                                            >
                                                {t("ORDERS.ORDER.VIEW.INVOICE")}
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key={1}>
                                            <a
                                                rel="noopener noreferrer"
                                                style={{ textAlign: "left" }}
                                                target="_blank"
                                                className={"ant-btn ant-btn-link"}
                                                href={"/documents/" + store.packingListData!.invoiceDocument.id}
                                            >
                                                {t("DOCUMENTS.FILES.SHOW_PDF")}
                                            </a>
                                        </Menu.Item>
                                        {/*<Menu.Item key={1} onClick={() => store.handleModal("export", true)}>*/}
                                        {/*    {t("DOWNLOAD.CSV_EXCEL")}*/}
                                        {/*</Menu.Item>*/}
                                    </Menu>
                                }
                            >
                                <Button> {t("ORDERS.ORDER.VIEW.INVOICE")}</Button>
                            </Dropdown>
                        ) : null}
                    </Space>

                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                <Menu.Item key={3} onClick={() => store.makeAsReadyToShip()}>
                                    Make as Ready to Ship (Demo)
                                </Menu.Item>
                                {isAllowed(attributes, "faker") ? (
                                    <Menu.Item key={3} onClick={() => store.setSourcesListShown(true)}>
                                        Show Sources List
                                    </Menu.Item>
                                ) : null}
                            </Menu>
                        }
                    >
                        <Button type={"primary"}>{t("ACTION")}</Button>
                    </Dropdown>

                </Row>
                <div className="data-content-wrapper">
                    <Descriptions
                        className="data-content-wrapper-descriptions"
                        size={"small"}
                        layout={"vertical"}
                        contentStyle={{ fontSize: "17px" }}
                        labelStyle={{ color: "#858585" }}
                        column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
                    >
                        {/*<Descriptions.Item label={t("ORDERS.ORDER.NUMBER")}>*/}
                        {/*    <Link className="link" to={"/orders/" + store.packingListData?.masterOrder.id}>*/}
                        {/*        {store.packingListData?.masterOrder.id}*/}
                        {/*    </Link>*/}
                        {/*</Descriptions.Item>*/}
                        {/*<Descriptions.Item*/}
                        {/*    label={t("PACKING_LIST")}*/}
                        {/*>*/}
                        {/*    {store.invoice?.mas.packingList ? store.invoice?.order.packingList.docNumber : null}*/}
                        {/*</Descriptions.Item>*/}
                        <Descriptions.Item label={t("CLIENT.NUMBER")}>
                            <Link
                                className="link"
                                to={"/clients/" + store.packingListData?.agent.number}
                            >
                                {store.packingListData?.agent.number} (
                                {store.packingListData?.agent.name})
                            </Link>
                        </Descriptions.Item>
                        <Descriptions.Item label={t("SHIPPING_WAREHOUSE")}>
                            {store.packingListData?.warehouse.name}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("WEIGHT_BRUTTO")}>
                            {store.packingListData?.totalGrossWeight} {t("KG")}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("PAYMENT_TERMS")}>
                            {store.packingListData?.paymentTerms}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("DATE")}>
                            <Date dateString={store.packingListData?.date!} showTime withSeconds />
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </PageHeader>
            {store.sourcesListShown ? (
                <SourcesListModal
                    documentId={store.packingListData!.id}
                    onClose={() => store.setSourcesListShown(false)}
                />
            ) : null}
            {store.packingListData ? (
                <Export
                    title={"ORDERS.ORDER.DOWNLOAD_CSV_PACKING_LIST"}
                    isShown={store.exportModalShown}
                    withBasicExportFields
                    config={{
                        link: "/api/v3/documents/packing-list/" + store.packingListData!.id,
                    }}
                    onClose={store.closeExportModal}
                />
            ) : null}
        </>
    );
});
