import React, {useMemo} from "react";
import {useTranslate} from "../../../hooks/translate.hook";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {Space, Typography} from "antd";
import {ColumnsType} from "antd/es/table";
import {Claim, ClaimStatus} from "../../../models/claims/claims";
import i18n from "i18next";
import {FieldGroupConfig} from "../../../models/filter/filter.group";
import {FilterDataHandler} from "../../../models/filter/filter.data.handler";
import {claimService} from "../../../services/claims/claims.service";
import FilteredTable from "../../common/filtered-table/filtered.table";
import {columnsFactory} from "./claim.list.columns";
import {filterGroupsFactory} from "./claim.filter-groups";
import {FilterButtonConfig} from "../../../models/filtered-table/filtered.table.props";
import {FieldGroupSnapshot} from "../../../models/filter/filter.field.snapshot";
import {ColumnsConfig, convertTableColumnsToColumnConfigs} from "../../../utils/table";

export enum ClaimFilterButtonsTypes {
    PROCESSED_CLAIMS = "processed_s",
    NOT_PROCESSED_CLAIMS = "not_processed",
}

export const ClaimList: React.FC<ComponentPropsFromRoute> = ({name}) => {

    const t = useTranslate();

    const columns: ColumnsType<Claim> = useMemo(() => columnsFactory(t), [i18n.language]);
    const columnsConfigs: ColumnsConfig = useMemo(() => convertTableColumnsToColumnConfigs<Claim>(columns), [i18n.language])
    const fcgConfigs: FieldGroupConfig[] = useMemo(() => filterGroupsFactory(t), []);
    const dataHandler: FilterDataHandler<Claim> = useMemo(() => new FilterDataHandler(request => claimService.getAll(request)), []);

    const filterButtonConfigs: FilterButtonConfig<ClaimFilterButtonsTypes>[] = [
        {
            type: ClaimFilterButtonsTypes.NOT_PROCESSED_CLAIMS,
            text: "CLAIMS.NOT_PROCESSED",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: ClaimStatus.RECEIVED,
                        },
                    ],
                },
            ],
        },
        {
            type: ClaimFilterButtonsTypes.PROCESSED_CLAIMS,
            text: "CLAIMS.PROCESSED",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: [ClaimStatus.CLOSED, ClaimStatus.ACCEPTED].join(","),
                        },
                    ],
                },
            ],
        }
        ];

    const defaultFilters: FieldGroupSnapshot[] = [
        {
            name: "statuses",
            snapshots: [
                {
                    rule: undefined,
                    name: "statuses",
                    value: ClaimStatus.RECEIVED,
                },
            ],
        }
    ]

    return (
        <Space direction="vertical" size={[0, 0]}>
            <Typography.Title level={3}>{t("CLAIMS.TITLE")}</Typography.Title>
            <FilteredTable
                columns={columns}
                columnsConfigs={{columns: columnsConfigs, pageKey: name}}
                dataHandler={dataHandler}
                filterGroups={fcgConfigs}
                filterButtons={filterButtonConfigs}
                filters={defaultFilters}
            />
        </Space>
    );
}
