import React from "react";
import { observer } from "mobx-react";
import { Space, Table, Typography } from "antd";
import { useRequiredStore } from "../../../../utils/store";
import { ArticleDataStoreContext } from "../article.data.store";
import {useTranslate} from "../../../../hooks/translate.hook";
import { ColumnsType } from "antd/es/table";
import {Warehouse} from "../../../../models/warehouses/warehouse";
import {toJS} from "mobx";

export const ArticleDataAvailability: React.FC = observer(() => {

    const store = useRequiredStore(ArticleDataStoreContext);
    const t = useTranslate();

    const columns: ColumnsType<any> = [
        {
            title: t("WAREHOUSES.WAREHOUSE_TITLE"),
            dataIndex: "warehouse",
            key: "warehouse",
            className: "bold",
            render: (warehouse: Warehouse) => warehouse.name
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "amount",
            key: "amount"
        }
    ];

    const stocks = store.articleAvailability[0]?.stocks;

    if (!stocks) {
        return <>Loading...</>;
    }

    return (
        <Space direction={"vertical"} size={0}>
            <Typography.Title level={3}>
                {t("ARTICLES.WAREHOUSES.TITLE")}
            </Typography.Title>
            <Table
                columns={columns}
                rowKey={"id"}
                dataSource={toJS(stocks)}
                loading={store.availabilityTableLoading}
                scroll={{ x: true }}
                pagination={false}
            />
        </Space>
    );
});
