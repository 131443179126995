import {FilterDataHandler} from "../../../../models/filter/filter.data.handler";
import {MergedInvoice} from "../../../../models/documents/invoices/merged-invoice/merged.invoice";
import {mergedInvoiceService} from "../../../../services/documents/invoices/merged.invoices.service";
import {PaginatedRequest} from "../../../../models/core/paginated.request";
import {FilterButtonConfig} from "../../../../models/filtered-table/filtered.table.props";
import {action, makeObservable, observable} from "mobx";
import React from "react";
import {agentService} from "../../../../services/agents/agent.service";
import {downloadBlobFile} from "../../../../utils/blob.download";

export enum InvoicesFilterButtonsTypes {
    ALL_INVOICES = "all_invoices",
    OPENED_INVOICED = "dispatched_today_invoices",
    CLOSED_INVOICES = "not_dispatched_invoices",
    CAN_BE_CLOSED_NOW = "can_be_closed_now",
}

export type InvoicesFilterButton = InvoicesFilterButtonsTypes | undefined;

export type InvoicesListStoreModalType = "dispatch" | "download" | "export";

export class MergedInvoicesListStore {
    dataHandler: FilterDataHandler<MergedInvoice>;

    request: PaginatedRequest = new PaginatedRequest();

    public dateFormat: string = "DD-MM-YYYY";

    @observable
    public dispatchModalShown: boolean = false;

    @observable
    public downloadModalShown: boolean = false;

    @observable
    public checkedInvoices: React.Key[] = [];

    @observable
    public exportInvoicesModalShown: boolean = false;

    public filterButtonConfigs: FilterButtonConfig<InvoicesFilterButton>[] = [
        {
            type: InvoicesFilterButtonsTypes.ALL_INVOICES,
            text: "INVOICES.ALL",
            filters: [],
        },
        {
            type: InvoicesFilterButtonsTypes.OPENED_INVOICED,
            text: "INVOICES.STATUS.OPENED",
            filters: [
                {
                    name: "status",
                    snapshots: [
                        {
                            name: "status",
                            value: "opened",
                        },
                    ],
                },
            ],
        },
        {
            type: InvoicesFilterButtonsTypes.CLOSED_INVOICES,
            text: "INVOICES.STATUS.CLOSED",
            filters: [
                {
                    name: "status",
                    snapshots: [
                        {
                            name: "status",
                            value: "closed",
                        },
                    ],
                },
            ],
        },
        {
            type: InvoicesFilterButtonsTypes.CAN_BE_CLOSED_NOW,
            text: "INVOICES.CAN_BE_CLOSED_NOW",
            filters: [
                {
                    name: "status",
                    snapshots: [
                        {
                            name: "status",
                            value: "opened",
                        },
                    ],
                },
                {
                    name: "canBeClosedNow",
                    snapshots: [
                        {
                            name: "canBeClosedNow",
                            value: "1",
                        },
                    ],
                },
            ],
        },
    ];

    @action
    private setDispatchModalShown(value: boolean): void {
        this.dispatchModalShown = value;
    }

    @action
    private setDownloadModalShown(value: boolean): void {
        this.downloadModalShown = value;
    }

    @action
    private setExportInvoicesModalShown(value: boolean): void {
        this.exportInvoicesModalShown = value;
    }

    public setRequest(request: PaginatedRequest) {
        this.request = request;
    }

    constructor() {
        makeObservable(this);
        this.dataHandler = new FilterDataHandler<MergedInvoice>(request => mergedInvoiceService.getMergedInvoices(request))
        this.onDispatched = this.onDispatched.bind(this);
        this.setDispatchModalShown = this.setDispatchModalShown.bind(this);
        this.setDownloadModalShown = this.setDownloadModalShown.bind(this);
        this.setRequest = this.setRequest.bind(this);
        this.onExportCreated = this.onExportCreated.bind(this);
        this.setExportInvoicesModalShown = this.setExportInvoicesModalShown.bind(this);
    }

    @action
    private setCheckedInvoices(invoices: React.Key[]): void {
        this.checkedInvoices = invoices;
    }

    public checkInvoicesToDispatch(invoices: React.Key[]): void {
        this.setCheckedInvoices(invoices);
    }

    public handleModal(modalType: InvoicesListStoreModalType, value: boolean): void {
        const modalTypes: Record<InvoicesListStoreModalType, (value: boolean) => void> = {
            dispatch: this.setDispatchModalShown,
            download: this.setDownloadModalShown,
            export: this.setExportInvoicesModalShown
        };
        modalTypes[modalType](value);
    }

    public async onDispatched() {
        this.setCheckedInvoices([]);
        await this.dataHandler.reloadItems(this.request!);
    }

    public searchAgents = async (value: string) => {
        const accounts = await agentService.searchWholesaleAccount(value);
        return accounts.map(account => ({value: account.id, label: account.agent.number + " - " + account.agent.name}));
    };

    public async deleteMergedInvoice(invoice: MergedInvoice) {
        this.dataHandler.setLoading(true);
        await mergedInvoiceService.deleteMergedInvoice(invoice.id);
        this.dataHandler.removeItem(invoice);
        await this.dataHandler.reloadItems(this.request);
        this.dataHandler.setLoading(false);
    }

    public async createExportInvoices(values: { weight: number }): Promise<void> {
        const { headers, data } = await mergedInvoiceService.createExportInvoices({
            weight: values.weight,
            invoices: this.checkedInvoices as number[],
        });
        const contentDisposition = headers["content-disposition"];
        const type = headers["content-type"];
        const filename = contentDisposition.split("filename=")[1];
        const invoiceBlob = new Blob([data], { type });
        downloadBlobFile(invoiceBlob, filename);
        await this.onExportCreated();
    }

    public async onExportCreated() {
        this.setCheckedInvoices([]);
        await this.dataHandler.reloadItems(this.request!);
    }
}