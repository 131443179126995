import React, {useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../utils/store";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {Form, Col, Select, FormInstance, Typography} from "antd";
import {WarehousesFormList} from "../../../../common/warehouses-form-list/warehouses.form.list";
import {SystemAccesses} from "../../system-accesses/system.accesses";
import {DepartmentRole} from "../../../../../models/departments/roles";
import {departmentsService} from "../../../../../services/departments/departments.service";
import {ExtendedUser} from "../../../../../models/users/user/extended.user";

interface UserDataFMSCartProps {
    form: FormInstance,
    type: string,
    disabled: boolean,
    defaultSelectedSystems: string[],
    user: ExtendedUser
}

export const UserDataFMSCart: React.FC<UserDataFMSCartProps> = observer(({form, type, disabled, defaultSelectedSystems, user}) => {
    const t = useTranslate();
    const {commonStore: {departments, priceLists, employeeStatuses}, authStore} = useRequiredStore(RootStoreContext);
    const [roles, setRoles] = useState<DepartmentRole[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const branches = useMemo(() => user?.accesses.map(a => a.account.branch?.id) || [], [user]);

    const reloadRoles = (department: number = -1, resetRoleOnReload: boolean = false) => {
        setLoading(true);
        if (-1 === department) {
            department = form.getFieldValue("department");
        }
        if (department !== undefined) {
            departmentsService.getDepartmentRoles(department).then(r => setRoles(r));
        }
        setLoading(false);
        if (resetRoleOnReload) {
            form.setFieldValue("role", null);
        }
    }

    useEffect(() => {
        reloadRoles();
    }, []);

    return (
        <>
            <Col xxl={12} xl={12} md={24}>
                {branches.length > 0 ? (
                    <Col span={24}>
                        <Form.Item
                            name="branch"
                            label={t("BRANCH.TITLE")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp={"children"}
                                disabled={disabled}
                                placeholder={t("STATUS")}
                            >
                                {authStore
                                    .accesses
                                    .filter(a => !branches.includes(a.branchId))
                                    .map(item => (
                                        <Select.Option key={item.branchId} value={item.branchId}>
                                            {item.branchName}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                ) : null}
                <Col span={24}>
                    <Form.Item
                        name="status"
                        label={t("STATUS")}
                        rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                    >
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp={"children"}
                            disabled={disabled}
                            placeholder={t("STATUS")}
                        >
                            {employeeStatuses.map(item => (
                                <Select.Option key={item} value={item}>
                                    {item.beautify()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={"prices"}
                        label={t("PRICE_LISTS")}
                        rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                    >
                        <Select
                            showSearch
                            disabled={disabled}
                            optionFilterProp={"children"}
                            placeholder={t("PRICE_LISTS")}
                            mode={"multiple"}
                        >
                            {priceLists.map(item => (
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                {loading ? null : (
                    <>
                        <Col span={24}>
                            <Form.Item
                                name="department"
                                label={t("DEPARTMENT.TITLE")}
                                rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp={"children"}
                                    disabled={disabled}
                                    placeholder={t("DEPARTMENT.TITLE")}
                                    onSelect={(value) => reloadRoles(value, true)}
                                >
                                    {departments.map(item => (
                                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.department !== nextValues.department}>
                                {() => (
                                    <Form.Item
                                        name="role"
                                        label={t("DEPARTMENT.ROLE.TITLE")}
                                        rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp={"children"}
                                            disabled={disabled}
                                            placeholder={t("DEPARTMENT.ROLE.TITLE")}

                                        >
                                            {roles.map(role => (
                                                <Select.Option key={role.id as number} value={role.id as number}>{role.name} ({role.type.capitalizeFirstLetter()})</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}
                            </Form.Item>
                        </Col>
                    </>
                )}
                <Col span={24}>
                    <Typography.Title level={5}>{t("USER.PROFILE.SYSTEM_ACCESSES")}</Typography.Title>
                </Col>
                <Col span={24}>
                    <SystemAccesses
                        defaultSelectedSystems={defaultSelectedSystems}
                        type={type}
                        form={form}
                        loading={disabled}
                    />
                </Col>
            </Col>
            <Col xxl={12} xl={12} md={24}>
                <WarehousesFormList disabled={disabled} form={form} withMaxAmount={true} withTransactionType={false}/>
            </Col>
        </>
    );
});
