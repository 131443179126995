import React, {useState} from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {Button, notification, Space, Spin, Typography} from "antd";
import {useRequiredStore} from "../../../../../../utils/store";
import {UserGroupsStoreContext} from "../../user.groups.store";
import {GroupsDataAccessesItem} from "./goups-data-accesses-item/groups.data.accesses.item";


export const GroupsDataAccesses: React.FC = observer(() => {
    const store = useRequiredStore(UserGroupsStoreContext);
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Space direction="vertical" size={[50, 50]}>
            <div>
                <Typography.Title level={3}>{t("USERS.GROUPS.ACCESSES.TITLE")}</Typography.Title>
                <Space direction="vertical" size={[50, 50]}>
                    {store.allCategories.length ? Object.keys(store.accessesData).map(item => (
                        <GroupsDataAccessesItem key={item} id={item} accessesMap={store.accessesData[item].accesses}/>
                    )) : (
                        <Space>
                            <Spin/>
                        </Space>
                    )}
                </Space>
            </div>
            <Button
                loading={loading}
                type="primary"
                onClick={async () => {
                    setLoading(true);
                    try {
                        await store.saveChanges();
                        notification.success({message: t("SAVED"), duration: 1});
                        setLoading(false);
                    } catch (error: any) {
                        notification.error({message: error.message, description: "Something went wrong", duration: 2});
                    }
                    setLoading(false);
                }}
            >
                {t("SAVE")}
            </Button>
        </Space>
    );
});
