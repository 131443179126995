import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {PaginatedResult} from "../../models/core/paginated.result";
import {DeclarationsCode} from "../../models/declarations/declarations.codes";
import {PaginatedRequest} from "../../models/core/paginated.request";

export class DeclarationsCodesService {
    api: AxiosInstance = API;

    getAll(request: PaginatedRequest): Promise<PaginatedResult<DeclarationsCode>> {
        return this.api.get<PaginatedResult<DeclarationsCode>>(
            'declarations/codes',
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }

    create(data: any): Promise<DeclarationsCode> {
        return this.api.post<DeclarationsCode>('declarations/codes', data).then(x => x.data);
    }

    update(id: number, data: any): Promise<DeclarationsCode> {
        return this.api.post<DeclarationsCode>('declarations/codes/' + id, data).then(x => x.data);
    }

    remove(id: number): Promise<any> {
        return this.api.delete<any>('declarations/codes/' + id).then(x => x.data);
    }
}

export const declarationsCodesService = new DeclarationsCodesService();
