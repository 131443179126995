import {SmartColumn} from "../../../common/smart-table/smart-table.model";
import {PropertyInventoryTask, PropertyInventoryTaskStatus} from "../../../../models/property/property";
import {FieldType} from "../../../common/smart-table/smart-table.field-config";
import {Translate} from "../../../../hooks/translate.hook";
import {InventoryTaskStatus} from "../common/inventory-task-status/inventory-task.status";
import {SystemUser} from "../../../../models/users/user/system.user";

export const columnFactory = (t: Translate): SmartColumn<PropertyInventoryTask>[] => {
    return [
        {
            name: 'id',
            visibleName: t('ID'),
            type: FieldType.OBJECT,
            options: {t: 'property/inventory-tasks', f: 'id'}
        },
        {
            name: 'year',
            visibleName: t('YEAR'),
            type: FieldType.INTEGER
        },
        {
            name: 'status',
            visibleName: t('STATUS'),
            type: FieldType.STRING,
            render: (status: PropertyInventoryTaskStatus) => <InventoryTaskStatus status={status} />
        },
        {
            name: 'startedBy',
            visibleName: t('PROPERTY.PROPERTY.INVENTORY_TASK.AUTHOR'),
            type: FieldType.INTEGER,
            render: (u: SystemUser) => u.firstName + ' ' + u.lastName
        },
        {
            name: 'createdAt',
            visibleName: t('DATE'),
            type: FieldType.DATETIME
        }
    ]
}