import { action, computed, makeObservable, observable } from "mobx";
import { AxiosResponse } from "axios";
import API from "../../utils/api";
import {SystemUser, SystemUserAccess} from "../../models/users/user/system.user";
import { authService } from "../../services/auth/auth.service";
import { userService } from "../../services/users/user.service";
import { Group } from "../../models/users/group/group";
import * as Sentry from "@sentry/react";
import { CommonStore } from "../common/common.store";
import {SystemUserDepartment, SystemUserRole} from "../../models/users/user/system.user-with-attributes";

export class AuthStore {

    static TOKEN = "token";

    @observable
    private _token: string = localStorage.getItem(AuthStore.TOKEN) || "";

    @observable
    private _user: SystemUser | null = null;

    @observable
    private _access: number = 0;

    @observable
    private _accesses: SystemUserAccess[] = [];

    @observable
    private _attributes: string[] = [];

    @observable
    private _userGroup: Group | null = null;

    @observable
    private _userBranch: string = "";

    @observable
    private _originalBranch: string | undefined;

    @observable
    private _originalUsername: string | undefined;

    @observable
    public inited: boolean = false;

    @observable
    private _department: SystemUserDepartment | null = null;
    // private _history: any = history;

    @observable
    private _role: SystemUserRole | null = null;

    constructor(private commonStore: CommonStore) {
        makeObservable(this);
        this.logout = this.logout.bind(this);
    }

    public init () {
        this.initUnauthorizedInterceptor();
        // this.tryLoadFakeBranch();
        // this.tryLoadFakeUser();
        this.tryLoadUser();
    }

    @computed
    public get department(): SystemUserDepartment | null {
        return this._department;
    }

    public set department(value: SystemUserDepartment | null) {
        this._department = value;
    }

    @computed
    public get role(): SystemUserRole | null {
        return this._role;
    }

    public set role(value: SystemUserRole | null) {
        this._role = value;
    }

    @computed
    public get group(): Group | null {
        return this._userGroup;
    }

    private set userGroup(value: Group) {
        this._userGroup = value;
    }

    @computed
    public get user(): SystemUser | null {
        return this._user;
    }

    public set user(value: SystemUser | null) {
        this._user = value;
    }

    @computed
    public get attributes(): string[] {
        return this._attributes;
    }

    public set attributes(value: string[]) {
        this._attributes = value;
    }

    @computed
    public get access(): number {
        return this._access;
    }

    public set access(value: number) {
        this._access = value;
    }

    @computed
    public get accesses(): SystemUserAccess[] {
        return this._accesses;
    }

    public set accesses(value: SystemUserAccess[]) {
        this._accesses = value;
    }

    @computed
    public get branch(): string {
        return this._userBranch;
    }

    public set userBranch(value: string) {
        this._userBranch = value;
    }

    @computed
    public get originalBranch(): string | undefined {
        return this._originalBranch;
    }

    public set originalBranch(value: string | undefined) {
        this._originalBranch = value;
    }

    @computed
    public get originalUsername(): string | undefined {
        return this._originalUsername;
    }

    public set originalUsername(value: string | undefined) {
        this._originalUsername = value;
    }

    @computed
    public get isLoggedIn(): boolean {
        return null !== this._user;
    }

    @computed
    public get token(): string {
        return this._token;
    }

    public set token(token: string) {
        this._token = token;
    }

    @computed
    public get isUserFaked(): boolean {
        return !!this.originalUsername;
    }

    @computed
    public get isBranchFaked(): boolean {
        return !!this.originalBranch;
    }

    @action
    public async login(username: string, password: string) {
        const result = await authService.auth(username, password);
        this._token = result.token;
        localStorage.setItem(AuthStore.TOKEN, result.token);
        await this.loadUser();
    }

    public logout = async () => {
        await authService.logout();
        this.setUser(null);
        this.commonStore.clearStore();
        Sentry.configureScope((scope) => scope.setUser(null));
        this.commonStore.navigate("/login");
    };

    public async loadUser() {
        this.commonStore.clearStore();

        try {
            const userWithAttributes = await userService.getCurrentUserWithAttributes();
            this.setUser(userWithAttributes.user);
            Sentry.setUser({ email: userWithAttributes.user.email, id: String(userWithAttributes.user.id) });
            this.setAttributes(userWithAttributes.attributes);
            this.setGroup(userWithAttributes.group);
            this.setBranch(userWithAttributes.branch);
            this.setUserDepartment(userWithAttributes.department);
            this.setUserRole(userWithAttributes.role);
            this.setOriginalBranch(userWithAttributes.originalBranch);
            this.setOriginalUsername(userWithAttributes.originalUser);
            this.setAccess(userWithAttributes.selectedAccess);
            this.setAccesses(userWithAttributes.accesses);
            this.setInited(true);
        } catch (e: any) {
            this.setInited(true);
        }
    }

    @action
    private setOriginalBranch(value: string | undefined) {
        this._originalBranch = value;
    }

    @action
    private setOriginalUsername(value: string | undefined) {
        this._originalUsername = value;
    }

    @action
    private setUser(value: SystemUser | null) {
        this.user = value;
    }

    @action
    private setBranch(value: string) {
        this.userBranch = value;
    }

    @action
    private setUserDepartment(value: SystemUserDepartment): void {
        this.department = value;
    }

    @action
    private setUserRole(value: SystemUserRole): void {
        this.role = value;
    }

    @action
    private setAttributes(values: string[]) {
        this.attributes = values;
    }

    @action
    public setAccess(value: number | null) {
        if (this.access !== value) {
            this.access = value || 0;
        }
    }

    @action
    public setAccesses(value: SystemUserAccess[]) {
        this.accesses = value;
    }

    @action
    private setGroup(value: Group) {
        this.userGroup = value;
    }

    @action
    public setInited(value: boolean): void {
        this.inited = value;
    }

    private tryLoadUser(): void {
        this.loadUser();
    }

    private initUnauthorizedInterceptor() {
        API.interceptors.response.use(
            (value: AxiosResponse): AxiosResponse => {
                return value;
            },
            (error) => {
                if (
                    error.response?.status === 401 &&
                    window.location.pathname !== "/login" &&
                    window.location.pathname !== "/"
                ) {
                    this.logout();
                }
                return Promise.reject(error);
            }
        );
    }
}
