import React, {useEffect, useState} from "react";
import { ComponentPropsFromRoute } from "../../../routers/routers";
import {SettingsAction} from "./actions.action";
import {Space, Typography} from "antd";
import {useTranslate} from "../../../hooks/translate.hook";
import {actionsService} from "../../../services/actions/actions.service";
import {LoadingSpin} from "../../common/loading-spin/loading.spin";

interface SettingsActionsProps extends ComponentPropsFromRoute {}
export const SettingsActions: React.FC<SettingsActionsProps> = ({ name }) => {
    const t = useTranslate();

    const [actions, setActions] = useState<string[]>([]);

    useEffect(() => {
        actionsService.getAliases().then(actions => setActions(actions))
    }, [])

    if (0 === actions.length) {
        return <LoadingSpin />
    }

    return <>
        <Typography.Title level={3}>
            {t("ACTIONS")}
        </Typography.Title>
        <Space direction={"vertical"}>
            {actions.map(alias => (
                <SettingsAction alias={alias} key={alias}/>
            ))}
        </Space>
    </>
}

//"Reindex all articles, vehicles, analogues and descriptions for search in catalog"