import React from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../utils/store";
import {OrderDataStoreContext} from "../order.data.store";
import {List, Typography} from "antd";
import {generateCommentsData} from "../../../../models/orders/order-data/order-data-helper/order.data.helper";
import {OrderDataCommentsForm} from "./order-data-comments-form/order.data.comments.form";
import "./order.data.comments.scss";
import {Comment} from "@ant-design/compatible";
import {useTranslate} from "../../../../hooks/translate.hook";

export const OrderDataComments: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(OrderDataStoreContext);

    const commentsData = generateCommentsData(store.order!.comments, store.removeComment, t("REMOVE"))

    return (
        <>
            <Typography.Title level={3} style={store.order?.comments.length ? {marginBottom: 10} : undefined}>{t("COMMENTS.TITLE")}</Typography.Title>
            {store.order?.comments.length ? (
                <List
                    className="comment-list"
                    itemLayout="horizontal"
                    dataSource={commentsData}
                    renderItem={item => (
                        <li>
                            <Comment
                                author={item.author}
                                content={item.content}
                                datetime={item.datetime}
                            />
                        </li>
                    )}
                />
            ) : null}
            <OrderDataCommentsForm/>
        </>
    );
});
