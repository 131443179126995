import React, {useState} from "react";
import {Button, Dropdown, Menu, MenuProps} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {ArticlesExportModal} from "../../export/articles.export.modal";
import {articlesFileService} from "../../../../../services/articles/articles/articles.file.service";
import {generateItems} from "./articles.list.actions.helpers";

export const ArticlesListActions: React.FC<any> = () => {

    const t = useTranslate();
    const [exportModalShown, setExportModalShown] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [items, setItems] = useState<MenuProps['items']>([]);

    const onOpenChange = async (open: boolean) => {
        if (open) {
            setDisabled(true);
            try {
                const list = await articlesFileService.getList();
                setItems(generateItems(setExportModalShown, articlesFileService.createDownloadURL, list, t))
            } finally {
                setDisabled(false);
            }
        }
    }

    return (
        <>
            <Dropdown
                trigger={["click"]}
                menu={{items: items}}
                onOpenChange={onOpenChange}
                disabled={disabled}
            >
                <Button
                    className={"table-page-button"}
                    type={"primary"}
                >
                    {t("ACTION")}
                </Button>
            </Dropdown>
            {exportModalShown ? (
                <ArticlesExportModal onClose={() => setExportModalShown(false)} />
            ) : null}
        </>
    )
}