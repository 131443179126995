import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {ErrorsStore} from "./errors.store";
import {LoadingSpin} from "../common/loading-spin/loading.spin";
import {Space, Tabs} from "antd";
import {useTranslate} from "../../hooks/translate.hook";
import {ComponentPropsFromRoute} from "../../routers/routers";
import {ExtendedSwitch} from "../core/router/extended-switch/extended.switch";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";

interface ErrorsProps extends ComponentPropsFromRoute {}
export const Errors: React.FC<ErrorsProps> = observer(({routeChildren}) => {
    const t = useTranslate();
    const [store] = useState(() => new ErrorsStore());
    const navigate = useNavigate();
    const {pathname} = useLocation();

    useEffect(() => {
        if (pathname === "/errors") {
            navigate("/errors/all")
        }
    },[pathname]);

    const rootTabPath = pathname.split("/").slice(0, 4).join("/");

    if (store.loading) {
        return <LoadingSpin/>
    }

    return (
        <Space direction="vertical" size={[0, 0]}>
            <Space direction={"vertical"} size={[0, 0]}>
                <Tabs
                    activeKey={rootTabPath}
                    onTabClick={(key) => navigate(key)}
                >
                    <Tabs.TabPane
                        tab={t("ERRORS.ALL.TITLE")}
                        key={"/errors/all"}
                    />
                    {store.groups.map(group => (
                        <Tabs.TabPane
                            tab={t("ERRORS." + group.toUpperCase() + ".TITLE")}
                            key={"/errors/" + group}
                        />
                    ))}
                </Tabs>
                <ExtendedSwitch routers={routeChildren!}/>
            </Space>
        </Space>
    );
});
