import React from "react";
import { observer } from "mobx-react";
import { useRequiredStore } from "../../../../utils/store";
import { OrderDataStoreContext } from "../order.data.store";
import {SmartLogger} from "../../../logger/smart-logger/smart-logger";
import {Typography} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {isAllowed} from "../../../../utils/helpers";

export const OrderDataLogger: React.FC = observer(() => {
    const store = useRequiredStore(OrderDataStoreContext);
    const t = useTranslate();

    const {
        authStore: { attributes }
    } = useRequiredStore(RootStoreContext);

    if (!isAllowed(attributes, "smartlogger.list")) {
        return null;
    }

    return (
        store.order ? (
            <>
                <Typography.Title level={3}>{t("LOGGER.TITLE")}</Typography.Title>
                <SmartLogger
                    entityName={"Order\\Order"}
                    entityId={store.order.id.toString()}
                />
            </>
        ) : null
    )
});
