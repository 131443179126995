import React, {useMemo, useState} from "react";
import {columnsFactory} from "./documents-code-files.columns";
import {useTranslate} from "../../../hooks/translate.hook";
import {Button, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SmartTable} from "../../common/smart-table/smart-table";
import {SmartTableDataHandler} from "../../common/smart-table/smart-table.data-handler";
import {DocumentsCodeFilesUploadModal} from "./upload/documents-code-files.upload.modal";
import {
    declarationsDocumentCodeFilesService
} from "../../../services/declarations/declarations.document-code-files.service";
import {DeclarationsDocumentCodeFile} from "../../../models/declarations/declarations.document-code-files";

export const DocumentsCodeFilesList: React.FC = () => {

    const t = useTranslate();

    const columns = useMemo(() => columnsFactory(t), [t]);
    const handler = useMemo(() => new SmartTableDataHandler(request => declarationsDocumentCodeFilesService.getAll(request)), []);

    const [modalShown, setModalShown] = useState<boolean>(false);

    const onRemove = async (id: DeclarationsDocumentCodeFile) => {
        await declarationsDocumentCodeFilesService.remove(id.id);
        await handler.reloadItemsWithLastRequest();
    }

    return (
        <Space direction={"vertical"} size={[10, 10]}>
            <Button
                type={"primary"}
                onClick={() => setModalShown(true)}
                icon={<FontAwesomeIcon icon={["fas", "plus"]} />}
            >
                {t('CREATE')}
            </Button>
            <SmartTable
                columns={columns}
                handler={handler}
                actions={[
                    {
                        icon: 'remove',
                        title: t("REMOVE"),
                        onClick: onRemove
                    }
                ]}
            />
            {modalShown ? (
                <DocumentsCodeFilesUploadModal
                    onClose={() => setModalShown(false)}
                    onComplete={() => handler.reloadItemsWithLastRequest()}
                />
            ) : null}
        </Space>
    )
}