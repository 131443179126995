import { languagesService } from "./../../services/languages/languages.service";
import { banksService } from "../../services/banks/banks.service";
import { Bank } from "../../models/banks/bank";
import { shippersService } from "../../services/shippers/shippers.services";
import { action, computed, makeObservable, observable } from "mobx";
import { Warehouse } from "../../models/warehouses/warehouse";
import { warehousesService } from "../../services/warehouses/warehouses.service";
import { Branch } from "../../models/branches/branch";
import { branchesService } from "../../services/branches/branches.service";
import { departmentsService } from "../../services/departments/departments.service";
import { PriceList } from "../../models/price-lists/price.lists";
import { priceListsService } from "../../services/price-lists/price-lists.service";
import { System } from "../../models/systems/system/system";
import { systemsService } from "../../services/systems/systems.service";
import { SystemGroup } from "../../models/users/group/group";
import { agentDataService } from "../../services/agents/agent.data.service";
import { documentTypesService } from "../../services/document-types/document.types.service";
import { agentTypesService } from "../../services/agents/agent.types.service";
import { statusesService } from "../../services/statuses/statuses.service";
import { Shipper } from "../../models/shippers/shipper";
import { DepartmentFlatten } from "../../models/departments/department";
import { flattenDepartments } from "../../models/departments/helper";
import { IntegrationConnectionType } from "../../models/agents/agent-data/agent-data-integrations/agent.data.integration";
import {AgentICAccessGroup} from "../../models/agents/agent";
import {NavigateFunction} from "react-router/dist/lib/hooks";

export enum OrderStatuses {
    NOT_COMPLETED = "not_completed",
    NOT_CONFIRMED = "not_confirmed",
    CONFIRMED = "confirmed",
    CANCELLED = "cancelled",
    TAKEN = "taken",
    ORDERED = "ordered",
    MERGED = "merged",
    IN_PROGRESS = "in_progress",
    COLLECTED = "collected",
    PACKED = "packed",
    SHIPPED = "shipped",
    DELIVERED = "delivered",
    PRE_ORDERED = "pre_ordered",
    SPLITTED = "splitted",
    INVALID = "invalid",
    SYNC = "sync",
    WAITING_FOR_GOODS = "waiting_for_goods"
}

export type OrderStatus = OrderStatuses;

export type StatusColor = Record<OrderStatus, string>;

export const statusColor: StatusColor = {
    not_completed: "red",
    not_confirmed: "gold",
    confirmed: "gold",
    cancelled: "red",
    taken: "red",
    ordered: "gold",
    merged: "",
    in_progress: "green",
    collected: "green",
    packed: "green",
    shipped: "green",
    delivered: "green",
    pre_ordered: "golden",
    splitted: "blue",
    invalid: "red",
    sync: "red",
    waiting_for_goods: "blue"
};

export enum ExportFormat {
    CSV = "csv",
    XLS = "xls",
    XLSX = "xlsx",
}

export class CommonStore {
    public integrationConnectionTypes: IntegrationConnectionType[] = [
        {
            value: "ftp",
            label: "FTP",
        },
        {
            value: "sftp",
            label: "SFTP",
        },
    ];

    // Client order types
    public orderTypes: string[] = ["wholesale", "wholesale_dropship"];

    // Export formats
    public exportFormats: ExportFormat[] = [ExportFormat.XLSX, ExportFormat.XLS, ExportFormat.CSV];

    // Order statuses
    public orderStatuses: OrderStatus[] = Object.keys(statusColor) as OrderStatus[];

    // Merged invoice statuses
    public mergedInvoiceStatuses: string[] = ["opened", "closed"];

    // All locales
    @observable
    private _allLocales: string[] = [];
    private allLocalesInited: boolean = false;

    // Banks
    @observable
    private _banksAll: Bank[] = [];
    private banksInited: boolean = false;

    @observable
    private _shippersAll: Shipper[] = [];
    private shippersInited: boolean = false;

    // System groups
    @observable
    private _systemGroups: SystemGroup[] = [];
    private systemGroupsInited: boolean = false;

    // Systems
    @observable
    private _systems: System[] = [];
    private systemsInited: boolean = false;

    // Price Lists
    @observable
    private _priceLists: PriceList[] = [];
    private priceListsInited: boolean = false;

    // Branches
    @observable
    private _branches: Branch[] = [];
    private branchesInited: boolean = false;

    // Warehouses
    @observable
    private _warehouses: Warehouse[] = [];
    private warehousesInited: boolean = false;

    // Departments
    @observable
    private _departments: DepartmentFlatten[] = [];
    private departmentsInited: boolean = false;

    // Doc types
    @observable
    private _docTypes: string[] = [];
    private docTypesInited: boolean = false;

    // Invoice doc types
    @observable
    private _invoiceDocTypes: string[] = [];
    private _invoiceDocTypesInited: boolean = false;

    // Packing list doc types
    @observable
    private _packingListDocTypes: string[] = [];
    private _packingListDocTypesInited: boolean = false;

    // Agent types
    @observable
    private _agentTypes: string[] = [];
    private agentTypesInited: boolean = false;

    // Agent Groups
    @observable
    private _agentGroups: string[] = [];
    private agentGroupsInited: boolean = false;

    // Agent IC access groups
    @observable
    private _agentICAccessGroups: AgentICAccessGroup[] = [];
    private agentICAccessGroupsInited: boolean = false;

    // Agent Statuses
    @observable
    private _statuses: string[] = [];
    private statusesInited: boolean = false;

    @observable
    public outdated: boolean = false;

    constructor(public navigate: NavigateFunction) {
        makeObservable(this);
    }

    @computed
    public get locales(): string[] {
        this.loadAllLocales();
        return this._allLocales;
    }

    private async loadAllLocales() {
        if (this.allLocalesInited) {
            return;
        }
        this.allLocalesInited = true;
        this.setAllLocales(await languagesService.getLocales());
    }

    @action
    private setAllLocales(value: string[]): void {
        this._allLocales = value;
    }

    //Banks
    @computed
    public get banks(): Bank[] {
        this.loadBanks();
        return this._banksAll;
    }

    private async loadBanks() {
        if (this.banksInited) {
            return;
        }
        this.banksInited = true;
        this.setBanks(await banksService.getAllBanks());
    }

    @action
    private setBanks(value: Bank[]): void {
        this._banksAll = value;
    }

    // Statuses
    @computed
    public get statuses(): string[] {
        this.loadStatuses();
        return this._statuses;
    }

    @computed
    public get employeeStatuses(): string[] {
        this.loadStatuses();
        return this._statuses.filter((status) => status !== "suspended_shipment" && status !== "potential");
    }
    @action
    private setStatuses(statuses: string[]): void {
        this._statuses = statuses;
    }

    private async loadStatuses() {
        if (this.statusesInited) {
            return;
        }
        this.statusesInited = true;
        this.setStatuses(await statusesService.getStatuses());
    }

    // Shippers
    @computed
    public get shippers(): Shipper[] {
        this.loadShippers();
        return this._shippersAll;
    }

    @action
    private setShippers(value: Shipper[]): void {
        this._shippersAll = value;
    }

    private async loadShippers() {
        if (this.shippersInited) {
            return;
        }
        this.shippersInited = true;
        this.setShippers(await shippersService.getShippers());
    }

    // Agent Groups
    @computed
    public get agentGroups(): string[] {
        this.loadAgentGroups();
        return this._agentGroups;
    }

    @action
    private setAgentGroups(groups: string[]): void {
        this._agentGroups = groups;
    }

    private async loadAgentGroups() {
        if (this.agentGroupsInited) {
            return;
        }
        this.agentGroupsInited = true;
        this.setAgentGroups(await agentDataService.getAgentGroups());
    }

    // Agent IC Access Groups
    @computed
    public get agentICAccessGroups(): AgentICAccessGroup[] {
        this.loadAgentICAccessGroups();
        return this._agentICAccessGroups;
    }

    @action
    private setAgentICAccessGroups(groups: AgentICAccessGroup[]): void {
        this._agentICAccessGroups = groups;
    }

    private async loadAgentICAccessGroups() {
        if (this.agentICAccessGroupsInited) {
            return;
        }
        this.agentICAccessGroupsInited = true;
        this.setAgentICAccessGroups(await agentDataService.getICAccessGroups());
    }

    // Agent types
    @computed
    public get agentTypes(): string[] {
        this.loadAgentTypes();
        return this._agentTypes;
    }
    @action
    private setAgentTypes(value: string[]): void {
        this._agentTypes = value;
    }
    private async loadAgentTypes() {
        if (this.agentTypesInited) {
            return;
        }
        this.agentTypesInited = true;
        this.setAgentTypes(await agentTypesService.getTypes());
    }

    // Document Types
    @computed
    public get documentTypes(): string[] {
        this.loadDocTypes();
        return this._docTypes;
    }

    @action
    private setDocumentTypes(value: string[]): void {
        this._docTypes = value;
    }

    private async loadDocTypes() {
        if (this.docTypesInited) {
            return;
        }
        this.docTypesInited = true;
        this.setDocumentTypes(await documentTypesService.getDocumentTypes());
    }

    // Document Types
    @computed
    public get invoiceDocumentTypes(): string[] {
        this.loadInvoiceDocTypes();
        return this._invoiceDocTypes;
    }
    @action
    private setInvoiceDocumentTypes(value: string[]): void {
        this._invoiceDocTypes = value;
    }
    private async loadInvoiceDocTypes() {
        if (this._invoiceDocTypesInited) {
            return;
        }
        this._invoiceDocTypesInited = true;
        this.setInvoiceDocumentTypes(await documentTypesService.getInvoiceDocumentTypes());
    }

    @computed
    public get packingListDocumentTypes(): string[] {
        this.loadPackingListDocTypes();
        return this._packingListDocTypes;
    }
    @action
    private setPackingListDocumentTypes(value: string[]): void {
        this._packingListDocTypes = value;
    }
    private async loadPackingListDocTypes() {
        if (this._packingListDocTypesInited) {
            return;
        }
        this._packingListDocTypesInited = true;
        this.setPackingListDocumentTypes(await documentTypesService.getPackingListDocumentTypes());
    }

    // Branches methods
    @computed
    public get branches(): Branch[] {
        this.loadBranches();
        return this._branches;
    }
    @action
    private setBranches(branches: Branch[]): void {
        this._branches = branches;
    }
    private async loadBranches() {
        if (this.branchesInited) {
            return;
        }
        this.branchesInited = true;
        this.setBranches(await branchesService.getAllBranches());
    }

    // Warehouses methods
    @computed
    public get warehouses(): Warehouse[] {
        this.loadWarehouses();
        return this._warehouses;
    }
    @action
    private setWarehouses(warehouses: Warehouse[]): void {
        this._warehouses = warehouses;
    }
    private async loadWarehouses() {
        if (this.warehousesInited) {
            return;
        }
        this.warehousesInited = true;
        this.setWarehouses(await warehousesService.getAllWarehouses());
    }

    // Departments methods;
    @computed
    public get departments(): DepartmentFlatten[] {
        this.loadDepartments();
        return this._departments;
    }

    @action
    private setDepartments(departments: DepartmentFlatten[]): void {
        this._departments = departments;
    }

    private async loadDepartments() {
        if (this.departmentsInited) {
            return;
        }
        this.departmentsInited = true;
        this.setDepartments(flattenDepartments(await departmentsService.getAll()));
    }

    // Price Lists methods
    @computed
    public get priceLists(): PriceList[] {
        this.loadPriceLists();
        return this._priceLists;
    }

    @action
    private setPriceLists(priceLists: PriceList[]): void {
        this._priceLists = priceLists;
    }

    private async loadPriceLists() {
        if (this.priceListsInited) {
            return;
        }
        this.priceListsInited = true;
        this.setPriceLists(await priceListsService.getPriceLists());
    }

    // Systems methods
    @computed
    public get systems(): System[] {
        this.loadSystems();
        return this._systems;
    }
    @action
    private setSystems(systems: System[]): void {
        this._systems = systems;
    }
    private async loadSystems() {
        if (this.systemsInited) {
            return;
        }
        this.systemsInited = true;
        this.setSystems(await systemsService.getSystems());
    }

    // System groups methods
    @computed
    public get systemGroups(): SystemGroup[] {
        this.loadSystemGroups();
        return this._systemGroups;
    }
    @action
    private setSystemGroups(systemGroups: SystemGroup[]): void {
        this._systemGroups = systemGroups;
    }
    private async loadSystemGroups() {
        if (this.systemGroupsInited) {
            return;
        }
        this.systemGroupsInited = true;
        this.setSystemGroups(await systemsService.getSystemGroups());
    }

    public clearStore(): void {
        this.setAgentGroups([]);
        this.agentGroupsInited = false;

        this.setBranches([]);
        this.branchesInited = false;

        this.setDepartments([]);
        this.departmentsInited = false;

        this.setSystems([]);
        this.systemsInited = false;

        this.setStatuses([]);
        this.statusesInited = false;

        this.setWarehouses([]);
        this.warehousesInited = false;

        this.setAgentTypes([]);
        this.agentTypesInited = false;

        this.setDocumentTypes([]);
        this.docTypesInited = false;

        this.setPriceLists([]);
        this.priceListsInited = false;

        this.setSystemGroups([]);
        this.systemGroupsInited = false;
    }

    @action
    public setOutdated(): void {
        this.outdated = true;
    }
}
