import {SmartColumn} from "../../../../common/smart-table/smart-table.model";
import {PropertyType} from "../../../../../models/property/property";
import {FieldType} from "../../../../common/smart-table/smart-table.field-config";
import {Translate} from "../../../../../hooks/translate.hook";
import {PropertyType as PropertyTypeView} from "../../../property/common/property-type/property-type";

export const columnFactory = (t: Translate): SmartColumn<PropertyType>[] => {
    return [
        {
            name: '',
            visibleName: t('PROPERTY.PROPERTY.TYPE'),
            type: FieldType.STRING,
            render: (_: any, vv) => <PropertyTypeView type={vv} />
        }
    ]
}