import React, { useState } from "react";
import {Alert, Col, Form, Modal, Row} from "antd";
import { AxiosError } from "axios";
import { ErrorResponseData } from "../../../../models/core/response";
import {useTranslate} from "../../../../hooks/translate.hook";
import { DebounceSelect } from "../../../common/debounce-select/debounce.select";
import { NumberInput } from "../../../common/number-input/number.input";
import { createArticleRequest } from "../../../../models/orders/orders-cart/orders-create/orders-cart-create-helper/orders.cart.create.helper";
import { OrdersCreateAddValues } from "../../../../models/orders/orders-cart/orders-create/orders.create.add.values";

interface OrdersArticleModalProps {
    orderId?: number;
    searchArticle: (search: string) => Promise<
        {
            value: number;
            label: string;
        }[]
    >;
    onClose: () => void;
    onSubmit: (values: OrdersCreateAddValues, id?: number) => Promise<any>;
}

export const OrdersArticleModal: React.FC<OrdersArticleModalProps> = ({
    orderId,
    searchArticle,
    onClose,
    onSubmit,
}) => {
    const t = useTranslate();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const [articles, setArticles] = useState<any>([]);
    const [selectedArticle, setSelectedArticle] = useState<any>();


    console.log(selectedArticle)

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("ADD")}
            okText={t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                size={"middle"}
                form={form}
                layout="vertical"
                name={"add_to_cart_form_" + orderId}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        onSubmit(createArticleRequest(values), orderId)
                            .then(
                                () => {
                                    form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    setLoading(false);
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                }
                            )
                            .then(() => (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={[10, 10]} align={"middle"}>
                    <Col span={24}>
                        <Form.Item
                            name={"article"}
                            label={t("ARTICLES.SEARCH")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                },
                            ]}
                        >
                            <DebounceSelect
                                autoFocus={true}
                                placeholder={t("ARTICLES.SEARCH")}
                                style={{ width: "100%" }}
                                disabled={loading}
                                fetchOptions={searchArticle}
                                onSelect={v => setSelectedArticle(articles.find((a: any) => a.key = v.value))}
                                onOptionsUpdated={setArticles}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"qty"}
                            label={t("QUANTITY")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                },
                            ]}
                            initialValue={1}
                        >
                            <NumberInput />
                        </Form.Item>
                        {selectedArticle?.multiple > 1 ? (
                            <Alert
                                message={t('ARTICLE.WITH_MULTIPLIER.DESCRIPTION', {multiple: selectedArticle.multiple})}
                                type="info"
                                showIcon
                            />
                        ) : null}
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"price"}
                            label={t("PRICE")}
                            tooltip={"CUSTOM_PRICE.TOOLTIP"}
                        >
                            <NumberInput />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
