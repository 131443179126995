import { action, makeObservable, observable } from "mobx";
import { SmartLoggerData } from "../../models/logger/logger.data";
import { PaginatedRequest } from "../../models/core/paginated.request";
import { FilterDataHandler } from "../../models/filter/filter.data.handler";
import { loggerService } from "../../services/logger/logger.service";
import { PaginatedResult } from "../../models/core/paginated.result";

export class SmartLoggerStore {
    @observable
    public entities: string[] = [];

    @observable
    public actions: string[] = [];

    @observable
    public loading: boolean = false;

    private request: PaginatedRequest = new PaginatedRequest();

    public dataHandler: FilterDataHandler<SmartLoggerData>;

    constructor() {
        makeObservable(this);
        SmartLoggerStore.convertLoggerData = SmartLoggerStore.convertLoggerData.bind(this);
        this.dataHandler = new FilterDataHandler<SmartLoggerData>(
            (request) => loggerService.getSmartLoggerData(request),
            SmartLoggerStore.convertLoggerData
        );

        this.init();
    }

    private static convertLoggerData(data: PaginatedResult<SmartLoggerData>): PaginatedResult<SmartLoggerData> {
        return {
            meta: data.meta,
            items: data.items.map((item, index) => ({ ...item, id: data.meta.page * data.meta.limit + index })),
        };
    }

    @action
    private setEntities(value: string[]): void {
        this.entities = value;
    }

    @action
    private setActions(value: string[]): void {
        this.actions = value;
    }

    // private static convertLoggerData(data: PaginatedResult<LoggerData>):

    private async init() {
        this.setActions(await loggerService.getActions());
        this.setEntities(await loggerService.getEntities());
    }
}
