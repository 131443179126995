import React, {useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import FilteredTable from "../../common/filtered-table/filtered.table";
import {declarationService} from "../../../services/declarations/declarations.services";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import {FilterKey} from "../../../models/filtered-table/filtered.table.props";
import {Link} from "react-router-dom";
import {Declaration} from "../../../models/declarations/declaration";
import {useTranslate} from "../../../hooks/translate.hook";
import {Alert, Button, Dropdown, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TableRowSelection} from "antd/es/table/interface";
import {filtersFactory} from "./declarations.list.filter";
import {columnsFactory} from "./declarations.list.columns";
import {DownloadXMLModal} from "../view/modal/download-xml/download-xml.modal";
import {downloadBlobFile} from "../../../utils/blob.download";

export const DeclarationsList = observer(() => {
    const t = useTranslate();

    const rootStore = useRequiredStore(RootStoreContext);
    const langStore = rootStore.langStore;

    const columns = useMemo(() => columnsFactory(t), [t]);
    const filters: FilterKey[] = useMemo(() => filtersFactory(t, langStore.countries), [t, langStore.countries]);

    const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
    // const [selectedDeclarations, setSelectedDeclarations] = useState<Declaration[]>([]);

    const [downloadXMLModalShown, setDownloadXMLModalShown] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const rowSelection: TableRowSelection<Declaration> = {
        type: "checkbox",
        selectedRowKeys: selectedKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys: React.Key[], selectedRows: Declaration[]) => {
            setSelectedKeys(selectedRowKeys as number[]);
            // setSelectedDeclarations(selectedRows);
        },
        getCheckboxProps: (item: Declaration) => ({
            name: "item_" + String(item.id)
        }),
    }

    const clearSelection = () => {
        setSelectedKeys([]);
        // setSelectedDeclarations([]);
    }

    const downloadSourceCSV = async () => {
        try {
            setLoading(true);
            const {blob, filename} = await declarationService.downloadAllSourceCSV(selectedKeys);
            downloadBlobFile(blob, filename)
        } finally {
            setLoading(false);
        }
    }

    const downloadAsJSON = async () => {
        try {
            setLoading(true);
            const {blob, filename} = await declarationService.downloadAllJSON(selectedKeys);
            downloadBlobFile(blob, filename)
        } finally {
            setLoading(false);
        }
    }

    const downloadAsXML = async (maxDocuments: number) => {
        try {
            setLoading(true);
            const {blob, filename} = await declarationService.downloadAllAsXML(selectedKeys, maxDocuments);
            downloadBlobFile(blob, filename)
        } finally {
            setLoading(false);
        }
    }

    return (
        <Space direction={"vertical"} size={[10, 10]}>
            <Space direction={"horizontal"} style={{justifyContent: "space-between"}}>
                <Link to={"/declarations/upload"}>
                    <Button
                        key={1}
                        icon={<FontAwesomeIcon icon={["fas", "plus"]} />}
                        className="filter-table-btn"
                        type="primary"
                    >
                        {t("DECLARATIONS.CREATE")}
                    </Button>
                </Link>
                <Dropdown
                    disabled={loading}
                    menu={{
                        items: [
                            {
                                key: 0,
                                label: 'Download source CSV',
                                disabled: selectedKeys.length === 0,
                                onClick: downloadSourceCSV,
                            },
                            {
                                key: 1,
                                label: 'Download as XML',
                                disabled: selectedKeys.length === 0,
                                onClick: () => setDownloadXMLModalShown(true)
                            },
                            {
                                key: 2,
                                label: 'Download as JSON',
                                disabled: selectedKeys.length === 0,
                                onClick: downloadAsJSON
                            }
                        ]
                    }}
                >
                    <Button type={"primary"}>
                        {t("ACTIONS")}
                    </Button>
                </Dropdown>
            </Space>
            {selectedKeys.length > 0 ? (
                <Alert
                    message={
                        <Space direction={"horizontal"} style={{justifyContent: "space-between"}}>
                            <span>Selected declarations: {selectedKeys.length}</span>
                            <Button size={"small"} type={"primary"} onClick={clearSelection}>Clear</Button>
                        </Space>
                    }
                    type="info"
                    style={{ marginTop: 20 }}
                />
            ) : null}
            {downloadXMLModalShown ? (
                <DownloadXMLModal
                    onClose={() => setDownloadXMLModalShown(false)}
                    onComplete={(maxDocuments) => downloadAsXML(maxDocuments)}
                />
            ) : null}
            <FilteredTable<Declaration, {}>
                filterKeys={filters}
                loadDataCallback={request => declarationService.getAll(request)}
                columns={columns}
                rowSelection={rowSelection}
                />
        </Space>
    );
});
