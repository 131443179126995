import React from "react";
import {observer} from "mobx-react";
import {Announcement, AnnouncementMessage, AnnouncementRequest} from "../../../models/announcements/announcement";
import {Button, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Tooltip, Typography} from "antd";
import {useTranslate} from "../../../hooks/translate.hook";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../models/core/response";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import "./announcements.modal.scss";
import {DebounceSelect} from "../../common/debounce-select/debounce.select";
import {AnnouncementsStoreContext} from "../announcements.store";
import dayjs from "dayjs";

interface AnnouncementsModalProps {
    item?: Announcement,
    visible: boolean,
    onSuccess: (request: AnnouncementRequest, item?: Announcement) => Promise<void>,
    onClose: () => void,
    locales: string[]
}

export const AnnouncementsModal: React.FC<AnnouncementsModalProps> = observer(
    ({
         item,
         visible,
         onClose,
         onSuccess,
         locales
     }) => {
        const store = useRequiredStore(AnnouncementsStoreContext);
        const [form] = Form.useForm();
        const [loading, setLoading] = React.useState(false);
        const t = useTranslate();
        const {commonStore: {warehouses}} = useRequiredStore(RootStoreContext);

        const isSelectOptionDisabled = (language: string) => form.getFieldValue("messages").map((item: AnnouncementMessage) => item.language).includes(language);

        const defaultValues = !item ? {messages: [{value: "", language: "en"}]} : {
            account: item.account ? {value: item.account?.id, label: item.account?.agent.number + " - " + item.account?.agent.name} : undefined,
            message: item.message,
            duplicateInCart: item.duplicateInCart,
            startedAt: dayjs(item.startedAt),
            endedAt: dayjs(item.endedAt),
            warehouses: item.warehouses?.map(item => item.id) || [],
            messages: item.messages
        };

        return (
            <Modal
                visible={visible}
                title={t(item ? "ANNOUNCEMENTS.EDIT" : "ANNOUNCEMENTS.ADD")}
                okText={t(item ? "SAVE" : "ADD")}
                cancelText={t("CANCEL")}
                onCancel={() => {
                    onClose();
                    form.resetFields();
                }}
                onOk={() => form.submit()}
                confirmLoading={loading}
                style={{top: 30}}
                width={700}
            >
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={defaultValues}
                    onFinish={() => form
                        .validateFields()
                        .then(values => {
                            setLoading(true);
                            onSuccess({...values, account: values.account ? values.account.value : null}, item).then(
                                () => {
                                    !item && form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                                .then(() => {
                                }, (e) => form.setFields(e.response?.data?.fields))
                                .finally(() => setLoading(false));
                        })
                    }
                >
                    <Space direction="vertical" size={[20, 20]}>
                        <Row gutter={[10, 0]}>
                            <Col span={24}>
                                <Typography.Title level={5}>{t("ANNOUNCEMENTS.ANNOUNCEMENT_DETAILS")}</Typography.Title>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="warehouses"
                                    label={t("WAREHOUSES.TITLE")}
                                >
                                    <Select
                                        allowClear
                                        mode="multiple"
                                        showSearch
                                        optionFilterProp={"children"}
                                        disabled={loading}
                                        placeholder={t("WAREHOUSES.TITLE")}
                                    >
                                        {warehouses.map(({id, name}) => (
                                            <Select.Option key={id} value={id}>{name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="account"
                                    label={t("CLIENT.NAME")}
                                >
                                    <DebounceSelect
                                        disabled={loading}
                                        placeholder={t("CLIENT.SEARCH")}
                                        style={{width: "100%"}}
                                        fetchOptions={store.searchAgents}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    rules={[{
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_SELECT_VALUE")
                                    }]}
                                    name="startedAt"
                                    label={t("ANNOUNCEMENTS.STARTED_AT")}
                                >
                                    <DatePicker
                                        showTime
                                        format="YYYY/MM/DD HH:mm"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    rules={[{
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_SELECT_VALUE")
                                    }]}
                                    name="endedAt"
                                    label={t("ANNOUNCEMENTS.ENDED_AT")}
                                >
                                    <DatePicker
                                        showTime
                                        format="YYYY/MM/DD HH:mm"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="duplicateInCart" valuePropName={"checked"}>
                                    <Checkbox disabled={loading}>{t("ANNOUNCEMENTS.DUPLICATE_IN_CART")}</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[10, 0]} align={"top"} justify="space-between">
                            <Col span={24}>
                                <Typography.Title level={5}>{t("ANNOUNCEMENTS.TRANSLATIONS")}</Typography.Title>
                            </Col>
                            <Col span={24}>
                                <Form.List
                                    name={"messages"}
                                    rules={[{
                                        validator: async (rule, names) => {
                                            try {
                                                if (!names || names.length === 0) {
                                                    return Promise.reject((t("FROM.ERROR.PLEASE_ADD_VALUE")));

                                                }
                                            } catch (error) {
                                                throw new Error(error as string);
                                            }
                                        }
                                    }]}
                                >
                                    {(fields, {add, remove}, {errors}) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <Row justify="space-between" gutter={0} key={field.key}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            noStyle
                                                            shouldUpdate={(prevValues, nextValues) => prevValues.length !== nextValues.length}
                                                        >
                                                            {() => (
                                                                <Form.Item
                                                                    {...field}
                                                                    labelCol={{
                                                                        xxl: 24,
                                                                        xl: 24,
                                                                        lg: 24,
                                                                        md: 24,
                                                                        sm: 24,
                                                                        xs: 24
                                                                    }}
                                                                    name={[field.name, "language"]}
                                                                    
                                                                    rules={[{
                                                                        required: true,
                                                                        message: t("FROM.ERROR.PLEASE_SELECT_VALUE")
                                                                    }]}
                                                                    label={
                                                                        <Row style={{width: "100%"}}
                                                                             justify="space-between" align="middle">
                                                                            <span>{t("ANNOUNCEMENTS.MESSAGE")}</span>
                                                                            <div>
                                                                                <Tooltip placement="topRight"
                                                                                         title={t("DELETE")}>
                                                                                    <Button
                                                                                        disabled={loading}
                                                                                        type="default"
                                                                                        onClick={() => remove(field.name)}
                                                                                        icon={<FontAwesomeIcon
                                                                                            icon={["fas", "times"]}/>}
                                                                                        className="btn btn-remove btn-flex-center"
                                                                                    />
                                                                                </Tooltip>
                                                                            </div>
                                                                        </Row>
                                                                    }
                                                                >
                                                                    <Select
                                                                        showSearch
                                                                        allowClear
                                                                        optionFilterProp={"children"}
                                                                        disabled={loading}
                                                                        placeholder={t("LOCALES")}
                                                                    >
                                                                        {locales.map(item => (
                                                                            <Select.Option
                                                                                key={item}
                                                                                value={item}
                                                                                disabled={isSelectOptionDisabled(item)}
                                                                            >
                                                                                {item.toUpperCase()}
                                                                            </Select.Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, "shortDescription"]}
                                                            
                                                            rules={[{
                                                                required: true,
                                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE")
                                                            }]}
                                                        >
                                                            <Input.TextArea
                                                                cols={5}
                                                                rows={3}
                                                                disabled={loading}
                                                                placeholder={t("ANNOUNCEMENTS.SHORT_MESSAGE")}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, "description"]}
                                                            
                                                            rules={[{
                                                                required: true,
                                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE")
                                                            }]}
                                                        >
                                                            <Input.TextArea
                                                                cols={5}
                                                                rows={5}
                                                                disabled={loading}
                                                                placeholder={t("ANNOUNCEMENTS.MESSAGE")}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    disabled={loading}
                                                    onClick={() => {
                                                        add({});
                                                    }}
                                                    block
                                                    icon={<FontAwesomeIcon icon={["fas", "plus-circle"]}/>}
                                                >
                                                    {t("LANGUAGES.ADD_TRANSLATION")}
                                                </Button>
                                                <Form.ErrorList errors={errors}/>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        </Row>
                    </Space>

                </Form>
            </Modal>
        );
    });
