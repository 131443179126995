import React, {useMemo, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {Space} from "antd";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import {columnsFactory} from "./branch.data.prices.columns";
import {useTranslate} from "../../../../hooks/translate.hook";
import {convertTableColumnsToColumnConfigs} from "../../../../utils/table";
import {filtersFactory} from "./branch.data.prices.filters";
import {branchesService} from "../../../../services/branches/branches.service";
import {useParams} from "react-router-dom";
import {PriceList} from "../../../../models/price-lists/price.lists";
import {FilterDataHandler} from "../../../../models/filter/filter.data.handler";
import {UploadModal} from "./upload/upload.modal";
import {EditModal} from "./edit/edit.modal";
import {downloadBlobFile} from "../../../../utils/blob.download";
interface BranchDataPricesProps extends ComponentPropsFromRoute {
}

export const BranchDataPrices: React.FC<BranchDataPricesProps> = ({name}) => {

    const { branchId } = useParams<{ branchId: string }>();

    const t = useTranslate();

    const [editModalItem, setEditModalItem] = useState<PriceList | null>(null);
    const [uploadModalItem, setUploadModalItem] = useState<PriceList | null>(null);

    const download = async (price: PriceList) => {
        const data = await branchesService.downloadPrice(price.id);
        downloadBlobFile(data.blob, data.filename)
    }

    const columns = useMemo(() => columnsFactory(t, setEditModalItem, setUploadModalItem, download), []);
    const filters = useMemo(() => filtersFactory(t), []);

    const dataHandler = useMemo(() => new FilterDataHandler(r => branchesService.getPrices(branchId!, r)), [])

    return (
        <Space direction="vertical" size={[0, 0]}>
            <FilteredTable
                filterKeys={filters}
                columns={columns}
                dataHandler={dataHandler}
                columnsConfigs={{ columns: convertTableColumnsToColumnConfigs(columns), pageKey: name }}
            />
            {editModalItem ? (
                <EditModal
                    priceList={editModalItem}
                    onCancel={() => setEditModalItem(null)}
                    onComplete={() => dataHandler.reloadWithLastRequest()}
                    branchId={branchId!}
                />
            ) : null}
            {uploadModalItem ? (
                <UploadModal
                    priceList={uploadModalItem}
                    onCancel={() => setUploadModalItem(null)}
                    onComplete={() => dataHandler.reloadWithLastRequest()}
                />
            ) : null}
        </Space>
    );
}