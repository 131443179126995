import {CreditInvoice} from "../../../../models/documents/invoices/credit-invoice/credit.invoice";
import {TableFooterData} from "../../../../models/table-footer/table.footer.data";

export const generateDocumentDetails = (invoice: CreditInvoice): TableFooterData => {
    return {
        qty: invoice.qty,
        taxPercent: invoice.vatPercent,
        totalWeight: 0,
        taxAmount: invoice.subtotalVat,
        totalAmount: invoice.subtotal,
        grandTotalAmount: invoice.grandTotal
    }
}