import React, {useMemo} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {useTranslate} from "../../../../hooks/translate.hook";
import {plannerService} from "../../../../services/planner/planner.service";
import {Button, Space} from "antd";
import {PlannerTask} from "../../../../models/planner/planner";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CardsViewDataHandler} from "../../../common/cards-view/cards-view.data-handler";
import {CardsView} from "../../../common/cards-view/cards-view";
import {PlannerTasksListCard} from "./tasks.list.card";
import {filtersFactory} from "./tasks.filters";
import "./tasks.list.scss"
import {typesConfig} from "../../ui/cards-view/filter/widget/task/task-type/task.type.widget";

export const PlannerTasksList: React.FC<ComponentPropsFromRoute> = () => {

    const t = useTranslate();
    const handler = useMemo(() => new CardsViewDataHandler(r => plannerService.getTasks(r)), [])
    const filters = useMemo(() => filtersFactory(t), []);

    return (
        <Space direction={"vertical"} size={[20, 20]}>
            <Link to={"/planner/tasks/create"}>
                <Button type={"primary"} icon={<FontAwesomeIcon icon={["fas", "plus"]} />} ghost>
                    {t("PLANNER.CREATE_NEW_TASK")}
                </Button>
            </Link>
            <CardsView
                handler={handler}
                render={(item: PlannerTask) => <PlannerTasksListCard item={item} />}
                filters={filters}
                initialFilters={{status: typesConfig[0].values}}
            />
        </Space>
    )
}