import React from "react";
import {WidgetProps} from "../widget";
import {WarehouseSelect} from "../../../../../warehouses/common/warehouse-select/warehouse-select";

export interface UIInputWidgetWarehouseProps extends WidgetProps {
    allowedOnly?: string[]
}

export const UIInputWidgetWarehouse: React.FC<UIInputWidgetWarehouseProps> = ({value, onChange, multiple, allowedOnly}) => {

    return (
        <WarehouseSelect
            value={value as any}
            onChange={onChange}
            selectMode={multiple ? "multiple" : undefined}
            selectStyle={{width: "320px"}}
            maxTagCount={3}
            allowedOnly={allowedOnly}
        />
    )
}