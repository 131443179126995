import React, { useState } from "react";
import { Modal, Form, Input, Row, Col } from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { AxiosError } from "axios";
import { ErrorResponseData } from "../../../../../models/core/response";
import { useRequiredStore } from "../../../../../utils/store";
import { AgentDataStoreContext } from "../../agent.data.store";
import { CustomerAccountContact } from "../../../../../models/accounts/customer-account/contact/customer.account.contact";

interface AgentDataContactsModalProps {
    visible: boolean;
    item?: CustomerAccountContact | undefined;
    onSubmit: (contact: CustomerAccountContact, id: number) => Promise<any>;
    onClose: () => void;
}

export const AgentDataContactsModal: React.FC<AgentDataContactsModalProps> = ({
    visible,
    item,
    onSubmit,
    onClose,
}) => {
    const store = useRequiredStore(AgentDataStoreContext);

    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={t(item ? "CONTACTS.EDIT" : "CONTACTS.ADD")}
            okText={t(item ? "SAVE" : "ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"contacts_form"}
                initialValues={item}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        onSubmit(values, item ? item.id : store.account!.id)
                            .then(
                                () => {
                                    !item && form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(
                                            e.response?.data?.fields
                                        );
                                    }
                                }
                            )
                            .then(
                                () => {},
                                (e) => form.setFields(e.response?.data?.fields)
                            )
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            name="firstName"
                            label={t("FIRSTNAME")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                type="text"
                                placeholder={t("FIRSTNAME")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="lastName"
                            label={t("LASTNAME")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                type="text"
                                placeholder={t("LASTNAME")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="position"
                            label={t("POSITION")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                type="text"
                                placeholder={t("POSITION")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="email"
                            label={t("EMAIL")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                type="text"
                                placeholder={t("EMAIL")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="workPhone"
                            label={t("WORKPHONE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                type="text"
                                placeholder={"+37200000000"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="mobilePhone" label={t("MOBILEPHONE")}>
                            <Input
                                disabled={loading}
                                type="text"
                                placeholder={"+37200000000"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="characteristic"
                            label={t("CHARACTERISTIC")}
                        >
                            <Input
                                disabled={loading}
                                type="text"
                                placeholder={t("CHARACTERISTIC")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
