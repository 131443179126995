import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Typography, Space, Button, Popconfirm, Tooltip } from "antd";

import { observer } from "mobx-react";

import React, { useState } from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { BranchBank } from "../../../../models/branches/branch-data";
import { useRequiredStore } from "../../../../utils/store";
import { CustomCard } from "../../../common/custom-card/custom.card";
import { BranchDataStoreContext } from "../branch.data.store";
import { BranchDataBanksModal } from "./branch.data.banks.modal";
import {BranchDataBanksStoreContext} from "./branch.data.banks.store";

interface BranchDataBanksCardProps {
    bank: BranchBank;
}
export const BranchDataBanksCard: React.FC<BranchDataBanksCardProps> = observer(
    ({ bank }) => {
        const t = useTranslate();
        const store = useRequiredStore(BranchDataBanksStoreContext);
        const [modalShown, setModalShown] = useState<boolean>(false);

        const cardRows = [
            { key: "name" },
            { key: "address" },
            { key: "iban" },
            { key: "swift" },
        ];

        return (
            <>
                <Card
                    className={"card"}
                    title={
                        <Typography.Text title={bank.visibleName}>
                            {bank.visibleName}
                        </Typography.Text>
                    }
                    extra={
                        <Space
                            key={0}
                            direction="horizontal"
                            size={[5, 5]}
                            align="center"
                            style={{ width: "auto" }}
                        >
                            <Tooltip placement="topRight" title={t("EDIT")}>
                                <Button
                                    size={"small"}
                                    className={"card-btn btn"}
                                    onClick={() => setModalShown(true)}
                                    type="default"
                                    icon={
                                        <FontAwesomeIcon
                                            icon={["fas", "pencil-alt"]}
                                        />
                                    }
                                />
                            </Tooltip>
                            <Popconfirm
                                title={t("ARE_YOU_SURE")}
                                onConfirm={() => store.removeBank(bank)}
                                placement="top"
                                okText={t("YES")}
                                cancelText={t("NO")}
                            >
                                <Tooltip
                                    placement="topRight"
                                    title={t("REMOVE")}
                                >
                                    <Button
                                        className="card-btn btn-remove btn"
                                        type="default"
                                        size="small"
                                        icon={
                                            <FontAwesomeIcon
                                                icon={["fas", "times"]}
                                            />
                                        }
                                    />
                                </Tooltip>
                            </Popconfirm>
                        </Space>
                    }
                >
                    <CustomCard data={bank} rowKeys={cardRows} />
                </Card>
                <BranchDataBanksModal
                    item={bank}
                    visible={modalShown}
                    onClose={() => setModalShown(false)}
                />
            </>
        );
    }
);
