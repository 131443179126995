import {Button, Space, Typography} from "antd";
import {ExtendedSwitch} from "../../core/router/extended-switch/extended.switch";
import React from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {useTranslate} from "../../../hooks/translate.hook";
import {Link} from "react-router-dom";

export const AccountingMain: React.FC<ComponentPropsFromRoute> = ({routeChildren}) => {

    const t = useTranslate();

    return (
        <Space direction={"vertical"} size={[30, 30]}>
            <Typography.Title level={3} style={{margin: 0}}>
                {t("ACTIONS")}
            </Typography.Title>
            <Space direction={"horizontal"}>
                <Link to={"/accounting/monthly-statements"}>
                    <Button type={"default"}>
                        {t("REPORTS.ACCOUNTING.MONTHLY-STATEMENTS.TITLE")}
                    </Button>
                </Link>
                <Link to={"/accounting/tax-rules"}>
                    <Button type={"default"}>
                        {t("WAREHOUSES.TAXES.TITLE")}
                    </Button>
                </Link>
            </Space>
            <ExtendedSwitch routers={routeChildren!} />
        </Space>
    )
}