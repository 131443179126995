import { articlesService } from "../../../../services/articles/articles.service";
import { isAllowed } from "../../../../utils/helpers";
import { Order } from "../../../../models/orders/orders-list/order";
import { action, makeObservable, observable } from "mobx";
import { ProformaData } from "../../../../models/documents/proformas/proforma.data";
import {
    PayerOrReceiverDetails,
    PayerReceiverFormValues,
    SellerDetails,
} from "../../../../models/payer-receiver-seller/payer.reveiver.seller";
import { proformasService } from "../../../../services/documents/proformas/proformas.service";
import React from "react";
import { PagesStore } from "../../../../stores/pages/pages.store";
import {addressService} from "../../../../services/documents/common/address.service";
import {senderService} from "../../../../services/documents/common/sender.service";
import {notifications} from "../../../../services/notifications/notifications";

export class ProformaDataStore {
    @observable
    public includedOrders: Order[] = [];

    @observable
    public stockAmount?: number;

    @observable
    public proformaData: ProformaData | null = null;

    @observable
    public loading: boolean = false;

    @observable
    public exportModalShown: boolean = false;

    @observable
    public sourcesListShown: boolean = false;

    constructor(private id: string, private pageStore: PagesStore, private url: string, private attributes: string[]) {
        makeObservable(this);
        this.setSourcesListShown = this.setSourcesListShown.bind(this);
        this.init();
    }

    @action
    public setStockAmount(value?: number): void {
        this.stockAmount = value;
    }

    @action
    private setIncludedOrders(value: Order[]): void {
        this.includedOrders = value;
    }

    @action
    private setProformaData(value: ProformaData): void {
        this.proformaData = value;
    }

    @action
    private setLoading(value: boolean): void {
        this.loading = value;
    }

    @action
    private setSellerData(seller: SellerDetails): void {
        this.proformaData!.sellerAddress = seller;
    }

    @action
    private setBillingData(data: PayerOrReceiverDetails): void {
        this.proformaData!.billingAddress = data;
    }

    @action
    private setShippingData(data: PayerOrReceiverDetails): void {
        this.proformaData!.shippingAddress = data;
    }

    public editSellerData = async (values: SellerDetails) => {
        await this.setSellerData(await addressService.updateSellerAddress(this.proformaData!.id, values));
    };

    public editPayerReceiverData = async (type: "payer" | "receiver", values: PayerReceiverFormValues) => {
        if (type === "payer") {
            this.setBillingData(await addressService.updateBillingAddress(this.proformaData!.id, values));
        } else {
            this.setShippingData(await addressService.updateShippingAddress(this.proformaData!.id, values));
        }
    };

    private async init() {
        this.setLoading(true);
        this.setProformaData(await proformasService.getProforma(this.id));
        this.pageStore.updateRouteName(this.url, this.proformaData?.docNumber);
        if (isAllowed(this.attributes, "document_proforma_create")) {
            this.setIncludedOrders(await proformasService.getIncludedOrders(this.proformaData!.id));
        }
        this.setLoading(false);
    }

    public async removeIncludedOrder(order: Order) {
        await proformasService.removeIncludedOrder(this.proformaData!.id, order.id);
        const index = this.includedOrders.findIndex((item) => item.id === order.id);
        this.removeIncludedOrderByIndex(index);
        this.setProformaData(await proformasService.getProforma(this.id));
    }

    @action
    private removeIncludedOrderByIndex(index: number) {
        if (index !== -1) {
            this.includedOrders.splice(index, 1);
        }
    }

    public closeProforma = async () => {
        await proformasService.closeProforma(this.proformaData!.id);
        this.setProformaData(await proformasService.getProforma(this.id));
    };

    public updateQty = async (id: number, values: { qty: string }) => {
        await proformasService.updateProformaQuantity(this.proformaData!.id, id, { qty: parseInt(values.qty, 10) });
        this.setProformaData(await proformasService.getProforma(this.id));
    };

    public remove = async (record: any) => {
        await proformasService.removeItem(this.proformaData!.id, record.id);
        this.setProformaData(await proformasService.getProforma(this.id));
    };

    public getArticleAmount = async (record: any) => {
        const { stock: stockAmount } = await articlesService.getStockAmount(
            record.articleId,
            this.proformaData!.warehouse.id
        );
        this.setStockAmount(stockAmount + record!.qty);
    };

    public cancelProforma = async () => {
        await proformasService.cancelProforma(this.proformaData!.id);
        this.setProformaData(await proformasService.getProforma(this.id));
        this.setIncludedOrders([]);
    };

    public cancelProformaWithOrders = async () => {
        await proformasService.cancelProformaWithOrders(this.proformaData!.id);
        this.setProformaData(await proformasService.getProforma(this.id));
        this.setIncludedOrders([]);
    };

    @action
    public setExportModalShown = (value: boolean): void => {
        this.exportModalShown = value;
    };

    @action
    public setSourcesListShown(value: boolean) {
        this.sourcesListShown = value;
    }

    public async sendDocumentToClient() {
        await senderService.send(parseInt(this.id));
        notifications.successfully()
    }
}

export const ProformaDataStoreContext = React.createContext<ProformaDataStore | null>(null);
