import React, {useCallback, useMemo, useState} from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {Button, DatePicker, Dropdown, Space, Typography} from "antd";
import {SlotTable} from "../../common/slot-table/slot-table";
import {useTranslate} from "../../../hooks/translate.hook";
import {SlotTableData} from "../../common/slot-table/slot-table.model";
import {
    generateCellsForSlots,
    generateColX,
    generateColY, getBack,
    getKeyByCoordinateKeys, getNext, getValuesFromRange,
    getValuesFromURL,
    handleRangeDate,
    RangeType
} from "./calendar.helpers";
import {useURLParams} from "../../../hooks/url-params.hook";
import {calendarService} from "../../../services/calendar/calendar.service";
import {Slot} from "../../../models/calendar/slot";
import dayjs from "dayjs";
import {EditSlotModal} from "./modal/edit-slot/edit-slot.modal";
import {NewSlotModal} from "./modal/new-slot/new-slot.modal";
import {downloadBlobFile} from "../../../utils/blob.download";

export const Calendar: React.FC<ComponentPropsFromRoute> = ({name}) => {

    const t = useTranslate();
    const {urlParams, setURLParams} = useURLParams();

    const colY = useMemo(() => generateColY(), [])
    const [dateRange, setDateRange] = useState<RangeType>(getValuesFromURL(urlParams));

    const [editSlot, setEditSlot] = useState<Slot | null>(null);
    const [newSlotDate, setNewSlotDate] = useState<dayjs.Dayjs | null>(null);

    const [loading, setLoading] = useState<boolean>(false);

    const wrappedSetDateRange = ([start, end]: RangeType) => {
        [start, end] = handleRangeDate([start, end])
        setDateRange([start, end])
        setURLParams(getValuesFromRange([start, end]))
    }

    const getData = useCallback(async (): Promise<SlotTableData> => {
        const slots = await calendarService.getSlots(dateRange);
        return ({
            cells: generateCellsForSlots(slots),
            colsX: generateColX(dateRange),
            colsY: colY
        });
    }, [colY, dateRange])

    const downloadXLSX = async () => {
        setLoading(true);
        try {
            const {blob, filename} = await calendarService.download(dateRange);
            downloadBlobFile(blob, filename)
        } finally {
            setLoading(false);
        }
    }

    return (
        <Space direction="vertical" size={[10, 10]}>
            <Typography.Title level={3}>{t("CALENDAR.TITLE")}</Typography.Title>
            <Space style={{justifyContent: "space-between"}}>
                <DatePicker.RangePicker
                    value={dateRange}
                    onChange={v => wrappedSetDateRange(v as any)}
                />
                <Dropdown
                    disabled={loading}
                    menu={{
                        items: [
                            {
                                key: 0,
                                label: t("DOCUMENTS.FILES.DOWNLOAD_XLS"),
                                onClick: () => downloadXLSX(),
                            },
                        ]
                    }}
                >
                    <Button type={"primary"}>
                        {t("ACTIONS")}
                    </Button>
                </Dropdown>
            </Space>

            <SlotTable
                getData={getData}
                getKeyByCoordinateKeys={getKeyByCoordinateKeys}
                onCellClick={cell => setEditSlot(cell.value)}
                onEmptyCellClick={(X, Y) => setNewSlotDate(dayjs(X.name + 'T' + Y.name + ':00'))}
                back={() => setDateRange(r => getBack(r))}
                next={() => setDateRange(r => getNext(r))}
            />
            {editSlot ? (
                <EditSlotModal
                    slot={editSlot}
                    onCancel={() => setEditSlot(null)}
                    onComplete={() => setDateRange(r => [...r])}
                />
            ) : null}
            {newSlotDate ? (
                <NewSlotModal
                    date={newSlotDate}
                    onCancel={() => setNewSlotDate(null)}
                    onComplete={() => setDateRange(r => [...r])}
                />
            ) : null}
        </Space>
    )
}