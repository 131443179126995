import {Translate} from "../../../../../hooks/translate.hook";
import {FilterKey} from "../../../../../models/filtered-table/filtered.table.props";

export const filtersFactory = (t: Translate): FilterKey[] => {
    return [
        {
            key: "id",
            label: "ID",
            type: "string",
        },
        {
            key: "number",
            label: t("INVOICES.NUMBER"),
            type: "string",
        },
        {
            key: "grandTotal",
            label: t("TOTAL_AMOUNT"),
            type: "int",
        },
        {
            key: "subtotal",
            label: t("TOTAL_PRICE"),
            type: "int",
        },
        {
            key: "subtotalVat",
            label: "VAT",
            type: "int",
        },
        {
            key: "date",
            label: t("DATE"),
            type: "date",
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date",
        },
    ];
}