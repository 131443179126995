import React, {useState} from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/es/table";
import {Link} from "react-router-dom";
import {Amount} from "../../../../../models/prices/price";
import {displayPrice} from "../../../../../models/prices/price.helper";
import {TableFooter} from "../../../../common/table-footer/table.footer";
import {generateDocumentDetails} from "../../../../../models/documents/invoices/invoice-data/invoice.data.helper";
import {useRequiredStore} from "../../../../../utils/store";
import {MergedInvoiceDataStoreContext} from "../merged.invoice.data.store";
import {ExpandedTable} from "../../../../common/order-details/expanded.table";
import {InvoiceDetailsItem} from "../../../../../models/documents/invoices/invoice-data/invoice.data";
import {convertTableColumnsToColumnConfigs} from "../../../../../utils/table";
import {InvoiceUpdateWeightModal} from "../../invoice-data/invoice-update-weight-modal/invoice-update-weight-modal";
import {Button, Dropdown, Menu} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CreditInvoiceCreateModal} from "../../credit-invoice-create-modal/credit-invoice-create-modal";
import {useNavigate} from "react-router";
import {nonStrictComparator} from "../../../../ui/table/filter-dropdown/filter-dropdown.comparator";
import {FilterDropdown} from "../../../../ui/table/filter-dropdown/filter-dropdown";

export const MergedInvoiceDataOrderDetails: React.FC = observer(() => {
    const store = useRequiredStore(MergedInvoiceDataStoreContext);
    const t = useTranslate();
    const navigate = useNavigate();

    const [creditModalItem, setCreditModalItem] = useState<InvoiceDetailsItem | null>();
    const [editWeightModalItem, setEditWeightModalItem] = useState<InvoiceDetailsItem | null>();

    const renderActions = (item: InvoiceDetailsItem) => (
        <Dropdown
            placement={"bottomRight"}
            trigger={["click"]}
            overlay={
                <Menu>
                    <Menu.Item
                        key={0}
                        icon={<FontAwesomeIcon icon={["fas", "money-check-alt"]} />}
                        onClick={() => setCreditModalItem(item)}
                    >
                        {t("DOCUMENTS.INVOICES.CREDIT.CREATE")}
                    </Menu.Item>
                    <Menu.Item
                        key={1}
                        icon={<FontAwesomeIcon icon={["fas", "balance-scale-left"]} />}
                        onClick={() => setEditWeightModalItem(item)}
                    >
                        {t("DOCUMENTS.INVOICES.WEIGHT.UPDATE")}
                    </Menu.Item>
                </Menu>
            }
        >
            <Button
                type={"text"}
                icon={<FontAwesomeIcon icon={["fas", "ellipsis-v"]}
                />}>
            </Button>
        </Dropdown>
    )

    const columns: ColumnsType<InvoiceDetailsItem> = [
        {
            title: t("ORDERS.ORDER.ID"),
            dataIndex: "referenceOrderId",
            key: "merged_invoice_data_order_detail_referenceOrderId",
            width: 120,
            render: item => item ? <Link to={"/orders/" + item}>{item}</Link> : null,
            sorter: (a, b) => a.referenceOrderId > b.referenceOrderId ? 1 : -1,
            onFilter: nonStrictComparator(v => v.referenceOrderId),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("CLIENT.ORDER.NUMBER"),
            dataIndex: "referenceOrderNumber",
            key: "merged_invoice_data_order_detail_referenceOrderNumber",
            width: 120,
            sorter: (a, b) => a.referenceOrderNumber > b.referenceOrderNumber ? 1 : -1,
            onFilter: nonStrictComparator(v => v.referenceOrderNumber),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("HS_CODE"),
            dataIndex: "hsCode",
            key: "hsCode",
            width: 120,
            sorter: (a, b) => a.hsCode > b.hsCode ? 1 : -1,
            onFilter: nonStrictComparator(v => v.hsCode),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "articleName",
            key: "merged_invoice_data_order_detail_articleName",
            width: "135px",
            render: (article: string) => article.toUpperCase() !== "DELIVERY" ? (
                <Link to={"/articles/" + article} style={{ whiteSpace: "nowrap" }}>
                    {article}
                </Link>
            ) : t("DELIVERY.TITLE"),
            sorter: (a, b) => a.articleName > b.articleName ? 1 : -1,
            onFilter: nonStrictComparator(v => v.articleName),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "merged_invoice_data_order_detail_description",
            width: "300px"
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "merged_invoice_data_order_detail_qty",
            width: 80,
            sorter: (a, b) => a.qty > b.qty ? 1 : -1,
        },
        {
            title: t("PRICE"),
            dataIndex: "price",
            key: "merged_invoice_data_order_detail_price",
            width: 150,
            render: (price: Amount) => <span>{displayPrice(price)}</span>,
            sorter: (a, b) => a.price.value > b.price.value ? 1 : -1,
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "totalPrice",
            key: "merged_invoice_data_order_detail_totalPrice",
            width: 150,
            render: (price: Amount) => <span>{displayPrice(price)}</span>,
            sorter: (a, b) => a.totalPrice.value > b.totalPrice.value ? 1 : -1,
        },
        {
            title: t("SALE_PERCENTAGE"),
            key: "salePercentage",
            dataIndex: "salePercentage",
            width: "80px",
            render: (item: string) => <span>{item || "0"}%</span>,
            sorter: (a, b) => a.salePercentage > b.salePercentage ? 1 : -1,
        },
        {
            title: '',
            dataIndex: "action",
            key: "action",
            width: "80px",
            render: (_: any, item) => renderActions(item)
        }
    ];

    return (
        <>
            <ExpandedTable<InvoiceDetailsItem>
                title="DETAILS"
                pageKey="merged_invoice_page_order_detail"
                columns={columns}
                columnsConfig={convertTableColumnsToColumnConfigs(columns)}
                tableData={store.invoice!.items}
                tableLoading={store.loading}
                tableFooter={<TableFooter data={generateDocumentDetails(store.invoice!)}/>}
            />
            {creditModalItem ? (
                <CreditInvoiceCreateModal
                    warehouse={store.invoice!.warehouse}
                    item={creditModalItem}
                    onClose={() => setCreditModalItem(null)}
                    onCompleted={(invoice) => {
                        navigate("/credit-invoices/" + invoice.id);
                    }}
                />
            ) : null}
            {editWeightModalItem ? (
                <InvoiceUpdateWeightModal
                    item={editWeightModalItem}
                    onClose={() => setEditWeightModalItem(null)}
                    onCompleted={() => store.reload()}
                />
            ) : null}
        </>
    );
});
