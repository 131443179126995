import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {MonthlyStatement} from "../../../../models/accounting/monthly-statements";
import {SystemUser} from "../../../../models/users/user/system.user";
import { Link } from "react-router-dom";
import {Translate} from "../../../../hooks/translate.hook";

export const columnsFactory = (t: Translate): ColumnsType<MonthlyStatement> => {
    return [
        {
            title: "ID",
            dataIndex: "id",
            key: "id"
        },
        {
            title: t("YEAR"),
            dataIndex: "year",
            key: "year",
        },
        {
            title: t("MONTH"),
            dataIndex: "month",
            key: "month"
        },
        {
            title: t("USER.TITLE"),
            dataIndex: "accountant",
            key: "accountant",
            width: "255px",
            render: (accountant: SystemUser) => accountant.lastName + ' ' + accountant.firstName,
        },
        {
            title: t("MISSMATCHES"),
            dataIndex: "mismatches",
            key: "mismatches",
            width: "125px"
        },
        {
            title: t("ACTIONS.TITLE"),
            key: "actions",
            dataIndex: "id",
            render: (id: number) => <Link to={"/accounting/monthly-statements/" + id} className="ant-btn ant-btn-primary">
                {t("VIEW")}
            </Link>
        }
    ];
}