import React from "react";
import { Spin, Select } from "antd";

import { SelectProps } from "antd/es/select";
import debounce from "lodash/debounce";

export interface DebounceSelectProps<ValueType = any> extends Omit<SelectProps<ValueType>, "options" | "children"> {
    fetchOptions: (search: string) => Promise<ValueType[]>;
    debounceTimeout?: number;
    optionsValueWithLabel?: boolean;
    labelInValue?: boolean;
    allowClear?: boolean;
    value?: ValueType;
    onOptionsUpdated?: (value: ValueType[]) => void
}

export function DebounceSelect<
    ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any
>({ fetchOptions, debounceTimeout = 100, optionsValueWithLabel = true, labelInValue = true, value, onOptionsUpdated, ...props }: DebounceSelectProps) {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState<ValueType[]>([]);
    const fetchRef = React.useRef(0);

    const loadOptions = (value: string) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);

        fetchOptions(value).then((newOptions) => {
            if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
            }

            setOptions(newOptions);
            onOptionsUpdated && onOptionsUpdated(newOptions);
            setFetching(false);
        });
    };

    const debounceFetcher = React.useMemo(() => {
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return optionsValueWithLabel ? (
        <Select<ValueType>
            showSearch
            labelInValue={labelInValue}
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            value={value}
            options={options}
        />
    ) : (
        <Select<ValueType>
            showSearch
            labelInValue={labelInValue}
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            value={value}
            {...props}
        >
            {options.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    );
}
