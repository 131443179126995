import React from "react";
import {StatusTag} from "../../../../common/status/status";
import {PropertyInventoryTaskStatus, statusColor} from "../../../../../models/property/property";

interface InventoryTaskStatusProps {
    status: PropertyInventoryTaskStatus;
    large?: boolean;
}
export const InventoryTaskStatus: React.FC<InventoryTaskStatusProps> = ({status, large = false}) => {
    return (
        <StatusTag<PropertyInventoryTaskStatus> status={status} colorsMap={statusColor} large={large} prefix="DOCUMENTS.STATUS"/>
    )
}
