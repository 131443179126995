import React from "react";
import {observer} from "mobx-react";
import {Col, Descriptions, Row} from "antd";
import {splitNumber} from "../../../../../utils/helpers";
import {displayPrice} from "../../../../../models/prices/price.helper";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../utils/store";
import {PackingListDataStoreContext} from "../packing-list.data.store";
// import "../../../common/table-footer/table.footer.scss";

export const PackingListDataDetailsTableFooter: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(PackingListDataStoreContext);
    return (
        <Row justify={"end"}>
            <Col xxl={9} xl={10} md={12} sm={24} xs={24}>
                <Descriptions
                    size={"small"}
                    layout={"horizontal"}
                    contentStyle={{fontWeight: "bold",}}
                    column={1}
                    className="table-footer-descriptions"

                >
                    <Descriptions.Item label={t("QUANTITY")}>{store.packingListData!.totalPieces}</Descriptions.Item>
                    <Descriptions.Item label={t("TOTAL_WEIGHT")}>{String(store.packingListData?.totalNetWeight + " / " + store.packingListData?.totalGrossWeight).replaceAll(".", ",")} ({t("KG")})</Descriptions.Item>
                    <Descriptions.Item label={t("TOTAL_BOXES")}>{store.packingListData?.totalBoxes}</Descriptions.Item>
                    <Descriptions.Item label={t("TOTAL_PALLETS")}><>{store.packingListData?.totalPallets}</></Descriptions.Item>
                </Descriptions>
            </Col>
        </Row>
    )
})
