import { Alert, Space, Typography } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { observer } from "mobx-react";
import React, {useMemo} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { TableShipment } from "../../../../models/parcels/shipment";
import { useRequiredStore } from "../../../../utils/store";
import { OrderDataStoreContext } from "../order.data.store";
import {columnsFactory} from "../../../documents/common/shipments/document.shipments.columns";

export const OrderDataShipments: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(OrderDataStoreContext);

    const columns: ColumnsType<TableShipment> = useMemo(() => columnsFactory(t), []);

    return (
        <Space direction="vertical" size={[0, 0]}>
            <Typography.Title level={3}>{t("SHIPMENTS.TITLE")}</Typography.Title>
            {store.shipments.length === 0 ? (
                <Alert type="info" message={t("SHIPMENTS.NO_SHIPMENTS")} />
            ) : (
                <Table columns={columns} dataSource={store.shipments} pagination={false} />
            )}
        </Space>
    );
});
