import React from "react";
import {StatusTag} from "../../common/status/status";
import {
    ClaimItemAcceptStatus,
    claimItemAcceptStatusColor,
    ClaimItemType,
    claimItemTypeColor
} from "../../../models/claims/claims";

interface ClaimsItemAcceptStatusProps {
    status: ClaimItemAcceptStatus;
    large?: boolean;
}

export const ClaimsItemAcceptStatus: React.FC<ClaimsItemAcceptStatusProps> = ({status, large = false}) => {
    return (
        <StatusTag<ClaimItemAcceptStatus> status={status} colorsMap={claimItemAcceptStatusColor} large={large} prefix="CLAIMS.CLAIM.ITEM_ACCEPT.STATUS"/>
    )
}

interface ClaimsItemTypeProps {
    type: ClaimItemType;
    large?: boolean;
}

export const ClaimsItemType: React.FC<ClaimsItemTypeProps> = ({type, large = false}) => {
    return (
        <StatusTag<ClaimItemType> status={type} colorsMap={claimItemTypeColor} large={large} prefix=""/>
    )
}