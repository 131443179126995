import React from "react";
import {Observer} from "mobx-react";
import {Col, Row, Space, Typography} from "antd";
import {UserDataCard} from "../user-data-card/user.data.card";
import {useRequiredStore} from "../../../../../utils/store";
import {UserDataStoreContext} from "../user.data.store";
import {useTranslate} from "../../../../../hooks/translate.hook";


export const UserDataBody: React.FC = () => {
    const t = useTranslate();
    const store = useRequiredStore(UserDataStoreContext);

    return (
        <>
            <Space size={[0, 0]} direction={"vertical"}>
                <Row align={"middle"}>
                    <Col md={24} lg={24} xl={24}>
                        <Typography.Title level={4}>{t("USER.PROFILE.LIST")}</Typography.Title>
                    </Col>
                </Row>
                <Observer>
                    {() => (
                        <>
                            {store.userData?.accesses.slice().sort((a, b) => a.account.type > b.account.type ? 1 : -1).map((item) => (
                                <UserDataCard type={item.account.type} access={item}/>
                            ))}
                        </>
                    )}
                </Observer>
            </Space>
        </>
    );
}
