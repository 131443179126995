import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {GraphRequest} from "../../models/reports/graph.request";
import {GraphDataWithMeta} from "../../models/graph/graph";
import {isEmpty} from "lodash";

export class GraphService {
    private api: AxiosInstance = API;

    getPaymentsByMonth<I, M>(request: GraphRequest, additionalFilters?: {[key: string]: any}): Promise<GraphDataWithMeta<I, M>> {
        if (!isEmpty(additionalFilters)) {
            for (const key in additionalFilters) {
                if (additionalFilters.hasOwnProperty(key)) {
                    request.addFilter(key, additionalFilters[key]);
                }
            }
        }
        return this.api.get<GraphDataWithMeta<I, M>>(
            "reports/payments-by-months",
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }
}

export const graphService = new GraphService();
