import React, {useMemo, useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Form, Input, Modal} from "antd";
import {PropertyType} from "../../../../../models/property/property";
import {propertyService} from "../../../../../services/property/property.service";

interface EditPropertyTypeModalProps {
    onClose: () => void;
    type?: PropertyType | null;
}
export const EditPropertyTypeModal: React.FC<EditPropertyTypeModalProps> = ({ onClose, type }) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const initialValues = useMemo(() => {
        let values: any = {}
        if (type) {
            values = {
                name: type.name
            }
        }

        return values;
    }, [])

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("PROPERTY.PROPERTY.TYPE")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={onClose}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name={"integrations_form"}
                initialValues={initialValues}
                onFinish={() => form
                    .validateFields()
                    .then(async values => {
                        setLoading(true);
                        try {
                            if (type) {
                                await propertyService.editType(type.id, values);
                                onClose();
                            } else {
                                await propertyService.createType(values);
                                onClose();
                            }
                        } finally {
                            setLoading(false);
                        }
                    })
                }
            >
                <Form.Item
                    label={t("PROPERTY.PROPERTY.TYPE")}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>

        </Modal>
    );
}