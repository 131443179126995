import React, { useEffect } from "react";

export type CanvasCallback = (
    ctx: CanvasRenderingContext2D,
    frameCount: number
) => void;

const setUpCanvas = (canvas: HTMLCanvasElement): CanvasRenderingContext2D => {
    const devicePixelRatio = window.devicePixelRatio || 1;
    const rect = canvas.getBoundingClientRect();

    canvas.width = rect.width * devicePixelRatio;
    //canvas.height = rect.height * devicePixelRatio;

    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    ctx.scale(devicePixelRatio, devicePixelRatio);

    return ctx;
};

export interface UseCanvasParams {
    renderFunctions: RenderFunctions;
    animationCountStop: number;
}
export type RenderFunctions = Array<
    (ctx: CanvasRenderingContext2D, frameCount: number) => void
>;

export const useCanvas = ({
    renderFunctions,
    animationCountStop = 250
}: UseCanvasParams) => {
    const canvasRef = React.useRef(null) as React.RefObject<HTMLCanvasElement>;

    useEffect(() => {
        let animationFrameId: number;
        let frameCounter: number = 1;

        const canvas = canvasRef.current as HTMLCanvasElement;
        if (!canvas) {
            return;
        }

        const onWindowResize = (): void => {
            canvas.width = canvas.clientWidth;
            //canvas.height = canvas.clientHeight;
            const context = setUpCanvas(canvas);

            renderFunctions.forEach((renderFunction) => {
                renderFunction(context, frameCounter);
            });
        };

        window.addEventListener("resize", onWindowResize);

        const render = () => {
            const context = setUpCanvas(canvas);
            animationFrameId = window.requestAnimationFrame(render);
            frameCounter += 1;

            renderFunctions.forEach((renderFunction) => {
                renderFunction(context!, frameCounter);

                if (frameCounter > animationCountStop) {
                    window.cancelAnimationFrame(animationFrameId);
                }
            });
        };

        render();

        return () => window.cancelAnimationFrame(animationFrameId);
    }, [renderFunctions, animationCountStop]);
    return canvasRef;
};
