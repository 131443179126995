import {Translate} from "../../../../hooks/translate.hook";
import {FilterKey} from "../../../../models/filtered-table/filtered.table.props";

export const filtersFactory = (t: Translate): FilterKey[] => [
        {
            key: "id",
            label: "ID",
            type: "int",
        },
        {
            key: "name",
            label: t("NAME"),
            type: "string",
        },
        {
            key: "ocName",
            label: t("1C.NAME"),
            type: "string",
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date",
        },
        {
            key: "updatedAt",
            label: t("UPDATED_AT"),
            type: "date",
        },
];