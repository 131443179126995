import React, { useState } from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { Button, Col, Row, Space, Tooltip, Typography } from "antd";
import { AgentDataContactsModal } from "./agent.data.contacts.modal";
import { AgentDataContactsCard } from "./agent.data.contacts.card";
import { useRequiredStore } from "../../../../../utils/store";
import { AgentDataStoreContext } from "../../agent.data.store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface AgentDataContactsProps {
    withTitle?: boolean;
    changeable?: boolean;
}

export const AgentDataContacts: React.FC<AgentDataContactsProps> = observer(({withTitle = true, changeable = true}) => {
    const t = useTranslate();
    const store = useRequiredStore(AgentDataStoreContext);

    const [isModalShown, setIsModalShown] = useState<boolean>(false);
    return (
        <Space size={[0, 0]} direction={"vertical"}>
            {withTitle ? (
                <Row>
                    <Typography.Title level={3}>
                        {t("CONTACTS.TITLE")}
                    </Typography.Title>
                    {changeable ? (
                        <Tooltip placement="topLeft" title={t("ADD")}>
                            <Button
                                className="btn"
                                style={{ margin: "3px 0 0 5px" }}
                                onClick={() => setIsModalShown(true)}
                                type="default"
                                icon={<FontAwesomeIcon icon={["fas", "plus"]} />}
                            />
                        </Tooltip>
                    ) : null}
                </Row>
            ) : null}
            <Row gutter={[30, 30]}>
                {store.account?.contacts.length ? (
                    store.account?.contacts.map((item) => (
                        <Col
                            className={"card-col"}
                            key={item.id}
                            xxl={8}
                            xl={8}
                            lg={8}
                            md={12}
                            sm={24}
                            xs={24}
                        >
                            <AgentDataContactsCard contact={item} changeable={changeable} />
                        </Col>
                    ))
                ) : (
                    <Col
                        className={"card-col"}
                        xxl={8}
                        xl={8}
                        lg={8}
                        md={12}
                        sm={24}
                        xs={24}
                    >
                        <AgentDataContactsCard />
                    </Col>
                )}
            </Row>
            <AgentDataContactsModal
                visible={isModalShown}
                onSubmit={store.createContact}
                onClose={() => setIsModalShown(false)}
            />
        </Space>
    );
});
