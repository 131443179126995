import {FieldGroupConfig} from "../../../../models/filter/filter.group";
import {FieldTypeName} from "../../../../models/filter/filter.field.type.name";
import {Translate} from "../../../../hooks/translate.hook";
import {
    MonthlyStatementItemStatus,
    MonthlyStatementItemType
} from "../../../../models/accounting/monthly-statements";

export const filterGroupsFactory = (t: Translate): FieldGroupConfig[] => {
    return [
        {
            visibleName: 'STATUS',
            name: 'statuses',
            fields: [
                {
                    name: 'statuses',
                    type: FieldTypeName.SELECT,
                    choices: Object.values(MonthlyStatementItemStatus).map(value => {
                        return {
                            name: t('ACCOUNTING.STATEMENTS.ITEM.STATUS.' + value.toUpperCase()),
                            value: value
                        }
                    }),
                    multiple: true
                }
            ],
        },
        {
            visibleName: 'TYPE',
            name: 'types',
            fields: [
                {
                    name: 'types',
                    type: FieldTypeName.SELECT,
                    choices: Object.values(MonthlyStatementItemType).map(value => {
                        return {
                            name: t('ACCOUNTING.STATEMENTS.ITEM.TYPE.' + value.toUpperCase()),
                            value: value
                        }
                    }),
                    multiple: true
                }
            ],
        }
    ]
}