import React from "react";
import {ReportConfigResolvableField} from "../../../../../../models/reports/reports";
import {config} from "../reports.report.resolvable-fields.config";

interface ReportsReportResolvableFieldProps {
    field: ReportConfigResolvableField;
    value: Record<string, any>;
    setValue: (value: Record<string, any>) => void;
}

export const ReportsReportResolvableField: React.FC<ReportsReportResolvableFieldProps> = ({field, value, setValue}) => {

    const {component: Widget, transform, reverseTransform} = config[field.type]

    return (
        <Widget
            onChange={v => setValue(transform(field, v))}
            value={reverseTransform(field, value)}
            multiple={field.multiple}
            withDefault={field.withDefault}
            defaultOptions={field.defaultOptions}
        />
    )
}