import React, {useState} from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Button, Card, Tooltip, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CustomCard} from "../../custom-card/custom.card";
import {PayerReceiverModal} from "../modals/payer.receiver.modal";
import {
    PayerOrReceiverDetails,
    PayerReceiverFormValues
} from "../../../../models/payer-receiver-seller/payer.reveiver.seller";

interface PayerReceiverCardProps {
    title: string,
    type: "payer" | "receiver",
    data: PayerOrReceiverDetails,
    editPayerReceiver?: (type: "payer" | "receiver", values: PayerReceiverFormValues) => Promise<void>
    withAddressType?: boolean;
}
export const PayerReceiverCard: React.FC<PayerReceiverCardProps> = observer(({title, type, data, editPayerReceiver, withAddressType}) => {
    const t = useTranslate();
    const [modalShown, setModalShown] = useState<boolean>(false);

    const cardDataRows = [
        {key: "companyName", label: "COMPANY_NAME"},
        {key: "firstName"},
        {key: "lastName"},
        {key: "vat", label: "VAT.TITLE"},
        {key: "customsCode", label: "EORI"},
        {key: "regCode", label: "REG_NUMBER"},
        {key: "country"},
        {key: "region"},
        {key: "city"},
        {key: "index"},
        {key: "address"},
        {key: "phone"},
        {key: "email"},
    ];


    return (
        <>
            <Card
                className="card"
                title={t(title)}
                extra={
                    editPayerReceiver ? (
                        <Tooltip placement="topRight" title={t("EDIT")}>
                            <Button
                                onClick={() => setModalShown(true)}
                                className={"card-btn btn"}
                                type="default"
                                icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                            />
                        </Tooltip>
                    ) : null
                }
            >
                <CustomCard data={{...data, country: data.country.name}} rowKeys={cardDataRows}/>
            </Card>
            {editPayerReceiver ? (
                <PayerReceiverModal data={data} visible={modalShown} type={type} onClose={() => setModalShown(false)} onSubmit={editPayerReceiver} withAddressType={withAddressType}/>

            ) : null}
        </>
    );
});
