import React from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../../utils/store";
import {MergedInvoiceDataStoreContext} from "../merged.invoice.data.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Link} from "react-router-dom";
import {Amount} from "../../../../../models/prices/price";
import {displayPrice} from "../../../../../models/prices/price.helper";
import Date from "../../../../date/date";
import {MergedInvoiceIncludedCreditInvoice} from "../../../../../models/documents/invoices/merged-invoice/merged-invoice-data/merged.invoice.data";
import {ColumnsType} from "antd/es/table";
import {ExpandedTable} from "../../../../common/order-details/expanded.table";
import {convertTableColumnsToColumnConfigs} from "../../../../../utils/table";
import {DocumentsStatus} from "../../../../common/documents/status/documents.status";

export const MergedInvoiceDataIncludedCreditInvoices: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(MergedInvoiceDataStoreContext);

    const columns: ColumnsType<MergedInvoiceIncludedCreditInvoice> = [
        {
            title: t("INVOICES.NUMBER"),
            dataIndex: "docNumber",
            key: "merged_invoice_page_included_invoices_number",
            width: "120px",
            render: (_: any, item) => <Link to={"/credit-invoices/" + item.id}>{item.docNumber}</Link>
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "merged_invoice_page_included_invoices_status",
            width: 100,
            render: (status) => <DocumentsStatus status={status} />
        },
        {
            title: t("INVOICES.DISPATCH.DISPATCH_DATE"),
            dataIndex: "date",
            key: "merged_invoice_page_included_invoices_invoiceCreatedAt",
            width: 100,
            render: (date: string | undefined, row) => date ? <Date dateString={date}/> : "-"
        },
        {
            title: t("TOTAL_PRICE"),
            dataIndex: "subtotal",
            key: "merged_invoice_page_included_invoices_subtotal",
            width: 100,
            render: (amount: Amount) => <span style={{fontWeight: "bold"}}>{displayPrice(amount)}</span>
        },
        {
            title: "VAT",
            dataIndex: "subtotalVat",
            key: "merged_invoice_page_included_invoices_subtotalVat",
            width: 100,
            render: (amount: Amount) => <span style={{fontWeight: "bold"}}>{displayPrice(amount)}</span>
        },
        {
            title: t("GRAND_TOTAL_PRICE"),
            dataIndex: "grandTotal",
            key: "merged_invoice_page_included_invoices_grandTotal",
            width: 100,
            render: (amount: Amount) => <span style={{fontWeight: "bold"}}>{displayPrice(amount)}</span>
        }
    ];

    return (
        <>

            <ExpandedTable<MergedInvoiceIncludedCreditInvoice>
                title="INVOICE.MERGED.INCLUDED_CREDIT_INVOICES.TITLE"
                pageKey="merged_invoice_page_included_credit_invoices"
                columns={columns}
                columnsConfig={convertTableColumnsToColumnConfigs(columns)}
                tableData={store.loading ? [] : [...store.invoice!.creditInvoices]}
                tableLoading={store.loading}
            >
            </ExpandedTable>
        </>
    )
});
