import React from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../utils/store";
import {OrderDeliveryCreateStoreContext} from "../order.delivery.create.store";
import {Col, Row, Space, Typography} from "antd";
import {PayerReceiverCard} from "../../../common/payer-seller-receiver-data/cards/payer.receiver.card";
import {useTranslate} from "../../../../hooks/translate.hook";

export const OrderDeliveryAddress: React.FC = observer(() => {
    const t = useTranslate();

    const store = useRequiredStore(OrderDeliveryCreateStoreContext);
    return (
        <Space direction="vertical" size={[0, 0]}>
            <Typography.Title level={3}>{t("ORDER.DELIVERY.RECEIVER_DETAILS")}</Typography.Title>
            <Row gutter={[30, 30]}>
                <Col className="card-col" xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <PayerReceiverCard
                        type={"receiver"}
                        title={"ORDER.DELIVERY.RECEIVER_DETAILS"}
                        data={store.deliveryOrder!.address}
                    />
                </Col>
            </Row>
        </Space>
)
})
