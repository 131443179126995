import {FilterDataHandler} from "../../../../models/filter/filter.data.handler";
import {AssignRule, AssignRuleRequest} from "../../../../models/users/assign-rule/assign.rule";
import {assignRulesService} from "../../../../services/users/assign-rules/assign.rules.service";
import {action, makeObservable, observable} from "mobx";

export class UserAssignRulesStore {
    @observable
    public editModalShown: boolean = false;

    @observable
    public createModalShown: boolean = false;

    @observable
    public editModalItem?: AssignRule;

    public dataHandler: FilterDataHandler<AssignRule>

    constructor() {
        makeObservable(this);
        this.dataHandler = new FilterDataHandler<AssignRule>(request => assignRulesService.getRules(request));
        this.closeCreateModal = this.closeCreateModal.bind(this);
        this.openCreateModal = this.openCreateModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.updateRule = this.updateRule.bind(this);
        this.createRule = this.createRule.bind(this);
    }


    @action
    public openEditModal(item: AssignRule): void {
        this.editModalShown = true;
        this.editModalItem = item;
    }

    @action
    public closeEditModal(): void {
        this.editModalShown = false;
    }

    @action
    public openCreateModal(): void {
        this.createModalShown = true;
    }

    @action
    public closeCreateModal(): void {
        this.createModalShown = false;
    }


    public isCreateModalShown(): boolean {
        return this.createModalShown;
    }

    public async updateRule(request: AssignRuleRequest, item: AssignRule) {
        const updatedItem = await assignRulesService.updateRule(request, item.id);
        this.dataHandler.updateItem(item, updatedItem);
    }

    public async createRule(request: AssignRuleRequest) {
        const createdItem = await assignRulesService.createRule(request);
        this.dataHandler.addItem(createdItem);
    }

    public async removeRule(item: AssignRule) {
        await assignRulesService.removeRule(item.id);
        this.dataHandler.removeItem(item);
    }

    public isEditModalShown(item: AssignRule): boolean {
        return this.editModalShown && this.editModalItem === item;
    }

}
