import React, {useMemo, useState} from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import { AxiosError } from "axios";
import { ErrorResponseData } from "../../../../../models/core/response";
import { useRequiredStore } from "../../../../../utils/store";
import { RootStoreContext } from "../../../../../stores/root/root.store";
import {ShippingCourier} from "../../../../../models/shipping/shipping";
import Checkbox from "antd/es/checkbox/Checkbox";

interface BranchDataCourierFormProps {
    courier: ShippingCourier;
    onSubmit: (courier: ShippingCourier) => Promise<ShippingCourier>;
}

export const BranchDataCourierForm: React.FC<BranchDataCourierFormProps> = observer(({courier, onSubmit}) => {
    const {
        commonStore: { warehouses, systems },
    } = useRequiredStore(RootStoreContext);
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const defaultValue = useMemo(() => {
        const data: any   = {...courier};
        data.warehouses    = courier.warehouses.map(w => w.id)
        data.shippingType  = courier.shippingType.id;
        data.systems       = courier.systems.map(system => system.id);
        return data;
    },[courier]);

    if (!warehouses.length) {
        return <Spin />;
    }

    return (
        <Form
            form={form}
            layout="vertical"
            name="edit_branch_form"
            initialValues={defaultValue}
            onFinish={() =>
                form.validateFields().then((values) => {
                    setLoading(true);

                    values = {
                        name:         values.name,
                        estimate:     values.estimate,
                        warehouses:   values.warehouses,
                        packType:     values.packType,
                        viewStatus:   values.viewStatus,
                        shippingType: values.shippingType,
                        systems:      values.systems
                    }

                    onSubmit(values)
                        .then(
                            () => {
                                return;
                            },
                            (e: AxiosError<ErrorResponseData>) => {
                                setLoading(false);
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            }
                        )
                        .then(() => (e: any) => form.setFields(e.response?.data?.fields))
                        .finally(() => setLoading(false));
                })
            }
        >
            <Row gutter={[10, 0]} align={"middle"}>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="name"
                        label={t("NAME")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input disabled={loading} type="text" placeholder={t("NAME")} />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="warehouses"
                        label={t("WAREHOUSES.WAREHOUSE_TITLE")}
                    >
                        <Select
                            placeholder={t("WAREHOUSES.WAREHOUSE_TITLE")}
                            mode={"multiple"}
                            disabled={loading}
                        >
                            <Select.Option value={""} key={0}>
                                {t("ALL")}
                            </Select.Option>
                            {warehouses.map((country) => (
                                <Select.Option value={country.id!} key={country.id!}>
                                    {country.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={12} xl={12} md={12} sm={12} xs={24}>
                    <Form.Item
                        name="systems"
                        label={t("SYSTEM")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            placeholder={t("SYSTEM")}
                            disabled={loading}
                            mode={"multiple"}
                        >
                            {systems.map((system) => (
                                <Select.Option value={system.id!} key={system.id!}>
                                    {system.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[10, 0]} align={"middle"}>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="shippingType"
                        label={t("SHIPPING.COURIER.SHIPPING_TYPE")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            placeholder={t("SHIPPING.COURIER.SHIPPING_TYPE")}
                            disabled={loading}
                        >
                            {["economy", "express", "custom"].map((value, key) => (
                                <Select.Option key={key} value={key + 1}>
                                    {value}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="packType"
                        label={t("SHIPPING.COURIER.PACK_TYPE")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            placeholder={t("SHIPPING.COURIER.PACK_TYPE")}
                            disabled={loading}
                        >
                            {["pallet", "box"].map((value) => (
                                <Select.Option key={value} value={value}>
                                    {value}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="estimate"
                        label={t("ESTIMATE")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input type="text" placeholder={t("ESTIMATE")} disabled={loading} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[10, 0]} align={"middle"}>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="viewStatus"
                        label={t("ENABLED")}
                        valuePropName={"checked"}
                    >
                        <Checkbox />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Button disabled={loading} type="primary" htmlType="submit">
                        {t("SAVE")}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
});
