import {AxiosInstance} from "axios";
import API from "../../utils/api";

export class ActionsService {
    api: AxiosInstance = API;

    getAliases(): Promise<string[]> {
        return this.api.get("actions").then(x => x.data);
    }

    run(alias: string): Promise<{id: number}> {
        return this.api.post("actions/" + alias).then(x => x.data);
    }
}

export const actionsService = new ActionsService();
