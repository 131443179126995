import React, {useState} from "react";
import {
    Col,
    Form,
    Input,
    Modal,
    Row, Select,
} from "antd";
import {useForm} from "antd/es/form/Form";
import {useTranslate} from "../../../../../hooks/translate.hook";
import dayjs from "dayjs";
import {
    UiInputWidgetUpcomingEvent
} from "../../../../common/ui/input/widget/upcoming-event/ui.input.widget.upcoming-event";
import {SlotType} from "../../calendar.helpers";
import {calendarService} from "../../../../../services/calendar/calendar.service";

interface NewSlotModalProps {
    date: dayjs.Dayjs;
    onCancel: () => void;
    onComplete: () => void;
}

export const NewSlotModal: React.FC<NewSlotModalProps> = ({date, onCancel, onComplete}) => {
    const t = useTranslate();

    const [form] = useForm();
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Modal
            title={t('New Slot ' + date.format('HH.mm DD.MM.YYYY'))}
            width={500}
            visible={true}
            onCancel={onCancel}
            onOk={() => {
                form.submit();
            }}
            destroyOnClose
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={async (values) => {
                    await form.validateFields();
                    try {
                        setLoading(true)
                        await calendarService.createSlot({...values, date: date.format('YYYY-MM-DD\THH:mm:00')})
                        onCancel()
                        onComplete()
                    } catch (e: any) {
                        form.setFields(e.response?.data?.fields);
                    } finally {
                        setLoading(false)
                    }
                }}
                autoComplete="off"
                initialValues={{}}
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            label={t('CALENDAR.SLOT.TYPE.TITLE')}
                            name={['type']}
                            rules={[{ required: true }]}
                        >
                            <Select disabled={loading}>
                                {Object.entries(SlotType).map(([_, v]) => (
                                    <Select.Option key={v} value={v}>{t('CALENDAR.SLOT.TYPE.' + v.toUpperCase())}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Form.Item
                        noStyle
                        shouldUpdate={(p, n) => p.type !== n.type}
                    >
                        {() => form.getFieldValue('type') === SlotType.EVENT ? (
                            <Col span={24}>
                                <Form.Item
                                    label={t('CALENDAR.SLOT.EVENT.NAME')}
                                    name={['event']}
                                    rules={[{ required: true }]}
                                >
                                    <UiInputWidgetUpcomingEvent />
                                </Form.Item>
                            </Col>
                        ) : null}
                    </Form.Item>
                </Row>
            </Form>
        </Modal>
    );
}