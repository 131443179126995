import React from "react";
import {WidgetProps} from "../../../../../../../common/cards-view/model/model";
import {Radio} from "antd";
import {PlannerTaskSystemStatus} from "../../../../../../../../models/planner/planner";

export const typesConfig = [
    {label: 'ALL', values: [PlannerTaskSystemStatus.PENDING, PlannerTaskSystemStatus.TODO, PlannerTaskSystemStatus.IN_PROGRESS, PlannerTaskSystemStatus.ON_REVIEW]},
    {label: 'Pending', values: [PlannerTaskSystemStatus.PENDING]},
    {label: 'TODO', values: [PlannerTaskSystemStatus.TODO]},
    {label: 'In Progress', values: [PlannerTaskSystemStatus.IN_PROGRESS]},
    {label: 'On Review', values: [PlannerTaskSystemStatus.ON_REVIEW]},
    {label: 'Done', values: [PlannerTaskSystemStatus.DONE]},
    {label: 'Cancelled', values: [PlannerTaskSystemStatus.CANCELLED]},
]

export const TaskTypeWidget: React.FC<WidgetProps> = ({onChange, value}) => {

    return (
        <Radio.Group
            value={value}
            onChange={e => onChange ? onChange(e.target.value) : null}
        >
            {typesConfig.map(type => (
                <Radio.Button
                    value={type.values}
                    key={type.label}
                >
                    {type.label}
                </Radio.Button>
            ))}
        </Radio.Group>
    )
}