import {UploadFile} from "antd/es/upload/interface";
import {PropertyFile} from "../../../../../../models/property/property";

export const createFileList = (attachments: PropertyFile[]): UploadFile[] => {
    return attachments.map(attachment => {

        return {
            uid: attachment.id.toString(),
            name: attachment.fileName,
            fileName: attachment.fileName,
            url: '/api/v3/property/attachment/' + attachment.hash + '/' + attachment.fileName,
            thumbUrl: '/api/v3/property/attachment/' + attachment.hash + '/' + attachment.fileName,
            status: 'done',
            size: 0,
            type: ''
        }
    })
}