import React, {useState} from "react";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {Button, Col, Form, FormInstance, Input, Row, Select} from "antd";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../../../models/core/response";
import {UserAccessCategory} from "../../../../../../models/users/access-category/user.access.category";


interface UserAccessesCategoryEditFormProps {
    accessesCategory: { [id: string]: string },
    onClose: () => void,
    onSubmit: (values: {editingCategoryId: string, name: string}) => Promise<any>,
    form: FormInstance
}

export const UserAccessesCategoryEditForm: React.FC<UserAccessesCategoryEditFormProps> = ({accessesCategory, onClose, onSubmit, form}) => {

    const t = useTranslate();
    const [loading, setLoading] = useState(false);




    return (
        <Form
            form={form}
            layout="vertical"
            name={"category_edit_form"}
            onFinish={() => form
                .validateFields()
                .then(values => {
                    setLoading(true);
                    onSubmit(values)
                        .then(
                            () => {
                                form.resetFields();
                                return onClose();
                            },
                            (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                        .then(() => {}, (e) => form.setFields(e.response?.data?.fields))
                        .finally(() => setLoading(false));
                })
            }
        >
            <Row gutter={10} >
                <Col span={24}>
                    <Form.Item
                        name="editingCategoryId"
                        label={t("NAME")}
                    >
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp={"children"}
                            onChange={(value: string) => form.setFieldsValue({name: accessesCategory[value]})}
                            placeholder={t("NAME")}
                        >
                            {Object.keys(accessesCategory).map(id => (
                                <Select.Option key={id} value={id}>{accessesCategory[id]}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(oldValue, newValue) => oldValue.editingCategoryId !== newValue.editingCategoryId}
                    >
                        {() => (
                            <Form.Item
                                name="name"
                                label={t("NEW_NAME")}
                                rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                            >
                                <Input type="text" placeholder={t("NEW_NAME")}/>
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item style={{marginBottom: 0}}>
                        <Button
                            loading={loading}
                            style={{display: "block", marginLeft: "auto"}}
                            htmlType={"submit"}
                            type={"primary"}
                        >
                            {t("SAVE")}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}
