import React, {useState} from "react";
import {Col, Form, Input, Modal, Row} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {AxiosError} from "axios";
import {InvoiceDataStoreContext} from "../invoice.data.store";
import {useRequiredStore} from "../../../../../utils/store";
import {ErrorResponseData} from "../../../../../models/core/response";
import {InvoiceDetailsItem} from "../../../../../models/documents/invoices/invoice-data/invoice.data";
import {invoiceService} from "../../../../../services/documents/invoices/invoices.service";

interface InvoiceUpdateWeightModalProps {
    item: InvoiceDetailsItem,
    onClose: () => void,
    onCompleted: () => void
}

export const InvoiceUpdateWeightModal: React.FC<InvoiceUpdateWeightModalProps> = ({item, onClose, onCompleted}) => {
    const t = useTranslate();
    const store = useRequiredStore(InvoiceDataStoreContext);

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("INVOICE.EDIT")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"order_data"}
                initialValues={{
                    netWeight: item.netWeight,
                    grossWeight: item.grossWeight
                }}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        invoiceService.updateWeight(
                            item.id,
                            {
                                netWeight: parseFloat(values.netWeight),
                                grossWeight: parseFloat(values.grossWeight)
                            })
                            .then(() => {
                                form.resetFields();
                                onCompleted();
                                return onClose();
                            }, (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                            .then(() =>
                                // onClose(),
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="netWeight"
                            label={t("NETTO_WEIGHT")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input type="number" placeholder={t("NETTO_WEIGHT")}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="grossWeight"
                            label={t("WEIGHT_BRUTTO")}
                        >
                            <Input type="number" placeholder={t("WEIGHT_BRUTTO")}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
