import React from 'react';
import {Tag} from "antd";
import {useTranslate} from "../../../hooks/translate.hook";

interface ShipmentsStatusProps {
    status: string,
    large?: boolean;
}
export const ShipmentsStatus: React.FC<ShipmentsStatusProps> = ({status, large}) => {
    const t = useTranslate();
    const statuses: {[key: string]: string} = {
        label_not_created: "red",
        awaiting: "gold",
        packed: "blue",
        shipped: "green",
        delivered: "green"
    }

    return (
        <Tag
            className={large ? "order-status-large order-status": "order-status"}
            color={statuses[status] ? statuses[status] : undefined}>
            {t("SHIPMENTS.STATUS." + status.toUpperCase())}
        </Tag>
    );
};