import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {CurrentQueueStatus, JobStatus} from "../../models/queue/job";

export class QueueService {
    private api: AxiosInstance = API;

    getStatus(): Promise<CurrentQueueStatus> {
        return this.api
            .get<CurrentQueueStatus>("queue")
            .then((x) => x.data);
    }

    getJobStatus(id: number): Promise<{status: JobStatus, error: string}> {
        return this.api.get("queue/job/" + id + "/status").then(x => x.data);

    }

    cancel(id: number): Promise<void> {
        return this.api.post("queue/job/" + id + "/cancel");
    }

    replay(id: number): Promise<void> {
        return this.api.post("queue/job/" + id + "/replay");
    }
}
export const queueService = new QueueService();
