import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Form, Input, Row, Select, Space, Typography} from "antd";
import {AgentNewContext} from "../../agent.new.modal";
import {useRequiredStore} from "../../../../../../utils/store";
import {RootStoreContext} from "../../../../../../stores/root/root.store";
import {useTranslate} from "../../../../../../hooks/translate.hook";

export const AddressesStep: React.FC = () => {

    const {setStep, getValue, setValue, fields} = React.useContext(AgentNewContext);

    const { langStore } = useRequiredStore(RootStoreContext);

    const t = useTranslate();

    const [loading, setLoading] = useState<boolean>(false);

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFields(fields);
    }, [fields])

    const [shippingSameAsBilling, setShippingSameAsBilling] = useState<boolean>(false);

    const defaultValues: any = {
        billingAddress: getValue('billingAddress'),
        shippingAddress: getValue('shippingAddress')
    }

    return <Space direction="vertical" size={[15, 15]} style={{marginTop: 40}}>
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            name={"client_form"}
            initialValues={defaultValues}
            onFinish={async (values) => {
                await form.validateFields();
                setValue('billingAddress', values.billingAddress)
                setValue('shippingAddress', shippingSameAsBilling ? values.billingAddress : values.shippingAddress)
                setStep(6)
            }}
        >
            <Typography.Title level={3}>{"Addresses"}</Typography.Title>
            <Row gutter={20}>
                <Col span={12}>
                    <Typography.Title level={4}>{"Billing address"}</Typography.Title>
                    <Row gutter={10}>
                        <Col span={24} style={{marginBottom: 15}}>
                            &nbsp;
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col span={24}>
                            <Form.Item
                                name={["billingAddress", "name"]}
                                label={t("NAME")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("NAME")} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name={["billingAddress", "companyName"]}
                                label={t("COMPANY_NAME")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("COMPANY_NAME")} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={["billingAddress", "firstName"]} label={t("FIRSTNAME")}>
                                <Input disabled={loading} type="text" placeholder={t("FIRSTNAME")} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={["billingAddress", "lastName"]} label={t("LASTNAME")}>
                                <Input disabled={loading} type="text" placeholder={t("LASTNAME")} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name={["billingAddress", "vat"]} label={"VAT"}>
                                <Input disabled={loading} type="text" placeholder={"EE00000000"} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={["billingAddress", "customsCode"]} label={"EORI"}>
                                <Input disabled={loading} type="text" placeholder={"EORI"} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={["billingAddress", "regCode"]} label={t("REG_NUMBER")}>
                                <Input disabled={loading} type="text" placeholder={t("REG_NUMBER")} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={["billingAddress", "country"]}
                                label={t("COUNTRY")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    disabled={loading}
                                    showSearch
                                    optionFilterProp={"children"}
                                    placeholder={t("COUNTRY_SELECT")}
                                >
                                    {langStore.countries.map(({ id, name }) => (
                                        <Select.Option key={id as string} value={id as string}>
                                            {name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={["billingAddress", "region"]} label={t("REGION")}>
                                <Input disabled={loading} type="text" placeholder={t("REGION")} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={["billingAddress", "city"]}
                                label={t("CITY")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("CITY")} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={["billingAddress", "index"]}
                                label={t("INDEX")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("INDEX")} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name={["billingAddress", "address"]}
                                label={t("ADDRESS")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("ADDRESS")} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={["billingAddress", "phone"]}
                                label={t("PHONE")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("PHONE")} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={["billingAddress", "email"]}
                                label={t("EMAIL")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("EMAIL")} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Typography.Title level={4}>{"Shipping address"}</Typography.Title>
                    <Row gutter={10}>
                        <Col span={24} style={{marginBottom: 15}}>
                            <Checkbox
                                checked={shippingSameAsBilling}
                                onChange={v => setShippingSameAsBilling(v.target.checked)}
                            >
                                Shipping address is same as billing
                            </Checkbox>
                        </Col>
                    </Row>
                    {!shippingSameAsBilling ? (
                        <Row gutter={10}>
                            <Col span={24}>
                                <Form.Item
                                    name={["shippingAddress", "name"]}
                                    label={t("NAME")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                        },
                                    ]}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("NAME")} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={["shippingAddress", "companyName"]}
                                    label={t("COMPANY_NAME")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                        },
                                    ]}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("COMPANY_NAME")} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={["shippingAddress", "firstName"]} label={t("FIRSTNAME")}>
                                    <Input disabled={loading} type="text" placeholder={t("FIRSTNAME")} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={["shippingAddress", "lastName"]} label={t("LASTNAME")}>
                                    <Input disabled={loading} type="text" placeholder={t("LASTNAME")} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={["shippingAddress", "vat"]} label={"VAT"}>
                                    <Input disabled={loading} type="text" placeholder={"EE00000000"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={["shippingAddress", "customsCode"]} label={"EORI"}>
                                    <Input disabled={loading} type="text" placeholder={"EORI"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={["shippingAddress", "regCode"]} label={t("REG_NUMBER")}>
                                    <Input disabled={loading} type="text" placeholder={t("REG_NUMBER")} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={["shippingAddress", "country"]}
                                    label={t("COUNTRY")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={loading}
                                        showSearch
                                        optionFilterProp={"children"}
                                        placeholder={t("COUNTRY_SELECT")}
                                    >
                                        {langStore.countries.map(({ id, name }) => (
                                            <Select.Option key={id as string} value={id as string}>
                                                {name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={["shippingAddress", "region"]} label={t("REGION")}>
                                    <Input disabled={loading} type="text" placeholder={t("REGION")} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={["shippingAddress", "city"]}
                                    label={t("CITY")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                        },
                                    ]}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("CITY")} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={["shippingAddress", "index"]}
                                    label={t("INDEX")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                        },
                                    ]}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("INDEX")} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={["shippingAddress", "address"]}
                                    label={t("ADDRESS")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                        },
                                    ]}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESS")} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={["shippingAddress", "phone"]}
                                    label={t("PHONE")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                        },
                                    ]}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("PHONE")} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={["shippingAddress", "email"]}
                                    label={t("EMAIL")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                        },
                                    ]}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("EMAIL")} />
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null}
                </Col>
            </Row>
            <Row gutter={[30, 0]} align={"top"}>

                <Col span={24}>
                    <Space direction={"horizontal"} size={[10, 10]}>
                        <Button type={"default"} onClick={() => setStep(4)}>Back</Button>
                        <Button type={"primary"} onClick={() => form.submit()}>Next</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    </Space>
}