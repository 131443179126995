import React, {useState} from "react";
import {Form, InputNumber, Input, Modal} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {PropertyInventoryTaskProperty} from "../../../../../models/property/property";
import {propertyService} from "../../../../../services/property/property.service";

interface EditQtyModalProps {
    property: PropertyInventoryTaskProperty
    onClose: () => void;
}

export const EditQtyModal: React.FC<EditQtyModalProps> = ({ property, onClose }) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={property.code}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={onClose}
            maskClosable={false}
            width={500}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{qty: property.writeOffQty, reason: property.writeOffReason}}
                onFinish={() => form
                    .validateFields()
                    .then(async values => {
                        setLoading(true);
                        try {
                            await propertyService.editInventoryPropertyWriteOffQty(property.id, values)
                            onClose();
                        }
                        catch (e: any) {
                            form.setFields(e.response?.data?.fields || [])
                        } finally {
                            setLoading(false);
                        }
                    })
                }
            >
                <Form.Item
                    label={t('QTY')}
                    name={['qty']}
                    rules={[{ required: true }]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    label={t('REASON')}
                    name={['reason']}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
}