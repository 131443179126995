import React, { ReactNode, useState } from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../hooks/translate.hook";
import { useVT } from "virtualizedtableforantd4";
import { ColumnsType } from "antd/es/table";
import { ExpandedTableStore } from "./expanded.table.store";
import { Button, Form, Popconfirm, Row, Space, Table, Tooltip, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnsModal } from "../filtered-table/columns-modal/columns.modal";
import {ColumnType, TableRowSelection} from "antd/es/table/interface";
import { EditableCell } from "../../orders/orders-create/create/table/editable-cell/editable.cell";

export interface ExpandedTableColumnType<RecordType> extends ColumnType<RecordType> {
    editable?: boolean;
}

export declare type ExpandedTableColumnsType<RecordType = unknown> = ExpandedTableColumnType<RecordType>[];

interface ExpandedTableProps<T extends object> {
    pageKey: string;
    columns: ColumnsType<T>;
    columnsConfig: { title: string; key: string }[];
    title: string;
    tableData: T[];
    tableLoading: boolean;
    tableFooter?: ReactNode;
    defaultHiddenColumns?: string[];
    isVirtualList?: boolean;
    rowSelection?: TableRowSelection<T>;
    additionButtons?: React.ReactNode;
    isEditable?: boolean;
    editableRowKey?: string;
    onSave?: (id: number, values: any, row?: any) => Promise<any>;
    onDelete?: (deletingItem: any) => Promise<any>;
    getEditValues?: (row: T) => any;
    getSaveValues?: (row: T) => any;
    onEdit?: (row: T) => Promise<any>,
    additionalActions?: (item: any) => React.ReactNode,
    actionsWidth?: number;
}

export const ExpandedTable = observer(
    <T extends object>({
        pageKey,
        columns: passedColumns,
        columnsConfig,
        title,
        tableData,
        tableFooter,
        tableLoading,
        defaultHiddenColumns = [],
        isVirtualList = true,
        rowSelection,
        additionButtons = null,
        isEditable = false,
        editableRowKey,
        onDelete,
        onSave,
        getEditValues,
        getSaveValues,
        onEdit,
        children,
        additionalActions,
        actionsWidth = 100
    }: ExpandedTableProps<T> & { children?: ReactNode }) => {
        let columns = passedColumns;
        const t = useTranslate();
        const [vt, setComponent] = useVT(() => ({ scroll: { y: 600 } }), []);

        if (isEditable && isVirtualList) {
            setComponent({
                body: {
                    cell: EditableCell,
                },
            });
        }

        const [store] = useState(
            () =>
                new ExpandedTableStore(
                    columns.map((item) => ({
                        title: String(item.title),
                        value: String(item.key),
                    })),
                    pageKey,
                    defaultHiddenColumns
                )
        );

        const getColumns = (allColumns: any[]): any => {
            const filteredColumns = allColumns.filter(
                (item) => store.selectedColumns.includes(item.key) && item.key !== "action"
            );
            const actionColumn = allColumns.find((item) => item.key === "action");
            if (actionColumn) {
                filteredColumns.push(actionColumn);
            }
            return filteredColumns ? filteredColumns : [];
        };

        const [form] = Form.useForm();
        const [editingKey, setEditingKey] = useState<number | null>(null);

        // RECORD - объект строки в таблице
        const isEditing = (record: any) => record.id === editingKey;

        const edit = async (row: any) => {
            if (onEdit) {
                onEdit(row);
            }
            form.setFieldsValue(getEditValues!(row));
            setEditingKey(row.id);
        };

        const save = async (row: any) => {
            try {
                const rowData = await form.validateFields();
                await onSave!(row.id, getSaveValues!(rowData), row);
                setEditingKey(null);
            } catch (errInfo) {}
        };

        if (isEditable) {
            columns = passedColumns.map((column) => {
                if (column.key !== "action") {
                    return column;
                }
                return {
                    title: t("ACTION"),
                    dataIndex: "action",
                    key: "action",
                    width: actionsWidth,
                    render: (_: any, record: any) => {
                        const editable = isEditing(record);
                        if (record[editableRowKey!]) {
                            return (
                                <Space direction={"horizontal"}>
                                    {additionalActions ? (
                                        <>
                                            {additionalActions(record)}
                                            {" "}
                                        </>
                                    ) : null}
                                    {onSave ? (
                                        <>
                                            {editable ? (
                                                <Tooltip placement="topRight" title={t("SAVE")}>
                                                    <Button
                                                        className="table-btn btn"
                                                        onClick={() => save(record)}
                                                        type="default"
                                                        icon={<FontAwesomeIcon icon={["fas", "check"]} />}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip placement="topRight" title={t("EDIT")}>
                                                    <Button
                                                        className="table-btn btn"
                                                        disabled={editingKey !== null}
                                                        onClick={() => edit(record)}
                                                        type="default"
                                                        icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                                                    />
                                                </Tooltip>
                                            )}{" "}
                                        </>
                                    ) : null}
                                    {onDelete ? (
                                        <Popconfirm
                                            title={t("ARE_YOU_SURE")}
                                            onConfirm={() => onDelete(record)}
                                            placement="left"
                                            okText={t("YES")}
                                            cancelText={t("NO")}
                                        >
                                            <Tooltip placement="topRight" title={t("REMOVE")}>
                                                <Button
                                                    className="table-btn btn-remove btn"
                                                    disabled={editingKey !== null}
                                                    type="default"
                                                    icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                                                />
                                            </Tooltip>
                                        </Popconfirm>
                                    ) : null}
                                </Space>
                            );
                        }

                        return <></>;
                    },
                };
            });
        }

        const mergedColumns = columns!.map((col: any) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record: any) => ({
                    record,
                    inputType: "number",
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    hint: col.hint,
                    rules: col.validationRules || [],
                }),
            };
        });

        return (
            <>
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <Typography.Title level={3} style={{ width: "auto" }}>
                        {t(title)}
                    </Typography.Title>
                    <Space style={{ marginTop: 3, marginLeft: 5 }} direction="horizontal" size={[5, 5]}>
                        <Button
                            onClick={store.openColumnsSettingsModal}
                            className={" btn"}
                            icon={<FontAwesomeIcon icon={["fas", "cog"]} />}
                        />
                        {additionButtons}
                    </Space>
                </div>
                {children}
                <Form form={form} component={false}>
                    <Table<T>
                        rowSelection={rowSelection}
                        className="order-data-details"
                        rowKey={"id"}
                        columns={isEditable ? getColumns(mergedColumns) : getColumns(columns!)}
                        dataSource={tableData}
                        loading={tableLoading}
                        scroll={isVirtualList ? { y: 600, x: true } : { y: 600, x: true }}
                        components={isVirtualList ? vt : isEditable ? { body: { cell: EditableCell } } : undefined}
                        pagination={false}
                        footer={tableFooter ? () => tableFooter : undefined}
                    />
                </Form>
                <ColumnsModal
                    visible={store.columnsSettingsModalShown}
                    columns={columnsConfig}
                    selectedColumns={store.selectedColumns}
                    selectColumns={store.selectColumns}
                    onClose={store.closeColumnsSettingsModal}
                />
            </>
        );
    }
);
