import React, {useState} from "react";
import {observer} from "mobx-react";
import {OrderDeliveryCreateStore, OrderDeliveryCreateStoreContext} from "./order.delivery.create.store";
import {LoadingSpin} from "../../common/loading-spin/loading.spin";
import {Space, Typography} from "antd";
import {OrdersDeliveryList} from "./orders.delivery.list";
import {OrdersDeliveryOptions} from "./options/orders.delivery.options";
import {OrderDeliveryAddress} from "./address/order.delivery.address";
import {OrderDeliveryButtons} from "./buttons/order.delivery.buttons";
import {OrderDeliveryPrice} from "./price/order.delivery.price";
import {useNavigate} from "react-router";

export const OrderDeliveryCreate: React.FC = observer(() => {
    const navigate = useNavigate();
    const [store] = useState(() => new OrderDeliveryCreateStore(navigate));

    if (store.loading) {
        return <LoadingSpin/>
    }

    return (
        <OrderDeliveryCreateStoreContext.Provider value={store}>
            <Space direction="vertical" size={[50, 50]}>
                <Typography.Title level={1}>{store.deliveryOrder?.account.agent.name}</Typography.Title>
                <OrdersDeliveryList/>
                <OrderDeliveryAddress/>
                <OrdersDeliveryOptions/>
                <OrderDeliveryPrice/>
                <OrderDeliveryButtons/>
            </Space>
        </OrderDeliveryCreateStoreContext.Provider>
    )
})
