import {FilterDataHandler} from "../../../models/filter/filter.data.handler";
import {Error} from "../../../models/errors/error";
import {errorsService} from "../../../services/errors/errors.service";
import {PaginatedRequest} from "../../../models/core/paginated.request";

export class ErrorsListStore {
    dataHandler: FilterDataHandler<Error[]>;

    constructor(private group?: string) {
        this.dataHandler = new FilterDataHandler<Error[]>(request => errorsService.getErrors(request, this.group === "all" ? undefined : this.group))
    }

    public async updateGroup(group: string) {
        this.group = group;
        await this.dataHandler.reloadItems(new PaginatedRequest(group !== "all" ? {group} : undefined));
    }
}
