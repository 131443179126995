export interface Camera {
    id: string;
    label: string;
}

export class Photograph {

    private stream: MediaStream | null = null;

    public static listCameras = (): Promise<Array<Camera>> => {
        if (!navigator.mediaDevices) {
            return Promise.resolve([]);
        }

        return navigator
            .mediaDevices
            .enumerateDevices()
            .then(v => {
                return v
                    .filter((device) => device.kind === 'videoinput')
                    .map((camera, i) => ({
                        id: camera.deviceId,
                        label: camera.label || (i === 0 ? 'Default Camera' : `Camera ${i + 1}`),
                    }));
            })
            .catch(() => {
                return [];
            })
    }

    constructor(
        private video: HTMLVideoElement,
        private onImageClick?: (blob: Blob) => void,
        private selectedCam?: string
    ) {
        // @ts-ignore
        video.disablePictureInPicture = true;
        // Allow inline playback on iPhone instead of requiring full screen playback,
        // see https://webkit.org/blog/6784/new-video-policies-for-ios/
        // @ts-ignore
        video.playsInline = true;
        // Allow play() on iPhone without requiring a user gesture. Should not really be needed as camera stream
        // includes no audio, but just to be safe.
        video.muted = true;
    }

    onImageClickListener = () => {
        const onImageClick = this.onImageClick;
        if (onImageClick) {
            this.captureFrame().then(blob => onImageClick(blob))
        }
    }

    start(): Promise<void> {
        if (!navigator.mediaDevices) {
            return Promise.reject("Failed to get cameras");
        }

        return navigator.mediaDevices
            .getUserMedia({ video: {deviceId: this.selectedCam}, audio: false })
            .then((stream) => {
                if ('srcObject' in this.video) {
                    this.video.srcObject = stream;
                    this.stream = stream;
                } else {
                    // @ts-ignore
                    this.video.src = window.URL.createObjectURL(stream);
                }
                this.video.addEventListener('click', this.onImageClickListener);

                return this.video.play();
            })
            .catch((err) => {
                console.error(`An error occurred: ${err}`);
            });
    }

    setCamera(id: string) {
        this.selectedCam = id;
        this.destroy();
        return this.start();
    }

    destroy() {
        this.video.pause()
        this.video.removeEventListener('click', this.onImageClickListener);
        if (this.stream) {
            this.stream.getTracks().forEach(track => {
                track.stop();
            })
            this.stream = null;
        }
    }

    captureFrame(): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');

            canvas.width = this.video.videoWidth;
            canvas.height = this.video.videoHeight;

            const context = canvas.getContext("2d");

            if (context) {
                context.drawImage(this.video, 0, 0)
                canvas.toBlob(blob => {
                    if (blob) {
                      resolve(blob)
                    } else {
                      reject('Failed to get blob')
                    }
                }, 'image/png')
            } else {
                reject('Failed to get context')
            }
        })
    }
}