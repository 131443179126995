import { Bank } from "./../../models/banks/bank";
import { AxiosInstance } from "axios";
import API from "../../utils/api";
export class BanksService {
    private api: AxiosInstance = API;

    getAllBanks(): Promise<Bank[]> {
        return this.api.get<Bank[]>("branches/banks/all").then((x) => x.data);
    }
}

export const banksService = new BanksService();
