import React, {useState} from "react";
import {Claim, ClaimItem} from "../../../../../models/claims/claims";
import {Button, Modal} from "antd";
import {ClaimPart} from "../../part/claims.part";
import {useTranslate} from "../../../../../hooks/translate.hook";

export interface ClaimPartsDetailsProps {
    claim: Claim
    item: ClaimItem
    onUpdate: () => void
}

export const ClaimPartsDetails: React.FC<ClaimPartsDetailsProps> = ({claim, item, onUpdate}) => {
    const t = useTranslate();

    const [previewItem, setPreviewItem] = useState<ClaimItem|null>(null);

    const openItemCart = (item: ClaimItem) => {
        setPreviewItem(item);
    }

    const closeItemCart = () => {
        setPreviewItem(null);
    }

    return (
        <>
            <Button size={"small"} onClick={() => openItemCart(item)}>{t('SHOW_DETAILS')}</Button>
            <Modal
                width={800}
                visible={!!previewItem}
                onCancel={() => closeItemCart()}
                closable={false}
                className={"modal-without-borders"}
                destroyOnClose
            >
                <>{previewItem ? (
                    <ClaimPart claim={claim} item={previewItem} onUpdate={onUpdate} />): null}</>
            </Modal>
        </>
    )
}