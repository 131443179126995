import React, {useState} from "react";
import {Col, Form, Input, Modal, Row} from "antd";
import {useForm} from "antd/es/form/Form";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {PurchasingAlliance} from "../../../../../models/settings/purchasing-alliances";
import {purchasingAlliancesService} from "../../../../../services/settings/purchasing-alliances.service";

interface PurchasingAlliancesModalProps {
    alliance: PurchasingAlliance | null;
    type: string;
    onCancel: () => void;
    onComplete: () => void;
}

export const PurchasingAlliancesModal: React.FC<PurchasingAlliancesModalProps> = ({onCancel, onComplete, alliance, type}) => {
    const t = useTranslate();

    const [form] = useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const initialValues: any = {}
    if (alliance) {
        initialValues.name = alliance.name
    }

    return (
        <Modal
            title={t(alliance? 'Edit alliance' : 'New alliance')}
            width={400}
            onCancel={onCancel}
            onOk={() => {
                form.submit();
            }}
            open
            destroyOnClose
            loading={loading}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={async (values) => {
                    await form.validateFields();
                    try {
                        setLoading(true)
                        if (alliance) {
                            await purchasingAlliancesService.update(alliance.id, values);
                        } else {
                            await purchasingAlliancesService.create({...values, type});
                        }
                        onCancel()
                        onComplete()
                    } catch (e: any) {
                        form.setFields(e.response?.data?.fields);
                    } finally {
                        setLoading(false)
                    }
                }}
                autoComplete="off"
                initialValues={initialValues}
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            label={t('NAME')}
                            name={['name']}
                            rules={[{ required: true }]}
                        >
                            <Input disabled={loading} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}