import React, {useEffect, useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Col, Modal, Row, Space, Statistic, Table} from "antd";
import {ordersListService} from "../../../../services/orders/orders-list/orders.list.service";
import {OrdersSummary} from "../../../../models/orders/orders-list/order";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {OrderStatus} from "../../../../stores/common/common.store";
import {OrdersStatus} from "../../status/order.status";
import {Amount} from "../../../../models/prices/price";
import {createVisiblePrice, displayPrice} from "../../../../models/prices/price.helper";
import Date from "../../../date/date";

interface OrderListSummaryModalProps {
    checkedOrders: number[];
    onClose: () => void
}
export const OrderListSummaryModal: React.FC<OrderListSummaryModalProps> = ({checkedOrders, onClose}) => {
    const t = useTranslate();

    const [summaryData, setSummaryData] = useState<OrdersSummary>();

    useEffect(() => {
        ordersListService.getSummary(checkedOrders).then(data => setSummaryData(data));
    }, [checkedOrders])

    if (!summaryData) {
        return <LoadingSpin />
    }

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t("STATUS"),
            dataIndex: 'status',
            key: 'status',
            render: (item: OrderStatus) => <OrdersStatus status={item} />
        },
        {
            title: t("CREATED_AT"),
            dataIndex: 'date',
            key: 'date',
            render: (item: string) => <Date dateString={item} showTime />
        },
        {
            title: t("TOTAL_PRICE"),
            dataIndex: 'subtotal',
            key: 'subtotal',
            render: (item: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(item)}</span>
        },
        {
            title: t("NETTO_WEIGHT"),
            dataIndex: 'netWeight',
            key: 'netWeight',
        },
        {
            title: t("WEIGHT_BRUTTO"),
            dataIndex: 'grossWeight',
            key: 'grossWeight',
        },
    ];

    return (
        <Modal
            title={t("ORDERS.SUMMARY")}
            cancelText={t("CANCEL")}
            onCancel={() => onClose()}
            onOk={() => onClose()}
            visible={true}
            width={800}
        >
            <Row gutter={24}  style={{marginTop: 10}}>
                <Col span={8}>
                    <Statistic title={t('NET_WEIGHT_TOTAL')} value={summaryData?.netWeight + ' kg.'} />
                </Col>
                <Col span={8}>
                    <Statistic title={t('GROSS_WEIGHT_TOTAL')} value={summaryData?.grossWeight + ' kg.'} />
                </Col>
                <Col span={8}>
                    <Statistic title={t('Total')} value={createVisiblePrice(summaryData.subtotal)} />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24} style={{marginTop: 30}}>
                    <Table
                        dataSource={summaryData.orders}
                        columns={columns}
                        pagination={false}
                        />
                </Col>
            </Row>
        </Modal>
    )
}
