import React from "react";
import {WidgetProps} from "../../../../../../../common/cards-view/model/model";
import {Select} from "antd";
import {PlannerTaskPriority} from "../../../../../../../../models/planner/planner";

export const TaskPriorityWidget: React.FC<WidgetProps> = ({onChange, value, placeholder}) => {

    return (
        <Select
            mode={"multiple"}
            onChange={onChange as any}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
            placeholder={placeholder}
            maxTagCount={1}
            variant={"filled"}
        >
            {Object.values(PlannerTaskPriority).map(v =>
                <Select.Option key={v} value={v}>{v.capitalizeFirstLetter()}</Select.Option>)}
        </Select>
    )
}