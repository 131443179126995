import React, { useState } from "react";
import { observer } from "mobx-react";
import { Space, Spin } from "antd";
import { BranchDataWarehouseForm } from "./branch.data.warehouse.form";
import { QuickLink, QuickLinks } from "../../../../common/quick-links/quick-links";
import { useParams, useLocation } from "react-router-dom";
import { BranchDataWarehouseStore, BranchDataWarehouseStoreContext } from "./branch.data.warehouse.store";
import { useRequiredStore } from "../../../../../utils/store";
import { RootStoreContext } from "../../../../../stores/root/root.store";
import {PageHeader} from "@ant-design/pro-components";
import {useNavigate} from "react-router";

const warehouseQuickLinks: QuickLink[] = [
    {
        path: "/warehouses/taxes",
        text: "WAREHOUSES.TAXES.TITLE",
        description: "WAREHOUSES.TAXES.DESCRIPTION",
    },
    {
        path: "/articles/stock",
        text: "ARTICLES.WAREHOUSES.TITLE",
        description: "ARTICLES.WAREHOUSES.DESCRIPTION",
    },
    {
        path: "/articles/history",
        text: "ARTICLES.HISTORY",
        description: "ARTICLES.WAREHOUSES.DESCRIPTION",
    },
];
export const BranchDataWarehouse: React.FC = observer(() => {
    const { branchId, warehouseId } = useParams<{ branchId: string; warehouseId: string }>();
    const { pageStore } = useRequiredStore(RootStoreContext);
    const match = useLocation();
    const navigate = useNavigate();
    const [store] = useState(() => new BranchDataWarehouseStore(branchId!, warehouseId!, pageStore, match.pathname, navigate));

    if (store.loading) {
        return <Spin />;
    }

    return (
        <BranchDataWarehouseStoreContext.Provider value={store}>
            <Space direction="vertical" size={[30, 30]}>
                <PageHeader
                    style={{ padding: 0 }}
                    // ghost={false}
                    title={store.warehouse?.name}
                    onBack={() => {
                        navigate("/branches");
                    }}
                >
                    <BranchDataWarehouseForm />
                </PageHeader>

                <QuickLinks links={warehouseQuickLinks} />
            </Space>
        </BranchDataWarehouseStoreContext.Provider>
    );
});
