import React, {useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {Button, Col, Row, Space, Typography, Divider} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MarkdownViewer} from "../../../core/markdown/markdown-viewer/markdown-viewer";
import {wikiService} from "../../../../services/wiki/wiki.service";
import {useParams, useLocation} from "react-router-dom";
import {WikiArticle} from "../../../../models/wiki/wiki";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Link} from "react-router-dom";
import {MarkdownHeaders} from "../../../core/markdown/markdown-headers/markdown-headers";
import i18n from "i18next";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {copyToClipboard} from "../../../../utils/clipboard";
import {isAllowed} from "../../../../utils/helpers";
import {downloadBlobFile} from "../../../../utils/blob.download";
import dayjs from "dayjs";

const { Title, Text } = Typography;

interface WikiViewPageProps extends ComponentPropsFromRoute {
}

export const WikiViewPage: React.FC<WikiViewPageProps> = () => {

    const {pageStore} = useRequiredStore(RootStoreContext);

    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);

    const location = useLocation();

    const t = useTranslate();

    const params = useParams<{ id: string }>();

    const [article, setArticle] = useState<WikiArticle>();

    const [disabled, setDisabled] = useState<boolean>(false);


    const reload = () => {
        wikiService
            .getArticle(parseInt(params.id!))
            .then(article => {
                setArticle(article)
                pageStore.updateRouteName(location.pathname, article.title)
            })
    }

    const downloadPDF = async () => {
        setDisabled(true);
        const {blob, filename} = await wikiService.downloadArticleAsPDF(parseInt(params.id!))
        downloadBlobFile(blob, filename);
        setDisabled(false);
    }

    const downloadHTML = async () => {
        setDisabled(true);
        const {blob, filename} = await wikiService.downloadArticleAsHTML(parseInt(params.id!))
        downloadBlobFile(blob, filename);
        setDisabled(false);
    }

    useEffect(() => {
        reload();
    }, [params.id])

    useEffect(() => {
        i18n.on("languageChanged", reload);
        return () => i18n.off("languageChanged", reload);
    }, []);

    if (!article) {
        return <LoadingSpin />
    }

    return (
        <Row wrap={false} gutter={[40, 0]}>
            <Col flex="auto">
                <Title level={2} style={{marginBottom: 0}}>{article.title}</Title>
                <div style={{paddingBottom: 30}}>{article.description}</div>
                <MarkdownViewer>{article.content}</MarkdownViewer>
            </Col>
            <Col flex="260px">

                <Space direction={"vertical"} split={<Divider orientation="left" />}>

                    <Space direction={"vertical"}>
                        {isAllowed(attributes, "wiki_edit") ?
                            <Link to="/wiki/new-page" className="ant-btn ant-btn-primary" style={{width: '100%'}}>
                                {t("WIKI.CMD.ADD_PAGE")}
                            </Link>
                            : null }

                        <Row justify="space-between">
                        {isAllowed(attributes, "wiki_edit") && article.editable ?
                            <Col span={4}>

                                    <Link to={"/wiki/page/" + params.id + "/edit"}>
                                        <Button icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />} />
                                    </Link>

                            </Col>
                            : null }
                            <Col span={4}>
                                <Button icon={<FontAwesomeIcon icon={["fas", "link"]} />} onClick={() => copyToClipboard(document.location.href)} disabled={disabled} />
                            </Col>
                            <Col span={4}>
                                <Button icon={<FontAwesomeIcon icon={["fas", "file-pdf"]} />} onClick={downloadPDF} disabled={disabled} />
                            </Col>
                            <Col span={4}>
                                <Button icon={<FontAwesomeIcon icon={["fas", "file-code"]} />} onClick={downloadHTML} disabled={disabled} />
                            </Col>
                        </Row>
                    </Space>

                    <MarkdownHeaders value={article.content} />

                    <Space direction={"vertical"}>
                        <h3>{t("WIKI.VIEW.AUTHOR")}</h3>
                        <Text>{article.author.firstName} {article.author.lastName}</Text>
                        <Text disabled>{dayjs(article.lastEditedAt).format("DD/MM/YYYY HH:MM")}</Text>
                    </Space>
                </Space>
            </Col>
        </Row>
    )
}