import React from "react";
import {Form, Input, Modal} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {useForm} from "antd/es/form/Form";

interface DocumentFilesUploadModalProps {
    onClose: () => void;
    onComplete: (maxDocuments: number) => void;
}

export const DownloadXMLModal: React.FC<DocumentFilesUploadModalProps> = ({onClose, onComplete}) => {

    const t = useTranslate();

    const [form] = useForm();

    return (
        <Modal
            width={"400px"}
            visible={true}
            title={t("DECLARATION.TITLE")}
            onOk={() => form.submit()}
            onCancel={() => onClose()}
            okText={t("Download")}
        >
            <Form
                form={form}
                initialValues={{maxDocuments: 99}}
                layout="vertical"
                onFinish={() =>
                    form.validateFields().then((values) => {
                        onComplete(values.maxDocuments);
                        onClose();
                    })
                }
            >
                <Form.Item
                    name="maxDocuments"
                    label={t("DECLARATION.DOWNLOAD.XML.MAX_DOCUMENTS")}
                    rules={[
                        {
                            required: true,
                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                        },
                    ]}
                >
                    <Input type={"number"} />
                </Form.Item>
            </Form>
        </Modal>
    )
}