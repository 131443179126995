import React, {useState} from "react";
import {observer} from "mobx-react";
import {Space, Alert} from "antd";
import {useRequiredStore} from "../../../../utils/store";
import {OrdersCheckoutAdvancedOrder} from "./orders-checkout-order/orders.checkout.order";
import {OrdersCheckoutStore, OrdersCheckoutStoreContext} from "./orders.checkout.store";
import {AgentDataStoreContext} from "../../../agents/agent-data/agent.data.store";
import {OrdersCheckoutNotValidItemsModal} from "./orders-checkout-not-valid-items-modal/orders.checkout.not.valid.items.modal";
import {OrdersCheckoutCancelOrderModal} from "./orders-checkout-cancel-order-modal/orders.checkout.cancel.order.modal";
import {useTranslate} from "../../../../hooks/translate.hook";
import Title from "antd/es/typography/Title";

export const OrdersCheckout: React.FC = observer(() => {
    const t = useTranslate();
    const agentStore = useRequiredStore(AgentDataStoreContext);
    const [store] = useState(() => new OrdersCheckoutStore(agentStore.account!, agentStore.decreaseAdvancedOrderAmount, agentStore.updateAdvancedOrdersCount));

    return (
        <OrdersCheckoutStoreContext.Provider value={store}>
            <Space direction="vertical" size={[20, 20]}>
                {store.loading ? null : (store.notCompletedOrders.length > 0 ? (
                   <Space direction={"vertical"} size={[40, 40]}>
                       {store.todayOrders.length > 0 ? (
                           <Space direction={"vertical"}>
                               {store.upToThisDayOrders.length > 0 ? (
                                   <Title level={3}>{t("ORDERS.NOT_COMPLETED_ORDERS.PART.TODAY")}</Title>
                               ) : null}
                               {store.todayOrders.map(order => <OrdersCheckoutAdvancedOrder key={order.id} advancedOrder={order} />)}
                           </Space>
                       ) : null}
                       {store.upToThisDayOrders.length > 0 ? (
                           <Space direction={"vertical"}>
                               {store.todayOrders.length > 0 ? (
                                   <Title level={3}>{t("ORDERS.NOT_COMPLETED_ORDERS.PART.UP_TO_THIS_DAY")}</Title>
                               ) : null}
                               {store.upToThisDayOrders.map(order => <OrdersCheckoutAdvancedOrder key={order.id} advancedOrder={order} />)}
                           </Space>
                       ) : null}
                   </Space>
                ) : (
                    <Alert type="info" message={t("CLIENT.NO_NOT_COMPLETED_ORDERS")} />
                ))}

            </Space>
            <OrdersCheckoutCancelOrderModal/>
            <OrdersCheckoutNotValidItemsModal/>
        </OrdersCheckoutStoreContext.Provider>
    );
});
