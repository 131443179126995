import React, {useMemo, useState} from "react";
import {declarationsProvidersService} from "../../../services/declarations/declarations.providers.service";
import {useTranslate} from "../../../hooks/translate.hook";
import {SmartTable} from "../../common/smart-table/smart-table";
import {columnsFactory} from "./declaration.providers.columns";
import {SmartTableDataHandler} from "../../common/smart-table/smart-table.data-handler";
import {DeclarationProvider} from "../upload/declaration.upload.model";
import {DeclarationProviderEditModal} from "./modal/edit/declaration.provider.edit.modal";
import {Button, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const DeclarationsProviders: React.FC = () => {
    const t = useTranslate();

    const columns = useMemo(() => columnsFactory(t), []);
    const handler = useMemo(() => new SmartTableDataHandler(request => declarationsProvidersService.getAll(request)), []);

    const [modalShown, setModalShown] = useState<boolean>(false);
    const [modalItem, setModalItem] = useState<DeclarationProvider | null>(null);

    const openModal = (item: DeclarationProvider | null) => {
        setModalShown(true);
        setModalItem(item);
    }

    const closeModal = async () => {
        setModalShown(false);
        setModalItem(null);
        await handler.reloadItemsWithLastRequest();
    }

    const remove = async (item: DeclarationProvider) => {
        await declarationsProvidersService.remove(item.id);
        await handler.reloadItemsWithLastRequest();
    }

    return (
        <Space direction={"vertical"} size={[10, 10]}>
            <Button
                type={"primary"}
                onClick={() => openModal(null)}
                icon={<FontAwesomeIcon icon={["fas", "plus"]} />}
            >
                {t('CREATE')}
            </Button>
            <SmartTable
                columns={columns}
                handler={handler}
                actions={[
                    {
                        icon: 'edit',
                        title: t('EDIT'),
                        onClick: openModal
                    },
                    {
                        icon: 'trash',
                        title: t('REMOVE'),
                        onClick: remove
                    }
                ]}
            />
            {modalShown ? (
                <DeclarationProviderEditModal item={modalItem} onClose={closeModal} />
            ) : null}
        </Space>
    );
};

