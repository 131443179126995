import React from "react";
import {PlannerTask, PlannerTaskSystemStatus} from "../../../../../../../models/planner/planner";
import {PlannerTasksViewAction} from "../task.view.action";
import {Action} from "../../model";
import {plannerService} from "../../../../../../../services/planner/planner.service";

export const PlannerTasksViewToReviewAction: Action = ({task, reload}) => {

    const run = async () => {
        await plannerService.toReview(task.id);
        reload();
    }

    return (
        <PlannerTasksViewAction
            title={"TO_REVIEW"}
            run={run}
        />
    )
}

PlannerTasksViewToReviewAction.isApplicable = (task: PlannerTask) => {
    return [
        PlannerTaskSystemStatus.IN_PROGRESS
    ].includes(task.status);
}