import React from "react";
import {
    PropertyInventoryTaskPart,
} from "../../../../../models/property/property";
import {Descriptions} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";

interface InventoryTaskPartDescriptionProps {
    part: PropertyInventoryTaskPart;
}
export const InventoryTaskPartDescription: React.FC<InventoryTaskPartDescriptionProps> = ({part}) => {

    const t = useTranslate();

    return (
        <div className="data-descriptions-with-edit-wrapper">
            <div className="data-content-wrapper">
                <Descriptions
                    className="data-content-wrapper-descriptions"
                    size={"small"}
                    layout={"vertical"}
                    contentStyle={{ fontSize: "17px" }}
                    labelStyle={{ color: "#858585" }}
                    column={{ xxl: 5, xl: 5, lg: 5, md: 3, sm: 2, xs: 1 }}
                >
                    <Descriptions.Item label={t("PROPERTY.PROPERTY.TYPE")}>
                        {part.type.name}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("QTY")}>
                        {part.qty}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("PROPERTY.INVENTORY_TASK_PROPERTY_CONFIRMED_QTY.TITLE")}>
                        {part.confirmedQty}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("PROPERTY.INVENTORY_TASK_PROPERTY_DAMAGED_QTY.TITLE")}>
                        {part.destroyedQty}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("PROPERTY.INVENTORY_TASK_PROPERTY_LOST_QTY.TITLE")}>
                        {part.lostQty}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("PROPERTY.PROPERTY.RESPONSIBLE_PERSON")}>
                        {part.responsiblePersons.map(p => p.firstName + ' ' + p.lastName).join(', ')}
                    </Descriptions.Item>
                </Descriptions>
            </div>
        </div>
    )
}
