import React, {ChangeEvent} from "react";
import {Input, InputProps, Tooltip} from 'antd';
import {useTranslate} from "../../../hooks/translate.hook";

export const NumberInput: React.FC<InputProps> = (props) => {
    const t = useTranslate();

    const formatNumber = (value: any) => {
        value += '';
        const list = value.split('.');
        const prefix = list[0].charAt(0) === '-' ? '-' : '';
        let num = prefix ? list[0].slice(1) : list[0];
        let result = '';
        while (num.length > 3) {
            result = `,${num.slice(-3)}${result}`;
            num = num.slice(0, num.length - 3);
        }
        if (num) {
            result = num + result;
        }
        return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value }: any = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((reg.test(value)) || value === '' || value === '-') {
            props.onChange!(value);
        }
    };

    // '.' at the end or only '-' in the input box.
    const onBlur = (e: any) => {
        const { value, onBlur, onChange } = props;
        if (value && (typeof(value) === "string")) {
            let valueTemp: any = value;
            if (value.charAt(value.length - 1) === '.' || value === '-') {
                valueTemp = value.slice(0, -1);
            }
            if (onChange) {
                onChange(valueTemp.replace(/0*(\d+)/, '$1'));
            }
            if (onBlur) {
                onBlur(e);
            }
        }
    };

    const { value } = props;
    const title = value ? (
        <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
    ) : (
        props.placeholder || t("INPUT_NUMBER")
    );

    return (
        <Tooltip
            trigger={['focus']}
            title={title}
            placement="topLeft"
            overlayClassName="numeric-input"
        >
            <Input
                {...props}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={props.placeholder || t("INPUT_NUMBER")}
                maxLength={25}
            />
        </Tooltip>
    );
}