import React, {useEffect, useState} from "react";
import {Form, Input, Modal, Select, Upload} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useForm} from "antd/es/form/Form";
import {Aggregator} from "../../../../models/transaction/aggregator";
import {transactionService} from "../../../../services/documents/common/transaction.service";

interface DocumentEditTransactionModalProps {
    id: number;
    transaction: {transactionID: string, aggregator: Aggregator | null};
    onClose: () => void;
    onComplete: (id: string) => void;
}

export const DocumentEditTransactionModal: React.FC<DocumentEditTransactionModalProps> = ({id, transaction, onClose, onComplete}) => {

    const t = useTranslate();

    const [form] = useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const [platforms, setPlatforms] = useState<Aggregator[]>([]);

    useEffect(() => {
        transactionService.getAggregators().then(platforms => setPlatforms(platforms))
    }, [])

    return (
        <Modal
            width={"600px"}
            visible={true}
            title={t("ORDERS.TRANSACTION_ID")}
            onOk={() => form.submit()}
            onCancel={() => onClose()}
            okText={t("SAVE")}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{transactionID: transaction.transactionID, aggregator: transaction.aggregator?.id}}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        transactionService.setTransaction(id, values.transactionID, values.aggregator).then(() => {
                            onClose();
                            onComplete(values.transactionID);
                        }).finally(() => {
                            setLoading(false);
                        })
                    })
                }
            >
                <Form.Item
                    name="transactionID"
                    label={t("ORDERS.TRANSACTION_ID")}
                    rules={[
                        {
                            required: true,
                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                        },
                    ]}
                >
                    <Input
                        type={"text"}
                        disabled={loading}
                        placeholder={t("ORDERS.TRANSACTION_ID")}
                    />
                </Form.Item>
                <Form.Item
                    name="aggregator"
                    label={t("PLATFORM.TITLE")}
                    rules={[
                        {
                            required: true,
                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                        },
                    ]}
                >
                    <Select
                        showSearch
                        allowClear
                        placeholder={t("PLATFORM.TITLE")}
                    >
                        {platforms.map(({id}) => (
                            <Select.Option key={id} value={id}>{id}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
}