import { languagesService } from "./../../../../services/languages/languages.service";
import { PriceList } from "./../../../../models/price-lists/price.lists";
import { Warehouse } from "./../../../../models/missing-items/missing.item";
import { warehousesService } from "./../../../../services/warehouses/warehouses.service";
import { priceListsService } from "./../../../../services/price-lists/price-lists.service";
import { ExportFormat } from "./../../../../stores/common/common.store";
import { action } from "mobx";
import { makeObservable, observable } from "mobx";
import { articlesListService } from "../../../../services/articles/articles/articles.list.service";

export class ArticlesExportModalStore {

    @observable
    public submitting: boolean = false;

    @observable
    public options: { value: string; label: string }[] = [];

    @observable
    public warehouses: Warehouse[] = [];

    @observable
    public prices: PriceList[] = [];

    @observable
    public locales: string[] = [];

    @observable
    public ready: boolean = false;

    constructor() {
        makeObservable(this);
        this.init();
    }

    @action
    public setSubmitting = (value: boolean): void => {
        this.submitting = value;
    };

    @action
    private setLocales(value: string[]): void {
        this.locales = value;
    }

    @action
    private setWarehouses(value: Warehouse[]): void {
        this.warehouses = value;
    }

    @action
    private setPrices(value: PriceList[]): void {
        this.prices = value;
    }

    @action
    private setOptions(options: { value: string; label: string }[]): void {
        this.options = options;
    }

    @action
    private setReady(): void {
        this.ready = true;
    }

    private async reload() {
        const prices = await priceListsService.getPriceLists();
        const warehouses = await warehousesService.getAllWarehouses();
        this.setWarehouses(warehouses);
        this.setPrices(prices);
        this.setLocales(await languagesService.getLocales());
        this.setOptions([
            { value: "id", label: "ID" },
            { value: "reference", label: "Reference" },
            { value: "description", label: "Description" },
            { value: "oem", label: "OEM" },
            { value: "cars", label: "Cars"},
            { value: "hs_code", label: "HS code" },
            { value: "ean_code", label: "EAN code" },
            { value: "", label: "Custom" },
            { value: "price", label: "Price" },
            { value: "price_rrp", label: "Price (RRP)" },
            ...prices.map((item) => ({
                value: "price_" + item.id,
                label: "Price (" + item.name + ")",
            })),
            ...warehouses.map((item) => ({
                value: "stock_" + item.id,
                label: "Stock (" + item.name + ")",
            })),
            { value: "net_weight", label: "Net Weight" },
            { value: "gross_weight", label: "Gross weight"},
            { value: "width",  label: "Width"},
            { value: "height", label: "Height"},
            { value: "length", label: "Length"},
            { value: "category", label: "Category"},
            { value: "subcategory", label: "Subcategory"},
            { value: "multiple", label: "Multiplicity"},
        ]);
        this.setReady();
    }

    private async init() {
        await this.reload();
    }

    public getExportDefaultValues = async () => {
        const config = await articlesListService.getArticlesExportConfig();
        if (config) {
            return { ...config, fields: config.fields.slice().sort((a: any, b: any) => a.sort! - b.sort!) };
        }
        return {
            language: "en",
            config: { withHeaders: true, format: ExportFormat.XLSX },
            fields: [{ name: "", visibleName: "", extra: "", sort: 0 }],
            excludeCatalogHidden: false,
            excludeStatisticHidden: false
        };
    };

    public export = async (values: any) => {
        const reqValues = await Promise.resolve({
            ...values,
            fields: values.fields.slice().map((item: any, index: number) => ({
                ...item,
                sort: index,
                extra: !!item.extra ? parseInt(item.extra, 10) : 0,
            })),
        });
        return await articlesListService.exportArticles(reqValues);
    };
}
