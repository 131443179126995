import API from "../../../utils/api";
import {AxiosInstance} from "axios";
import {PaginatedRequest} from "../../../models/core/paginated.request";
import {PaginatedResult} from "../../../models/core/paginated.result";
import {Invoice} from "../../../models/documents/invoices/invoice";
import {InvoiceData} from "../../../models/documents/invoices/invoice-data/invoice.data";
import React from "react";
import {DispatchAwareInterface} from "./dispatch-aware.interface";
import {CreateInvoice} from "../../../models/documents/invoices/invoice-data/invoice.create";

export class InvoicesService implements DispatchAwareInterface {
    private api: AxiosInstance = API;

    getInvoices(request: PaginatedRequest, defaultFilters?: {[key: string]: string | string[]}): Promise<PaginatedResult<Invoice>> {
        if (defaultFilters) {
            Object.keys(defaultFilters).forEach(key => {
                request.addFilter(key, defaultFilters[key]);
            })
        }
        return this.api.get<PaginatedResult<Invoice>>(
            "documents/invoices",
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }

    createInvoice(dto: CreateInvoice): Promise<InvoiceData> {
        return this.api
            .post<InvoiceData>("documents/invoices", dto)
            .then(x => x.data);
    }

    getInvoice(id: string): Promise<InvoiceData> {
        return this.api.get<InvoiceData>("documents/invoices/" + id).then(x => x.data);
    }

    dispatchInvoices(value: {date: string, invoices: React.Key[]}): Promise<void> {
        return this.api.post("documents/invoices/dispatch", value);
    }

    setDate(id: string, date: string): Promise<void> {
        return this.api.post("documents/invoices/" + id + "/set-date", {date});
    }

    recalcWeight(id: string, values: {weight: string}): Promise<void> {
        return this.api.post("documents/invoices/" + id + "/recalc-weights", values);
    }

    updateWeight(id: number, values: {netWeight: number, grossWeight: number}): Promise<void> {
        return this.api.post("documents/invoices/items/" + id + "/weights", values);
    }

    edit(id: string, values: {paymentTerms: number, shipmentCondition: string}): Promise<void> {
        return this.api.post("documents/invoices/" + id, values);
    }

    removeOrder(id: number, orderId: number): Promise<void> {
        return this.api.delete("documents/invoices/" + id + "/order/" + orderId);
    }

    cancelInvoice(id: string): Promise<void> {
        return this.api.post("documents/invoices/" + id + "/cancel");
    }

    cancelInvoiceWithOrders(id: string): Promise<void> {
        return this.api.post("documents/invoices/" + id + "/cancel-with-orders");
    }

    uploadInvoice(id: string, options: object): Promise<void> {
        return this.api.post("documents/invoices/" + id + "/upload", options);
    }

    moveInvoiceToCart(id: string): Promise<void> {
        return this.api.post("documents/invoices/" + id + "/move-to-cart");
    }

    moveInvoiceToCartWithClear(id: string): Promise<void> {
        return this.api.post("documents/invoices/" + id + "/move-to-cart-with-clear");
    }

    createExportInvoices(values: {weight: number, invoices: number[] | React.Key}): Promise<any> {
        return this.api.post("documents/invoices/export-invoice", values, {responseType: "arraybuffer"}).then(x => ({
            headers: x.headers,
            data: x.data
        }));
    }

    getAllNewInvoices(accountId: number): Promise<Invoice[]> {
        return this.api
            .get<Invoice[]>("accounts/" + accountId + "/documents/invoices/new")
            .then((x) => x.data);
    }
}

export const invoiceService = new InvoicesService();
