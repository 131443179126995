import React from "react";
import {WidgetProps} from "../widget";
import {Col, Input, Row} from "antd";
import "./ui.input.number-with-controls.scss"

export interface NumberWithControlsProps extends WidgetProps {
    min: number;
    max: number;
}

export const UiInputNumberWithControls: React.FC<NumberWithControlsProps> = ({value, onChange, min, max, ...props}) => {

    const increase = () => {
        let newValue = value as number + 1;
        if (newValue > max) {
            newValue = max;
        }
        onChange!(newValue);
    }

    const decrease = () => {
        let newValue = value as number - 1;
        if (newValue < min) {
            newValue = min;
        }
        onChange!(newValue);
    }

    return (
        <Row gutter={[10, 10]}>
            <Col span={8}>
                <div
                    className={"ui-input-func-btn"}
                    onClick={decrease}
                >
                    -
                </div>
            </Col>
            <Col span={8}>
                <Input
                    className={"ui-input-field"}
                    type={"number"}
                    pattern="[0-9]*"
                    inputMode="numeric"
                    min={min}
                    max={max}
                    value={value as number}
                    onChange={onChange as any}
                />
            </Col>
            <Col span={8}>
                <div
                    className={"ui-input-func-btn"}
                    onClick={increase}
                >
                    +
                </div>
            </Col>
        </Row>
    )
}