import React, {useState} from "react";
import {Button, Collapse} from "antd";
import {DeclarationUpload, UploadedDeclaration, UploadedDeclarationResponse} from "../../declaration.upload.model";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DeclarationCart} from "./declaration.cart";
import {declarationService} from "../../../../../services/declarations/declarations.services";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {SaveModal} from "../../modal/save.modal";

interface DeclarationCartWrapperProps {
    declaration: UploadedDeclaration,
    params: DeclarationUpload,
    result: UploadedDeclarationResponse,
    namePrefix: string
}

export const DeclarationCartWrapper: React.FC<DeclarationCartWrapperProps> = ({declaration, params, result, namePrefix, ...props}) => {

    const t = useTranslate();

    const [savedModalShown, setSavedModalShown] = useState<boolean>(false);

    const [savedDeclaration, setSavedDeclaration] = useState<UploadedDeclaration | null>(null);

    const onSave = (number: string, transportToBorder: number, transportFromBorder: number) => {
        const config: any = {...params, declarant: parseInt(params.declarant)};
        delete config.file;

        const dto = {
            file: result.file,
            declaration: declaration.key,
            transportToBorder: transportToBorder,
            transportFromBorder: transportFromBorder,
            number: number,
            config: config
        }

        declarationService.saveDeclaration(dto).then(r => setSavedDeclaration(r))
    }

    const openSaveModal = (e: React.MouseEvent<any>) => {
        e.stopPropagation()
        setSavedModalShown(true);
    }

    return (
        <>
            <Collapse.Panel
                {...props}
                key={declaration.key}
                header={declaration.key}
                style={{width: '100%'}}
                extra={
                    <>
                        {savedDeclaration ? (
                            <Link to={"/declaration/" + savedDeclaration?.number} target={"_blank"}>
                                <Button
                                    size={"small"}
                                    icon={<FontAwesomeIcon icon={["fas", "save"]} />}
                                    onClick={e => e.stopPropagation()}
                                    type={"primary"}
                                >
                                    {t("SHOW")}
                                </Button>
                            </Link>
                        ) : (
                            <Button
                                size={"small"}
                                icon={<FontAwesomeIcon icon={["fas", "save"]} />}
                                onClick={e => openSaveModal(e)}
                            >
                                {t("SAVE")}
                            </Button>
                        )}
                    </>
                }
            >
                <DeclarationCart declaration={declaration} />
            </Collapse.Panel>
            {savedModalShown ? (
                <SaveModal
                    onClose={() => setSavedModalShown(false)}
                    onComplete={(number: string, transportToBorder: number, transportFromBorder: number) => onSave(number, transportToBorder, transportFromBorder)}
                    namePrefix={namePrefix}
                />
            ) : null}
        </>
    )
}