import React, {useEffect, useState} from "react";
import {Button, Form, Space, Table} from "antd";
import {ShipContext} from "../ship-modal";
import {shipmentsService} from "../../../../../services/shipments/shipments.service";
import {Package} from "../../../../../models/parcels/shipment";
import {PreShipmentParcelInfo} from "../../../../../models/parcels/pre-shipments";
import {columnsFactory} from "./set-parcel-dimensions.columns";
import {ColumnsType} from "antd/es/table";

export const SetParcelDimensionsStep: React.FC = () => {

    const c = React.useContext(ShipContext);

    const [packages, setPackages] = useState<Package[]>([]);

    const [columns, setColumns] = useState<ColumnsType<PreShipmentParcelInfo>>([]);

    const updateColumns = () => {
        setColumns(columnsFactory(c.parcels, packages, form, () => updateColumns()))
    }

    const addParcel = () => {
        const newParcel: PreShipmentParcelInfo = {
            number: '', type: 'box'
        }
        c.setParcels([...c.parcels, newParcel])
    }

    useEffect(() => {
        shipmentsService
            .getPackages()
            .then((ps) => {
                ps = [...ps];
                ps.push({id: 0, name: "Merged", width: 0, height: 0, length: 0})
                setPackages(ps)
            })
    }, []);

    useEffect(() => {
        updateColumns();
    },[packages])

    const [form] = Form.useForm();

    return <Space direction="vertical" size={[15, 15]}>
            <Space direction="vertical" size={[15, 15]}>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{parcels: Object.assign({}, c.parcels)}}
                    onFinish={values => {
                        form.validateFields().then(() => {
                            c.parcels.map((value, index) => {
                                const insertions = values.parcels[index.toString()];
                                const fields: any = {
                                    ...value,
                                    package: parseInt(insertions.package)
                                };

                                if (fields.package > 0) {
                                    fields.width    = parseFloat(insertions.width);
                                    fields.height   = parseFloat(insertions.height);
                                    fields.length   = parseFloat(insertions.length);
                                    fields.weight   = parseFloat(insertions.weight);
                                    delete fields.mergedTo;
                                } else {
                                    fields.mergedTo = insertions.mergedTo;
                                    delete fields.width;
                                    delete fields.height;
                                    delete fields.length;
                                    delete fields.weight;
                                }

                                c.parcels[index] = fields
                            })
                            c.setStep(2)
                        })
                    }}
                >
                    <Space direction={"vertical"} size={[10, 10]}>
                        <Table
                            columns={columns}
                            dataSource={c.parcels}
                            pagination={false}
                        />
                        <Button
                            style={{width: '100%'}}
                            onClick={() => addParcel()}
                            type={"primary"}
                        >Add a new empty package to shipment</Button>
                    </Space>
                </Form>
            </Space>
            <Space direction="horizontal" size={[15, 15]}>
                <Button type={"primary"} onClick={() => form.submit()}>Next</Button>
                <Button type={"default"} onClick={() => c.setStep(0)}>Go back</Button>
            </Space>
    </Space>
}