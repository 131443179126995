import React from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../hooks/translate.hook";
import FilteredTable from "../../common/filtered-table/filtered.table";
import { branchesService } from "../../../services/branches/branches.service";
import { Typography } from "antd";
import { ComponentPropsFromRoute } from "../../../routers/routers";
import { FilterKey } from "../../../models/filtered-table/filtered.table.props";
import { Branch } from "../../../models/branches/branch";
import { Link } from "react-router-dom";
import {ColumnsType} from "antd/es/table";

interface BranchesProps extends ComponentPropsFromRoute {}
export const Branches: React.FC<BranchesProps> = observer(({ name }) => {
    const t = useTranslate();

    const columns: ColumnsType<Branch> = [
        {
            title: t("NAME"),
            dataIndex: "name",
            key: "name",
            render: (name: string, row) => <Link to={"/settings/branches/" + row.id}>{name}</Link>,
        },
        {
            title: t("CODE"),
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("1C.CODE"),
            dataIndex: "icCode",
            key: "icCode",
        },
        {
            title: t("COUNTRY_CODE"),
            dataIndex: "countryCode",
            key: "countryCode",
        },
    ];

    const columnsConfigs = [
        {
            title: "ID",
            key: "id",
        },
        {
            title: "NAME",
            key: "name",
        },
    ];

    const filters: FilterKey[] = [
        {
            key: "id",
            label: "ID",
            type: "string",
        },
        {
            key: "name",
            label: t("NAME"),
            type: "string",
        },
    ];

    return (
        <>
            <Typography.Title level={3}>
                {t("BRANCHES.LIST_TITLE")}
            </Typography.Title>
            <FilteredTable
                filterKeys={filters}
                columns={columns}
                columnsConfigs={{ columns: columnsConfigs, pageKey: name }}
                loadDataCallback={(request) =>
                    branchesService.getBranches(request)
                }
            />
        </>
    );
});
