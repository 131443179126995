import React from "react";
import {Col, Form, Input, Modal, Row, Select, Typography} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useForm} from "antd/es/form/Form";

interface SaveModalProps {
    onClose: () => void;
    onComplete: (number: string, transportToBorder: number, transportFromBorder: number) => void;
    namePrefix: string;
}

export const SaveModal: React.FC<SaveModalProps> = ({onClose, onComplete, namePrefix}) => {

    const t = useTranslate();

    const [form] = useForm();

    const transports = [
        { id: 1, name: 'Море'},
        { id: 3, name: 'Машина'},
    ]

    return (
        <Modal
            width={"400px"}
            visible={true}
            title={t("DECLARATION.TITLE")}
            onOk={() => form.submit()}
            onCancel={() => onClose()}
            okText={t("SAVE")}
        >
            <Form
                form={form}
                initialValues={{number: namePrefix, transportToBorder: 1, transportFromBorder: 1}}
                layout="vertical"
                onFinish={() =>
                    form.validateFields().then((values) => {
                        onComplete(values.number, values.transportToBorder, values.transportFromBorder);
                        onClose();
                    })
                }
            >
                <Row gutter={[20, 20]}>
                    <Col span={12}>
                        <Form.Item
                            name="number"
                            label={t("DECLARATIONS.NUMBER")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="transportToBorder"
                            label={t("DECLARATIONS.TRANSPORTS.TO_BORDER")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Select>
                                {transports.map(transport => (
                                    <Select.Option value={transport.id}>{transport.id} - {transport.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="transportFromBorder"
                            label={t("DECLARATIONS.TRANSPORTS.TO_WAREHOUSE")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Select>
                                {transports.map(transport => (
                                    <Select.Option value={transport.id}>{transport.id} - {transport.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}