import React, {useState} from "react";
import {Form, Input, Modal, Upload} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useForm} from "antd/es/form/Form";
import {filesService} from "../../../../services/documents/common/files.service";
import TextArea from "antd/es/input/TextArea";

interface DocumentFilesUploadModalProps {
    id: number;
    onClose: () => void;
    onComplete: () => void;
}

export const DocumentFilesUploadModal: React.FC<DocumentFilesUploadModalProps> = ({id, onClose, onComplete}) => {

    const t = useTranslate();

    const [form] = useForm();

    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Modal
            width={"600px"}
            visible={true}
            title={t("DOCUMENT.FILES.TITLE")}
            onOk={() => form.submit()}
            onCancel={() => onClose()}
            okText={t("CREATE")}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        filesService.uploadFile(id, values).then(() => {
                            onClose();
                            onComplete();
                        }).finally(() => {
                            setLoading(false);
                        })
                    })
                }
            >
                <Form.Item
                    name="comment"
                    label={t("DESCRIPTION")}
                    rules={[
                        {
                            required: true,
                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                        },
                    ]}
                >
                    <TextArea
                        disabled={loading}
                        placeholder={t("DESCRIPTION")}
                        style={{height: "80px"}}
                    />
                </Form.Item>
                <Form.Item
                    label="File"
                    name="file"
                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                    valuePropName={""}
                    getValueFromEvent={(e) => e.file || null}
                >
                    <Upload.Dragger
                        beforeUpload={() => false}
                        accept={".pdf,.xls,.xlsx,.txt,.jpg,.png"}
                        maxCount={1}
                        disabled={loading}
                    >
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                    </Upload.Dragger>
                </Form.Item>
            </Form>
        </Modal>
    );
}