import {Translate} from "../../../../hooks/translate.hook";
import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {ShippingCourier, ShippingType} from "../../../../models/shipping/shipping";
import {YesNo} from "../../../common/yes-no/yes-no";
import {Link} from "react-router-dom";
import {Warehouse} from "../../../../models/warehouses/warehouse";
import {ShippingCouriersGroup} from "./branch.data.couriers";

export const groupedColumnsFactory = (t: Translate): ColumnsType<ShippingCouriersGroup> => {
    return [
        {
            title: "Warehouse",
            dataIndex: "warehouses",
            key: "warehouses",
            render: (warehouses: Warehouse[]) => warehouses.map(w => w.name).join(', ') || "All"
        }
    ];
}

export const columnsFactory = (branchId: string, t: Translate): ColumnsType<ShippingCourier> => {
    return [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (name: string, courier: ShippingCourier) => <Link to={"/settings/branches/" + branchId + "/shipping-couriers/" + courier.id}>{name}</Link>,
            width: 350
        },
        {
            title: "Estimate",
            dataIndex: "estimate",
            key: "estimate",
            width: 150
        },
        {
            title: "Shipping type",
            dataIndex: "shippingType",
            key: "shippingType",
            render: (type: ShippingType) => type.displayName,
            width: 150
        },
        {
            title: "Pack type",
            dataIndex: "packType",
            key: "packType",
            width: 150
        },
        {
            title: "Enabled",
            dataIndex: "viewStatus",
            key: "viewStatus",
            render: (value: boolean) => <YesNo value={value} />
        }
    ];
}