import React, {useState} from "react";
import {Button, Col, Form, Input, Modal, Row, Select, Space} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {WarehousesFormList} from "../../../../common/warehouses-form-list/warehouses.form.list";
import {useRequiredStore} from "../../../../../utils/store";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {observer} from "mobx-react";
import {WarehousePriorityGroup} from "../../../../../models/warehouses/warehouse";

export interface BranchDataWarehousesPriorityGroupsNewModalProps {
    group: WarehousePriorityGroup | null;
    onSubmit(sourceGroup: WarehousePriorityGroup | null, group: WarehousePriorityGroup): Promise<WarehousePriorityGroup>;
    onClose(): void;
}

export const BranchDataWarehousesPriorityGroupsNewModal: React.FC<BranchDataWarehousesPriorityGroupsNewModalProps> = observer(({group, onClose, onSubmit}) => {
    const t = useTranslate();

    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const rootStore = useRequiredStore(RootStoreContext);
    const langStore = rootStore.langStore;

    const defaultValues: WarehousePriorityGroup | {} = group ? {
        ...group,
        countries: group.countries.map(c => c.id),
        warehouses: group.warehouses.map(w => ({warehouse: w.warehouse.id, sort: w.priority}))
    } : {};

    return <Modal
        visible={true}
        confirmLoading={loading}
        title={
            group ? t("BRANCH.DATA.WAREHOUSES.PRIORITY_GROUPS.ADD") : t("BRANCH.DATA.WAREHOUSES.PRIORITY_GROUPS.EDIT")
        }
        okText={group ? t("SAVE") : t("ADD")}
        cancelText={t("CANCEL")}
        onOk={() => form.submit()}
        onCancel={() => onClose()}
    >
        <Form
            form={form}
            layout="vertical"
            name={"address_form"}
            initialValues={defaultValues || {}}
            onFinish={() =>
                form.validateFields().then((values) => {
                    setLoading(true);

                    values = {
                        name: values.name,
                        countries: values.countries,
                        warehouses: values.warehouses
                    }

                    onSubmit(group, values)
                        .catch(() => (e: any) => {
                            form.setFields(e.response?.data?.fields);
                            setLoading(false);
                        })
                })
            }
        >
            <Row gutter={10}>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label={t("NAME")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input
                            disabled={loading}
                            type="name"
                            placeholder={t("NAME")}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <WarehousesFormList form={form} disabled={loading} withMaxAmount={false} withTransactionType={false}/>
                </Col>
                <Col span={24}>
                    <Space direction={"vertical"} size={[10, 10]} style={{marginBottom: 15}}>
                        <Form.Item
                            name="countries"
                            label={t("COUNTRIES")}
                            style={{marginBottom: 0}}
                        >
                            <Select
                                mode={"multiple"}
                                allowClear
                                loading={loading}
                                placeholder={t("COUNTRIES")}
                                maxTagCount={3}
                            >
                                {langStore.countries.map(country => (
                                    <Select.Option key={country.id!} value={country.id!}>
                                        {country.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Space direction={"horizontal"} size={[10, 10]}>
                            <Button size={"middle"} onClick={() => form.setFieldsValue({countries: langStore.countries.map(c => c.id)})}>Select all</Button>
                        </Space>
                    </Space>
                </Col>
            </Row>
        </Form>
    </Modal>
})