import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {Event} from "../../models/calendar/calendar";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";

export class EventsService {
    private api: AxiosInstance = API;

    createEvent(data: any): Promise<PaginatedResult<Event>> {
        return this.api.post("calendar/events", data).then((x) => x.data);
    }

    getEvents(request: PaginatedRequest): Promise<PaginatedResult<Event>> {
        return this.api.get("calendar/events", {
            params: request.toParams(),
        }).then((x) => x.data);
    }

    getUpcomingEvents(): Promise<Event[]> {
        return this.api.get("calendar/events/upcoming").then((x) => x.data);
    }
}

export const eventsService = new EventsService();
