import React, {useState} from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {AgentDataStoreContext} from "../agent.data.store";
import {Button, Card, Col, Row, Space, Tooltip, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AgentDataAddressesModal} from "./agent.data.addresses.modal";
import {AgentDataAddressCard} from "./agent.data.address.card";
import {CustomerAccountAddress} from "../../../../models/accounts/customer-account/address/customer.account.address";


interface AgentDataAddressesProps {
    addresses: CustomerAccountAddress[];
    title: string;
    type: "shipping" | "billing"
    changeable?: boolean;
    allowAddOrRemove?: boolean;
}

export const AgentDataAddressWrapper: React.FC<AgentDataAddressesProps> = observer(({addresses, title, type, changeable, allowAddOrRemove = changeable}) => {
    const t = useTranslate();
    const store = useRequiredStore(AgentDataStoreContext);
    const [isModalShown, setIsModalShown] = useState<boolean>(false);

    return (
        <Space size={[0, 0]} direction={"vertical"}>
            <Row>
                <Typography.Title level={3}>{t(title)}</Typography.Title>
                {allowAddOrRemove ? (
                    <Tooltip placement="topLeft" title={t("ADD")}>
                        <Button
                            className="btn"
                            style={{margin: "3px 0 0 5px"}}
                            onClick={() => setIsModalShown(true)}
                            type="default"
                            icon={<FontAwesomeIcon icon={["fas", "plus"]}/>}
                        />
                    </Tooltip>
                ) : null}
            </Row>
            <Row gutter={[30, 30]}>
                {addresses.length ? addresses.map((address, index) => (
                    <Col key={address.id} className={"card-col"} xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                        <AgentDataAddressCard
                            last={index === 0 && addresses.length === 1}
                            removable={addresses.length < 2}
                            data={address}
                            changeable={changeable}
                            allowAddOrRemove={allowAddOrRemove}
                        />
                    </Col>
                )) : (
                    <Col className={"card-col"} xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Card className="card">{t("NO_DATA")}</Card>
                    </Col>
                )}
            </Row>
            {changeable ? (
                <AgentDataAddressesModal
                    visible={isModalShown}
                    type={type}
                    onClose={() => setIsModalShown(false)}
                    onSubmit={store.createAddress}
                />
            ) : null}
        </Space>
    );
});
