import React from "react";
import { observer } from "mobx-react";
import { useRequiredStore } from "../../../../../utils/store";
import { OrderDataStoreContext } from "../../order.data.store";
import {
    Alert,
    Button,
    Descriptions,
    Dropdown,
    Menu,
    notification,
    Popconfirm,
    Row,
    Space,
    Tooltip,
    Typography,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { OrderDataHeaderModal } from "./order-data-header-modal/order.data.header.modal";
import "./order.data.header.scss";
import { Export } from "../../../../common/export/export";
import Date from "../../../../date/date";
import { OrderWarnings } from "../../order-data-warnings/order.data.warnings";
import { OrderDataDocumentButtons } from "./order-data-header-document-buttons/order.data.document.buttons";
import { OrdersStatus } from "../../../status/order.status";
import {isAllowed} from "../../../../../utils/helpers";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {OrdersSplitModal} from "../../../split/split.modal";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";
import {useNavigate} from "react-router";

export const OrderDataHeader: React.FC = observer(() => {
    const t = useTranslate();
    const navigate = useNavigate();
    const store = useRequiredStore(OrderDataStoreContext);
    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);

    const openNotification = (type: "success" | "error", message: string): void => {
        notification[type]({
            message: t(type.toUpperCase()),
            description: t(message),
            duration: 2,
        });
    };

    return (
        <>
            <PageHeader
                style={{ padding: 0 }}
                ghost={false}
                title={
                    <Typography.Title level={1} style={{ margin: 0 }}>
                        {t("ORDERS.ORDER.TITLE") + " #" + store.order?.id}
                    </Typography.Title>
                }
                onBack={() => {
                    navigate("/orders");
                }}
                extra={<OrdersStatus large status={store.order!.status} />}
            >
                <Space direction="vertical" size={[20, 20]}>
                    {store.order?.hasComments ? (
                        <Alert
                            showIcon
                            type="warning"
                            message={t("WARNING")}
                            description={
                                <span>{t('ORDER.CONTAINS_COMMENTS')}</span>
                            }
                        />
                    ) : null}
                    {store.order?.warnings ? (
                        <Alert
                            showIcon
                            type="warning"
                            message={t("WARNING")}
                            description={
                                // <Space direction="vertical" size={[5, 5]}>
                                // </Space>
                                <span>{t("ORDER.WARNINGS.DESCRIPTION")}</span>
                            }
                            action={
                                <Button type="primary" danger onClick={store.showWarnings}>
                                    {t("VIEW")}
                                </Button>
                            }
                        />
                    ) : null}
                    {store.order?.locked ? (
                        <Alert
                            showIcon
                            message={t("ORDERS.EXCHANGE_LOCKED.TITLE")}
                            description={
                                <Space direction="vertical" size={[5, 5]}>
                                    <span>
                                        {t("ORDERS.EXCHANGE_LOCKED.REASON")}:{" "}
                                        {store.order?.lockReason
                                            ? t("ORDER_LOCK_REASON_" + store.order?.lockReason.toUpperCase())
                                            : null}
                                    </span>
                                    <span>
                                        {store.order?.lockedUntil ? (
                                            <>
                                                {t("ORDERS.EXCHANGE_LOCKED.UNTIL_DATE")}
                                                {": "}
                                                <Date dateString={store.order?.lockedUntil} showTime />
                                            </>
                                        ) : (
                                            t("ORDERS.EXCHANGE_LOCKED.UNTIL_NO_DATE")
                                        )}
                                    </span>
                                </Space>
                            }
                            type="error"
                        />
                    ) : null}
                    {!store.order?.syncedWithPrimarySystem ? (
                        <Alert message={t("ORDERS.ORDER.1C_SYNC_AWAITING")} type="warning" showIcon />
                    ) : null}
                    {store.order?.status === "not_completed" ? (
                        <Alert
                            showIcon
                            type="info"
                            description={
                                <Link to={"/clients/" + store.order.agent.number + "/cart/checkout"}>
                                    {t("CLIENT.CONTINUE_CHECKOUT")}
                                </Link>
                            }
                            style={{ marginBottom: 20 }}
                            message={t("ADVANCED_ORDER.CONTINUE")}
                        />
                    ) : null}
                    <Row justify={"space-between"}>
                        <Space className={"order-data-header-buttons"} direction={"horizontal"} size={[20, 20]} wrap>
                            <OrderDataDocumentButtons order={store.order} masterOrderId={null} includedOrdersIds={store.order?.orders || []}>
                                {isAllowed(attributes, "document_proforma_create") ? (
                                    <a
                                        style={{ textAlign: "left" }}
                                        target="_blank"
                                        className={"ant-btn ant-btn-primary"}
                                        href={"/api/v3/collect/order/" + store.order!.id + "/pdf"}
                                    >
                                        {t("ORDERS.COLLECT_LIST")}
                                    </a>
                                ) : null}
                            </OrderDataDocumentButtons>
                        </Space>
                        <Dropdown
                            trigger={["click"]}
                            onVisibleChange={store.handleActionMenuVisibleChange}
                            visible={store.actionMenuVisible}
                            overlay={
                                <Menu onClick={store.handleActionMenu}>
                                    <Menu.Item key={1}>
                                        <Button
                                            type="link"
                                            style={{ color: "inherit" }}
                                            disabled={store.resetting}
                                            loading={store.resetting}
                                            onClick={() => store.sync(openNotification)}
                                        >
                                            {t("ORDERS.ORDER.UPDATE_DOCS")}
                                        </Button>
                                    </Menu.Item>
                                    <Menu.Item key={3}>
                                        {!store.order!.locked ? (
                                            <Button
                                                type="link"
                                                style={{ color: "inherit" }}
                                                disabled={store.locking}
                                                loading={store.locking}
                                                onClick={() => store.lockExchange(openNotification)}
                                            >
                                                {t("ORDERS.ORDER.BLOCK_EXCHANGE")}
                                            </Button>
                                        ) : (
                                            <Button
                                                type="link"
                                                style={{ color: "inherit" }}
                                                disabled={store.locking}
                                                loading={store.locking}
                                                onClick={() => store.unlockExchange(openNotification)}
                                            >
                                                {t("ORDERS.ORDER.UNLOCK_EXCHANGE")}
                                            </Button>
                                        )}
                                    </Menu.Item>
                                    {store.order?.status === "not_confirmed" ||
                                    store.order?.status === "ordered" ||
                                    store.order?.status === "confirmed" ? (
                                        <Menu.Item key={4}>
                                            <Button
                                                type="link"
                                                style={{ color: "inherit" }}
                                                disabled={store.makingPostProcedure}
                                                loading={store.makingPostProcedure}
                                                onClick={() => store.makePostProcedure(openNotification)}
                                            >
                                                {t("ORDERS.ORDER.POST_PROCEDURE")}
                                            </Button>
                                        </Menu.Item>
                                    ) : null}
                                    {store.masterOrderId ? (
                                        <Menu.Item key={5}>
                                            <Button
                                                type="link"
                                                style={{ color: "inherit" }}
                                                disabled={!store.masterOrderId}
                                                onClick={() => store.handleModal("editWeight", true)}
                                            >
                                                {t("RECALC_WEIGHT.TITLE")}
                                            </Button>
                                        </Menu.Item>
                                    ) : null}
                                    <Menu.Item key={6}>
                                        <Popconfirm
                                            title={t("ARE_YOU_SURE")}
                                            onConfirm={() => store.duplicateToMissingItems(openNotification)}
                                            onCancel={() => store.setActionMenuVisible(false)}
                                            placement="left"
                                            okText={t("YES")}
                                            cancelText={t("NO")}
                                        >
                                            <Button type="link" style={{ color: "inherit", cursor: "pointer" }}>
                                                {t("ORDER.DUPLICATE_TO_MISSING_ITEMS.TITLE")}
                                            </Button>
                                        </Popconfirm>
                                    </Menu.Item>
                                    <Menu.Item key={7}>
                                        <Popconfirm
                                            title={t("ARE_YOU_SURE")}
                                            onConfirm={() => store.clearToMissingItems(openNotification)}
                                            onCancel={() => store.setActionMenuVisible(false)}
                                            placement="left"
                                            okText={t("YES")}
                                            cancelText={t("NO")}
                                        >
                                            <Button type="link" style={{ color: "inherit", cursor: "pointer" }}>
                                                {t("ORDER.CLEAR_MISSING_ITEMS.TITLE")}
                                            </Button>
                                        </Popconfirm>
                                    </Menu.Item>
                                    {store.order?.status === "not_confirmed" ||
                                    store.order?.status === "not_completed" ? (
                                        <Menu.Item key={8}>
                                            <Popconfirm
                                                title={t("ARE_YOU_SURE")}
                                                onConfirm={store.cancelOrder}
                                                onCancel={() => store.setActionMenuVisible(false)}
                                                placement="left"
                                                okText={t("YES")}
                                                cancelText={t("NO")}
                                            >
                                                <Button type="link" style={{ color: "inherit", cursor: "pointer" }}>
                                                    {t("ORDERS.CANCEL.TITLE")}
                                                </Button>
                                            </Popconfirm>
                                        </Menu.Item>
                                    ) : null}
                                    {store.order?.status === "confirmed" ? (
                                        <Menu.Item key={8}>
                                            <Button
                                                type="link"
                                                style={{ color: "inherit", cursor: "pointer" }}
                                                onClick={() => store.setSplitModalShown(true)}
                                            >
                                                {t("ORDERS.SPLIT.TITLE")}
                                            </Button>
                                        </Menu.Item>
                                    ) : null}
                                    <Menu.Item key={9}>
                                        <Button
                                            type="link"
                                            style={{ color: "inherit" }}
                                            onClick={() => store.setExportModalShown(true)}
                                        >
                                            {t("DOWNLOAD.CSV_EXCEL")}
                                        </Button>
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button type="primary">{t("ACTION")}</Button>
                        </Dropdown>
                    </Row>
                    <div className="data-descriptions-with-edit-wrapper">
                        <div className="data-content-wrapper">
                            <Descriptions
                                className="data-content-wrapper-descriptions"
                                size={"small"}
                                layout={"vertical"}
                                contentStyle={{ fontSize: "17px" }}
                                labelStyle={{ color: "#858585" }}
                                column={{
                                    xxl: 4,
                                    xl: 4,
                                    lg: 4,
                                    md: 3,
                                    sm: 2,
                                    xs: 1,
                                }}
                            >
                                <Descriptions.Item label={t("ORDER.MAGENTO_ORDER_ID")}>
                                    {store.order?.clientOrderId}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("AGENT_TITLE")}>
                                    {store.order?.agent.name} ({store.order?.agent.number})
                                </Descriptions.Item>
                                <Descriptions.Item label={t("SHIPPING_WAREHOUSE")}>
                                    {store.order?.warehouse.name}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ORDERS.SOURCE")}>
                                    {store.order?.sourceId}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ORDERS.PLATFORM")}>
                                    {store.order?.platform.name}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ORDERS.PROCEDURE")}>
                                    {store.order?.procedureType}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ORDERS.PAYMENT_AGGREGATOR")}>
                                    {store.order?.paymentAggregator}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ORDERS.TRANSACTION_ID")}>
                                    {store.order?.transactionID}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ORDERS.WMS_TASK_NUMBER")}>
                                    {store.order?.WMSTaskNumber}
                                </Descriptions.Item>
                            </Descriptions>
                            <Tooltip placement="topRight" title={t("EDIT")}>
                                <Button
                                    className="data-description-btn btn"
                                    onClick={() => store.handleModal("headerModalShown", true)}
                                    type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </Space>
            </PageHeader>
            {/*{store.masterOrderId ? (*/}
            {/*    <RecalcWeightModal*/}
            {/*        defaultWeight={store.order?.totalGrossWeight}*/}
            {/*        visible={store.editWeightModalShown}*/}
            {/*        onClose={() => store.handleModal("editWeight", false)}*/}
            {/*        onSubmit={store.recalcWeight}*/}
            {/*    />*/}
            {/*) : null}*/}
            <OrderDataHeaderModal
                visible={store.headerModalShown}
                onClose={() => store.handleModal("headerModalShown", false)}
            />
            <OrderWarnings
                visible={store.warningsModalShown}
                warnings={store.warnings}
                onCancel={store.closeWarningsModal}
            />
            {store.splitModalShown ? (
                <OrdersSplitModal
                    orderId={store.order!.id}
                    onClose={() => store.setSplitModalShown(false)}
                />
            ) : null}
            <Export
                title={"ORDERS.ORDER.DOWNLOAD_CSV_INVOICE"}
                isShown={store.exportModalShown}
                withBasicExportFields
                config={{
                    link: "/api/v3/orders/" + store.order!.id
                }}
                onClose={() => store.setExportModalShown(false)}
            />
        </>
    );
});
