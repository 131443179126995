import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {PlannerTask, PlannerTaskComment, PlannerTaskFile} from "../../models/planner/planner";
import {PaginatedResult} from "../../models/core/paginated.result";
import {Department} from "../../models/departments/department";
import {SystemUser} from "../../models/users/user/system.user";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PlanerTag} from "../../models/departments/planer_tags";

export class PlannerService {
    api: AxiosInstance = API;

    getTasks(request: PaginatedRequest): Promise<PaginatedResult<PlannerTask>> {
        return this.api.get("planner/tasks/my", {
            params: request.toParams(),
        }).then(x => x.data);
    }

    getTasksInDepartment(request: PaginatedRequest): Promise<PaginatedResult<PlannerTask>> {
        return this.api.get("planner/tasks", {
            params: request.toParams(),
        }).then(x => x.data);
    }

    createTask(values: object): Promise<PlannerTask> {
        return this.api.post("planner/tasks", values).then(x => x.data);
    }

    getTask(id: number): Promise<PlannerTask> {
        return this.api.get("planner/tasks/" + id).then(x => x.data);
    }

    getTaskComments(id: number): Promise<PaginatedResult<PlannerTaskComment>> {
        return this.api.get("planner/tasks/" + id + "/comments").then(x => x.data);
    }

    addTaskComment(id: number, comment: string): Promise<PaginatedResult<PlannerTaskComment>> {
        return this.api.post("planner/tasks/" + id + "/comments", {comment}).then(x => x.data);
    }

    getTaskIssueComments(id: number): Promise<PaginatedResult<PlannerTaskComment>> {
        return this.api.get("planner/tasks/" + id + "/issue-comments").then(x => x.data);
    }

    addTaskIssueComment(id: number, comment: string): Promise<PaginatedResult<PlannerTaskComment>> {
        return this.api.post("planner/tasks/" + id + "/issue-comments", {comment}).then(x => x.data);
    }

    createIssue(values: object): Promise<PlannerTask> {
        return this.api.post("planner/issues", values).then(x => x.data);
    }

    getIssues(request: PaginatedRequest): Promise<PaginatedResult<PlannerTask>> {
        return this.api.get("planner/issues", {
            params: request.toParams(),
        }).then(x => x.data);
    }

    getDepartments(): Promise<Department[]> {
        return this.api.get("planner/departments").then(x => x.data);
    }

    getMyDepartments(): Promise<Department[]> {
        return this.api.get("planner/departments/my").then(x => x.data);
    }

    getAssignees(): Promise<SystemUser[]> {
        return this.api.get("planner/assignees").then(x => x.data);
    }

    getDepartmentAvailableTags(): Promise<PlanerTag[]> {
        return this.api.get("planner/tasks/available-tags").then(x => x.data);
    }

    getAvailableTags(taskId: number): Promise<PlanerTag[]> {
        return this.api.get("planner/tasks/" + taskId + "/available-tags").then(x => x.data);
    }

    getAllPlannerTags(): Promise<PlanerTag[]> {
        return this.api.get<PlanerTag[]>("planner/tags").then((x) => x.data);
    }

    addPlannerTag(values: object): Promise<PlanerTag> {
        return this.api.post<PlanerTag>("planner/tags", values).then((x) => x.data);
    }

    editPlannerTag(id: number, values: object): Promise<PlanerTag> {
        return this.api.post<PlanerTag>("planner/tags/" + id, values).then((x) => x.data);
    }

    removePlannerTag(id: number): Promise<any> {
        return this.api.delete<any>("/planner/tags/" + id).then((x) => x.data);
    }

    addTag(taskId: number, values: object): Promise<PlanerTag> {
        return this.api.post("planner/tasks/" + taskId + "/tags", values).then(x => x.data);
    }

    removeTag(taskId: number, id: number): Promise<any> {
        return this.api.delete("planner/tasks/" + taskId + "/tags/" + id).then(x => x.data);
    }

    updateTask(id: number, values: object): Promise<PlannerTask> {
        return this.api.post("planner/tasks/" + id, values).then(x => x.data);
    }

    approvalPending(id: number, values: object): Promise<PlannerTask> {
        return this.api.post("planner/tasks/" + id+ "/approval-pending", values).then(x => x.data);
    }

    toProgress(id: number): Promise<PlannerTask> {
        return this.api.post("planner/tasks/" + id+ "/to-progress").then(x => x.data);
    }

    toReview(id: number): Promise<PlannerTask> {
        return this.api.post("planner/tasks/" + id+ "/to-review").then(x => x.data);
    }

    cancel(id: number): Promise<PlannerTask> {
        return this.api.post("planner/tasks/" + id+ "/cancel").then(x => x.data);
    }

    complete(id: number): Promise<PlannerTask> {
        return this.api.post("planner/tasks/" + id+ "/complete").then(x => x.data);
    }

    addAssignee(id: number, values: object): Promise<PlannerTask> {
        return this.api.post("planner/tasks/" + id+ "/add-assignee", values).then(x => x.data);
    }

    removeAssignee(id: number, values: object): Promise<PlannerTask> {
        return this.api.post("planner/tasks/" + id+ "/remove-assignee", values).then(x => x.data);
    }

    addDepartment(id: number, values: object): Promise<PlannerTask> {
        return this.api.post("planner/tasks/" + id+ "/add-department", values).then(x => x.data);
    }

    removeDepartment(id: number, values: object): Promise<PlannerTask> {
        return this.api.post("planner/tasks/" + id + "/remove-department", values).then(x => x.data);
    }

    attachFile(id: number, attach: boolean, file: File): Promise<[string, string]> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("attach", attach ? "1" : "0");

        return this.api
            .post("planner/tasks/" + id + "/upload", formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(result => {
                return [
                    result.data.filename,
                    result.data.filepath
                ];
            });
    }

    uploadFile(file: File): Promise<[string, string]> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("attach", "0");

        return this.api
            .post("planner/tasks/upload", formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(result => {
                return [
                    result.data.filename,
                    result.data.filepath
                ];
            });
    }

    removeFile(hash: string): Promise<any> {
        return this.api
            .delete("planner/tasks/download/" + hash)
            .then(x => x.data);
    }

    getAttachments(id: number): Promise<PlannerTaskFile[]> {
        return this.api.get("planner/tasks/" + id + "/attachments").then(x => x.data);
    }
}

export const plannerService = new PlannerService();
