import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Col, Form, FormInstance, Row, Select, Spin, Tooltip } from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import { useRequiredStore } from "../../../../utils/store";
import { RootStoreContext } from "../../../../stores/root/root.store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getGroupsBySystemId } from "../../../../models/users/user/user.data.helper";
import { System } from "../../../../models/systems/system/system";

interface SystemAccessesProps {
    form: FormInstance;
    type: string;
    defaultSelectedSystems: string[];
    loading: boolean;
}

export const SystemAccesses: React.FC<SystemAccessesProps> = observer(
    ({ form, type, defaultSelectedSystems, loading }) => {
        const t = useTranslate();
        const {
            commonStore: { systemGroups, systems },
        } = useRequiredStore(RootStoreContext);
        const [selectedSystems, setSelectedSystems] = useState<string[]>(defaultSelectedSystems);

        if (systems.length === 0) {
            return <></>
        }

        const filterSystemsByType = (systems: System[], type: string): System[] => {
            const systemsMap: { [k: string]: System[] } = {
                employee: systems.filter(
                    (item) => item.id === "fms_main" || item.id === "fms_api_exchange_v1" || item.id === "fms_api_v3"
                ),
                wholesale: systems.filter(
                    (item) =>
                        item.id === "fms_ws" ||
                        item.id === "fms_api" ||
                        item.id === "fms_api_v2" ||
                        item.id === "fms_api_xml"
                ),
                retail: systems.filter((item) => item.id === "fms_retail"),
            };
            return systemsMap[type] ? systemsMap[type] : [];
        };

        if (!systems.length && !systemGroups.length) {
            return <Spin />;
        }

        const isSelectOptionDisabled = (id: string) =>
            form
                .getFieldValue("systemAccesses")
                .map((item: { system: string; group: number }) => (item ? item.system : undefined))
                .includes(id);

        const isAddButtonDisabled = (): boolean => {
            const systemAccessesFields = form.getFieldValue(["systemAccesses"]);
            if (systemAccessesFields) {
                const systemAccessesFieldsLength = systemAccessesFields.length;
                const lastSelectedSystem: string = form.getFieldValue([
                    "systemAccesses",
                    systemAccessesFieldsLength - 1,
                ]);
                return !lastSelectedSystem && systemAccessesFieldsLength !== 0;
            }
            return false;
        };

        return (
            <>
                <Form.List
                    name={"systemAccesses"}
                    rules={[
                        {
                            validator: async (rule, names) => {
                                try {
                                    if (!names || names.length === 0) {
                                        return Promise.reject(t("FROM.ERROR.PLEASE_ADD_VALUE"));
                                    }
                                } catch (error) {
                                    throw new Error(error as string);
                                }
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Row justify="space-between" gutter={[10, 5]} key={field.key}>
                                    <Col span={11}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, "system"]}
                                            
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                optionFilterProp={"children"}
                                                disabled={loading}
                                                onChange={(value) => {
                                                    const currentField = form.getFieldValue(["systemAccesses", index]);

                                                    if (currentField) {
                                                        const systemAccessesFields: {
                                                            system: string;
                                                            group: number | undefined;
                                                        }[] = form.getFieldsValue().systemAccesses;
                                                        const fieldItemIndex = systemAccessesFields.findIndex(
                                                            (item) => item.system === value
                                                        );
                                                        const fieldItem: { system: string; group: number | undefined } =
                                                            systemAccessesFields[fieldItemIndex];

                                                        systemAccessesFields[fieldItemIndex] = {
                                                            ...fieldItem,
                                                            group: undefined,
                                                        };
                                                        form.setFieldsValue({
                                                            ...form.getFieldsValue(),
                                                            systemAccessesFields,
                                                        });
                                                    }

                                                    setSelectedSystems(
                                                        form
                                                            .getFieldValue(["systemAccesses"])
                                                            .map((item: { system: string; group: number }) =>
                                                                item ? item.system : null
                                                            )
                                                    );
                                                }}
                                                placeholder={t("SYSTEM")}
                                            >
                                                {filterSystemsByType(systems, type).map((system) => (
                                                    <Select.Option
                                                        disabled={isSelectOptionDisabled(system.id)}
                                                        key={system.id}
                                                        value={system.id}
                                                    >
                                                        {system.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item noStyle shouldUpdate={true}>
                                            {({ getFieldValue }) => (
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "group"]}
                                                    
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        optionFilterProp={"children"}
                                                        disabled={!getFieldValue(["systemAccesses", index]) || loading}
                                                        placeholder={t("GROUP.TITLE")}
                                                    >
                                                        {getGroupsBySystemId(
                                                            form.getFieldValue(["systemAccesses", index]),
                                                            systemGroups
                                                        ).map((group) => (
                                                            <Select.Option key={group.id} value={group.id}>
                                                                {group.name}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Tooltip placement="topRight" title={t("DELETE")}>
                                            <Button
                                                type="default"
                                                danger
                                                disabled={loading}
                                                onClick={() => {
                                                    remove(field.name);
                                                    const selectedSystemsWithoutRemoved = [...selectedSystems].splice(
                                                        index,
                                                        1
                                                    );
                                                    setSelectedSystems(selectedSystemsWithoutRemoved);
                                                }}
                                                icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                                                style={{ width: "100%" }}
                                                className="btn-flex-center"
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            ))}

                            <Form.Item noStyle shouldUpdate={true}>
                                {({ getFieldValue }) =>
                                    getFieldValue("systemAccesses").length ===
                                    filterSystemsByType(systems, type).length ? null : (
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                disabled={isAddButtonDisabled() || loading}
                                                onClick={() => add()}
                                                block
                                                icon={<FontAwesomeIcon icon={["fas", "plus-circle"]} />}
                                            >
                                                {t("ADD")}
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    )
                                }
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </>
        );
    }
);
