import { action, computed, makeObservable, observable } from "mobx";
import { countriesService } from "../../services/countries/countries.service";
import { i18n } from "i18next";
import { localesService } from "../../services/locales/locales.service";
import { Country } from "../../models/countries/country";

export class LangStore {
    private countriesInitialized: boolean = false;

    public i18n?: i18n;

    constructor() {
        makeObservable(this);
    }

    init() {
        this.reloadLocales();
    }

    @observable
    private _locales: string[] = [];

    @observable
    private _countries: Country[] = [];

    @computed
    public get locales(): string[] {
        return this._locales;
    }

    @action
    public setLocales(locales: string[]): void {
        this._locales = locales.slice().sort((a, b) => (a > b ? 1 : -1));
    }

    public async reloadLocales() {
        this.setLocales(await localesService.getLocales());
    }

    @computed
    public get countries(): Country[] {
        this.loadCountries();
        return this._countries;
    }

    @action
    public setCountries(countries: Country[]) {
        this._countries = countries;
    }

    public setI18n(i18n: i18n): void {
        this.i18n = i18n;
    }

    public async loadCountries() {
        if (this.countriesInitialized) {
            return;
        }
        this.countriesInitialized = true;
        this.setCountries(await countriesService.getAll());
    }
}
