import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Col, Form, Input, Modal, Radio, Row, Select} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {InvoiceDetailsItem} from "../../../../models/documents/invoices/invoice-data/invoice.data";
import {CreditInvoice} from "../../../../models/documents/invoices/credit-invoice/credit.invoice";
import {creditInvoicesService} from "../../../../services/documents/invoices/credit.invoices.service";
import {Warehouse} from "../../../../models/warehouses/warehouse";
import {warehousesService} from "../../../../services/warehouses/warehouses.service";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";

const DESCRIPTIONS = [
    "Delivery compensation",
    "Warranty case\nGoods are damaged and should be disposed",
    "Goods returned to the warehouse",
    "Goods are lost in transit",
    "Other"
]

interface CreditInvoiceCreateModalProps {
    warehouse: Warehouse;
    item: InvoiceDetailsItem,
    onClose: () => void,
    onCompleted: (document: CreditInvoice) => void
}
export const CreditInvoiceCreateModal: React.FC<CreditInvoiceCreateModalProps> = observer(({warehouse, item, onClose, onCompleted}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const [relatedCreditInvoices, setRelatedCreditInvoices] = useState<CreditInvoice[]>([]);

    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);

    useEffect(() => {
        warehousesService.getAllWarehouses().then(warehouses => setWarehouses(warehouses));
    }, [])

    useEffect(() => {
        creditInvoicesService.getRelatedForInvoiceItem(item.id).then(invoices => setRelatedCreditInvoices(invoices))
    }, [item])

    const complete = (document: CreditInvoice) => {
        onCompleted(document);
        onClose();
    };

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("DOCUMENTS.INVOICES.CREDIT.CREATE")}
            okText={t("CREATE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"dispatch_modal"}
                initialValues={{
                    qty: item.qty, type: "exiting"
                }}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        const dto = {
                            warehouse: values.warehouse,
                            qty: parseInt(values.qty),
                            item: item.id,
                            description: values.description === "Other" ? values.customDescription : values.description
                        };

                        setLoading(true);

                        if (values.type === 'exiting') {
                            creditInvoicesService.addItemToCreditInvoice(values.invoice, dto)
                                .then(complete)
                                .finally(() => setLoading(false));
                        } else {
                            creditInvoicesService.createWithSingleItem(dto)
                                .then(complete)
                                .finally(() => setLoading(false));
                        }
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="type"
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <Radio.Group>
                                <Radio.Button value={"exiting"}>Add to exiting credit invoice</Radio.Button>
                                <Radio.Button value={"new"}>Create new</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('type') === 'exiting' ? (
                                    <Form.Item
                                        name="invoice"
                                        label="Credit invoice"
                                        rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                                    >
                                        <Select>
                                            {relatedCreditInvoices.map(invoice => (
                                                <Select.Option value={invoice.id}>{dayjs(invoice.date).format("DD-MM-YYYY")} - {invoice.docNumber}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="qty"
                            label={t("QTY")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('type') === 'new' ? (
                            <Form.Item
                                name="warehouse"
                                label={t("WAREHOUSES.WAREHOUSE_TITLE")}
                                rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                            >
                                <Select>
                                    {warehouses.map(warehouse => (
                                        <Select.Option value={warehouse.id}>{warehouse.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('type') === 'new' ? (
                                <Form.Item
                                    name="description"
                                    label={t("DESCRIPTION")}
                                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                                >
                                    <Select>
                                        {DESCRIPTIONS.map(value => (
                                            <Select.Option value={value}>{value}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) => prevValues.description !== curValues.description || prevValues.type !== curValues.type}
                        >
                            {({getFieldValue}) => getFieldValue('type') === 'new' && getFieldValue('description') === 'Other' ? (
                                <Form.Item
                                    name="customDescription"
                                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                                >
                                    <TextArea />
                                </Form.Item>
                            ) : null}
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    )
})