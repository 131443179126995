import React from "react";
import {RouteTabs} from "../../common/route-tabs/route.tabs";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {useTranslate} from "../../../hooks/translate.hook";
import {useParams} from "react-router-dom";

export const ReportsReport: React.FC<ComponentPropsFromRoute> = ({routeChildren}) => {
    const t = useTranslate();
    const { name } = useParams<{ name: string }>();

    return (
        <>
            <h1>{t("REPORTS." + name!.toUpperCase() + ".TITLE")}</h1>
            <RouteTabs routes={routeChildren!} />
        </>
    )
};