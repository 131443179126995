import React, {useEffect, useState} from "react";
import {Col, Row, Typography} from "antd";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {ListCategoriesWebSiteSettings} from "./categories/list/list.categories";
import {ExtendedSwitch} from "../../core/router/extended-switch/extended.switch";
import {WebSiteCategory} from "../../../models/web-site/web-site";
import {webSiteService} from "../../../services/web-site/web-site.service";

export const WebSiteSettings: React.FC<ComponentPropsFromRoute> = ({routeChildren}) => {

    const [categoriesTree, setCategoriesTree] = useState<WebSiteCategory[]>([]);

    const update = async () => {
        setCategoriesTree(await webSiteService.getCategoriesTree());
    }

    const onTreeShouldBeUpdated = () => {
        update();
    }

    useEffect(() => {
        update();
    }, [])

    return (
        <>
            <Typography.Title level={3}>{"Web Site"}</Typography.Title>
            <Row gutter={[40, 0]} wrap={false}>
                <Col style={{borderRight: '1px solid rgb(0,0,0,0.06)'}} flex="340px">
                    <ListCategoriesWebSiteSettings
                        tree={categoriesTree}
                        onTreeShouldBeUpdated={onTreeShouldBeUpdated}
                    />
                </Col>
                <Col flex="auto">
                    <ExtendedSwitch
                        context={{
                            onTreeShouldBeUpdated: onTreeShouldBeUpdated,
                            tree: categoriesTree
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}