import React from "react";
import { LoggerData, LoggerFilters, LoggerUser } from "../../../models/logger/logger.data";
import {useTranslate} from "../../../hooks/translate.hook";
import { ColumnsType } from "antd/es/table";
import FilteredTable from "../filtered-table/filtered.table";
import Date from "../../date/date";
import { loggerService } from "../../../services/logger/logger.service";
import { isAllowed } from "../../../utils/helpers";
import { observer } from "mobx-react";
import { useRequiredStore } from "../../../utils/store";
import { RootStoreContext } from "../../../stores/root/root.store";

export const Logger: React.FC<LoggerFilters> = observer(({ relationId, relationType, additionalFilters }) => {
    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);
    const t = useTranslate();

    if (!isAllowed(attributes, "logger.list")) {
        return null;
    }

    const columns: ColumnsType<LoggerData> = [
        {
            title: t("CREATED_AT"),
            dataIndex: "date",
            key: "date",
            render: (item: string) => <Date dateString={item} showTime />,
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            render: (_: any, data: LoggerData) =>
                data.meta.length ? (
                    <>
                        {data.action} ({JSON.stringify(data.meta)})
                    </>
                ) : (
                    <>{data.action}</>
                ),
        },
        {
            title: t("RELATION_TYPE"),
            dataIndex: "relationType",
            key: "relationType",
        },
        {
            title: t("USER.TITLE"),
            dataIndex: "user",
            key: "user",
            render: (user: LoggerUser | null) => (user ? <>{user.firstName + " " + user.lastName}</> : t("SYSTEM")),
        },
    ];

    return (
        <FilteredTable
            // rowKey={"relationId"}
            listenToUrl={false}
            columns={columns}
            loadDataCallback={(request) =>
                loggerService.getData(request, { relationId, relationType, additionalFilters })
            }
        />
    );
});
