import React, {useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Col, Form, Input, Modal, Row, Select} from "antd";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../../models/core/response";
import {useRequiredStore} from "../../../../../utils/store";
import {UserGroupsStoreContext} from "../user.groups.store";
import {AccessGroup, AccessGroupRequest} from "../../../../../models/users/access-group/access.group";
import {observer} from "mobx-react";
import {getCheckedAccesses} from "../../../../../models/users/access-group/access.group.helper";
import {SystemGroup} from "../../../../../models/users/group/group";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {useNavigate} from "react-router";

interface UsersGroupsModalProps {
    item?: {name: string, system: string}
    visible: boolean,
    onClose: () => void,
    onSubmit: (values: AccessGroupRequest, systemGroups: SystemGroup[], item?: AccessGroup) => Promise<any | void>
}

export const UsersGroupsModal: React.FC<UsersGroupsModalProps> = observer(({visible, onClose, onSubmit, item}) => {
    const t = useTranslate();
    const navigate = useNavigate();
    const {commonStore: {systemGroups, systems}} = useRequiredStore(RootStoreContext);
    const store = useRequiredStore(UserGroupsStoreContext);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={item ? t("USERS.GROUPS.EDIT") : t("USERS.GROUPS.ADD")}
            okText={item ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"users_groups_form"}
                initialValues={item ? item : undefined}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        onSubmit(item ? {...values, accesses: getCheckedAccesses(store.accessesData)} : values, systemGroups)
                            .then(
                                (data) => {
                                    !item && form.resetFields();
                                    if (!item) {
                                        navigate("/users/groups/" + data.id);
                                    }
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                            .then(() => {}, (e) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="system"
                            label={t("SYSTEM")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Select disabled={loading} placeholder={t("SYSTEM")}>
                                {systems.map(system => (
                                    <Select.Option key={system.id} value={system.id}>{system.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label={t("NAME")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input type="text" disabled={loading} placeholder={t("NAME")}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
})
