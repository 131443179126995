import React from "react";
import { Alert, Button, Descriptions, Dropdown, Menu, notification, Space, Tag, Typography } from "antd";
import { useRequiredStore } from "../../../../utils/store";
import { AgentDataStoreContext } from "../agent.data.store";
import { Link, useLocation } from "react-router-dom";
import { ProgressBar } from "../../../common/progress-bar/progress-bar";
import { displayPrice } from "../../../../models/prices/price.helper";
import {useTranslate} from "../../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";

export const AgentDataHeader: React.FC = () => {
    const t = useTranslate();
    const store = useRequiredStore(AgentDataStoreContext);
    const { pathname } = useLocation();

    const tagColor: { [K: string]: string } = {
        active: "green",
        inactive: "red",
        potential: "blue",
        suspended_shipment: "orange",
    };

    const openNotification = (type: "success" | "error", message: string): void => {
        notification[type]({
            message: t(type.toUpperCase()),
            description: message,
        });
    };

    return (
        <>
            <Tag style={{ marginBottom: 10 }} color={tagColor[store.account?.status || 'inactive']}>
                {t("CLIENT." + store.account!.status.toUpperCase())}
            </Tag>
            <PageHeader
                style={{ padding: 0 }}
                ghost={false}
                title={
                    <Space align={"start"} direction="vertical" style={{ width: "auto" }}>
                        <div>
                            <Typography.Title style={{ margin: 0, maxWidth: 750 }} level={1}>
                                {store.account?.agent.name}
                            </Typography.Title>
                            <span style={{ fontSize: 12, color: "#8C8C8C" }}>{store.account?.agent.businessName}</span>
                        </div>
                    </Space>
                }
                extra={
                    store.account?.status !== 'potential' && store.account?.status !== 'cancelled' ? (
                        <Dropdown
                            trigger={["click"]}
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key={1}
                                        disabled={store.account?.status !== "active" || store.copyingToSandbox}
                                        onClick={() => store.copyToSandbox(openNotification)}
                                    >
                                        {t("CLIENT.COPY_TO_SANDBOX.TITLE")}
                                    </Menu.Item>
                                    {store.account?.status === 'active' ? (
                                        <Menu.Item
                                            key={2}
                                            onClick={() => store.suspend()}
                                        >
                                            {t("Suspend")}
                                        </Menu.Item>
                                    ) : null}
                                    {store.account?.status === 'active' ? (
                                        <Menu.Item
                                            key={2}
                                            onClick={() => store.deactivate()}
                                        >
                                            {t("Deactivate")}
                                        </Menu.Item>
                                    ) : null}
                                    {store.account?.status === 'inactive' ? (
                                        <Menu.Item
                                            key={2}
                                            onClick={() => store.reactivate()}
                                        >
                                            {t("Re-activate")}
                                        </Menu.Item>
                                    ) : null}
                                    {store.account?.status === 'suspended_shipment' ? (
                                        <Menu.Item
                                            key={2}
                                            onClick={() => store.unsuspend()}
                                        >
                                            {t("Unsuspend")}
                                        </Menu.Item>
                                    ) : null}
                                </Menu>
                            }
                        >
                            <Button style={{ marginTop: 5 }} type={"primary"}>
                                {t("ACTION")}
                            </Button>
                        </Dropdown>
                    ) : null
                }
            >
                {pathname === "/clients/" + store.account?.agent.number + "/cart/checkout" ? null : store.accountMeta!
                      .notCompletedOrders !== 0 ? (
                    <Alert
                        showIcon
                        type="info"
                        description={
                            <Link to={"/clients/" + store.account?.agent.number + "/cart/checkout"}>
                                {t("CLIENT.CONTINUE_CHECKOUT")}
                            </Link>
                        }
                        style={{ marginBottom: 20 }}
                        message={t("CLIENT.HAS_ADVANCED_ORDERS", {
                            amount: store.accountMeta!.notCompletedOrders,
                        })}
                    />
                ) : null}
                {store.account?.status !== "potential" && store.account?.status !== "cancelled" && store.progressBarData ? (
                    <Space direction="vertical" size={[0, 0]} style={{ marginTop: 10 }}>
                        <Typography.Title style={{ margin: 0 }} level={5}>
                            {t("CLIENT.TOTAL_SOLD")}: {displayPrice(store.accountMeta!.paidInvoices)} (
                            {new Date().getFullYear()})
                        </Typography.Title>
                        <ProgressBar
                            coefficientToMaxValue={1.2}
                            points={store.progressBarData.points}
                            bars={store.progressBarData.bars}
                        />
                    </Space>
                ) : null}
                {!store.account ? (
                    <Descriptions
                        size={"small"}
                        column={1}
                        contentStyle={{ fontSize: "17px" }}
                        style={{ marginBottom: 10 }}
                    >
                        <Descriptions.Item label={""}>
                            <Alert message={t("REGISTER_ACCOUNT_NEEDED") + ""} type="warning" />
                        </Descriptions.Item>
                    </Descriptions>
                ) : null}
            </PageHeader>
        </>
    );
};
