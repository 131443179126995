import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Card, Col, Row, Space, Tooltip, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { AgentDataIntegrationsModal } from "./agent.data.integrations.modal";
import { useRequiredStore } from "../../../../../utils/store";
import { AgentDataStoreContext } from "../../agent.data.store";
import { AgentDataIntegrationsCard } from "./agent.data.integrations.card";

export const AgentDataIntegrations: React.FC = observer(() => {
    const store = useRequiredStore(AgentDataStoreContext);
    const t = useTranslate();

    const [isModalShown, setIsModalShown] = useState<boolean>(false);

    return (
        <Space size={[0, 0]} direction={"vertical"}>
            <Row>
                <Typography.Title level={3}>{t("CLIENT.INTEGRATIONS.TITLE")}</Typography.Title>
                <Tooltip placement="topLeft" title={t("ADD")}>
                    <Button
                        className="btn"
                        style={{ margin: "3px 0 0 5px" }}
                        onClick={() => setIsModalShown(true)}
                        type="default"
                        icon={<FontAwesomeIcon icon={["fas", "plus"]} />}
                    />
                </Tooltip>
            </Row>
            <Row gutter={[30, 30]}>
                {store.account?.integrations.length ? (
                    store.account?.integrations.map((integration) => (
                        <Col className={"card-col"} key={integration.id} xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                            <AgentDataIntegrationsCard integration={integration as any} />
                        </Col>
                    ))
                ) : (
                    <Col className={"card-col"} xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Card className={"card"}>{t("NO_DATA")}</Card>
                    </Col>
                )}
            </Row>
            <AgentDataIntegrationsModal
                defaultFileLangueage={store.account?.language}
                visible={isModalShown}
                item={undefined}
                warehouses={store.account!.warehouses}
                accountId={store.account!.id}
                onSubmit={store.createIntegration}
                onClose={() => setIsModalShown(false)}
                testConnection={store.testIntegrationConnection}
            />
        </Space>
    );
});
