import React, { Key, useEffect, useState } from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../hooks/translate.hook";
import { useRequiredStore } from "../../../utils/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ShipmentsStatus } from "../shipments-status/shipments.status";
import Date from "../../date/date";
import FilteredTable from "../../common/filtered-table/filtered.table";
import { ShipmentsModal } from "../shipments-modal/shipments.modal";
import { ShipmentsEditModal } from "../shipments-edit-modal/shipments.edit.modal";
import { Button, Dropdown, Menu, Row, Space, Tooltip, Typography } from "antd";
import { RootStoreContext } from "../../../stores/root/root.store";
import { ColumnsType } from "antd/es/table";
import { ShipmentOrder, TableShipment } from "../../../models/parcels/shipment";
import { Warehouse } from "../../../models/warehouses/warehouse";
import { ShipmentsDropshipStore, ShipmentsStoreContext } from "./shipments-dropship.store";
import "./shipments-dropship.table.scss";
// @ts-ignore
import onScan from "onscan.js";
import { scanner } from "../../../utils/scanner";
import { FilterKey } from "../../../models/filtered-table/filtered.table.props";
import { Link } from "react-router-dom";

export const ShipmentsDropship: React.FC = observer(() => {
    const {
        commonStore: { warehouses },
        authStore: { token },
    } = useRequiredStore(RootStoreContext);
    const [store] = useState(() => new ShipmentsDropshipStore(token));
    const t = useTranslate();

    const columns: ColumnsType<TableShipment> = [
        {
            title: t("WAREHOUSES.WAREHOUSE_TITLE"),
            dataIndex: "warehouse",
            key: "warehouse",
            width: 160,
            render: (warehouse: Warehouse) => (warehouse ? warehouse.name : ""),
        },
        {
            title: t("COURIER.TITLE"),
            dataIndex: "carrier",
            key: "carrier",
            width: 110,
        },
        {
            title: t("ORDERS.TITLE"),
            dataIndex: "orders",
            key: "orders",
            render: (orders: ShipmentOrder[]) =>
                orders
                    ? orders.map((item, index) => (
                          <>
                              <Link to={"/orders/" + item.id}>{item.id}</Link>
                              {index !== orders.length - 1 ? ", " : ""}
                          </>
                      ))
                    : null,
        },

        {
            title: t("SHIPMENTS.PARCELS.TRACK_CODE"),
            dataIndex: "trackCode",
            key: "trackCode",
            width: 250,
        },
        {
            title: t("TYPE"),
            dataIndex: "type",
            key: "type",
            render: (type: string) => (type ? t("SHIPMENTS.PARCELS.TYPE." + type.toUpperCase()) : "-"),
        },
        {
            title: t("SHIPMENTS.PARCELS.NUMBER"),
            dataIndex: "number",
            key: "number",
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            render: (status: string) => (status ? <ShipmentsStatus status={status} /> : null),
        },
        {
            title: t("SHIPPED_AT"),
            dataIndex: "packedAt",
            key: "packedAt",
            render: (dateString: string | null) => {
                return dateString ? <Date dateString={dateString} showTime={false} /> : null;
            },
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            className: "table-button-cell",
            render: (_: any, item: TableShipment) =>
                !item.id.includes("CHILD") ? (
                    <Space direction="horizontal" size={[5, 5]}>
                        <Tooltip placement="topRight" title={t("EDIT")}>
                            <Button
                                className="table-btn btn"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    store.openEditModal(item);
                                }}
                                type="default"
                                icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                            />
                        </Tooltip>
                        <Tooltip placement="topRight" title={t("SHIPMENTS.SHOW_DELIVERY_NOTE")}>
                            <a
                                className="btn ant-btn btn-icon-link"
                                href={"/api/v3/shipments/" + item.id + "/delivery-note"}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={["fas", "file-pdf"]} />
                            </a>
                        </Tooltip>
                    </Space>
                ) : null,
        },
    ];
    const filterKeys: FilterKey[] = [
        {
            label: t("WAREHOUSES.TITLE"),
            key: "warehouses",
            type: "select",
            options: warehouses.map(({ id, name }) => ({ value: id, label: name })),
        },
        {
            label: t("COURIER.TITLE"),
            key: "carriers",
            type: "select",
            options: store.cariers.map(({ id, name }) => ({ value: id, label: name })),
        },
        {
            label: t("STATUS"),
            key: "statuses",
            type: "select",
            options: store.statuses.map((status) => ({
                value: status,
                label: t("SHIPMENTS.STATUS." + status.toUpperCase()),
            })),
        },
        {
            label: t("SHIPPED_AT"),
            key: "packedAt",
            type: "date",
        },
        {
            label: t("SHIPMENTS.PARCELS.NUMBER"),
            key: "parcelId",
            type: "int",
        },
        {
            label: t("SHIPMENTS.PARCELS.TRACK_CODE"),
            key: "trackCode",
            type: "string",
        },
        {
            label: t("SHIPMENTS.BOX_PALLET_NUMBER"),
            key: "number",
            type: "string",
        },
    ];

    useEffect(() => {
        scanner.attach((code) => store.setCode(code));
        return () => scanner.detach();
    }, []);

    return (
        <ShipmentsStoreContext.Provider value={store}>
            <FilteredTable
                getRequest={store.setRequest}
                className="shipments-table"
                columns={columns}
                filterKeys={filterKeys}
                dataHandler={store.dataHandler}
                filters={store.filters}
                filterButtons={store.filterButtonConfigs}
                buttons={[
                    <Dropdown
                        disabled={store.isPrintDisabled}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                <Menu.Item key={1}>
                                    <a target={"_blank"} href={store.labelsLink} onClick={store.printStickers}>
                                        {t("PRINT.TITLE")}
                                    </a>
                                </Menu.Item>
                                <Menu.Item key={2} onClick={store.createStickers}>
                                    {t("PRINT.CREATE")}
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button type="primary">{t("ACTION")}</Button>
                    </Dropdown>
                ]}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                            event.stopPropagation();
                            if (record.status !== "packed") {
                                // if (record.status !== "packed" && !event.target.classList.contains("table-button-cell")) {
                                store.openModal(record.parentId ? record.parentId : record.id);
                            }
                        },
                    };
                }}
                expandable={{
                    rowExpandable: (record: TableShipment) => !!record.children,
                }}
                rowSelection={{
                    type: "checkbox",
                    selectedRowKeys: store.selectedShipments.map((item) => item.id),
                    checkStrictly: false,
                    onChange: (selectedRowKeys: Key[], selectedRows) => {
                        store.selectShipments(selectedRows);
                    },
                    renderCell: (checked, record, index, originNode) => {
                        if (record.parentId) {
                            return <></>;
                        }
                        return originNode;
                    },
                }}
            />
            <ShipmentsModal visible={store.modalShown} />
            <ShipmentsEditModal
                visible={store.editModalShown}
                onSuccess={(request, item) => (item ? store.updateShipment(request, item) : Promise.reject())}
                onClose={store.closeEditModal}
            />
        </ShipmentsStoreContext.Provider>
    );
});
