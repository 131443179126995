import React, {ErrorInfo, PropsWithChildren} from "react";
import {Typography} from "antd";
import {withTranslation, WithTranslationProps} from "react-i18next";
import "./error.boundary.scss";

interface ErrorBoundaryState {
    hasError: boolean
}
class ErrorBoundary extends React.Component<PropsWithChildren<WithTranslationProps>, ErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    }

    render() {
        if (this.state.hasError) {
            // Можно отрендерить запасной UI произвольного вида
            return (
                <div className={"error-wrapper"}>
                    <Typography.Title className={"error-title"} level={1}>{this.props.i18n?.t("SOMETHING_WENT_WRONG") as string}</Typography.Title>
                    <a className={"error-link"} href="/">{this.props.i18n?.t("NOT_FOUND.RETURN") as string}</a>
                </div>
            );
        }

        return this.props.children;
    }

}

export default withTranslation()(ErrorBoundary);
