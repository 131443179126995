import React from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Typography} from "antd";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../utils/store";
import {OrderDataStoreContext} from "../order.data.store";
import {Logger} from "../../../common/logger/logger";

export const OrderDataHistory: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(OrderDataStoreContext);

    return (
        <>
            <Typography.Title level={3}>{t("ORDERS.ORDER.HISTORY.TITLE")}</Typography.Title>
            <Logger relationType="order" relationId={store.order!.id}/>
        </>
    );
})
