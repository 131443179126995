import React, {useState} from "react";
import {PlannerTask, PlannerTaskAssignee} from "../../../../../models/planner/planner";
import {Button, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {plannerService} from "../../../../../services/planner/planner.service";

interface TaskAssigneesProps {
    task: PlannerTask;
    assignee: PlannerTaskAssignee;
    reload: () => void;
}

export const TaskAssignee: React.FC<TaskAssigneesProps> = ({task, assignee, reload}) => {

    const {user} = assignee;

    const [disabled, setDisabled] = useState<boolean>(false);

    const remove = async () => {
        setDisabled(true);
        try {
            await plannerService.removeAssignee(task.id, {assignee: assignee.id});
            reload();
        } finally {
            setDisabled(false);
        }
    }

    return (
        <Space direction={"horizontal"}>
            <div>{user.firstName} {user.lastName}</div>
            <Button
                disabled={disabled}
                onClick={remove}
                icon={<FontAwesomeIcon icon={["fas", "user-times"]}/>}
                type={"text"}
                size={"small"}
            />
        </Space>
    )
}
