import React, {lazy, LazyExoticComponent} from "react";
import { observer } from "mobx-react";
import { useRequiredStore } from "../../../../utils/store";
import { AgentDataStoreContext } from "../agent.data.store";
import { ReportPaymentsByMonthItem, ReportPaymentsStats } from "../../../../models/reports/reports";
import { graphService } from "../../../../services/graph/graph.service";
import { ReportsMainPageStats } from "../../../reports/main-page/reports-main-page-stats/reports.main.page.stats";
import {useTranslate} from "../../../../hooks/translate.hook";
import { GraphDataWithMeta } from "../../../../models/graph/graph";
import {GraphType} from "../../../common/graph/graph";
import { Space, Typography } from "antd";
import { ChartConfigDataset } from "../../../../models/reports/reports.chart.configs";
import { FieldGroupConfig } from "../../../../models/filter/filter.group";
import { FieldTypeName } from "../../../../models/filter/filter.field.type.name";

const Chart: LazyExoticComponent<GraphType<ReportPaymentsByMonthItem, ReportPaymentsStats>> = lazy(() => import("../../../common/graph/graph"));

export const AgentDataStatistics: React.FC = observer(() => {
    const store = useRequiredStore(AgentDataStoreContext);
    const t = useTranslate();

    const getReportPaymentsGraphValues = (
        data: GraphDataWithMeta<ReportPaymentsByMonthItem, ReportPaymentsStats>
    ): ChartConfigDataset[] => {
        return [
            {
                data: data.items.map((item) => item.value),
                fill: "start",
                label: "",
            },
        ];
    };
    const getReportPaymentsGraphLabels = (
        data: GraphDataWithMeta<ReportPaymentsByMonthItem, ReportPaymentsStats>
    ): string[] => data.items.map((item) => item.month + " - " + item.year);

    const getCurrencyCode = (meta: ReportPaymentsStats) => meta.currencyCode;

    const filterGroups: FieldGroupConfig[] = [
        {
            name: "warehouses",
            visibleName: "WAREHOUSES.TITLE",
            fields: [
                {
                    name: "warehouses",
                    type: FieldTypeName.SELECT,
                    choices: store.account!.warehouses.map(({ warehouse: { name, id } }) => ({
                        name: name,
                        value: id,
                    })),
                    multiple: true,
                },
            ],
        },
    ];

    return (
        <Space direction={"vertical"} size={[0, 0]}>
            <Typography.Title level={3}>{t("CLIENT.STATISTICS.TITLE")}</Typography.Title>
            <Chart
                loadOnInit={false}
                filterGroups={filterGroups}
                //snapshots={[]}
                title="CLIENT.SALES_ACTIVITY"
                xAxeLabel="REPORTS.MONTHS"
                yAxeLabel="REPORTS.AMOUNT"
                loadDataCallback={(request) =>
                    graphService.getPaymentsByMonth<ReportPaymentsByMonthItem, ReportPaymentsStats>(request, {
                        account: store.account!.id!,
                    })
                }
                getGraphData={getReportPaymentsGraphValues}
                getGraphLabelsData={getReportPaymentsGraphLabels}
                getCurrencyCode={getCurrencyCode}
                meta={ReportsMainPageStats}
            />
        </Space>
    );
});
