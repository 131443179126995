import { FormInstance } from "antd";
import React, { RefObject, useContext, useEffect } from "react";
import { FieldChangeCallback } from "../../../../../../models/filter/filter.field.type.config";
import { FieldConfig, FieldConfigChoices, FieldGroupConfig } from "../../../../../../models/filter/filter.group";
import { FilterContext } from "../../filter";
import { SelectedFilter } from "./selected.filter";

interface SelectedFiltersProps {
    choices: Record<string, FieldConfigChoices>;
    setChoices: React.Dispatch<React.SetStateAction<Record<string, FieldConfigChoices>>>;
    popupRef: RefObject<HTMLDivElement>;
    config: FieldGroupConfig;
    form: FormInstance;
}

export const SelectedFilters: React.FC<SelectedFiltersProps> = ({ popupRef, config, form, choices, setChoices }) => {
    const context = useContext(FilterContext);

    useEffect(() => {
        let newChoices: Record<string, FieldConfigChoices> = {};

        Object.keys(context.choices).forEach((key) => {
            if (config.fields.map(({ name }) => name).includes(key)) {
                setChoices((prev) => {
                    newChoices = { ...prev, ...{ [key]: context.choices[key] } };
                    return newChoices;
                });
            }
        });
        config.fields.forEach((field) => {
            if (!newChoices[field.name] && (!field.requireFields || field.requireFields.length === 0)) {
                tryLoadChoices(field, newChoices);
            }
        });
    }, [context, config]);

    useEffect(() => {}, [choices]);

    const tryLoadChoices = (item: FieldConfig, newChoices: Record<string, FieldConfigChoices>) => {
        if (item.choicesLoader) {
            if (
                (!item.requireFields ||
                    item.requireFields.every((requiredField) => !!form.getFieldValue(["fields", requiredField]))) &&
                !newChoices[item.name]
            ) {
                item.choicesLoader(form.getFieldsValue().fields || {}).then((choices) => {
                    setChoices((prev) => ({ ...prev, ...{ [item.name]: choices } }));
                });
            }
        }
    };

    const onChange: FieldChangeCallback = (field, value): void => {
        config.fields.forEach((item) => {
            if (item.requireFields?.includes(field.name)) {
                form.resetFields([["fields", item.name]]);
                let newChoices: Record<string, FieldConfigChoices> = {};
                setChoices((prev) => {
                    newChoices = { ...prev };
                    delete newChoices[item.name];
                    return newChoices;
                });
                tryLoadChoices(item, newChoices);
            }
        });
    };

    return config ? (
        <>
            {config.fields.map((item, index) => (
                <SelectedFilter
                    key={item.name}
                    form={form}
                    choices={choices[item.name] || []}
                    field={item}
                    index={index}
                    popupRef={popupRef}
                    onChange={onChange}
                />
            ))}
        </>
    ) : null;
};
