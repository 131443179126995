import { Button, Col, Collapse, Input, Row, Space, Typography } from "antd";
import i18n from "i18next";
import { observer } from "mobx-react";
import React, { RefObject, useEffect, useRef, useState } from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { NotCompletedOrder } from "../../../../../models/orders/orders-cart/orders-checkout/orders.checkout.not_completed.order";
import { useRequiredStore } from "../../../../../utils/store";
import { OrdersArticleModal } from "../../article-modal/orders.article.modal";
import { OrdersCheckoutAddresses } from "../orders-checkout-addresses/orders.checkout.addresses";
import { OrdersCheckoutShippings } from "../orders-checkout-shippings/orders.checkout.shippings";
import { OrdersCheckoutStoreContext } from "../orders.checkout.store";
import { OrdersCheckoutTable } from "./orders-checkout-table/orders.checkout.table";
import "../orders.checkout.scss";
import "./orders.checkout.order.body.scss";
import { generateCartApplyTableData } from "../../../../../models/orders/orders-cart/orders-create/orders-cart-create-helper/orders.cart.create.helper";
import { OrdersCheckoutTableFooter } from "./orders.checkout.order.table.footer";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

interface OrdersAdvancedOrderBodyProps {
    order: NotCompletedOrder;
}

export const OrdersAdvancedOrderBody: React.FC<OrdersAdvancedOrderBodyProps> = observer(({ order }) => {
    const t = useTranslate();
    const store = useRequiredStore(OrdersCheckoutStoreContext);
    const [applyDisabled, setApplyDisabled] = useState<boolean>(order.items.every((item) => !item.valid));
    const rowRef = useRef<HTMLSpanElement>() as RefObject<HTMLSpanElement>;

    useEffect(() => {
        i18n.on("languageChanged", store.onLngChangeApply);
        return () => i18n.off("languageChanged", store.onLngChangeApply);
    }, []);

    return (
        <>
            <Collapse
                activeKey={store.activeTabs}
                key={order.id}
                expandIconPosition="right"
                bordered={false}
                onChange={() => store.toggleOrderCollapse(order)}
            >
                <Collapse.Panel
                    key={order.id}
                    className="cart-collapse-panel"
                    header={
                        <span
                            ref={rowRef}
                            style={{
                                fontWeight: "bold",
                                fontSize: 20,
                                scrollMargin: 80,
                            }}
                        >
                            {t("ORDERS.ORDER.TITLE") + " #" + order.id} ({order.warehouse.name}) {order.container ? (<Link to={"/containers/" + order.container.id}>{order.container.name}</Link>) : null}
                        </span>
                    }
                    extra={<>{dayjs(order.date).fromNow()}</>}
                >
                    <Row style={{ width: "100%" }} gutter={[0, 50]}>
                        <Col span={24}>
                            <OrdersCheckoutTable
                                order={order}
                                loading={store.loading}
                                useBackorder={order.useBackorder}
                                generateData={generateCartApplyTableData}
                                deleteItem={store.deleteOrderItem}
                                getStockArticleAmount={store.getStockArticleAmount}
                                updateOrderItem={store.updateOrderItem}
                                footerRender={() => (
                                    <OrdersCheckoutTableFooter
                                        backorderSummary={order.backorderSummary}
                                        stockSummary={order.stockSummary}
                                        openAddRowModal={store.openAddModal}
                                        vatPercent={order.vatPercent}
                                        orderDiscount={store.ordersDiscount[order.id]}
                                        updateDiscount={discount => store.updateDiscount(order, discount)}
                                    />
                                )}
                            />
                        </Col>
                        <Col span={24}>
                            <Typography.Title level={3}>{t("CART.ASSOCIATED_NUMBER.TITLE")}</Typography.Title>
                            <Input
                                value={store.relatedOrderNumber[order.id]}
                                onChange={(event) => store.setRelatedOrderNumberValue(order, event.target.value)}
                                type="text"
                                maxLength={50}
                                placeholder={t("CART.ASSOCIATED_NUMBER.DESCRIPTION")}
                            />
                        </Col>
                        <Col span={24}>
                            <OrdersCheckoutAddresses
                                title={"CART.DELIVERY.ADDRESSES"}
                                addresses={store.agentShippingAddresses[order.id]}
                                order={order}
                                type={"shipping"}
                                onCardClick={store.updateAgentShippingAddresses}
                            />
                        </Col>
                        {order.type !== 'backorder' ? (
                            <Col span={24}>
                                {store.agentShippingAddresses[order.id] ? <OrdersCheckoutShippings order={order} /> : null}
                            </Col>
                        ) : null}
                        <Col span={24}>
                            <Typography.Title level={3}>{t("ORDERS.COMMENT")}</Typography.Title>
                            <Input.TextArea
                                value={store.orderComment[order.id]}
                                onChange={(event) => store.setOrderCommentValue(order, event.target.value)}
                                rows={3}
                                maxLength={150}
                                allowClear
                            />
                        </Col>
                        <Col span={24}>
                            <Space
                                style={{
                                    display: "flex",
                                    width: "max-content",
                                    margin: "0 auto",
                                }}
                                direction="horizontal"
                                size={[10, 10]}
                                wrap
                            >
                                <Button
                                    disabled={applyDisabled}
                                    type="primary"
                                    onClick={async () => {
                                        store.createOrder(order);
                                        await rowRef.current?.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start",
                                        });
                                    }}
                                >
                                    {t("ORDERS.CREATE.FROM_WAREHOUSE") + " " + order.warehouse.name}
                                </Button>
                                <Button
                                    danger
                                    style={{ width: "100%" }}
                                    onClick={() => store.onCancelOrder(order)}
                                    disabled={store.ordersCancelling[order.id]}
                                >
                                    {t("ORDERS.CANCEL.TITLE")}
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                    {store.addModalShown ? (
                        <OrdersArticleModal
                            key={order.id}
                            orderId={order.id}
                            onClose={store.closeAddModal}
                            searchArticle={store.searchArticle}
                            onSubmit={store.addToCart}
                        />
                    ) : null}
                </Collapse.Panel>
            </Collapse>
        </>
    );
});
