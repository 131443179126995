import React, {useEffect, useState} from "react";
import QrScanner from "qr-scanner";
import {notifications} from "../../../services/notifications/notifications";
import {Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./qr-code-scanner.scss"

interface QrCodeScannerProps {
    onDecode: (value: string) => void;
}

export const QrCodeScanner: React.FC<QrCodeScannerProps> = ({onDecode}) => {

    const CAM_KEY = "qr_cam";

    const [ready, setReady] = useState<boolean>(false);
    const [scanner, setScanner] = useState<QrScanner | null>(null);
    const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(null);

    const [cams, setCams] = useState<QrScanner.Camera[]>([]);
    const [selectedCam, setSelectedCam] = useState<QrScanner.DeviceId>(localStorage.getItem(CAM_KEY) || "");

    useEffect(() => {
        QrScanner.listCameras(false).then(cams => setCams(cams))
    }, [])

    useEffect(() => {
        if (videoElement) {
            const scanner = new QrScanner(
                videoElement,
                (v) => onDecode(v.data), {
                    preferredCamera: selectedCam || undefined,
                    returnDetailedScanResult: true
                }
            );

            scanner
                .start()
                .then(() => setReady(true))
                .catch(e => notifications.message("error", e));

            setScanner(scanner)

            return () => {
                scanner.destroy();
            }
        }
    }, [videoElement])

    const toggleCamera = (selectedCam: QrScanner.DeviceId): QrScanner.DeviceId => {
        let idx = 0;
        const foundIdx = cams.findIndex(c => c.id === selectedCam);
        if (-1 !== foundIdx && cams[foundIdx+1]) {
            idx = foundIdx + 1;
        }

        let newCam = cams[idx];

        localStorage.setItem(CAM_KEY, newCam.id);

        setReady(false);
        scanner?.setCamera(newCam.id).then(() => setReady(true));

        return newCam.id;
    }

    return (
        <div className="qr-code-scanner">
            <video
                ref={e => setVideoElement(e)}
                width={"100%"}
            />
            {ready && cams.length > 1 ? (
                <Button
                    className={"qr-code-scanner-change-cam-button"}
                    onClick={() => setSelectedCam(toggleCamera)}
                    shape={"circle"}
                    icon={<FontAwesomeIcon icon={["fas", "camera"]} />}
                />
            ) : null}
        </div>
    )
}