import React, {useCallback, useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {plannerService} from "../../../../services/planner/planner.service";
import {Link, useParams} from "react-router-dom";
import {PlannerTask, PlannerTaskType} from "../../../../models/planner/planner";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {Button, Card, Col, Row, Space, Tabs, Typography} from "antd";
import {MarkdownViewer} from "../../../core/markdown/markdown-viewer/markdown-viewer";
import {TaskCommentsList, TaskCommentsListType} from "../comments/list/task.comments.list";
import {PlannerTasksViewActions} from "./actions/task.view.actions";
import {PlannerTasksViewDescriptions} from "./descriptions/task.view.descriptions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslate} from "../../../../hooks/translate.hook";
import {PlannerTasksViewAttachments} from "./attachments/task.view.attachments";

export const PlannerTasksView: React.FC<ComponentPropsFromRoute> = () => {

    const t = useTranslate();

    const { id } = useParams();

    const [task, setTask] = useState<PlannerTask>();

    const reload = useCallback(() => {
        plannerService.getTask(parseInt(id!)).then(task => setTask(task))
    }, [id])

    useEffect(() => {
        reload();
    }, [])

    if (!task) {
        return <LoadingSpin />
    }

    const tabs = [
        {key: '1', label: 'Comments', children: <TaskCommentsList task={task} type={TaskCommentsListType.COMMENTS} />},
    ]

    if (task.type === PlannerTaskType.ISSUE) {
        tabs.push(
            {key: '2', label: 'Comm. With a Reporter', children: <TaskCommentsList task={task} type={TaskCommentsListType.COMMENTS_WITH_A_REPORTER} />}
        )
    }

    return (
        <>
            <Typography.Title level={2}>{task.name}</Typography.Title>
            <Row gutter={[20, 20]}>
                <Col xxl={19} xl={19} lg={19} md={15} sm={24} xs={24}>
                    <Space size={[10, 10]} direction={"vertical"}>
                        <Card
                            title={task.author.firstName + ' ' + task.author.lastName}
                            extra={
                                <Link to={"/planner/tasks/" + task.id + "/edit"}>
                                    <Button
                                        type={"link"}
                                        icon={<FontAwesomeIcon
                                            icon={["fas", "edit"]}
                                        />} />
                                </Link>
                            }
                        >
                            <MarkdownViewer>
                                {task.description}
                            </MarkdownViewer>
                            <PlannerTasksViewAttachments task={task} reload={reload} />
                        </Card>
                        <Tabs items={tabs} />
                    </Space>
                </Col>
                <Col xxl={5} xl={5} lg={5} md={9} sm={24} xs={24}>
                    <Space direction={"vertical"} size={[20, 20]}>
                        <Card>
                            <PlannerTasksViewDescriptions task={task} reload={reload} />
                        </Card>
                        <Card>
                            <PlannerTasksViewActions task={task} reload={reload} />
                        </Card>
                    </Space>
                </Col>
            </Row>
        </>
    )
}