import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import {Translate} from "../hooks/translate.hook";

export const maxAmount = (max: number | null, t: Translate) => {
    return async (
        rule: RuleObject,
        value: StoreValue,
        callback: (error?: any) => any
    ) => {
        if (max !== null && max >= 0 && parseFloat(value) > max) {
            return Promise.reject(t("MESSAGE"));
        }
    };
};
