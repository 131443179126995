import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {PaginatedResult} from "../../models/core/paginated.result";
import {DeclarationsDocument} from "../../models/declarations/declarations.documents";
import {PaginatedRequest} from "../../models/core/paginated.request";

export class DeclarationsDocumentsService {
    api: AxiosInstance = API;

    getAll(request: PaginatedRequest): Promise<PaginatedResult<DeclarationsDocument>> {
        return this.api.get<PaginatedResult<DeclarationsDocument>>(
            'declarations/code-documents',
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }

    create(data: any): Promise<DeclarationsDocument> {
        return this.api.post<DeclarationsDocument>('declarations/code-documents', data).then(x => x.data);
    }

    update(id: number, data: any): Promise<DeclarationsDocument> {
        return this.api.post<DeclarationsDocument>('declarations/code-documents/' + id, data).then(x => x.data);
    }

    remove(id: number): Promise<any> {
        return this.api.delete<any>('declarations/code-documents/' + id).then(x => x.data);
    }
}

export const declarationsDocumentsService = new DeclarationsDocumentsService();
