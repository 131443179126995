import {FieldType} from "../../../common/smart-table/smart-table.field-config";
import {ReportConfigField} from "../../../../models/reports/reports";
import React from "react";
import {WidgetProps} from "../../../common/smart-table/filter/dropdown/widget/widget";
import {WarehouseWidget} from "../../../common/smart-table/filter/dropdown/widget/custom/warehouse.widget";
import {CountryWidget} from "../../../common/smart-table/filter/dropdown/widget/custom/country.widget";

const widgetsMap: Record<string, React.FC<WidgetProps>> = {
    warehouse: WarehouseWidget,
    country: CountryWidget
}

export const createColumns = (fields: ReportConfigField[]) => {
    return fields.map(field => ({
        name: field.name,
        visibleName: field.visibleName,
        sortable: field.sortable,
        filterable: field.filterable,
        type: field.type as FieldType,
        options: field.options,
        widget: widgetsMap[field.options?.widget]
    }))
}