import React from "react";
import { StatusTag } from "../../status/status";
import { DocumentStatus } from "../../../../models/documents/status";

const statuses: Record<DocumentStatus, string> = {
    reopened: "gold",
    opened: "gold",
    pre_closed: "gold",
    closed: "green",
    verified: "green",
    cancelled: "red",
    sync: "green",
    invalid: "red"
};

interface DocumentsStatusProps {
    status: DocumentStatus;
    large?: boolean;
}
export const DocumentsStatus: React.FC<DocumentsStatusProps> = ({ status, large = false }) => {
    return <StatusTag<DocumentStatus> status={status} colorsMap={statuses} large={large} />;
};
