import React from "react";
import {Card, Col, Descriptions, Row, Space, Typography} from "antd";
import {CustomCard} from "../../../../common/custom-card/custom.card";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {DeclarationCartCodesList} from "./codes-list/codes-list";
import {UploadedDeclaration} from "../../declaration.upload.model";
import Date from "../../../../date/date";
import {displayPrice} from "../../../../../models/prices/price.helper";

interface DeclarationCartProps {
    declaration: UploadedDeclaration
}

export const DeclarationCart: React.FC<DeclarationCartProps> = ({declaration}) => {

    const t = useTranslate();

    return (
        <Space direction={"vertical"} size={[20, 20]}>

            <Typography.Title level={4} style={{ margin: 0 }}>
                {t('DECLARATIONS.UPLOAD.DATA')}
            </Typography.Title>

            <div className="data-content-wrapper">
                <Descriptions
                    className="data-content-wrapper-descriptions"
                    size={"small"}
                    layout={"vertical"}
                    contentStyle={{ fontSize: "17px" }}
                    labelStyle={{ color: "#858585" }}
                    column={{
                        xxl: 4,
                        xl: 4,
                        lg: 4,
                        md: 3,
                        sm: 2,
                        xs: 1,
                    }}
                >
                    <Descriptions.Item label={t("DECLARATION.PROVIDER.COUNTRY")}>
                        {declaration.providersCountry.name}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("DECLARATION.ORIGIN.COUNTRY")}>
                        {declaration.productOrigin}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("DECLARATION.TRANSPORT.AGREEMENT")}>
                        {declaration.transportAgreement}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("DECLARATION.PACK.TYPE")}>
                        {declaration.packingType}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("DECLARATION.CODES.QTY")}>
                        {declaration.partsQty}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("DECLARATION.PACKS.QTY")}>
                        {declaration.boxesQty}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("DECLARATION.QTY")}>
                        {declaration.pcsQty}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("DECLARATION.VALUE")}>
                        {displayPrice(declaration.price)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("DECLARATION.NET_WEIGHT")}>
                        {declaration.netWeight}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("DECLARATION.GROSS_WEIGHT")}>
                        {declaration.grossWeight}
                    </Descriptions.Item>
                    {declaration.status === 'verified' ? (
                        <>
                            <Descriptions.Item label={t("DECLARATION.DECLARATION.SUBMISSION_DATE")}>
                                <Date dateString={declaration.submissionDate} />
                            </Descriptions.Item>
                            <Descriptions.Item label={t("DECLARATION.DECLARATION.GOODS_RELEASED_DATE")}>
                                <Date dateString={declaration.goodsReleasedDate} />
                            </Descriptions.Item>
                            <Descriptions.Item label={"Tax A00"}>
                                {displayPrice({value: parseFloat(declaration.taxA00), currencyCode: declaration.price.currencyCode})}
                            </Descriptions.Item>
                            <Descriptions.Item label={"Tax A30"}>
                                {displayPrice({value: parseFloat(declaration.taxA30), currencyCode: declaration.price.currencyCode})}
                            </Descriptions.Item>
                            <Descriptions.Item label={"Tax B00"}>
                                {displayPrice({value: parseFloat(declaration.taxB00), currencyCode: declaration.price.currencyCode})}
                            </Descriptions.Item>
                        </>
                    ) : null}
                </Descriptions>
            </div>

            <Typography.Title level={4} style={{ margin: 0 }}>
                {t('DECLARATIONS.RECEIVER.DATA')}
            </Typography.Title>

            <Card
                hoverable
                className={"card"}
            >
                <CustomCard
                    data={declaration.receiver}
                    rowKeys={[
                        {key: "id", label: "ID"},
                        {key: "name", label: "NAME"},
                        {key: "", label: "VAT.TITLE"},
                        {key: "street", label: "ADDRESS"},
                        {key: "city", label: "CITY"},
                        {key: "zip", label: "Postcode"},
                        {key: "country.name", label: "COUNTRY"}
                    ]}/>
            </Card>

            <Typography.Title level={4} style={{ margin: 0 }}>
                {t('DECLARATIONS.OWNERS.DATA')}
            </Typography.Title>

            <Row style={{width: '100%'}} gutter={[20, 20]}>
                {declaration.providers.map(provider => (
                    <Col span={8}>
                        <Card
                            key={provider.id}
                            hoverable
                            className={"card"}
                        >
                            <CustomCard
                                data={provider}
                                rowKeys={[
                                    {key: "id", label: "ID"},
                                    {key: "code", label: "DECLARATIONS.AGREEMENT"},
                                    {key: "name", label: "NAME"},
                                    {key: "address", label: "ADDRESS"},
                                    {key: "city", label: "CITY"},
                                    {key: "zip", label: "Postcode"},
                                    {key: "country.name", label: "COUNTRY"}
                                ]}/>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Typography.Title level={4} style={{ margin: 0 }}>
                {t('DECLARATIONS.CODES_LIST.TITLE')}
            </Typography.Title>

            <DeclarationCartCodesList declaration={declaration} />

        </Space>
    )
}