import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, Space, Tabs} from "antd";
import Title from "antd/es/typography/Title";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Editor} from "../../../../common/ui/editor/editor";
import {WikiEditLanguageSelectModal} from "../../../../wiki/page/edit/edit.language-select.modal";
import {WebSiteCategory, WebSitePage} from "../../../../../models/web-site/web-site";
import {webSiteService} from "../../../../../services/web-site/web-site.service";
import {useOutletContext, useParams} from "react-router-dom";
import {generateCategoriesTree} from "../../categories/edit/edit.categories";
import EditorJS from "@editorjs/editorjs";
import {notifications} from "../../../../../services/notifications/notifications";
import {useNavigate} from "react-router";
import {LoadingSpin} from "../../../../common/loading-spin/loading.spin";
import {UIInputWidgetImage} from "../../../../common/ui/input/widget/image/ui.input.widget.image";

interface ValuesContent {
    title: string;
    content?: EditorJS;
    fields: Record<string, string>
    headImage?: any;
}

interface Values {
    category: number;
    contents: Record<string, ValuesContent>
}

const toRequest = async (value: Values, defaultLocale: string): Promise<any> => {

    const contents = [];
    for (let key in value.contents) {
        const content = value.contents[key];
        let savedContent: any = '';

        if (content.content) {
            try {
                savedContent = JSON.stringify(await content.content.save());
            } catch (e) {
                console.log("Failed to save content", e);
            }
        }

        contents.push(
            {
                title: content.title,
                content: savedContent,
                locale: key,
                headImage: (content.headImage && content.headImage[0]) ? parseInt(content.headImage[0].uid) : null,
                fields: content.fields ? Object.keys(content.fields).map(key => {
                    return {
                        field: parseInt(key),
                        value: content.fields[key]
                    }
                }) : []
            }
        )
    }

    return {
        category: value.category,
        defaultContentLocale: defaultLocale,
        contents: contents
    }
}

const toForm = (page: WebSitePage): Values=> {
    const contents: any = {};
    page.contents.forEach(value => {

        let headImage: any = null;
        if (value.headImageFile) {
            headImage = {
                id: value.headImageFile.id,
                uid: value.headImageFile.id,
                url: '/api/v3/web-site/download/' + value.headImageFile.hash + '/' + value.headImageFile.filename,
                filename: value.headImageFile.filename
            }
        }

        const fields: any = {};
        value.values.forEach(v_value => {
            fields[String(v_value.fieldId)] = v_value.value;
        })
        contents[value.locale] = {
            title: value.title,
            content: value.content,
            fields: fields,
            headImage: headImage ? [headImage] : undefined
        }
    })

    return {
        category: page.category.id,
        contents: contents
    };
}

export const EditPageWebSiteSettings: React.FC = () => {

    const { id, pageId } = useParams<{id: string, pageId: string}>();
    const t = useTranslate();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [activeLangTabKey, setActiveLangTabKey] = useState<string>(t.i18n.language);
    const [loading, setLoading] = useState<boolean>(false);
    const [defaultLanguage, setDefaultLanguage] = useState<string>(t.i18n.language);
    const [openSelectLanguageModal, setOpenSelectLanguageModal] = useState<boolean>(false);
    const [usedLanguages, setUsedLanguages] = useState<string[]>([t.i18n.language]);
    const [defaultValues, setDefaultValues] = useState<Values>({
        category: parseInt(id!),
        contents: {[t.i18n.language]: {
            title: '',
            fields: {}
        }}
    });

    const [category, setCategory] = useState<WebSiteCategory | null>(null);
    const [ready, setReady] = useState<boolean>(!pageId);

    const ctx = useOutletContext<{onTreeShouldBeUpdated: () => void, tree: WebSiteCategory[]}>();

    const onLanguageSelected = (value: string): void => {
        setOpenSelectLanguageModal(false);
        if (value !== "" && defaultValues) {
            setActiveLangTabKey(String(value))
            setUsedLanguages(usedLanguages => [...usedLanguages, value])
        }
    }

    const onLanguageListModify = (targetKey: any, eventName: string) => {
        if (eventName === 'add') {
            setOpenSelectLanguageModal(true);
        } else if (eventName === 'remove') {
            const values: Values = {...form.getFieldsValue()};
            delete values.contents[targetKey];

            setUsedLanguages(languages => {
                const newLanguages = [...languages];
                const idx = languages.findIndex(e => e === targetKey);
                if (-1 !== idx) {
                    newLanguages.splice(idx, 1);
                }

                return newLanguages;
            })
        }
    }

    useEffect(() => {
        if (id) {
            webSiteService.getCategory(parseInt(id!)).then(category => setCategory(category));
        }
    }, [id])

    useEffect(() => {
        if (pageId) {
            webSiteService.getPage(parseInt(pageId!)).then(page => {
                const formFields = toForm(page);
                setDefaultLanguage(page.defaultLocale)
                setActiveLangTabKey(page.defaultLocale)
                setDefaultValues({...formFields})
                setCategory(page.category)
                setReady(true);
                setUsedLanguages(page.contents.map(c => c.locale))
            });
        }
    }, [pageId])

    if (!ready) {
        return <LoadingSpin />
    }

    return (
        <Space direction={"vertical"}>
            <Title level={4}>{"Page"}</Title>
            <Form
                size={"middle"}
                form={form}
                layout="vertical"
                initialValues={defaultValues}
                onFinish={() => form
                    .validateFields()
                    .then(async (values: Values) => {
                        setLoading(true);
                        try {
                            const req = await toRequest(values, defaultLanguage);
                            if (pageId) {
                                await webSiteService.editPage(parseInt(pageId), req);
                            } else {
                                const data = await webSiteService.addPage(req);
                                navigate('/settings/web-site/page/' + data.id);
                            }
                            ctx.onTreeShouldBeUpdated();
                            notifications.successfully();
                        } catch (e: any) {
                            if (e.response?.data.fields) {
                                form.setFields(e.response?.data?.fields);
                            }
                        } finally {
                            setLoading(false)
                        }
                    })
                }
            >

                <Row>
                    <Col span={24}>
                        <Form.Item
                            name={["category"]}
                            label={t("Сategory")}
                            rules={[{required: true}]}
                        >
                            <Select disabled={true}>
                                <Select.Option value={null} key={0}>
                                    (root)
                                </Select.Option>
                                {generateCategoriesTree(ctx.tree, 0)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Tabs
                            type={"editable-card"}
                            activeKey={activeLangTabKey}
                            onChange={idx => setActiveLangTabKey(idx)}
                            onEdit={onLanguageListModify}
                            destroyInactiveTabPane={false}
                        >
                            {usedLanguages.map(language => {
                                return (
                                    <Tabs.TabPane
                                        tab={<>
                                            {defaultLanguage === language
                                                ? <FontAwesomeIcon icon={["fas", "check-circle"]} color={"#096dd9"} />
                                                : null}&nbsp;
                                            {language.toUpperCase()}
                                        </>}
                                        key={language}
                                        closable={true}
                                        forceRender={true}
                                    >
                                        <Row gutter={[10, 10]} align={"middle"}>
                                            <Col span={24}>
                                                <Button
                                                    type={"primary"}
                                                    onClick={() => setDefaultLanguage(language)}
                                                    disabled={language === defaultLanguage}
                                                >
                                                    {t("WIKI.EDIT-PAGE.DEFAULT_LANGUAGE")}
                                                </Button>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    name={["contents", language, "title"]}
                                                    label={t("WIKI.CREATE-PAGE.FIELDS.TITLE")}
                                                    rules={[{required: true}]}
                                                >
                                                    <Input type={"text"} disabled={loading}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    name={["contents", language, "content"]}
                                                >
                                                    <Editor uploadFile={f => webSiteService.uploadFile(f).then(v => {
                                                        return {
                                                            success: 1,
                                                            file: {
                                                                url: v.filepath
                                                            }
                                                        }
                                                    })} />
                                                </Form.Item>
                                            </Col>
                                            {category?.fields.map(field => (
                                                <Col span={24}>
                                                    <Form.Item
                                                        name={["contents", language, "fields", String(field.id)]}
                                                        label={field.visibleName}
                                                        rules={[{required: true}]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            ))}
                                            <Col span={24}>
                                                <Form.Item
                                                    name={["contents", language, "headImage"]}
                                                    label={"Head image"}
                                                >
                                                    <UIInputWidgetImage
                                                        action={"/api/v3/web-site/upload"}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Tabs.TabPane>
                                )
                            })}
                        </Tabs>
                    </Col>
                    <Col span={24}>
                        <Button block type="primary" onClick={() => form.submit()} disabled={loading}>
                            {t("SAVE")}
                        </Button>
                    </Col>
                </Row>
            </Form>
            <WikiEditLanguageSelectModal
                visible={openSelectLanguageModal}
                onSubmit={onLanguageSelected}
                excludedLanguages={usedLanguages}
            />
        </Space>
    )
}