import {Translate} from "../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {Link} from "react-router-dom";
import {ContainerItem} from "../../../../../models/containers/containers";
import {OrderStatus} from "../../../../../stores/common/common.store";
import {OrdersStatus} from "../../../../orders/status/order.status";
import {Warehouse} from "../../../../../models/warehouses/warehouse";
import {Amount} from "../../../../../models/prices/price";
import {displayPrice} from "../../../../../models/prices/price.helper";
import Date from "../../../../date/date";

export const columnsFactory = (t: Translate): ColumnsType<ContainerItem> => {
    return [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "160px",
            render: (id: string) => (
                <Link className="link" to={"/orders/" + id}>
                    {id}
                </Link>
            ),
        },
        {
            title: t("ORDERS.ALT_NUMBER"),
            dataIndex: "clientOrderId",
            key: "orders_clientOrderId",
            width: "220px"
        },
        {
            title: t("ORDERS.STATUS.TITLE"),
            dataIndex: "status",
            key: "orders_status",
            render: (status: OrderStatus) => <OrdersStatus status={status} />,
            width: "220px"
        },
        {
            title: t("WAREHOUSES.WAREHOUSE_TITLE"),
            dataIndex: "warehouse",
            key: "orders_warehouse",
            render: (warehouse: Warehouse) => <>{warehouse.name}</>,
            width: "120px"
        },
        {
            title: t("GRAND_TOTAL_PRICE"),
            dataIndex: "grandTotal",
            key: "orders_grandTotal",
            render: (total: Amount) => <>{displayPrice(total)}</>,
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "orders_createdAt",
            render: (date: string) => <Date dateString={date} showTime />,
        }
    ];
}