import React, {useEffect, useState} from "react";
import {WidgetProps} from "../widget";
import {Select} from "antd";
import {countriesService} from "../../../../../../services/countries/countries.service";
import {Country} from "../../../../../../models/countries/country";

export const UIInputWidgetCountry: React.FC<WidgetProps> = ({value, onChange, multiple}) => {

    const [countries, setCountries] = useState<Country[]>([]);

    useEffect(() => {
        countriesService.getAll().then(countries => {
            setCountries(countries);
        });
    }, []);

    return (
        <Select
            mode={multiple ? "multiple" : undefined}
            onChange={onChange as any}
            value={value as any}
            style={{width: '250px'}}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
        >
            {countries.map(country =>
                <Select.Option key={country.id as string} value={country.id as string}>{country.name}</Select.Option>)}
        </Select>
    )
}