import React, {useState, Suspense} from "react";
import { observer } from "mobx-react";
import { useRequiredStore } from "../../utils/store";
import { initInterceptors } from "../../utils/interceptors";
import {Select, Spin, Layout, Space} from "antd";
import LeftSideNavigation from "../navigation/left.side.navigation";
import { RootStore, RootStoreContext } from "../../stores/root/root.store";
import ErrorBoundary from "../common/error-boundary/error.boundary";
import {Breadcrumbs} from "../common/breadscrumbs/breadcrumbs";
import {notifications} from "../../services/notifications/notifications";
import {VersionChecker} from "../common/version-checker/version-checker";
import {Confirmation} from "../common/confirmation/confirmation";
import {RootMenu} from "../root/menu/menu";
import {isAllowed} from "../../utils/helpers";
import {ViolationList} from "../common/violation-list/violation-list";
import {useTranslate} from "../../hooks/translate.hook";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {useRoutes} from "react-router-dom";
import {useNavigate} from "react-router";
import "./app.scss";
import "../../utils/icons";
import {reactRouterConfig} from "../../routers/routers";
import {authService} from "../../services/auth/auth.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MainContainer: React.FC = observer(() => {
    const rootStore = useRequiredStore(RootStoreContext);
    const pageStore = rootStore.pageStore;
    const langStore = rootStore.langStore;
    const authStore = rootStore.authStore;
    const containerClass = rootStore.authStore.isLoggedIn
        ? rootStore.menuStore.getStateForContainer
        : "";
    const t = useTranslate();
    const routes = useRoutes(reactRouterConfig);

    const switchRole = async (access: number) => {
        await authService.switchAccount(access)
        window.location.reload();
    }

    if (!t.ready || !rootStore.authStore.inited) {
        return (
            <Space
                direction={"horizontal"}
                align={"center"}
                style={{
                    height: "100vh",
                    width: "100vw",
                    justifyContent: "center",
                }}
            >
                <Spin />
            </Space>
        );
    }

    return (
        <>
            <LeftSideNavigation />
            <Layout.Content
                style={
                    rootStore.authStore.isLoggedIn && !pageStore.fullWidth
                        ? { maxWidth: 1450 }
                        : undefined
                }
                className={"rs-container " + containerClass}
            >
                {rootStore.authStore.isLoggedIn ? (
                    <div className={"section-top-bar"}>

                        {/*{t(pageStore.key)}*/}
                        <Breadcrumbs/>
                        <div>
                            {authStore.accesses.length > 1 ? (
                                <Select<number>
                                    onChange={switchRole}
                                    style={{ width: "350px" }}
                                    value={authStore.access}
                                    suffixIcon={<FontAwesomeIcon icon={["fas", "building"]} />}
                                >
                                    {authStore.accesses.map((item, index) => (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.branchName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            ) : null}
                            <Select
                                showSearch
                                optionFilterProp={"children"}
                                onChange={(value: string) =>
                                    t.i18n.changeLanguage(value)
                                }
                                style={{ width: "80px" }}
                                value={t.i18n.language}
                                suffixIcon={<FontAwesomeIcon icon={["fas", "language"]} />}
                            >
                                {langStore.locales.map((item, index) => (
                                    <Select.Option key={index} value={item}>
                                        {item.toUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                ) : (
                    <React.Fragment />
                )}
                <div
                    className={
                        rootStore.authStore.isLoggedIn
                            ? "main-container"
                            : "main-container-login"
                    }
                >
                    <VersionChecker />
                    <Confirmation />
                    <ViolationList />
                    {isAllowed(rootStore.authStore.attributes, "faker") ? <RootMenu /> : null}
                    {routes}
                </div>
            </Layout.Content>
        </>
    );
});

function App() {
    const t = useTranslate();
    const navigate = useNavigate();
    const [rootStore] = useState(() => {
        const store = new RootStore(navigate as NavigateFunction);
        store.langStore.setI18n(t.i18n);
        initInterceptors(
            store,
            () => t("FROM.ERROR.SERVER_ERROR"),
            () => t("ERROR"),
            () => t("ACCESS_ERROR")
        );

        notifications.init(t);
        store.init();

        return store;
    });

    return (
        <RootStoreContext.Provider value={rootStore}>
            <Suspense
                fallback={
                    <Space
                        direction={"horizontal"}
                        align={"center"}
                        style={{
                            height: "100vh",
                            width: "100vw",
                            justifyContent: "center",
                        }}
                    >
                        <Spin />
                    </Space>
                }
            >
                <ErrorBoundary>
                    <MainContainer />
                </ErrorBoundary>
            </Suspense>
        </RootStoreContext.Provider>
    );
}

export default App;
