import React, {useState} from 'react';
import {useTranslate} from "../../../hooks/translate.hook";
import {NumberInput} from "../number-input/number.input";
import {Col, Form, Modal, notification, Row} from "antd";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../models/core/response";

interface RecalcWeightModalProps {
    visible: boolean,
    onClose: () => void,
    onSubmit: (value: {weight: string}, openNotification: (type: "success" | "error", message: string) => void) => Promise<void>
    defaultWeight?: string |  number;
}
export const RecalcWeightModal: React.FC<RecalcWeightModalProps> = ({defaultWeight , visible, onSubmit, onClose}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const openNotification = (type: "success" | "error", message: string): void => {
        notification[type]({
            message: t(type.toUpperCase()),
            description: t(message),
            duration: 2
        });
    };


    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={t("RECALCULATE_WEIGHT.TITLE")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"edit_weight_modal"}
                initialValues={{weight: defaultWeight}}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                            onSubmit(values, openNotification)
                            .then(() => {
                                form.resetFields();
                                return onClose();
                            }, (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                            .then(() =>
                                    onClose(),
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="weight"
                            label={t("WEIGHT_BRUTTO")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <NumberInput/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
