import React, { useState } from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { Button, Card, Col, Row, Space, Tooltip, Typography } from "antd";
import { useRequiredStore } from "../../../../utils/store";
import { AgentDataStoreContext } from "../agent.data.store";
import { AgentDataAccountsCard } from "./agent.data.accounts.card";
import { AgentDataAccountsModal } from "./agent.data.accounts.modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import { AgentDataIntegrations } from "./agent-data-integrations/agent.data.integrations";

export const AgentDataAccounts: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(AgentDataStoreContext);

    const [isModalShown, setIsModalShown] = useState<boolean>(false);

    return (
        <Space direction="vertical" size={[50, 50]}>
            <Space size={[0, 0]} direction={"vertical"}>
                <Row>
                    <Typography.Title level={3}>
                        {t("ACCOUNTS.TITLE")}
                    </Typography.Title>
                    <Tooltip key={2} placement="topLeft" title={t("ADD")}>
                        <Button
                            className="btn"
                            style={{ margin: "3px 0 0 5px" }}
                            onClick={() => setIsModalShown(true)}
                            type="default"
                            icon={<FontAwesomeIcon icon={["fas", "plus"]} />}
                        />
                    </Tooltip>
                </Row>
                <Row gutter={[30, 30]}>
                    {store.account?.accesses.length ? (
                        store.account?.accesses.map(
                            (access, index) => (
                                <Col
                                    className={"card-col"}
                                    key={index}
                                    xxl={8}
                                    xl={8}
                                    lg={8}
                                    md={12}
                                    sm={24}
                                    xs={24}
                                >
                                    <AgentDataAccountsCard
                                        onAdd={() => setIsModalShown(true)}
                                        access={access}
                                    />
                                </Col>
                            )
                        )
                    ) : (
                        <Col
                            className={"card-col"}
                            xxl={8}
                            xl={8}
                            lg={8}
                            md={12}
                            sm={24}
                            xs={24}
                        >
                            <Card className={"card"}>{t("NO_DATA")}</Card>
                        </Col>
                    )}
                </Row>
                <AgentDataAccountsModal
                    visible={isModalShown}
                    onClose={() => setIsModalShown(false)}
                    onSubmit={store.createAccount}
                />
            </Space>
            <AgentDataIntegrations />
        </Space>
    );
});
