import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {CreditInvoice} from "../../../../models/documents/invoices/credit-invoice/credit.invoice";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {creditInvoicesService} from "../../../../services/documents/invoices/credit.invoices.service";
import {Space} from "antd";
import {PayerSellerReceiverData} from "../../../common/payer-seller-receiver-data/payer.seller.receiver.data";
import {PayerReceiverFormValues, SellerDetails} from "../../../../models/payer-receiver-seller/payer.reveiver.seller";
import {CreditInvoiceDataHeader} from "./credit-invoide-data-header/credit.invoice.data.header";
import {CreditInvoiceDataDetails} from "./credit-invoice-data-details/credit.invoice.data.details";
import {addressService} from "../../../../services/documents/common/address.service";

export const CreditInvoiceData: React.FC = () => {

    const {id} = useParams<{id: string}>();

    const [creditInvoice, setCreditInvoice] = useState<CreditInvoice|null>(null);

    const awaitPreClosed = () => {
        setTimeout(() => {
            reload().then(creditInvoice =>  {
                if (creditInvoice.status === "sync") {
                    awaitPreClosed();
                }
            })
        }, 1000)
    }

    const reload = useCallback(async (): Promise<CreditInvoice> => {
        const downloadedInvoice = await creditInvoicesService.getCreditInvoice(id!);
        setCreditInvoice(downloadedInvoice);
        return downloadedInvoice
    }, []);

    const reloadWithCheck = useCallback(async (): Promise<CreditInvoice> => {
        const downloadedInvoice = await reload();
        if (downloadedInvoice.status === "sync") {
            awaitPreClosed()
        }
        return downloadedInvoice
    }, []);

    useEffect(() => {
        reloadWithCheck();
    }, [id]);

    if (!creditInvoice) {
        return <LoadingSpin />
    }

    const editSellerData = async (values: SellerDetails) => {
        creditInvoice.sellerAddress = await addressService.updateSellerAddress(creditInvoice.id, values);
        setCreditInvoice({...creditInvoice});
    };

    const editPayerReceiverData = async (type: "payer" | "receiver", values: PayerReceiverFormValues) => {
        if (type === "payer") {
            creditInvoice.billingAddress = await addressService.updateBillingAddress(creditInvoice.id, values);
        } else {
            creditInvoice.shippingAddress = await addressService.updateShippingAddress(creditInvoice.id, values);
        }
        setCreditInvoice({...creditInvoice});
    };

    return <Space direction="vertical" size={[50, 50]}>
        <CreditInvoiceDataHeader document={creditInvoice} reload={reloadWithCheck} />
        <PayerSellerReceiverData
            payer={creditInvoice.billingAddress}
            receiver={creditInvoice.shippingAddress}
            seller={creditInvoice.sellerAddress}
            editPayerReceiver={editPayerReceiverData}
            editSeller={editSellerData}
        />
        <CreditInvoiceDataDetails document={creditInvoice} reload={reloadWithCheck} />
    </Space>
}