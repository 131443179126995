import React, {useState} from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../../utils/store";
import {AgentDataStoreContext} from "../../agent.data.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, Tooltip, Typography} from "antd";
import {InvoicingPeriod} from "../../../../../models/invoicing/invoicing";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {accountService} from "../../../../../services/accounts/account.service";
import {notifications} from "../../../../../services/notifications/notifications";
import {isAllowed} from "../../../../../utils/helpers";

export const AgentDataDocuments: React.FC<ComponentPropsFromRoute> = observer(({name}) => {
    const {account} = useRequiredStore(AgentDataStoreContext);
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const { commonStore, authStore: {attributes} } = useRequiredStore(RootStoreContext);

    if (!account) {
        return <></>;
    }

    const submit = async (values: any) => {
        setLoading(true)
        try {
            await accountService.updateDocuments(account.id, values)
            notifications.successfully()
        } catch (e: any) {
            if (e.response?.data.fields) {
                form.setFields(e.response?.data?.fields);
            }
        } finally {
            setLoading(false);
        }
    }

    const defaultValues = {
        documentType: account.documentType,
        invoiceDocumentType: account.invoiceDocumentType,
        packingListDocumentType: account.packingListDocumentType,
        invoicingPeriod: account.invoicingPeriod,
        emailType: account.emailType,
        allowedMergedInvoices: account.allowedMergedInvoices,
        useBackorder: account.useBackorder,
        separateDeliveryOrder: account.separateDeliveryOrder,
        combineGoodsAndDeliveryIsProhibited: account.combineGoodsAndDeliveryIsProhibited,
        sendProforma: account.sendProforma,
        sendProformaEmails: account.sendProformaEmails,
        sendInvoice: account.sendInvoice,
        sendEInvoice: account.sendEInvoice,
        sendInvoiceEmails: account.sendInvoiceEmails,
        sendInvoiceFormat: account.sendInvoiceFormat,
        sendPackingList: account.sendPackingList,
        sendPackingListAdditionalDocument: account.sendPackingListAdditionalDocument,
        sendPackingListEmails: account.sendPackingListEmails,
    }

    return <Form
        size={"middle"}
        form={form}
        layout="vertical"
        name={"client_form"}
        initialValues={defaultValues}
        onFinish={async (values) => {
            await form.validateFields();
            await submit(values)
        }}
    >
        <Typography.Title level={3}>{t("CLIENT.DOCUMENTS.TITLE")}</Typography.Title>
        <Row gutter={[30, 0]} align={"top"}>
            <Col span={12}>
                <Row gutter={[30, 0]} align={"top"}>
                    <Col span={24}>
                        <Form.Item
                            name={["documentType"]}
                            label={t("DOCUMENT_TYPE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp={"children"}
                                disabled={loading}
                                placeholder={t("DOCUMENT_TYPE")}
                            >
                                {commonStore.documentTypes.map((item) => (
                                    <Select.Option key={item} value={item}>
                                        {item.toLowerCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={["invoiceDocumentType"]}
                            label={t("INVOICE_DOCUMENT_TYPE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp={"children"}
                                disabled={loading}
                                placeholder={t("INVOICE_DOCUMENT_TYPE")}
                            >
                                {commonStore.invoiceDocumentTypes.map((item) => (
                                    <Select.Option key={item} value={item}>
                                        {item.toLowerCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={["packingListDocumentType"]}
                            label={t("PACKING_LIST_DOCUMENT_TYPE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp={"children"}
                                disabled={loading}
                                placeholder={t("PACKING_LIST_DOCUMENT_TYPE")}
                            >
                                {commonStore.packingListDocumentTypes.map((item) => (
                                    <Select.Option key={item} value={item}>
                                        {item.toLowerCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={["emailType"]}
                            label={t("EMAIL_TYPE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp={"children"}
                                placeholder={t("EMAIL_TYPE")}
                                disabled={loading}
                            >
                                <Select.Option value={"default"}>Default</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={["invoicingPeriod"]}
                            label={t("INVOICING.PERIOD.TITLE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Select
                                placeholder={t("INVOICING.PERIOD.TITLE")}
                                disabled={loading}
                            >
                                {Object.values(InvoicingPeriod).map((item) => (
                                    <Select.Option key={item} value={item}>
                                        {t("INVOICING.PERIOD." + item.toUpperCase())}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={["allowedMergedInvoices"]} valuePropName="checked">
                            <Checkbox>{t("CLIENT.ALLOWED_MERGED_INVOICES")}</Checkbox>
                        </Form.Item>
                    </Col>
                    {isAllowed(attributes, 'order_backorder') ? (
                        <Col span={24}>
                            <Form.Item name={["useBackorder"]} valuePropName="checked">
                                <Checkbox>{t("BRANCH.ALLOW_USE_BACKORDER")}</Checkbox>
                            </Form.Item>
                        </Col>
                    ) : null}
                    <Col span={24}>
                        <Form.Item name={["separateDeliveryOrder"]} valuePropName="checked">
                            <Checkbox>{t("CLIENT.SEPARATE_DELIVERY_ORDER")}</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={["combineGoodsAndDeliveryIsProhibited"]} valuePropName="checked">
                            <Checkbox>{t("CLIENT.COMBINE_GOODS_AND_DELIVERY_IS_PROHIBITED")}</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
            <Col span={12}>
                <Row gutter={[30, 0]} align={"top"}>
                    <Col span={24}>
                        <Form.Item name={["sendProforma"]} valuePropName={"checked"}>
                            <Checkbox
                                onChange={(e) => {
                                    const emails = form.getFieldValue(["sendProformaEmails"]);
                                    const fieldsValue = form.getFieldsValue();
                                    if (
                                        (e.target.checked && !emails) ||
                                        (e.target.checked && !emails.length)
                                    ) {
                                        form.setFieldsValue({
                                            ...fieldsValue,
                                            account: {
                                                ...fieldsValue,
                                                sendProformaEmails: [""],
                                            },
                                        });
                                    }
                                }}
                                disabled={loading}
                            >
                                {t("CLIENT.SEND_PROFORMAS")}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.List name={["sendProformaEmails"]}>
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item required={false} key={field.key}>
                                            <Row justify={"space-between"} gutter={[5, 0]}>
                                                <Col span={22}>
                                                    <Form.Item {...field} noStyle>
                                                        <Input disabled={loading} placeholder="Email" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <Tooltip placement="topRight" title={t("DELETE")}>
                                                        <Button
                                                            disabled={loading}
                                                            type="default"
                                                            onClick={() => remove(field.name)}
                                                            icon={
                                                                <FontAwesomeIcon icon={["fas", "times"]} />
                                                            }
                                                            danger
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            className="btn-flex-center"
                                                        />
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            disabled={loading}
                                            onClick={() => add()}
                                            block
                                            icon={<FontAwesomeIcon icon={["fas", "plus-circle"]} />}
                                        >
                                            {t("CLIENT.ADD_EMAIL")}
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>

                    <Col span={24}>
                        <Typography.Title level={5}>{t("CLIENT.INVOICE_EMAILS")}</Typography.Title>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={["sendInvoice"]} valuePropName={"checked"}>
                            <Checkbox
                                onChange={(e) => {
                                    const emails = form.getFieldValue(["sendInvoiceEmails"]);
                                    const fieldsValue = form.getFieldsValue();
                                    if (
                                        (e.target.checked && !emails) ||
                                        (e.target.checked && !emails.length)
                                    ) {
                                        form.setFieldsValue({
                                            ...fieldsValue,
                                            account: {
                                                ...fieldsValue,
                                                sendInvoiceEmails: [""],
                                            },
                                        });
                                    }
                                }}
                                disabled={loading}
                            >
                                {t("CLIENT.SEND_INVOICES")}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) =>
                                getFieldValue(["sendInvoice"]) ? (
                                    <Form.Item
                                        name={["sendEInvoice"]}
                                        valuePropName={"checked"}
                                    >
                                        <Checkbox>{t("CLIENT.SEND_INVOICES_E_INVOICE")}</Checkbox>
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) =>
                                getFieldValue(["sendInvoice"]) ? (
                                    <Form.Item
                                        label={t("CLIENT.SEND_INVOICES_FORMAT")}
                                        name={["sendInvoiceFormat"]}
                                    >
                                        <Select
                                            placeholder={t("CLIENT.SEND_INVOICES_FORMAT")}
                                            disabled={loading}
                                        >
                                            {commonStore.exportFormats.map((format) => (
                                                <Select.Option key={format} value={format}>
                                                    {format.toUpperCase()}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.List name={["sendInvoiceEmails"]}>
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            required={false}
                                            key={field.key}
                                            label={index === 0 ? "Emails" : ""}
                                        >
                                            <Row justify={"space-between"} gutter={[5, 0]}>
                                                <Col span={22}>
                                                    <Form.Item {...field} noStyle>
                                                        <Input disabled={loading} placeholder="Email" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <Tooltip placement="topRight" title={t("DELETE")}>
                                                        <Button
                                                            disabled={loading}
                                                            type="default"
                                                            onClick={() => remove(field.name)}
                                                            icon={
                                                                <FontAwesomeIcon icon={["fas", "times"]} />
                                                            }
                                                            danger
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            className="btn-flex-center"
                                                        />
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            disabled={loading}
                                            onClick={() => add()}
                                            block
                                            icon={<FontAwesomeIcon icon={["fas", "plus-circle"]} />}
                                        >
                                            {t("CLIENT.ADD_EMAIL")}
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                    <Col span={24}>
                        <Typography.Title level={5}>{t("CLIENT.PACKING_LIST_EMAILS")}</Typography.Title>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={["sendPackingList"]} valuePropName={"checked"}>
                            <Checkbox
                                onChange={(e) => {
                                    const emails = form.getFieldValue(["sendPackingListEmails"]);
                                    const fieldsValue = form.getFieldsValue();
                                    if (
                                        (e.target.checked && !emails) ||
                                        (e.target.checked && !emails.length)
                                    ) {
                                        form.setFieldsValue({
                                            ...fieldsValue,
                                            account: {
                                                ...fieldsValue,
                                                sendPackingListEmails: [""],
                                            },
                                        });
                                    }
                                }}
                                disabled={loading}
                            >
                                {t("CLIENT.SEND_PACKING_LISTS")}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={["sendPackingListAdditionalDocument"]} valuePropName={"checked"}>
                            <Checkbox
                                onChange={(e) => {
                                    const emails = form.getFieldValue(["sendPackingListAdditionalDocument"]);
                                    const fieldsValue = form.getFieldsValue();
                                    if (
                                        (e.target.checked && !emails) ||
                                        (e.target.checked && !emails.length)
                                    ) {
                                        form.setFieldsValue({
                                            ...fieldsValue,
                                            account: {
                                                ...fieldsValue,
                                                sendPackingListAdditionalDocument: false,
                                            },
                                        });
                                    }
                                }}
                                disabled={loading}
                            >
                                {t("CLIENT.SEND_PACKING_LISTS_ADDITIONAL_DOCUMENTS")}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.List name={["sendPackingListEmails"]}>
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item required={false} key={field.key}>
                                            <Row justify={"space-between"} gutter={[5, 0]}>
                                                <Col span={22}>
                                                    <Form.Item
                                                        {...field}
                                                        // rules={[
                                                        //     {
                                                        //         required: true,
                                                        //         message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                        //     },
                                                        // ]}
                                                        noStyle
                                                    >
                                                        <Input disabled={loading} placeholder="Email" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <Tooltip placement="topRight" title={t("DELETE")}>
                                                        <Button
                                                            disabled={loading}
                                                            type="default"
                                                            onClick={() => remove(field.name)}
                                                            icon={
                                                                <FontAwesomeIcon icon={["fas", "times"]} />
                                                            }
                                                            danger
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            className="btn-flex-center"
                                                        />
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            disabled={loading}
                                            onClick={() => add()}
                                            block
                                            icon={<FontAwesomeIcon icon={["fas", "plus-circle"]} />}
                                        >
                                            {t("CLIENT.ADD_EMAIL")}
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row gutter={[30, 0]} align={"top"}>
            <Col span={24}>
                <Button disabled={loading} type={"primary"} onClick={() => form.submit()}>{t("SAVE")}</Button>
            </Col>
        </Row>
    </Form>
});
