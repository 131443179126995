import { AxiosInstance } from "axios";
import API from "../../../utils/api";
import { PaginatedRequest } from "../../../models/core/paginated.request";
import { PaginatedResult } from "../../../models/core/paginated.result";
import { ArticlesPrices } from "../../../models/articles/articles.prices";

export class ArticlesPricesService {
    api: AxiosInstance = API;

    getAll(
        request: PaginatedRequest
    ): Promise<PaginatedResult<ArticlesPrices>> {
        return this.api
            .get<PaginatedResult<ArticlesPrices>>("articles/prices", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }
}

export const articlesPrisesService = new ArticlesPricesService();
