import React from "react";
import { action, makeObservable, observable } from "mobx";
import { ArticleData, ArticleVehicle } from "../../../models/articles/article.data";
import { articlesService } from "../../../services/articles/articles.service";
import { PaginatedRequest } from "../../../models/core/paginated.request";
import { articlesStockService } from "../../../services/articles/articles/articles.stock.service";
import { PaginatedResult, Pagination } from "../../../models/core/paginated.result";
import { ArticlesStock } from "../../../models/articles/articles.stock";
import { ArticlesHistory } from "../../../models/articles/articles.history";
import { articlesHistoryService } from "../../../services/articles/articles/articles.history.service";
import { FilterValues } from "../../../models/filter/filter.props";
import { FieldStateGroup } from "../../../models/filter/filter.field.state";
import {PagesStore} from "../../../stores/pages/pages.store";
import dayjs from "dayjs";

export class ArticleDataStore {
    @observable
    public loading: boolean = false;

    @observable
    public availabilityTableLoading: boolean = false;

    @observable
    public articleHistoryTableLoading: boolean = false;

    @observable
    public vehicles: ArticleVehicle[] = [];

    @observable
    public article: ArticleData | null = null;

    @observable
    public imageLink: string = "";

    @observable
    public imageModalShown: boolean = false;

    @observable
    public articleAvailability: ArticlesStock[] = [];

    @observable
    public articleHistory: ArticlesHistory[] = [];

    @observable
    public historyRequest: PaginatedRequest = new PaginatedRequest();

    public historyFilters: FieldStateGroup[] = [];

    @observable
    public pagination?: Pagination;

    @observable
    public availabilityDataRequest: PaginatedRequest = new PaginatedRequest();

    @observable
    public total: number = 0;

    @observable
    public limitVariants: string[] = ["10", "20", "30", "40", "50", "100"];

    constructor(private readonly id: string, private pageStore: PagesStore, private url: string) {
        makeObservable(this);
        this.setPage = this.setPage.bind(this);
        this.setLimit = this.setLimit.bind(this);
        this.onLngChange = this.onLngChange.bind(this);

        this.init();
    }

    @action
    public setLoading(loading: boolean): void {
        this.loading = loading;
    }

    @action
    public setArticle(article: ArticleData): void {
        this.article = article;
    }

    @action
    public setImageLink(value: string): void {
        this.imageLink = value;
    }

    @action
    public setImageModalShown(value: boolean): void {
        this.imageModalShown = value;
    }

    @action
    public setPage(page: number) {
        this.historyRequest.page = page;
        this.reloadHistoryData();
    }

    @action
    public setLimit(limit: number) {
        this.historyRequest.limit = limit;
        this.reloadHistoryData();
    }

    @action
    private setVehicles(value: ArticleVehicle[]): void {
        this.vehicles = value;
    }

    public async reloadAvailabilityData() {
        this.availabilityDataRequest.addFilter("id", String(this.article!.id));
        this.setAvailabilityTableLoading(true);
        this.setAvailabilityData(await articlesStockService.getAll(this.availabilityDataRequest));
        this.setAvailabilityTableLoading(false);
    }

    public async reloadHistoryData() {
        this.historyRequest.addFilter("id", String(this.article!.id));
        this.setArticleHistoryTableLoading(true);
        this.setArticleHistory(await articlesHistoryService.getAll(this.historyRequest));
        this.setArticleHistoryTableLoading(false);
    }

    @action
    private setAvailabilityData(value: PaginatedResult<ArticlesStock>): void {
        this.articleAvailability = value.items;
    }

    @action
    private setArticleHistory(value: PaginatedResult<ArticlesHistory>): void {
        this.articleHistory = value.items;
        this.pagination = value.meta;
    }

    @action
    private setAvailabilityTableLoading(value: boolean): void {
        this.availabilityTableLoading = value;
    }

    @action
    private setArticleHistoryTableLoading(value: boolean): void {
        this.articleHistoryTableLoading = value;
    }

    public onImageClick(link: string): void {
        this.setImageLink(link);
        this.setImageModalShown(true);
    }

    public updateHistoryFilters = (filterStateGroups: FieldStateGroup[], requestFilters: FilterValues) => {
        this.historyRequest.filters = requestFilters;
        this.historyRequest.addFilter("id", this.id);
        this.historyRequest.page = 1;
        this.historyFilters = filterStateGroups;
        this.reloadHistoryData();
    };

    public async updateArticleAvailabilityStats(dates: [dayjs.Dayjs, dayjs.Dayjs]) {
        const params: { [key: string]: string } = {};
        params["filter[article]"] = this.article!.name;
        params["filter[from]"] = dayjs(dates[0]).format("YYYY-MM-DD");
        params["filter[until]"] = dayjs(dates[1]).format("YYYY-MM-DD");
    }

    private async loadData() {
        this.setLoading(true);
        this.setArticle(await articlesService.getArticle(this.id));
        this.setVehicles(await articlesService.getArticleVehicles(this.article!.name));
        this.setLoading(false);
    }

    public onLngChange(): void {
        this.loadData();
    }

    private async init() {
        await this.loadData();
        this.pageStore.updateRouteName(this.url, this.id);
        await this.reloadAvailabilityData();
        await this.reloadHistoryData();
    }
}

export const ArticleDataStoreContext = React.createContext<null | ArticleDataStore>(null);
