import { action, observable } from "mobx";
import { makeObservable } from "mobx";
import { downloadBlobFile } from "../../../utils/blob.download";
import { Messages } from "./export.blob";

export class ExportBlobStore<V> {
    private messageKey: string = "exportMessage";
    public defaultValues?: any;

    @observable
    public loading: boolean = false;

    constructor(
        private onSubmit: (values: V) => Promise<any> | any,
        private messages: Messages,
        private onInit?: () => Promise<any>
    ) {
        makeObservable(this);
        this.init();
    }

    @action
    private setLoading(value: boolean): void {
        this.loading = value;
    }

    private async init() {
        if (this.onInit) {
            this.setLoading(true);
            this.defaultValues = await this.onInit();
            this.setLoading(false);
        }
    }

    public async submit(values: V) {
        this.messages.loading("DOWNLOAD.BEGINS", this.messageKey);
        try {
            const { data: res, headers }: { data: Blob; headers: any } = await this.onSubmit(values);
            const [_, fileName] = headers["content-disposition"].split("; ");

            downloadBlobFile(res, fileName.split("=")[1].replace(/"/g, ""));

            this.messages.success("SUCCESS", this.messageKey, 3);
        } catch (e: any) {
            this.messages.error("ERROR", this.messageKey, 3);
        }
    }
}
