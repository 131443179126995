import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {PaginatedResult} from "../../models/core/paginated.result";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {
    AgentExtended,
    WholesaleAccount
} from "../../models/accounts/customer-account/wholesale-account/wholesale.account";

export class AgentService {
    api: AxiosInstance = API;

    getAll(request: PaginatedRequest): Promise<PaginatedResult<WholesaleAccount[]>> {
        return this.api.get<PaginatedResult<WholesaleAccount[]>>(
            'accounts/wholesale',
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }

    searchWholesaleAccount(value: string, skipBranch: boolean = false): Promise<WholesaleAccount[]> {
        return this.api.get<WholesaleAccount[]>(
            "accounts/wholesale/search",
            {
                params: {
                    value,
                    skipBranch
                }
            }
        ).then(x => x.data);
    }

    getByNumber(number: string): Promise<AgentExtended> {
        return this.api.get<AgentExtended>("agents/number/" + number, {allowNotFound: true}).then(x => x.data);
    }
}

export const agentService = new AgentService();
