import {SmartColumn} from "../../../../common/smart-table/smart-table.model";
import {PropertyDocumentItem, PropertyInventoryTaskPart} from "../../../../../models/property/property";
import {Translate} from "../../../../../hooks/translate.hook";
import {FieldType} from "../../../../common/smart-table/smart-table.field-config";
import {Link} from "react-router-dom";
import {SystemUser} from "../../../../../models/users/user/system.user";

export const columnFactory = (t: Translate): SmartColumn<PropertyDocumentItem>[] => {
    return [
        {
            name: 'qty',
            visibleName: t('NUMBER'),
            dataIndex: ['document', 'number'],
            type: FieldType.STRING,
        },
        {
            name: 'qty',
            visibleName: t('QTY'),
            type: FieldType.INTEGER,
            render: (qty, item) => item.previousQty +  ' => ' + qty
        },
        {
            name: 'reason',
            visibleName: t('COMMENT'),
            type: FieldType.STRING,
            render: c => c || '(none)'
        },
        {
            name: 'accountant',
            visibleName: t('USER.TITLE'),
            dataIndex: ['document', 'accountant'],
            type: FieldType.CUSTOM,
            render: (u: SystemUser) => u.firstName + ' ' + u.lastName
        },
        {
            name: 'createdAt',
            visibleName: t('DATE'),
            dataIndex: ['document', 'createdAt'],
            type: FieldType.DATETIME,
        },
        {
            name: 'taskPart',
            visibleName: t('PROPERTY.INVENTORY_TASK.TITLE'),
            dataIndex: ['document', 'taskPart'],
            type: FieldType.CUSTOM,
            render: (part: PropertyInventoryTaskPart) => {
                if (part) {
                    return <Link to={"/property/inventory-tasks/part/" + part.id + "/write-off"}>{part.task.year}</Link>
                }
                return '-';
            }
        },
    ]
}