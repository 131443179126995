import React, {useEffect, useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Modal, Select} from "antd";
import {languagesService} from "../../../../services/languages/languages.service";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";

interface UserDataEditModalProps {
    visible: boolean,
    onSubmit: (value: string) => void,
    excludedLanguages: string[]
}

export const WikiEditLanguageSelectModal: React.FC<UserDataEditModalProps> = ({visible, onSubmit, excludedLanguages}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [locales, setLocales] = useState<string[]>([]);

    useEffect(() => {
        setLoading(true);
        languagesService.getLocales()
            .then(locales => setLocales(locales.filter(locale => -1 === excludedLanguages.indexOf(locale))))
            .finally(() => setLoading(false));
    }, [excludedLanguages])

    const onSelect = (value: string) => {
        onSubmit(value);
    }

    if (loading) {
        return <LoadingSpin />
    }

    return (
        <Modal
            visible={visible}
            title={t("WIKI.EDIT-PAGE.SELECT_LANGUAGE")}
            onCancel={() => onSubmit("")}
            onOk={() => onSubmit("")}
            destroyOnClose={true}
        >
            <Select onSelect={onSelect} autoFocus={true} open={true} defaultActiveFirstOption={true} style={{width: 100}}>
                {locales.map((locale: string) => {
                    return (<Select.Option key={locale} value={locale}>{locale.toUpperCase()}</Select.Option>);
                })}
            </Select>
        </Modal>
    );
}