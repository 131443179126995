import {PaginatedResult} from "../../../models/core/paginated.result";
import {PaginatedRequest} from "../../../models/core/paginated.request";
import {parseSmartFilters, stringifySmartFilters} from "./smart-table.utils";

export class SmartTableDataHandler<T>
{
    public lastRequest: PaginatedRequest | null = null;

    public reload: (request: PaginatedRequest) => Promise<PaginatedResult<T>>;

    public onResult: (result: PaginatedResult<T>) => void;

    public onLoadingChange: (loading: boolean) => void;

    constructor(
        dataLoader: (request: PaginatedRequest) => Promise<PaginatedResult<T>>,
    ) {
        this.onResult = () => null;
        this.onLoadingChange = (loading: boolean) => null;
        this.reload = async (request) => {
            try {
                this.onLoadingChange(true);
                this.lastRequest = request;
                const data = await dataLoader(request);
                this.onResult(data);

                return data;
            }
            finally {
                this.onLoadingChange(false);
            }
        }
    }

    setOnResult(fn: (result: PaginatedResult<T>) => void) {
        this.onResult = fn;
    }

    setOnLoadingChange(fn: (loading: boolean) => void) {
        this.onLoadingChange = fn;
    }

    async setPage(page: number) {
        if (this.lastRequest && this.lastRequest.page !== page) {
            this.lastRequest.page = page;
            await this.reloadItemsWithLastRequest();
        }
    }

    async setLimit(limit: number) {
        if (this.lastRequest && this.lastRequest.limit !== limit) {
            this.lastRequest.limit = limit;
            await this.reloadItemsWithLastRequest();
        }
    }

    async setFilters(filters: [string, string | string[]][]) {
        const request = this.lastRequest;
        if (!request) {
            return;
        }

        request.clearFilters();
        filters.forEach(([name, value]) => {
            request.addFilter(name, value);
        })

        await this.reloadItemsWithLastRequest();
    }

    async reloadItemsWithLastRequest() {
        this.lastRequest && await this.reloadItems(this.lastRequest);
    }

    async reloadItems(request: PaginatedRequest) {
        await this.reload(request);
    }

    async reloadItemsWithDefaults() {
        await this.reload(new PaginatedRequest());
    }

    toURLParams(): any {
        const r = this.lastRequest;
        if (!r) {
            return {};
        }

        return {
            page    : r.page,
            limit   : r.limit,
            filters : stringifySmartFilters(r.filters),
            extra   : stringifySmartFilters(r.extraFields)
        };
    }

    fromURLParams(values: any): any {
        this.lastRequest = new PaginatedRequest(
            parseSmartFilters(values.filters || {})
        );

        this.lastRequest.page        = parseInt(values.page) || 1;
        this.lastRequest.limit       = parseInt(values.limit) || 20;
        this.lastRequest.extraFields = parseSmartFilters(values.extra || {})
    }

    setExtraFields(extraFields: Record<string, any>) {

        console.log("N_EF", extraFields);


        if (this.lastRequest) {
            this.lastRequest.extraFields = extraFields;
        }
    }
}