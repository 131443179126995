import {action, makeObservable, observable} from "mobx";

export interface Violation {
    id: string;
    violation: string;
}

export class ViolationListStore {

    @observable
    public shown: boolean = false;

    @observable
    public hidden: boolean = false;

    @observable
    public violations: Violation[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    open() {
        this.hidden = false;
    }

    @action
    hide() {
        this.hidden = true
    }

    @action
    close() {
        this.hidden = true;
        this.shown = false;
        this.violations = []
    }

    @action
    setViolations(violations: Violation[]): void {
        this.violations = violations;
        this.shown = true;
        this.hidden = false;
    }
}