import React from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../hooks/translate.hook";
import Date from "../../date/date";
import {getPropertyByPath} from "../../../utils/helpers";

interface CustomCardProps {
    data: { [key: string]: any };
    rowKeys: { key: string; label?: string; isDate?: boolean }[];
}
export const CustomCard: React.FC<CustomCardProps> = observer(
    ({ data, rowKeys }) => {
        const t = useTranslate();
        return (
            <table>
                <tbody>
                    {rowKeys.map(({ key, label, isDate }) => {
                        const value = getPropertyByPath(data, key);
                        return (
                            value ? (
                                <tr key={key}>
                                    <td
                                        style={{
                                            padding: "1px 13px 1px 1px",
                                            color: "#939393",
                                        }}
                                    >
                                        {t(label ? label : key.toUpperCase())}:
                                    </td>
                                    <td style={{ padding: "1px 3px 1px 1px" }}>
                                        {value ? (
                                            isDate ? (
                                                <Date
                                                    dateString={value}
                                                    showTime
                                                />
                                            ) : (
                                                value
                                            )
                                        ) : (
                                            "-"
                                        )}
                                    </td>
                                </tr>
                            ) : null
                        )
                    })}
                </tbody>
            </table>
        );
    }
);
