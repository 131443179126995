import React, {createRef} from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../utils/store";
import {ArticleDataStoreContext} from "../article.data.store";
import {Col, Descriptions, Row, Space, Typography} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {ArticleImageModal} from "../article-data-image-modal/modal/article.image.modal";
import placeholder from "./placeholder.png";
import "./article.data.header.scss";
import {PageHeader} from "@ant-design/pro-components";
import {useNavigate} from "react-router";

export const ArticleDataHeader: React.FC = observer(() => {
    const store = useRequiredStore(ArticleDataStoreContext);
    const t = useTranslate();
    const navigate = useNavigate();
    const imageRef = createRef<HTMLImageElement>();
    const schemaImageRef = createRef<HTMLImageElement>();

    return (
        <>
            <PageHeader
                style={{padding: 0}}
                ghost={false}
                title={<Typography.Title style={{margin: 0}}>{store.article?.name}</Typography.Title>}
                subTitle={store.article?.ean === "0" || !store.article?.ean ? null : store.article.ean}
                onBack={() => {
                    navigate("/articles");
                }}
            >
                <Row align="middle" justify="space-between" gutter={[30, 0]}>
                    <Col style={{display: "flex"}} xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <Space direction={"horizontal"} size={[65, 65]} wrap>
                            <img
                                className={"article-image"}
                                ref={imageRef}
                                src={"/product_photos/200/photos/" + store.article?.name + ".jpg"}
                                alt={store.article?.description}
                                onError={() => imageRef.current!.src = placeholder}
                                onClick={() => {
                                    if (!imageRef.current?.src.includes("placeholder")) {
                                        store.onImageClick("/product_photos/1600/photos/" + store.article!.name + ".jpg")
                                    }
                                }}
                            />
                            <img
                                className={"article-image"}
                                ref={schemaImageRef}
                                src={"/product_photos/200/schemes/" + store.article?.name + ".png"}
                                alt={store.article?.description}
                                onError={() => schemaImageRef.current!.src = placeholder}
                                onClick={() => {
                                    if (!schemaImageRef.current?.src.includes("placeholder")) {
                                        store.onImageClick("/product_photos/640/schemes/" + store.article!.name + ".png");
                                    }
                                }}
                            />
                        </Space>
                    </Col>
                    {store.article?.dimensions ? (
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                            <Descriptions
                                size={"small"}
                                layout={"horizontal"}
                                labelStyle={{color: "#858585"}}
                                column={{xs: 1, sm: 2, md: 3, lg: 3, xl: 2, xxl: 2}}
                            >
                                <Descriptions.Item
                                    label={t("HEIGHT")}>{store.article!.dimensions!.height}</Descriptions.Item>
                                <Descriptions.Item
                                    label={t("LENGTH")}>{store.article!.dimensions!.length}</Descriptions.Item>
                                <Descriptions.Item
                                    label={t("WIDTH")}>{store.article!.dimensions!.width}</Descriptions.Item>
                                <Descriptions.Item
                                    label={t("WEIGHT")}>{store.article!.dimensions!.weight} ({t("KG")})</Descriptions.Item>
                                <Descriptions.Item
                                    label={t("NETTO_WEIGHT")}>{store.article!.dimensions!.nettoWeight} ({t("KG")})</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    ) : null}
                </Row>
            </PageHeader>
            <ArticleImageModal
                opened={store.imageModalShown}
                onClose={() => store.setImageModalShown(false)}
            >
                <img
                    className="modal-image"
                    style={{maxWidth: "90%", maxHeight: "90%"}}
                    alt={store.article ? store.article.name : ""}
                    src={store.imageLink}
                />
            </ArticleImageModal>
        </>
    );
});
