import React from "react";
import {Col, DatePicker, Form, Row, Select, TimePicker} from "antd";
import {config} from "../config";
import TextArea from "antd/es/input/TextArea";

export interface LoadUnloadFieldsProps {
    loading: boolean;
    courierName: string;
}

export const loadTypes = ['earliest', 'latest', 'fixed'];

export const LoadUnloadFields: React.FC<LoadUnloadFieldsProps> = ({loading, courierName}) => {

    if (!config[courierName]?.useLoadUnload) {
        return null;
    }

    return (
        <>
            <Row gutter={[20, 10]}>
                <Col span={6}>
                    <Form.Item
                        name="loadType"
                        label="Type"
                    >
                        <Select disabled={loading}>
                            {loadTypes.map(type =>
                                <Select.Option value={type}>{type}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        name="loadDate"
                        label="Load Date"
                    >
                        <DatePicker disabled={loading}  />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        name="loadTimeFrom"
                        label="Time From"
                    >
                        <TimePicker format={'HH:mm'} disabled={loading} />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        name="loadTimeTo"
                        label="Time To"
                    >
                        <TimePicker format={'HH:mm'} disabled={loading} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        name="loadNotes"
                        label="Load notes"
                    >
                        <TextArea />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[20, 10]}>
                <Col span={6}>
                    <Form.Item
                        name="unloadType"
                        label="Type"
                    >
                        <Select disabled={loading}>
                            {loadTypes.map(type =>
                                <Select.Option value={type}>{type}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        name="unloadDate"
                        label="Unload Date"
                    >
                        <DatePicker disabled={loading}  />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        name="unloadTimeFrom"
                        label="Time"
                    >
                        <TimePicker format={'HH:mm'} disabled={loading}  />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        name="unloadTimeTo"
                        label="Time"
                    >
                        <TimePicker format={'HH:mm'} disabled={loading}  />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        name="unloadNotes"
                        label="Unload notes"
                    >
                        <TextArea />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}