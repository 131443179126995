import {AxiosInstance} from "axios";
import API from "../../../utils/api";
import {
    OrderDelivery,
    OrderDeliveryCheckoutResponse,
    OrderDeliveryCheckoutValues
} from "../../../models/orders/order-delivery/order.delivery";

export class OrderDeliveryService {
    private api: AxiosInstance = API;

    createDeliveryOrder(orders: {orders: string[]}): Promise<OrderDelivery> {
        return this.api.get<OrderDelivery>("orders/delivery", {params: orders}).then(x => x.data)
    }

    checkoutOrder(values: OrderDeliveryCheckoutValues): Promise<OrderDeliveryCheckoutResponse> {
        return this.api.post<OrderDeliveryCheckoutResponse>("orders/delivery", values).then(x => x.data);
    }
}

export const orderDeliveryService = new OrderDeliveryService();
