import React from "react";
import {observer} from "mobx-react";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {Space} from "antd";
import {AgentDataAgentAccount} from "./agent.data.agent.account";
import {AgentDataAgentAgent} from "./agent.data.agent.agent";

export const AgentDataAgent: React.FC<ComponentPropsFromRoute> = observer(({name}) => {

    return <>
        <Space size={[60, 60]} direction={"vertical"}>
            <AgentDataAgentAccount />
            <AgentDataAgentAgent />
        </Space>
    </>
});
