import React, {useMemo, useState} from "react";
import {Button, Col, Form, Input, Modal, Row, Select, Space, Table} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {WarehouseSelect} from "../../../warehouses/common/warehouse-select/warehouse-select";
import {ShippingCourierWithPrice, TestSelection} from "../../../../models/shipping/shipping";
import {branchesService} from "../../../../services/branches/branches.service";
import {useParams} from "react-router-dom";
import {columnsFactory} from "./branch.data.couriers.test.modal.columns";
import {BranchDataStoreContext} from "../branch.data.store";
import {currencies, Currency} from "../../../../models/prices/price.helper";

export interface BranchDataCouriersTestModalProps {
    onClose(): void;
}

export const BranchDataCouriersTestModal: React.FC<BranchDataCouriersTestModalProps> = observer(({onClose}) => {
    const t = useTranslate();
    const {branchId} = useParams<{ branchId: string }>();

    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm<TestSelection>();

    const [results, setResults] = useState<ShippingCourierWithPrice[] | null>(null);

    const {
        langStore,
        commonStore: { systems },
    } = useRequiredStore(RootStoreContext);

    const branchStore = useRequiredStore(BranchDataStoreContext);

    const defaultValues = {};

    const columns = useMemo(() => columnsFactory(t), []);

    return <Modal
        visible={true}
        confirmLoading={loading}
        title={t("TEST")}
        okText={t("TEST")}
        cancelText={t("CANCEL")}
        onOk={() => form.submit()}
        onCancel={() => onClose()}
        width={800}
    >
        <Form
            form={form}
            layout="vertical"
            name={"address_form"}
            initialValues={defaultValues || {}}
            onFinish={() =>
                form.validateFields().then((values) => {
                    setLoading(true);
                    values = {...values, branch: branchId!}
                    branchesService.testCourierSelection(values).then(results => {
                        setResults(results);
                        setLoading(false);
                    })
                })
            }
        >
            <Row gutter={10}>
                <Col span={8}>
                    <Form.Item
                        name="warehouse"
                        label={t("WAREHOUSES.ID")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <WarehouseSelect />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="system"
                        label={t("SYSTEM")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            placeholder={t("SYSTEM")}
                            disabled={loading}
                        >
                            {systems.map((system) => (
                                <Select.Option value={system.id!} key={system.id!}>
                                    {system.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="country"
                        label={t("COUNTRY")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            allowClear
                            loading={loading}
                            placeholder={t("COUNTRIES")}
                            maxTagCount={3}
                        >
                            {langStore.countries.map(country => (
                                <Select.Option key={country.id!} value={country.id!}>
                                    {country.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="weight"
                        label={t("WEIGHT")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input
                            addonAfter={"kg"}
                            disabled={loading}
                            placeholder={t("WEIGHT")}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="price"
                        label={t("PRICE")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input
                            addonAfter={currencies[branchStore.branch!.currencyCode.toUpperCase() as Currency]}
                            disabled={loading}
                            placeholder={t("PRICE")}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="zipCode"
                        label={t("ZIP_CODE")}
                    >
                        <Input
                            disabled={loading}
                            placeholder={t("ZIP_CODE")}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>

        <p>Please fill all fields and press to "Test" button</p>

        {results ? (
            <>
                <h2>Results:</h2>
                <Table
                    columns={columns}
                    dataSource={results}
                    pagination={false}
                />
            </>
        ) : null}
    </Modal>
})