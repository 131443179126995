import {action, makeObservable, observable} from "mobx";

export class ExpandedTableStore {
    @observable
    public columnsSettingsModalShown: boolean = false;

    @observable
    public selectedColumns: string[] = !this.pageKey ? this.defaultColumns.map(item => item.value).filter(item => !this.defaultHiddenColumns?.includes(item)) : (localStorage[this.pageKey] ? JSON.parse(localStorage[this.pageKey]) : this.defaultColumns.map(item => item.value).filter(item => !this.defaultHiddenColumns?.includes(item)));

    constructor(
        public defaultColumns: {title: string, value: string}[],
        public pageKey?: string,
        public defaultHiddenColumns?: string[]
    ) {
        makeObservable(this);
        this.openColumnsSettingsModal = this.openColumnsSettingsModal.bind(this);
        this.closeColumnsSettingsModal = this.closeColumnsSettingsModal.bind(this);
        this.selectColumns = this.selectColumns.bind(this);
    }

    @action
    private setColumnsSettingsModalShown(value: boolean): void {
        this.columnsSettingsModalShown = value;
    }

    @action
    private setSelectedColumns(value: string[]): void {
        this.selectedColumns = value;
    }

    public selectColumns({columns}: {columns: string[]}): void {
        this.setSelectedColumns(columns);
        if (this.pageKey) {
            localStorage.setItem(this.pageKey, JSON.stringify(columns))
        }
    }

    public openColumnsSettingsModal(): void {
        this.setColumnsSettingsModalShown(true);
    }

    public closeColumnsSettingsModal(): void {
        this.setColumnsSettingsModalShown(false);
    }
}