import React from "react";
import {StatusTag} from "../../common/status/status";
import {DeclarationStatus, statusColor} from "../../../models/declarations/declaration";

interface DeclarationStatusProps {
    status: DeclarationStatus;
    large?: boolean;
}
export const DeclarationsStatus: React.FC<DeclarationStatusProps> = ({status, large = false}) => {
    return (
        <StatusTag<DeclarationStatus> status={status} colorsMap={statusColor} large={large} prefix="DECLARATIONS.DECLARATION.STATUS"/>
    )
}
