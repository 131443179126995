import React, {useState} from "react";
import {observer} from "mobx-react";
import {UserGroupsStore, UserGroupsStoreContext} from "./user.groups.store";

import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {ExtendedSwitch} from "../../../core/router/extended-switch/extended.switch";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {useLocation} from "react-router-dom";

interface UserGroupsProps extends ComponentPropsFromRoute {}

export const UserGroups: React.FC<UserGroupsProps> = observer(({routeChildren}) => {
    const {pageStore} = useRequiredStore(RootStoreContext);
    const [store] = useState(() => new UserGroupsStore(pageStore));

    return (
        <UserGroupsStoreContext.Provider value={store}>
            {routeChildren ? <ExtendedSwitch routers={routeChildren}/> : null}
        </UserGroupsStoreContext.Provider>
    );
});
