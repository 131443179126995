import React, {useState} from "react";
import {observer} from "mobx-react";
import {Button, Card, Popconfirm, Space, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {AgentDataStoreContext} from "../agent.data.store";
import {AgentDataAgreementsModal} from "./agent.data.agreements.modal";
import {
    CustomerAccountAgreement
} from "../../../../models/accounts/customer-account/agreement/customer.account.agreement";

interface AgentDataAgreementsCardProps {
    onAdd: () => void
}

export const AgentDataAgreementsCard: React.FC<AgentDataAgreementsCardProps> = observer(({onAdd}) => {
    const t = useTranslate();
    const store = useRequiredStore(AgentDataStoreContext);

    const [editableAgreement, setEditableAgreement] = useState<CustomerAccountAgreement | null>(null);

    return (
        <Card className={"card"}>
            <Space direction={"vertical"}>
                {store.account?.agreements.length ? (
                    store.account?.agreements.map(agreement => (
                        <div className="agreement-row" key={agreement.id}>
                            <p style={{marginBottom: "auto", display: "block", whiteSpace: "pre-wrap"}}>
                                {t("AGREEMENT." + agreement.category.toUpperCase()) + ": " + agreement.content}
                            </p>
                            <Space className="agreement-buttons-col" size={[5, 5]} align="center">
                                <Tooltip placement="topLeft" title={t("EDIT")}>
                                    <Button
                                        className={"card-btn btn"}
                                        type="default"
                                        icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                                        onClick={() => setEditableAgreement(agreement)}
                                    />
                                </Tooltip>
                                <Popconfirm
                                    key={3}
                                    title={t("ARE_YOU_SURE")}
                                    onConfirm={() => store.removeAgreement(agreement)}
                                    placement="top"
                                    okText={t("YES")}
                                    cancelText={t("NO")}
                                >
                                    <Tooltip placement="topRight" title={t("REMOVE")}>
                                        <Button
                                            className={"card-btn btn-remove btn"}
                                            type="default"
                                            icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                                        />
                                    </Tooltip>
                                </Popconfirm>
                            </Space>
                        </div>
                    ))
                ) : (<>{t("NO_DATA")}</>)}
                {!!editableAgreement ? (
                    <AgentDataAgreementsModal
                        visible={true}
                        onClose={() => setEditableAgreement(null)}
                        agreement={editableAgreement || undefined}
                        onSubmit={store.updateAgreement}
                    />
                ) : null}
            </Space>
        </Card>
    );
});
