import React from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Parcel, ParcelItem} from "../../../../models/parcels/shipment";
import {Table} from "antd";
import {ColumnsType} from "antd/es/table";

interface InvoiceDataHeaderProps {
    parcel: Parcel;
    showInfo: boolean;
}

export const ShipmentBox: React.FC<InvoiceDataHeaderProps> = ({parcel, showInfo}) => {
    const t = useTranslate();

    const columns: ColumnsType<ParcelItem> = [
        {
            title: t("NAME"),
            dataIndex: "name",
            key: "name",
            render: name => <a href={""}>{name}</a>
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "description",
        },
        {
            title: t("QTY"),
            dataIndex: "qty",
            key: "qty",
        },
        {
            title: t("WEIGHT"),
            dataIndex: "grossWeight",
            key: "grossWeight",
        }
    ]

    return (
        <Table
            columns={columns}
            dataSource={parcel.items}
            pagination={false}
        ></Table>
    );
}