import React from "react";
import {StatusTag} from "../../common/status/status";
import {ClaimStatus, claimStatusColor} from "../../../models/claims/claims";

interface ClaimStatusProps {
    status: ClaimStatus;
    large?: boolean;
}
export const ClaimsStatus: React.FC<ClaimStatusProps> = ({status, large = false}) => {
    return (
        <StatusTag<ClaimStatus> status={status} colorsMap={claimStatusColor} large={large} prefix="CLAIMS.CLAIM.STATUS"/>
    )
}
