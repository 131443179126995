import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Button, Col, DatePicker, Form, Input, message, Modal, notification, Radio, Row, Select, Space} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Return} from "../../../../../models/documents/returns/returns";
import {returnsService} from "../../../../../services/documents/returns/returns.service";
import {OrderDetailsItem} from "../../../../../models/orders/order-data/order.data";
import dayjs from "dayjs";

interface OrderDataCreateReturnModalProps {
    item: OrderDetailsItem,
    onClose: () => void,
    onCompleted: (document: Return) => void
}
export const OrderDataCreateReturnModal: React.FC<OrderDataCreateReturnModalProps> = observer(({item, onClose, onCompleted}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const [relatedReturnDocuments, setRelatedReturnDocuments] = useState<Return[]>([]);

    useEffect(() => {
        returnsService.getRelatedForOrderItem(item.id).then(invoices => setRelatedReturnDocuments(invoices))
    }, [item])

    const complete = (document: Return) => {
        onCompleted(document);
        onClose();
    };

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("DOCUMENTS.RETAIL.RETURN.CREATE")}
            okText={t("CREATE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"dispatch_modal"}
                initialValues={{
                    qty: item.qtyRequested, type: "new"
                }}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        const dto = {qty: parseInt(values.qty), item: item.id};

                        setLoading(true);

                        if (values.type === 'exiting') {
                            returnsService.addItemToCreditInvoice(values.invoice, dto)
                                .then(complete)
                                .finally(() => setLoading(false));
                        } else {
                            returnsService.createWithSingleItem(dto)
                                .then(complete)
                                .finally(() => setLoading(false));
                        }
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="type"
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <Radio.Group>
                                <Radio.Button value={"new"}>Create new</Radio.Button>
                                <Radio.Button value={"exiting"}>Add to exiting return</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('type') === 'exiting' ? (
                                    <Form.Item
                                        name="invoice"
                                        label="Return"
                                        rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                                    >
                                        <Select>
                                            {relatedReturnDocuments.map(document => (
                                                <Select.Option value={document.id}>{dayjs(document.date).format("DD-MM-YYYY")} - {document.docNumber}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="qty"
                            label={t("QTY")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    )
})