import React, {useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Button, Form, Input, Typography} from "antd";
import {useNavigate} from "react-router";
import {plannerService} from "../../../../services/planner/planner.service";
import {MarkdownTextarea} from "../../../core/markdown/markdown-textarea/markdown-textarea";
import {UIInputWidgetDepartment} from "../../ui/input/widget/department/ui.input.widget.department";
import {UIInputWidgetPriority} from "../../ui/input/widget/priority/ui.input.widget.priority";

export const PlannerTasksCreateIssue: React.FC<ComponentPropsFromRoute> = () => {

    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const navigate = useNavigate();

    const initialValues = {}

    return (
        <>
            <Typography.Title level={2}>{t("PLANNER.TASK.NEW_ISSUE.TITLE")}</Typography.Title>
            <Form
                form={form}
                layout="vertical"
                name={"planner_task_form"}
                initialValues={initialValues}
                onFinish={() => form
                    .validateFields()
                    .then(async values => {
                        setLoading(true);
                        try {
                            const createdTask = await plannerService.createIssue(values);
                            navigate('/planner/tasks/' + createdTask.id);
                        } catch (e: any) {
                            form.setFields(e.response?.data?.fields);
                        } finally {
                            setLoading(false);
                        }
                    })
                }
            >
                <Form.Item
                    label={t("PLANNER.TASK.FIELDS.NAME")}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <Input disabled={loading} />
                </Form.Item>
                <Form.Item
                    label={t("PLANNER.TASK.FIELDS.DESCRIPTION")}
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <MarkdownTextarea
                        fileUploader={f => plannerService.uploadFile(f)}
                        height={"400px"}
                        disabled={loading}
                    />
                </Form.Item>
                <Form.Item
                    label={t("PLANNER.TASK.FIELDS.DEPARTMENTS")}
                    name="department"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <UIInputWidgetDepartment />
                </Form.Item>
                <Form.Item
                    label={t("PLANNER.TASK.FIELDS.PRIORITY")}
                    name="priority"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <UIInputWidgetPriority />
                </Form.Item>
                <Form.Item>
                    <Button
                        type={"primary"}
                        onClick={() => form.submit()}
                        disabled={loading}
                    >
                        {t("SUBMIT")}
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
