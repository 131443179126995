import React, {useState} from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {AgentDataStoreContext} from "../agent.data.store";
import {Button, Row, Tooltip, Typography} from "antd";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import {Article} from "../../../../models/articles/article";
import {Amount} from "../../../../models/prices/price";
import {displayPrice} from "../../../../models/prices/price.helper";
import {AgentDataCustomPriceModal} from "./agent-data-custom-price-modal/agent.data.custom.price.modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {FilterKey} from "../../../../models/filtered-table/filtered.table.props";


const selectSavedValues = (row: any) => ({amount: row.amount});
const selectEditValues = (row: any) => ({amount: row.amount.value});

interface AgentDataCustomPricesProps {
    pageName: string
}
export const AgentDataCustomPrices: React.FC<AgentDataCustomPricesProps> = observer(({pageName}) => {
    const t = useTranslate();
    const store = useRequiredStore(AgentDataStoreContext);

    const [modalShown, setModalShown] = useState<boolean>(false);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id"
        },
        {
            title: t("ARTICLES.NAME"),
            dataIndex: "article",
            key: "article",
            render: (item: Article) => item.name.toUpperCase() !== "DELIVERY" ?  <Link to={"/articles/" + item.name} style={{whiteSpace: "nowrap"}}>{item.name}</Link> : item.name
        },
        {
            title: t("PRICE"),
            dataIndex: "amount",
            key: "amount",
            editable: true,
            render: (item: Amount) => <>{displayPrice(item)}</>
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            width: 100
        }
    ];

    const columnsConfigs: {key: string, title: string}[] = [
        {
            title: "ID",
            key: "id",
        },
        {
            title: "ARTICLES.NAME",
            key: "article"
        },
        {
            title: "PRICE",
            key: "amount",
        }
    ];



    const filters: FilterKey[] = [
        {
            key: "article",
            label: t("ARTICLES.NAME"),
            type: "string"
        },
        {
            key: "value",
            label: t("TOTAL_AMOUNT"),
            type: "int"
        }
    ];

    return (
        <>
            <Row>
                <Typography.Title level={3}>{t("CLIENT.CUSTOM_PRICE.TITLE")}</Typography.Title>
                <Tooltip key={2} placement="topLeft" title={t("ADD")}>
                    <Button
                        className="btn"
                        style={{margin: "3px 0 0 5px"}}
                        onClick={() => setModalShown(true)}
                        type="default"
                        icon={<FontAwesomeIcon icon={["fas", "plus"]}/>}
                    />
                </Tooltip>
            </Row>

            <FilteredTable
                rowKey={"id"}
                columns={columns}
                filterKeys={filters}
                dataHandler={store.dataHandler}
                isEditable={true}
                editableRowKey={"article"}
                getEditValues={selectEditValues}
                getSaveValues={selectSavedValues}
                onSave={store.updateCustomPrice}
                onDelete={store.removeCustomPrice}
                columnsConfigs={{columns: columnsConfigs, pageKey: pageName}}
            />
            <AgentDataCustomPriceModal
                visible={modalShown}
                onClose={() => setModalShown(false)}
            />
        </>
    )
});
