import React, { useState } from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import Date from "../../../date/date";
import {
    HistoryArticle,
    HistoryArticleWarehouse,
} from "../../../../models/articles/articles.history";

import FilteredTable from "../../../common/filtered-table/filtered.table";
import { articlesHistoryService } from "../../../../services/articles/articles/articles.history.service";
import { Link } from "react-router-dom";
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Row,
    Select, Space,
    Typography,
} from "antd";
import { useRequiredStore } from "../../../../utils/store";
import { RootStoreContext } from "../../../../stores/root/root.store";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
import { FilterKey } from "../../../../models/filtered-table/filtered.table.props";
import { Export } from "../../../common/export/export";
import { ExportFormat } from "../../../../stores/common/common.store";
import {ArticlesLinks} from "../articles-links/articles.links";
import dayjs from "dayjs";

interface ArticlesHistoryListProps extends ComponentPropsFromRoute {}
export const ArticlesHistoryList: React.FC<ArticlesHistoryListProps> = observer(
    ({ name }) => {
        const {
            commonStore: { warehouses },
        } = useRequiredStore(RootStoreContext);
        const t = useTranslate();
        const [modalShown, setModalShown] = useState<boolean>(false);

        const [dates, setDates] = useState<any[]>([]);
        const [hackValue, setHackValue] = useState<any>();
        const [value, setValue] = useState();

        const disabledDate = (current: any): boolean => {
            if (!dates || dates.length === 0) {
                return false;
            }

            const tooLate =
                (dates[0] && current.diff(dates[0], "days") < 14) ||
                (dates[0] && current.diff(dates[0], "days") > 365);

            const tooEarly =
                (dates[1] && dates[1].diff(current, "days") < 14) ||
                (dates[1] && dates[1].diff(current, "days") > 365);

            return tooEarly || tooLate;
        };

        const onOpenChange = (open: boolean) => {
            if (open) {
                setHackValue([]);
                setDates([]);
            } else {
                setHackValue(undefined);
            }
        };

        const closeExportModal = (): void => {
            setModalShown(false);
        };

        const columns = [
            {
                title: t("CODE"),
                dataIndex: "article",
                key: "article",
                render: (item: HistoryArticle) => item.name.toUpperCase() !== "DELIVERY" ? (
                    <Link
                        style={{ whiteSpace: "nowrap" }}
                        to={"/articles/" + item.name}
                    >
                        {item.name}
                    </Link>
                ) : item.name,
            },
            {
                title: t("WAREHOUSES.NAME"),
                dataIndex: "warehouse",
                key: "warehouse",
                render: (item: HistoryArticleWarehouse) => <>{item.name}</>,
            },
            {
                title: t("QUANTITY"),
                dataIndex: "amount",
                key: "amount",
                className: "bold",
            },
            {
                title: t("CREATED_AT"),
                dataIndex: "createdAt",
                key: "createdAt",
                render: (date: string) => (
                    <Date dateString={date} showTime withSeconds />
                ),
            },
        ];

        const columnsConfigs: { title: string; key: string }[] = [
            {
                title: "CODE",
                key: "article",
            },
            {
                title: "WAREHOUSES.NAME",
                key: "warehouse",
            },
            {
                title: "QUANTITY",
                key: "amount",
            },
            {
                title: "CREATED_AT",
                key: "createdAt",
            },
        ];

        const filters: FilterKey[] = [
            {
                key: "article",
                label: t("CODE"),
                type: "string",
            },
        ];

        if (warehouses) {
            filters.push({
                key: "warehouses",
                label: t("WAREHOUSES.NAME"),
                type: "select",
                options: warehouses.map(({ id, name }) => ({
                    label: name,
                    value: id,
                })),
            });
        }

        filters.push(
            {
                key: "amount",
                label: t("QUANTITY"),
                type: "int",
            },
            {
                key: "createdAt",
                label: t("CREATED_AT"),
                type: "date",
            }
        );

        const exportDefaultValues = {
            date: [dayjs().add(-1, "M"), dayjs()],
            warehouses: [warehouses[0]?.id],
            extraMode: false,
        };

        return (
            <Space direction="vertical" size={[50, 50]}>
                <ArticlesLinks/>
                <Space direction="vertical" size={[0, 0]}>
                    <Row justify="space-between" wrap>
                        <Typography.Title level={3}>
                            {t("ARTICLES.HISTORY")}
                        </Typography.Title>
                        <Button type="primary" onClick={() => setModalShown(true)}>
                            {t("ARTICLES.DOWNLOAD_AVAILABILITY_STATS")}
                        </Button>
                    </Row>
                    <FilteredTable
                        reloadOnLngChange
                        filterKeys={filters}
                        columns={columns}
                        columnsConfigs={{ columns: columnsConfigs, pageKey: name }}
                        loadDataCallback={(request) =>
                            articlesHistoryService.getAll(request)
                        }
                    />
                </Space>
                <Export
                    title={"ARTICLES.DOWNLOAD_AVAILABILITY_STATS"}
                    withBasicExportFields
                    isShown={modalShown}
                    config={{
                        link: "/api/v3/reports/article-available-periods",
                    }}
                    additionalDefaultValues={exportDefaultValues}
                    allowedFormats={[ExportFormat.CSV]}
                    onClose={closeExportModal}
                >
                    <Col span={24}>
                        <Form.Item
                            label={t("DATE")}
                            name="date"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "FROM.ERROR.PLEASE_SELECT_VALUE"
                                    ),
                                },
                            ]}
                        >
                            <DatePicker.RangePicker
                                showNow
                                style={{ width: "100%" }}
                                value={hackValue || value}
                                disabledDate={disabledDate}
                                onCalendarChange={(val) => setDates(val as any)}
                                onChange={(val) => setValue(val as any)}
                                onOpenChange={onOpenChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={t("WAREHOUSES.TITLE")}
                            name="warehouses"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "FROM.ERROR.PLEASE_SELECT_VALUE"
                                    ),
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                mode="multiple"
                                allowClear={false}
                                placeholder={t("WAREHOUSES.TITLE")}
                                optionFilterProp={"children"}
                            >
                                {warehouses.map(({ id, name }) => (
                                    <Select.Option key={id} value={id}>
                                        {name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="extraMode" valuePropName="checked">
                            <Checkbox>
                                {t(
                                    "ARTICLES.DOWNLOAD_AVAILABILITY_STATS_EXTRA_MODE"
                                )}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Export>
            </Space>
        );
    }
);
