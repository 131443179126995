import {useTranslation} from "react-i18next";
import {i18n} from "i18next";
import {useMemo} from "react";

export interface Translate {
    (key: string, options?: any): string;
    i18n: i18n
    ready: boolean;
}

export const useTranslate = (): Translate => {

    const {t, i18n, ready} = useTranslation("translation", {useSuspense: false});

    return useMemo(() => {
        const result = (key: string, options?: any): string => {
            return t(key, options)
        }

        result.i18n = i18n;
        result.ready = ready;

        return result;
    }, [t, i18n, ready])
}