import React, {useState} from "react";
import {Space, Steps, Typography} from "antd";
import {useTranslate} from "../../../hooks/translate.hook";
import {DeclarationSaveStep} from "./steps/declaration.save.step";
import {DeclarationUpload as Params, UploadedDeclarationResponse} from "./declaration.upload.model";
import {DeclarationUploadStep} from "./steps/declaration.upload.step";

export const DeclarationUpload: React.FC = () => {
    const t = useTranslate();

    const [params, setParams] = useState<Params | null>(null);
    const [result, setResult] = useState<UploadedDeclarationResponse | null>(null);
    const [step, setStep] = useState<number>(0);
    const [namePrefix, setNamePrefix] = useState<string>("");

    const onUploaded = (params: Params, result: UploadedDeclarationResponse, namePrefix: string) => {
        setParams(params)
        setResult(result)
        setStep(1);
        setNamePrefix(namePrefix);
    }

    return (
        <Space size={[20, 20]} direction={"vertical"}>
            <Space direction={"horizontal"} size={[40, 40]}>
                <Steps current={step} direction={"vertical"} style={{width: "250px"}}>
                    <Steps.Step key={0} title={"Create"} description={"Upload CSV file"} />
                    <Steps.Step key={1} title={"SAVE"} description={"Select and save declarations"} />
                </Steps>
                <Space direction={"vertical"} size={[20, 20]} style={{width: 1050}}>
                    <Typography.Title level={2} style={{ margin: 0 }}>
                        {t('DECLARATIONS.UPLOAD.TITLE')}
                    </Typography.Title>
                    {step === 0 ? (
                        <DeclarationUploadStep initialValues={params} onUploaded={onUploaded} />
                    ) : (
                        <DeclarationSaveStep params={params!} result={result!} onGoBack={() => setStep(0)} namePrefix={namePrefix} />
                    )}
                </Space>
            </Space>
        </Space>
    )
};