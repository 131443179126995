import React from "react";
import {observer} from "mobx-react";
import {Space} from "antd";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {ExtendedSwitch} from "../../core/router/extended-switch/extended.switch";

interface WarehousesProps extends ComponentPropsFromRoute {
}

export const Warehouses: React.FC<WarehousesProps> = observer(({routeChildren}) => {

    return (
        <Space size={[50, 50]} direction={"vertical"} className="table-wrapper">
            {routeChildren ? <ExtendedSwitch routers={routeChildren}/> : null}
        </Space>
    );
});
