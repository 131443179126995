import React, {useEffect, useState} from "react";
import {PlannerTask, PlannerTaskComment} from "../../../../../models/planner/planner";
import {plannerService} from "../../../../../services/planner/planner.service";
import {Pagination as P} from "../../../../../models/core/paginated.result";
import {LoadingSpin} from "../../../../common/loading-spin/loading.spin";
import {Pagination, Space} from "antd";
import {TaskComment} from "../comment/task.comments.comment";
import {TaskNewComment} from "../new-comment/task.comments.new-comment";

export enum TaskCommentsListType {
    COMMENTS,
    COMMENTS_WITH_A_REPORTER
}

interface TaskCommentsProps {
    task: PlannerTask;
    type: TaskCommentsListType;
}

const CFG: Record<TaskCommentsListType, [(id: number) => Promise<any>, (id: number, value: any) => Promise<any>]> = {
    [TaskCommentsListType.COMMENTS]: [
        (n) => plannerService.getTaskComments(n),
        (n, v) => plannerService.addTaskComment(n, v)
    ],
    [TaskCommentsListType.COMMENTS_WITH_A_REPORTER]: [
        (n) => plannerService.getTaskIssueComments(n),
        (n, v) => plannerService.addTaskIssueComment(n, v)
    ],
}

export const TaskCommentsList: React.FC<TaskCommentsProps> = ({task, type}) => {

    const [comments, setComments] = useState<PlannerTaskComment[]>([]);
    const [pagination, setPagination] = useState<P>();

    const [getTaskComments, addTaskComment] = CFG[type]

    useEffect(() => {
        reload();
    }, [])

    const reload = async () => {
        await getTaskComments(task.id).then(result => {
            setComments(result.items)
            setPagination(result.meta)
        })
    }

    const onNewComment = async (value: string) => {
        await addTaskComment(task.id, value);
        await reload();
    }

    if (!pagination) {
        return <LoadingSpin />
    }

    return (
        <Space direction={"vertical"} size={[20, 20]}>
            <TaskNewComment
                task={task}
                onSubmit={onNewComment}
            />
            {comments.map(comment => (
                <TaskComment
                    comment={comment}
                />
            ))}
            <Pagination
                simple
                defaultCurrent={pagination.page}
                total={pagination.totalPages || 1}
            />
        </Space>
    )
}