import {AxiosInstance} from "axios";
import API from "../../utils/api";


export class DocumentTypesService {
    private api: AxiosInstance = API;

    async getDocumentTypes(): Promise<string[]> {
        return ["ee_default", "ee_default_with_name", "ee_default_with_oc", "ee_uk", "ee_autodoc", "bg_default", "bg_with_order", "bg_export", "de_default", "de_autodoc", "de_autodoc_dropship", "sr_default", "ae_default"];
    }

    async getInvoiceDocumentTypes(): Promise<string[]> {
        return ["regular", "mr_auto"];
    }

    async getPackingListDocumentTypes(): Promise<string[]> {
        return ["default"];
    }
}

export const documentTypesService = new DocumentTypesService();
