import React from "react";
import {Modal, Input, Form, Space, Select, Button, Row, Col, Tooltip} from "antd";
import {LanguagesModel} from "../../../../models/languages/languages";
import {FC} from "react";
import {KeyRequest} from "../../../../models/languages/key.request";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useTranslate} from "../../../../hooks/translate.hook";
import {toJS} from "mobx";
import {map, chain, isNil, difference} from "lodash";
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';

import "../list/languages.scss";
import "./languages.modal.scss"
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";

interface KeyEditModalProps {
    item?: LanguagesModel;
    locales: string[];
    scopes: string[];
    isModalShown: boolean;
    onSuccess: (data: KeyRequest, item?: LanguagesModel) => Promise<any>;
    onClose: () => void;
}

export const KeyEditModal: FC<KeyEditModalProps> = ({item, scopes, locales, isModalShown, onSuccess, onClose}) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const t = useTranslate();

    let readOnlyLocales: string[] = [];
    let defaultValue: KeyRequest | any = {};

    if (item) {
        readOnlyLocales = map(item.translations, t => t.locale);
        defaultValue = {
            code: item.code,
            description: item.description,
            translations: toJS(item.translations),
            scopes: toJS(item.scopes)
        }
    } else {
        defaultValue = {
            code: "",
            translations: [{value: "", locale: "en"}],
            scopes: []
        }
    }

    const getNotUsedLocales = (): string[] => {
        const usedLocales = chain(form.getFieldValue("translations"))
            .omitBy((value) => isNil(value) || isNil(value.locale))
            .map(value => value.locale)
            .value();

        return difference(toJS(locales), usedLocales);
    }

    return (
        <Modal
            visible={isModalShown}
            title={item ? t("LANGUAGES.EDIT_TRANSLATION") : t("LANGUAGES.ADD_TRANSLATION")}
            okText={item ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
            onOk={() => form.submit()}
            confirmLoading={loading}
            width={500}
        >
            <Form
                layout="vertical"
                form={form}
                initialValues={defaultValue}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        onSuccess(values, item).then(
                            () => {
                                !item && form.resetFields();
                                return onClose();
                            },
                            (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                            .then(() => {}, (e) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Form.Item
                    name="code"
                    label={t("KEY")}
                    rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                >
                    <Input
                        type="text"
                        placeholder={t("KEY")}
                    />
                </Form.Item>
                <Form.Item
                    name={'description'}
                    label={t("LANGUAGES.CONTEXT_DESCRIPTION")}
                    
                >
                    <Input.TextArea
                        maxLength={255}
                        autoSize={{minRows: 2, maxRows: 6}}
                        showCount={{
                            formatter: ({ count, maxLength }) => count + "/" + maxLength
                        }}
                        placeholder={t("LANGUAGES.CONTEXT_DESCRIPTION")}
                    />
                </Form.Item>
                <Form.List
                    name="translations"
                    rules={[{
                        validator: async (_, names) => {
                            if (!names || names.length === 0) {
                                return Promise.reject(new Error(t('FROM.ERROR.PLEASE_ENTER_VALUE')));
                            }
                        }
                    }]}
                >
                    {(fields, {add, remove}) => (
                        <>
                            {fields.map(field => (
                                <>
                                    <Row gutter={10} key={field.key}>
                                        <Col span={4}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(oldValue, newValue) => oldValue.changeNumberEnabled !== newValue.changeNumberEnabled}
                                            >
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'locale']}
                                                        
                                                        rules={[{
                                                            required: true,
                                                            message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                                                        }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            optionFilterProp={"children"}
                                                            disabled={readOnlyLocales.indexOf(form.getFieldValue(['translations', field.key])) !== -1}
                                                        >
                                                            {getNotUsedLocales().map(item => (
                                                                <Select.Option key={item} value={item}>
                                                                    {item.toUpperCase()}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={17}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'value']}
                                                
                                                rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                                            >
                                                <Input placeholder={t("TRANSLATION")}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Tooltip placement="topRight" title={t('DELETE')}>
                                                <Button type="default" danger onClick={() => remove(field.name)}
                                                        icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                                                        style={{marginLeft: 'auto'}}
                                                        className="btn-flex-center"
                                                />
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block
                                        icon={<FontAwesomeIcon icon={["fas", "plus-circle"]}/>}>
                                    {t("LANGUAGES.ADD_TRANSLATION")}
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item
                    name="scopes"
                    label={t("SCOPES")}
                    rules={[{required: true, message: t('FROM.ERROR.PLEASE_SELECT_VALUE')}]}
                >
                    <Select
                        showSearch
                        allowClear
                        optionFilterProp={"children"}
                        placeholder={t("SCOPE")}
                        mode="multiple"
                        value={scopes}
                        style={{width: 200}}
                    >
                        {scopes.map((scope, index) => (
                            <Select.Option key={index} value={scope}>{scope}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
}
