import React, {PropsWithChildren, useState} from "react";
import {Button, Dropdown, Menu, Space} from "antd";
import {Link} from "react-router-dom";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {OrderData} from "../../../../../../models/orders/order-data/order.data";
import {Export} from "../../../../../common/export/export";
import {DocumentDataMasterOrder} from "../../../../../../models/documents/master-order";

interface OrderDataDocumentButtonsProps {
    order: OrderData | DocumentDataMasterOrder | undefined | null;
    includedOrdersIds: number[];
    masterOrderId: number | null;
    showIncludedOrdersList?: boolean;
    showMasterOrderBtn?: boolean;
    showProformas?: boolean;
    showPackingLists?: boolean;
    showInvoices?: boolean;
    showMergedInvoices?: boolean;
}

export const OrderDataDocumentButtons: React.FC<PropsWithChildren<OrderDataDocumentButtonsProps>> = ({
        order,
        includedOrdersIds = [],
        masterOrderId,
        showIncludedOrdersList = true,
        showMasterOrderBtn = true,
        showProformas = true,
        showPackingLists = true,
        showInvoices = true,
        showMergedInvoices = true,
        children}) => {
    const t = useTranslate();

    const [exportInvoiceId, setExportInvoiceId] = useState<number | null>(null);

    return (
        <>
            <Space className={"order-data-header-buttons"} direction={"horizontal"} size={[20, 20]} wrap>
                {showIncludedOrdersList && includedOrdersIds.length > 0 ? (
                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu style={{maxHeight: 200, overflowY: "scroll"}}>
                                {includedOrdersIds.map((item) => (
                                    <Menu.Item key={item}>
                                        <Link
                                            style={{textAlign: "left"}}
                                            className={"ant-btn ant-btn-link"}
                                            to={"/orders/" + item}
                                        >
                                            {item}
                                        </Link>
                                    </Menu.Item>
                                ))}
                            </Menu>
                        }
                    >
                        <Button type="primary">{t("ORDERS.LIST")}</Button>
                    </Dropdown>
                ) : null}

                {showMasterOrderBtn && order?.mergedOrderId && (order?.mergedOrderId !== order?.id) ? (
                    <Link className="ant-btn ant-btn-primary"
                          to={"/orders/" + (masterOrderId || order?.mergedOrderId)}>{t("ORDERS.ORDER.MASTER_ORDER")}</Link>
                ) : null}

                {children}

                {!showProformas || !order?.documents.proforma ? null : order.documents.proforma.length > 1 ? (
                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                {order.documents.proforma.map(proforma => (
                                    <Menu.SubMenu key={proforma.id} title={proforma.docNumber}>
                                        <Menu.Item style={{padding: "4px 15px"}}>
                                            <Link
                                                style={{textAlign: "left"}}
                                                className={"ant-btn ant-btn-link"}
                                                to={"/proformas/" + proforma.id}
                                            >
                                                {t("ORDERS.ORDER.VIEW.PROFORMA")}
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item style={{padding: "4px 15px"}}>
                                            <a
                                                target="_blank"
                                                href={"/documents/" + proforma.id}
                                            >
                                                {t("DOCUMENTS.FILES.SHOW_PDF")}
                                            </a>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                ))}
                            </Menu>
                        }
                    >
                        <Button> {t("ORDERS.ORDER.VIEW.PROFORMA")}</Button>
                    </Dropdown>
                ) : (
                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                <Menu.Item style={{padding: "4px 15px"}}>
                                    <Link
                                        style={{textAlign: "left"}}
                                        className={"ant-btn ant-btn-link"}
                                        to={"/proformas/" + order?.documents.proforma[0].id}
                                    >
                                        {t("ORDERS.ORDER.VIEW.PROFORMA")}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item style={{padding: "4px 15px"}}>
                                    <a
                                        target="_blank"
                                        href={"/documents/" + order?.documents.proforma[0].id}
                                    >
                                        {t("DOCUMENTS.FILES.SHOW_PDF")}
                                    </a>
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button> {t("ORDERS.ORDER.VIEW.PROFORMA")}</Button>
                    </Dropdown>
                )}
                {!showPackingLists || !order?.documents.packing_list ? null : order.documents.packing_list.length > 1 ? (
                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                {order.documents.packing_list.map(packingList => (
                                    <Menu.SubMenu key={packingList.id} title={packingList.docNumber}>
                                        <Menu.Item style={{padding: "4px 15px"}}>
                                            <Link
                                                style={{textAlign: "left"}}
                                                className={"ant-btn ant-btn-link"}
                                                to={"/packing-lists/" + packingList.id}
                                            >
                                                {t("ORDERS.ORDER.VIEW.PACKING_LIST")}
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item style={{padding: "4px 15px"}}>
                                            <a
                                                target="_blank"
                                                href={"/documents/" + packingList.id}
                                            >
                                                {t("DOCUMENTS.FILES.SHOW_PDF")}
                                            </a>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                ))}
                            </Menu>
                        }
                    >
                        <Button> {t("ORDERS.ORDER.VIEW.PACKING_LIST")}</Button>
                    </Dropdown>
                ) : (
                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                <Menu.Item style={{padding: "4px 15px"}}>
                                    <Link
                                        style={{textAlign: "left"}}
                                        className={"ant-btn ant-btn-link"}
                                        to={"/packing-lists/" + order?.documents.packing_list[0].id}
                                    >
                                        {t("ORDERS.ORDER.VIEW.PACKING_LIST")}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item style={{padding: "4px 15px"}}>
                                    <a
                                        target="_blank"
                                        href={"/documents/" + order?.documents.packing_list[0].id}
                                    >
                                        {t("DOCUMENTS.FILES.SHOW_PDF")}
                                    </a>
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button> {t("ORDERS.ORDER.VIEW.PACKING_LIST")}</Button>
                    </Dropdown>
                )}
                {!showInvoices || !order?.documents.invoice ? null : order.documents.invoice.length > 1 ? (
                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                {order.documents.invoice.map(invoice => (
                                    <Menu.SubMenu title={invoice.docNumber}>
                                        <Menu.Item key={0} style={{padding: "4px 15px"}}>
                                            <Link
                                                style={{textAlign: "left"}}
                                                className={"ant-btn ant-btn-link"}
                                                to={"/invoices/" + invoice.id}
                                            >
                                                {t("ORDERS.ORDER.VIEW.INVOICE")}
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key={1} style={{padding: "4px 15px"}}>
                                            <a
                                                style={{textAlign: "left"}}
                                                target="_blank"
                                                className={"ant-btn ant-btn-link"}
                                                href={"/documents/" + invoice.id}
                                            >
                                                {t("DOCUMENTS.FILES.SHOW_PDF")}
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item
                                            key={2}
                                            style={{padding: "4px 15px"}}
                                            onClick={() => setExportInvoiceId(invoice.id)}
                                        >
                                            {t("DOWNLOAD.CSV_EXCEL")}
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                ))}
                            </Menu>
                        }
                    >
                        <Button> {t("ORDERS.ORDER.VIEW.INVOICE")}</Button>
                    </Dropdown>
                ) : (
                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                <Menu.Item key={0} style={{padding: "4px 15px"}}>
                                    <Link
                                        style={{textAlign: "left"}}
                                        className={"ant-btn ant-btn-link"}
                                        to={"/invoices/" + order.documents.invoice[0].id}
                                    >
                                        {t("ORDERS.ORDER.VIEW.INVOICE")}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={1} style={{padding: "4px 15px"}}>
                                    <a
                                        style={{textAlign: "left"}}
                                        target="_blank"
                                        className={"ant-btn ant-btn-link"}
                                        href={"/documents/" + order.documents.invoice[0].id}
                                    >
                                        {t("DOCUMENTS.FILES.SHOW_PDF")}
                                    </a>
                                </Menu.Item>
                                <Menu.Item
                                    key={2}
                                    style={{padding: "4px 15px"}}
                                    onClick={() => setExportInvoiceId(order!.documents.invoice![0].id)}
                                >
                                    {t("DOWNLOAD.CSV_EXCEL")}
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button> {t("ORDERS.ORDER.VIEW.INVOICE")}</Button>
                    </Dropdown>
                )}

                {!showMergedInvoices ||!order?.documents.merged_invoice ? null : order.documents.merged_invoice?.length > 1 ? (
                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                {order.documents.merged_invoice.map(invoice => (
                                    <Menu.SubMenu title={invoice.docNumber}>
                                        <Menu.Item key={0} style={{padding: "4px 15px"}}>
                                            <Link
                                                style={{textAlign: "left"}}
                                                className={"ant-btn ant-btn-link"}
                                                to={"/merged-invoices/" + invoice.id}
                                            >
                                                {t("ORDERS.ORDER.VIEW.INVOICE")}
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key={1} style={{padding: "4px 15px"}}>
                                            <a
                                                style={{textAlign: "left"}}
                                                target="_blank"
                                                className={"ant-btn ant-btn-link"}
                                                href={"/documents/" + invoice.id}
                                            >
                                                {t("DOCUMENTS.FILES.SHOW_PDF")}
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item
                                            key={2}
                                            style={{padding: "4px 15px"}}
                                            onClick={() => setExportInvoiceId(invoice.id)}
                                        >
                                            {t("DOWNLOAD.CSV_EXCEL")}
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                ))}
                            </Menu>
                        }
                    >
                        <Button> {t("ORDERS.ORDER.VIEW.MERGED_INVOICE")}</Button>
                    </Dropdown>
                ) : (
                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                <Menu.Item key={0} style={{padding: "4px 15px"}}>
                                    <Link
                                        style={{textAlign: "left"}}
                                        className={"ant-btn ant-btn-link"}
                                        to={"/merged-invoices/" + order.documents.merged_invoice[0].id}
                                    >
                                        {t("ORDERS.ORDER.VIEW.INVOICE")}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={1} style={{padding: "4px 15px"}}>
                                    <a
                                        style={{textAlign: "left"}}
                                        target="_blank"
                                        className={"ant-btn ant-btn-link"}
                                        href={"/documents/" + order.documents.merged_invoice[0].id}
                                    >
                                        {t("DOCUMENTS.FILES.SHOW_PDF")}
                                    </a>
                                </Menu.Item>
                                <Menu.Item
                                    key={2}
                                    style={{padding: "4px 15px"}}
                                    onClick={() => setExportInvoiceId(order!.documents.merged_invoice![0].id)}
                                >
                                    {t("DOWNLOAD.CSV_EXCEL")}
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button> {t("ORDERS.ORDER.VIEW.MERGED_INVOICE")}</Button>
                    </Dropdown>
                )}
            </Space>

            {exportInvoiceId ? (
                <Export
                    title={"ORDERS.ORDER.DOWNLOAD_CSV_INVOICE"}
                    isShown={true}
                    withBasicExportFields
                    config={{
                        link: "/api/v3/documents/invoices/" + exportInvoiceId,
                    }}
                    onClose={() => setExportInvoiceId(null)}
                />
            ) : null}

        </>
    )
}