import React, {useEffect, useState} from "react";
import {useTranslate} from "../../../hooks/translate.hook";
import {Parcel, Shipment} from "../../../models/parcels/shipment";
import {Col, Row, Typography} from "antd";
import {Logger} from "../../common/logger/logger";

interface InvoiceDataHeaderProps {
    shipment: Shipment;
}

export const ShipmentLogger: React.FC<InvoiceDataHeaderProps> = ({shipment}) => {
    const t = useTranslate();

    return (
        <Row gutter={[25, 25]}>
            <Col span={24}>
                <Typography.Title level={3}>Logger</Typography.Title>
                <Logger relationType="shipment" relationId={shipment.id}/>
            </Col>
        </Row>
    );
}