import React, {useMemo, useState} from "react";
import {
    Radio,
    Button, Divider,
    Space, Table,
    Typography
} from "antd";
import {useTranslate} from "../../../hooks/translate.hook";
import {ClaimType, CreateClaimItem} from "../../../models/claims/claims";
import i18n from "i18next";
import {columnsFactory} from "./claims.create.columns";
import {claimService} from "../../../services/claims/claims.service";
import {useRequiredStore} from "../../../utils/store";
import {AgentDataStoreContext} from "../../agents/agent-data/agent.data.store";
import {useNavigate} from "react-router";
import Dragger from "antd/es/upload/Dragger";
import {ClaimsCreateModal} from "./claims.create.modal";
import Papa from "papaparse";
import dayjs from "dayjs";

export const ClaimsCreate: React.FC = () => {

    const {account} = useRequiredStore(AgentDataStoreContext);

    const t = useTranslate();

    const [items, setItems] = useState<CreateClaimItem[]>([]);

    const [type, setType] = useState<ClaimType>(ClaimType.RECLAMATION);

    const [modalShown, setModalShown] = useState<boolean>(false);
    const [previewItem, setPreviewItem] = useState<CreateClaimItem|null>(null);

    const navigate = useNavigate();

    const setItemsAndUpdateMapUsedQty = (items: CreateClaimItem[]): void => {
        setItems(items);
    }

    const createItem = () => {
        setPreviewItem(null);
        setModalShown(true);
    }

    const editItem = (item: CreateClaimItem) => {
        removeItem(item);
        setPreviewItem(item);
        setModalShown(true);
    }

    const removeItem = (item: CreateClaimItem) => {
        const newItems = [...items];
        const idx = newItems.indexOf(item);
        if (-1 !== idx) {
            newItems.splice(idx, 1);
        }

        setItemsAndUpdateMapUsedQty(newItems);
    }

    const closeItem = () => {
        setPreviewItem(null);
        setModalShown(false);
    }

    const returnItemToListAndClose = () => {
        if (previewItem) {
            setItemsAndUpdateMapUsedQty([...items, previewItem]);
        }
        closeItem();
    }

    const updateItem = (item: CreateClaimItem) => {
        setItemsAndUpdateMapUsedQty([...items, item]);
        closeItem();
    }

    const createClaim = async () => {
        const claim = await claimService.createClaimWithAccount(account!.id, {items, type});
        navigate('/claims/' + claim.id);
    }

    const columns = useMemo(() => columnsFactory(t, removeItem), [i18n.language, items]);

    return (
        <Space direction="vertical" size={[20, 20]}>
            <Typography.Title level={3}>{t("CLAIMS.CREATE.TITLE")}</Typography.Title>

            <Radio.Group onChange={e => setType(e.target.value as ClaimType)} value={type}>
                <Space direction="vertical">
                    {Object.values(ClaimType).map(type => (
                        <Radio value={type}>{t("CLAIMS.CLAIM.TYPE." + type.toUpperCase())}</Radio>
                    ))}
                </Space>
            </Radio.Group>

            <Button type={"primary"} onClick={() => createItem()}>{t('CLAIMS.CREATE.ADD_ITEM')}</Button>

            <Divider plain style={{margin: 0}}>{t('CLAIMS.CREATE.CAN_UPLOAD_HELP_TEXT')}</Divider>

            <Dragger
                accept={".csv"}
                showUploadList={false}
                customRequest={(data) => {
                    const blob = data.file as Blob;
                    blob.text().then(value => {
                        const newItems: CreateClaimItem[] = [];
                        Papa.parse<any>(value, {
                            complete: (results) => {
                                results.data.forEach(item => {
                                    if (item.length > 2) {
                                        newItems.push({
                                            type: "unknown",
                                            clarification: {},
                                            data: {
                                                sku: item[0],
                                                price: parseFloat(item[2].replace(",", ".")),
                                                currencyCode: "eur",
                                                qty: parseInt(item[1]),
                                                invoiceNumber: item[3],
                                                invoiceDate: dayjs(item[4]),
                                                description: item[5]
                                            }
                                        });
                                    }
                                })
                            }
                        });
                        setItemsAndUpdateMapUsedQty([...items, ...newItems]);
                    })
                }}
            >
                <p className="ant-upload-text" style={{textAlign: "left", paddingLeft: 20}}>{t('CLAIMS.CREATE.UPLOAD.P1')}</p>
                <p className="ant-upload-hint" style={{textAlign: "left", paddingLeft: 20}}>{t('CLAIMS.CREATE.UPLOAD.P2')}</p>
                <p className="ant-upload-hint" style={{textAlign: "left", paddingLeft: 20}}>{t('CLAIMS.CREATE.UPLOAD.P3')}</p>
            </Dragger>

            {items.length > 0 ? (
                <>
                    <Table
                        dataSource={items}
                        columns={columns}
                        pagination={false}
                        rowKey={"sku"}
                    />
                    <Button type={"primary"} onClick={() => createClaim()}>{t('CLAIMS.CREATE.TITLE')}</Button>
                </>
            ) : null}

            {modalShown ? (
                <ClaimsCreateModal
                    onComplete={updateItem}
                    onClose={returnItemToListAndClose}
                />
            ) : null}
        </Space>
    );
}
