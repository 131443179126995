import React from "react";
import { Form, FormInstance } from "antd";
import { NumberInput } from "../../../../../common/number-input/number.input";
import { Rule } from "antd/lib/form";

interface EditableCellValue {
    value: string;
    displayValue: string;
}

export interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "number" | "text";
    record: any;
    index?: number;
    form?: FormInstance;
    rules: Rule[];
    hint?: string;
    children?: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    rules = [],
    hint,
    ...restProps
}) => {
    // const inputNode = inputType === "number" ? : <Input/>;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    help={hint}
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={rules}
                >
                    <NumberInput />
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
