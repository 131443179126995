import React, {useRef} from "react";
import {Popover} from "antd";
import "./property-code.scss"
import {propertyService} from "../../../../../services/property/property.service";
import {Property} from "../../../../../models/property/property";

interface PropertyTypeProps {
    property: Property;
}
export const PropertyCode: React.FC<PropertyTypeProps> = ({property}) => {

    const ref = useRef<null | HTMLDivElement>(null);

    const onVisibleChange = (visibility: boolean) => {
        if (visibility) {
            propertyService.getPropertyQRCode(property.id).then(blob => {
                const img = new Image();
                img.src = URL.createObjectURL(blob);
                ref.current?.replaceWith(img);
            })
        }
    }

    return (
        <Popover
            overlayClassName={"property-code-popover"}
            trigger={"click"}
            content={
                <>
                    <div className={"property-code-popover-title"}>
                        {property.code}
                    </div>
                    <div className={"property-code-popover-description"}>
                        {property.description}
                    </div>
                    <div ref={ref}>
                        Loading...
                    </div>
                </>
            }
            onVisibleChange={onVisibleChange}
        >
            <span className={"property-code"}>{property.code}</span>
        </Popover>
    )
}