import React, {useEffect, useState} from "react";
import {Camera, Photograph} from "./photo-capture.photograph";
import {notifications} from "../../../services/notifications/notifications";
import "./photo-capture.scss"
import {Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import QrScanner from "qr-scanner";

interface PhotoCaptureProps {
    onCaptured: (value: Blob) => void;
}

export const PhotoCapture: React.FC<PhotoCaptureProps> = ({onCaptured}) => {

    const CAM_KEY = "qr_cam";

    const [ready, setReady] = useState<boolean>(false);
    const [scanner, setScanner] = useState<Photograph | null>(null);
    const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(null);

    const [cams, setCams] = useState<Camera[]>([]);
    const [selectedCam, setSelectedCam] = useState<string>(localStorage.getItem(CAM_KEY) || "");

    useEffect(() => {
        Photograph.listCameras().then(cams => setCams(cams))
    }, [])

    useEffect(() => {
        if (videoElement) {
            const scanner = new Photograph(
                videoElement,
                onCaptured,
                selectedCam
            );

            scanner
                .start()
                .then(() => {
                    setReady(true)
                })
                .catch(e => notifications.message("error", e));

            setScanner(scanner)

            return () => {
                scanner.destroy();
            }
        }
    }, [videoElement])

    const toggleCamera = (selectedCam: QrScanner.DeviceId): QrScanner.DeviceId => {
        let idx = 0;
        const foundIdx = cams.findIndex(c => c.id === selectedCam);
        if (-1 !== foundIdx && cams[foundIdx+1]) {
            idx = foundIdx + 1;
        }

        let newCam = cams[idx];

        localStorage.setItem(CAM_KEY, newCam.id);

        setReady(false);
        scanner?.setCamera(newCam.id).then(() => setReady(true));

        return newCam.id;
    }

    return (
        <div className="qr-code-scanner">
            <video
                ref={e => setVideoElement(e)}
                width={"100%"}
            />
            {ready && cams.length > 1 ? (
                <Button
                    className={"qr-code-scanner-change-cam-button"}
                    onClick={() => setSelectedCam(toggleCamera)}
                    shape={"circle"}
                    icon={<FontAwesomeIcon icon={["fas", "camera"]} />}
                />
            ) : null}
        </div>
    )
}