import { Modal, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { observer } from "mobx-react-lite";
import React from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { useVT } from "virtualizedtableforantd4";
import { OrderWarning } from "../../../../models/orders/order-data/order.data";
import {Error} from "../../../../models/errors/error";
import Date from "../../../date/date";

interface OrderWarningsProps {
    visible: boolean;
    warnings: Error[]
    onCancel: () => void
}
export const OrderWarnings: React.FC<OrderWarningsProps> = observer(({visible, warnings, onCancel}) => {
    const t = useTranslate();
    const y = 400;
    const [vt] = useVT(() => ({ scroll: { y }, debug: true }), [y]);
    const columns: ColumnsType<Error> = [
        {
            title: t("TYPE"),
            dataIndex: "type",
            key: "type",
            width: 50,
        },
        {
            title: t("LEVEL"),
            dataIndex: "level",
            key: "level",
            width: 50,
            render: (type: string) => <Tag color={type === "error" ? "red" : "warning"}>{type.toUpperCase()}</Tag>,
        },
        // {
        //     title: t("ORDER.WARNINGS.REASON.TITLE"),
        //     dataIndex: "reason",
        //     key: "reason",
        //     width: 60,
        //     render: (reason: string) => t("ORDER.WARNINGS.REASON." + reason.toUpperCase()),
        // },
        {
            title: t("MESSAGE"),
            dataIndex: "message",
            key: "message",
            width: 200,
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            width: 80,
            render: (date: string) => <Date dateString={date} showTime withSeconds />,
        },
    ];

    return (
        <Modal
            visible={visible}
            title={t("ORDER.WARNINGS.TITLE")}
            width={1100}
            footer={null}
            onCancel={onCancel}
        >
            <Table
                columns={columns}
                rowKey={"id"}
                dataSource={[...warnings]}
                components={vt}
                scroll={{ x: undefined, y }}
                pagination={false}
            />
        </Modal>
    );
});
