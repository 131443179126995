import { observer } from "mobx-react";
import React, { useState } from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import Date from "../../../date/date";
import { Space } from "antd";
import { BranchDataWarehousesStore } from "./branch.data.warehouses.store";
import { Link, useParams } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { WarehouseExpanded } from "../../../../models/warehouses/warehouses";
import { FieldGroupConfig } from "../../../../models/filter/filter.group";
import { FieldTypeName } from "../../../../models/filter/filter.field.type.name";
import { QuickLink, QuickLinks } from "../../../common/quick-links/quick-links";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
const warehouseQuickLinks: QuickLink[] = [
    {
        path: "/warehouses/taxes",
        text: "WAREHOUSES.TAXES.TITLE",
        description: "WAREHOUSES.TAXES.DESCRIPTION",
    },
    {
        path: "/articles/stock",
        text: "ARTICLES.WAREHOUSES.TITLE",
        description: "ARTICLES.WAREHOUSES.DESCRIPTION",
    },
    {
        path: "/articles/history",
        text: "ARTICLES.HISTORY",
        description: "ARTICLES.WAREHOUSES.DESCRIPTION",
    },
];
interface BranchDataWarehousesProps extends ComponentPropsFromRoute {}
export const BranchDataWarehouses: React.FC<BranchDataWarehousesProps> = observer(({ name }) => {
    const { branchId } = useParams<{ branchId: string }>();
    const t = useTranslate();
    const [store] = useState(() => new BranchDataWarehousesStore(branchId!));

    const columns: ColumnsType<WarehouseExpanded> = [
        {
            title: t("WAREHOUSES.NAME"),
            dataIndex: "name",
            key: "name",
            render: (name: string, row) => (name ? <Link to={row.id}>{name}</Link> : null),
        },
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("1C.NAME"),
            dataIndex: "ocName",
            key: "ocName",
        },
        {
            title: t("1C.CODE"),
            dataIndex: "ocCode",
            key: "ocCode",
        },
        {
            title: t("1C.CODE_SALES"),
            dataIndex: "ocOrderCode",
            key: "ocOrderCode",
        },
        {
            title: t("WAREHOUSES.STATUS"),
            dataIndex: "status",
            key: "status",
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (item: string) => <Date dateString={item} showTime={true} />,
        },
        {
            title: t("UPDATED_AT"),
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (item: string) => <Date dateString={item} showTime={true} />,
        },
    ];

    const filterGroups: FieldGroupConfig[] = [
        {
            name: "name",
            visibleName: "WAREHOUSES.NAME",
            fields: [
                {
                    name: "name",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "id",
            visibleName: "ID",
            fields: [
                {
                    name: "id",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "1c_name",
            visibleName: "1C.NAME",
            fields: [
                {
                    name: "ocName",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "1c_code",
            visibleName: "1C.CODE",
            fields: [
                {
                    name: "ocCode",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "1c_order_code",
            visibleName: "1C.CODE_SALES",
            fields: [
                {
                    name: "ocOrderCode",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "status",
            visibleName: "status",
            fields: [
                {
                    name: "status",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "createdAt",
            visibleName: "CREATED_AT",
            fields: [
                {
                    name: "createdAt",
                    type: FieldTypeName.DATE_RANGE,
                },
            ],
        },
        {
            name: "updatedAt",
            visibleName: "UPDATED_AT",
            fields: [
                {
                    name: "updatedAt",
                    type: FieldTypeName.DATE_RANGE,
                },
            ],
        },
    ];

    const columnsConfigs = [
        {
            title: "ID",
            key: "id",
        },
        {
            title: "WAREHOUSES.NAME",
            key: "name",
        },
        {
            title: "1C.NAME",
            key: "ocName",
        },
        {
            title: "1C.CODE",
            key: "ocCode",
        },
        {
            title: "WAREHOUSES.STATUS",
            key: "status",
        },
        {
            title: "CREATED_AT",
            key: "createdAt",
        },
        {
            title: "UPDATED_AT",
            key: "updatedAt",
        },
    ];

    return (
        <Space direction="vertical" size={[30, 30]}>
            <Space direction="horizontal" size={[10, 10]}>
                <Link className="ant-btn ant-btn-primary" to={"/settings/branches/" + branchId + "/warehouses/new"}>
                    {t("BRANCH.DATA.WAREHOUSES.ADD")}
                </Link>
                <Link className="ant-btn ant-btn-primary" to={"/settings/branches/" + branchId + "/warehouses/priority"}>
                    {t("BRANCH.DATA.WAREHOUSES.PRIORITY_GROUPS.TITLE")}
                </Link>
            </Space>
            <FilteredTable
                reloadOnLngChange
                columns={columns}
                columnsConfigs={{ columns: columnsConfigs, pageKey: name }}
                filterGroups={filterGroups}
                dataHandler={store.dataHandler}
            />
            <QuickLinks links={warehouseQuickLinks} />
        </Space>
    );
});
