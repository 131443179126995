import React, {ReactElement, useEffect, useMemo, useState} from "react";
import {Button, Space, Table} from "antd";
import {SmartTableDataHandler} from "./smart-table.data-handler";
import {Pagination} from "../../../models/core/paginated.result";
import {LoadingSpin} from "../loading-spin/loading.spin";
import {useTranslate} from "../../../hooks/translate.hook";
import {SmartAction, SmartColumn, SmartFilterGroup, SmartFilterGroupValue} from "./smart-table.model";
import {
    antFiltersToSmartFilters, parseSmartFilters,
    smartColumnsToAntdColumns,
    smartColumnsToNameFieldConfigMap
} from "./smart-table.utils";
import {useURLParams} from "../../../hooks/url-params.hook";
import {FilterValues} from "../../../models/filter/filter.props";
import _ from "lodash";

interface SmartTableProps<T> {
    handler: SmartTableDataHandler<T>
    columns: SmartColumn<T>[]
    actions?: SmartAction<T>[]
    extraFields?: Record<string, any>;
    filterGroups?: SmartFilterGroup[];
    setExtraFields?: (fields: Record<string, any>) => void;
    setDefaultExtraFields?: (fields: Record<string, any>) => void;
    loadOnlyIfExtraFieldsIsSet?: boolean;
    disabled?: boolean;
    extraRight?: ReactElement;
}

export const SmartTable: React.FC<SmartTableProps<any>> = ({handler, columns, actions = [], disabled = false, extraFields, setExtraFields, setDefaultExtraFields, filterGroups, extraRight, loadOnlyIfExtraFieldsIsSet = false}) => {
    const t = useTranslate();

    const {urlParams, setURLParams} = useURLParams();

    const [customFilters, setCustomFilters] = useState<FilterValues | null>(null);

    const defaultFilters = useMemo(() => {
        if (null !== customFilters) {
            return customFilters;
        }
        return parseSmartFilters(String(urlParams.filters));
    }, [customFilters])

    const columnsNameFieldConfig = useMemo(() => smartColumnsToNameFieldConfigMap(columns), [columns])
    const stdColumns = useMemo(() => smartColumnsToAntdColumns(columns, columnsNameFieldConfig, defaultFilters, actions), [columns, defaultFilters, actions])

    const [items, setItems] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [fullLoading, setFullLoading] = useState<boolean>(false);

    const [pagination, setPagination] = useState<Pagination|null>(null);

    useEffect(() => {
        handler.setOnLoadingChange(loading => setLoading(loading))
        handler.setOnResult(({items, meta}) => {
            setItems(items);
            setPagination(meta);
            setURLParams(handler.toURLParams(), true)
        })

        handler.fromURLParams(urlParams);
        if (setDefaultExtraFields && handler.lastRequest) {
            setDefaultExtraFields(handler.lastRequest.extraFields);
        }

        if (!loadOnlyIfExtraFieldsIsSet && !disabled) {
            handler.reloadItemsWithLastRequest()
        }
    }, [handler])

    useEffect(() => {
        if (extraFields && !_.isEqual(handler.lastRequest?.extraFields, extraFields)) {
            handler.setExtraFields(extraFields);
            if (!disabled) {
                handler.reloadItemsWithLastRequest()
            }
        }
    }, [handler, extraFields, disabled])

    const applyFilterGroup = (value: SmartFilterGroupValue[]) => {
        handler.setFilters(value.map(k => ([k.name, k.value])))
        setCustomFilters((() => {
            const m: Record<string, any> = {};
            value.forEach(v => {
                m[v.name] = v.value;
            })
            return m;
        })());
        setFullLoading(true);
        setTimeout(() => {
            setFullLoading(false);
        }, 0);
    }

    if (disabled) {
        return <></>
    }

    if (!pagination || fullLoading) {
        return <LoadingSpin />
    }

    return <Space direction={"vertical"} size={[20, 20]}>
        {filterGroups || extraRight ? (
            <Space direction={"horizontal"} style={{justifyContent: "space-between"}}>
                {filterGroups ? (
                        <Space direction={"horizontal"}>
                            {filterGroups.map(group => (
                                <Button onClick={() => applyFilterGroup(group.fields)}>
                                    {t("FILTER-GROUP." + group.name.toUpperCase())}
                                </Button>
                            ))}
                        </Space>
                    ) : null}
                {extraRight ? (
                    <>{extraRight}</>
                ) : null}
            </Space>
        ) : null}
        <Table
            loading={loading}
            columns={stdColumns}
            dataSource={items}
            virtual={items.length > 150}
            scroll={{ y: 750, x: (stdColumns.length >= 8) ? stdColumns.length * 150: true }}
            onChange={(_, filters, sorter, extra) => {
                switch (extra.action) {
                    case 'filter':
                        handler.setFilters(antFiltersToSmartFilters(filters, columnsNameFieldConfig));
                        setCustomFilters(null);
                        break;
                    case 'sort':
                        break;
                }
            }}
            pagination={pagination.totalPages > 0 ? {
                position: ["bottomLeft"],
                total: pagination.total,
                current: pagination.page,
                pageSize: pagination.limit,
                pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
                showSizeChanger: true,
                showQuickJumper: true,
                onChange: (page) => handler.setPage(page),
                onShowSizeChange: (_, size) => handler.setLimit(size),
                showTotal: (total) => t("TOTAL_ITEMS") + ": " + total,
            } : false}
        />
    </Space>
}