import { fieldTypes } from "./filter.field.type.config";
import { FieldGroupConfig } from "./filter.group";
import { FieldRuleName } from "./filter.field.rule";
import { FieldStateGroup } from "./filter.field.state";

export interface FilterFormValue {
    key: string;
    fields: FilterFormValueFieldValues;
}

export interface FilterFormValueField {
    values: any;
    rule: FieldRuleName;
}

export type FilterFormValueFieldValues = Record<string, FilterFormValueField>;

export const createFieldStateGroup = (
    config: FieldGroupConfig,
    values: FilterFormValueFieldValues
): FieldStateGroup => {
    return {
        name: config.name,
        visibleName: config.visibleName,
        states: config.fields.map((field) => {
            const { values: value, rule } = values[field.name] || {
                values: fieldTypes[field.type].getDefaultValue(),
                rule: fieldTypes[field.type].supportedRules[0],
            };

            return {
                config: field,
                rule,
                value,
            };
        }),
    };
};

export const createFormValues = (group: FieldStateGroup): FilterFormValue => {
    const fields: FilterFormValueFieldValues = {};

    group.states.forEach((state) => {
        fields[state.config.name] = {
            values: state.value,
            rule: state.rule,
        };
    });

    return {
        key: group.name,
        fields,
    };
};
