import {Translate} from "../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {ShippingRule} from "../../../../../models/shipping/shipping";
import {Amount, CurrencyCode} from "../../../../../models/prices/price";
import {createVisiblePrice, displayPrice} from "../../../../../models/prices/price.helper";
import {Button, Popconfirm, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ShippingRulesGroup} from "./branch.data.rules";

export const groupedColumnsFactory = (t: Translate): ColumnsType<ShippingRulesGroup> => {
    return [
        {
            title: "Countries",
            dataIndex: "countries",
            key: "countries",
            render: (countries: string[]) => countries.join(", ") || "ALL Countries"
        }
    ];
}

export const columnsFactory = (t: Translate, currencyCode: CurrencyCode, onEdit: (rule: ShippingRule) => void, onRemove: (rule: ShippingRule) => void): ColumnsType<ShippingRule> => {
    return [
        {
            title: "Weight",
            key: "weight",
            render: (_: any, rule) => rule.weightFrom + " kg." + " → " + (rule.weightUntil ? (rule.weightUntil + " kg.") : '∞'),
            width: 150
        },
        {
            title: "Order subtotal",
            key: "price",
            render: (_: any, rule) => createVisiblePrice({value: rule.priceFrom, currencyCode: currencyCode}) + " → " + (rule.priceUntil ? createVisiblePrice({value: rule.priceUntil, currencyCode: currencyCode}) : '∞'),
            width: 150
        },
        {
            title: "Cost",
            dataIndex: "cost",
            key: "cost",
            render: (amount: Amount) => displayPrice(amount),
            width: 150
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            width: 100,
            className: "table-button-cell",
            render: (_: any, rule) => (
                <>
                    <Tooltip placement="topRight" title={t("EDIT")}>
                        <Button
                            onClick={() => onEdit(rule)}
                            className="table-btn btn-remove btn"
                            type="default"
                            icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                        />
                    </Tooltip>
                    <Popconfirm
                        title={t("ARE_YOU_SURE")}
                        onConfirm={() => onRemove(rule)}
                        placement="left"
                        okText={t("YES")}
                        cancelText={t("NO")}
                    >
                        <Tooltip placement="topRight" title={t("REMOVE")}>
                            <Button
                                className="table-btn btn-remove btn"
                                type="default"
                                icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                            />
                        </Tooltip>
                    </Popconfirm>
                </>
            ),
        }
    ];
}