import React, {useState} from "react";
import {Button, Card, Space} from "antd";
import {MarkdownTextarea} from "../../../../core/markdown/markdown-textarea/markdown-textarea";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {plannerService} from "../../../../../services/planner/planner.service";
import {PlannerTask} from "../../../../../models/planner/planner";

interface TaskCommentProps {
    task: PlannerTask;
    onSubmit: (value: string) => Promise<any>;
}

export const TaskNewComment: React.FC<TaskCommentProps> = ({task, onSubmit}) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [value, setValue] = useState<string>("");
    const t = useTranslate();

    const onCompleted = async () => {
        setLoading(true);
        try {
            await onSubmit(value);
            setValue("")
        } finally {
            setLoading(false);
        }
    }

    return (
        <Card
            title={"New comment"}
            size={"small"}
        >
            <Space direction={"vertical"} size={[20, 20]}>
                <MarkdownTextarea
                    value={value}
                    onChange={v => setValue(v)}
                    fileUploader={f => plannerService.attachFile(task.id, false, f)}
                    height={"100px"}
                    disabled={loading}
                />
                <Button
                    onClick={() => onCompleted()}
                    disabled={value.length === 0}
                    loading={loading}
                >
                    {t('SUBMIT')}
                </Button>
            </Space>
        </Card>
    )
}