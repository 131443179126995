import React, {useState} from "react";
import {Button, Divider, Space} from "antd";
import {FilterDropdownProps} from "antd/lib/table/interface";
import {FilterDropdownSelector} from "./filter-dropdown-selector";
export const FilterDropdown: React.FC<FilterDropdownProps> = ({ setSelectedKeys, confirm, clearFilters, filters }) => {

    const [value, setValue] = useState<string[]>([]);

    const onConfirm = () => {
        setSelectedKeys(value)
        confirm();
    }

    const onChange = (value: string[]) => {
        setValue(value)
    }

    return <div style={{minWidth: "250px"}}>
        <Space direction={"vertical"} size={[10, 10]} split={<Divider style={{margin: 0}} />} style={{padding: "5px 10px"}}>
            <FilterDropdownSelector
                onConfirm={onConfirm}
                onChange={onChange}
                items={filters}
            />
            <Space direction={"horizontal"} size={[10, 10]} style={{justifyContent: "space-between", padding: "2px 0px"}}>
                <Button
                    onClick={() =>onConfirm()}
                    disabled={value.length === 0}
                    size={"small"}
                >
                    OK
                </Button>
                <Button
                    onClick={() => clearFilters && clearFilters()}
                    size={"small"}
                >
                    Clear
                </Button>
            </Space>
        </Space>
    </div>
}