import {Form, Input, Modal, Select} from "antd";
import React, {useState} from "react";
import {ClaimItemReject, ClaimItemRejectReason} from "../../../models/claims/claims";
import {useTranslate} from "../../../hooks/translate.hook";

interface ClaimRejectModalProps {
    title: string;
    visible: boolean;
    onComplete: (value: ClaimItemReject) => Promise<void>;
    onClose: () => void;
}

export const ClaimRejectModal: React.FC<ClaimRejectModalProps> = ({title, visible, onComplete, onClose}) => {

    const t = useTranslate();

    const [form] = Form.useForm<ClaimItemReject>();

    const [disabled, setDisabled] = useState(false);

    return <Modal
        title={title}
        width={500}
        destroyOnClose
        visible={visible}
        onCancel={onClose}
        onOk={() => {
            form.validateFields()
                .then(values => {
                    setDisabled(true);
                    onComplete(values)
                        .then(() => {
                            onClose()
                            form.resetFields();
                        })
                        .catch((e) => form.setFields(e.response?.data?.fields || []))
                        .finally(() => {
                            setDisabled(false);
                        })
                })
        }}
    >
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
        >
            <Form.Item
                label="Select reason"
                name="reason"
                rules={[{required: true}]}
            >
                <Select disabled={disabled} autoFocus={true} defaultActiveFirstOption={true}>
                    {Object.values(ClaimItemRejectReason).map(item => (
                        <Select.Option value={item} key={item}>{t('CLAIMS.ITEM.REJECT_REASON.' + item.toUpperCase())}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                label="Explanation"
                name="explanation"
            >
                <Input.TextArea />
            </Form.Item>
        </Form>
    </Modal>
}