import React from "react";
import {WidgetProps} from "../../../../../../../common/smart-table/filter/dropdown/widget/widget";
import {Select} from "antd";
import {
    propertyInventoryTaskPropertyStatuses,
} from "../../../../../../../../models/property/property";
import {useTranslate} from "../../../../../../../../hooks/translate.hook";

export const TaskPropertyStatusWidget: React.FC<WidgetProps> = ({onChange, value}) => {

    const t = useTranslate();

    return (
        <Select
            mode={"multiple"}
            onChange={onChange as any}
            value={value as any}
            style={{width: '250px'}}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
        >
            {propertyInventoryTaskPropertyStatuses.map(v =>
                <Select.Option key={v} value={v}>{t("PROPERTY.INVENTORY_TASK_PROPERTY.STATUS." + v.toUpperCase())}</Select.Option>)}
        </Select>
    )
}