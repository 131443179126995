import React, { useEffect, useState } from "react";
import {useParams, useLocation} from "react-router-dom";
import {
    ArticleDataStore,
    ArticleDataStoreContext,
} from "./article.data.store";
import {Col, Row, Space, Spin} from "antd";
import { observer } from "mobx-react";
import { ArticleDataHeader } from "./article-data-header/article.data.header";
import { ArticleDataOem } from "./article-data-oem/article.data.oem";
import { ArticleDataPrices } from "./article-data-prices/article.data.prices";
import { ArticleDataAvailability } from "./article-data-availability/article.data.availability";
import { ArticleDataHistory } from "./article-data-history/article.data.history";
import { ArticleDataAttributes } from "./article-data-attributes/article.data.attributes";
import { ArticleDataAnalogues } from "./article-data-analogues/article.data.analogues";
import { ArticleVehicles } from "./article-vehicles/article.vehicles";
import i18n from "i18next";
import { ArticlesDataAvailabilityStats } from "./article-data-availability/stats/articles.data.availability.stats";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import {ArticleDataClaims} from "./article-data-claims/article.data.claims";
import {ArticleDataStatus} from "./article-data-status/article.data.status";
import {isAllowed} from "../../../utils/helpers";

export const ArticleData: React.FC = observer(() => {
    const { articleId } = useParams<{ articleId: string }>();
    const {pageStore} = useRequiredStore(RootStoreContext);
    const location = useLocation();
    const [store] = useState(() => new ArticleDataStore(articleId!, pageStore, location.pathname));

    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);

    useEffect(() => {
        i18n.on("languageChanged", store.onLngChange);
        return () => i18n.off("languageChanged", store.onLngChange);
    }, []);

    if (store.loading) {
        return (
            <Space
                direction={"horizontal"}
                align={"center"}
                style={{
                    width: "100%",
                    height: "calc(100vh - 90px)",
                    justifyContent: "center",
                }}
            >
                <Spin />
            </Space>
        );
    }

    return (
        <ArticleDataStoreContext.Provider value={store}>
            <Space direction={"vertical"} size={[50, 50]}>
                <ArticleDataHeader />
                <Row gutter={[20, 20]}>
                    <Col span={12}>
                        <ArticleDataAttributes />
                    </Col>
                    <Col span={12}>
                        <ArticleDataStatus />
                    </Col>
                </Row>
                <Row gutter={[20, 20]}>
                    <Col span={12}>
                        <ArticleDataOem />
                    </Col>
                    <Col span={12}>
                        <ArticleDataAnalogues />
                    </Col>
                </Row>
                <Row gutter={[20, 20]}>
                    <Col span={12}>
                        <ArticleDataPrices />
                    </Col>
                    <Col span={12}>
                        <ArticleDataAvailability />
                    </Col>
                </Row>
                {isAllowed(attributes, "claims") ? (
                    <Row gutter={[20, 20]}>
                        <Col span={12}>
                            <ArticleDataClaims articleId={store.article!.id} />
                        </Col>
                    </Row>
                ) : null}
                <ArticleVehicles />
                <ArticlesDataAvailabilityStats />
                <ArticleDataHistory />
            </Space>
        </ArticleDataStoreContext.Provider>
    );
});
