import React from "react";
import {PlannerTaskSystemStatus} from "../../../../../models/planner/planner";
import {Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconName} from "@fortawesome/fontawesome-svg-core";

interface TaskStatusProps {
    status: PlannerTaskSystemStatus;
}

const colorMap: Record<PlannerTaskSystemStatus, [string, IconName]> = {
    [PlannerTaskSystemStatus.PENDING]: ["yellow", "clock"],
    [PlannerTaskSystemStatus.TODO]: ["yellow", "clock"],
    [PlannerTaskSystemStatus.IN_PROGRESS]: ["green", "spinner"],
    [PlannerTaskSystemStatus.ON_REVIEW]: ["yellow", "spinner"],
    [PlannerTaskSystemStatus.DONE]: ["green", "check"],
    [PlannerTaskSystemStatus.CANCELLED]: ["red", "window-close"]
}

export const TaskStatus: React.FC<TaskStatusProps> = ({status}) => {

    const [color, icon] = colorMap[status];

    return (
        <Tag
            color={color}
            icon={<FontAwesomeIcon icon={["fas", icon]} />}
        >
            &nbsp;{status.capitalizeFirstLetter()}
        </Tag>
    )
}
