import { AxiosInstance } from "axios";
import API from "../../../utils/api";
import { PaginatedRequest } from "../../../models/core/paginated.request";
import { PaginatedResult } from "../../../models/core/paginated.result";
import { ExtendedArticle } from "../../../models/articles/article";

export class ArticlesListService {
    api: AxiosInstance = API;

    getAll(request: PaginatedRequest): Promise<PaginatedResult<ExtendedArticle>> {
        return this.api
            .get<PaginatedResult<ExtendedArticle>>("articles", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }

    getArticlesExportConfig(): Promise<any> {
        return this.api.get("articles/export/config").then((x) => x.data);
    }

    exportArticles(values: any): Promise<any> {
        return this.api
            .post<any>("articles/export", values, { responseType: "blob" })
            .then((x) => ({ data: x.data, headers: x.headers }));
    }
}

export const articlesListService = new ArticlesListService();
