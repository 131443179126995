import React from "react";
import {observer} from "mobx-react";
import {Space, Typography} from "antd";
import {useTranslate} from "../../hooks/translate.hook";
import {ComponentPropsFromRoute} from "../../routers/routers";
import {RouteTabs} from "../common/route-tabs/route.tabs";



interface DeclarationsProps extends ComponentPropsFromRoute {}
export const Declarations: React.FC<DeclarationsProps> = observer(({routeChildren}) => {
    const t = useTranslate();

    return (
        <>
            <Space direction={"vertical"} style={{width: "100%", marginBottom: "20px"}}>
                <Typography.Title level={4}>{t("DECLARATIONS.DETAILS")}</Typography.Title>
                <RouteTabs routes={routeChildren!} />
            </Space>
        </>
    );
})
