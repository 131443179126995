import React, {useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {Button, Space, Tag, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PlanerTag} from "../../../../models/departments/planer_tags";
import {useTranslate} from "../../../../hooks/translate.hook";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {plannerService} from "../../../../services/planner/planner.service";
import {SettingsTagsAddTagModal} from "./settings-tags.add-tag.modal";
import "./settings-tags.scss"

export const PlannerSettingsTags: React.FC<ComponentPropsFromRoute> = () => {

    const t = useTranslate();

    const [loading, setLoading] = useState<boolean>(true);
    const [addPlannerTagModalVisible, setAddPlannerTagModalVisible] = useState<boolean>(false);
    const [editablePlanerTag, setEditablePlanerTag] = useState<PlanerTag | null>(null);

    const [groupedTags, setGroupedTags] = useState<Record<string, PlanerTag[]>>({});

    const reload = async () => {
        setLoading(true);

        const tags = await plannerService.getAllPlannerTags();
        const groupedTags: Record<string, PlanerTag[]> = {};
        tags.forEach(tag => {
            if (!groupedTags[tag.color]) {
                groupedTags[tag.color] = [];
            }
            groupedTags[tag.color].push(tag);
        })

        setGroupedTags(groupedTags);
        setLoading(false);
    }

    useEffect(() => {
        reload();
    }, [])

    if (loading) {
        return <LoadingSpin />
    }

    return (
        <>
            <Typography.Title level={3}>
                {t('PLANNER.SETTINGS.TAGS.TITLE')}
            </Typography.Title>
            <Space direction={"vertical"} size={[20, 20]}>
                <Button
                    type="primary"
                    disabled={loading}
                    onClick={() => {
                        setEditablePlanerTag(null)
                        setAddPlannerTagModalVisible(true)
                    }}
                    style={{ marginBottom: 10 }}
                    icon={<FontAwesomeIcon icon={["fas", "plus-circle"]}/>}
                >
                    {t("PLANNER.SETTINGS.TAGS.ADD_TAG")}
                </Button>
                <div className={"tags-list"}>
                    {Object.keys(groupedTags).map(key =>
                        <div>
                            {groupedTags[key].map(tag => (
                                <Tag
                                    key={tag.id}
                                    color={tag.color}
                                    onClick={() => {
                                        setEditablePlanerTag(tag)
                                        setAddPlannerTagModalVisible(true)
                                    }}
                                    closable
                                    onClose={async () => {
                                        await plannerService.removePlannerTag(tag.id);
                                        await reload()
                                    }}
                                >
                                    {tag.name}
                                </Tag>
                            ))}
                        </div>
                    )}
                </div>
                {addPlannerTagModalVisible ? (
                    <SettingsTagsAddTagModal
                        editableTag={editablePlanerTag}
                        onCancel={() => setAddPlannerTagModalVisible(false)}
                        onComplete={() => reload()}
                    />
                ) : null}
            </Space>
        </>
    )
}
