import React, { useState } from "react";
import {Col, Form, Modal, Row, Select} from "antd";
import { AxiosError } from "axios";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {ErrorResponseData} from "../../../../../models/core/response";
import {ArticleSearch} from "../../../../articles/common/article-search/article-search";
import {NumberInput} from "../../../../common/number-input/number.input";

interface InvoiceAddItemModalProps {
    onClose: () => void;
    onSubmit: (values: any) => Promise<any>;
    orders: number[]
}

export const InvoiceAddItemModal: React.FC<InvoiceAddItemModalProps> = ({onClose, onSubmit, orders}) => {
    const t = useTranslate();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("ADD")}
            okText={t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                size={"middle"}
                form={form}
                layout="vertical"
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        onSubmit(values)
                            .then(
                                () => {
                                    form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    setLoading(false);
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                }
                            )
                            .then(() => (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={[10, 0]} align={"middle"}>
                    <Col span={24}>
                        <Form.Item
                            name={"order"}
                            label={t("ORDERS.ORDER.TITLE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                },
                            ]}
                        >
                            <Select>
                                {orders.map(id => (
                                    <Select.Option value={id}>{id}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"article"}
                            label={t("ARTICLES.TITLE_SINGLE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                },
                            ]}
                        >
                            <ArticleSearch />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"qty"}
                            label={t("QUANTITY")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                },
                            ]}
                            initialValue={1}
                        >
                            <NumberInput />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
