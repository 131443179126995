import {Translate} from "../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {Claim, ClaimStatus, ClaimType} from "../../../../../models/claims/claims";
import Date from "../../../../date/date";
import {Link} from "react-router-dom";
import {ClaimsStatus} from "../../../../claims/status/claim.status";

export const columnsFactory = (t: Translate): ColumnsType<Claim> => {
    return [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 50
        },
        {
            title: t("TYPE"),
            dataIndex: "type",
            key: "type",
            render: (type: ClaimType) => t("CLAIMS.CLAIM.TYPE." + type.toUpperCase()),
        },
        {
            title: t("NUMBER"),
            dataIndex: "documentNumber",
            key: "documentNumber",
            width: 200,
            render: (number: number, claim) => <Link to={"/claims/" + claim.id}>{number}</Link>,
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            width: "125px",
            render: (item: ClaimStatus) => <ClaimsStatus status={item} />
        },
        {
            title: t("DATE"),
            dataIndex: "documentDate",
            key: "documentDate",
            render: (item: string) => <Date dateString={item} showTime />
        }
    ];
}