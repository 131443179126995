import React from "react";
import {observer} from "mobx-react";
import {Space, Table, Typography} from "antd";
import {useRequiredStore} from "../../../../utils/store";
import {ArticleDataStoreContext} from "../article.data.store";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Amount} from "../../../../models/prices/price";
import {displayPrice} from "../../../../models/prices/price.helper";
import {PriceList} from "../../../../models/price-lists/price.lists";

export const ArticleDataPrices: React.FC = observer(() => {
    const store = useRequiredStore(ArticleDataStoreContext);
    const t = useTranslate();

    const columns: any[] = [
        {
            title: t("NAME"),
            dataIndex: "price",
            key: "price",
            render: (price: PriceList) => <>{price.name}</>
        },
        {
            title: t("PRICE"),
            dataIndex: "amount",
            key: "amount",
            render: (item: Amount) => <>{displayPrice(item)}</>
        }
    ]

    return (
        <Space direction={"vertical"} size={0}>
            <Typography.Title level={3}>{t("ARTICLES.PRICES.TITLE")}</Typography.Title>
            <Table
                columns={columns}
                rowKey={"id"}
                dataSource={[...store.article!.prices]}
                loading={store.loading}
                scroll={{x: true}}
                pagination={false}
            />
        </Space>
    );
})
