import React from "react";
import { useRequiredStore } from "../../../../utils/store";
import { RootStoreContext } from "../../../../stores/root/root.store";
import { isAllowed } from "../../../../utils/helpers";
import {RouteConfig} from "../../../../routers/routers";
import {useNavigate} from "react-router";
import {useMatches} from "react-router-dom";

export const ExtendedRoute: React.FC<{config: RouteConfig}> = ({config}) => {
    const Component = config.component;
    const store = useRequiredStore(RootStoreContext);
    const authStore = store.authStore;
    const navigate = useNavigate();

    if (!isAllowed(authStore.attributes, config.access) && (!config.role || config.role === authStore.role)) {
        navigate('/');
        return null;
    }

    return <Component
        name={config.name}
        requiredConfigs={config.configData}
        routeChildren={config.children}
    />
};