import {Article} from "../articles/article";
import {WholesaleAccount} from "../accounts/customer-account/wholesale-account/wholesale.account";
import {Amount, CurrencyCode} from "../prices/price";
import {CustomUploadFile} from "../../components/core/file-uploader/file-uploader";
import {SystemUser} from "../users/user/system.user";
import {InvoiceDataDocument, InvoiceDetailsItem} from "../documents/invoices/invoice-data/invoice.data";
import {Warehouse} from "../warehouses/warehouse";
import {SearchedMergedInvoice, SearchedMergedInvoiceItem} from "../documents/invoices/merged-invoice/merged.invoice";
import dayjs from "dayjs";

export enum ClaimType
{
    RETURN = "return",
    RECLAMATION = "reclamation"
}

export enum ClaimStatus
{
    RECEIVED = "received",
    ACCEPTED = "accepted",
    CLOSED    = "closed",
}

export type ClaimStatusColor = Record<ClaimStatus, string>;

export const claimStatusColor: ClaimStatusColor =
{
    received: "gold",
    accepted: "green",
    closed  : "red",
}

export enum ClaimItemAcceptStatus
{
    RECEIVED = "received",
    ACCEPTED = "accepted",
    REFUNDED = "refunded",
    REJECTED = "rejected",
    CLARIFICATION_AWAITING = "clarification_awaiting",
}

export enum ClaimItemType
{
    KNOWN = "known",
    UNKNOWN = "unknown",
}

export type ClaimItemAcceptStatusColor = Record<ClaimItemAcceptStatus, string>;

export const claimItemAcceptStatusColor: ClaimItemAcceptStatusColor =
{
    received: "gold",
    accepted: "gold",
    refunded: "green",
    rejected: "red",
    clarification_awaiting: "gold",
}

export type ClaimItemTypeColor = Record<ClaimItemType, string>;

export const claimItemTypeColor: ClaimItemTypeColor =
    {
        [ClaimItemType.KNOWN]: "green",
        [ClaimItemType.UNKNOWN]: "red",
    }

export const claimItemAcceptCardColor: ClaimItemAcceptStatusColor =
{
    received: "#d48806",
    accepted: "#d48806",
    refunded: "#b7eb8f",
    rejected: "#cf1322",
    clarification_awaiting: "#d48806",
}

export interface Claim
{
    id: number;
    type: ClaimType;
    status: ClaimStatus;
    account: WholesaleAccount;
    documentNumber: string;
    documentDate: string;
    subTotal: Amount;
    taxTotal: Amount;
    grandTotal: Amount;
    comments: ClaimComment[]
    items: ClaimItem[]
    user: SystemUser;
}

export interface ClaimComment
{
    id: number;
    claim: Claim;
    user?: SystemUser;
    viewToClient: boolean;
    text: string;
    createdAt: string;
}

export interface ClaimItem
{
    id: number;
    type: ClaimItemType;
    claim: Claim;
    article: Article;

    warehouse?: Warehouse;
    attachments: ClaimItemAttachment[]
    status: ClaimItemAcceptStatus;
    invoiceDocumentNumber: string;
    invoiceDate: string;
    creditInvoiceDocument?: InvoiceDataDocument;
    invoiceDocument?: InvoiceDataDocument;
    saleDocument?: InvoiceDataDocument;
    saleDocumentItem?: InvoiceDetailsItem;
    qty: number;
    priceByItem: Amount;
    description: string;
    fittingDate: string;
    fittingOnMillage: number;
    problemDate: string;
    problemOnMillage: number;
    carBrand: string;
    carModel: string;
    carModelClarification: string;
    carModelYear: number;
    vinNumber: string;
    hasCrashedBoxRetention: boolean;
    hasBadPackingRetention: boolean;
    rejectionReason: ClaimItemRejectReason | "";
    rejectionExplanation: string;
}

export interface ClaimItemAttachment
{
    id: number;
    fileName: string;
    hash: string;
}

// Commands

export interface ClaimStatusUpdate
{
    status: ClaimStatus;
}

export interface ClaimAddComment
{
    viewToClient: boolean;
    text: string;
}

export interface CreateClaim
{
    items: CreateClaimItem[]
    type: ClaimType
}

export type CreateClaimItemType = "known" | "unknown";

export interface CreateClaimItem {
    type?: CreateClaimItemType;
    data?: CreateClaimItemData;
    clarification?: CreateClaimItemClarification;
}

export interface EditClaimItem {
    data?: EditClaimItemData;
    clarification?: CreateClaimItemClarification;
}

export interface EditClaimItemData {
    qty: number;
    description: string;
}

export type CreateClaimItemData = CreateClaimItemWithInvoice | CreateClaimItemWithoutInvoice;

export interface CreateClaimItemWithInvoice
{
    sku: string;
    qty: number;
    description: string;
    images?: CustomUploadFile[];
    invoice?: SearchedMergedInvoice;
    item?: SearchedMergedInvoiceItem;
}

export interface CreateClaimItemWithoutInvoice
{
    sku: string;
    invoiceNumber: string;
    invoiceDate: dayjs.Dayjs;
    qty: number;
    price: number;
    currencyCode: CurrencyCode;
    description: string;
    images?: CustomUploadFile[];
}

export interface CreateClaimItemClarification
{
    fittingDate?: dayjs.Dayjs;
    fittingOnMillage?: number;
    problemDate?: dayjs.Dayjs;
    problemOnMillage?: number;
    carBrand?: string;
    carModel?: string;
    carModelClarification?: string;
    carModelYear?: number;
    vinNumber?: string;
    hasCrashedBoxRetention?: boolean;
    hasBadPackingRetention?: boolean;
}


export enum ClaimItemRejectReason
{
    INSUFFICIENT_DESC = 'insufficient_desc',
    NEED_PHOTO = 'need_photo',
    NEED_VIDEO = 'need_video',
    WRONG_MOUNTING = 'wrong_mounting',
    WARRANTY_EXPIRED = 'warranty_expired',
    NOT_PURCHASED = 'not_purchased',
    OTHER = 'other',
}

export interface ClaimItemReject
{
    reason: ClaimItemRejectReason;
    explanation: string;
}

export interface ClaimItemsReject extends ClaimItemReject, ClaimBatchAction
{
}

export interface CreateClaimCreditGroup {
    warehouse: string;
    usedWarehouse: string;
    description: string;
}

export interface CreateClaimCredit {
    groups: CreateClaimCreditGroup[];
}

export interface CreateClaimCreditWithItems extends CreateClaimCredit {
    items: number[]
}

export interface ClaimSetWarehouseAction extends ClaimBatchAction {
    warehouse: string;
}

export interface ClaimBatchAction {
    items: number[]
}

export interface ClaimCreditGroup {
    key: string;
    currencyCode: string;
    vatNumber: string;
    vatPercent: number;
    warehouse: Warehouse;
    qty: number;
}

export const createEditRequestFromClaimItem = (item: ClaimItem): EditClaimItem => {
    return {
        data: {
            qty: item.qty,
            description: item.description,
        },
        clarification: {
            fittingDate: dayjs(item.fittingDate),
            fittingOnMillage: item.fittingOnMillage,
            problemDate: dayjs(item.problemDate),
            problemOnMillage: item.problemOnMillage,
            carBrand: item.carBrand,
            carModel: item.carModel,
            carModelClarification: item.carModelClarification,
            carModelYear: item.carModelYear,
            vinNumber: item.vinNumber,
            hasCrashedBoxRetention: item.hasCrashedBoxRetention,
            hasBadPackingRetention: item.hasBadPackingRetention,
        }
    }
}