import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import Date from "../../../../../date/date";
import {copyToClipboard} from "../../../../../../utils/clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Button, Space} from "antd";

export const columnsFactory = (
    download: (id: number) => void,
    handle: (id: number) => void,
    show: (id: number) => void,
    compare: (id: number) => void
): ColumnsType<any> => {
    return [
        {
            dataIndex: "direction",
            key: "direction",
            width: "50px",
            render: (data: string) => <>
                {data === 'incoming' ? (
                    <FontAwesomeIcon color={"#ff0000"} icon={["fas", "arrow-down"]} />
                ) : (
                    <FontAwesomeIcon color={"#00ff00"} icon={["fas", "arrow-up"]} />
                )}
            </>
        },
        {
            title: "Filename",
            dataIndex: "filename",
            key: "filename",
            render: (data: string, fields: any) => <a onClick={() => copyToClipboard(fields.path)}>{data}</a>
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status"
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (data: string) => <Date dateString={data} showTime={true} />
        },
        {
            title: "Handled At",
            dataIndex: "handledAt",
            key: "handledAt",
            render: (data: string) => <Date dateString={data} showTime={true} />
        },
        {
            title: "Actions",
            key: "actions",
            render: (_: any, value: any) => <Space direction={"horizontal"} size={[10, 10]}>
                <Button
                    shape="circle"
                    icon={<FontAwesomeIcon icon={["fas", "download"]}/>}
                    onClick={() => download(value.id)}
                />
                <Button
                    type={"primary"}
                    shape="circle"
                    icon={<FontAwesomeIcon icon={["fas", "eye"]}/>}
                    onClick={() => show(value.id)}
                />
                <Button
                    type={"primary"}
                    shape="circle"
                    icon={<FontAwesomeIcon icon={["fas", "not-equal"]}/>}
                    onClick={() => compare(value.id)}
                />
                <Button
                    danger
                    shape="circle"
                    icon={<FontAwesomeIcon icon={["fas", "hard-hat"]}/>}
                    onClick={() => handle(value.id)}
                />
            </Space>
        },
    ];
}