import React, {useState} from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../../utils/store";
import {AgentDataStoreContext} from "../../agent.data.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Button, Col, Form, Row, Select, Typography} from "antd";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {accountService} from "../../../../../services/accounts/account.service";
import {notifications} from "../../../../../services/notifications/notifications";

export const AgentDataAgentAccount: React.FC<{}> = observer(({}) => {
    const {account} = useRequiredStore(AgentDataStoreContext);
    const { commonStore } = useRequiredStore(RootStoreContext);

    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [form] = Form.useForm();

    if (!account) {
        return <></>;
    }

    const submit = async (values: any) => {
        setLoading(true)
        try {
            await accountService.updateGeneral(account.id, values)
            notifications.successfully()
        } catch (e: any) {
            if (e.response?.data.fields) {
                form.setFields(e.response?.data?.fields);
            }
        } finally {
            setLoading(false);
        }
    }

    const defaultValues = {
        legalType: account.legalType,
        status: account.status,
        language: account.language,
        bank: account.bank?.id,
        orderType: account.orderType
    }

    return <>
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            name={"client_form"}
            initialValues={defaultValues}
            onFinish={async (values) => {
                await form.validateFields();
                await submit(values)
            }}
        >
            <Typography.Title level={3}>General</Typography.Title>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={24}>
                    <Row gutter={[30, 0]} align={"top"}>
                        <Col span={6}>
                            <Form.Item
                                name={["legalType"]}
                                label={t("CLIENT.TYPE")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    disabled={loading || disabled}
                                    placeholder={t("CLIENT.TYPE")}
                                    showSearch
                                    allowClear
                                    optionFilterProp={"children"}
                                >
                                    {commonStore.agentTypes.map((item) => (
                                        <Select.Option key={item} value={item}>
                                            {item.beautify()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name={["language"]}
                                label={t("LOCALES")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    placeholder={t("LOCALES")}
                                    disabled={loading || disabled}
                                >
                                    <Select.Option value={"en"}>EN</Select.Option>
                                    <Select.Option value={"bg"}>BG</Select.Option>
                                    <Select.Option value={"sr"}>SR</Select.Option>
                                    <Select.Option value={"de"}>DE</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name={["bank"]}
                                label={t("BANK.NAME")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp={"children"}
                                    disabled={loading || disabled}
                                    placeholder={t("BANK.NAME")}
                                >
                                    {commonStore.banks.map((bank) => (
                                        <Select.Option key={bank.id} value={bank.id}>
                                            {bank.visibleName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name={["orderType"]}
                                label={t("CLIENT.ORDER_TYPE.TITLE")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp={"children"}
                                    disabled={loading || disabled}
                                    placeholder={t("CLIENT.ORDER_TYPE.TITLE")}
                                >
                                    {commonStore.orderTypes.map((item) => (
                                        <Select.Option value={item} key={item}>
                                            {t("CLIENT.ORDER_TYPE." + item.toUpperCase())}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Button disabled={disabled || loading} type={"primary"} onClick={() => form.submit()}>{t("SAVE")}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    </>
});
