import API from "../../utils/api";
import {AxiosInstance} from "axios";
import {PaginatedResult} from "../../models/core/paginated.result";
import {ExtendedUser, UserContacts, UserAccess, PostAccountArguments} from "../../models/users/user/extended.user";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {SearchedUser} from "../../models/users/user/searched-user/searched.user";
import {SystemUserWithAttributes} from "../../models/users/user/system.user-with-attributes";

export class UserService {
    api: AxiosInstance = API;

    getCurrentUserWithAttributes(): Promise<SystemUserWithAttributes> {
        return this.api.get<SystemUserWithAttributes>('loggedin').then(x => x.data);
    }

    getAll(request: PaginatedRequest): Promise<PaginatedResult<ExtendedUser>> {
        return this.api.get<PaginatedResult<ExtendedUser>>(
            "users",
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }

    getById(id: string): Promise<ExtendedUser> {
        return this.api.get<ExtendedUser>("users/" + id).then(x => x.data);
    }

    createUser(values: UserContacts): Promise<ExtendedUser> {
        return this.api.post<ExtendedUser>("users", values).then(x => x.data);
    }

    editUser(userId: number | string, values: UserContacts): Promise<ExtendedUser> {
        return this.api.post<ExtendedUser>(
            "users/" + userId,
            values
        ).then(x => x.data);
    }

    searchUser(value: string, accountType?: string): Promise<SearchedUser[]> {
        return this.api.get<SearchedUser[]>(
            "users/search" + (accountType ? "/" + accountType : ""),
            {
                params: {
                    value
                }
            }
        ).then(x => (x.data));
    }

    connectAccounts(accounts: number[], userId: number): Promise<UserAccess[]> {
        return this.api.post<UserAccess[]>(
            "users/" + userId + "/accounts",
            {accounts}
        ).then(x => x.data);
    }

    setPrimaryAccount(userId: number, scid: number): Promise<void> {
        return this.api.post("users/" + userId + "/accounts/" + scid + "/primary");
    }

    postAccount({userId, values, type, accountId}: PostAccountArguments): Promise<UserAccess > {
        accountId = accountId ? "/" + accountId : "";

        return this.api.post(
            "users/" + userId + "/accounts/" + type + accountId,
            values
        ).then(x => x.data)
    };

    revokeAccess(userId: number, scid: string): Promise<void> {
        return this.api.delete("users/" + userId + "/accounts/" + scid)
    }
}

export const userService = new UserService();
