import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {UserSelect} from "../../users/common/user-select/user-select.component";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import {fakerService} from "../../../services/faker/faker.service";

export const SwitchUserSubMenu: React.FC = observer(() => {

    const { authStore } = useRequiredStore(RootStoreContext);

    const [disabled, setDisabled] = useState<boolean>(false);
    const [value, setValue] = useState<string>("");

    const onSelect = async (value: string) => {
        setDisabled(true)
        setValue(value)
        await fakerService.switchUser(value);
        window.location.reload();
    }

    const onClear = async () => {
        setDisabled(true)
        setValue(value)
        await fakerService.resetUser();
        window.location.reload();
    }

    useEffect(() => {
        setValue(authStore.user?.email || "")
    }, [authStore.user])

    return (
        <UserSelect
            style={{width: 300}}
            disabled={disabled}
            onSelect={onSelect}
            onClear={onClear}
            labelInValue={false}
        />
    )
});