import { AxiosInstance } from "axios";
import API from "../../../utils/api";
import {ArticlesStatus} from "../../../models/articles/articles.status";

export class ArticlesStatusService {
    api: AxiosInstance = API;

    getStatus(sku: string): Promise<ArticlesStatus> {
        return this.api
            .get<ArticlesStatus>("articles/" + sku + "/status")
            .then((x) => x.data);
    }

    hideForClient(sku: string): Promise<void> {
        return this.api.post("articles/" + sku + "/hide-client");
    }

    showForClient(sku: string): Promise<void> {
        return this.api.delete("articles/" + sku + "/hide-client");
    }

    hideForStatistic(sku: string): Promise<void> {
        return this.api.post("articles/" + sku + "/hide-statistic");
    }

    showForStatistic(sku: string): Promise<void> {
        return this.api.delete("articles/" + sku + "/hide-statistic");
    }

    hideForCatalog(sku: string): Promise<void> {
        return this.api.post("articles/" + sku + "/hide-catalog");
    }

    showForCatalog(sku: string): Promise<void> {
        return this.api.delete("articles/" + sku + "/hide-catalog");
    }

    hidePermanentlyForCatalog(sku: string): Promise<void> {
        return this.api.post("articles/" + sku + "/hide-permanently");
    }

    showPermanentlyForCatalog(sku: string): Promise<void> {
        return this.api.delete("articles/" + sku + "/hide-permanently");
    }
}

export const articlesStatusService = new ArticlesStatusService();
