import {Amount} from "../prices/price";
import {SystemUser} from "../users/user/system.user";

export interface Property
{
    code: string;
    description: string;
    id: number;
    location: PropertyLocation;
    purchaseAmount: Amount;
    purchaseDate: string;
    qty: number;
    responsiblePerson: SystemUser;
    supplier: string;
    type: PropertyType;
    files: PropertyFile[];
    requiredPhotoConfirmation: boolean;
}

export enum PropertyFileType
{
     PHOTO = 'photo',
     INVENTORY_PHOTO_CONFIRMATION = 'inventory_photo_confirmation',
     DOCUMENT = 'document'
}

export interface PropertyFile
{
    id: number;
    type: PropertyFileType;
    hash: string;
    description: string;
    fileName: string;
}

export interface PropertyType
{
    id: number;
    name: string;
}

export interface PropertyLocation
{
    id: number;
    name: string;
    code: string;
}

export interface PropertyInventoryTask
{
    id: number;
    createdAt: string;
    startedBy: SystemUser;
    status: PropertyInventoryTaskStatus;
    year: number;
    parts: PropertyInventoryTaskPart[]
}

export interface PropertyInventoryTaskPart
{
    id: number;
    createdAt: string;
    responsiblePersons: SystemUser[];
    status: PropertyInventoryTaskStatus;
    type: PropertyType;
    task: PropertyInventoryTask;
    qty: number;
    confirmedQty: number;
    destroyedQty: number;
    lostQty: number;
    document?: PropertyDocument;
}

export type PropertyInventoryTaskStatus = 'opened' | 'closed'

export type StatusColor = Record<PropertyInventoryTaskStatus, string>;

export const statusColor: StatusColor = {
    opened: "gold",
    closed: "green"
}

export const propertyInventoryTaskPropertyStatuses = ['unhandled', 'partially_handled', 'found', 'partially_found', 'lost']

export type PropertyInventoryTaskPropertyStatus = typeof propertyInventoryTaskPropertyStatuses[number]

export type PropertyStatusColor = Record<PropertyInventoryTaskPropertyStatus, string>;

export const propertyStatusColor: PropertyStatusColor = {
    unhandled: "grey",
    partially_handled: "grey",
    found: "green",
    partially_found: "gold",
    lost: "red",
}

export interface PropertyInventoryTaskProperty
{
    id: number;
    property: Property;
    type: PropertyType;

    location: PropertyLocation;
    code: string;
    description: string;
    qty: number;
    confirmedQty: number;
    destroyedQty: number;
    lostQty: number;
    writeOffQty: number;
    writeOffReason: string;
    status: PropertyInventoryTaskPropertyStatus;

    beforeLastYearReviews: PropertyInventoryTaskPropertyReview[]
}

export type PropertyInventoryTaskPropertyReviewStatus = 'ok' | 'damaged' | 'lost'

export type PropertyInventoryTaskPropertyReviewStatusColor = Record<PropertyInventoryTaskPropertyReviewStatus, string>;

export const reviewStatusColor: PropertyInventoryTaskPropertyReviewStatusColor = {
    ok: "green",
    damaged: "gold",
    lost: "red"
}

export interface PropertyInventoryTaskPropertyReview
{
    comment: string;
    createdAt: string;
    id: number;
    qty: number;
    status: PropertyInventoryTaskPropertyReviewStatus;
    task: PropertyInventoryTask;
    user: SystemUser;
    propertyPhoto?: PropertyFile;
}

export interface PropertyDocument
{
    id: number;
    number: string;
    items: PropertyDocumentItem[];
    accountant: SystemUser;
    date: string;
    createdAt: string;
}

export interface PropertyDocumentItem
{
    id: number;
    qty: number;
    previousQty: number;
    comment: string;
    document: PropertyDocument
}