import {Translate} from "../../../../hooks/translate.hook";
import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {Link} from "react-router-dom";
import {ShippingCourierWithPrice} from "../../../../models/shipping/shipping";
import {displayPrice} from "../../../../models/prices/price.helper";

export const columnsFactory = (t: Translate): ColumnsType<ShippingCourierWithPrice> => {
    return [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (_: any, courier) => <Link to={"shipping-couriers/" + courier.id}>{courier.name}</Link>,
        },
        {
            title: "Pack type",
            dataIndex: "packType",
            key: "packType",
        },
        {
            title: "Estimate",
            dataIndex: "estimate",
            key: "estimate",
        },
        {
            title: "Price",
            dataIndex: "packType",
            key: "packType",
            render: (_: any, courier) => displayPrice(courier.price.cost)
        },
    ];
}