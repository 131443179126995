import {PaginatedResult} from "../../../models/core/paginated.result";
import {PaginatedRequest} from "../../../models/core/paginated.request";

export class CardsViewDataHandler<T>
{
    public lastRequest: PaginatedRequest | null = null;

    public reload: (request: PaginatedRequest) => Promise<PaginatedResult<T>>;

    public onResult: (result: PaginatedResult<T>) => void;

    public onLoadingChange: (loading: boolean) => void;

    constructor(
        dataLoader: (request: PaginatedRequest) => Promise<PaginatedResult<T>>,
    ) {
        this.onResult = () => null;
        this.onLoadingChange = (loading: boolean) => null;
        this.reload = async (request) => {
            try {
                this.onLoadingChange(true);
                this.lastRequest = request;
                const data = await dataLoader(request);
                this.onResult(data);

                return data;
            }
            finally {
                this.onLoadingChange(false);
            }
        }
    }

    setOnResult(fn: (result: PaginatedResult<T>) => void) {
        this.onResult = fn;
    }

    setOnLoadingChange(fn: (loading: boolean) => void) {
        this.onLoadingChange = fn;
    }

    async setPageAndLimit(page: number, limit: number) {
        if (!this.lastRequest) {
            return
        }

        let changed = false;

        if (this.lastRequest.page !== page) {
            this.lastRequest.page = page;
            changed = true;
        }
        if (this.lastRequest.limit !== limit) {
            this.lastRequest.page = 1;
            this.lastRequest.limit = limit;
            changed = true;
        }

        if (changed) {
            await this.reloadItemsWithLastRequest();
        }
    }

    async reloadItemsWithLastRequest() {
        this.lastRequest && await this.reloadItems(this.lastRequest);
    }

    async reloadItems(request: PaginatedRequest) {
        await this.reload(request);
    }

    async reloadItemsWithDefaults() {
        await this.reload(new PaginatedRequest());
    }

    setFilters(filters: [string, string | string[]][]) {
        let request = this.lastRequest;
        if (!request) {
            return;
        }

        request.clearFilters();
        filters.forEach(([name, value]) => {
            request!.addFilter(name, value);
        })
    }

    toValues() {
        if (!this.lastRequest) {
            return {}
        }
        return {
            ...this.lastRequest.filters,
            page: this.lastRequest.page,
            limit: this.lastRequest.limit,
        }
    }

    fromValues(urlParams: Record<string, any>) {
        if (!this.lastRequest) {
            this.lastRequest = new PaginatedRequest();
        }

        const params = {...urlParams};
        this.lastRequest.page  = parseInt(urlParams.page as string) || 1;
        this.lastRequest.limit = parseInt(urlParams.limit as string) || 20;

        delete params.page;
        delete params.limit;

        this.setFilters(Object.keys(params).map(key => {
            return [key, params[key] as []]
        }))
    }
}