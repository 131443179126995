import React, {useEffect, useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Modal} from "antd";
import {StepConfig, Stepper} from "../../../common/stepper/stepper";
import {SelectInvoicesStep} from "./steps/select-invoices.step";
import {SetParcelDimensionsStep} from "./steps/set-parcel-dimensions.step";
import {SelectCourierStep} from "./steps/select-courier.step";
import {preShipmentsService} from "../../../../services/shipments/pre-shipments.service";
import {PreShipmentParcelInfo} from "../../../../models/parcels/pre-shipments";
import {Shipment} from "../../../../models/parcels/shipment";
import {useNavigate} from "react-router";
import {AddressStep} from "./steps/address.step";
import {PayerOrReceiverDetails} from "../../../../models/payer-receiver-seller/payer.reveiver.seller";

interface ShipModalProps {
    agentCode: string;
    onCancel: () => void;
    visible: boolean;
}

interface ShipContextValue {
    step: number;
    setStep: (step: number) => void;
    selectedDocuments: number[];
    setSelectedDocuments: (values: number[]) => void;
    parcels: PreShipmentParcelInfo[];
    setParcels: (values: PreShipmentParcelInfo[]) => void;
    agentCode: string;
    onComplete: (shipment: Shipment) => void;
    address: object | null;
    setAddress: (address: object) => void;
}

export const ShipContext = React.createContext<ShipContextValue>({
    step: 0,
    setStep: (step: number) => null,
    selectedDocuments: [],
    setSelectedDocuments: (values: number[]) => null,
    parcels: [],
    setParcels: (values: PreShipmentParcelInfo[]) => null,
    agentCode: "",
    onComplete: () => null,
    address: null,
    setAddress: () => null,
});

export const ShipModal: React.FC<ShipModalProps> = ({agentCode, visible, onCancel}) => {

    const t = useTranslate();

    const [step, setStep] = useState<number>(0);

    const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);

    const [parcels, setParcels] = useState<PreShipmentParcelInfo[]>([]);
    const [address, setAddress] = useState<object | null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (selectedDocuments.length > 0) {
            preShipmentsService.getReadyToShipParcelsByDocuments(selectedDocuments).then(({parcels, address}) => {
                setParcels(parcels)
                setAddress({...address, country: address.country.id})
            });
        }
    }, [selectedDocuments]);

    const steps: StepConfig<{}>[] = [
        {
            title: 'Invoices',
            description: 'Select invoices',
            component: SelectInvoicesStep
        },
        {
            title: 'Dimensions',
            description: 'Set dimensions',
            component: SetParcelDimensionsStep
        },
        {
            title: 'Address',
            description: 'Delivery address',
            component: AddressStep
        },
        {
            title: 'Ship',
            description: 'Ship parcels',
            component: SelectCourierStep
        },
    ]

    return <Modal
        title={"Ship"}
        visible={visible}
        onCancel={onCancel}
        width={"900px"}
        footer={false}
    >
        <ShipContext.Provider value={{
            step,
            setStep,
            selectedDocuments,
            setSelectedDocuments,
            agentCode,
            parcels,
            setParcels,
            onComplete: (shipment: Shipment) => navigate("/shipment/" + shipment.id),
            address,
            setAddress
        }}>
            <Stepper
                current={step}
                steps={steps}
            />
        </ShipContext.Provider>
    </Modal>
}