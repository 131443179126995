import React, {useCallback, useEffect, useState} from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Button, Dropdown, Menu, Popconfirm, Space, Typography} from "antd";
import { ColumnsType } from "antd/es/table";
import { DocumentMasterOrder } from "../../../../models/documents/master-order";
import { DocumentStatus } from "../../../../models/documents/status";
import { FieldTypeName } from "../../../../models/filter/filter.field.type.name";
import { FieldGroupConfig } from "../../../../models/filter/filter.group";
import { Amount } from "../../../../models/prices/price";
import { displayPrice } from "../../../../models/prices/price.helper";
import { Proforma } from "../../../../models/documents/proformas/proforma";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
import { proformasService } from "../../../../services/documents/proformas/proformas.service";
import { DocumentsMenu } from "../../../common/documents/menu/documents.menu";
import { DocumentsStatus } from "../../../common/documents/status/documents.status";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import Date from "../../../date/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {isAllowed} from "../../../../utils/helpers";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {FilterDataHandler} from "../../../../models/filter/filter.data.handler";
import {PaginatedRequest} from "../../../../models/core/paginated.request";
import {ProformaCommentsPopover} from "../comments/proforma.comments-popover";

enum ProformasFilterButtonTypes {
    ALL = "all",
    OPENED = "opened",
    CLOSED = "closed",
    CANCELLED = "cancelled",
}

const filterButtons = [
    {
        type: ProformasFilterButtonTypes.ALL,
        text: "PROFROMAS.ALL",
        filters: [],
    },
    {
        type: ProformasFilterButtonTypes.OPENED,
        text: "DOCUMENTS.STATUS.OPENED",
        filters: [
            {
                name: "status",
                snapshots: [
                    {
                        name: "status",
                        rule: undefined,
                        value: "opened",
                    },
                ],
            },
        ],
    },
    {
        type: ProformasFilterButtonTypes.CLOSED,
        text: "DOCUMENTS.STATUS.CLOSED",
        filters: [
            {
                name: "status",
                snapshots: [
                    {
                        name: "status",
                        rule: undefined,
                        value: "closed",
                    },
                ],
            },
        ],
    },
];

interface ProformasListProps extends ComponentPropsFromRoute {}
export const ProformasList: React.FC<ProformasListProps> = observer(({ name }) => {
    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);
    const t = useTranslate();

    const [request, setRequest] = useState<PaginatedRequest>();

    const [dataHandler] = useState(() => new FilterDataHandler((request) => proformasService.getProformas(request)));

    const cancelProforma = useCallback((document: Proforma) => {
        proformasService.cancelProforma(document.id).then(() => {
            dataHandler.reloadItems(request!);
        });
    }, [request]);

    const cancelProformaWithOrders = useCallback((document: Proforma) => {
        proformasService.cancelProformaWithOrders(document.id).then(() => {
            dataHandler.reloadItems(request!);
        });
    }, [request]);

    const columns: ColumnsType<Proforma> = [
        {
            title: t("DOCUMENT_NUMBER"),
            dataIndex: "docNumber",
            key: "proformas_docNumber",
            width: "120px",
            render: (item: string | null, document) => (
                item ? (
                    <ProformaCommentsPopover document={document}>
                        <Link to={"/proformas/" + document.id} style={{ whiteSpace: "nowrap", width: "auto" }}>{item}</Link>
                    </ProformaCommentsPopover>
                ) : null),
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "proformas_status",
            render: (status: DocumentStatus) => <DocumentsStatus status={status} />,
        },
        {
            title: t("ORDERS.ORDER.TITLE"),
            dataIndex: "masterOrder",
            key: "proformas_masterOrder",
            width: "100px",
            render: (item: DocumentMasterOrder | null) =>
                item ? <Link to={"/orders/" + item.id}>{item.id}</Link> : null,
        },

        {
            title: t("CLIENT.NAME"),
            dataIndex: "agent",
            key: "proformas_client",
            width: "120px",
            render: (agent) => (
                <Link to={"/clients/" + agent.number}>{agent.name}</Link>
            ),
        },
        {
            title: t("PAYMENT_TERMS"),
            dataIndex: "paymentTerms",
            key: "paymentTerms",
            width: 105
        },
        {
            title: t("TOTAL_PRICE"),
            dataIndex: "subtotal",
            key: "proformas_subtotal",
            render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
        },
        {
            title: "VAT",
            dataIndex: "subtotalVat",
            key: "proformas_subtotalVat",
            render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "grandTotal",
            key: "proformas_grandTotal",
            render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
        },
        {
            title: t("DATE"),
            dataIndex: "date",
            key: "proformas_date",
            render: (date: string | null) => (date ? <Date dateString={date} showTime withSeconds /> : null),
        },
        {
            title: "",
            key: "action",
            render: (document: Proforma) => {

                if (!isAllowed(attributes, "document_proforma_create") || document.status !== 'opened') {
                    return null;
                }

                return (
                    <Dropdown overlay={
                        <Menu>
                            <Menu.Item key="0" onClick={() => cancelProformaWithOrders(document)}>
                                {t('PROFORMA.CANCEL_AND_CANCEL_ORDER')}
                            </Menu.Item>
                            <Menu.Item key="1" onClick={() => cancelProforma(document)}>
                                {t('PROFORMA.CANCEL')}
                            </Menu.Item>
                        </Menu>
                    } trigger={['click']}>
                        <a className="ant-dropdown-link" style={{color: '#777'}} onClick={e => e.preventDefault()}>
                            <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                        </a>
                    </Dropdown>
                )
            }
        },
    ];

    const columnsConfigs = [
        {
            title: "DOCUMENT_NUMBER",
            key: "proformas_docNumber",
        },
        {
            title: "STATUS",
            key: "proformas_status",
        },
        {
            title: "ORDERS.ORDER.TITLE",
            key: "proformas_masterOrder",
        },

        {
            title: "CLIENT.NAME",
            key: "proformas_client",
        },
        {
            title: "PAYMENT_TERMS",
            key: "proformas_payment_terms",
        },

        {
            title: "TOTAL_PRICE",
            key: "proformas_subtotal",
        },
        {
            title: "VAT.TITLE",
            key: "proformas_subtotalVat",
        },
        {
            title: "TOTAL_AMOUNT",
            key: "proformas_grandTotal",
        },
        {
            title: "DATE",
            key: "proformas_date",
        },
        {
            title: 'Action',
            key: 'action',
        },
    ];

    const filterGroups: FieldGroupConfig[] = [
        {
            name: "docNumber",
            visibleName: "DOCUMENT_NUMBER",
            fields: [
                {
                    name: "docNumber",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "status",
            visibleName: "STATUS",
            fields: [
                {
                    name: "status",
                    type: FieldTypeName.SELECT,
                    multiple: true,
                    choices: [
                        { name: "Opened", value: "opened" },
                        { name: "Closed", value: "closed" },
                        { name: "Cancelled", value: "cancelled" },
                    ],
                },
            ],
        },
        {
            name: "order",
            visibleName: "ORDERS.ORDER.TITLE",
            fields: [
                {
                    name: "order",
                    type: FieldTypeName.STRING,
                },
            ],
        },

        {
            name: "client",
            visibleName: "CLIENT.NAME",
            fields: [
                {
                    name: "clientName",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "paymentTerms",
            visibleName: "PAYMENT_TERMS",
            fields: [
                {
                    name: "clientPaymentTerms",
                    type: FieldTypeName.INTEGER,
                },
            ],
        },

        {
            name: "subtotal",
            visibleName: "TOTAL_PRICE",
            fields: [
                {
                    name: "subtotal",
                    type: FieldTypeName.INTEGER,
                },
            ],
        },
        {
            name: "subtotalVat",
            visibleName: "VAT.TITLE",
            fields: [
                {
                    name: "subtotalVat",
                    type: FieldTypeName.INTEGER,
                },
            ],
        },
        {
            name: "grandTotal",
            visibleName: "TOTAL_AMOUNT",
            fields: [
                {
                    name: "grandTotal",
                    type: FieldTypeName.INTEGER,
                },
            ],
        },

        {
            name: "date",
            visibleName: "DATE",
            fields: [
                {
                    name: "date",
                    type: FieldTypeName.DATE_RANGE,
                },
            ],
        },
    ];

    return (
        <Space direction="vertical" size={[50, 50]}>
            <DocumentsMenu />
            <Space direction="vertical" size={[0, 0]}>
                <Typography.Title level={3}>{t("PROFORMAS.TITLE")}</Typography.Title>
                <Space direction={"vertical"} size={[15, 15]}>
                    <FilteredTable
                        columns={columns}
                        columnsConfigs={{ columns: columnsConfigs, pageKey: name }}
                        filterGroups={filterGroups}
                        filterButtons={filterButtons}
                        dataHandler={dataHandler}
                        getRequest={setRequest}
                    />
                </Space>
            </Space>
        </Space>
    );
});
