import {Translate} from "../../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {Link} from "react-router-dom";
import Date from "../../../../../date/date";
import {Invoice} from "../../../../../../models/documents/invoices/invoice";
import {DocumentsStatus} from "../../../../../common/documents/status/documents.status";
import {DocumentStatus} from "../../../../../../models/documents/status";

export const columnsFactory = (t: Translate): ColumnsType<Invoice> => {
    return [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "100px",
            render: (id: number) => <Link to={"/invoices/" + id}>{id}</Link>
        },
        {
            title: t("NUMBER"),
            dataIndex: "docNumber",
            key: "docNumber",
            sorter: (a, b) => a.docNumber > b.docNumber ? 1 : -1
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            width: "125px",
            render: (item: DocumentStatus, row) => <DocumentsStatus status={item} />,
            sorter: (a, b) => a.status > b.status ? 1 : -1
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "date",
            key: "date",
            width: 150,
            render: (item: string) => <Date dateString={item} showTime />,
            sorter: (a, b) => a.date > b.date ? 1 : -1
        }
    ]
}