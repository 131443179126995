import React, {useState} from "react";
import {observer} from "mobx-react";
import {Col, Form, Modal, Row} from "antd";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {useTranslate} from "../../../../hooks/translate.hook";
import {NumberInput} from "../../../common/number-input/number.input";
import dayjs from "dayjs";

interface InvoicesExportModalProps {
    visible: boolean,
    onClose: () => void,
    onSuccess: (value: {weight: number}) => Promise<void>
}
export const InvoicesExportModal: React.FC<InvoicesExportModalProps> = observer(({visible, onClose, onSuccess}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={t("ORDERS.ORDER.VIEW.GENERATE_EXPORT_INVOICE")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"dispatch_modal"}
                initialValues={{
                    date: dayjs()
                }}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        onSuccess(values)
                            .then(() => {
                                form.resetFields();
                                onClose();
                            }, (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                            .then(() =>
                                    onClose(),
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item name="weight" label={t("WEIGHT")}>
                            <NumberInput placeholder={t("WEIGHT")}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
})