import * as React from "react";

export const nonStrictComparator = (getter: (value: any) => string | number) => {
    return (v: React.Key | boolean, r: any) => {
        let testValue = getter(r)
        if (typeof testValue !== 'string') {
            testValue = String(testValue);
        }

        return -1 !== testValue.toLowerCase().indexOf((v as string).toLowerCase())
    }
}

export const strictComparator = (getter: (value: any) => string | number) => {
    return (v: React.Key | boolean, r: any) => {
        let testValue = getter(r)
        if (typeof testValue !== 'string') {
            testValue = String(testValue);
        }

        return testValue.toLowerCase() === (v as string).toLowerCase()
    }
}