import React from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../../utils/store";
import {OrdersCheckoutStoreContext} from "../orders.checkout.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Button, Modal, Row, Typography} from "antd";

export const OrdersCheckoutNotValidItemsModal: React.FC = observer(() => {
    const store = useRequiredStore(OrdersCheckoutStoreContext);
    const t = useTranslate();

    return (
        <Modal
            centered
            visible={store.notValidItemsModalShown}
            title={t("ORDERS.NOT_VALID_ITEMS.ALERT.TITLE")}
            onCancel={store.closeNotValidItemsModal}
            footer={
                <Row justify="end">
                    <Button type="primary" onClick={store.onNotValidItemsModalConfirm}>
                        {t("ORDERS.CREATE.CONFIRM")}
                    </Button>
                    <Button danger type="primary" onClick={store.onNotValidItemsModalCancel}>
                        {t("ORDERS.CANCEL.TITLE")}
                    </Button>
                </Row>
            }
        >
            <Typography.Paragraph>
                {t("ORDERS.NOT_VALID_ITEMS.ALERT.TEXT")}
            </Typography.Paragraph>
        </Modal>
    )
})