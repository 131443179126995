import React from "react";
import {PlannerTask, PlannerTaskSystemStatus} from "../../../../../../../models/planner/planner";
import {PlannerTasksViewAction} from "../task.view.action";
import {Action} from "../../model";
import {plannerService} from "../../../../../../../services/planner/planner.service";
import {SystemUserRole} from "../../../../../../../models/users/user/system.user-with-attributes";

export const PlannerTasksViewCancelAction: Action = ({task, reload}) => {

    const run = async () => {
        await plannerService.cancel(task.id);
        reload();
    }

    return (
        <PlannerTasksViewAction
            title={"CANCEL"}
            run={run}
            danger
        />
    )
}

PlannerTasksViewCancelAction.isApplicable = (task: PlannerTask, role: SystemUserRole) => {
    return role === "superior" && [
        PlannerTaskSystemStatus.TODO,
        PlannerTaskSystemStatus.PENDING,
        PlannerTaskSystemStatus.ON_REVIEW
    ].includes(task.status);
}