import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {WholesaleAccountMeta} from "../../models/agents/agent-data/agent.data";
import {AgentDataAccountRequest} from "../../models/agents/agent-data/agent-data-accounts/agent.data.account.request";
import {AgentDataAccountResponse} from "../../models/agents/agent-data/agent-data-accounts/agent.data.account.response";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";
import {AgentDataCustomPrice} from "../../models/agents/agent-data/agent-data-custom-price/agent.data.custom.price";
import {
    AgentDataIntegration,
    IntegrationConnectionTestValues
} from "../../models/agents/agent-data/agent-data-integrations/agent.data.integration";
import {CustomerAccountContact} from "../../models/accounts/customer-account/contact/customer.account.contact";
import {CustomerAccountAddress} from "../../models/accounts/customer-account/address/customer.account.address";
import {CustomerAccountAgreement} from "../../models/accounts/customer-account/agreement/customer.account.agreement";
import {MetaResult} from "../../models/meta-result/meta.result";
import {WholesaleAccount} from "../../models/accounts/customer-account/wholesale-account/wholesale.account";
import {AccountDataRequest} from "../../models/agents/agent-data/agent.data.request";
import {AgentICAccessGroup} from "../../models/agents/agent";

export class AgentDataService {
    api: AxiosInstance = API;

    getWholesaleAccountByAgentNumber(number: string): Promise<MetaResult<WholesaleAccount, WholesaleAccountMeta>> {
        return this.api.get<MetaResult<WholesaleAccount, WholesaleAccountMeta>>('accounts/wholesale/agent-number/' + number).then(x => x.data);
    }

    async getAgentGroups(): Promise<string[]> {
        return ["wholesale", "retail"]
    }

    async getAgreementTypes(): Promise<string[]> {
        return ["couriers", "shipping", "invoices", "other", "additional"];
    }

    getICAccessGroups(): Promise<AgentICAccessGroup[]> {
        return this.api.get("agents/ic/access-groups").then(x => x.data);
    }

    createContact(contact: CustomerAccountContact, accountId: number): Promise<CustomerAccountContact> {
        return this.api.post<CustomerAccountContact>("accounts/" + accountId + "/contacts", contact).then(x => x.data);
    }

    updateContact(contact: CustomerAccountContact, contactId: number): Promise<CustomerAccountContact> {
        return this.api.post<CustomerAccountContact>("accounts/contacts/" + contactId, contact).then(x => x.data);
    }

    removeContact(contactId: number): Promise<void> {
        return this.api.delete("accounts/contacts/" + contactId);
    }

    createWholesaleAccount(request: AccountDataRequest): Promise<WholesaleAccount> {
        return this.api.post<WholesaleAccount>("accounts/wholesale", request).then(x => x.data);
    }

    updateWholesaleAccount(request: AccountDataRequest, id: number): Promise<WholesaleAccount> {
        return this.api.post<WholesaleAccount>("accounts/wholesale/" + id, request).then(x => x.data);
    }

    createAddress(address: CustomerAccountAddress, accountId: number): Promise<CustomerAccountAddress> {
        return this.api.post<CustomerAccountAddress>("accounts/" + accountId + "/addresses", address).then(x => x.data);
    }

    updateAddress(address: CustomerAccountAddress, addressId: number): Promise<CustomerAccountAddress> {
        return this.api.post<CustomerAccountAddress>("accounts/addresses/" + addressId, address).then(x => x.data);
    }

    removeAddress(addressId: number): Promise<void> {
        return this.api.delete("accounts/addresses/" + addressId);
    }

    updateDefaultAddress(address: CustomerAccountAddress, addressId: number): Promise<CustomerAccountAddress> {
        return this.api.post<CustomerAccountAddress>("accounts/addresses/" + addressId + "/default").then(x => x.data);
    }

    createAccount(accountData: AgentDataAccountRequest, accountId: number): Promise<AgentDataAccountResponse> {
        return this.api.post<AgentDataAccountResponse>("accounts/wholesale/" + accountId + "/users", accountData).then(x => x.data);
    }

    updateAccount(accountData: AgentDataAccountRequest, accountId: number, userId: number): Promise<AgentDataAccountResponse> {
        return this.api.post<AgentDataAccountResponse>("accounts/wholesale/" + accountId + "/users/" + userId, accountData).then(x => x.data);
    }

    removeAccount(accountId: number, userId: number): Promise<void> {
        return this.api.delete("accounts/wholesale/" + accountId + "/users/" + userId);
    }

    createAgreement(agreement: CustomerAccountAgreement, accountId: number): Promise<CustomerAccountAgreement> {
        return this.api.post<CustomerAccountAgreement>("accounts/" + accountId + "/agreements", agreement).then(x => x.data);
    }

    updateAgreement(agreement: CustomerAccountAgreement, agreementId: number): Promise<CustomerAccountAgreement> {
        return this.api.post<CustomerAccountAgreement>("accounts/agreements/" + agreementId, agreement).then(x => x.data);
    }

    removeAgreement(agreementId: number): Promise<void> {
        return this.api.delete("accounts/agreements/" + agreementId);
    }

    getCustomPrices(request: PaginatedRequest, accountId: number): Promise<PaginatedResult<AgentDataCustomPrice>> {
        return this.api.get<PaginatedResult<AgentDataCustomPrice>>(
            "accounts/" + accountId + "/custom-prices",
            {
                params: request.toParams(),
            }
        ).then(x => x.data);
    }

    createCustomPrice(accountId: number, values: {article: string, amount: string}): Promise<AgentDataCustomPrice> {
        return this.api.post<AgentDataCustomPrice>(
            "accounts/" + accountId + "/custom-prices",
            values
        ).then(x => x.data);
    }

    updateCustomPrices(customPriceId: number, values: {amount: string}): Promise<{id: number}> {
        return this.api.post<{id: number}>(
            "article/custom-price/" + customPriceId,
            values
        ).then(x => x.data);
    }

    removeCustomPrice(id: number): Promise<void> {
        return this.api.delete("article/custom-price/" + id);
    }

    getIntegration(id: string): Promise<AgentDataIntegration> {
        return this.api.get("accounts/integrations/" + id).then(x => x.data);
    }

    createAgentIntegration(values: AgentDataIntegration, accountId: number): Promise<AgentDataIntegration> {
        return this.api.post<AgentDataIntegration>(
            "accounts/" + accountId + "/integrations",
            values
        ).then(x => x.data);
    }

    updateAgentIntegration(values: AgentDataIntegration, integrationId: number): Promise<AgentDataIntegration> {
        return this.api.post<AgentDataIntegration>(
            "accounts/integrations/" + integrationId,
            values
        ).then(x => x.data);
    }

    removeAgentIntegration(integrationId: number) {
        return this.api.delete("accounts/integrations/" + integrationId);
    }

    testIntegrationConnection(values: IntegrationConnectionTestValues, id?: number): Promise<any> {
        const url = id ? "accounts/integrations/" + id + "/test" : "accounts/integrations/test";
        return this.api.post<any>(url, values);
    }

    copyToSandBox(accountId: number): Promise<void> {
        return this.api.post("accounts/" + accountId + "/sandbox", {});
    }


    uploadIntegrationData(integrationId: number) {
        return this.api.post("accounts/integrations/" + integrationId + "/upload");
    }

}

export const agentDataService = new AgentDataService();
