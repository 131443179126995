import { isEmpty, isEqual } from "lodash";
import { action, makeObservable, observable } from "mobx";
import React from "react";
import { PaginatedRequest } from "../../../models/core/paginated.request";
import { FilterDataHandler } from "../../../models/filter/filter.data.handler";
import { FieldStateGroup } from "../../../models/filter/filter.field.state";
import { fieldTypes } from "../../../models/filter/filter.field.type.config";
import { FilterValues } from "../../../models/filter/filter.props";
import { FilterButtonConfig } from "../../../models/filtered-table/filtered.table.props";
import { FieldRuleName, filterRuleMap } from "../../../models/filter/filter.field.rule";
import { createFieldGroupSnapshot, FieldGroupSnapshot } from "../../../models/filter/filter.field.snapshot";
import { FieldState } from "../../../models/filter/filter.field.state";
import queryString from "query-string";

export class FilteredTableStore<T, B> {
    @observable
    public columnsSettingsModalShown: boolean = false;

    @observable
    public selectedColumns: string[] = !this.pageKey
        ? this.defaultColumns.map((item) => item.value)
        : localStorage[this.pageKey]
        ? JSON.parse(localStorage[this.pageKey])
        : this.defaultColumns.map((item) => item.value);

    @observable
    public total: number = 0;

    @observable
    public limitVariants: string[] = ["10", "20", "30", "40", "50", "100"];

    @observable
    public activeFilterButton: B | undefined = this.getActiveFilterButton();

    @observable
    public filters: FieldStateGroup[] = [];

    @observable
    public filtersSnapshots?: FieldGroupSnapshot[];

    @observable
    public request: PaginatedRequest;

    constructor(
        private forceLoad: boolean,
        private listenToUrl: boolean,
        public dataHandler: FilterDataHandler<T>,
        public defaultColumns: { title: string; value: string }[],
        filters: FieldGroupSnapshot[] = [],
        public pageKey?: string,
        public filterButtonConfigs?: FilterButtonConfig<B>[]
    ) {
        makeObservable(this);
        this.setPage = this.setPage.bind(this);
        this.setLimit = this.setLimit.bind(this);
        this.reloadDataByPopState = this.reloadDataByPopState.bind(this);
        this.openColumnsSettingsModal = this.openColumnsSettingsModal.bind(this);
        this.onLngChange = this.onLngChange.bind(this);
        this.selectColumns = this.selectColumns.bind(this);
        this.closeColumnsSettingsModal = this.closeColumnsSettingsModal.bind(this);
        this.onFilterButtonClick = this.onFilterButtonClick.bind(this);
        this.changeActiveButton = this.changeActiveButton.bind(this);

        this.filtersSnapshots = isEmpty(queryString.parse(window.location.search.substr(1)))
            ? filters
            : !!queryString.parse(window.location.search.substr(1))?.filter
            ? this.getSnapshotFromUrl(queryString.parse(window.location.search.substr(1))?.filter)
            : [];
        this.request = new PaginatedRequest();
        this.activeFilterButton = this.getActiveFilterButton();

        if (this.listenToUrl) {
            this.updateRequestParamsFromURL();
        }

        if (this.forceLoad) {
            this.reloadData();
        }
    }

    public onLngChange(): void {
        this.reloadData();
    }

    private getActiveFilterButton(): B | undefined {
        const parsedUrl = queryString.parse(window.location.search.substr(1));
        if (isEmpty(parsedUrl)) {
            const config = this.filterButtonConfigs?.find((item) => isEqual(item.filters, this.filtersSnapshots));

            return config?.type;
        }

        const filterFromUrl = this.getFiltersFromUrl();

        if (filterFromUrl) {
            const config = this.filterButtonConfigs?.find((item) => isEqual(item.filters, filterFromUrl));
            return config?.type;
        }
        return undefined;
    }

    private getFiltersFromUrl(): FieldGroupSnapshot[] {
        const params: any = queryString.parse(window.location.search.substr(1));
        if (!params.filter) {
            return [];
        }
        return this.getSnapshotFromUrl(params.filter);
    }

    public onFilterButtonClick(type: B): void {
        this.setActiveFilterButtonChange(type);
        const filterButton = this.filterButtonConfigs?.find((item) => item.type === type);

        if (filterButton) {
            this.setFiltersSnapshots(filterButton.filters);
        }
    }

    public changeActiveButton(filterGroups: FieldStateGroup[]): void {
        const snapshots = filterGroups.map((group) => createFieldGroupSnapshot(group));
        const button = this.filterButtonConfigs?.find((item) => isEqual(snapshots, item.filters));
        if (button) {
            this.activeFilterButton = button.type;
            return;
        }
        this.activeFilterButton = undefined;
    }

    @action
    public setActiveFilterButtonChange(value: B): void {
        this.activeFilterButton = value;
    }

    @action
    private setColumnsSettingsModalShown(value: boolean): void {
        this.columnsSettingsModalShown = value;
    }

    @action
    private setSelectedColumns(value: string[]): void {
        this.selectedColumns = value;
    }

    public selectColumns({ columns }: { columns: string[] }): void {
        this.setSelectedColumns(columns);
        if (this.pageKey) {
            localStorage.setItem(this.pageKey, JSON.stringify(columns));
        }
    }

    public openColumnsSettingsModal(): void {
        this.setColumnsSettingsModalShown(true);
    }

    public closeColumnsSettingsModal(): void {
        this.setColumnsSettingsModalShown(false);
    }

    @action
    public setPage(page: number) {
        this.request.page = page;
        this.updateUrlQueryString();
        this.reloadData();
    }

    @action
    public setLimit(limit: number) {
        this.request.limit = limit;
        this.updateUrlQueryString();
        this.reloadData();
    }

    public async reloadData() {
        this.dataHandler.setLoading(true);
        await this.dataHandler.reloadItems(this.request);
        this.dataHandler.setLoading(false);
    }

    private buildUrlString(group: FieldStateGroup, state: FieldState, valuesString: string): string {
        let urlString = group.name + "__" + state.config.name + "=" + encodeURIComponent(valuesString);
        if (fieldTypes[state.config.type].supportedRules.length > 0) {
            urlString += "|" + state.rule;
        }
        return urlString;
    }

    @action
    public updateFilters = (filterStateGroups: FieldStateGroup[], requestFilters: FilterValues): void => {
        this.request.filters = requestFilters;
        this.filters = filterStateGroups;
        if (this.filterButtonConfigs) {
            this.changeActiveButton(filterStateGroups);
        }

        this.reloadData();
    };

    public onFilterInit = (filterStateGroups: FieldStateGroup[], requestFilters: FilterValues): void => {
        const params: any = queryString.parse(window.location.search.substr(1));
        this.request.page = params.page ? parseInt(params.page) : 1;
        this.request.limit = params.limit ? parseInt(params.limit) : 20;
        this.updateFilters(filterStateGroups, requestFilters);
    }

    public onFilterUpdated = (filterStateGroups: FieldStateGroup[], requestFilters: FilterValues): void => {
        this.setFiltersSnapshots(filterStateGroups.map((item) => createFieldGroupSnapshot(item)));
       // this.onFilterInternalUpdated(filterStateGroups, requestFilters);
    };

    public onFilterInternalUpdated = (filterStateGroups: FieldStateGroup[], requestFilters: FilterValues): void => {
        this.request.page = 1;
        this.updateFilters(filterStateGroups, requestFilters);
        this.updateUrlQueryString();
    };

    @action
    public reloadDataByPopState() {
        this.updateRequestParamsFromURL();
        this.reloadData();
    }

    @action
    public setFiltersSnapshots = (value: FieldGroupSnapshot[]) => {
        this.filtersSnapshots = value;
    };

    private updateRequestParamsFromURL() {
        const params: any = queryString.parse(window.location.search.substr(1));
        if (isEmpty(params)) {
            return;
        }

        if (params.filter) {
            const snapshotGroups = this.getSnapshotFromUrl(params.filter);
            this.setFiltersSnapshots(snapshotGroups);
        }

        // this.request.filters =

        this.request.page = params.page ? parseInt(params.page) : 1;
        this.request.limit = params.limit ? parseInt(params.limit) : 20;
    }

    private getSnapshotFromUrl(filter: any): FieldGroupSnapshot[] {
        const snapshotGroups: FieldGroupSnapshot[] = [];

        filter.split("&").forEach((item: string) => {
            const [groupName, rest]: string[] = item.split("__");
            const [fieldName, valueAndRule] = rest.split("=");
            const [value, rule] = valueAndRule.split("|");
            let snapshotGroup = snapshotGroups.find((item) => item.name === groupName);

            if (!snapshotGroup) {
                snapshotGroup = {
                    name: groupName,
                    snapshots: [],
                };
                snapshotGroups.push(snapshotGroup);
            }

            snapshotGroup.snapshots.push({
                rule: this.findRule(rule),
                value: decodeURIComponent(value),
                name: fieldName,
            });
        });
        return snapshotGroups;
    }
    private findRule(rule: string): FieldRuleName | undefined {
        for (const ruleKey in filterRuleMap) {
            if (filterRuleMap[ruleKey as FieldRuleName].value === rule) {
                return ruleKey as FieldRuleName;
            }
        }
        return undefined;
    }

    private updateUrlQueryString() {
        if (this.listenToUrl) {
            const serializeStrings: string[] = [];

            this.filters.forEach((group) => {
                group.states.forEach((state) => {
                    serializeStrings.push(
                        this.buildUrlString(group, state, fieldTypes[state.config.type].toUrl(state))
                    );
                });
            });

            const link = queryString.stringify({
                filter: serializeStrings.join("&"),
                page: this.request.page,
                limit: this.request.limit,
            });
            window.history.pushState("", "", "?" + link);
        }
    }
}
export const FilteredTableStoreContext = React.createContext<null | FilteredTableStore<any, any>>(null);
