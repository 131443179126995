import React, {useEffect, useState} from "react";
import {Select, Tag} from "antd";
import {WidgetProps} from "../../../../../common/ui/input/widget/widget";
import {plannerService} from "../../../../../../services/planner/planner.service";
import {PlanerTag} from "../../../../../../models/departments/planer_tags";

interface UiInputWidgetTagsProps extends WidgetProps {
    taskId: number;
}

export const UiInputWidgetTags: React.FC<UiInputWidgetTagsProps> = ({taskId, value, onChange, multiple}) => {

    const [tags, setTags] = useState<PlanerTag[]>([]);

    useEffect(() => {
        plannerService.getAvailableTags(taskId).then(tags => setTags(tags));
    }, [])

    return (
        <Select
            mode={multiple ? "multiple" : undefined}
            onChange={onChange as any}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
            maxTagCount={1}
        >
            {tags.map(tag => (
                <Select.Option value={tag.id}>
                    <Tag color={tag.color}>{tag.name}</Tag>
                </Select.Option>
            ))}
        </Select>
    )
}