import {DatePicker, Form, Modal} from "antd";
import React, {useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {claimService} from "../../../../services/claims/claims.service";
import {UIInputWidgetWarehouse} from "../../../common/ui/input/widget/warehouse/ui.input.widget.warehouse";
import dayjs from "dayjs";
interface ClaimGuessModalProps {
    claimId: number;
    onClose: () => void;
    onComplete: () => void;
}

export const ClaimGuessModal: React.FC<ClaimGuessModalProps> = ({claimId, onClose, onComplete}) => {

    const t = useTranslate();

    const [form] = Form.useForm();

    const [disabled, setDisabled] = useState(false);

    return <Modal
        title={t("GUESS_INVOICES_FOR_UNKNOWNS")}
        width={500}
        destroyOnClose
        visible={true}
        onCancel={onClose}
        onOk={() => {
            form.validateFields()
                .then(values => {
                    setDisabled(true);
                    claimService.guessInvoicesForUnknowns(claimId, values)
                        .then(() => {
                            onClose()
                            onComplete()
                            form.resetFields();
                        })
                        .catch((e) => form.setFields(e.response?.data?.fields || []))
                        .finally(() => {
                            setDisabled(false);
                        })
                })
        }}
    >
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            initialValues={{
                warehouses: [],
                afterAt: dayjs().subtract(2, 'year')
            }}
        >
            <Form.Item
                label="Use invoices after date"
                name="afterAt"
                rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
            >
                <DatePicker disabled={disabled} picker={"month"} />
            </Form.Item>
            <Form.Item
                label="Only for warehouses"
                name="warehouses"
            >
                <UIInputWidgetWarehouse multiple />
            </Form.Item>
        </Form>
    </Modal>
}