import React from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Row, Typography} from "antd";
import {NotCompletedOrder} from "../../../../../models/orders/orders-cart/orders-checkout/orders.checkout.not_completed.order";

interface OrdersCreateApplyResultProps {
    advancedOrder: NotCompletedOrder
}

export const OrdersCheckoutResult: React.FC<OrdersCreateApplyResultProps> = observer(({advancedOrder}) => {
    const t = useTranslate();


    return (
        <>
            {/*<Typography.Title level={3}>*/}
            {/*    {t("ORDERS.ORDER.TITLE") + " #" + advancedOrder.id} ({advancedOrder.warehouse.name}), {t("ORDERS.ORDER.STATUS.CONFIRMED")?.toLowerCase()}*/}
            {/*</Typography.Title>*/}
            {/*<a*/}
            {/*    target="_blank"*/}
            {/*    href={"/order/" + advancedOrder.id}*/}
            {/*    className="ant-btn ant-btn-primary ant-btn-lg"*/}
            {/*    style={{marginBottom: 40}}*/}
            {/*>*/}
            {/*    {t("ORDERS.ORDER_LINK")}*/}
            {/*</a>*/}
            <Row style={{backgroundColor: "rgba(0, 0, 0, 0.05)", padding: "12px 16px"}}>
                <span style={{fontSize: 20, fontWeight: "bold"}}>
                    {t("ORDERS.ORDER.TITLE") + " #" + advancedOrder.id} ({advancedOrder.warehouse.name})
                </span>
                <a
                    target="_blank"
                    href={"/orders/" + advancedOrder.id}
                    className="ant-btn ant-btn-primary ant-btn-lg"
                    style={{margin: "3px 0px 0px 25px", fontSize: 13, lineHeight: "21px", height: 25 }}
                >
                    {t("ORDERS.ORDER_LINK")}
                </a>
            </Row>
        </>
    );
});
