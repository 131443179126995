import React, { RefObject, useRef } from "react";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import { Form, Select } from "antd";
import { FieldsProps } from "../filter.popup";
import { FieldGroupedChoice } from "../../../../../../models/filter/filter.group";
import { FieldChangeCallback } from "../../../../../../models/filter/filter.field.type.config";

interface GroupedSelectFieldsProps extends FieldsProps {
    shoudlDefaultOpen?: boolean;
    groupedChoices: FieldGroupedChoice[];
    onChange: FieldChangeCallback;
}
export const GroupedSelectFields: React.FC<GroupedSelectFieldsProps> = ({
    popupRef,
    config,
    groupedChoices,
    shoudlDefaultOpen = false,
    onChange,
}) => {
    const t = useTranslate();
    const inputRef = useRef() as RefObject<any>;

    return (
        <Form.Item
            className="filterValueSelector"
            name={["fields", config.name, "values"]}
            rules={[
                {
                    required: true,
                    message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                },
            ]}
        >
            <Select
                showSearch
                defaultOpen={shoudlDefaultOpen}
                autoFocus={shoudlDefaultOpen}
                ref={inputRef}
                mode={config.multiple ? "multiple" : undefined}
                placeholder={t("FILTER.SELECT_FIELD")}
                getPopupContainer={() => popupRef.current as HTMLElement}
                onChange={(value) => {
                    onChange(config, value);
                }}
            >
                {groupedChoices.map((item) => (
                    <Select.OptGroup key={item.groupName} label={item.groupName}>
                        {item.choices.map((choice, index: number) => (
                            <Select.Option key={choice.value + "" + choice.name} value={choice.value}>
                                {choice.name}
                            </Select.Option>
                        ))}
                    </Select.OptGroup>
                ))}
            </Select>
        </Form.Item>
    );
};
