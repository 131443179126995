import {
    OrdersCart,
    OrdersCartCalculatedPrice,
    OrdersCartItem,
    OrdersCartQuery,
} from "../orders.cart";
import {
    OrdersCreateTableLastRecord,
    OrdersCreateTableRecord,
} from "../orders.create.table.record";
import { Warehouse } from "../../../../warehouses/warehouse";
import { OrdersCartEditFormValues } from "../../orders.cart.edit.form.values";
import { OrdersCheckoutTableItem } from "../../orders-checkout/orders.checkout.table";
import { displayPrice } from "../../../../prices/price.helper";
import { Space, Tag, Tooltip, Typography } from "antd";
import { OrdersCheckoutValues } from "../../orders-checkout/orders.checkout.values";
import { OrdersCheckoutRequest } from "../../orders-checkout/orders.checkout.request";
import { OrdersCheckoutAddress } from "../../orders-checkout/orders.checkout.address";
import { CustomerAccount } from "../../../../accounts/customer-account/customer.account";
import { OrdersCartRequestQuery } from "../orders.cart.request.query";
import { OrdersCreateMissingItem } from "../orders.create.response";
import {NotCompletedItem, NotCompletedOrder} from "../../orders-checkout/orders.checkout.not_completed.order";
import {OrderData} from "../../../order-data/order.data";
import {Translate} from "../../../../../hooks/translate.hook";
import {OrderCreateType} from "./order.create.type";

const generateCartItemData = (
    item: OrdersCartItem,
    warehouses: Warehouse[],
    editFormValues: OrdersCartEditFormValues
) => {
    const resultItem: OrdersCreateTableRecord = {
        id: item.article.id,
        article_name: item.article.name,
        article_description: item.article.description,
        price: item.calculatedPrice,
        quantity: { qty: item.qty, requestedQty: item.requestedQty },
        total_amount: item.totalAmount,
        sale_percentage: item.calculatedPrice.salePercentage || 0
    };

    let stocks: { [stock: string]: [number, number] } = {};

    item.stocks.forEach((stock) => {
        stocks[stock.warehouse.id] = [stock.stockAmount, stock.backorderAmount];
    });

    warehouses.forEach((warehouse) => {
        if (editFormValues.warehouses.includes(warehouse.id)) {
            resultItem[warehouse.id] = stocks[warehouse.id] || 0;
        }
    });

    return resultItem;
};

export const generateCartMissingItemsTableDataItem = (
    item: OrdersCreateMissingItem
): any => {
    const resultItem = {
        id: item.article.id,
        article_name: item.article.name,
        article_description: item.article.description,
        price: item.calculatedPrice,
        quantity: item.qty,
        total_amount: item.totalAmount,
    };

    // let stocks: { [stock: string]: number } = {};
    //
    // item.stocks.forEach((stock) => {
    //     stocks[stock.warehouse.id] = stock.amount;
    // });

    return resultItem;
};
export const generateData = (
    cartPreview: OrdersCart,
    warehouses: Warehouse[],
    editFormValues: OrdersCartEditFormValues,
    t: Translate
) => {
    let res: OrdersCreateTableRecord[] & OrdersCreateTableLastRecord[] =
        cartPreview.items.map((item) =>
            generateCartItemData(item, warehouses, editFormValues)
        );

    res.push({
        id: "footer",
        article_description: t("ORDER.CREATE.TOTALS_WITHOUT_BACKORDER"),
        quantity: {
            qty: cartPreview.qtyWithoutBackorder,
            requestedQty: cartPreview.requestedQty,
        },
        total_amount: cartPreview.totalWithoutBackorder,
        sale_percentage: cartPreview.salePercentageWithoutBackorder
    });

    res.push({
        id: "footer2",
        article_description: t("ORDER.CREATE.TOTALS_WITH_BACKORDER"),
        quantity: {
            qty: cartPreview.qty,
            requestedQty: cartPreview.requestedQty,
        },
        total_amount: cartPreview.total,
        sale_percentage: cartPreview.salePercentage
    });

    return res;
};

export const createOrdersCartQuery = ({
    warehouses,
    discount,
}: CustomerAccount): OrdersCartQuery => {
    return {
        warehouses: warehouses
            .slice()
            .sort((a, b) => a.sort! - b.sort!)
            .map((item) => item.warehouse.id),
        discountPercent: discount,
    };
};

export const createArticleRequest = ({
    article,
    qty,
    price
}: {
    article: { value: number };
    qty: string;
    price: string
}) => {
    return {
        article: article.value,
        qty: parseInt(qty),
        price: price ? parseFloat(price) : 0
    };
};

export const generateCartCheckoutTableItem = (
    item: NotCompletedItem
): OrdersCheckoutTableItem => {
    return {
        id: item.id,
        articleId: item.article.id,
        articleName: item.article.name,
        articleDescription: item.article.description,
        price: item.calculatedPrice,
        quantity: item.qty,
        stockQty: item.stockQty,
        backOrderedQty: item.backOrderedQty,
        totalPrice: item.totalAmount,
        valid: item.valid,
    };
};

export const generateCartApplyTableData = (
    order: NotCompletedOrder
): OrdersCheckoutTableItem[] => {
    return order.items.map((item) => generateCartCheckoutTableItem(item));
};

export const generateEditOrderItemsTableData = (order: OrderData): any => {
    return order.items.map(item => {
        return {
            id: item.id,
            articleId: item.articleId,
            articleName: item.articleName,
            articleDescription: item.description,
            quantity: item.qtyRequested,
            totalPrice: item.totalPrice,
            // valid: item.valid,
            price: {
                type: item.priceType,
                clientAmount: item.clientPrice,
                amount: item.price,
                // discountPercent: ite
            },
        }
    })
}

// const displayCustomDiscountPrice = (
//     price: OrdersCartCalculatedPrice
// ): JSX.Element => {
//     return (
//         <Space direction="vertical" style={{ textAlign: "left" }}>
//             <>{displayPrice(price.amount)}</>
//             <Typography.Text delete style={{ color: "rgba(0,0,0,.45)" }}>
//                 {displayPrice(price.clientAmount)}
//             </Typography.Text>
//         </Space>
//     );
// };

const displayCustomPriceWithLabel = (
    price: OrdersCartCalculatedPrice,
    description: string
): JSX.Element => {
    const priceTagText: { [key: string]: string } = {
        custom_price: "C$",
        custom_discount: "C%",
        client_price: "CC$",
        minimal_price: "M$",
        increased: "MI$"
    };

    return (
        <Space
            direction="horizontal"
            align={"center"}
            style={{ justifyContent: "flex-end" }}
        >
            {priceTagText[price.type] ? (
                <Tooltip placement="topRight" title={description}>
                    <Tag color={price.type === "client_price" ? "gold" : "red"}>
                        {priceTagText[price.type]}
                    </Tag>
                </Tooltip>
            ) : null}
            <Space direction="vertical" style={{ textAlign: "right" }}>
                <>{displayPrice(price.amount)}</>
                <Typography.Text delete style={{ color: "rgba(0,0,0,.45)" }}>
                    {displayPrice(price.clientAmount)}
                </Typography.Text>
            </Space>
        </Space>
    );
};

export const displayCustomPrice = (
    price: OrdersCartCalculatedPrice,
    description: string
) => {
    const priceTypes: any = {
        regular: (
            <Space direction="vertical" align={"end"}>
                {displayPrice(price.amount)}
            </Space>
        ),
        custom_discount: displayCustomPriceWithLabel(price, description),
        client_discount: (
            <Space direction="vertical" align={"end"}>
                {displayPrice(price.amount)}
            </Space>
        ),
        client_price: displayCustomPriceWithLabel(price, description),
        custom_price: displayCustomPriceWithLabel(price, description),
        minimal_price: displayCustomPriceWithLabel(price, description),
        increased: displayCustomPriceWithLabel(price, description),

    };
    return priceTypes[price.type];
};

const generateApplyRequestAddresses = (addresses: OrdersCheckoutAddress[]) => {
    const address = addresses.find((item) => item.checked);
    const addressId = address && !address.custom ? address.id : undefined;
    const customAddress: any = !addressId
        ? { ...addresses.find((item) => item.custom) }
        : undefined;

    if (customAddress) {
        delete customAddress.custom;
        delete customAddress.checked;
        delete customAddress.default;
        delete customAddress.type;
        customAddress.country = customAddress.country.id;
    }
    return { id: addressId, customAddress };
};

export const generateApplyRequest = ({
    relatedOrderNumber,
    shippingAddresses,
    shippingCouriers,
    orderComment,
    discountPercent,
}: OrdersCheckoutValues): OrdersCheckoutRequest => {
    const shippingAddressData =
        generateApplyRequestAddresses(shippingAddresses);
    return {
        shippingAddress: shippingAddressData.id,
        customShippingAddress: shippingAddressData.customAddress,
        shippingCourier: shippingCouriers ? shippingCouriers.find((item) => item.checked)!.id : null,
        relatedOrderNumber,
        orderComment,
        discountPercent,
    };
};

export const generateCreateOrderRequestQuery = ({
    cart,
    editFormValues: { warehouses, ignoreRegionalProhibitions, discountPercent, useBackorder },
    type
}: {
    cart: OrdersCart;
    editFormValues: OrdersCartEditFormValues;
    type: OrderCreateType;
}): OrdersCartRequestQuery => {
    return {
        warehouses,
        items: cart.items.map((item) => ({
            article: item.article.id,
            qty: item.requestedQty,
            customAmountValue:
                item.calculatedPrice.type === "custom_price"
                    ? item.calculatedPrice.amount.value
                    : undefined,
        })),
        discountPercent,
        ignoreRegionalProhibitions,
        type,
        useBackorder
    };
};
