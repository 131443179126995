
import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {
    PayerOrReceiverDetails,
    PayerReceiverFormValues, SellerDetails
} from "../../models/payer-receiver-seller/payer.reveiver.seller";

export class PayerReceiverSellerService {
    api: AxiosInstance = API;

    updatePayerReceiveData(id: number, type: "payer" | "receiver", values: PayerReceiverFormValues): Promise<PayerOrReceiverDetails> {
        const url: string = type === "payer" ? "orders/" + id + "/payer" : "orders/" + id + "/receiver";
        return this.api.post<PayerOrReceiverDetails>(url, values).then(x => x.data);
    }

    updateSellerData(id: number, values: SellerDetails): Promise<SellerDetails> {
        return this.api.post<SellerDetails>("orders/" + id + "/seller", values).then(x => x.data);
    }

}

export const payerReceiverSellerService = new PayerReceiverSellerService();
