import {WebSitePage} from "../../../../../models/web-site/web-site";
import {SmartColumn} from "../../../../common/smart-table/smart-table.model";
import {Translate} from "../../../../../hooks/translate.hook";
import {FieldType} from "../../../../common/smart-table/smart-table.field-config";

export const columnFactory = (t: Translate): SmartColumn<WebSitePage>[] => {
    return [
        {
            name: 'id',
            visibleName: t('ID'),
            type: FieldType.OBJECT,
            options: {t: 'settings/web-site/page', f: 'id'}
        },
        {
            name: 'publicationDate',
            visibleName: t('DATE'),
            type: FieldType.DATETIME
        },
        {
            name: 'locale',
            visibleName: t('LOCALE'),
            type: FieldType.STRING
        },
        {
            name: 'title',
            visibleName: t('TITLE'),
            type: FieldType.STRING
        }
    ]
}