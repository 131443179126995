import {InvoiceData} from "./invoice.data";
import {TableFooterData} from "../../../table-footer/table.footer.data";


export const generateDocumentDetails = (invoice: InvoiceData): TableFooterData => {
    return {
        qty: invoice.qty,
        taxPercent: invoice.vatPercent,
        totalWeight: invoice.totalNetWeight + " / " + invoice.totalGrossWeight,
        taxAmount: invoice.subtotalVat,
        totalAmount: invoice.subtotal,
        grandTotalAmount: invoice.grandTotal,
        salePercentage: invoice.salePercentage
    }
}
