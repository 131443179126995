import React, { useState } from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { Button, Popconfirm, Space, Table, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnsType } from "antd/es/table";
import { DepartmentFlatten } from "../../../../models/departments/department";
import { BranchDataDepartmentsModal } from "./branch.data.departments.modal";
import { Link, useParams } from "react-router-dom";
import { BranchDataDepartmentsStore, BranchDataDepartmentsStoreContext } from "./branch.data.departments.store";

export const BranchDataDepartments: React.FC = observer(() => {
    const { branchId } = useParams<{ branchId: string }>();
    const t = useTranslate();
    const [store] = useState(() => new BranchDataDepartmentsStore(branchId!));

    const columns: ColumnsType<DepartmentFlatten> = [
        {
            title: t("NAME"),
            dataIndex: "name",
            key: "name",
            render: (name: string, row) => <Link to={String(row.id)}>{name}</Link>,
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            width: 100,
            className: "table-button-cell",
            render: (_: any, record) => (
                <>
                    <Popconfirm
                        title={t("ARE_YOU_SURE")}
                        onConfirm={() => store.deleteDepartment(record.id as any)}
                        placement="left"
                        okText={t("YES")}
                        cancelText={t("NO")}
                    >
                        <Tooltip placement="topRight" title={t("REMOVE")}>
                            <Button
                                className="table-btn btn-remove btn"
                                type="default"
                                icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                            />
                        </Tooltip>
                    </Popconfirm>
                </>
            ),
        },
    ];

    return (
        <BranchDataDepartmentsStoreContext.Provider value={store}>
            <Space direction="vertical" size={[20, 20]}>
                <Button onClick={store.openDepartmentCreateModal} type="primary">
                    {t("BRANCH.DATA.DEPARTMENTS.ADD")}
                </Button>
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={[...store.departments]}
                    loading={store.departmentsLoading}
                    scroll={{ x: true }}
                    pagination={false}
                />
                {store.departmentsModalShown ? <BranchDataDepartmentsModal /> : null}
            </Space>
        </BranchDataDepartmentsStoreContext.Provider>
    );
});
