import { AxiosInstance } from "axios";
import API from "../../../utils/api";
import {ArticleFile} from "../../../models/articles/article.files";

export class ArticlesFileService {
    api: AxiosInstance = API;

    async getList(): Promise<ArticleFile[]> {
        return await this.api.get("articles/files").then((x) => x.data);
    }

    createDownloadURL(type: string): string {
        return "api/v3/articles/files/" + type + "/download";
    }
}

export const articlesFileService = new ArticlesFileService();
