import {ColumnsType} from "antd/es/table";
import {DeclarationItem} from "../../upload/declaration.upload.model";
import {Article} from "../../../../models/articles/article";
import {Amount} from "../../../../models/prices/price";
import {displayPrice} from "../../../../models/prices/price.helper";
import {Translate} from "../../../../hooks/translate.hook";

export const columnsFactory = (t: Translate): ColumnsType<DeclarationItem> => {
    return [
        {
            title: t("ARTICLE.TITLE"),
            dataIndex: "article",
            render: (article: Article) => article.name
        },
        {
            title: t("HS Code"),
            dataIndex: "vedCode"
        },
        {
            title: t("Declaration"),
            dataIndex: "declarationName"
        },
        {
            title: t("Invoice"),
            dataIndex: "invoiceNumber"
        },
        {
            title: t("Shipment Invoice"),
            dataIndex: "transportInvoiceNumber"
        },
        {
            title: t("Boxes"),
            dataIndex: "boxesQty"
        },
        {
            title: t("Pcs"),
            dataIndex: "piecesQty"
        },
        {
            title: t("Net Weight"),
            dataIndex: "netWeight"
        },
        {
            title: t("Gross weight"),
            dataIndex: "grossWeight"
        },
        {
            title: t("Price (tk)"),
            dataIndex: "price",
            render: (i: Amount) => displayPrice(i)
        },
        {
            title: t("Price (row)"),
            dataIndex: "rowPrice",
            render: (i: Amount) => displayPrice(i)
        },
        {
            title: t("Transport cost"),
            dataIndex: "transportCost",
            render: (i: Amount) => displayPrice(i)
        }
    ];
}