import {ComponentPropsFromRoute} from "../../../routers/routers";
import React, {useEffect, useState} from "react";
import {Col, Divider, Row, Statistic, Typography} from "antd";
import {QueueJob} from "../job/queue.job";
import {CurrentQueueStatus} from "../../../models/queue/job";
import {LoadingSpin} from "../../common/loading-spin/loading.spin";
import {queueService} from "../../../services/queue/queue.service";
import {useTranslate} from "../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";

interface QueueStatusProps extends ComponentPropsFromRoute {
}

export const QueueStatus: React.FC<QueueStatusProps> = ({}) => {

    const t = useTranslate();

    const [status, setStatus] = useState<CurrentQueueStatus | null>(null);

    const reload = () => {
        queueService.getStatus().then(status => setStatus(status));
    }

    useEffect(() => {
        reload();

        const timeout = setInterval(() => {
            reload();
        }, 2000);

        return () => {
            clearTimeout(timeout);
        }
    }, []);

    if (!status) {
        return <LoadingSpin />
    }

    return (
        <>
            <PageHeader
                style={{ padding: "0 0 20px 0" }}
                ghost={false}
                title={<Typography.Title level={1} style={{ margin: 0 }}>{t("QUEUE.TITLE")}</Typography.Title>}
            >
            </PageHeader>

            <Row gutter={16}>
                <Col span={2}>
                    <Statistic title="Total Jobs" value={status.totalJobs} />
                </Col>
                <Col span={2}>
                    <Statistic title="Active Jobs" value={status.activeJobs.length} />
                </Col>
            </Row>

            <Row style={{marginTop: "20px"}}>
                <Col span={11}>
                    <h1>Active Jobs</h1>
                    {status.activeJobs.map(job => (
                        <QueueJob key={job.id} job={job} />
                    ))}
                </Col>
                <Col span={2} style={{textAlign: "center"}}>
                    <Divider type={"vertical"} style={{ height: "100%" }} />
                </Col>
                <Col span={11}>
                    <h1>Last Completed Jobs (10)</h1>
                    {status.lastCompletedJobs.map(job => (
                        <QueueJob key={job.id} job={job} />
                    ))}
                </Col>
            </Row>
        </>
    )
}