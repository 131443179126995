import React, {useState} from "react";
import {Form, Input, Modal, Upload} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {useForm} from "antd/es/form/Form";
import {declarationService} from "../../../../../services/declarations/declarations.services";

interface UploadModalModalProps {
    number: string;
    onClose: () => void;
    onComplete: () => void;
}

export const UploadModal: React.FC<UploadModalModalProps> = ({number, onClose, onComplete}) => {

    const t = useTranslate();

    const [form] = useForm();
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Modal
            width={"600px"}
            visible={true}
            title={t("DECLARATION.TITLE")}
            onOk={() => form.submit()}
            onCancel={() => onClose()}
            okText={t("UPLOAD.TITLE")}
            confirmLoading={loading}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={() =>
                    form.validateFields().then(async (values) => {
                        try {
                            setLoading(true);
                            await declarationService.handleXML(number, values.file);
                            onClose();
                            onComplete();
                        }
                        finally {
                            setLoading(false);
                        }
                    })
                }
            >
                <Form.Item
                    label={t("FILE")}
                    name="file"
                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                    valuePropName={""}
                    getValueFromEvent={(e) => e.file || null}
                >
                    <Upload.Dragger
                        beforeUpload={() => false}
                        accept={".xml"}
                        maxCount={1}
                        disabled={loading}
                    >
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single upload.
                        </p>
                    </Upload.Dragger>
                </Form.Item>
            </Form>
        </Modal>
    )
}