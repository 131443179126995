import {action, makeObservable, observable} from "mobx";
import {FilterDataHandler} from "../../../../models/filter/filter.data.handler";
import {WarehousesTaxesRequest, WarehouseTaxes} from "../../../../models/warehouses/warehouses.taxes";
import {agentTypesService} from "../../../../services/agents/agent.types.service";
import {branchesService} from "../../../../services/branches/branches.service";

export class BranchDataTaxRulesStore {
    @observable
    public types: string[] = [];

    @observable
    public createModalShown: boolean = false;

    @observable
    public editModalShown: boolean = false;

    @observable
    public editModalItem?: WarehouseTaxes;

    public dataHandler: FilterDataHandler<WarehouseTaxes>;

    constructor(private id: string) {
        makeObservable(this);
        this.updateItem = this.updateItem.bind(this);
        this.createItem = this.createItem.bind(this);
        this.openCreateModal = this.openCreateModal.bind(this);
        this.closeCreateModal = this.closeCreateModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.dataHandler = new FilterDataHandler<WarehouseTaxes>(request => branchesService.getWarehousesTaxes(id, request));
        this.init();
    }

    public isEditModalShown(item: WarehouseTaxes): boolean {
        return this.editModalShown && this.editModalItem === item;
    }

    public isCreateModalShown(): boolean {
        return this.createModalShown;
    }

    @action
    public openEditModal(item: WarehouseTaxes): void {
        this.editModalShown = true;
        this.editModalItem = item;
    }

    @action
    public closeEditModal(): void {
        this.editModalShown = false;
    }

    @action
    public openCreateModal(): void {
        this.createModalShown = true;
    }

    @action
    public closeCreateModal(): void {
        this.createModalShown = false;
    }

    public async updateItem(request: WarehousesTaxesRequest, item: WarehouseTaxes) {
        const updatedItem = await branchesService.editTaxRule(request, item.id);
        this.dataHandler.updateItem(item, updatedItem);
    }

    public async createItem(request: WarehousesTaxesRequest) {
        const createdItem = await branchesService.createTaxRule(this.id, request);
        this.dataHandler.addItem(createdItem);
    }

    public async removeItem(item: WarehouseTaxes) {
        await branchesService.removeTaxRule(item.id!!);
        this.dataHandler.removeItem(item);
    }

    @action
    public setTypes(types: string[]): void {
        this.types = types;
    }

    private async init() {
        this.setTypes(await agentTypesService.getTypes());
    }
}
