import React, {useMemo} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {ColumnsType} from "antd/es/table";
import {columnFactory} from "./shipment.invoiced-invoices.columns";
import {convertTableColumnsToColumnConfigs} from "../../../../utils/table";
import {
    MergedInvoiceIncludedInvoice
} from "../../../../models/documents/invoices/merged-invoice/merged-invoice-data/merged.invoice.data";
import {Shipment} from "../../../../models/parcels/shipment";
import {ExpandedTable} from "../../../common/order-details/expanded.table";

export interface ShipmentIncludedInvoicesProps {
    shipment: Shipment
}

export const ShipmentIncludedInvoices: React.FC<ShipmentIncludedInvoicesProps> = ({shipment}) => {
    const t = useTranslate();
    const columns: ColumnsType<MergedInvoiceIncludedInvoice> = useMemo(() => columnFactory(t), []);

    return (
        <>
            <ExpandedTable<MergedInvoiceIncludedInvoice>
                title="INVOICE.MERGED.INCLUDED_INVOICES.TITLE"
                pageKey="shipment_page_included_invoices"
                columns={columns}
                columnsConfig={convertTableColumnsToColumnConfigs(columns)}
                tableData={shipment.invoiceDocuments}
                tableLoading={false}
            >
            </ExpandedTable>
        </>
    )
};
