import React, {useState} from "react";
import {Button} from "antd";
import {useTranslate} from "../../../../../../hooks/translate.hook";

export interface ActionProps {
    title: string;
    run: () => Promise<any>;
    danger?: boolean;
}

export const PlannerTasksViewAction: React.FC<ActionProps> = ({title, run, danger}) => {

    const t = useTranslate();
    const [disabled, setDisabled] = useState<boolean>(false);

    const runWrapped = async () => {
        setDisabled(true);
        try {
            await run()
        } finally {
            setDisabled(false)
        }
    }

    return (
        <Button
            block
            type={"primary"}
            onClick={runWrapped}
            disabled={disabled}
            danger={danger}
        >
            {t('PLANNER.TASK.ACTION.' + title)}
        </Button>
    )
}