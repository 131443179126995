import {action, computed, makeObservable, observable} from "mobx";
import {Department, DepartmentFlatten, DepartmentWithParent} from "../../../../models/departments/department";
import {DepartmentRole} from "../../../../models/departments/roles";
import {departmentsService} from "../../../../services/departments/departments.service";
import React from "react";
import {flattenDepartments} from "../../../../models/departments/helper";
import {DepartmentsRequestValues} from "../../../../models/departments/departments.request.values";

export class BranchDataDepartmentsStore {
    @observable
    public departmentsLoading: boolean = false;

    @observable
    public _departments: Department[] = [];

    @observable
    public departmentsModalShown: boolean = false;

    @observable
    public departmentEditItem: DepartmentWithParent | null = null;

    constructor(private id: string) {
        makeObservable(this);
        this.init();
    }

    @action
    private setDepartmentEditItem(value: DepartmentWithParent | null): void {
        this.departmentEditItem = value;
    }

    @action
    public setDepartmentsModalShown(value: boolean): void {
        this.departmentsModalShown = value;
    }

    @action
    private setBranchDepartments(value: Department[]): void {
        this._departments = value;
    }

    @action
    private setDepartmentsLoading(value: boolean): void {
        this.departmentsLoading = value;
    }

    @action
    private setRoles(roles: DepartmentRole[]): void {
        if (this.departmentEditItem) {
            this.departmentEditItem.roles = roles;
        }
    }

    @computed
    public get departments(): DepartmentFlatten[] {
        return flattenDepartments(this._departments);
    }

    public openDepartmentCreateModal = (): void => {
        this.setDepartmentsModalShown(true);
    }

    public closeDepartmentModal = (): void => {
        if (this.departmentEditItem) {
            this.setDepartmentEditItem(null);
        }
        this.setDepartmentsModalShown(false);
    }

    public openDepartmentEditModal = async (id: number) => {
        let item: DepartmentWithParent | any = null;
        const findDepartment = (parent: Department | null, departments: Department[]) => {
            departments.forEach(department => {
                if (department.id == id as any) {

                    item = {
                        id: department.id,
                        name: department.name,
                        roles: [],
                        parent: parent ? {id: parent.id, name: parent?.name} : null
                    };
                    return;
                }
                if (!department.children.length && department.id != id as any) {
                    return;
                }
                if (department.id != id as any && department.children.length > 0) {
                    findDepartment(department, department.children);
                }
            });
        }
        findDepartment(null, this._departments);
        if (item) {
            const roles = await departmentsService.getDepartmentRoles(item.id);
            item!.roles = roles.slice().sort((a, b) => a.position - b.position)
        }
        this.setDepartmentEditItem(item);
        this.setDepartmentsModalShown(true);
    }

    public updateDepartment = async (values: DepartmentsRequestValues): Promise<any> => {
        const valuesWithSortedRoles: DepartmentsRequestValues = {...values, roles: values.roles.map((role, index: number) => ({
                ...role,
                position: index,
            }))}
        await departmentsService.updateDepartment(this.departmentEditItem!.id, valuesWithSortedRoles);
        this.setBranchDepartments(await departmentsService.getBranchDepartments(this.id));
    }

    public createDepartment = async (values: DepartmentsRequestValues): Promise<any> => {
        await departmentsService.createDepartment(values);
        this.setBranchDepartments(await departmentsService.getBranchDepartments(this.id));
    }

    public async deleteDepartment(id: number) {
        this.setDepartmentsLoading(true);
        try {
            await departmentsService.removeDepartment(id);
            this.setBranchDepartments(await departmentsService.getBranchDepartments(this.id));
            this.setDepartmentsLoading(false);
        } catch (e: any) {
            this.setDepartmentsLoading(false);
        }
    }

    private async init() {
        this.setDepartmentsLoading(true);
        this.setBranchDepartments(await departmentsService.getBranchDepartments(this.id));
        this.setDepartmentsLoading(false);
    }
}

export const BranchDataDepartmentsStoreContext = React.createContext<BranchDataDepartmentsStore | null>(null)
