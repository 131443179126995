import {action, makeObservable, observable} from "mobx";
import {forgotPasswordService} from "../../../services/forgot-password/forgot.password.service";

export class ForgotPasswordStore {
    @observable
    public sent: boolean = false;

    @observable
    public disabled: boolean = false;

    constructor() {
        makeObservable(this)
    }

    @action
    public setSent(value: boolean): void {
        this.sent = value;
    }

    @action
    public setDisabled(value: boolean): void {
        this.disabled = value;
    }

    public async sendEmail(values: {username: string}) {
        this.setDisabled(true);
        await forgotPasswordService.sendEmail(values);
        this.setDisabled(false);
        this.setSent(true);
    }
}