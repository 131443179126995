import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {WidgetProps} from "../../../../../common/ui/input/widget/widget";
import {SystemUser} from "../../../../../../models/users/user/system.user";
import {plannerService} from "../../../../../../services/planner/planner.service";

export const UIInputWidgetAssignees: React.FC<WidgetProps> = ({value, onChange, multiple}) => {

    const [assignees, setAssignees] = useState<SystemUser[]>([]);

    useEffect(() => {
        plannerService.getAssignees().then(assignees => setAssignees(assignees));
    }, [])

    return (
        <Select
            mode={multiple ? "multiple" : undefined}
            onChange={onChange as any}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
        >
            {assignees.map(user => (
                <Select.Option value={user.id}>{user.firstName} {user.lastName}</Select.Option>
            ))}
        </Select>
    )
}