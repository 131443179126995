import {Alert, Button, Form, Modal} from "antd";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import React from "react";
import {
    CustomerAccountAddress
} from "../../../../../../models/accounts/customer-account/address/customer.account.address";
import {CardSelect} from "../../../../../common/ui/form/card-select/card-select";

interface WrongDeliveryModalProps {
    reason: string;
    textReason: string;
    onClose: () => void;
    onComplete: (skipDeliveryCheck: boolean, addForgotDeliveryOrders: boolean) => void;
}

export const WrongDeliveryModal: React.FC<WrongDeliveryModalProps> = ({reason, textReason, onClose, onComplete}) => {

    const t = useTranslate();

    const onAddForgotDeliveryOrders = () => {
        onComplete(false, true);
        onClose();
    }

    return (
        <Modal
            visible={true}
            width={500}
            title={t("WARNING")}
            onCancel={() => onClose()}
            onOk={() => {
                onComplete(true, false);
                onClose();
            }}
            okText={"Continue"}
            footer={(_, extra) => {
                return <>
                    {reason === 'NOT_ALL_DELIVERY_INCLUDED' ? (
                        <Button type={"primary"} onClick={() => onAddForgotDeliveryOrders()}>
                            Add all matching delivery orders
                        </Button>
                    ) : null}
                    <extra.OkBtn />
                    <extra.CancelBtn />
                </>
            }}
        >
            {textReason}. Continue?
        </Modal>
    )
}