import {AxiosInstance} from "axios";
import API from "../../../utils/api";
import {CreditInvoice, CreditInvoiceItemCreate} from "../../../models/documents/invoices/credit-invoice/credit.invoice";
import {PaginatedRequest} from "../../../models/core/paginated.request";
import {PaginatedResult} from "../../../models/core/paginated.result";

export class CreditInvoicesService {
    private api: AxiosInstance = API;

    getCreditInvoice(id: string): Promise<CreditInvoice> {
        return this.api.get<CreditInvoice>("documents/credit-invoices/" + id).then(x => x.data);
    }

    getAll(request: PaginatedRequest): Promise<PaginatedResult<CreditInvoice>> {
        return this.api.get<PaginatedResult<CreditInvoice>>(
            "documents/credit-invoices",
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }

    getAllByAccount(request: PaginatedRequest, accountId: string): Promise<PaginatedResult<CreditInvoice>> {
        request.addFilter("account", accountId)
        return this.api.get<PaginatedResult<CreditInvoice>>(
            'documents/credit-invoices',
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }

    getRelatedForInvoiceItem(id: number): Promise<CreditInvoice[]> {
        return this.api.get<CreditInvoice[]>("documents/credit-invoices/related-for-item/" + id).then(x => x.data);
    }

    createWithSingleItem(dto: CreditInvoiceItemCreate): Promise<CreditInvoice> {
        return this.api.post<CreditInvoice>("documents/credit-invoices", dto).then(x => x.data);
    }

    addItemToCreditInvoice(id: number, dto: CreditInvoiceItemCreate): Promise<CreditInvoice> {
        return this.api.post<CreditInvoice>("documents/credit-invoices/" + id + "/items", dto).then(x => x.data);
    }

    removeItem(id: number, itemId: number): Promise<void> {
        return this.api.post("documents/credit-invoices/" + id + "/items/remove", {item: itemId});
    }

    setQty(id: number, itemId: number, qty: number): Promise<void> {
        return this.api.post("documents/credit-invoices/" + id + "/items/qty", {item: itemId, qty: qty});
    }

    close(id: number): Promise<void> {
        return this.api.post("documents/credit-invoices/" + id + "/close");
    }

    reopen(id: number): Promise<void> {
        return this.api.post("documents/credit-invoices/" + id + "/reopen");
    }

    uploadToSecondary(id: number): Promise<void> {
        return this.api.post("documents/credit-invoices/" + id + "/secondary/upload");
    }

    downloadFromSecondary(id: number): Promise<void> {
        return this.api.post("documents/credit-invoices/" + id + "/secondary/download");
    }

    cancel(id: number): Promise<void> {
        return this.api.post("documents/credit-invoices/" + id + "/cancel");
    }
}

export const creditInvoicesService = new CreditInvoicesService();
