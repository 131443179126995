import React from "react";
import {useTranslate} from "../../../hooks/translate.hook";
import {Shipment} from "../../../models/parcels/shipment";
import {Col, Row, Typography} from "antd";
import {SmartLogger} from "../../logger/smart-logger/smart-logger";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import {isAllowed} from "../../../utils/helpers";
import {observer} from "mobx-react";

interface InvoiceDataHeaderProps {
    shipment: Shipment;
}

export const ShipmentSmartLogger: React.FC<InvoiceDataHeaderProps> = observer(({shipment}) => {
    const t = useTranslate();

    const {
        authStore: { attributes }
    } = useRequiredStore(RootStoreContext);

    if (!isAllowed(attributes, "smartlogger.list")) {
        return null;
    }

    return (
        <Row gutter={[25, 25]}>
            <Col span={24}>
                <Typography.Title level={3}>{t("LOGGER.TITLE")}</Typography.Title>
                <SmartLogger
                    entityName={"Order\\Shipment\\Shipment"}
                    entityId={shipment.id.toString()}
                />
            </Col>
        </Row>
    );
})