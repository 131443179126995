import React from "react";
import {WidgetProps} from "../../../../../common/ui/input/widget/widget";
import {DebounceSelect} from "../../../../../common/debounce-select/debounce.select";
import {propertyService} from "../../../../../../services/property/property.service";

const searchAgents = async (value: string) => {
    const result = await propertyService.findByCode(value);
    return result.map(property => ({
        value: property.id,
        label: property.code  + ' - ' + property.description.substr(0, 25)
    }));
};

export const UiInputWidgetPropertySearch: React.FC<WidgetProps> = ({value, onChange, multiple}) => {

    return (
        <DebounceSelect
            autoFocus
            placeholder={"Search by code"}
            style={{ width: "280px" }}
            onChange={onChange}
            fetchOptions={searchAgents}
            labelInValue={false}
        />
    )
}