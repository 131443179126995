import React from "react";
import {Button, Col, Row, Typography} from "antd";
import { Link, useLocation } from "react-router-dom";
import {useTranslate} from "../../../../hooks/translate.hook";

const links: { path: string; text: string }[] = [
    {
        path: "/articles/history",
        text: "ARTICLES.HISTORY",
    },
    {
        path: "/articles/stock",
        text: "ARTICLES.WAREHOUSES.TITLE",
    },
    {
        path: "/articles/consignation-stock",
        text: "ARTICLES.CONS_WAREHOUSES.TITLE",
    },
    {
        path: "/articles/prices",
        text: "ARTICLES.PRICES.TITLE",
    },
    {
        path: "/articles/price-lists",
        text: "PRICE_LISTS",
    },
    {
        path: "/articles/locked-stock",
        text: "ARTICLES.LOCKED_STOCK",
    },
    {
        path: "/articles/stock-report",
        text: "ARTICLES.STOCK-REPORT.TITLE",
    }
];

export const ArticlesLinks: React.FC = () => {
    const t = useTranslate();
    const { pathname } = useLocation();
    return (
        <div>
            <Typography.Title level={3}>{t("ARTICLES.DETAILS")}</Typography.Title>
            <Row gutter={[20, 10]}>
                {links.map((item) => (
                    <Col key={item.path} xs={24} sm={24} md={12} lg={7} xl={5}>
                        <Link
                            to={item.path}
                        >
                            <Button block type={pathname === item.path ? "primary" : "default"}>
                                {t(item.text)}
                            </Button>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    );
};
