import {FilterDataHandler} from "../../../../models/filter/filter.data.handler";
import {ExtendedUser, UserContacts} from "../../../../models/users/user/extended.user";
import {userService} from "../../../../services/users/user.service";
import {NavigateFunction} from "react-router/dist/lib/hooks";

export class UsersListStore {
    public dataHandler: FilterDataHandler<ExtendedUser>;

    constructor(private navigate: NavigateFunction) {
        this.dataHandler = new FilterDataHandler(request => userService.getAll(request));
        this.createUser = this.createUser.bind(this);
    }

    public async createUser(values: UserContacts) {
        try {
            const newUser = await userService.createUser(values);
            // this.dataHandler.addItem(newUser);
            this.navigate("/users/" + newUser.id);
        } catch (e: any) {
        }
    }

}
