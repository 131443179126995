import React from "react";
import { observer } from "mobx-react";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { displayPrice } from "../../../../../models/prices/price.helper";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { TableFooter } from "../../../../common/table-footer/table.footer";
import { ExpandedTable } from "../../../../common/order-details/expanded.table";
import {generateDocumentDetails} from "../data.helper";
import {convertTableColumnsToColumnConfigs} from "../../../../../utils/table";
import {Return, ReturnItem} from "../../../../../models/documents/returns/returns";

export interface ReturnDataDetailsProps {
    document: Return;
}

export const Details: React.FC<ReturnDataDetailsProps> = observer(({document}) => {
    const t = useTranslate();
    const columns: ColumnsType<ReturnItem> = [
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "articleName",
            key: "invoice_data_order_detail_articleName",
            width: "135px",
            render: (article: string) => article.toUpperCase() !== "DELIVERY" ? (
                <Link to={"/articles/" + article} style={{ whiteSpace: "nowrap" }}>
                    {article}
                </Link>
            ) : t("DELIVERY.TITLE"),
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "invoice_data_order_detail_articleDescription",
            width: "300px",
        },
        {
            title: t("PRICE"),
            dataIndex: "price",
            key: "invoice_data_order_detail_price",
            width: "167px",
            render: (item) => (item ? <>{displayPrice(item)}</> : null),
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "invoice_data_order_detail_quantity",
            width: "125px",
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "totalPrice",
            key: "invoice_data_order_detail_totalPrice",
            width: "140px",
            render: (item) => (item ? <>{displayPrice(item)}</> : null),
        },
    ];

    return (
        <ExpandedTable<ReturnItem>
            title="DETAILS"
            pageKey="invoice_data_order_detail"
            columns={columns}
            columnsConfig={convertTableColumnsToColumnConfigs(columns)}
            tableData={document.items}
            tableLoading={false}
            tableFooter={<TableFooter data={generateDocumentDetails(document)} />}
        />
    );
});
