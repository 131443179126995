import React, {useState} from "react";
import { observer } from "mobx-react";
import {Button, Descriptions, Dropdown, Menu, Row, Space, Tooltip, Typography} from "antd";
import { Link } from "react-router-dom";
import "./header.scss";
import { DocumentsStatus } from "../../../../common/documents/status/documents.status";
import Date from "../../../../date/date";
import {Return} from "../../../../../models/documents/returns/returns";
import {Value} from "../../../../common/value/value";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DocumentEditTransactionModal} from "../../../common/transaction/document.edit-transaction.modal";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";
import {useNavigate} from "react-router";

export interface ReturnDataHeaderProps {
    document: Return;
    reload: () => void;
}

export const ReturnDataHeader: React.FC<ReturnDataHeaderProps> = observer(({document, reload}) => {
    const t = useTranslate();
    const navigate = useNavigate();
    const [transactionEditModalShown, setTransactionEditModalShown] = useState(false);

    return (
        <>
            <PageHeader
                className="invoice-header"
                style={{ padding: 0 }}
                ghost={false}
                title={
                    <Typography.Title level={1} style={{ margin: 0 }}>
                        {t("DOCUMENTS.RETAIL.RETURN.TITLE") + " " + document.docNumber}
                    </Typography.Title>
                }
                onBack={() => {
                    navigate("/returns");
                }}
                extra={<DocumentsStatus large status={document.status} />}
            >
                <Space direction="vertical" size={[15, 15]}>
                    <Row justify={"space-between"}>
                        <Space className={"invoice-data-header-buttons"} direction={"horizontal"} size={[20, 20]} wrap>
                            <Dropdown
                                placement={"bottomLeft"}
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        {document.orders && document.orders.length
                                            ? document.orders.map((order) => (
                                                  <Menu.Item key={order}>
                                                      <Link
                                                          style={{ textAlign: "left" }}
                                                          className={"ant-btn ant-btn-link"}
                                                          to={"/orders/" + order}
                                                      >
                                                          {order === document.masterOrder.id
                                                              ? order + " (master)"
                                                              : order}
                                                      </Link>
                                                  </Menu.Item>
                                              ))
                                            : null}
                                    </Menu>
                                }
                            >
                                <Button type="primary">{t("ORDERS.LIST")}</Button>
                            </Dropdown>
                            <Dropdown
                                placement={"bottomRight"}
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        <Menu.Item key={0}>
                                            <a
                                                rel="noopener noreferrer"
                                                style={{ textAlign: "left" }}
                                                target="_blank"
                                                className={"ant-btn ant-btn-link"}
                                                href={"/documents/" + document.id}
                                            >
                                                {t("DOCUMENTS.FILES.SHOW_PDF")}
                                            </a>
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button> {t("ORDERS.ORDER.VIEW.INVOICE")}</Button>
                            </Dropdown>

                        </Space>

                        <Dropdown
                            placement={"bottomRight"}
                            trigger={["click"]}
                            overlay={
                                <Menu>
                                </Menu>
                            }
                        >
                            <Button type={"primary"}>{t("ACTION")}</Button>
                        </Dropdown>
                    </Row>

                    <div className="data-descriptions-with-edit-wrapper">
                        <div className="data-content-wrapper">
                            <Descriptions
                                className="data-content-wrapper-descriptions"
                                size={"small"}
                                layout={"vertical"}
                                contentStyle={{ fontSize: "17px" }}
                                labelStyle={{ color: "#858585" }}
                                column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
                            >
                                {/*<Descriptions.Item label={t("CLIENT.NUMBER")}>*/}
                                {/*    <Link*/}
                                {/*        className="link"*/}
                                {/*        to={"/clients/" + document.account.agent.number}*/}
                                {/*    >*/}
                                {/*        {document.account.agent.number} (*/}
                                {/*        {document.account.agent.name})*/}
                                {/*    </Link>*/}
                                {/*</Descriptions.Item>*/}
                                <Descriptions.Item label={t("ORDERS.ORDER.NUMBER")}>
                                    <Link className="link" to={"/orders/" + document.masterOrder.id}>
                                        {document.masterOrder.id}
                                    </Link>
                                </Descriptions.Item>
                                <Descriptions.Item label={t("DATE")}>
                                    <Date dateString={document.date!} showTime withSeconds />
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ORDERS.TRANSACTION_ID")}>
                                    <Value value={document.transactionID} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t("PLATFORM.TITLE")}>
                                    <Value value={document.aggregator?.id} />
                                </Descriptions.Item>
                            </Descriptions>
                            <Tooltip placement="topRight" title={t("EDIT")}>
                                <Button
                                    className="data-description-btn btn"
                                    onClick={() => setTransactionEditModalShown(true)}
                                    type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </Space>
            </PageHeader>
            {transactionEditModalShown ? (
                <DocumentEditTransactionModal
                    id={document.id}
                    transaction={document}
                    onClose={() => setTransactionEditModalShown(false)}
                    onComplete={() => reload()}
                    />
            ) : null}
        </>
    );
});
