import React from "react";
import {Card, Checkbox, Row, Typography} from "antd";
import {CustomCard} from "../../../custom-card/custom.card";
import {CardSelectData, CardSelectRowKey} from "./card-select.model";

interface CardSelectSelectableCardProps {
    title: string,
    data: CardSelectData,
    rowKeys: CardSelectRowKey[];
    onClick: () => void,
    value: boolean
}
export const CardSelectSelectableCard: React.FC<CardSelectSelectableCardProps> = ({title, data, rowKeys, onClick, value}) => {
    return (
        <Card
            hoverable
            onClick={() => onClick()}
            className={"card"}
            title={
                <Row align={"middle"} justify={"space-between"}>
                    <Typography.Text ellipsis>{title}</Typography.Text>
                    <Checkbox
                        className={"large"}
                        checked={value}
                    />
                </Row>
            }
        >
            <CustomCard data={data} rowKeys={rowKeys}/>
        </Card>
    )
}
