import React, {createRef, Ref, useEffect} from "react";
import {Form, Input, InputRef} from "antd";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {FieldsProps} from "../filter.popup";
import {fieldTypes} from "../../../../../../models/filter/filter.field.type.config";

export const IntFields: React.FC<FieldsProps> = ({ popupRef, config }) => {
    const t = useTranslate();
    const inputRef = createRef() as Ref<InputRef>;

    let width = "auto";
    if (fieldTypes[config.type].supportedRules.length > 0) {
        width = "67%";
    }

    useEffect(() => {
        const input = inputRef as any;
        input?.current.focus();
    }, [inputRef]);

    return (
        <Form.Item
            style={{ width: width, marginRight: 0 }}
            name={["fields", config.name, "values"]}
            rules={[
                {
                    required: true,
                    message: "Enter value",
                },
            ]}
        >
            <Input inputMode={"numeric"} ref={inputRef} placeholder={t("FILTER.VALUE")} />
        </Form.Item>
    );
};
