import {action, makeObservable, observable} from "mobx";
import React from "react";
import {OrderDelivery} from "../../../models/orders/order-delivery/order.delivery";
import queryString from "query-string";
import {orderDeliveryService} from "../../../services/orders/order-delivery/orders.delivery";
import {orderDataService} from "../../../services/orders/order-data/order.data.service";
import {ordersListService} from "../../../services/orders/orders-list/orders.list.service";
import {OrderListClientAgreement} from "../../../models/orders/orders-list/order";
import {Error} from "../../../models/errors/error";
import {
    PayerOrReceiverDetails,
} from "../../../models/payer-receiver-seller/payer.reveiver.seller";
import {Amount} from "../../../models/prices/price";
import {NavigateFunction} from "react-router/dist/lib/hooks";

export class OrderDeliveryCreateStore {
    private orders: string[] | null = [];

    @observable
    public creating: boolean = false;

    @observable
    public loading: boolean = false;

    @observable
    public deliveryOrder: OrderDelivery | null = null;

    @observable
    public customAmount?: Partial<Amount> = {currencyCode: "eur"};

    @observable
    public warningsModalShown: boolean = false;

    @observable
    public warnings: Error[] | null = null;

    @observable
    public agreementsModalShown: boolean = false;

    @observable
    public agreements: OrderListClientAgreement[] | null = null;

    @observable
    public agreementsAccountId: number | null = null;

    constructor(private navigate: NavigateFunction) {
        makeObservable(this);
        this.init();
    }

    @action
    private setCreating(value: boolean): void {
        this.creating = value;
    }

    @action
    public setAgreementsAccountId(value: number | null): void {
        this.agreementsAccountId = value;
    }

    @action
    public setAgreementsModalShown(value: boolean): void {
        this.agreementsModalShown = value;
    }

    @action
    public setAgreements(value: OrderListClientAgreement[] | null): void {
        this.agreements = value;
    }

    @action
    public setWarnings(value: Error[] | null): void {
        this.warnings = value;
    }

    @action
    private setLoading(value: boolean): void {
        this.loading = value;
    }

    @action
    public setDeliveryOrder(value: OrderDelivery): void {
        this.deliveryOrder = value;
    }

    @action
    public setWarningsModalShown(value: boolean): void {
        this.warningsModalShown = value;
    }

    @action
    private setAddress(value: PayerOrReceiverDetails): void {
        this.deliveryOrder!.address = value;
    }

    @action
    public setCustomAmount(value: Amount): void {
        this.customAmount = value;
    }

    @action
    public selectShipping(
        id: string,
        checked: boolean
    ): void {
        const courierIndex = this.deliveryOrder?.couriers.findIndex(
            (courier) => courier.id === id
        );
        if (courierIndex !== -1 && courierIndex !== undefined) {
            this.deliveryOrder!.couriers = this.deliveryOrder!.couriers.map(
                (courier) => ({
                    ...courier,
                    checked: false,
                })
            );
            this.deliveryOrder!.couriers[courierIndex].checked = checked;

            if (this.deliveryOrder?.couriers.every((item) => !item.checked)) {
                this.deliveryOrder!.couriers[courierIndex].checked = true;
            }

            const checkedCourier = this.deliveryOrder?.couriers.find(item => item.checked);
            this.customAmount = checkedCourier?.price.cost || {value: 0, currencyCode: "eur"};
        }
    }

    public async openWarningsModal(id: number) {
        this.setWarnings(await orderDataService.getWarnings(id));
        this.setWarningsModalShown(true);
    }

    public closeWarningsModal = () => {
        this.setWarningsModalShown(false);
        this.setWarnings(null)
    }

    public async openAgreementsModal(id: number, orderId: number) {
        this.setAgreements(await ordersListService.getClientAgreements(id));
        this.setAgreementsModalShown(true);
        this.setAgreementsAccountId(orderId);
    }

    public closeAgreementsModal = () => {
        this.setAgreementsAccountId(null);
        this.setAgreementsModalShown(false);
        this.setAgreements(null);
    }


    public createOrder = async () => {
        this.setCreating(true);
        const values: any = {
            orders: this.orders!.map(item => parseInt(item, 10)),
            courier: this.deliveryOrder?.couriers.find(item => item.checked)?.id,
            customDeliveryPrice: this.customAmount && this.customAmount.value ? parseFloat(this.customAmount.value as any) : parseFloat("0.0")
        }
        const result = await orderDeliveryService.checkoutOrder(values)
        this.setCreating(false);
        this.navigate("/orders/" + result.id);
    }

    private async init() {
        this.setLoading(true);
        const params: any = queryString.parse(window.location.search.substr(1));
        this.orders = Array.isArray(params.orders) ? params.orders : [params.orders];
        const order = await orderDeliveryService.createDeliveryOrder({orders: this.orders!});
        this.setDeliveryOrder({...order, couriers: order.couriers.map((item, index) => ({...item, checked: index === 0}))});
        this.setLoading(false);
    }
}

export const OrderDeliveryCreateStoreContext = React.createContext<OrderDeliveryCreateStore | null>(null);
