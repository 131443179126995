import {Translate} from "../../../hooks/translate.hook";
import {SmartColumn} from "../../common/smart-table/smart-table.model";
import {DeclarationProvider} from "../upload/declaration.upload.model";
import {FieldType} from "../../common/smart-table/smart-table.field-config";

export const columnsFactory = (t: Translate): SmartColumn<DeclarationProvider>[] => {
    return [
        {
            visibleName: "ID",
            dataIndex: "id",
            name: "id",
            type: FieldType.INTEGER,
            filterable: true
        },
        {
            visibleName: t("DECLARATION.DOCUMENT_CODE"),
            dataIndex: "documentCode",
            name: "documentCode",
            type: FieldType.STRING,
            filterable: false
        },
        {
            visibleName: t("FILENAME"),
            dataIndex: "fileName",
            name: "fileName",
            type: FieldType.STRING,
            filterable: false
        }
    ]
}