import { AxiosInstance } from "axios";
import API from "../../utils/api";
import { Article } from "../../models/articles/article";
import { ArticleData, ArticleVehicle } from "../../models/articles/article.data";
import { ArticleAvailabilityStats } from "../../models/articles/article.availability.stats";
import { GraphDataWithMeta } from "../../models/graph/graph";
import { GraphRequest } from "../../models/reports/graph.request";
import { isEmpty } from "lodash";
import { ArticleGroup } from "../../models/articles/groups/article.groups";
import { Manufacturer } from "../../models/articles/manufatrurers/manufatrurer";
import { Model } from "../../models/articles/models/model";
import { ArticleSubgroup } from "../../models/articles/subgroups/subgroup";
import { Submodel } from "../../models/articles/submodels/submodel";

export class ArticlesService {
    api: AxiosInstance = API;

    searchArticle(value: string): Promise<Article[]> {
        return this.api
            .get<Article[]>("articles/search", {
                params: { value },
            })
            .then((x) => x.data);
    }

    getArticle(id: string): Promise<ArticleData> {
        return this.api.get<ArticleData>("articles/" + id).then((x) => x.data);
    }

    getArticleVehicles(id: string | number): Promise<ArticleVehicle[]> {
        return this.api.get<ArticleVehicle[]>("articles/" + id + "/vehicles").then((x) => x.data);
    }

    getArticleAvailabilityStatistics(
        id: number,
        request: GraphRequest,
        additionalFilters?: { [key: string]: any }
    ): Promise<GraphDataWithMeta<ArticleAvailabilityStats, any>> {
        if (!isEmpty(additionalFilters)) {
            for (const key in additionalFilters) {
                if (additionalFilters.hasOwnProperty(key)) {
                    request.addFilter(key, additionalFilters[key]);
                }
            }
        }
        return this.api
            .get<GraphDataWithMeta<ArticleAvailabilityStats, any>>("article/" + id + "/availability", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }

    getStockAmount(id: number, warehouseId: string): Promise<{ stock: number }> {
        return this.api.get("articles/" + id + "/stock/" + warehouseId).then((x) => x.data);
    }

    public async getManufacturers(): Promise<Manufacturer[]> {
        return (await this.api.get<Manufacturer[]>("vehicle/manufacturers")).data;
    }

    public async getManufacturerModels(manufacturerId: number): Promise<Model[]> {
        return (await this.api.get<Model[]>("vehicle/model/manufacturer/" + manufacturerId)).data;
    }

    public async getSubmodels(modelId: number): Promise<Submodel[]> {
        return (await this.api.get<Submodel[]>("vehicle/engine/model/" + modelId)).data;
    }

    public async getGroups(): Promise<ArticleGroup[]> {
        return (await this.api.get<ArticleGroup[]>("article/groups")).data;
    }

    public async getSubgroups(groupId: number): Promise<ArticleSubgroup[]> {
        return (await this.api.get<ArticleSubgroup[]>("article/group/" + groupId)).data;
    }
}

export const articlesService = new ArticlesService();
