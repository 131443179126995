import React, {useMemo, useState} from "react";
import {DatePicker, Form, Input, InputNumber, Modal} from "antd";
import {Property} from "../../../../../models/property/property";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {propertyService} from "../../../../../services/property/property.service";
import {
    UIInputWidgetPropertyResponsibleUser
} from "../../../ui/input/widget/property-responsible-user/ui.input.widget.property-responsible-user";

interface DecreaseQtyPropertyModalProps {
    onClose: () => void;
    property: Property;
}

export const DecreaseQtyPropertyModal: React.FC<DecreaseQtyPropertyModalProps> = ({ onClose, property }) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const initialValues = useMemo(() => {
        let values: any = {
            qty: 1
        }
        return values;
    }, [])

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("Write Off")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={onClose}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name={"integrations_form"}
                initialValues={initialValues}
                onFinish={() => form
                    .validateFields()
                    .then(async values => {
                        setLoading(true);
                        try {
                            await propertyService.decreasePropertyQty(property.id, values);
                            onClose();
                        } finally {
                            setLoading(false);
                        }
                    })
                }
            >
                <Form.Item
                    label={t("PROPERTY.PROPERTY.QTY")}
                    name="qty"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    label={t("COMMENT")}
                    name="comment"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "FROM.ERROR.PLEASE_SELECT_VALUE"
                            ),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('DATE')}
                    name={['date']}
                    rules={[{ required: true }]}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    label={t('PROPERTY.INVENTORY_TASK_PROPERTY_DOCUMENT_NUMBER.TITLE')}
                    name={['documentNumber']}
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('PROPERTY.INVENTORY_TASK_PROPERTY_ACCOUNTANT.TITLE')}
                    name={['accountant']}
                    rules={[{ required: true }]}
                >
                    <UIInputWidgetPropertyResponsibleUser />
                </Form.Item>
            </Form>
        </Modal>
    );
}