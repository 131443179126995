import React, {useEffect} from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {RouteTabs} from "../../common/route-tabs/route.tabs";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";

export const PropertySettings: React.FC<ComponentPropsFromRoute> = ({routeChildren}) => {

    const navigate  = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/property/settings") {
            navigate("/property/settings/types", {replace: true})
        }
    }, [location.pathname])

    return (
        <RouteTabs routes={routeChildren!} withoutRootTabPath={true} />
    )
}