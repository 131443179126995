import {Translate} from "../../../../../hooks/translate.hook";
import {ColumnFilterItem, ColumnsType} from "antd/lib/table/interface";
import {Claim, ClaimItem, ClaimItemAcceptStatus, ClaimItemType} from "../../../../../models/claims/claims";
import {Link} from "react-router-dom";
import React from "react";
import {ClaimsItemAcceptStatus, ClaimsItemType} from "../../../status/claim.item.status";
import {displayPrice} from "../../../../../models/prices/price.helper";
import {InvoiceDataDocument} from "../../../../../models/documents/invoices/invoice-data/invoice.data";
import {Warehouse} from "../../../../../models/warehouses/warehouse";
import {ClaimPartsDetails} from "./claim.parts.details";
import {FilterDropdown} from "../../../../ui/table/filter-dropdown/filter-dropdown";
import {nonStrictComparator, strictComparator} from "../../../../ui/table/filter-dropdown/filter-dropdown.comparator";

export const columnsFactory = (t: Translate, claim: Claim, onUpdate: () => void): ColumnsType<ClaimItem> => {
    return [
        {
            title: "#",
            key: "number",
            render: (_, __, number) => number + 1,
            shouldCellUpdate: () => false
        },
        {
            title: t("TYPE"),
            dataIndex: "type",
            key: "type",
            render: type => <ClaimsItemType type={type}/>,
            sorter: (a, b) => a.type > b.type ? 1 : -1,
            onFilter: strictComparator((r: ClaimItem) => r.type),
            filterDropdown: FilterDropdown,
            filters: Object.values(ClaimItemType).map(v => ({text: v.beautify(), value: v}))
        },
        {
            title: t('ARTICLES.TITLE_SINGLE'),
            dataIndex: "article",
            key: "article",
            render: article => <Link className={"link"} to={"/articles/" + article.name}>{article.name}</Link>,
            shouldCellUpdate: () => false,
            sorter: (a, b) => a.article.name > b.article.name ? 1 : -1,
            onFilter: nonStrictComparator((r: ClaimItem)  => r.article.name),
            filterDropdown: FilterDropdown,
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            render: status => <ClaimsItemAcceptStatus status={status} />,
            sorter: (a, b) => a.status > b.status ? 1 : -1,
            onFilter: strictComparator((r: ClaimItem) => r.status),
            filterDropdown: FilterDropdown,
            filters: Object.values(ClaimItemAcceptStatus).map(v => ({text: t("CLAIMS.CLAIM.ITEM_ACCEPT.STATUS." + v.toUpperCase()), value: v}))
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "priceByItem",
            key: "priceByItem",
            render: price => displayPrice(price),
            shouldCellUpdate: () => false.valueOf(),
            sorter: (a, b) => a.priceByItem.value - b.priceByItem.value
        },
        {
            title: t('QUANTITY_LONG'),
            dataIndex: "qty",
            key: "qty",
            sorter: (a, b) => a.qty - b.qty
        },
        {
            title: t('WAREHOUSES.WAREHOUSE_TITLE'),
            dataIndex: "warehouse",
            key: "warehouse",
            render: (warehouse: Warehouse) => warehouse?.name || "-",
            sorter: (a, b) => (a.warehouse?.id || "") > (b.warehouse?.id || "") ? 1 : -1,
            onFilter: strictComparator((r: ClaimItem) => r.warehouse?.id || ""),
            filterDropdown: FilterDropdown,
            filters: (() => {
                const warehouses: Record<string, ColumnFilterItem> = {};
                claim.items.forEach(i => {
                    if (i.warehouse) {
                        warehouses[i.warehouse.id] = {text: i.warehouse.name, value: i.warehouse.id};
                    } else {
                        warehouses[""] = {text: "-", value: ""};
                    }
                })
                return Object
                    .values(warehouses)
                    .sort((a, b) => (a.text || "") > (b.text || "") ? 1 : -1)
            })()
        },
        {
            title: t('INVOICE.TITLE'),
            dataIndex: "invoiceDocumentNumber",
            key: "invoiceDocumentNumber",
            render: (number, item) => {
                if (item.invoiceDocument) {
                    return <Link className={"link"} to={"/merged-invoices/" + item.invoiceDocument.id}>{item.invoiceDocument.docNumber}</Link>
                }

                return <Link className={"link"} to={"/merged-invoices?filter=" + encodeURIComponent('number__number=' + number + '|%')}>{number}</Link>
            },
            sorter: (a, b) => (a.invoiceDocument?.docNumber || a.invoiceDocumentNumber) > (b.invoiceDocument?.docNumber || b.invoiceDocumentNumber) ? 1 : -1,
            onFilter: nonStrictComparator((r: ClaimItem)  => r.invoiceDocument?.docNumber || r.invoiceDocumentNumber),
            filterDropdown: FilterDropdown,
        },
        {
            title: t('INVOICE.SALE.TITLE'),
            key: "saleDocumentNumber",
            render: (_: any, item) => {
                if (item.saleDocument) {
                    return <Link className={"link"} to={"/invoices/" + item.saleDocument.id}>{item.saleDocument.docNumber}</Link>
                }

                return <>-</>
            },
            sorter: (a, b) => (a.saleDocument?.docNumber || "") > (b.saleDocument?.docNumber || "") ? 1 : -1,
            onFilter: nonStrictComparator((r: ClaimItem)  => r.saleDocument?.docNumber || ""),
            filterDropdown: FilterDropdown,
        },
        {
            title: t('INVOICE.CREDIT.NUMBER_FEBEST'),
            dataIndex: "creditInvoiceDocument",
            key: "creditInvoiceDocument",
            render: (document?: InvoiceDataDocument) => document ? (<Link className={"link"} to={"/credit-invoices/" + document.id}>{document.docNumber}</Link>) : "-",
            onFilter: nonStrictComparator((r: ClaimItem)  => r.creditInvoiceDocument?.docNumber || ""),
            filterDropdown: FilterDropdown,
        },
        {
            key: "actions",
            render: (_, item) => <ClaimPartsDetails claim={claim} item={item} onUpdate={onUpdate} />,
            shouldCellUpdate: () => false
        }
    ];
}