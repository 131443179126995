import { BranchRequestValues } from "../../../models/branches/branch-data";
import { branchesService } from "../../../services/branches/branches.service";
import { action, makeObservable, observable } from "mobx";
import React from "react";
import { BranchData } from "../../../models/branches/branch-data";
import { PagesStore } from "../../../stores/pages/pages.store";

export class BranchDataStore {
    @observable
    public loading: boolean = false;

    @observable
    public branch: null | BranchData = null;

    @observable
    public editModalShown: boolean = false;

    constructor(private id: string, private pageStore: PagesStore, private url: string) {
        makeObservable(this);
        this.init();
    }

    public openEditModal = (): void => {
        this.setEditModalShown(true);
    };

    public closeEditModal = (): void => {
        this.setEditModalShown(false);
    };

    @action
    private setLoading(value: boolean): void {
        this.loading = value;
    }

    @action
    private setBranch(value: BranchData): void {
        this.branch = value;
    }

    @action
    private setEditModalShown(value: boolean): void {
        this.editModalShown = value;
    }

    public async editBranch(values: BranchRequestValues) {
        this.setBranch(
            await branchesService.updateBranch(this.id, {
                ...values,
                icCode: parseInt(values.icCode as string, 10),
            })
        );
    }

    private async init() {
        this.setLoading(true);
        this.setBranch(await branchesService.getBranch(this.id));
        this.pageStore.updateRouteName("branch", this.branch?.name);
        this.setLoading(false);
    }
}

export const BranchDataStoreContext = React.createContext<null | BranchDataStore>(null);
