import React, {useState} from "react";
import {Observer, observer} from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Button, Popconfirm, Space, Tooltip} from "antd";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import {UserAssignRulesStore} from "./user.assign.rules.store";
import {FilterKey} from "../../../../models/filtered-table/filtered.table.props";
import Date from "../../../date/date";
import {Group} from "../../../../models/users/group/group";
import {AssignRule} from "../../../../models/users/assign-rule/assign.rule";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AssignRulesModal} from "./assign-rules-modal/assign.rules.modal";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {ComponentPropsFromRoute} from "../../../../routers/routers";


interface UserAssignRules extends ComponentPropsFromRoute {}

export const UserAssignRules: React.FC<UserAssignRules> = observer(({name}) => {
    const {commonStore: {systemGroups}} = useRequiredStore(RootStoreContext);
    const [store] = useState(() => new UserAssignRulesStore());
    const t = useTranslate();

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("USERS.ASSIGNED_GROUP"),
            dataIndex: "group",
            key: "group",
            render: (group: Group) => <>{group.name}</>
        },
        {
            title: t("USERS.ASSIGNING_GROUP"),
            dataIndex: "assignerGroup",
            key: "assignerGroup",
            render: (group: Group) => <>{group.name}</>
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date: string) => <Date dateString={date} showTime={false}/>
        },
        {
            title: t("UPDATED_AT"),
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (date: string) => <Date dateString={date} showTime/>
        },
        {
            title: t("ACTION"),
            key: "action",
            width: 55,
            style: {display: "flex", justifyContent: "center"},
            render: (_: any, row: AssignRule) => (
                <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <Tooltip placement="topRight" title={t("EDIT")}>
                        <Button
                            className={"table-btn btn"}
                            onClick={() => store.openEditModal(row)}
                            type="default"
                            icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                        />
                    </Tooltip>
                    <Observer>
                        {() => (
                            <AssignRulesModal
                                item={row}
                                visible={store.isEditModalShown(row)}
                                onSuccess={(request, item) => item ? store.updateRule(request, item) : Promise.reject()}
                                onClose={store.closeEditModal}
                            />
                        )}
                    </Observer>
                    {" "}
                    <Popconfirm
                        title={t("ARE_YOU_SURE")}
                        onConfirm={() => store.removeRule(row)}
                        placement="left"
                        okText={t("YES")}
                        cancelText={t("NO")}
                    >
                        <Tooltip placement="topRight" title={t("REMOVE")}>
                            <Button className="table-btn btn-remove btn" type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "times"]}/>}/>
                        </Tooltip>
                    </Popconfirm>
                </div>
            )
        }
    ];

    const columnsConfigs = [
        {
            title: "ID",
            key: "id",
        },
        {
            title: "USERS.ASSIGNED_GROUP",
            key: "group",
        },
        {
            title: "USERS.ASSIGNING_GROUP",
            key: "assignerGroup",
        },
        {
            title: "CREATED_AT",
            key: "createdAt",
        },
        {
            title: "UPDATED_AT",
            key: "updatedAt",
        },
    ]

    const filters: FilterKey[] = [
        {
            key: "id",
            label: "ID",
            type: "int"
        },
        {
            key: "group",
            label: t("USERS.ASSIGNED_GROUP"),
            type: "groupedSelect",
            groupedOptions: systemGroups.map(item => ({
                groupName: item.name,
                options: item.groups.map(group => ({label: group.name, value: String(group.id)}))
            }))
        },
        {
            key: "assignerGroup",
            label: t("USERS.ASSIGNING_GROUP"),
            type: "groupedSelect",
            groupedOptions: systemGroups.map(item => ({
                groupName: item.name,
                options: item.groups.map(group => ({label: group.name, value: String(group.id)}))
            }))
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date",
        },
        {
            key: "updatedAt",
            label: t("UPDATED_AT"),
            type: "date",
        }
    ];


    return (
        <Space direction={"vertical"} size={[30, 30]}>
            <FilteredTable
                columns={columns}
                columnsConfigs={{columns: columnsConfigs, pageKey: name}}
                filterKeys={filters}
                dataHandler={store.dataHandler}
                buttons={[
                    <Button onClick={store.openCreateModal} key={0} type="primary">
                        {t("USERS.ASSIGN_RULES.CREATE")}
                    </Button>
                ]}
            />
            <AssignRulesModal visible={store.isCreateModalShown()} onClose={store.closeCreateModal}
                              onSuccess={store.createRule}/>
        </Space>
    );
});
