import React, {useState} from "react";
import {observer} from "mobx-react";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {Col, DatePicker, Form, Modal, notification, Row} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import dayjs from "dayjs";
import {DispatchAwareInterface} from "../../../../services/documents/invoices/dispatch-aware.interface";


interface InvoicesDispatchModalProps {
    invoicesToDispatch: number[] | React.Key[],
    visible: boolean,
    onClose: () => void,
    onInvoicesDispatched?: () => void,
    dispatchAware: DispatchAwareInterface;
}
export const InvoicesDispatchModal: React.FC<InvoicesDispatchModalProps> = observer(({visible, onClose, onInvoicesDispatched, invoicesToDispatch, dispatchAware}) => {

    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();


    const dateFormat: string = "DD-MM-YYYY";


    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={t("INVOICES.DISPATCH.TITLE")}
            okText={t("DISPATCH.DO_DISPATCH")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"dispatch_modal"}
                initialValues={{
                    date: dayjs()
                }}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        dispatchAware.dispatchInvoices({date: dayjs(values.date).format("YYYY-MM-DD"), invoices: invoicesToDispatch})
                            .then(() => {
                                form.resetFields();
                                if (onInvoicesDispatched) {
                                    onInvoicesDispatched();
                                }
                                notification.success({
                                    message: t("SUCCESS"),
                                    description: t("INVOICES.DISPATCH.SUCCESS"),
                                    duration: 4
                                })
                                return onClose();
                            }, (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                            .then(() =>
                                onClose(),
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="date"
                            label={t("INVOICES.DISPATCH.DISPATCH_DATE")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <DatePicker
                                disabled={loading}
                                style={{width: "100%"}}
                                placeholder={t("DATE")}
                                format={dateFormat}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
});
