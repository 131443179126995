import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useNavigate} from "react-router";
import {useRequiredStore} from "../../../utils/store";
import {LoginStore, LoginStoreContext} from "./login.store";
import {RootStoreContext} from "../../../stores/root/root.store";
import {useTranslate} from "../../../hooks/translate.hook";
import {Form, Input, Button, notification} from "antd";
import {UserOutlined, LockOutlined} from "@ant-design/icons";
import "./login.scss";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../models/core/response";
import {LoginWrapper} from "../login.wrapper";
import {Link} from "react-router-dom";

export const LoginComponent = observer(() => {
    const {authStore: {isLoggedIn}} = useRequiredStore(RootStoreContext)
    const store = useRequiredStore(LoginStoreContext);
    const t = useTranslate();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<boolean>(false);

    const onFinish = async (value: any): Promise<void> => {
        store.setUsername(value.username);
        store.setPassword(value.password ? value.password.trim() : "");
        await store.auth();
    }

    useEffect(() => {
        if (isLoggedIn) {
            setRedirect(true);
        }
    }, [])

    if (redirect) {
        navigate('/');
        return;
    }

    return (
        <LoginWrapper title="LOGIN.TITLE">
            <Form
                form={form}
                name="normal_login"
                className="login-form"
                layout="vertical"
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        onFinish(values)
                            .then(() => {
                                form.resetFields();
                            }, (e: AxiosError<ErrorResponseData>) => {
                                setLoading(false);
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                                if (e.response?.status === 401) {
                                    notification.error({
                                        message: t("ERROR"),
                                        description: t("LOGIN.INVALID")
                                    });
                                }
                            })
                    })
                }
            >
                <Form.Item name="username" rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]} label={t("USERNAME")}>
                    <Input
                        disabled={loading}
                        prefix={<UserOutlined className="site-form-item-icon"/>}
                        placeholder={t("USERNAME")}
                    />
                </Form.Item>
                <Form.Item name="password" rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]} label={t("PASSWORD.TITLE")}>
                    <Input
                        disabled={loading}
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        type="password"
                        placeholder={t("USERNAME")}
                    />
                </Form.Item>
                <Form.Item>
                    <Link
                        to="/forgot-password"
                        // style={{marginBottom: 24}}
                    >
                        {t("FORGOT_PASSWORD.TITLE")}
                    </Link>
                </Form.Item>
                <Form.Item style={{marginTop: 0}}>
                    <Button style={{width: "100%"}} disabled={loading} type="primary" htmlType="submit">{t("LOGIN.LOG_IN")}</Button>
                </Form.Item>
            </Form>
        </LoginWrapper>
    );
});

export const Login = () => {

    const rootStore = useRequiredStore(RootStoreContext);
    const navigate = useNavigate();
    const loginStore = new LoginStore(rootStore.authStore, navigate);

    return (
        <LoginStoreContext.Provider value={loginStore}>
            <LoginComponent/>
        </LoginStoreContext.Provider>
    );
};
