import { Amount } from "./price";
import { splitNumber } from "../../utils/helpers";

export type Currency = "EUR" | "GBP" | "USD" | "BGN";
export type CurrencyMap = Record<Currency, string>;

export const currencies: CurrencyMap = {
    EUR: "€",
    GBP: "₤",
    USD: "$",
    BGN: "BGN",
};

export const displayCurrencyCode = (code: string) => {
    return currencies[code.toUpperCase() as keyof CurrencyMap] || code;
}

export const displayPrice = (amount: Amount) => {
    return (
        <span style={{ whiteSpace: "nowrap", textAlign: "right" }}>
            {createVisiblePrice(amount)}
        </span>
    );
};

export const displayPriceValue = (value: number): string => {
    return splitNumber(value.toFixed(2)).replace(".", ",");
}

export const createVisiblePrice = ({ value, currencyCode }: Amount, toK = false): string => {
    let displayValue = "";

    if (toK && (value / 1000) > 1) {
        displayValue = (value / 1000).toFixed(0) + "K";
    } else {
        displayValue = displayPriceValue(value);
    }

    return (displayValue + " " + displayCurrencyCode(currencyCode));
};
