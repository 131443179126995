import React from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Descriptions} from "antd";
import {Claim} from "../../../../models/claims/claims";
import {Link} from "react-router-dom";
import Date from "../../../date/date";

export interface ClaimDescriptionProps {
    claim: Claim;
}

export const ClaimDescription: React.FC<ClaimDescriptionProps> = ({claim}) => {
    const t = useTranslate();

    return (
        <div className="data-descriptions-with-edit-wrapper">
            <div className="data-content-wrapper">
                <Descriptions
                    className="data-content-wrapper-descriptions"
                    size={"small"}
                    layout={"vertical"}
                    contentStyle={{ fontSize: "17px" }}
                    labelStyle={{ color: "#858585" }}
                    column={{
                        xxl: 4,
                        xl: 4,
                        lg: 4,
                        md: 3,
                        sm: 2,
                        xs: 1,
                    }}
                >
                    <Descriptions.Item label={t("CLIENT.NUMBER")}>
                        <Link className="link" to={"/clients/" + claim.account.agent.number}>
                            {claim.account.agent.name} ({claim.account.agent.number})
                        </Link>
                    </Descriptions.Item>

                    <Descriptions.Item label={t("DATE")}>
                        <Date dateString={claim.documentDate} showTime />
                    </Descriptions.Item>

                    <Descriptions.Item label={t("Created By")}>
                        {claim.user.firstName} {claim.user.lastName}
                    </Descriptions.Item>

                </Descriptions>
            </div>
        </div>
    );
}