import React from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {ColumnsType} from "antd/es/table";
import {PackingList, PackingListInvoiceDocument} from "../../../../models/documents/packing-list/packing-list";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {Link} from "react-router-dom";
import {DocumentMasterOrder} from "../../../../models/documents/master-order";
import Date from "../../../date/date";
import {FieldGroupConfig} from "../../../../models/filter/filter.group";
import {FieldTypeName} from "../../../../models/filter/filter.field.type.name";
import {DocumentsMenu} from "../../../common/documents/menu/documents.menu";
import {Space, Typography} from "antd";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import {packingListService} from "../../../../services/documents/packing-lists/packing-list.service";

interface PackingListsListProps extends ComponentPropsFromRoute{};
export const PackingLists: React.FC<PackingListsListProps> = observer(({name}) => {
    const t = useTranslate();
    const columns: ColumnsType<PackingList> = [
        {
            title: t("DOCUMENT_NUMBER"),
            dataIndex: "docNumber",
            key: "packing_lists_docNumber",
            width: "120px",
            render: (item: string | null, row) => item ? (
                <Link to={"/packing-lists/" + row.id}>{item}</Link>
            ) : null
        },
        {
            title: t("INVOICES.NUMBER"),
            dataIndex: "invoiceDocument",
            key: "packing_lists_invoiceDocument",
            width: "120px",
            render: (item: PackingListInvoiceDocument) => item ? (
                <Link to={"/invoices/" + item.id}>{item.docNumber}</Link>
            ) : null
        },
        {
            title: t("CLIENT.NAME"),
            dataIndex: "agent",
            key: "packing_lists_client",
            render: (agent) => (
                <Link to={"/clients/" + agent.number}>{agent.name}</Link>
            ),
        },
        {
            title: t("PAYMENT_TERMS"),
            dataIndex: "paymentTerms",
            key: "paymentTerms",
            width: 105
        },
        // {
        //     title: t("ORDERS.ORDER.TITLE"),
        //     dataIndex: "masterOrder",
        //     key: "packing_lists_masterOrder",
        //     width: "100px",
        //     render: (item: DocumentMasterOrder | null) => item ? <Link to={"/orders/" + item.id}>{item.id}</Link> : null,
        // },
        {
            title: t("WEIGHT_BRUTTO"),
            dataIndex: "totalGrossWeight",
            key: "packing_lists_totalGrossWeight"
        },
        {
          title: t("DATE"),
          dataIndex: "date",
          key: "packing_lists_date",
          render: (date: string | null) => date ? <Date dateString={date} showTime withSeconds/> : null
        },
    ];

    const columnsConfigs = [
        {
            title: "DOCUMENT_NUMBER",
            key: "packing_lists_docNumber",
        },
        {
            title: "INVOICES.NUMBER"  ,
            key: "packing_lists_invoiceDocument"
        },
        {
            title: "CLIENT.NAME",
            key: "packing_lists_client",
        },
        {
            title: "PAYMENT_TERMS",
            key: "packing_lists_payment_terms",
        },
        {
            title: "ORDERS.ORDER.TITLE",
            key: "packing_lists_masterOrder",
        },
        {
            title: "WEIGHT_BRUTTO",
            key: "packing_lists_totalGrossWeight"
        },
        {
            title: "DATE",
            key: "packing_lists_date",
        }
    ];

    const filterGroups: FieldGroupConfig[] = [
        {
            name: "docNumber",
            visibleName: "DOCUMENT_NUMBER",
            fields: [
                {
                    name: "docNumber",
                    type: FieldTypeName.STRING
                }
            ]
        },
        {
            name: "invoice",
            visibleName: "INVOICES.NUMBER",
            fields: [
                {
                    name: "invoiceDocument",
                    type: FieldTypeName.STRING
                }
            ]
        },
        {
            name: "client",
            visibleName: "CLIENT.NAME",
            fields: [
                {
                    name: "clientName",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "paymentTerms",
            visibleName: "PAYMENT_TERMS",
            fields: [
                {
                    name: "clientPaymentTerms",
                    type: FieldTypeName.INTEGER,
                },
            ],
        },
        {
            name: "order",
            visibleName: "ORDERS.ORDER.TITLE",
            fields: [
                {
                    name: "id",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "weightBrutto",
            visibleName: "WEIGHT_BRUTTO",
            fields: [
                {
                    name: "totalGrossWeight",
                    type: FieldTypeName.INTEGER,
                },
            ],
        },
        {
            name: "date",
            visibleName: "DATE",
            fields: [
                {
                    name: "date",
                    type: FieldTypeName.DATE_RANGE,
                },
            ],
        },
    ]


    return (
        <Space direction="vertical" size={[50, 50]}>
            <DocumentsMenu/>
            <Space direction="vertical" size={[0, 0]}>
                <Typography.Title level={3}>{t("PACKING_LISTS.TITLE")}</Typography.Title>
                <Space direction={"vertical"} size={[15, 15]}>
                    <FilteredTable
                        columns={columns}
                        columnsConfigs={{ columns: columnsConfigs, pageKey: name }}
                        filterGroups={filterGroups}
                        loadDataCallback={request => packingListService.getPackingLists(request)}
                    />
                </Space>
            </Space>
        </Space>
    )
})
