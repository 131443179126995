import { AxiosInstance } from "axios";
import API from "../../../utils/api";
import { ShipmentWithTrackingUrl } from "../../../models/parcels/shipment";

export class ShipmentsService {
    private api: AxiosInstance = API;

    getDocumentShipments(id: number): Promise<ShipmentWithTrackingUrl[]> {
        return this.api.get<ShipmentWithTrackingUrl[]>("documents/" + id + "/shipments").then((x) => x.data);
    }
}

export const shipmentsService = new ShipmentsService();
