import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {
    MonthlyStatement,
    MonthlyStatementItem,
    UploadMonthlyStatement
} from "../../models/accounting/monthly-statements";
import {PaginatedResult} from "../../models/core/paginated.result";
import {PaginatedRequest} from "../../models/core/paginated.request";

export class MonthlyStatementService {
    api: AxiosInstance = API;

    get(id: number): Promise<MonthlyStatement> {
        return this.api.get('accounting/monthly-statement/' + id)
            .then((x) => x.data);
    }

    getAll(request: PaginatedRequest): Promise<PaginatedResult<MonthlyStatement>> {
        return this.api.get('accounting/monthly-statement', {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }

    getAllItemsForStatement(id: number, request: PaginatedRequest): Promise<PaginatedResult<MonthlyStatementItem>> {
        return this.api.get('accounting/monthly-statement/' + id + '/items', {
            params: request.toParams(),
        })
            .then((x) => x.data);
    }

    uploadStatement(dto: UploadMonthlyStatement): Promise<MonthlyStatement> {

        const formData = new FormData();
        formData.append('month', dto.month.toString());
        formData.append('year', dto.year.toString());
        formData.append('file', dto.file);

        return this.api
            .post<MonthlyStatement>('accounting/monthly-statement/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(x => x.data);
    }

    recheck(itemId: number): Promise<MonthlyStatementItem> {
        return this.api.post<MonthlyStatementItem>('accounting/monthly-statement/items/' + itemId + '/recheck').then(x => x.data);
    }

    skip(itemId: number): Promise<MonthlyStatementItem> {
        return this.api.post<MonthlyStatementItem>('accounting/monthly-statement/items/' + itemId + '/skip').then(x => x.data);
    }
}

export const monthlyStatementService = new MonthlyStatementService();