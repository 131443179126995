import {AxiosInstance} from "axios";
import API from "../../../utils/api";
import {CreditInvoiceItemCreate} from "../../../models/documents/invoices/credit-invoice/credit.invoice";
import {PaginatedRequest} from "../../../models/core/paginated.request";
import {PaginatedResult} from "../../../models/core/paginated.result";
import {Return} from "../../../models/documents/returns/returns";

export class ReturnsService {
    private api: AxiosInstance = API;

    getInvoice(id: string): Promise<Return> {
        return this.api.get<Return>("documents/returns/" + id).then(x => x.data);
    }

    getAll(request: PaginatedRequest): Promise<PaginatedResult<Return>> {
        return this.api.get<PaginatedResult<Return>>("documents/returns").then(x => x.data);
    }

    getRelatedForOrderItem(id: number): Promise<Return[]> {
        return this.api.get<Return[]>("documents/returns/related-for-item/" + id).then(x => x.data);
    }

    createWithSingleItem(dto: CreditInvoiceItemCreate): Promise<Return> {
        return this.api.post<Return>("documents/returns", dto).then(x => x.data);
    }

    addItemToCreditInvoice(id: number, dto: CreditInvoiceItemCreate): Promise<Return> {
        return this.api.post<Return>("documents/returns/" + id + "/items", dto).then(x => x.data);
    }
}

export const returnsService = new ReturnsService();
