import React, {useEffect, useMemo, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {Button, DatePicker, Form, Row, Space, Table, Typography} from "antd";
import {createWeekAgo, createYesterday} from "../../../../utils/date";
import {ColumnsType} from "antd/lib/table/interface";
import i18n from "i18next";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useURLParams} from "../../../../hooks/url-params.hook";
import {useVT} from "virtualizedtableforantd4";
import {Export} from "../../../common/export/export";
import {createDefaultFormValues, createFormValues, createQuery} from "./articles.stock-report.helper";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {ArticleSearch} from "../../common/article-search/article-search";
import {ArticleStockFormValue, ArticleStockQuery} from "../../../../models/reports/article-stock/article-stock";
import {WarehouseSelect} from "../../../warehouses/common/warehouse-select/warehouse-select";
import {Warehouse} from "../../../../models/warehouses/warehouse";
import {reportsService} from "../../../../services/reports/reports.service";
import {columnsFactory} from "./articles.stock-report.columns";
import {ArticlesLinks} from "../articles-links/articles.links";
import dayjs from "dayjs";

export const ArticlesStockReport: React.FC<ComponentPropsFromRoute> = () => {

    const y = 660;
    const {urlParams, setURLParams} = useURLParams();
    const t = useTranslate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [exportModalShown, setExportModalShown] = useState<boolean>(false);
    const [defaultFormValues, setDefaultFormValues] = useState<ArticleStockFormValue>();
    const [stocks, setStocks] = useState<any[]>([]);
    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);

    const [lastQuery, setLastQuery] = useState<ArticleStockQuery>();
    const columns: ColumnsType<any> = useMemo(() => columnsFactory(t, warehouses, lastQuery?.warehouses || []), [i18n.language, warehouses, lastQuery?.warehouses]);

    const [vt] = useVT(() => ({ scroll: { y: y }, debug: true }), [stocks, columns]);

    const updateUrlParams = (values: ArticleStockFormValue, replace: boolean = false) => {
        // @ts-ignore
        setURLParams(createQuery(values), replace);
    }

    const updateDate = (date: dayjs.Dayjs) => {
        form.setFieldsValue({date})
        form.submit();
    }

    useEffect(() => {
        let formValues = createFormValues(urlParams);
        if (!formValues) {
            formValues = createDefaultFormValues(createYesterday());
            updateUrlParams(formValues, true);
            return;
        }

        const query = createQuery(formValues);

        setLastQuery(query);
        setLoading(true);
        setDefaultFormValues(formValues);

        reportsService.getArticleStocks(query).then(stocks => {
            setStocks(stocks)
            setLoading(false);
        });
    }, [urlParams]);

    if (!defaultFormValues) {
        return <LoadingSpin />;
    }

    return (
        <Space direction={"vertical"} size={[50, 50]}>
            <ArticlesLinks />
            <Space direction="vertical" size={[20, 20]}>
                <Row style={{ marginBottom: 10 }} justify="space-between" wrap>
                    <Typography.Title level={3} style={{ margin: 0 }}>
                        {t("ARTICLES.STOCK-REPORT.TITLE")}
                    </Typography.Title>
                </Row>
                <Button
                    onClick={() => setExportModalShown(true)}
                    type={"primary"}
                >
                    {t("REPORTS.DOWNLOAD")}
                </Button>
                <Form
                    form={form}
                    layout="vertical"
                    name={"detailed_shipments_form"}
                    initialValues={defaultFormValues}
                    onFinish={updateUrlParams}
                >
                    <Space direction={"horizontal"}>
                        <Form.Item name="article">
                            <ArticleSearch
                                onChange={() => form.submit()}
                                selectStyle={{width: 250}}
                            />
                        </Form.Item>
                        <Form.Item name="warehouses">
                            <WarehouseSelect
                                onChange={() => form.submit()}
                                selectMode={"multiple"}
                                selectStyle={{width: 200}}
                                onListUpdated={warehouses => setWarehouses(warehouses)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="date"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "FROM.ERROR.PLEASE_SELECT_VALUE"
                                    ),
                                },
                            ]}
                        >
                            <DatePicker
                                allowClear={false}
                                style={{ width: 200 }}
                                onChange={() => form.submit()}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Space direction={"horizontal"}>
                                <Button key={0} type={"primary"} onClick={() => updateDate(createYesterday())}>Yesterday</Button>
                                <Button key={1} type={"primary"} onClick={() => updateDate(createWeekAgo())}>A week ago</Button>
                            </Space>
                        </Form.Item>
                    </Space>
                </Form>
                    <Table
                        rowKey={"id"}
                        components={vt}
                        columns={columns}
                        dataSource={stocks}
                        loading={loading}
                        scroll={{ x: true, y }}
                        pagination={false}
                    />
            </Space>
            <Export
                title={"DOWNLOAD.DO_DOWNLOAD"}
                isShown={exportModalShown}
                withBasicExportFields
                config={{
                    link: "/api/v3/reports/article-stock",
                    query: lastQuery,
                }}
                onClose={() => setExportModalShown(false)}
            />
        </Space>
    )
}