import React, { useState } from 'react';
import {UploadFile} from "antd/es/upload/interface";
import {ClaimItem} from "../../../../../models/claims/claims";
import {createFileList} from "./file_list.factory";
import {FileUploader} from "../../../../core/file-uploader/file-uploader";
import {claimService} from "../../../../../services/claims/claims.service";

export interface ClaimPartsImagesProps {
    item: ClaimItem;
}

export const ClaimPartsImages: React.FC<ClaimPartsImagesProps> = ({item}) => {

    const [fileList, setFileList] = useState<UploadFile<any>[]>(() => createFileList(item.attachments));

    return (
        <FileUploader
            value={fileList}
            onChange={fileList => setFileList(fileList)}
            action={item ? ("/api/v3/claim/item/" + item.id + "/attachment") : ("/api/v3/claim/attachment")}
            onRemove={file => claimService.removeFile(parseInt(file.uid))}
        />
    )
};