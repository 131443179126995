import React, { Context, createContext, useCallback, useEffect, useRef, useState } from "react";
import { Tag } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterPopup from "./popup/filter.popup";
import { FieldConfigChoices, FieldGroupConfig } from "../../../../models/filter/filter.group";
import { FieldStateGroup } from "../../../../models/filter/filter.field.state";
import { FieldGroupSnapshot, restoreFieldGroupSnapshots } from "../../../../models/filter/filter.field.snapshot";
import { createFormValues } from "../../../../models/filter/filter.form.value";
import { fieldTypes } from "../../../../models/filter/filter.field.type.config";
import { createRequestFilters } from "../../../../models/filter/request.filters";
import "./filter.scss";
import {useTranslate} from "../../../../hooks/translate.hook";
interface FilterContext {
    choices: Record<string, FieldConfigChoices>;
    setChoices: React.Dispatch<React.SetStateAction<Record<string, FieldConfigChoices>>>;
}
export const FilterContext = createContext<null | FilterContext>(null) as Context<FilterContext>;
interface FilterProps {
    config: FieldGroupConfig[];
    defaultValue?: FieldGroupSnapshot[];
    onUpdated: (stateGroups: FieldStateGroup[], requestFiltes: { [key: string]: any }) => void;
    onInternalUpdated?: (stateGroups: FieldStateGroup[], requestFiltes: { [key: string]: any }) => void;
    onInit?: (stateGroups: FieldStateGroup[], requestFiltes: { [key: string]: any }) => void;
}

const Filter: React.FC<FilterProps> = ({ config, defaultValue, onUpdated, onInternalUpdated, onInit }) => {
    const t = useTranslate();
    const [isPopupShown, setIsPopupShown] = useState<boolean>(false);
    const [filterStates, setFilterStates] = useState<FieldStateGroup[]>([]);
    const [editingStateGroup, setEditingStateGroup] = useState<FieldStateGroup | undefined>(undefined);
    const [choices, setChoices] = useState<Record<string, FieldConfigChoices>>({});

    const inited = useRef(false);
    const defaultValueRef = useRef(defaultValue);

    const filterContext: FilterContext = {
        choices,
        setChoices,
    };

    useEffect(() => {
        let stateGroups: FieldStateGroup[] = [];
        if (defaultValue) {
            stateGroups = restoreFieldGroupSnapshots(defaultValue, config);
            setFilterStates(stateGroups);
        }

        config.forEach((config) => {
            config.fields.forEach((field) => {
                if (field.choices && !field.choicesLoader) {
                    setChoices((prev) => ({ ...prev, ...{ [field.name]: field.choices || [] } }));
                } else if (field.choicesLoader && stateGroups.length > 0) {
                    const stateGroup = stateGroups.find((item) => item.name === config.name);
                    if (stateGroup && !choices[field.name]) {
                        setChoices((prev) => ({ ...prev, ...{ [field.name]: [] } }));
                        field.choicesLoader(createFormValues(stateGroup).fields).then((receivedChoices) => {
                            setChoices((prev) => ({ ...prev, ...{ [field.name]: receivedChoices } }));
                        });
                    }
                }
            });
        });

        if (defaultValueRef.current !== defaultValue) {
            if (!onInternalUpdated) {
                onInternalUpdated = onUpdated;
            }

            onInternalUpdated(stateGroups, createRequestFilters(stateGroups));
            defaultValueRef.current = defaultValue;
        }

        if (!inited.current) {
            if (onInit) {
                onInit(stateGroups, createRequestFilters(stateGroups));
            }

            inited.current = true;
        }
    }, [config, defaultValue]);

    const openPopup = (): void => {
        setIsPopupShown(true);
    };
    const closePopup = (): void => {
        setIsPopupShown(false);
    };

    const deleteStateGroup = useCallback(
        (group: FieldStateGroup): void => {
            group.states.forEach((state) => {
                if (state.config.requireFields && state.config.requireFields.length > 0) {
                    // if (true) {
                    setChoices((prev) => {
                        const newValues = { ...prev };
                        delete newValues[state.config.name];
                        return newValues;
                    });
                }
            });

            const removeIndex = filterStates.indexOf(group);

            if (removeIndex !== -1) {
                const newGroups = [...filterStates];
                newGroups.splice(removeIndex, 1);
                setFilterStates(newGroups);
                onUpdated(newGroups, createRequestFilters(newGroups));
            }
        },
        [filterStates, onUpdated]
    );

    const editStateGroup = (group: FieldStateGroup): void => {
        setEditingStateGroup(group);
        openPopup();
    };

    const onRemove = (): void => {
        setEditingStateGroup(undefined);
    };

    const onCompleted = (group: FieldStateGroup): void => {
        const newStateGroups = [...filterStates];
        if (editingStateGroup) {
            const editingTagIndex = newStateGroups.indexOf(editingStateGroup);
            if (editingTagIndex !== -1) {
                newStateGroups[editingTagIndex] = group;
            }
        } else {
            newStateGroups.push(group);
        }
        setIsPopupShown(false);

        setFilterStates(newStateGroups);
        onUpdated(newStateGroups, createRequestFilters(newStateGroups));
    };

    return (
        <FilterContext.Provider value={filterContext}>
            <div className="filter-wrapper" onClick={openPopup}>
                <FontAwesomeIcon
                    icon={["fas", "filter"]}
                    size={"sm"}
                    color={"rgba(0,0,0, 0.3)"}
                    style={{ marginRight: "10px", marginLeft: "4px" }}
                    className={"filter-icon"}
                />
                {filterStates.length > 0 ? (
                    filterStates.map((state) => (
                        <Tag
                            key={state.name}
                            closable
                            onClick={() => editStateGroup(state)}
                            onClose={() => deleteStateGroup(state)}
                            style={{ cursor: "pointer" }}
                            color={state === editingStateGroup ? "#f93822" : "#108ee9"}
                        >
                            {t(state.visibleName)}:{" "}
                            {state.states.map((item) => {
                                const val = fieldTypes[item.config.type].getDisplayValue(
                                    item,
                                    choices[item.config.name]
                                );

                                return (
                                    <span key={state.name}>
                                        {Array.isArray(val) ? (val as string[]).join(", ") : val}
                                    </span>
                                );
                            })}
                        </Tag>
                    ))
                ) : (
                    <span className={"filterPlaceHolder"}>{t("FILTER.ADD")}</span>
                )}
                {isPopupShown ? (
                    <FilterPopup
                        configs={config.filter(
                            (item) =>
                                !filterStates
                                    .filter((item) => editingStateGroup !== item)
                                    .map((state) => state.name)
                                    .includes(item.name)
                        )}
                        onCompleted={onCompleted}
                        close={closePopup}
                        editingStateGroup={editingStateGroup}
                        removeEditingTag={onRemove}
                    />
                ) : null}
            </div>
        </FilterContext.Provider>
    );
};

export default Filter;
