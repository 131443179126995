import { FilterDataHandler } from "../../../../models/filter/filter.data.handler";
import { Invoice } from "../../../../models/documents/invoices/invoice";
import { invoiceService } from "../../../../services/documents/invoices/invoices.service";
import React from "react";
import { action, makeObservable, observable } from "mobx";
import { PaginatedRequest } from "../../../../models/core/paginated.request";
import { FilterButtonConfig } from "../../../../models/filtered-table/filtered.table.props";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { downloadBlobFile } from "../../../../utils/blob.download";
import { agentService } from "../../../../services/agents/agent.service";

dayjs.extend(utc);
dayjs.extend(timezone);

export enum InvoicesFilterButtonsTypes {
    ALL_INVOICES = "all_invoices",
    DISPATCHED_TODAY_INVOICES = "dispatched_today_invoices",
    NOT_DISPATCHED_INVOICES = "not_dispatched_invoices",
}

export type InvoicesListStoreModalType = "dispatch" | "download" | "export";

export type ActiveInvoicesFilterButton = InvoicesFilterButtonsTypes | undefined;

export class InvoicesListStore {
    public request: PaginatedRequest | null = null;

    public dataHandler: FilterDataHandler<Invoice>;

    public dateFormat: string = "DD-MM-YYYY";

    private date: dayjs.Dayjs = dayjs.tz(dayjs(), dayjs.tz.guess());

    public filterButtonConfigs: FilterButtonConfig<ActiveInvoicesFilterButton>[] = [
        {
            type: InvoicesFilterButtonsTypes.ALL_INVOICES,
            text: "INVOICES.ALL",
            filters: [],
        },
        {
            type: InvoicesFilterButtonsTypes.DISPATCHED_TODAY_INVOICES,
            text: "INVOICES.DISPATCH.DISPATCHED_TODAY",
            filters: [
                {
                    name: "date",
                    snapshots: [
                        {
                            name: "date",
                            value: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")].join("--"),
                        },
                    ],
                },
                {
                    name: "status",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "isDispatched",
                            value: "1",
                        },
                    ],
                },
            ],
        },
        {
            type: InvoicesFilterButtonsTypes.NOT_DISPATCHED_INVOICES,
            text: "INVOICES.DISPATCH.NOT_DISPATCHED",
            filters: [
                {
                    name: "status",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "isDispatched",
                            value: "0",
                        },
                    ],
                },
            ],
        },
    ];

    @observable
    public dispatchModalShown: boolean = false;

    @observable
    public downloadModalShown: boolean = false;

    @observable
    public checkedInvoices: React.Key[] = [];

    @observable
    public exportInvoicesModalShown: boolean = false;

    constructor() {
        makeObservable(this);
        this.onDispatched = this.onDispatched.bind(this);
        // this.onDispatchClick = this.onDispatchClick.bind(this);
        // this.onDispatchClose = this.onDispatchClose.bind(this);
        // this.onDownloadButtonClick = this.onDownloadButtonClick.bind(this);
        // this.onDownloadClose = this.onDownloadClose.bind(this);
        this.onExportCreated = this.onExportCreated.bind(this);
        this.setDispatchModalShown = this.setDispatchModalShown.bind(this);
        this.setDownloadModalShown = this.setDownloadModalShown.bind(this);
        this.setExportInvoicesModalShown = this.setExportInvoicesModalShown.bind(this);
        this.dataHandler = new FilterDataHandler<Invoice>((request) => invoiceService.getInvoices(request));
    }

    @action
    private setExportInvoicesModalShown(value: boolean): void {
        this.exportInvoicesModalShown = value;
    }

    @action
    private setDispatchModalShown(value: boolean): void {
        this.dispatchModalShown = value;
    }

    @action
    private setDownloadModalShown(value: boolean): void {
        this.downloadModalShown = value;
    }

    @action
    private setCheckedInvoices(invoices: React.Key[]): void {
        this.checkedInvoices = invoices;
    }

    public handleModal(modalType: InvoicesListStoreModalType, value: boolean): void {
        const modalTypes: Record<InvoicesListStoreModalType, (value: boolean) => void> = {
            dispatch: this.setDispatchModalShown,
            download: this.setDownloadModalShown,
            export: this.setExportInvoicesModalShown,
        };
        modalTypes[modalType](value);
    }

    public searchAgents = async (value: string) => {
        const accounts = await agentService.searchWholesaleAccount(value);
        return accounts.map(account => ({value: account.id, label: account.agent.number + " - " + account.agent.name}));
    };

    public async createExportInvoices(values: { weight: number }): Promise<void> {
        const { headers, data } = await invoiceService.createExportInvoices({
            weight: values.weight,
            invoices: this.checkedInvoices as number[],
        });
        const contentDisposition = headers["content-disposition"];
        const type = headers["content-type"];
        const filename = contentDisposition.split("filename=")[1];
        // const invoiceBlob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        const invoiceBlob = new Blob([data], { type });
        downloadBlobFile(invoiceBlob, filename);
        await this.onExportCreated();
    }

    public checkInvoicesToDispatch(invoices: React.Key[]): void {
        this.setCheckedInvoices(invoices);
    }

    public async onExportCreated() {
        this.setCheckedInvoices([]);
        await this.dataHandler.reloadItems(this.request!);
    }

    public async onDispatched() {
        this.setCheckedInvoices([]);
        await this.dataHandler.reloadItems(this.request!);
    }

    public setRequest(value: PaginatedRequest | null): void {
        this.request = value;
    }
}

export const InvoicesListStoreContext = React.createContext<null | InvoicesListStore>(
    null
) as React.Context<InvoicesListStore>;
