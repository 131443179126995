import React from "react";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
import {RouteButtons} from "../../../common/route-tabs/route.buttons";

interface AgentDataInvoicesWrapperProps extends ComponentPropsFromRoute {
}

export const AgentDataInvoicesWrapper: React.FC<AgentDataInvoicesWrapperProps> = ({ routeChildren }) => (
    <RouteButtons
        routes={routeChildren!}
        withoutRootTabPath={true}
    />
);
