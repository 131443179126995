import React, {useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {Button, Col, Row, Space, Tabs, Typography, Form, Input, Checkbox, Select, Divider} from "antd";
import {wikiService} from "../../../../services/wiki/wiki.service";
import {useParams, useLocation, useOutletContext} from "react-router-dom";
import {
    WikiArticleAccess,
    WikiEditableArticle,
    WikiEditableArticleContent,
    WikiTreeItem
} from "../../../../models/wiki/wiki";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {useTranslate} from "../../../../hooks/translate.hook";
import {MarkdownTextarea} from "../../../core/markdown/markdown-textarea/markdown-textarea";
import {MarkdownHeaders} from "../../../core/markdown/markdown-headers/markdown-headers";
import {times} from "lodash";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {useNavigate} from "react-router";
import {WikiEditLanguageSelectModal} from "./edit.language-select.modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "i18next";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {WikiPagePermissions} from "../permissions/permissions";
import {WikiPagePermissionsEditModal} from "../permissions/edit-modal/edit-modal";
import {isAllowed} from "../../../../utils/helpers";

const { Title } = Typography;
const { TabPane } = Tabs;

interface FormDefaultValues {
    isCategory: boolean;
    contents: WikiEditableArticleContent[];
    parent?: number;
}

const generateCategoriesTree = (categories: WikiTreeItem[], level: number = 0): React.ReactElement => {
    return <>{categories.map(category => (
            <React.Fragment key={category.id}>
                <Select.Option value={category.id} key={category.id}>
                    {times(level).map(() => (<>-&nbsp;-&nbsp;</>))} {category.title}
                </Select.Option>
                {generateCategoriesTree(category.children, level + 1)}
            </React.Fragment>
        ))}</>
}

const getDefaultFormValues = (article: WikiEditableArticle): FormDefaultValues => {

    const defaultValues: FormDefaultValues = {
        isCategory: !article.leaf,
        contents: [],
        parent: article.parent
    }

    article.contents.forEach((editableContent => {
        defaultValues.contents.push(editableContent);
    }))

    return defaultValues;
}

const getDefaultFormValuesForCreate = (): FormDefaultValues => {

    return {
        isCategory: true,
        contents: [{title: '', description: '', language: i18n.language, body: ''}]
    }
}

const formToRequest = (defaultValues: FormDefaultValues, values: FormDefaultValues, defaultLanguage: string, accesses: WikiArticleAccess[]): any => {

    const content: any = {
        leaf: !values.isCategory,
        contents: [],
        defaultLanguage: defaultLanguage,
        parent: values.parent || null,
        accesses: accesses.map(access => {
            return {
                system: access.system.id,
                branch: access.branch?.id,
                department: access.department?.id ,
                departmentRole: access.departmentRole?.id,
                level: access.level
            }
        })
    }

    for (const langKey in values.contents) {
        content.contents.push({...values.contents[langKey], language: defaultValues.contents[langKey].language})
    }

    return content;
}

export const WikiEditPage: React.FC = () => {

    const {setOverloadNodes} = useOutletContext<{setOverloadNodes: (items: WikiTreeItem[]) => void}>();

    const {pageStore} = useRequiredStore(RootStoreContext);

    const match = useLocation();

    const t = useTranslate();

    const params = useParams<{ id: string }>();

    const [article, setArticle] = useState<WikiEditableArticle>();

    const [accesses, setAccesses] = useState<WikiArticleAccess[]>([]);

    const [editPermission, setEditPermission] = useState<WikiArticleAccess | null>(null);

    const [openPermissionsEditModal, setOpenPermissionsEditModal] = useState<boolean>(false);

    const [defaultValues, setDefaultValues] = useState<FormDefaultValues>();

    const [categories, setCategories] = useState<WikiTreeItem[]>([]);

    const [contentValue, setContentValue] = useState<string>("");

    const [openSelectLanguageModal, setOpenSelectLanguageModal] = useState<boolean>(false);

    const [usedLanguages, setUsedLanguages] = useState<string[]>([]);

    const [defaultLanguage, setDefaultLanguage] = useState<string>("");

    const [activeLangTabKey, setActiveLangTabKey] = useState<string>("0");

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);

    const onContentChange = (() => {
        let timeout: NodeJS.Timeout;
        return (text: string) => {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                setContentValue(text);
            }, 1000);
        }
    })()

    const onLanguageTabChange = (tabIndex: string) => {
        onContentChange(form.getFieldValue(["contents", tabIndex, "body"]));
        setActiveLangTabKey(tabIndex);
    }

    const uploadFile = (file: File): Promise<[string, string]> => {
        return wikiService.uploadFile(file);
    }

    const onLanguageListModify = (targetKey: any, eventName: string) => {
        if (eventName === 'add') {
            setOpenSelectLanguageModal(true);
        } else {
            if (defaultValues) {
                if (defaultValues.contents.length === 1) {
                    return;
                }

                const content = defaultValues.contents[targetKey];

                defaultValues.contents.splice(targetKey, 1);
                setDefaultValues({...defaultValues});

                if (content.language === defaultLanguage) {
                    setDefaultLanguage(defaultValues.contents[0].language);
                }
            }
        }
    }

    const onLanguageSelected = (value: string): void => {
        setOpenSelectLanguageModal(false);
        if (value !== "" && defaultValues) {
            const insertedKey = defaultValues.contents.push({title: '', description: '', body: '', language: value}) - 1
            setDefaultValues({...defaultValues})
            setActiveLangTabKey(String(insertedKey))
        }
    }

    const onPermissionChangeCompleted = (access: WikiArticleAccess, editable: WikiArticleAccess | null): void => {
        const newAccesses = [...accesses];
        if (editable) {
            const idx = newAccesses.indexOf(editable);
            if (-1 !== idx) {
                newAccesses[idx] = access;
            }
        } else {
            newAccesses.push(access);
        }

        setOpenPermissionsEditModal(false);
        setEditPermission(null);
        setAccesses(newAccesses);
    }

    const onPermissionRemove = (access: WikiArticleAccess): void => {
        const newAccesses = [...accesses];
        const idx = newAccesses.indexOf(access);
        if (-1 !== idx) {
            newAccesses.splice(idx, 1);
            setAccesses(newAccesses);
        }
    }

    const onPermissionChangeAborted = (): void => {
        setOpenPermissionsEditModal(false);
        setEditPermission(null);
    }

    const openEditPermissionModal = (access: WikiArticleAccess | null): void => {
        setOpenPermissionsEditModal(true);
        setEditPermission(access);
    }

    useEffect(() => {
        if (params.id) {
            wikiService
                .getEditableArticle(parseInt(params.id!))
                .then(article => {
                    setArticle(article)
                    if (article.contents[0]) {
                        pageStore.updateRouteName(match.pathname, article.contents[0].title)
                        onContentChange(article.contents[0].body);
                    }
                })
        }
    }, [params.id])

    useEffect(() => {
        wikiService
            .getCategories()
            .then(categories => setCategories(categories))
    }, [])

    useEffect(() => {
        if (params.id) {
            if (article) {
                setDefaultValues(getDefaultFormValues(article));
                setDefaultLanguage(article.defaultContent.language)
            }
        } else {
            setDefaultValues(getDefaultFormValuesForCreate());
            setDefaultLanguage(i18n.language);
        }

    }, [params.id, article])

    useEffect(() => {
        if (defaultValues) {
            setUsedLanguages(defaultValues.contents.map(content => content.language));
        }
    }, [defaultValues])

    useEffect(() => {
        setAccesses([...article?.accesses || []]);
    }, [article])

    if (!defaultValues) {
        return <LoadingSpin />
    }

    return (
        <>
            <Form
                size={"middle"}
                form={form}
                layout="vertical"
                name={"edit_user_form"}
                initialValues={defaultValues}
                onFinishFailed={(info) => {
                    for (const fieldKey in info.errorFields) {
                        const field = info.errorFields[fieldKey];
                        if (field.name[0] === 'contents') {
                            setActiveLangTabKey(String(field.name[1]));
                            break;
                        }
                    }
                }}
                onFinish={() => form
                    .validateFields()
                    .then(values => {

                        setLoading(true);

                        let promise: Promise<any>;

                        if (article) {
                            promise = wikiService.updateArticle(article.id, formToRequest(defaultValues, values, defaultLanguage, accesses));
                        } else {
                            promise = wikiService.createArticle(formToRequest(defaultValues, values, defaultLanguage, accesses));
                        }

                        promise.then(
                               (item: WikiTreeItem) => {
                                   setOverloadNodes([item]);
                                   navigate('/wiki/page/' + item.id);
                               },
                               (e: AxiosError<ErrorResponseData>) => {
                                   setLoading(false);
                                   if (e.response?.data.fields) {
                                       form.setFields(e.response?.data?.fields);
                                   }
                               })
                           .then(() =>
                               (e: any) => form.setFields(e.response?.data?.fields))
                           .finally(() => setLoading(false));
                    })
                }
            >
                <Row wrap={false} gutter={[20,0]}>
                    <Col flex="auto">

                        <Title level={2}>{article ? t("WIKI.EDIT-PAGE.TITLE") : t("WIKI.CREATE-PAGE.TITLE")}</Title>

                        <Tabs
                            type={"editable-card"}
                            activeKey={activeLangTabKey}
                            onChange={onLanguageTabChange}
                            onEdit={onLanguageListModify}
                            destroyInactiveTabPane={false}
                        >
                            {defaultValues.contents.map((content, key) => (
                                <TabPane
                                    tab={<>
                                        {defaultLanguage === content.language
                                            ? <FontAwesomeIcon icon={["fas", "check-circle"]} color={"#096dd9"} />
                                            : null}&nbsp;
                                        {content.language.toUpperCase()}
                                    </>}
                                    key={key}
                                    closable={true}
                                    forceRender={true}
                                >
                                    <Row gutter={[10, 10]} align={"middle"}>
                                        <Col span={24}>
                                            <Button
                                                type={"primary"}
                                                onClick={() => setDefaultLanguage(content.language)}
                                                disabled={content.language === defaultLanguage}
                                            >
                                                {t("WIKI.EDIT-PAGE.DEFAULT_LANGUAGE")}
                                            </Button>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name={["contents", key, "title"]}
                                                label={t("WIKI.CREATE-PAGE.FIELDS.TITLE")}
                                                rules={[{required: true}]}
                                            >
                                                <Input type={"text"} disabled={loading}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name={["contents", key, "description"]}
                                                label={t("WIKI.CREATE-PAGE.FIELDS.DESCRIPTION")}
                                                rules={[{required: true}]}
                                            >
                                                <Input type={"text"} disabled={loading}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name={["contents", key, "body"]}
                                                rules={[{required: true}]}
                                            >
                                                <MarkdownTextarea onChange={onContentChange} fileUploader={uploadFile} disabled={loading} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                            ))}
                        </Tabs>
                    </Col>
                    <Col flex="280px">
                        <Space direction={"vertical"} split={<Divider orientation="left" />}>
                            <Button type="primary" onClick={() => form.submit()} disabled={loading} style={{width: '100%'}}>
                                {t("SAVE")}
                            </Button>
                            <Space direction={"vertical"} >
                                <Title level={4}>Settings</Title>
                                <Form.Item
                                    name={["parent"]}
                                    label={t("WIKI.CATEGORY.PARENT")}
                                >
                                    <Select allowClear={true}>
                                        {generateCategoriesTree(categories)}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    style={{marginBottom: 0}}
                                    name={["isCategory"]}
                                    valuePropName={"checked"}
                                >
                                    <Checkbox disabled={loading}>
                                        Use as category
                                    </Checkbox>
                                </Form.Item>
                            </Space>
                            {isAllowed(attributes, "wiki_manage_permissions") ? (
                                <Space direction={"vertical"}>
                                    <Space direction={"horizontal"} align={"start"} style={{justifyContent: "space-between"}}>
                                        <Title level={4}>Permissions</Title>
                                        <Button
                                            type={"default"}
                                            size={"middle"}
                                            onClick={() => openEditPermissionModal(null)}
                                            icon={<FontAwesomeIcon icon={["fas", "plus"]} />}
                                        />
                                    </Space>
                                    <WikiPagePermissions accesses={accesses} onEdit={openEditPermissionModal} onRemove={onPermissionRemove} />
                                </Space>
                            ) : null}
                            <Space direction={"vertical"}>
                                <Title level={4}>Contents</Title>
                                <MarkdownHeaders value={contentValue} />
                            </Space>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <WikiEditLanguageSelectModal
                visible={openSelectLanguageModal}
                onSubmit={onLanguageSelected}
                excludedLanguages={usedLanguages}
            />
            <WikiPagePermissionsEditModal
                permission={editPermission}
                visible={openPermissionsEditModal}
                onSubmit={onPermissionChangeCompleted}
                onClose={onPermissionChangeAborted}
            />
        </>
    )
}