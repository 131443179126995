import React, {useEffect, useMemo, useState} from "react";
import {Button, Space, Table, Typography} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {DocumentFilesUploadModal} from "./document.files.upload.modal";
import {DocumentFile} from "../../../../models/documents/common/files";
import {filesService} from "../../../../services/documents/common/files.service";
import {columnsFactory} from "./document.files.columns";

interface DocumentFilesProps {
    id: number;
}

export const DocumentFiles: React.FC<DocumentFilesProps> = ({id}) => {

    const t = useTranslate();

    const [addFileModalShown, setAddFileModalShown] = useState<boolean>(false);

    const [files, setFiles] = useState<DocumentFile[]>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const reload = async () => {
        try {
            setLoading(true);
            setFiles(await filesService.getFiles(id));
        } finally {
            setLoading(false);
        }
    }

    const removeFile = async (fileId: number) => {
        setLoading(true);
        try {
            await filesService.deleteFile(fileId);
            await reload();
        } finally {
            setLoading(false);
        }
    }

    const columns = useMemo(() => columnsFactory(t, removeFile), [])

    useEffect(() => {
        reload();
    }, [])

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <Space direction="vertical" size={[10, 10]}>
            <Typography.Title level={3}>{t("DOCUMENT.FILES.TITLE")}</Typography.Title>
            <Button type={"primary"} onClick={() => setAddFileModalShown(true)}>{t("UPLOAD.TITLE")}</Button>
            {addFileModalShown ? (
                <DocumentFilesUploadModal
                    id={id}
                    onClose={() => setAddFileModalShown(false)}
                    onComplete={() => reload()}
                />
            ) : null}
            {files.length ? (
                <Table
                    style={{width: '50%'}}
                    dataSource={files}
                    columns={columns}
                    pagination={false}
                >
                </Table>
            ) : null}
        </Space>
    );
}