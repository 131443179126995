import React, { useState } from 'react';
import {UploadFile} from "antd/es/upload/interface";
import {FileUploader} from "../../../../../core/file-uploader/file-uploader";
import {Property, PropertyFile, PropertyFileType} from "../../../../../../models/property/property";
import {createFileList} from "./file_list.factory";
import {propertyService} from "../../../../../../services/property/property.service";

export interface ClaimPartsImagesProps {
    property: Property;
    files: PropertyFile[];
    disableUpload?: boolean;
    type: PropertyFileType;
}

export const ReviewListPropertyImages: React.FC<ClaimPartsImagesProps> = ({property, files, disableUpload, type}) => {

    const [fileList, setFileList] = useState<UploadFile<any>[]>(() => createFileList(files));

    return (
        <FileUploader
            value={fileList}
            onChange={fileList => setFileList(fileList)}
            action={"/api/v3/property/" + property.id + "/attachment"}
            onRemove={file => propertyService.removeFile(parseInt(file.uid))}
            disableUpload={disableUpload}
            data={{type: type}}
        />
    )
};