import React from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Typography} from "antd";
import {Claim} from "../../../../models/claims/claims";
import {SmartLogger} from "../../../logger/smart-logger/smart-logger";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {isAllowed} from "../../../../utils/helpers";
import {observer} from "mobx-react";

export interface ClaimCommentsFormProps {
    claim: Claim;
}

export const ClaimLogger: React.FC<ClaimCommentsFormProps> = observer(({claim}) => {
    const t = useTranslate();

    const {
        authStore: { attributes }
    } = useRequiredStore(RootStoreContext);

    if (!isAllowed(attributes, "smartlogger.list")) {
        return null;
    }

    return (
        <>
            <Typography.Title level={3}>{t("LOGGER.TITLE")}</Typography.Title>
            <SmartLogger entityName={"Claim\\Claim"} entityId={claim.id.toString()} />
        </>
    );
});
