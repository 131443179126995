import React, {PropsWithChildren} from "react";
import {Button, Col, Descriptions, Row} from "antd";
import {displayPrice} from "../../../models/prices/price.helper";
import {splitNumber} from "../../../utils/helpers";
import "./table.footer.scss"
import {TableFooterData} from "../../../models/table-footer/table.footer.data";
import {useTranslate} from "../../../hooks/translate.hook";

interface TableFooterProps {
    data: TableFooterData
}

export const TableFooter: React.FC<PropsWithChildren<TableFooterProps>> = (
    {
        data: {
            totalAmount,
            totalWeight,
            grandTotalAmount,
            taxPercent,
            taxAmount,
            rounding,
            qty,
            salePercentage
        },
        children
    }) => {
    const t = useTranslate();

    return (
        <Row justify={children ? "space-between" : "end"}>
            {children ? (
                <Col xxl={13} xl={12} md={12} sm={24} xs={24}>
                    {children}
                </Col>
            ) : null}
            <Col xxl={9} xl={10} md={12} sm={24} xs={24}>
                <Descriptions
                    size={"small"}
                    layout={"horizontal"}
                    contentStyle={{fontWeight: "bold"}}
                    column={1}
                    className="table-footer-descriptions"

                >
                    <Descriptions.Item  label={t("SHIPPED_QUANTITY")}>{qty !== undefined ? (splitNumber(qty) + t("PCS")) : "UNDEFINED"}</Descriptions.Item>
                    <Descriptions.Item label={t("TOTAL_WEIGHT")}>{totalWeight !== undefined ? String(totalWeight).replaceAll(".", ",") : "UNDEFINED"} ({t("KG")})</Descriptions.Item>
                    <Descriptions.Item label={t("TOTAL_PRICE")}>{totalAmount !== undefined ? displayPrice(totalAmount) : "UNDEFINED"}</Descriptions.Item>
                    <Descriptions.Item label={"VAT(" + taxPercent + "%)"}>{taxAmount !== undefined ? displayPrice(taxAmount) : "UNDEFINED"}</Descriptions.Item>
                    {rounding !== undefined ? (
                        <Descriptions.Item label={t("ROUNDING")}>{displayPrice(rounding)}</Descriptions.Item>
                    ) : null}
                    <Descriptions.Item label={t("GRAND_TOTAL_PRICE")}>{grandTotalAmount !== undefined ? displayPrice(grandTotalAmount) : "UNDEFINED"}</Descriptions.Item>
                    {salePercentage !== undefined ? (
                        <Descriptions.Item label={t("SALE_PERCENTAGE")}>{salePercentage}%</Descriptions.Item>
                    ) : null}
                </Descriptions>
            </Col>
        </Row>
    )
}
