import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {Konto} from "../../models/konto/konto";

export class KontoService {
    api: AxiosInstance = API;

    getAll(): Promise<Konto[]> {
        return this.api.get('konto').then(x => x.data);
    }
}

export const kontoService = new KontoService();
