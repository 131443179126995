import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/lib/table/interface";
import {Table} from "antd";

interface ViewArrayAsTableProps {
    items: Record<string, any>[];
}

export const ViewArrayAsTable : React.FC<ViewArrayAsTableProps> = ({items}) => {

    const [columns, setColumns] = useState<ColumnsType<any>>([]);

    useEffect(() => {
        setColumns(Object.keys(items[0]).map(k => {
            return {
                key: k,
                dataIndex: k,
                title: k
            }
        }));

    }, [items])

    return (
        <Table
            columns={columns}
            dataSource={items}
            pagination={false}
        />
    )
}