import {AxiosInstance} from "axios";
import API from "../../../utils/api";
export class SourcesService {
    private api: AxiosInstance = API;
    getSources(documentId: number): Promise<any[]> {
        return this.api
            .get('documents/' + documentId + '/sources')
            .then(x => x.data);
    }

    compare(id: number): Promise<any[]> {
        return this.api
            .get('documents/sources/' + id + '/compare')
            .then(x => x.data);
    }

    downloadSource(id: number): Promise<{blob: Blob, filename: string}> {
        return this.api
            .get("documents/sources/" + id + "/file.json", {
                responseType: 'blob',
            })
            .then(resp => {
                return {
                    blob: resp.data,
                    filename: resp.headers["content-disposition"].split("filename=")[1].replaceAll('"', '')
                };
            });
    }

    handle(id: number): Promise<any> {
        return this.api
            .post("documents/sources/" + id + "/handle")
    }

}
export const sourcesService = new SourcesService();