import React, {useState} from "react";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {QrCodeScanner} from "../../../../common/qr-code-scanner/qr-code-scanner";
import {LoadingSpin} from "../../../../common/loading-spin/loading.spin";
import {propertyService} from "../../../../../services/property/property.service";
import {useNavigate} from "react-router";
import {notifications} from "../../../../../services/notifications/notifications";

export const PropertyListFindByQrCode: React.FC<ComponentPropsFromRoute> = () => {

    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const onDecode = async (value: string) => {
        try {
            setLoading(true);
            const property = await propertyService.getByCode(value);
            navigate('/property/' + property.id);
        } catch (e: any) {
            notifications.message("error", "Not found");
            setLoading(false);
        }
    }

    if (loading){
        return <LoadingSpin />
    }

    return (
        <>
            <h1>Search by QR-code</h1>
            <QrCodeScanner onDecode={onDecode} />
        </>
    )
}