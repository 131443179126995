import {ArticleStockFormValue, ArticleStockQuery} from "../../../../models/reports/article-stock/article-stock";
import {ParsedQuery} from "query-string";
import dayjs from "dayjs";

const DATE_FORMAT = 'YYYY-MM-DD';

export const createDefaultFormValues = (dateRange: dayjs.Dayjs): ArticleStockFormValue => {
    return {
        date: dateRange
    }
}

export const createFormValues = (query: ParsedQuery): ArticleStockFormValue|null => {
    if (!query.date) {
        return null;
    }

    return {
        article: parseInt(query.article as string) || undefined,
        date: dayjs(query.date as string, DATE_FORMAT),
        warehouses: query.warehouses as string[] || []
    }
}

export const createQuery = (values: ArticleStockFormValue): ArticleStockQuery => {
    return {
        article: values.article || undefined,
        date: values.date.format(DATE_FORMAT),
        warehouses: values.warehouses || []
    }
}