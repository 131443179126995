import React from "react";
import {observer} from "mobx-react";
import {Button, Space} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {OrderDeliveryCreateStoreContext} from "../order.delivery.create.store";
import {useNavigate} from "react-router";

export const OrderDeliveryButtons: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(OrderDeliveryCreateStoreContext);
    const navigate = useNavigate();

    return (
        <Space direction="horizontal" size={[10, 10]} wrap>
            <Button
                loading={store.creating}
                type="primary"
                onClick={store.createOrder}
            >
                {t("ORDERS.CREATE.FROM_WAREHOUSE") + " " + store.deliveryOrder?.warehouse.name}
            </Button>
            <Button
                danger
                style={{ width: "100%" }}
                onClick={() =>
                   navigate("/orders")
                }
                disabled={store.creating}
            >
                {t("ORDERS.CANCEL.TITLE")}
            </Button>
        </Space>
    )
})
