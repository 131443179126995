import React, { useEffect, useState } from "react";
import { Button, Col, Form, notification, Popconfirm, Row, Space, Table, Tooltip } from "antd";
import { useRequiredStore } from "../../../../../utils/store";
import { OrdersCreateStoreContext } from "../orders.create.store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { useVT } from "virtualizedtableforantd4";
import { OrdersArticleModal } from "../../article-modal/orders.article.modal";

import { observer } from "mobx-react";
import { EditableCell } from "./editable-cell/editable.cell";
import "./orders.create.table.scss";
import { Amount } from "../../../../../models/prices/price";
import { displayPrice } from "../../../../../models/prices/price.helper";
import { OrdersCreateQuantity } from "../../../../../models/orders/orders-cart/orders-create/orders.create.quantity";
import {
    OrdersCreateTableLastRecord,
    OrdersCreateTableRecord,
} from "../../../../../models/orders/orders-cart/orders-create/orders.create.table.record";
import { OrdersCartCalculatedPrice } from "../../../../../models/orders/orders-cart/orders-create/orders.cart";
import { displayCustomPrice } from "../../../../../models/orders/orders-cart/orders-create/orders-cart-create-helper/orders.cart.create.helper";
import { PricesLegend } from "../../../../common/prices-legend/prices.legend";
import { Link } from "react-router-dom";
import i18n from "i18next";

export const OrdersCreateTable: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(OrdersCreateStoreContext);
    const [modalShown, setModalShown] = useState<boolean>(false);

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState<string | number>("");

    const [moveButtonClicked, setMoveButtonClicked] = useState<boolean>(false);
    const [moveButtonLoading, setMoveButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        i18n.on("languageChanged", store.onLngChangeCreate);
        return () => i18n.off("languageChanged", store.onLngChangeCreate);
    }, []);

    useEffect(() => {
        if (store.tableData.length) {
            setMoveButtonClicked(false);
        }
    }, [store.tableData]);

    const openNotification = (type: "error" | "success") => {
        notification[type]({
            message: t(type.toUpperCase()),
            description: t(type === "success" ? "ORDERS.MOVE.SUCCESS" : "SOMETHING_WENT_WRONG"),
        });
    };

    const isRowDisabled = (row: OrdersCreateTableRecord & OrdersCreateTableLastRecord): boolean =>
        !!(!row.quantity.qty && row.id);

    const generateColumns = () => {
        const columns: any[] = [
            {
                title: t("ARTICLES.TITLE_SINGLE"),
                dataIndex: "article_name",
                key: "article_name",
                width: "120px",
                render: (name: string, row: OrdersCreateTableRecord & OrdersCreateTableLastRecord) =>
                    name && name.toUpperCase() !== "DELIVERY" ? (
                        <Link
                            to={"/articles/" + name}
                            className={isRowDisabled(row) ? "disabled" : undefined}
                            style={{ whiteSpace: "nowrap" }}
                        >
                            {name}
                        </Link>
                    ) : (
                        name
                    ),
            },
            {
                title: t("DESCRIPTION"),
                dataIndex: "article_description",
                key: "article_description",
                width: "250px",
                render: (item: string, row: OrdersCreateTableRecord & OrdersCreateTableLastRecord) => (
                    <span className={isRowDisabled(row) ? "disabled" : undefined}>{item}</span>
                ),
            },
        ];

        columns.push({
            title: t("PRICE"),
            dataIndex: "price",
            key: "price",
            editable: true,
            align: "right",
            width: "130px",
            render: (
                item: OrdersCartCalculatedPrice | undefined,
                row: OrdersCreateTableRecord & OrdersCreateTableLastRecord
            ) =>
                item ? (
                    <span className={isRowDisabled(row) ? "disabled" : undefined}>
                        {displayCustomPrice(item, t("PRICES." + item.type.toUpperCase()))}
                    </span>
                ) : null,
        });

        store.warehouses.forEach((warehouse) => {
            if (store.editFormValues.warehouses.includes(warehouse.id)) {
                columns.push({
                    title: warehouse.name,
                    dataIndex: warehouse.id,
                    key: warehouse.id,
                    align: "left",
                    width: "100px",
                    render: (item: string, row: OrdersCreateTableRecord & OrdersCreateTableLastRecord) => {
                        if (typeof item === 'object') {
                            return (
                                <span className={isRowDisabled(row) ? "disabled" : undefined}>
                                    {item[0]}
                                    {item[1] ? (<div className={"cart-row-backorder"}>+{item[1]} <span className={"cart-row-backorder-mark"}>(backorder)</span></div>) : null}
                                </span>
                            )
                        } else {
                            return null
                        }
                    },
                });
            }
        });

        columns.push({
            title: t("QUANTITY"),
            dataIndex: "quantity",
            key: "quantity",
            editable: true,
            width: "110px",
            render: (item: OrdersCreateQuantity, row: OrdersCreateTableRecord & OrdersCreateTableLastRecord) =>
                item ? (
                    <span className={isRowDisabled(row) ? "disabled" : undefined}>
                        {item.qty} / {item.requestedQty}
                    </span>
                ) : null,
        });

        columns.push({
            title: t("TOTAL_AMOUNT"),
            dataIndex: "total_amount",
            key: "total_amount",
            width: "90px",
            render: (item: Amount | undefined, row: OrdersCreateTableRecord & OrdersCreateTableLastRecord) =>
                item ? <span className={isRowDisabled(row) ? "disabled" : undefined}>{displayPrice(item)}</span> : null,
        });

        columns.push({
            title: t("SALE_PERCENTAGE"),
            key: "salePercentage",
            dataIndex: "sale_percentage",
            width: "90px",
            render: (item: number) => <>
                {item !== undefined ? ((item || "0") + "%") : ""}
            </>
        });

        columns.push({
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            width: "100px",
            render: (_: any, record: OrdersCreateTableRecord & OrdersCreateTableLastRecord) => {
                const editable = isEditing(record);
                if (record.article_name) {
                    return (
                        <Space style={{ width: "auto" }} direction="horizontal" size={[5, 5]}>
                            {editable ? (
                                <Tooltip placement="topRight" title={t("SAVE")}>
                                    <Button
                                        className="table-btn btn"
                                        onClick={() => save(record)}
                                        type="default"
                                        icon={<FontAwesomeIcon icon={["fas", "check"]} />}
                                    />
                                </Tooltip>
                            ) : (
                                <Tooltip placement="topRight" title={t("EDIT")}>
                                    <Button
                                        className="table-btn btn"
                                        disabled={editingKey !== ""}
                                        onClick={() => edit(record)}
                                        type="default"
                                        icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                                    />
                                </Tooltip>
                            )}

                            <Popconfirm
                                title={t("ARE_YOU_SURE")}
                                onConfirm={() => store.deleteCartItem(record.id)}
                                placement="left"
                                okText={t("YES")}
                                cancelText={t("NO")}
                            >
                                <Tooltip placement="topRight" title={t("REMOVE")}>
                                    <Button
                                        className="table-btn btn btn-remove "
                                        disabled={editingKey !== ""}
                                        type="default"
                                        icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                                    />
                                </Tooltip>
                            </Popconfirm>
                        </Space>
                    );
                }

                return <></>;
            },
        });
        return columns;
    };

    const isEditing = (record: OrdersCreateTableRecord & OrdersCreateTableLastRecord) => record.id === editingKey;

    const edit = (record: OrdersCreateTableRecord & OrdersCreateTableLastRecord & { id: React.Key }) => {
        form.setFieldsValue({
            price: record.price.amount.value,
            quantity: record.quantity.requestedQty,
        });
        setEditingKey(record.id);
    };

    const save = async (record: OrdersCreateTableRecord & OrdersCreateTableLastRecord) => {
        try {
            const row = await form.validateFields();
            // quantity
            if (
                record.price.amount.value !== parseFloat(row.price) ||
                record.quantity.requestedQty !== parseInt(row.quantity, 10)
            ) {
                await store.updateCartItem(record.id, {
                    customAmountValue: row.price,
                    qty: row.quantity,
                    type: store.type
                });
            }
            setEditingKey("");
        } catch (errInfo) {}
    };

    const columns: any[] = generateColumns();

    const mergedColumns = columns.map((col: any) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: OrdersCreateTableRecord & OrdersCreateTableLastRecord) => ({
                record,
                inputType: "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const y = 500;
    const [vt, setComponent] = useVT(() => ({ scroll: { y }, debug: true }), [y]);
    setComponent({
        body: {
            cell: EditableCell,
        },
    });

    return (
        <>
            <Space
                size={[10, 10]}
                direction="vertical"
                style={{
                    maxWidth: "100%",
                    overflowY: "auto",
                    marginBottom: 15,
                }}
            >
                <Row className="orders-create-buttons-legend">
                    <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                        <PricesLegend style={{ paddingTop: 5 }} />
                    </Col>
                    <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                        <Row className="orders-create-buttons" justify="end">
                            <Button
                                onClick={store.createOrder}
                                type="primary"
                                loading={store.checkoutButtonDisabled}
                                disabled={store.cart?.qty === 0}
                            >
                                {t("ORDERS.CHECKOUT")}
                            </Button>
                            <Button
                                loading={moveButtonLoading}
                                onClick={async () => {
                                    setMoveButtonClicked(true);
                                    setMoveButtonLoading(true);
                                    try {
                                        await store.moveCartItemsToMissingItems();
                                        openNotification("success");
                                        setMoveButtonLoading(false);
                                    } catch (e: any) {
                                        openNotification("error");
                                        setMoveButtonLoading(false);
                                    }
                                }}
                                type="primary"
                                disabled={store.isMoveButtonDisabled || moveButtonClicked}
                            >
                                {t("ORDERS.MOVE.TITLE")}
                            </Button>

                            <Popconfirm
                                title={t("ARE_YOU_SURE")}
                                onConfirm={() => store.clearCart()}
                                placement="top"
                                okText={t("YES")}
                                cancelText={t("NO")}
                            >
                                <Button type="primary" danger>
                                    {t("CART.CLEAR")}
                                </Button>
                            </Popconfirm>
                        </Row>
                    </Col>
                </Row>
                <Form form={form} component={false}>
                    <Table
                        rowKey={"id"}
                        components={vt}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        dataSource={[...store.tableData]}
                        loading={store.loading}
                        scroll={{ x: true, y }}
                        pagination={false}
                    />
                </Form>
            </Space>
            <Row justify={"center"}>
                <Button onClick={() => setModalShown(true)}>{t("CART.ADD_ROW")}</Button>
            </Row>
            {modalShown ? (
                <OrdersArticleModal
                    searchArticle={store.searchArticle}
                    onClose={() => setModalShown(false)}
                    onSubmit={store.addToCart}
                />
            ) : null}
        </>
    );
});

//EST00078
