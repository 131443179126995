import {FieldRuleName} from "./filter/rule/smart-table.filter.rule";
import React from "react";
import {StringWidget} from "./filter/dropdown/widget/string.widget";
import {IntegerWidget} from "./filter/dropdown/widget/integer.widget";
import {DateRangeWidget} from "./filter/dropdown/widget/date-range.widget";
import {SmartColumn} from "./smart-table.model";
import Date from "../../date/date";
import {Link} from "react-router-dom";
import {moneyFormat, numberFormat} from "../../../utils/money";
import {YesNo} from "../yes-no/yes-no";
import {BooleanWidget} from "./filter/dropdown/widget/boolean.widget";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {displayPrice} from "../../../models/prices/price.helper";
import {getPropertyByPath} from "../../../utils/helpers";
import {WidgetProps} from "./filter/dropdown/widget/widget";

export enum FieldType {
    OBJECT = "object",
    STRING = "string",
    STRING_ARRAY = "string_array",
    INTEGER = "number",
    INTEGER_ARRAY = "number_array",
    DATETIME = "datetime",
    ENUM = "enum",
    BOOLEAN = "bool",
    AMOUNT = "amount",
    CUSTOM = "custom",
    CUSTOM_ARRAY = "custom_array"
}

export interface FieldConfig {
    widget: React.FC<WidgetProps>
    rules: FieldRuleName[]
    normalize: (value: string[]) => string[] | string;
    denormalize: (value: string[] | string) => string[]
    render: (value: any, values: Record<string, any>, column: SmartColumn<any>) => React.ReactElement
}

export const configMap: Record<FieldType, FieldConfig> = {
    [FieldType.OBJECT]: {
        widget: StringWidget,
        rules: [
            FieldRuleName.LIKE,
            FieldRuleName.EQ,
            FieldRuleName.NEQ
        ],
        normalize: v => v[0] || "",
        denormalize: v => [v as string],
        render: (v, vv, f) => <Link to={"/" + f.options?.t + "/" + getPropertyByPath(vv, f.options?.f)}>{v}</Link>
    },
    [FieldType.STRING]: {
        widget: StringWidget,
        rules: [
            FieldRuleName.LIKE,
            FieldRuleName.EQ,
            FieldRuleName.NEQ
        ],
        normalize: v => v[0] || "",
        denormalize: v => [v as string],
        render: v => <>{v}</>
    },
    [FieldType.STRING_ARRAY]: {
        widget: StringWidget,
        rules: [
            FieldRuleName.LIKE,
            FieldRuleName.EQ,
            FieldRuleName.NEQ
        ],
        normalize: v => v,
        denormalize: v => {
            return Array.isArray(v) ? v : [v]
        },
        render: v => <>{v.join(", ")}</>

    },
    [FieldType.ENUM]: {
        widget: StringWidget,
        rules: [
            FieldRuleName.EQ,
            FieldRuleName.NEQ
        ],
        normalize: v => v[0] || "",
        denormalize: v => [v as string],
        render: v => <>{v}</>,
    },
    [FieldType.INTEGER]: {
        widget: IntegerWidget,
        rules: [
            FieldRuleName.LIKE,
            FieldRuleName.EQ,
            FieldRuleName.NEQ,
            FieldRuleName.GT,
            FieldRuleName.GTE,
            FieldRuleName.LT,
            FieldRuleName.LTE
        ],
        normalize: v => v[0] || "",
        denormalize: v => [v as string],
        render: (v, vv, f) => {
            if (!v) {
                v = 0;
            }
            switch (f.options?.format) {
                case 'money':
                    return <>{moneyFormat(v)}</>
                case 'percentage':
                    if (f.options?.upDownArrows) {
                        return <>
                            {v > 0 ? (
                                <FontAwesomeIcon icon={["fas", "arrow-up"]} color="#51C41A" />
                            ) : v < 0 ? (
                                <FontAwesomeIcon icon={["fas", "arrow-down"]} color="#FF4D4E" />
                            ) : null}{" "}
                            {v}%
                        </>
                    }

                    return <>{v}%</>
                default:
                    return <>{numberFormat(v)}</>
            }
        }
    },
    [FieldType.INTEGER_ARRAY]: {
        widget: IntegerWidget,
        rules: [
            FieldRuleName.LIKE,
            FieldRuleName.EQ,
            FieldRuleName.NEQ,
            FieldRuleName.GT,
            FieldRuleName.GTE,
            FieldRuleName.LT,
            FieldRuleName.LTE
        ],
        normalize: v => v,
        denormalize: v => {
            return Array.isArray(v) ? v : [v]
        },
        render: v => <>{v.join(", ")}</>
    },
    [FieldType.DATETIME]: {
        widget: DateRangeWidget,
        rules: [],
        normalize: v => v[0] || "",
        denormalize: v => [v as string],
        render: (v, vv, f) => <Date dateString={v} showTime={!f.options?.withoutTime} />
    },
    [FieldType.BOOLEAN]: {
        widget: BooleanWidget,
        rules: [],
        normalize: v => v[0] || '',
        denormalize: v => [v ? '1' : '0'],
        render: v => <YesNo value={v as boolean} />
    },
    [FieldType.AMOUNT]: {
        widget: IntegerWidget,
        rules: [
            FieldRuleName.LIKE,
            FieldRuleName.EQ,
            FieldRuleName.NEQ,
            FieldRuleName.GT,
            FieldRuleName.GTE,
            FieldRuleName.LT,
            FieldRuleName.LTE
        ],
        normalize: v => v[0] || "",
        denormalize: v => [v as string],
        render: (v, vv, f) => {
            return <>{displayPrice(v)}</>
        }
    },
    [FieldType.CUSTOM]: {
        widget: StringWidget,
        rules: [],
        normalize: v => v[0] || "",
        denormalize: v => [v as string],
        render: v => <>{v}</>
    },
    [FieldType.CUSTOM_ARRAY]: {
        widget: StringWidget,
        rules: [],
        normalize: v => v,
        denormalize: v => {
            return Array.isArray(v) ? v : v.split(',')
        },
        render: v => <>{Array.isArray(v) ? v.join(', ') : v}</>
    },
}