import {AxiosInstance} from "axios";
import API from "../../utils/api";

export class ForgotPasswordService {
    private api: AxiosInstance = API;

    public sendEmail(values: {username: string}) {
        return this.api.post("forgot-password", values)
    }

    public checkIfCompleted(values: {token: string}) {
        return this.api.post("forgot-password/complete", values);
    }
}

export const forgotPasswordService = new ForgotPasswordService();