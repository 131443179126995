import React from "react";
import { observer } from "mobx-react";
import { Alert, Checkbox } from "antd";
import { useRequiredStore } from "../../../../../utils/store";
import { OrdersCreateStoreContext } from "../orders.create.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {createVisiblePrice} from "../../../../../models/prices/price.helper";

export const OrderCreateAdditionalPercentAlert: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(OrdersCreateStoreContext);

    if (store.cart?.minimumOrderAmount === 0) {
        return null;
    }

    return (
        <Alert
            showIcon={true}
            type="warning"
            message={t("ORDERS.CREATE.MINIMUM_ORDER_AMOUNT.TITLE")}
            description={
                <>
                    {t("ORDERS.CREATE.MINIMUM_ORDER_AMOUNT.DESCRIPTION", {
                        "minAmount": createVisiblePrice({value: store.cart!.minimumOrderAmount, currencyCode: store.cart!.total.currencyCode}),
                        "percent": store.cart?.minimumOrderAdditionalPercent
                    })}
                </>
            }
            // showIcon
        />
    );
});
