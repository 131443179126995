import {Translate} from "../../../../hooks/translate.hook";
import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {Order, OrderAccount, OrderShippingType} from "../../../../models/orders/orders-list/order";
import {Observer} from "mobx-react";
import {Divider, List, Popover, Space, Tag} from "antd";
import {Link} from "react-router-dom";
import {OrderListWarningIcon} from "../warning-icon/order.list.warning.icon";
import {Document} from "../../../../models/documents/document";
import {Warehouse} from "../../../../models/warehouses/warehouse";
import {OrderStatus} from "../../../../stores/common/common.store";
import {OrdersStatus} from "../../status/order.status";
import {Amount} from "../../../../models/prices/price";
import {displayPrice} from "../../../../models/prices/price.helper";
import Date from "../../../date/date";
import {OrdersListStore} from "./orders.list.store";
import {Comment} from "@ant-design/compatible";

export const columnsFactory = (store: OrdersListStore, ref: any, t: Translate): ColumnsType<Order> => {
    return [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "100px",
            render: (id: number, order) => (
                <Observer>
                    {() => (
                        <Space direction="horizontal" align={"start"}>
                            <Link to={"/orders/" + id}>{id}</Link>
                            {order.hasComments ? (
                                <Popover trigger={"click"}
                                         visible={store.commentsModalShown && store.commentOrderId === order.id}
                                         content={
                                             store.comments === null ? null : (
                                                 <Space style={{ maxWidth: 300 }} direction="vertical" size={[0, 0]}>
                                                     <h3>Comments</h3>
                                                     <List
                                                         className="comment-list"
                                                         itemLayout="horizontal"
                                                         dataSource={store.comments}
                                                         renderItem={item => (
                                                             <li>
                                                                 <Comment
                                                                     className={"modal-comment"}
                                                                     author={item.user.firstName + ' ' + item.user.lastName}
                                                                     content={item.message}
                                                                     datetime={item.createdAt}
                                                                 />
                                                             </li>
                                                         )}
                                                     />
                                                 </Space>
                                             )
                                         }>
                                    <OrderListWarningIcon
                                        fill="#19f"
                                        style={{ marginTop: 2, cursor: "pointer" }}
                                        onClick={() => store.openCommentsModal(order.id)}
                                    />
                                </Popover>
                            ) : null}
                        </Space>
                    )}
                </Observer>
            ),
        },
        {
            title: t("PROFORMAS.TITLE"),
            dataIndex: "documents",
            key: "documents",
            render: (documents: { proforma?: Document[] }, row) => {
                return Array.isArray(documents) || !documents.proforma || !Array.isArray(documents.proforma)
                    ? null
                    : documents?.proforma?.map((doc, index) => (
                        <span key={doc.id}>
                              <Link to={"/proformas/" + doc.id}>{doc.docNumber}</Link>
                            {index !== documents!.proforma!.length - 1 ? ", " : ""}
                          </span>
                    ));
            },
        },
        {
            title: t("CLIENT.NAME"),
            dataIndex: "account",
            key: "account",
            width: "255px",
            render: ({ hasAgreements, id }: OrderAccount, order) => (
                <Observer>
                    {() => (
                        <Space style={{ whiteSpace: "nowrap", width: "auto" }} direction="horizontal" size={[5, 5]}>
                            <Link to={"/clients/" + order.agent.number}>{order.agent.name}</Link>
                            {hasAgreements ? (
                                <div id={"popover-wrapper"} ref={ref as any}>
                                    <Popover
                                        // getPopupContainer={() => ref.current as any}
                                        content={
                                            store.agreements === null ? null : (
                                                <Space style={{ maxWidth: 300 }} direction="vertical" size={[0, 0]}>
                                                    {store.agreements.map((item, index) => {
                                                        return (
                                                            <Space key={item.id} direction="vertical">
                                                                <Tag>
                                                                    {item.category
                                                                        ? t("AGREEMENT." + item.category.toUpperCase())
                                                                        : ""}
                                                                </Tag>
                                                                <span>{item.content}</span>
                                                                {store.agreements?.length! > 1 &&
                                                                index !== store.agreements?.length! - 1 ? (
                                                                    <Divider
                                                                        style={{ margin: "0px 0 12px 0" }}
                                                                        type={"horizontal"}
                                                                    />
                                                                ) : null}
                                                            </Space>
                                                        );
                                                    })}
                                                </Space>
                                            )
                                        }
                                        trigger="click"
                                        visible={store.agreementsModalShown && store.agreementsAccountId === order.id}
                                    >
                                        <OrderListWarningIcon
                                            fill="#fc3"
                                            style={{ marginTop: 4, cursor: "pointer" }}
                                            onClick={() => store.openAgreementsModal(id, order.id)}
                                        />
                                    </Popover>
                                </div>
                            ) : null}
                        </Space>
                    )}
                </Observer>
            ),
        },
        {
            title: t("WAREHOUSES.WAREHOUSE_TITLE"),
            dataIndex: "warehouse",
            key: "warehouse",
            render: (item: Warehouse) => <>{item.name}</>,
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            width: "125px",
            render: (item: OrderStatus, row) =>
                row.warnings ? (
                    <Space direction="horizontal" align={"start"}>
                        <OrdersStatus status={item} />
                        <OrderListWarningIcon
                            fill="#d33"
                            style={{ marginTop: 2, cursor: "pointer" }}
                            onClick={() => store.openWarningsModal(row.id)}
                        />
                    </Space>
                ) : (
                    <OrdersStatus status={item} />
                ),
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "grandTotal",
            key: "grandTotal",
            render: (item: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(item)}</span>,
        },
        {
            title: t("CLIENT.NUMBER"),
            dataIndex: "clientOrderId",
            key: "clientOrderId",
            width: "225px",
        },
        {
            title: t("SHIPPING.TYPE"),
            dataIndex: "shipmentType",
            key: "shipmentType",
            render: (t: OrderShippingType | null) => t?.courierName || "-"
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            width: 150,
            render: (item: string) => <Date dateString={item} showTime />,
        }
    ]
}