import React, {useEffect, useState} from "react";
import { observer } from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { AxiosError } from "axios";
import { ErrorResponseData } from "../../../../models/core/response";
import { Button, Checkbox, Col, Form, Input, Row, Select, Spin } from "antd";
import { currencies } from "../../../../models/prices/price.helper";
import { useRequiredStore } from "../../../../utils/store";
import { RootStoreContext } from "../../../../stores/root/root.store";
import { BranchDataStoreContext } from "../branch.data.store";
import {branchesService} from "../../../../services/branches/branches.service";
import {PriceList} from "../../../../models/price-lists/price.lists";

export const BranchDataMain: React.FC = observer(() => {
    const t = useTranslate();
    const {
        langStore: { countries },
    } = useRequiredStore(RootStoreContext);
    const store = useRequiredStore(BranchDataStoreContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [priceLists, setPriceLists] = useState<PriceList[]>([]);


    const [form] = Form.useForm();

    const defaultValues = {
        ...store.branch,
        currencyCode: store.branch?.currencyCode.toUpperCase(),
        countryCode: countries.find((item) => item.id === store.branch?.countryCode)?.id,
        rrpPrice : store.branch?.rrpPrice?.id,
        purchasePrice : store.branch?.purchasePrice?.id,
        // taxStrategy: store.branch?.taxStrategy || "each_item",
        // allowProformaCreate: store.branch?.allowProformaCreate || false,
        // allowMultipleDocuments: store.branch?.allowMultipleDocuments || false,
    };

    useEffect(() => {
        branchesService.getAllPrices(store.branch!.id).then(prices => setPriceLists(prices));
    }, [store.branch!.id])

    if (store.loading || countries.length === 0) {
        return <Spin />;
    }

    return (
        <Form
            form={form}
            layout="vertical"
            name="edit_branch_form"
            initialValues={defaultValues}
            onFinish={() =>
                form.validateFields().then((values) => {
                    setLoading(true);
                    store
                        .editBranch(values)
                        .then(
                            () => {
                                return store.closeEditModal();
                            },
                            (e: AxiosError<ErrorResponseData>) => {
                                setLoading(false);
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            }
                        )
                        .then(() => (e: any) => form.setFields(e.response?.data?.fields))
                        .finally(() => setLoading(false));
                })
            }
        >
            <Row gutter={[10, 0]} align={"middle"}>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item name="id" label={t("ID")}>
                        <Input disabled type="text" />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="name"
                        label={t("NAME")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input disabled={loading} type="text" />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="icCode"
                        label={t("1C.CODE")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input disabled={loading} type="text" />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="icPriceCalculationMethod"
                        label={t("1C.PRICE_CALCULATION_METHOD")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp={"children"}
                            placeholder={t("COUNTRY")}
                            disabled={loading}
                        >
                            {["calculate", "custom"].map((method) => (
                                <Select.Option value={method} key={method}>
                                    {method.beautify()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="currencyCode"
                        label={t("CURRENCY")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp={"children"}
                            placeholder={t("CURRENCY")}
                            disabled={loading}
                        >
                            {Object.keys(currencies).map((currency) => (
                                <Select.Option value={currency} key={currency}>
                                    {currency.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="availableCurrencyCodes"
                        label={t("AVAILABLE_CURRENCY_CODES")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp={"children"}
                            placeholder={t("CURRENCY")}
                            disabled={loading}
                            mode={"multiple"}
                        >
                            {Object.keys(currencies).map((currency) => (
                                <Select.Option value={currency} key={currency}>
                                    {currency.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="customsCode"
                        label={"EORI"}
                    >
                        <Input type="text" placeholder={"EORI"} disabled={loading} />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="phone"
                        label={t("PHONE")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input disabled={loading} type="tel" />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="email"
                        label={t("EMAIL")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input disabled={loading} type="email" />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="street"
                        label={t("STREET")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input type="text" placeholder={t("STREET")} disabled={loading} />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="city"
                        label={t("CITY")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input type="text" placeholder={t("CITY")} disabled={loading} />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="zip"
                        label={t("ZIP_CODE")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input type="text" placeholder={t("ZIP_CODE")} disabled={loading} />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="countryCode"
                        label={t("COUNTRY")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp={"children"}
                            placeholder={t("COUNTRY")}
                            loading={loading}
                        >
                            {countries.map((country) => (
                                <Select.Option value={country.id!} key={country.id!}>
                                    {country.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="regNumber"
                        label={t("REG_NUMBER")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input type="text" placeholder={t("REG_NUMBER")} disabled={loading} />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item label={t("BRANCH.ALLOW_PROFORMA_CREATE")} name="allowProformaCreate">
                        <Select placeholder={t("BRANCH.ALLOW_PROFORMA_CREATE")} disabled={loading}>
                            <Select.Option value={true as any}>{t("ALLOW")}</Select.Option>
                            <Select.Option value={false as any}>{t("NOT_ALLOW")}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item label={t("BRANCH.ALLOW_MULTIPLE_DOCUMENTS")} name="allowMultipleDocuments">
                        <Select placeholder={t("BRANCH.ALLOW_MULTIPLE_DOCUMENTS")} disabled={loading}>
                            <Select.Option value={true as any}>{t("ALLOW")}</Select.Option>
                            <Select.Option value={false as any}>{t("NOT_ALLOW")}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item label={t("BRANCH.ALLOW_USE_CONTAINERS")} name="allowUseContainers">
                        <Select placeholder={t("BRANCH.ALLOW_USE_CONTAINERS")} disabled={loading}>
                            <Select.Option value={true as any}>{t("ALLOW")}</Select.Option>
                            <Select.Option value={false as any}>{t("NOT_ALLOW")}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item label={t("BRANCH.ALLOW_USE_BACKORDER")} name="allowUseBackorder">
                        <Select placeholder={t("BRANCH.ALLOW_USE_BACKORDER")} disabled={loading}>
                            <Select.Option value={true as any}>{t("ALLOW")}</Select.Option>
                            <Select.Option value={false as any}>{t("NOT_ALLOW")}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item label={t("BRANCH.TAX_STRATEGY.TITLE")} name="taxStrategy">
                        <Select placeholder={t("BRANCH.TAX_STRATEGY.TITLE")} disabled={loading}>
                            <Select.Option value="each_item">{t("BRANCH.TAX_STRATEGY.EACH")}</Select.Option>
                            <Select.Option value="summary">{t("BRANCH.TAX_STRATEGY.SUMMARY")}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item label={t("BRANCH.PRICE.RRP_PRICE.TITLE")} name="rrpPrice">
                        <Select placeholder={t("BRANCH.PRICE.RRP_PRICE.TITLE")} disabled={loading}>
                            {priceLists.map(price => (
                                <Select.Option value={price.id}>{price.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item label={t("BRANCH.PRICE.PURCHASE_PRICE.TITLE")} name="purchasePrice">
                        <Select placeholder={t("BRANCH.PRICE.PURCHASE_PRICE.TITLE")} disabled={loading}>
                            {priceLists.map(price => (
                                <Select.Option value={price.id}>{price.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="minimumOrderAmount"
                        label={t("ORDER.MINIMUM_AMOUNT")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input type="text" placeholder={t("REG_NUMBER")} disabled={loading} />
                    </Form.Item>
                </Col>
                <Col xxl={6} xl={6} md={8} sm={12} xs={24}>
                    <Form.Item
                        name="minimumOrderAdditionalPercent"
                        label={t("ORDER.MINIMUM_ADDITIONAL_PERCENT")}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                            },
                        ]}
                    >
                        <Input type="text" placeholder={t("REG_NUMBER")} disabled={loading} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Button type="primary" htmlType="submit">
                        {t("SAVE")}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
});
