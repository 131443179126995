import React, {useEffect} from "react";
import {Select} from "antd";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {observer} from "mobx-react";
import {Warehouse} from "../../../../models/warehouses/warehouse";

interface ArticleSearchProps {
    value?: number;
    onChange?: (value: number) => void
    onListUpdated?: (warehouses: Warehouse[]) => void,
    autoFocus?: boolean;
    selectStyle?: React.CSSProperties;
    selectMode?: 'multiple' | 'tags';
    maxTagCount?: number;
    allowedOnly?: string[];
}

export const WarehouseSelect: React.FC<ArticleSearchProps> = observer(({allowedOnly, value, onChange, autoFocus, selectStyle, selectMode, onListUpdated, maxTagCount=1}) => {

    let {
        commonStore: { warehouses }
    } = useRequiredStore(RootStoreContext);

    if (allowedOnly) {
        warehouses = warehouses.filter(w => allowedOnly.includes(w.id));
    }

    useEffect(() => {
        onListUpdated && onListUpdated(warehouses);
    }, [warehouses])

    return <Select
        mode={selectMode}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        style={selectStyle}
        maxTagCount={maxTagCount}
    >
        {warehouses.map((warehouse => {
            return <Select.Option key={warehouse.id} value={warehouse.id}>{warehouse.name}</Select.Option>
        }))}
    </Select>
})