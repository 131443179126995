import React, {ReactElement, useEffect, useMemo, useState} from "react";
import {CardsViewDataHandler} from "./cards-view.data-handler";
import {Pagination, Space} from "antd";
import {Pagination as PR} from "../../../models/core/paginated.result";
import {CartViewFilter} from "./model/model";
import {CardsViewFilters} from "./cards-view.filters";
import {useURLParams} from "../../../hooks/url-params.hook";
import {typesConfig} from "../../planner/ui/cards-view/filter/widget/task/task-type/task.type.widget";

interface CardsViewProps<T> {
    handler: CardsViewDataHandler<T>;
    render: (item: T) => ReactElement;
    filters: CartViewFilter[];
    initialFilters: Record<string, string | string[]>
}

export const CardsView: React.FC<CardsViewProps<any>> = ({handler, render, filters, initialFilters}) => {

    const [items, setItems] = useState<any[]>([]);
    const [pagination, setPagination] = useState<PR>();
    const [ready, setReady] = useState<boolean>(false);

    const {urlParams, setURLParams} = useURLParams();

    const onFiltersChange = (values: Record<string, string[]>) => {
        handler.fromValues(values)
        setURLParams(handler.toValues());
    }

    const onPageChange = (page: number, limit: number) => {
        handler.setPageAndLimit(page, limit)
        setURLParams(handler.toValues());
    }

    useEffect(() => {
        setReady(true)

        if (Object.keys(urlParams).length === 0) {
            setURLParams({
                status: typesConfig[0].values
            });
            return
        }

        handler.fromValues(urlParams)
        handler.reloadItemsWithLastRequest()
    }, [handler, urlParams]);

    useEffect(() => {
        handler.setOnResult(result => {
            setItems(result.items);
            setPagination(result.meta)
        })
    }, [handler, setItems, setPagination])

    if (!ready) {
        return null;
    }

    return <Space direction={"vertical"} size={[20, 20]}>
        <CardsViewFilters
            filters={filters}
            onChange={onFiltersChange}
            initialValues={urlParams as any}
        />
        <Space direction={"vertical"}>
            {items.map((item, key) => <div key={key}>{render(item)}</div>)}
        </Space>
        <Pagination
            current={pagination?.page}
            total={pagination?.total}
            pageSize={pagination?.limit}
            showSizeChanger
            onChange={onPageChange}
        />
    </Space>
}