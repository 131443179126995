import { AxiosInstance } from "axios";
import API from "../../../utils/api";
import {
    OrdersCheckoutShipping,
    OrdersCheckoutShippingQuery,
} from "../../../models/orders/orders-cart/orders-checkout/orders.checkout.shipping";
import { OrdersCheckoutRequest } from "../../../models/orders/orders-cart/orders-checkout/orders.checkout.request";
import { NotCompletedOrder } from "../../../models/orders/orders-cart/orders-checkout/orders.checkout.not_completed.order";

export class OrdersCheckoutService {
    private api: AxiosInstance = API;

    getNotCompletedOrders(id: number, billingData?: { [key: string]: any }): Promise<NotCompletedOrder[]> {
        return this.api
            .get<NotCompletedOrder[]>("accounts/" + id + "/orders/not-completed", {
                params: { ...billingData },
            })
            .then((x) => x.data);
    }

    getCalculatedNotCompletedOrder(id: number, billingData?: { [key: string]: any }): Promise<NotCompletedOrder> {
        return this.api
            .get<NotCompletedOrder>("orders/not-completed/" + id, {
                params: { ...billingData },
            })
            .then((x) => x.data);
    }

    getShipping(orderId: number, query: OrdersCheckoutShippingQuery): Promise<OrdersCheckoutShipping[]> {
        return this.api
            .get<OrdersCheckoutShipping[]>("shipping/order/" + orderId + "/prices", {
                params: query,
            })
            .then((x) => x.data);
    }

    completeOrder(orderId: number, request: OrdersCheckoutRequest): Promise<void> {
        return this.api.post("orders/not-completed/" + orderId, request).then((x) => x.data);
    }

    cancelOrder(orderId: number, params?: { move: "1" | "0" }): Promise<void> {
        return this.api.delete("orders/" + orderId, { params }).then((x) => x.data);
    }
}

export const ordersCheckoutService = new OrdersCheckoutService();
