import React, {useMemo} from "react";
import { Space, Tabs } from "antd";
import { RouteConfig } from "../../../routers/routers";
import { useLocation, useParams } from "react-router-dom";
import { ExtendedSwitch } from "../../core/router/extended-switch/extended.switch";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import {isAllowed} from "../../../utils/helpers";
import {useTranslate} from "../../../hooks/translate.hook";
import {useNavigate} from "react-router";

export interface RouteTabsProps {
    customSpliceNum?: number;
    withoutRootTabPath?: boolean;
    routes: RouteConfig[];
}

const bindPathParams = (path: string, params: Record<string, any>) => {
    for (let key in params) {
        path = path.replace(":" + key, params[key]);
    }

    return path;
};

export const RouteTabs = <T extends object>({
    routes,
    withoutRootTabPath = false,
    customSpliceNum = 4,
}: RouteTabsProps) => {

    const t = useTranslate();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const params = useParams();
    const rootTabPath = withoutRootTabPath ? pathname : pathname.split("/").slice(0, customSpliceNum).join("/");

    const {
        authStore: { attributes, role },
    } = useRequiredStore(RootStoreContext);

    const tabs = useMemo(() => routes.filter(route => isAllowed(attributes, route.access) && !route.hidden && (!route.role || route.role === role)), []);

    return (
        <Space direction={"vertical"} size={[20, 20]}>
            {tabs.length > 1 ? (
                <Tabs
                    activeKey={rootTabPath}
                    onTabClick={(key) => navigate(key)}
                    tabPosition={"top"}
                >
                    {tabs.map((route) => {
                        return (
                            <Tabs.TabPane tab={t(route.systemName)} key={bindPathParams(route.path, params)} />
                        );
                    })}
                </Tabs>
            ) : null}
            <ExtendedSwitch />
        </Space>
    );
};
