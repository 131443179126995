import React from "react";
import { Alert } from "antd";
import { Typography } from 'antd';

const { Paragraph } = Typography;

const alertMap: {[K: string]: { title: string, type: "info" | "warning" | "error" }} = {
    'hint tip':   {title: 'Informational Notes', type: 'info'},
    'hint warn':  {title: 'Warning', type: 'warning'},
    'hint error': {title: 'Danger', type: 'error'}
}

export const RenderParagraph: React.FC<any> = ({className, children, ...props}) => {

    const alert = alertMap[className]
    if (alert) {
        return <Alert
            message={alert.title}
            description={children}
            type={alert.type}
            showIcon
        />
    }

    return <Paragraph>{children}</Paragraph>
}