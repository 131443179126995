import {BackorderItem} from "../../models/backorder/backorder.item";
import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";

export class BackorderService {
    private api: AxiosInstance = API;

    public async getList(accountId: number, request: PaginatedRequest): Promise<PaginatedResult<BackorderItem>> {
        return (await this.api.get<PaginatedResult<BackorderItem>>(
            "accounts/" + accountId + "/backorder",
            {
                params: request.toParams()
            }
        )).data;
    }
}

export const backorderService = new BackorderService();

