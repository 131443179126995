import API from "../../utils/api";
import { AxiosInstance } from "axios";
import { DepartmentsRequestValues } from "../../models/departments/departments.request.values";
import { Department, DepartmentExpanded } from "../../models/departments/department";
import { DepartmentRole } from "../../models/departments/roles";
import {PlanerTag} from "../../models/departments/planer_tags";

export class DepartmentsService {
    api: AxiosInstance = API;

    getAll(): Promise<Department[]> {
        return this.api.get<Department[]>("departments/all").then((x) => x.data);
    }

    getBranchDepartments(branch: string): Promise<Department[]> {
        return this.api.get<Department[]>("branches/" + branch + "/departments").then((x) => x.data);
    }

    getDepartment(branchId: string | number, id: number | string): Promise<DepartmentExpanded> {
        return this.api.get<DepartmentExpanded>("branches/" + branchId + "/departments/" + id).then((x) => x.data);
    }

    createDepartment(values: DepartmentsRequestValues): Promise<Department> {
        return this.api.post<Department>("departments", values).then((x) => x.data);
    }

    updateDepartment(id: number, values: DepartmentsRequestValues): Promise<DepartmentExpanded> {
        return this.api.put<DepartmentExpanded>("departments/" + id, values).then((x) => x.data);
    }

    removeDepartment(id: number): Promise<void> {
        return this.api.delete("departments/" + id);
    }

    getAllRoles(): Promise<DepartmentRole[]> {
        return this.api.get<DepartmentRole[]>("departments/roles/all").then((x) => x.data);
    }

    getDepartmentRoles(id: number): Promise<DepartmentRole[]> {
        return this.api.get<DepartmentRole[]>("departments/" + id + "/roles").then((x) => x.data);
    }

    createDepartmentRole(id: number): Promise<DepartmentRole> {
        return this.api.post<DepartmentRole>("departments/" + id + "/roles").then((x) => x.data);
    }

    updateDepartmentRole(id: number): Promise<DepartmentRole> {
        return this.api.put<DepartmentRole>("departments/roles/" + id).then((x) => x.data);
    }

    removeDepartmentRole(id: number): Promise<DepartmentRole> {
        return this.api.delete<DepartmentRole>("departments/roles/" + id).then((x) => x.data);
    }
}

export const departmentsService = new DepartmentsService();
