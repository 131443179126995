import React, {useState} from "react";
import {useTranslate} from "../../../hooks/translate.hook";
import {Col, Form, Modal, Row, Upload} from "antd";
import {WarehouseSelect} from "../../warehouses/common/warehouse-select/warehouse-select";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../models/core/response";
import {containersService} from "../../../services/containers/containers.service";

interface ContainersImportModalProps {
    onClose: () => void,
    onCompleted: () => void
}

export const ContainersImportModal: React.FC<ContainersImportModalProps> = ({onClose, onCompleted}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("CONTAINERS.CONTAINER.UPLOPAD.MODAL.TITLE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        containersService.import(values.warehouse, values.file)
                            .then(
                                () => {
                                    onClose();
                                    onCompleted();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(
                                            e.response?.data?.fields
                                        );
                                    }
                                }
                            )
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>

                        <Form.Item
                            label={t("CONTAINERS.UPLOAD.FILE")}
                            name="file"
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                            valuePropName={""}
                            getValueFromEvent={(e) => e.file || null}
                        >
                            <Upload.Dragger
                                beforeUpload={() => false}
                                accept={".csv,.txt"}
                                maxCount={1}
                                disabled={loading}
                            >
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Required fields: #;Дата;Артикул;Количество<br />
                                    Example row: EE-851;31.07.21;NAB-S51S;80
                                </p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="warehouse"
                            label={t("WAREHOUSES.WAREHOUSE_TITLE")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <WarehouseSelect />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
