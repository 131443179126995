import {Translate} from "../../../../hooks/translate.hook";
import {ColumnsType, ColumnType} from "antd/lib/table/interface";
import React from "react";
import {Warehouse} from "../../../../models/warehouses/warehouse";
import {Link} from "react-router-dom";

export const columnsFactory = (t: Translate, warehouses: Warehouse[], allowedWarehouses: string[]): ColumnsType<any> => {

    const columns: ColumnsType<any> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("CODE"),
            dataIndex: "name",
            key: "name",
            render: (value: string) => value.toUpperCase() !== "DELIVERY" ? (
                <Link
                    style={{ whiteSpace: "nowrap" }}
                    to={"/articles/" + value}
                >
                    {value}
                </Link>
            ) : value,
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "description",
        },
    ];

    warehouses
        .filter(w =>allowedWarehouses.length === 0 || -1 !== allowedWarehouses.indexOf(w.id))
        .forEach(({ id, name }) => {
            const column: ColumnType<any> = {
                title: name,
                dataIndex: id,
                key: id,
                render: (_, row: any) => {
                    return <>{row.stock[id] ?? 0}</>
                },
            };

            columns.push(column);
    })

    return columns;
}