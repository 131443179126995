import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {PaginatedResult} from "../../models/core/paginated.result";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {DeclarationsDocumentCodeFile} from "../../models/declarations/declarations.document-code-files";

export class DeclarationsDocumentCodeFilesService {
    api: AxiosInstance = API;

    getAll(request: PaginatedRequest): Promise<PaginatedResult<DeclarationsDocumentCodeFile>> {
        return this.api.get<PaginatedResult<DeclarationsDocumentCodeFile>>(
            'declarations/document-code-files',
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }

    create(data: any): Promise<DeclarationsDocumentCodeFile> {
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('documentCode', data.documentCode);

        return this.api
            .post("declarations/document-code-files", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(x => x.data);
    }

    remove(id: number): Promise<any> {
        return this.api.delete<any>('declarations/document-code-files/' + id).then(x => x.data);
    }
}

export const declarationsDocumentCodeFilesService = new DeclarationsDocumentCodeFilesService();
