import React, {useState} from "react";
import {Button, Form, Upload} from "antd";
import {EditableParcel} from "../../../../../models/parcels/shipment";

export const RenderUploadLabelField: React.FC<{parcel: EditableParcel, index: number}> = ({index}) => {

    const [uploaded, setUploaded] = useState(false);

    return <Form.Item
        key={index}
        name={["parcels", index.toString(), 'label']}
        rules={[{ required: true, message: 'Label is required' }]}
        valuePropName={""}
        getValueFromEvent={(e) => e.file || null}
        shouldUpdate={() => true}
    >
        <Upload
            beforeUpload={() => {
                setUploaded(true);
                return false;
            }}
            accept={".pdf"}
            maxCount={1}
            showUploadList={false}
        >
            <Button type={uploaded ? "primary" : "default"}>Upload</Button>
        </Upload>
    </Form.Item>
}