import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Space, Table} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {branchesService} from "../../../../services/branches/branches.service";
import {ShippingCourier} from "../../../../models/shipping/shipping";
import {columnsFactory, groupedColumnsFactory} from "./branch.data.couriers.columns";
import {Warehouse} from "../../../../models/warehouses/warehouse";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {BranchDataCouriersTestModal} from "./branch.data.couriers.test.modal";
import {BranchDataCouriersCreateModal} from "./branch.data.couriers.create.modal";
import {downloadBlobFile} from "../../../../utils/blob.download";
import {notifications} from "../../../../services/notifications/notifications";

export interface ShippingCouriersGroup {
    couriers: ShippingCourier[];
    warehouses: Warehouse[];
    key: string;
}

export const BranchDataCouriers: React.FC = () => {
    const {branchId} = useParams<{ branchId: string }>();
    const t = useTranslate();

    const [loading, setLoading] = useState<boolean>(true);
    const [couriers, setCouriers] = useState<ShippingCouriersGroup[]>([]);

    const [testModalShown, setTestModalShown] = useState<boolean>(false);
    const [createModalShown, setCreateModalShown] = useState<boolean>(false);
    const [loadingToXLSX, setLoadingToXLSX] = useState<boolean>(false);

    const loadCouriers = () => {
        branchesService.getShippingCouriers(branchId!).then(couriers => {
            let groups: {[K: string]: ShippingCouriersGroup} = {};
            couriers.map(courier => {
                courier.warehouses.sort((w1, w2) => w1.name > w2.name ? 1 : -1);
                let key = courier.warehouses.map(w => w.id).join('|') || 'all';
                let group = groups[key] || {couriers: [], warehouses: courier.warehouses, key: key};
                group.couriers.push(courier);
                groups[key] = group;
            })

            const values = Object.values(groups);
            values.sort((g1, g2) => g1.key > g2.key ? 1 : -1);

            setCouriers(values);
            setLoading(false);
        })
    }

    const exportToXLSX = () => {
        setLoadingToXLSX(true)
        branchesService.downloadShippingCouriersAsXLSX(branchId!).then(value => {
            setLoadingToXLSX(false)
            downloadBlobFile(value.blob, value.filename)
            notifications.successfully()
        })
    }

    useEffect(() => {
        loadCouriers();
    }, []);

    const columns = useMemo(() => columnsFactory(branchId!, t), []);
    const groupedColumns = useMemo(() => groupedColumnsFactory(t), []);

    if (loading) {
        return <LoadingSpin />;
    }

    return <Space direction="vertical" size={[30, 30]}>

        <Space direction={"horizontal"} style={{justifyContent: "space-between"}}>
            <Button type={"primary"} onClick={() => setTestModalShown(true)}>Check courier selection</Button>
            <Space direction={"horizontal"}>
                <Button type={"default"} onClick={() => exportToXLSX()} loading={loadingToXLSX}>Export to XLSX</Button>
                <Button type={"primary"} onClick={() => setCreateModalShown(true)}>New Courier</Button>
            </Space>
        </Space>

        <Table
            loading={loading}
            rowKey={"key"}
            columns={groupedColumns}
            dataSource={couriers}
            pagination={false}
            defaultExpandAllRows={true}
            expandedRowRender={group =>
                <Table
                    rowKey={"id"}
                    columns={columns}
                    dataSource={group.couriers}
                    pagination={false}
                    onRow={value => {
                        const props: any = {};
                        if (!value.viewStatus) {
                            props.class = "disabled";
                        }
                        return props;
                    }}
                />
            }
        />
        {testModalShown ? (
            <BranchDataCouriersTestModal
                onClose={() => setTestModalShown(false)} />
        ) : null}
        {createModalShown ? (
            <BranchDataCouriersCreateModal
                onClose={() => setCreateModalShown(false)}
                onComplete={() => loadCouriers()} />
        ) : null}
    </Space>
}