import React, {useState} from "react";
import {observer} from "mobx-react";
import {useParams, useLocation} from "react-router-dom";
import {MergedInvoiceDataStore, MergedInvoiceDataStoreContext} from "./merged.invoice.data.store";
import {Space, Spin} from "antd";
import {MergedInvoiceDataHeader} from "./merged-invoice-data-header/merged.invoice.data.header";
import {PayerSellerReceiverData} from "../../../common/payer-seller-receiver-data/payer.seller.receiver.data";
import {MergedInvoiceDataIncludedInvoices} from "./merged-invoice-data-included-invoices/merged.invoice.data.included.invoices";
import {MergedInvoiceDataOrderDetails} from "./merged-invoice-data-order-details/merged.invoice.data.order.details";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {
    MergedInvoiceDataIncludedCreditInvoices
} from "./merged-invoice-data-included-credit-invoices/merged.invoice.data.included.credit-invoices";
import {DocumentShipments} from "../../common/shipments/document.shipments";
import {useNavigate} from "react-router";

export const MergedInvoiceData: React.FC = observer(() => {
    const {mergedInvoiceId} = useParams<{mergedInvoiceId: string}>();
    const {pageStore} = useRequiredStore(RootStoreContext);
    const match = useLocation();
    const navigate = useNavigate();
    const [store] = useState(() => new MergedInvoiceDataStore(mergedInvoiceId!, pageStore, match.pathname, navigate));

    if (store.loading) {
        return (
            <Space direction={"horizontal"} align={"center"} style={{height: "calc(100vh - 90px)", justifyContent: "center"}}>
                <Spin/>
            </Space>
        );
    }

    return (
        <MergedInvoiceDataStoreContext.Provider value={store}>
            <Space direction="vertical" size={[50, 50]}>
                <MergedInvoiceDataHeader/>
                <PayerSellerReceiverData
                    payer={store.invoice!.billingAddress}
                    receiver={store.invoice!.shippingAddress}
                    seller={store.invoice!.sellerAddress}
                    editPayerReceiver={store.editPayerReceiverData}
                    editSeller={store.editSellerData}
                />
                <DocumentShipments id={store.invoice!.id} />
                <MergedInvoiceDataIncludedInvoices/>
                <MergedInvoiceDataIncludedCreditInvoices/>
                <MergedInvoiceDataOrderDetails/>
            </Space>
        </MergedInvoiceDataStoreContext.Provider>
    );
})
