import {PaginatedRequest} from "../../../models/core/paginated.request";
import {PaginatedResult} from "../../../models/core/paginated.result";
import {AxiosInstance} from "axios";
import API from "../../../utils/api";
import {MergedInvoiceData} from "../../../models/documents/invoices/merged-invoice/merged-invoice-data/merged.invoice.data";
import {
    MergedInvoice, SearchedMergedInvoice,
} from "../../../models/documents/invoices/merged-invoice/merged.invoice";
import React from "react";
import {DispatchAwareInterface} from "./dispatch-aware.interface";

export class MergedInvoicesService implements DispatchAwareInterface {
    private api: AxiosInstance = API;

    async searchMergedInvoices(number: string): Promise<SearchedMergedInvoice[]> {
        return (await this.api.get<SearchedMergedInvoice[]>("documents/merged-invoices/search?number=" + number)).data;
    }

    getMergedInvoices(request: PaginatedRequest, defaultFilters?: {[key: string]: string | string[]}): Promise<PaginatedResult<MergedInvoice>> {
        if (defaultFilters) {
            Object.keys(defaultFilters).forEach(key => {
                request.addFilter(key, defaultFilters[key]);
            })
        }
        return this.api.get<PaginatedResult<MergedInvoice>>(
            "documents/merged-invoices",
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }


    getAgentMergedInvoicesByAccount(request: PaginatedRequest, accountId: string): Promise<PaginatedResult<MergedInvoice>> {
        request.addFilter("account", accountId)
        return this.api.get<PaginatedResult<MergedInvoice>>(
            'documents/merged-invoices',
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }


    getMergedInvoice(id: string): Promise<MergedInvoiceData> {
        return this.api.get<MergedInvoiceData>("documents/merged-invoices/" + id).then(x => x.data);
    }

    editInvoice(id: string, values: {number: string, date: string}): Promise<void> {
        return this.api.post<void>("documents/merged-invoices/" + id, values).then(x => x.data);
    }

    createMergedInvoice(values: {number: string, date: string, invoices: number[]}) {
        return this.api.post("documents/merged-invoices", values);
    }

    deleteMergedInvoice(id: number): Promise<void> {
        return this.api.delete("documents/merged-invoices/" + id).then(x => x.data);
    }

    excludeInvoicesFromMergedInvoice(id: string, invoices: number[]): Promise<MergedInvoiceData> {
        return this.api.delete<MergedInvoiceData>("documents/merged-invoices/" + id + "/invoices", {
            params: {invoices}
        }).then(x => x.data);
    }

    addInvoicesToMergedInvoice(id: string, values: {invoices: number[]}): Promise<MergedInvoiceData> {
        return this.api.post<MergedInvoiceData>("documents/merged-invoices/" + id + "/invoices", values.invoices).then(x => x.data);
    }

    confirm(id: string, value: {date: string}): Promise<any> {
        return this.api.post("documents/merged-invoices/" + id + "/confirmation", value);
    }

    unconfirm(id: string): Promise<any> {
        return this.api.delete("documents/merged-invoices/" + id + "/confirmation");
    }

    dispatchInvoices(value: {date: string, invoices: React.Key[]}): Promise<void> {
        return this.api.post("documents/merged-invoices/dispatch", value);
    }

    recalcWeight(id: string, values: {weight: string}): Promise<void> {
        return this.api.post("documents/merged-invoices/" + id + "/recalc-weights", values);
    }

    setDate(id: string, date: string): Promise<void> {
        return this.api.post("documents/merged-invoices/" + id + "/set-date", {date});
    }

    createExportInvoices(values: {weight: number, invoices: number[] | React.Key}): Promise<any> {
        return this.api.post("documents/merged-invoices/export-invoice", values, {responseType: "arraybuffer"}).then(x => ({
            headers: x.headers,
            data: x.data
        }));
    }
}

export const mergedInvoiceService = new MergedInvoicesService();
