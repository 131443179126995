import React, {useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../../../routers/routers";
import {ExtendedSwitch} from "../../../../../core/router/extended-switch/extended.switch";
import {propertyService} from "../../../../../../services/property/property.service";
import {useOutletContext, useParams} from "react-router-dom";
import {ReviewContext} from "../../review.inventory-tasks.model";
import {PropertyInventoryTaskProperty} from "../../../../../../models/property/property";
import {ReviewStepContext} from "./review.step.context";
import {LoadingSpin} from "../../../../../common/loading-spin/loading.spin";
import {useNavigate} from "react-router";
import {PageHeader} from "@ant-design/pro-components";
import {Button, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {EditPropertyModal} from "../../../../property/edit/edit.property.modal";

export const ReviewStep: React.FC<ComponentPropsFromRoute> = () => {

    const t = useTranslate();
    const {code} = useParams<{ code: string }>();
    const baseCtx = useOutletContext<ReviewContext>();
    const [ctx, setCtx] = useState<ReviewStepContext | null>();
    const [property, setProperty] = useState<PropertyInventoryTaskProperty | null>(null);
    const navigate = useNavigate();
    const [editableModalShown, setEditableModalShown] = useState<boolean>(false);

    const onEditableModalClose = () => {
        setEditableModalShown(false);
    }

    const reload = () => {
        propertyService.getTaskProperty(baseCtx.task.id, parseInt(code!)).then(property => setProperty(property));
    }

    useEffect(() => {
        reload();
    }, [code]);

    useEffect(() => {
        if (property) {
            setCtx({
                property: property,
                task: baseCtx.task,
                backToStart: baseCtx.backToStart
            })

            if (property.property.requiredPhotoConfirmation) {
                navigate('/property/review/inventory-task/' + baseCtx.task.id +'/review/' + code +'/take-photo');
            } else {
                navigate('/property/review/inventory-task/' + baseCtx.task.id +'/review/' + code +'/status');
            }
        }
    }, [property])

    if (!ctx || !property) {
        return (
            <LoadingSpin />
        )
    }

    return (
        <Space direction={"vertical"} className={"inventory-task-review"}>
            <PageHeader
                style={{ padding: 0 }}
                ghost={false}
                title={property.code + " (" + (property.destroyedQty + property.confirmedQty + property.lostQty) + " / " + property.qty + " tk.)"}
                footer={<>
                    {property.type.name} / {property.description}<br />
                    {t('PROPERTY.PROPERTY.RESPONSIBLE_PERSON')}: {property.property.responsiblePerson.firstName} {property.property.responsiblePerson.lastName}<br />
                    {t('PROPERTY.PROPERTY.LOCATION')}: {'(' + property.location.code + ') ' + property.location.name}<br />
                    {t('COMMENT')}: {property.beforeLastYearReviews.map(r => r.comment).join(', ') || '(none)'}<br />
                </>}
                extra={
                    <Button
                        size={"small"}
                        icon={<FontAwesomeIcon icon={["fas", "edit"]} />}
                        onClick={() => setEditableModalShown(true)}
                        type={"text"}
                    />
                }
                onBack={() => reload()}
            />
            {editableModalShown ? (
                <EditPropertyModal onClose={() => onEditableModalClose()} property={property.property} />
            ) : null}
            <ExtendedSwitch
                context={ctx}
            />
        </Space>
    )
}