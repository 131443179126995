import {Amount} from "../../../prices/price";
import {Space, Tag, Tooltip} from "antd";
import {displayPrice} from "../../../prices/price.helper";
import {PriceType} from "../order.data";



export interface OrderDetailsPrice {
    type: PriceType,
    price: Amount
}

export const displayOrderDetailsPrice = (priceConfig: OrderDetailsPrice, description: string): JSX.Element => {
    const priceTagText: Record<PriceType, string> = {
        custom_price: "C$",
        custom_discount: "C%",
        client_price: "CC$",
        minimal_price: "M$",
        increased: "MI$"
    };
    // const priceTypes: any = {
    //     regular: <Space direction="vertical" align={"end"}>{displayPrice(price.amount)}</Space>,
    //     custom_discount: displayCustomPriceWithLabel(price),
    //     client_discount: <Space direction="vertical" align={"end"}>{displayPrice(price.amount)}</Space>,
    //     client_price: displayCustomPriceWithLabel(price),
    //     custom_price: displayCustomPriceWithLabel(price)
    // }
    // return priceTypes[price.type]

    return (
        <Space direction="horizontal" align={"center"}>
            {priceTagText[priceConfig.type] ? (
                <Tooltip placement="topRight" title={description}>
                    <Tag color={"red"}>{priceTagText[priceConfig.type]}</Tag>
                </Tooltip>
            ) : null}
            <Space direction="vertical" style={{textAlign: "left"}}>
                <>{displayPrice(priceConfig.price)}</>
            </Space>
        </Space>
    );
}
