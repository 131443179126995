import {AxiosInstance} from "axios";
import API from "../../../utils/api";
import {OrderSplitPreview} from "../../../models/orders/order-split/order.split";

export class OrderSplitService {
    private api: AxiosInstance = API;

    preview(orderId: number): Promise<OrderSplitPreview> {
        return this.api
            .get<OrderSplitPreview>("/orders/" + orderId + "/split")
            .then(x => x.data)
    }

    doSplit(orderId: number): Promise<void> {
        return this.api
            .post("/orders/" + orderId + "/split");
    }
}

export const orderSplitService = new OrderSplitService();
