import React from "react";
import {PlannerTask} from "../../../../../models/planner/planner";
import {Space} from "antd";
import {actions} from "./action";
import {useRequiredStore} from "../../../../../utils/store";
import {RootStoreContext} from "../../../../../stores/root/root.store";

interface PlannerTasksViewActionsProps {
    task: PlannerTask;
    reload: () => void;
}

export const PlannerTasksViewActions: React.FC<PlannerTasksViewActionsProps> = ({task, reload}) => {

    const {
        authStore: { role },
    } = useRequiredStore(RootStoreContext);

    const applicableActions = actions.filter(Action => Action.isApplicable(task, role!));

    return (
        <Space direction={"vertical"}>
            {applicableActions.length > 0 ? (
                <>
                    {applicableActions.map(Action => Action.isApplicable(task, role!) ? (
                    <Action task={task} reload={reload} />
                ) : null)}
                </>
            ) : 'No actions'}
        </Space>
    )
}