import {AxiosInstance} from "axios";
import API from "../../utils/api";

export class LocalesService {
    api: AxiosInstance = API

    getLocales(): Promise<string[]> {
        return this.api.get<string[]>("locales").then(x => x.data);
    }
}

export const localesService = new LocalesService();