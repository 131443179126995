import React from "react";
import {
    MonthlyStatementItemStatus,
    monthlyStatementItemStatusColor
} from "../../../../models/accounting/monthly-statements";
import {StatusTag} from "../../../common/status/status";

interface StatementsItemStatusProps {
    status: MonthlyStatementItemStatus;
    large?: boolean;
}
export const MonthlyStatementsItemStatus: React.FC<StatementsItemStatusProps> = ({status, large = false}) => {
    return (
        <StatusTag<MonthlyStatementItemStatus> status={status} colorsMap={monthlyStatementItemStatusColor} large={large} prefix="ACCOUNTING.STATEMENTS.ITEM.STATUS"/>
    )
}
