import React, {useEffect, useMemo, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {useParams} from "react-router-dom";
import {PropertyInventoryTaskPart, PropertyInventoryTaskProperty} from "../../../../models/property/property";
import {propertyService} from "../../../../services/property/property.service";
import {LoadingSpin} from "../../../common/loading-spin/loading.spin";
import {Col, Row, Typography} from "antd";
import {InventoryTaskStatus} from "../common/inventory-task-status/inventory-task.status";
import {useNavigate} from "react-router";
import {SmartTableDataHandler} from "../../../common/smart-table/smart-table.data-handler";
import {columnFactory} from "./part.inventoty-tasks.inventory.columns";
import {SmartTable} from "../../../common/smart-table/smart-table";
import {useTranslate} from "../../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";
import {SmartAction} from "../../../common/smart-table/smart-table.model";
import {InventoryTaskPartDescription} from "../common/inventory-task-part-description/inventory-task-part-description";

export const InventoryTasksPart: React.FC<ComponentPropsFromRoute> = () => {

    const t = useTranslate();
    const navigate = useNavigate();

    const {id} = useParams<{ id: string }>();

    const [part, setPart] = useState<PropertyInventoryTaskPart | null>(null);

    const handler = useMemo(() => new SmartTableDataHandler(r => propertyService.getInventoryPartProperties(parseInt(id!), r)), []);
    const columns = useMemo(() => columnFactory(t), [t])

    useEffect(() => {
        propertyService.getInventoryPart(parseInt(id!)).then(part => setPart(part));
    }, [id]);

    if (!part) {
        return <LoadingSpin/>
    }

    const actions: SmartAction<any>[] = [
        {
            icon: 'eye',
            title: 'Add review',
            onClick: (item: PropertyInventoryTaskProperty) => {
                navigate('/property/review/inventory-task/' + part.task.id + '/review/' + item.property.id);
            }
        }
    ]

    return (
        <>
            <PageHeader
                style={{ padding: "0 0 10px 0" }}
                ghost={false}
                title={
                    <Typography.Title level={1} style={{ margin: 0 }}>
                        {t("PROPERTY.INVENTORY_TASK_PART.TITLE")}
                    </Typography.Title>
                }
                onBack={() => {
                    navigate("/property/inventory-tasks/" + part?.task.id);
                }}
                extra={<InventoryTaskStatus status={part.status} large={true} />}
            />
            <Row gutter={[25, 25]}>
                <Col span={24}>
                    <InventoryTaskPartDescription part={part} />
                </Col>
                <Col span={24}>
                    <SmartTable
                        handler={handler}
                        columns={columns}
                        actions={actions}
                    />
                </Col>
            </Row>
        </>
    )
}