import { flattenDepartments } from "./../../../../../models/departments/helper";
import { FormInstance } from "antd";
import { action, makeObservable, observable } from "mobx";
import { Department, DepartmentExpanded } from "../../../../../models/departments/department";
import { departmentsService } from "../../../../../services/departments/departments.service";
import React from "react";
import { PagesStore } from "../../../../../stores/pages/pages.store";

export class BranchDataDepartmentStore {
    @observable
    public department: DepartmentExpanded | null = null;

    @observable
    public loading: boolean = false;

    @observable
    public departments: any[] = [];

    constructor(private branchId: string, private id: string, private pageStore: PagesStore, private url: string) {
        makeObservable(this);
        this.init(this.id);
    }

    @action
    private setDepartment(value: DepartmentExpanded | null): void {
        this.department = value;
    }

    @action
    public setLoading(value: boolean): void {
        this.loading = value;
    }

    @action
    public setDepartments(value: any[]): void {
        this.departments = value;
    }

    public async init(departmentId: string, form?: FormInstance) {
        this.setLoading(true);
        this.departments.length === 0 && this.setDepartments(flattenDepartments(await departmentsService.getAll()));
        const department = await departmentsService.getDepartment(this.branchId, parseInt(departmentId, 10));
        this.setDepartment(department);
        if (form) {
            form.setFieldsValue({
                name: department.name,
                parent: department.parent,
                children: department.children.map((item) => ({ id: item.id, name: item.name })),
                roles: [...department.roles].slice().sort((a, b) => a.position! - b.position!),
            });
        }
        this.pageStore.updateRouteName(this.url, this.department?.name);
        this.setLoading(false);
    }

    public updateDepartment = async (id: any, values: any) => {
        const requestValues = {
            ...values,
            roles: values.roles.map((item: any, index: number) => ({
                ...item,
                position: index,
            })),
            children: values.children.map((item: Department) => item.id),
        };
        const department = await departmentsService.updateDepartment(id, requestValues);
        this.setDepartment(department);
    };

    public reloadDepartment = async () => {
        this.setDepartment(await departmentsService.getDepartment(this.branchId, this.department?.id!));
    };
}

export const BranchDataDepartmentStoreContext = React.createContext<BranchDataDepartmentStore | null>(null);
