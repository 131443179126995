import {PaginatedRequest} from "../../../models/core/paginated.request";
import {PaginatedResult} from "../../../models/core/paginated.result";
import {WarehousesTaxesRequest, WarehouseTaxes} from "../../../models/warehouses/warehouses.taxes";
import {AxiosInstance} from "axios";
import API from "../../../utils/api";

export class WarehousesTaxesService {
    api: AxiosInstance = API;

    getWarehousesTaxes(request: PaginatedRequest): Promise<PaginatedResult<WarehouseTaxes>> {
        return this.api.get<PaginatedResult<WarehouseTaxes>>(
            "warehouses/tax-rules",
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }

    createTaxRule(request: WarehousesTaxesRequest): Promise<WarehouseTaxes> {
        return this.api.post<WarehouseTaxes>("warehouses/tax-rules", request).then(x => x.data);
    }

    editTaxRule(request: WarehousesTaxesRequest, id: number): Promise<WarehouseTaxes> {
        return this.api.post<WarehouseTaxes>("warehouses/tax-rules/" + id, request).then(x => x.data);
    }

    removeTaxRule(id: number): Promise<void> {
        return this.api.delete("warehouses/tax-rules/" + id);
    }
}

export const warehousesTaxesService = new WarehousesTaxesService();
