import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {AccessGroup, AccessGroupRequest} from "../../models/users/access-group/access.group";

export class GroupsService {
    api: AxiosInstance = API;

    getGroup(id: string): Promise<AccessGroup> {
        return this.api.get<AccessGroup>("/access/groups/" + id).then(x => x.data);
    }

    createGroup(request: AccessGroupRequest): Promise<AccessGroup> {
        return this.api.post<AccessGroup>("/access/groups", {...request, accesses: []}).then(x => x.data);
    }

    editGroup(request: AccessGroupRequest, id: number): Promise<AccessGroup> {
        return this.api.post<AccessGroup>("/access/groups/" + id, request).then(x => x.data);
    }

}

export const groupsService = new GroupsService();
