// @ts-ignore
import onScan from "onscan.js";

class Scanner {

    public attach (onScanFn: (sCode: string) => void): void {
        onScan.attachTo(window.document, {
            minLength: 1,
            avgTimeByChar: 50,
            suffixKeyCodes: [13], // enter-key expected at the end of a scan
            reactToPaste: false, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
            onScan: (sCode: string) => { // Alternative to document.addEventListener('scan')
                onScanFn(sCode);
            },
            keyCodeMapper: (event: KeyboardEvent) => {
                const iCode = onScan._getNormalizedKeyNum(event);
                switch (true) {
                    case iCode >= 48 && iCode <= 90: // numbers and letters
                    case iCode >= 106 && iCode <= 111: // operations on numeric keypad (+, -, etc.)

                        let sDecoded = String.fromCharCode(iCode);

                        switch (event.shiftKey) {
                            case false: sDecoded = sDecoded.toLowerCase(); break;
                            case true: sDecoded = sDecoded.toUpperCase(); break;
                        }
                        return sDecoded;
                    case iCode >= 96 && iCode <= 105: // numbers on numeric keypad
                        return 0+(iCode-96);
                }
                return '';
            }
        });
    }

    public detach(): void {
        onScan.detachFrom(window.document);
    }
}

export const scanner = new Scanner();