import React, {useMemo, useState} from "react";
import {Button, Space, Typography} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {ReportsAccountingMonthlyStatementsUploadModal} from "./upload-modal/upload-modal";
import {ColumnsType} from "antd/es/table";
import i18n from "i18next";
import {columnsFactory} from "./statements-list.columns";
import {filterGroupsFactory} from "./statements-list.filter-groups";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {MonthlyStatement} from "../../../../models/accounting/monthly-statements";
import {ColumnsConfig, convertTableColumnsToColumnConfigs} from "../../../../utils/table";
import {FilterDataHandler} from "../../../../models/filter/filter.data.handler";
import {FieldGroupConfig} from "../../../../models/filter/filter.group";
import {monthlyStatementService} from "../../../../services/accounting/monthly-statement.service";
import {FilterButtonConfig} from "../../../../models/filtered-table/filtered.table.props";
import {FieldGroupSnapshot} from "../../../../models/filter/filter.field.snapshot";

export enum FilterButtonsTypes {
    LAST = "okay",
    ARCHIVE = "archive"
}

export const AccountingMonthlyStatements: React.FC<ComponentPropsFromRoute> = ({name}) => {
    const t = useTranslate();

    const columns: ColumnsType<MonthlyStatement> = useMemo(() => columnsFactory(t), [i18n.language]);
    const columnsConfigs: ColumnsConfig = useMemo(() => convertTableColumnsToColumnConfigs<MonthlyStatement>(columns), [i18n.language])
    const dataHandler: FilterDataHandler<MonthlyStatement> = useMemo(() => new FilterDataHandler(request => monthlyStatementService.getAll(request)), []);
    const fcgConfigs: FieldGroupConfig[] = useMemo(() => filterGroupsFactory(t), []);
    const [uploadModalShown, setUploadModalShown] = useState<boolean>(false);

    const filterButtonConfigs: FilterButtonConfig<FilterButtonsTypes>[] = [
        {
            type: FilterButtonsTypes.LAST,
            text: "LAST",
            filters: [
                {
                    name: "replaced",
                    snapshots: [
                        {
                            name: "replaced",
                            value: "0"
                        },
                    ],
                },
            ],
        },
        {
            type: FilterButtonsTypes.ARCHIVE,
            text: "ARCHIVE",
            filters: [
                {
                    name: "replaced",
                    snapshots: [
                        {
                            name: "replaced",
                            value: "1"
                        },
                    ],
                },
            ],
        }
    ];

    const defaultFilters: FieldGroupSnapshot[] = [
        {
            name: "replaced",
            snapshots: [
                {
                    name: "replaced",
                    value: "0"
                },
            ],
        }
    ]

    return <Space direction="vertical" size={[20, 20]}>
        <Typography.Title level={3} style={{margin: 0}}>
            {t("REPORTS.ACCOUNTING.MONTHLY-STATEMENTS.TITLE")}
        </Typography.Title>
        <Button onClick={() => setUploadModalShown(true)} type={"primary"}>
            {t("REPORTS.ACCOUNTING.MONTHLY-STATEMENTS.UPLOAD.TITLE")}
        </Button>
        <ReportsAccountingMonthlyStatementsUploadModal
            visible={uploadModalShown}
            onClose={() => setUploadModalShown(false)}
            onSubmit={() => true}
        />
        <FilteredTable
            columns={columns}
            columnsConfigs={{columns: columnsConfigs, pageKey: name}}
            dataHandler={dataHandler}
            filterGroups={fcgConfigs}
            filterButtons={filterButtonConfigs}
            filters={defaultFilters}
        />
    </Space>;
}