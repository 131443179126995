import React, {useState} from "react";
import {PlannerTask} from "../../../../../models/planner/planner";
import {TaskAssignee} from "./task.assignee";
import {Button, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PlannerTasksAssigneeAddModal} from "./task.assignee.add.modal";

interface TaskAssigneesProps {
    task: PlannerTask
    reload: () => void;
}

export const TaskAssignees: React.FC<TaskAssigneesProps> = ({task, reload}) => {

    const [addModalShown, setAddModalShown] = useState<boolean>(false);

    const onComplete = () => {
        setAddModalShown(false);
        reload();
    }

    return (
        <Space direction={"vertical"}>
            {task.assignees.map(assignee =>
                <TaskAssignee task={task} assignee={assignee} reload={reload} />)
            }
            <Button
                icon={<FontAwesomeIcon icon={["fas", "user-plus"]}/>}
                type={"text"}
                size={"small"}
                onClick={() => setAddModalShown(true)}
            />
            {addModalShown ? (
                <PlannerTasksAssigneeAddModal
                    onClose={onComplete}
                    task={task}
                />
            ) : null}
        </Space>
    )
}
