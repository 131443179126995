import {UserAccessCategoryMap} from "../access-category/user.access.category";
import {Access, AccessMap} from "../access/access";

export const getCheckedAccesses = (accessesData: UserAccessCategoryMap): string[] => {
    const result: string[] = [];
    Object.keys(accessesData).forEach(categoryKey => {
        Object.keys(accessesData[categoryKey].accesses).forEach(accessKey => {
            if (accessesData[categoryKey].accesses[accessKey].checked) {
                result.push(accessKey);
            }
        })
    });
    return result;
}

export const createAccessMap = (accesses: Access[], currentGroupAccesses: Access[]): AccessMap => {
    const result: any = {};
    accesses.forEach(item => {
        result[item.id] = {
            description: item.description,
            checked: !!currentGroupAccesses.find(currentGroupAccess => currentGroupAccess.id === item.id)
        }
    });
    return result;
}
