import {Space, Typography} from "antd";
import React from "react";
import {useTranslate} from "../../hooks/translate.hook";
import {Link} from "react-router-dom";
import "./not.found.scss";

export const NotFound: React.FC = () => {
    const t = useTranslate();

    return (
        <Space className="not-found" size={[10, 10]} direction="vertical" align={"center"}>
            <Typography.Text className="not-found_title">404</Typography.Text>
            <Typography.Text className="not-found_text" style={{fontSize: "3rem"}}>
                {t("NOT_FOUND.TITLE")}
            </Typography.Text>
            <Link className="not-found_link link" to={"/"}>{t("NOT_FOUND.RETURN")}</Link>
        </Space>
    );
};
