import React, {useMemo, useState} from "react";
import { Invoice } from "../../../../../models/documents/invoices/invoice";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { useRequiredStore } from "../../../../../utils/store";
import {Alert, Button, Dropdown, Menu, Row, Space} from "antd";
import FilteredTable from "../../../../common/filtered-table/filtered.table";
import { ComponentPropsFromRoute } from "../../../../../routers/routers";
import { AgentDataStoreContext } from "../../agent.data.store";
import { observer } from "mobx-react";
import {convertTableColumnsToColumnConfigs} from "../../../../../utils/table";
import {PDFModal} from "../pdf-modal/pdf.modal";
import {TableRowSelection} from "antd/lib/table/interface";
import {columnsFactory} from "./agent.data.invoices.columns";
import {filtersFactory} from "./agent.data.invoices.filters";
import {FilterDataHandler} from "../../../../../models/filter/filter.data.handler";
import {invoiceService} from "../../../../../services/documents/invoices/invoices.service";

interface AgentDataInvoicesProps extends ComponentPropsFromRoute {}
export const AgentDataInvoices: React.FC<AgentDataInvoicesProps> = observer(({ name }) => {
    const t = useTranslate();

    const dataHandler = useMemo(
        () =>
            new FilterDataHandler<Invoice>((request) =>
                invoiceService.getInvoices(request, { account: String(agentDataStore.account!.id) })
            ),
        []
    );

    const agentDataStore = useRequiredStore(AgentDataStoreContext);

    const columns = useMemo(() => columnsFactory(t), []);
    const filters = useMemo(() => filtersFactory(t), []);

    const [exportAsPDFModalShown, setExportAsPDFModalShown] = useState<boolean>(false);
    const [selectedDocs, setSelectedDocs] = useState<any[]>([]);

    const rowSelection: TableRowSelection<Invoice> = {
        preserveSelectedRowKeys: true,
        type: "checkbox",
        selectedRowKeys: selectedDocs,
        onChange: (selectedRowKeys: React.Key[], selectedRows: Invoice[]) => {
            setSelectedDocs(selectedRowKeys)
        },
    };

    return (
        <Space direction={"vertical"} size={[10, 10]}>
            <Row justify={"end"}>
                <Dropdown
                    trigger={["click"]}
                    overlay={
                        <Menu>
                            <Menu.Item
                                key={0}
                                onClick={() => setExportAsPDFModalShown(true)}
                            >
                                {t('DOCUMENTS.EXPORT.AS_PDF')}
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <Button type={"primary"}>
                        {t("ACTION")}
                    </Button>
                </Dropdown>
            </Row>

            <Alert
                message={t("INVOICES.MERGED_INVOICE.LENGTH") + ": " + selectedDocs.length}
                type="info"
            />

            <FilteredTable
                rowSelection={rowSelection}
                columns={columns}
                columnsConfigs={{
                    columns: convertTableColumnsToColumnConfigs(columns),
                    pageKey: name,
                }}
                filterKeys={filters}
                dataHandler={dataHandler}
            />
            {exportAsPDFModalShown ? (
                <PDFModal
                    onClose={() => setExportAsPDFModalShown(false)}
                    accountId={agentDataStore.account!.id}
                    selectedDocs={selectedDocs}
                    type={"invoice"}
                />
            ) : null}
        </Space>
    );
});
