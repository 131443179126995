import {AxiosInstance, AxiosResponse} from "axios";
import API from "../../utils/api";
import {PaginatedResult} from "../../models/core/paginated.result";
import {LanguagesModel} from "../../models/languages/languages";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {KeyRequest} from "../../models/languages/key.request";
import {ImportResponse} from "../../models/import/import.response";

export class LanguagesService {

    api: AxiosInstance = API;

    getAllKeys(request: PaginatedRequest): Promise<PaginatedResult<LanguagesModel>> {
        return this.api.get<PaginatedResult<LanguagesModel>>(
            'i18n/key',
            {
                params: request.toParams(),

            }).then(x => x.data);
    }

    updateKey(request: KeyRequest, id: string = ""): Promise<LanguagesModel> {
        return this.api.post('i18n/key/' + id, request).then(x => x.data);
    }

    createKey(request: KeyRequest): Promise<LanguagesModel> {
        return this.api.post('i18n/key', request).then(x => x.data);
    }

    deleteKey(id: string): Promise<void> {
        return this.api.delete('i18n/key/' + id);
    }

    getScopes(): Promise<string[]> {
        return this.api.get<string[]>('i18n/scope').then(x => x.data);
    }

    getLocales(): Promise<string[]> {
        return this.api.get<string[]>('i18n/locale').then(x => x.data);
    }

    importLocaleFile(value: {file: File, withUpdate: boolean}, onProgress: (percent: number) => void): Promise<AxiosResponse<ImportResponse>> {
        let formData = new FormData();
        formData.append("file", value.file);
        formData.append("withUpdate", value.withUpdate ? "1" : "0")
        return this.api.post<AxiosResponse<ImportResponse>>("i18n/key/import", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: event => onProgress((event.loaded / event.total) * 100)
        }).then(x => x.data);
    }
}

export const languagesService = new LanguagesService();
