import React, {PropsWithChildren, useState} from "react";
import './menu.scss'
import {Link} from "react-router-dom";

interface RootMenuItemProps {
    subMenu?: React.FC;
    onClick?: () => void;
    to?: string;

}

export const RootMenuItem: React.FC<PropsWithChildren<RootMenuItemProps>> = ({children, subMenu, onClick, to}) => {

    const [opened, setOpened] = useState<boolean>(false);

    if (onClick) {
        return <li onClick={onClick}>
            {children}
        </li>;
    }

    if (to) {
        return <li>
            <Link to={to}>
                {children}
            </Link>
        </li>;
    }

    if (subMenu) {
        const SubMenuComponent = subMenu;
        return <li onClick={() => setOpened(v => !v)}>
            {children}
            {opened ? (
                <div className={"root-sub-menu"} onClick={e => e.stopPropagation()}>
                    <SubMenuComponent />
                </div>
            ) : null}
        </li>;
    }

    return <li>
        {children}
    </li>
};