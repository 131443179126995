import React, {useMemo, useState} from "react";
import {reportsService} from "../../../../services/reports/reports.service";
import {useParams} from "react-router-dom";
import {SmartTableDataHandler} from "../../../common/smart-table/smart-table.data-handler";
import {SmartTable} from "../../../common/smart-table/smart-table";
import {FieldType} from "../../../common/smart-table/smart-table.field-config";
import Date from "../../../date/date";
import {Button, Col, Row} from "antd";
import {downloadBlobFile} from "../../../../utils/blob.download";
import {ReportsReportPreparingStatusModal} from "../view/preparing-status/reports.report.preparing-status.modal";

export const ReportsReportHistory: React.FC = () => {

    const { name } = useParams<{ name: string }>();
    const handler = useMemo(() => new SmartTableDataHandler(r => reportsService.getHistory(name!, r)), [name])

    const [shownPreparingStatusId, setShownPreparingStatusId] = useState<number>(0);

    const download = async (id: number) => {
        const data = await reportsService.download(id);
        downloadBlobFile(data.blob, data.filename);
    }

    return (
        <Row>
            <Col span={8}>
                <SmartTable
                    handler={handler}
                    columns={[
                        {
                            name: 'createdAt',
                            visibleName: 'Date',
                            type: FieldType.STRING,
                            render: v => <Date dateString={v} showTime />
                        },
                        {
                            name: 'status',
                            visibleName: 'Status',
                            type: FieldType.STRING
                        },
                        {
                            name: 'id',
                            visibleName: '',
                            type: FieldType.STRING,
                            render: (id: number, item: any) => <>
                                {item.status === 'completed' ? (
                                    <Button onClick={() => download(id)}>Download</Button>
                                ) : (
                                    <Button onClick={() => setShownPreparingStatusId(id)}>Show progress</Button>
                                )}
                            </>
                        }
                    ]}
                />
                {shownPreparingStatusId ? (
                    <ReportsReportPreparingStatusModal
                        id={shownPreparingStatusId}
                        onClose={() => setShownPreparingStatusId(0)}
                    />
                ) : null}
            </Col>
        </Row>
    )
};