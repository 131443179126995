import React, {useState} from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../../utils/store";
import {AgentDataStoreContext} from "../../agent.data.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {Button, Col, Form, Input, Row, Select, Typography} from "antd";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {accountService} from "../../../../../services/accounts/account.service";
import {notifications} from "../../../../../services/notifications/notifications";

export const AgentDataShipments: React.FC<ComponentPropsFromRoute> = observer(({name}) => {
    const {account} = useRequiredStore(AgentDataStoreContext);
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const { commonStore } = useRequiredStore(RootStoreContext);

    if (!account) {
        return <></>;
    }

    const submit = async (values: any) => {
        setLoading(true)
        try {
            await accountService.updateShipments(account.id, values)
            notifications.successfully()
        } catch (e: any) {
            if (e.response?.data.fields) {
                form.setFields(e.response?.data?.fields);
            }
        } finally {
            setLoading(false);
        }
    }

    const defaultValues = {
        shipmentCondition: account.shipmentCondition,
        shipmentProcedureName: account.shipmentProcedureName,
        customShipper: account.customShipper?.id,
    }

    return <Form
        size={"middle"}
        form={form}
        layout="vertical"
        name={"client_form"}
        initialValues={defaultValues}
        onFinish={async (values) => {
            await form.validateFields();
            await submit(values)
        }}
    >
        <Typography.Title level={3}>{t("CLIENT.SHIPMENTS.TITLE")}</Typography.Title>
        <Row gutter={[30, 0]} align={"top"}>
            <Col span={12}>
                <Row gutter={[30, 0]} align={"top"}>
                    <Col span={24}>
                        <Form.Item name={["shipmentCondition"]} label={t("SHIPMENT_CONDITION")}>
                            <Input disabled={loading} type="text" placeholder={t("SHIPMENT_CONDITION")} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={["shipmentProcedureName"]} label={t("SHIPMENT_PROCEDURE")}>
                            <Select
                                disabled={loading}
                                placeholder={t("SHIPMENT_PROCEDURE")}
                            >
                                {["default", "autodoc"].map((procedure) => (
                                    <Select.Option key={procedure} value={procedure}>
                                        {procedure}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={["customShipper"]} label={t("CLIENT.CUSTOM_SHIPPER")}>
                            <Select allowClear loading={loading} placeholder={t("CLIENT.CUSTOM_SHIPPER")}>
                                {commonStore.shippers.map(({ id, companyName, personName }) => (
                                    <Select.Option key={id} value={id}>
                                        {companyName} ({personName})
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row gutter={[30, 0]} align={"top"}>
            <Col span={24}>
                <Button disabled={loading} type={"primary"} onClick={() => form.submit()}>{t("SAVE")}</Button>
            </Col>
        </Row>
    </Form>
});
