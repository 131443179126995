import React, {useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Col, Form, Input, Modal, Row} from "antd";
import {DeclarationsDocument} from "../../../../../models/declarations/declarations.documents";
import {declarationsDocumentsService} from "../../../../../services/declarations/declarations.documents.service";

export interface DeclarationsDocumentEditModalProps {
    item: DeclarationsDocument | null;
    onClose(): void;
}

export const DeclarationsDocumentEditModal: React.FC<DeclarationsDocumentEditModalProps> = ({item, onClose}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const initialValues: any = {};
    if (item) {
        initialValues.fullCode = item.fullCode;
        initialValues.documentCode = item.documentCode;
        initialValues.viide = item.viide;
    }

    return (
        <Modal
            open={true}
            confirmLoading={loading}
            title={t("DECLARATIONS.ADD_CODE_DOCUMENTS")}
            okText={item ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => onClose()}
        >
            <Form
                form={form}
                layout="vertical"
                name={"address_form"}
                initialValues={initialValues}
                onFinish={() =>
                    form.validateFields().then(async (values) => {
                        setLoading(true);
                        try {
                            await (item
                                    ? declarationsDocumentsService.update(item.id, values)
                                    : declarationsDocumentsService.create(values)
                            );
                            onClose();
                        }
                        catch (e: any) {
                            if (e.response?.data?.fields) {
                                form.setFields(e.response?.data?.fields);
                            }
                            setLoading(false);
                        }
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="fullCode"
                            label={t("FULL_CODE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                placeholder={t("FULL_CODE")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="documentCode"
                            label={"pohi"}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                placeholder={"pohi"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="viide"
                            label={"viide"}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                placeholder={"viide"}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
